import './animalspeciesarray.css';
import ValueArray from '../../../../elements/valuearray/ValueArray';
import Popup from '../../../../elements/popup/Popup';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { AnimalSpeciesResponse } from '../../../../api/petcloudapi/api';
import { useState } from 'react';
import AnimalSpeciesEditor from '../animalspecieseditor/AnimalSpeciesEditor';

interface AnimalSpeciesArrayProps {
  animalSpecies: AnimalSpeciesResponse[] | null | undefined;
  availableAnimalSpecies: AnimalSpeciesResponse[];
  onDelete: (index: number) => void;
  onSubmit?: (animalSpecies: AnimalSpeciesResponse) => void;
  hint?: {
    title?: string;
    paragraphs: string[];
  };
}

const AnimalSpeciesArray: React.FC<AnimalSpeciesArrayProps> = ({
  animalSpecies,
  availableAnimalSpecies,
  onDelete,
  onSubmit,
  hint,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.animalSpeciesArray',
  });

  const [popup, setPopup] = useState(false);

  return (
    <div className={'animalSpeciesSelector'}>
      <ValueArray
        title={t('animalSpecies')}
        hint={hint}
        items={animalSpecies}
        titleKey={'name'}
        titleKeyLang={'de-DE'}
        onEdit={onSubmit ? () => setPopup(true) : undefined}
        onDelete={onSubmit ? onDelete : undefined}
        emptyStateText={t('emptyArray')}
      />
      <Popup toggled={popup} width={'30%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('popup.title')}</div>
        <AnimalSpeciesEditor
          availableAnimalSpecies={availableAnimalSpecies}
          animalSpecies={animalSpecies}
          onDelete={onDelete}
          onSubmit={onSubmit}
        />
        <div className={'categoryEdit-popup-actions'}>
          <Button
            cta={t('popup.cta')}
            look={'save'}
            width={'full'}
            action={() => setPopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
};

export default AnimalSpeciesArray;
