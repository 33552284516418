import './orderbrief.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrderResponse } from '../../../api/petcloudapi/api';
import Badge from '../../../elements/badge/Badge';
import Button from '../../../elements/button/Button';
import DateTime from '../../../elements/datetime/DateTime';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';
import { states } from '../../../features/list/List';

interface OrderBriefProps {
  order: OrderResponse;
}

const OrderBrief: React.FC<OrderBriefProps> = ({ order }) => {
  const { t } = useTranslation();
  const link = useNavigate();

  const renderStatus = (status: string) => {
    return (
      <Badge
        title={t('list.states.' + status)}
        color={states[status] ?? 'var(--color-gray)'}
      />
    );
  };

  const renderDate = (date: string) => {
    return (
      <DateTime dateString={date} show={['day', 'month', 'year', 'time']} />
    );
  };

  const prepareOrderForList = (order: OrderResponse) => {
    return [
      {
        title: t('view.payment.order.currentOrderState'),
        value: order.currentOrderState.orderState?.technicalName,
        renderMethod: renderStatus,
      },
      {
        title: t('view.payment.order.currentTransactionState'),
        value: order.currentTransactionState.orderState?.technicalName,
        renderMethod: renderStatus,
      },
      {
        title: t('view.payment.order.currentShippingState'),
        value: order.currentShippingState.orderState?.technicalName,
        renderMethod: renderStatus,
      },
      {
        title: t('view.payment.order.orderNumber'),
        value: order.orderNumber,
      },
      {
        title: t('view.payment.order.amountNet'),
        value: order.amountNet,
      },
      {
        title: t('view.payment.order.amountTotal'),
        value: order.amountTotal,
      },
      {
        title: t('view.payment.order.orderDateTime'),
        value: order.orderDateTime,
        renderMethod: renderDate,
      },
    ];
  };

  return (
    <div className="orderbrief">
      <TitleValueList entries={prepareOrderForList(order)} />
      <div className="orderbrief-actions">
        <Button
          cta={t('view.payment.order.cta')}
          action={() => link('/orders/' + order.id)}
          look={'secondary'}
          width="minimal"
        />
      </div>
    </div>
  );
};

export default OrderBrief;
