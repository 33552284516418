import './itemactions.css';
import { ReactNode, useRef, useState } from 'react';
import { ReactComponent as More } from '../../../assets/icon/more.svg';
import ActionsPopout from './actionspopout/ActionsPopout';
import { ButtonLook } from '../button/Button';

export interface ItemAction {
  icon?: ReactNode;
  cta?: string;
  ctaAlreadyTranslated?: boolean;
  look?: ButtonLook;
  action?: (item?: any) => void;
  hide?: (item: any) => boolean;
  renderFunc?: () => ReactNode;
  group?: string;
}

interface ItemActionsProps {
  item: {
    [key: string]: any;
  };
  actions: ItemAction[];
  iconAlignment?: 'horizontal' | 'vertical';
  xOffset?: number;
  yOffset?: number;
}

const ItemActions: React.FC<ItemActionsProps> = ({
  item,
  actions,
  iconAlignment,
  xOffset,
  yOffset,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showActions, setShowActions] = useState(false);

  const actionsPopupActions: ItemAction[] = [];
  actions.forEach((action) => {
    if (action.cta !== 'show_variants') {
      actionsPopupActions.push(action);
    } else if (item.children) {
      actionsPopupActions.push(action);
    }
  });

  return (
    <div className="itemActions" ref={ref}>
      <More
        fill="var(--color-text_tertiary)"
        className={
          iconAlignment
            ? 'itemActions-icon itemActions-icon-alignment-' + iconAlignment
            : 'itemActions-icon'
        }
        onClick={(e) => {
          e.stopPropagation();
          setShowActions(true);
        }}
        onMouseDown={(e) => e.stopPropagation()}
      />
      {showActions && ref ? (
        <ActionsPopout
          item={item}
          show={showActions}
          hide={() => setShowActions(false)}
          actions={actionsPopupActions}
          xOffset={xOffset}
          yOffset={yOffset}
          anchorRef={ref}
        />
      ) : null}
    </div>
  );
};

export default ItemActions;
