import './notification.css';
import { ReactComponent as IconClose } from '../../../assets/icon/close.svg';

export enum NotificationType {
  Info,
  Warning,
  Error,
  Success,
}

interface NotificationProps {
  type: NotificationType;
  title?: string;
  text: string;
  remove?: () => void;
}

const backgroundColor = {
  0: 'var(--color-blue)',
  1: 'var(--color-warning)',
  2: 'var(--color-danger)',
  3: 'var(--color-success)',
};

const Notification: React.FC<NotificationProps> = ({
  type,
  title,
  text,
  remove,
  children,
}) => {
  return (
    <div
      className={'notification'}
      style={{ backgroundColor: backgroundColor[type] }}
    >
      <div className={'notification-body'}>
        {title ? <div className={'notification-title'}>{title}</div> : null}
        <div className={'notification-text'}>{text}</div>
        {children ? (
          <div className={'notification-children'}>{children}</div>
        ) : null}
      </div>
      {remove ? (
        <div className={'notification-actions'}>
          <IconClose
            className={'notification-closeIcon'}
            fill={'white'}
            onClick={remove}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Notification;
