import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import OAuth2 from './OAuth2';

// Authentication Context - Allows to access props defined in Authentication Provider
export type AuthenticationContextValue = {
  logout: () => void;
  initiateAuth: () => void;
  getAccessToken: (code: string) => void;
  refreshToken: () => Promise<unknown>;
  needsTokenRefresh: () => boolean;
  accessToken: () => string | null | undefined;
  setRedirectUri: (uri: string) => void;
  setRedirectUriToCurrentUrl: () => void;
};

export const AuthenticationContext =
  createContext<AuthenticationContextValue | null>(null);

export const useAuthentication = () => {
  const ac = useContext(AuthenticationContext);

  if (ac === null) {
    throw new Error('useAuthentication must be inside AuthenticationProvider');
  }
  return ac;
};

// Authentication Provider - Provides entire app with auth, user and logout method.
export const AuthenticationProvider: React.FC = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'access_token',
    'refresh_token',
    'id_token',
  ]);

  const [at, setAt] = useState<string | null>(null);

  // if the authcode is present in URL / user got here via redirect URL, get the access token
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authCode = urlParams.get('code');
    console.log(authCode);
    if (authCode) {
      getAccessToken(authCode);
    }
  }, []);

  const petcloudAuth = new OAuth2({
    clientId:
      process.env.REACT_APP_OAUTH_CLIENT_ID ??
      'clientId environment variable is missing',
    authUri:
      process.env.REACT_APP_OAUTH_AUTH_URI ??
      'authUri environment variable is missing',
    accessTokenUrl:
      process.env.REACT_APP_OAUTH_ACCESS_TOKEN_URL ??
      'accessTokenUrl environment variable is missing',
    endSessionEndpoint:
      process.env.REACT_APP_OAUTH_END_SESSION_ENDPOINT ??
      'endSessionEndpoint environment variable is missing',
    refreshThreshold: 3600,
  });

  const getCurrentBaseUrl = () => {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  };

  const setRedirectUri = (uri: string) => {
    petcloudAuth.redirectUri = uri;
  };

  const setRedirectUriToCurrentUrl = () => {
    petcloudAuth.redirectUri = getCurrentBaseUrl();
  };

  // send user to PetCloud Keycloack for login
  const initiateAuth = () => {
    petcloudAuth.initiateAuth();
  };

  const setTokenCookies = (
    access_token: string,
    refresh_token: string,
    expires_in: number,
    refresh_expires_in: number,
    id_token: string
  ) => {
    // set cookie for access, id, and refresh tokens
    setCookie('access_token', access_token, {
      path: '/',
      expires: createExpirationDate(expires_in),
    });

    setCookie('refresh_token', refresh_token, {
      path: '/',
      expires: createExpirationDate(refresh_expires_in),
    });

    setCookie('id_token', id_token, {
      path: '/',
      expires: new Date(getExp(id_token) * 1000),
    });
  };

  // get tokens with code from keycloak redirect
  const getAccessToken = (code: string) => {
    petcloudAuth
      .getTokens(code)
      .then((response) => {
        console.log(response);
        setTokenCookies(
          response.data.access_token,
          response.data.refresh_token,
          parseFloat(response.data.expires_in),
          parseFloat(response.data.refresh_expires_in),
          response.data.id_token
        );
        setAt(response.data.access_token);
        window.location.href = getCurrentBaseUrl();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const accessToken = () => {
    return at;
  };

  const needsTokenRefresh = () => {
    return !cookies.access_token;
  };

  const refreshToken = () => {
    return new Promise((resolve: any, reject: any) => {
      if (cookies.refresh_token) {
        console.log('trying to get a new token...');
        petcloudAuth
          .refreshTokens(cookies.refresh_token)
          .then((response: any) => {
            console.log(response);
            resolve(response.data.access_token);
            setTokenCookies(
              response.data.access_token,
              response.data.refresh_token,
              parseFloat(response.data.expires_in),
              parseFloat(response.data.refresh_expires_in),
              response.data.id_token
            );
          })
          .catch((error) => {
            console.log(error);
            reject();
            logout(true);
          });
      } else {
        reject();
        logout();
      }
    });
  };

  const createExpirationDate = (secondsFromNow: number) => {
    const expirationDate = new Date();
    expirationDate.setSeconds(secondsFromNow);
    return expirationDate;
  };

  const getExp = (token: string) => {
    const tokenPart = token.split('.')[1];
    const decodedTokenPart = window.atob(tokenPart);
    const parsed = JSON.parse(decodedTokenPart);
    return parsed.exp;
  };

  const tokenExpired = (token: string) => {
    const exp = getExp(token);
    const end = new Date(exp * 1000);
    const now = new Date();
    return now > end;
  };

  const logout = (forceNew?: boolean) => {
    const idToken = cookies.id_token;
    console.log(idToken);

    removeCookie('access_token', { path: '/' });
    removeCookie('refresh_token', { path: '/' });
    removeCookie('id_token', { path: '/' });

    // Redirect to end_session_endpoint but only if session has not expired yet
    if (idToken && !tokenExpired(idToken)) {
      const params = new URLSearchParams();
      setRedirectUri(window.location.href);
      params.append('id_token_hint', idToken);
      params.append('post_logout_redirect_uri', petcloudAuth.redirectUri);
      window.location.href = `${
        petcloudAuth.endSessionEndpoint
      }?${params.toString()}`;
    } else if (forceNew) {
      initiateAuth();
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        logout,
        initiateAuth,
        getAccessToken,
        refreshToken,
        needsTokenRefresh,
        accessToken,
        setRedirectUri,
        setRedirectUriToCurrentUrl,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
