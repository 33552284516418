import './rolenamemapper.css';
import { UserRoleResponse } from '../../../../api/petcloudapi/api';
import { ReactComponent as IconShield } from '../../../../../assets/icon/sidebar/shield.svg';

interface RoleNameMapperProps {
  role: UserRoleResponse;
  withBackground?: boolean;
}

const RoleNameMapper: React.FC<RoleNameMapperProps> = ({
  role,
  withBackground,
}) => {
  const { name } = role;

  if (name === 'MANUFACTURER_ADMIN') {
    return (
      <span
        className={`roleNameMapper-name roleNameMapper-admin ${
          withBackground
            ? 'roleNameMapper-background roleNameMapper-admin-background'
            : null
        }`}
      >
        <IconShield
          className={'roleNameMapper-icon'}
          fill={'var(--color-success)'}
        />
        Administrator
      </span>
    );
  }
  if (name === 'PRODUCT_OWNER') {
    return (
      <span
        className={`roleNameMapper-name roleNameMapper-productOwner ${
          withBackground
            ? 'roleNameMapper-background roleNameMapper-productOwner-background'
            : null
        }`}
      >
        <IconShield
          className={'roleNameMapper-icon'}
          fill={'var(--color-danger)'}
        />
        Product Owner
      </span>
    );
  }
  return (
    <span
      className={`roleNameMapper-name ${
        withBackground ? 'roleNameMapper-background' : null
      }`}
    >
      {name}
    </span>
  );
};

export default RoleNameMapper;
