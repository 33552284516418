import './propertygrouparray.css';
import { PropertyGroupResponse } from '../../../../api/petcloudapi/api';
import ValueArray from '../../../../elements/valuearray/ValueArray';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../elements/popup/Popup';
import PropertyBrowser from '../../../../elements/propertybrowser/PropertyBrowser';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import Button from '../../../../elements/button/Button';

interface PropertyGroupArrayProps {
  propertyGroupIds: string[] | null | undefined;
  availablePropertyGroups: PropertyGroupResponse[];
  updateSelectedPropertyGroups: (groups: PropertyGroupResponse[]) => void;
}

const PropertyGroupArray: React.FC<PropertyGroupArrayProps> = ({
  propertyGroupIds,
  availablePropertyGroups,
  updateSelectedPropertyGroups,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroups.newPopup.propertyGroups',
  });
  const [popup, setPopup] = useState(false);

  const selectedPropertyGroups = availablePropertyGroups.filter((g) =>
    propertyGroupIds?.includes(g.id)
  );

  return (
    <div className={'propertyGroupArray'}>
      <ValueArray
        title={t('title')}
        items={selectedPropertyGroups}
        titleKey={'name'}
        titleKeyLang={i18n.language as TranslatedStringIndex}
        onEdit={() => setPopup(true)}
      />
      <Popup toggled={popup} width={'40%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('popup.title')}</div>
        <PropertyBrowser
          availablePropertyGroups={availablePropertyGroups}
          selectedPropertyGroups={selectedPropertyGroups}
          updateSelectedPropertyGroups={updateSelectedPropertyGroups}
          withSearch
        />
        <div className={'global-cardActions-noBorder'}>
          <Button
            cta={t('popup.ok')}
            look={'secondary'}
            width={'minimal'}
            action={() => setPopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
};

export default PropertyGroupArray;
