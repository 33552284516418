import { useTranslation } from 'react-i18next';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import ProductProperties from '../../sections/admin/productproperties/ProductProperties';

const Properties = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'view.admin' });
  const { user } = useUser();

  if (user?.isProductOwner) {
    return (
      <Stage>
        <Navigator title={t('properties.title')}></Navigator>
        <Card bigScreenWidth={'full'}>
          <CardSection>
            <ProductProperties />
          </CardSection>
        </Card>
      </Stage>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default Properties;
