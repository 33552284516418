import { UserSettingHeaderConfig } from '../features/jsontable/headerconfigsave/HeaderConfigSave';
import { useUser } from '../contexts/auth/User';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../api/PetCloudApi';
import { useErrorHandler } from '../contexts/errorhandler/ErrorHandler';
import { UserSettingKey } from '../api/petcloudapi/api';

export type UserSettings = {
  productBulkEditor?: {
    headerConfigs: UserSettingHeaderConfig[];
  };
};

const useUserSettings = (saveSuccessCallback?: () => void) => {
  const api = usePetCloudApi();
  const usersApi = api.usersApi();
  const errorHandler = useErrorHandler();

  const { user, getUser } = useUser();
  const [currentUserSettings, setCurrentUserSettings] =
    useState<UserSettings | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setCurrentUserSettings(
      user?.settings ? JSON.parse(user.settings['PortalSettings']) : {}
    );
  }, [user]);

  const updateUserSettings = (key: keyof UserSettings, setting: any) => {
    console.log(setting);
    const update = { ...currentUserSettings };
    update[key] = setting;
    setCurrentUserSettings(update);

    setIsSubmitting(true);
    // submit to backend
    usersApi
      .usersUpdateUserSetting({
        key: UserSettingKey.PortalSettings,
        value: JSON.stringify(update),
      })
      .then((response) => {
        console.log(response);
        if (saveSuccessCallback) {
          saveSuccessCallback();
        }
        getUser(true);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return { currentUserSettings, updateUserSettings, isSubmitting };
};

export default useUserSettings;
