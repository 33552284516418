import './producttour.css';
import Card, { CardSection } from '../../../elements/card/Card';
import { KnowledgeBaseGroup } from '../../knowledgebase/KnowledgeBase';
import { KnowledgeBaseInfoItem } from '../../../elements/knowledgebasepopup/KnowledgeBasePopup';
import { useTranslation } from 'react-i18next';
import useLocalStorageSettings from '../../../hooks/useLocalStorageSettings';
import { useState } from 'react';

interface ProductTourProps {
  title: string;
  text?: string;
  infoItems: KnowledgeBaseInfoItem[];
  settingName: string;
}

const ProductTour: React.FC<ProductTourProps> = ({
  title,
  text,
  infoItems,
  settingName,
}) => {
  const { t } = useTranslation();
  const { setToggledSetting, getToggledSetting } = useLocalStorageSettings();
  const [show, setShow] = useState(!getToggledSetting(settingName));

  const toggleShow = () => {
    setToggledSetting(settingName, true);
    setShow(false);
  };

  if (show) {
    return (
      <div className={'productTour'}>
        <Card bigScreenWidth={'100%'} helperCSSClass={'productTour-card'}>
          <CardSection
            title={title}
            action={{
              action: toggleShow,
              cta: t('actions.dont_show_again'),
            }}
          >
            {text ? <div className={'productTour-text'}>{text}</div> : null}
            <KnowledgeBaseGroup infoItems={infoItems} inline />
          </CardSection>
        </Card>
      </div>
    );
  } else {
    return null;
  }
};

export default ProductTour;
