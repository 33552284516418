import { useState } from 'react';
import { PropertyValidationRuleResponse } from '../../api/petcloudapi/api';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';

const useAdditionalPropertyValidationRules = () => {
  const api = usePetCloudApi();
  const productGroupsApi = api.productGroupsApi();
  const errorHandler = useErrorHandler();

  const [additionalValidationRules, setAdditionalValidationRules] = useState<
    PropertyValidationRuleResponse[] | null | undefined
  >(null);

  const getAdditionalValidationRules = (productGroupId: string) => {
    productGroupsApi
      .productGroupsGetProductGroupById(productGroupId)
      .then((response) => {
        console.log(response);
        setAdditionalValidationRules(
          response.data.additionalPropertyValidationRules
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return {
    additionalValidationRules,
    getAdditionalValidationRules,
  };
};

export default useAdditionalPropertyValidationRules;
