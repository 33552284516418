import './stocksettings.css';
import { useTranslation } from 'react-i18next';
import { Check } from '../../../elements/selectors/Selectors';
import Input from '../../../elements/input/Input';

interface StockSettingsProps {
  restockTimeDays?: number | null;
  inheritedRestockTimeDays?: number | null;
  updateRestockTimeDays: (e: string) => void;
  isCloseout: boolean;
  inheritedIsCloseout?: boolean;
  toggleIsCloseout: () => void;
}

const StockSettings: React.FC<StockSettingsProps> = ({
  restockTimeDays,
  inheritedRestockTimeDays,
  updateRestockTimeDays,
  isCloseout,
  inheritedIsCloseout,
  toggleIsCloseout,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="stocksettings">
        <div className="stocksetting">
          <Input
            key={restockTimeDays?.toString()}
            title={t('view.product.stocksettings.restockTimeDays')}
            content={restockTimeDays ? restockTimeDays.toString() : ''}
            update={updateRestockTimeDays}
            unit={t('units.days')}
            inherited={inheritedRestockTimeDays?.toString()}
            hint={t('view.product.stocksettings.restockTimeDaysHint')}
          />
        </div>
        <div className="stocksetting" style={{ marginTop: 30 }}>
          <Check
            key={isCloseout.toString()}
            checked={isCloseout}
            update={toggleIsCloseout}
            inherited={inheritedIsCloseout}
          />
          <div className="stocksettings-text">
            {t('view.product.stocksettings.isCloseout')}
          </div>
        </div>
      </div>
    </>
  );
};

export default StockSettings;
