import { TranslatedTypeResponseOfString } from '../api/petcloudapi/api';
import { availableLanguages as langs } from '../../locate/languageUtils';

const useLanguages = () => {
  const availableLanguages = langs;

  const emptyTranslatableContent: TranslatedTypeResponseOfString = {
    'de-DE': '',
    'en-GB': '',
  };

  return {
    availableLanguages,
    emptyTranslatableContent,
  };
};

export default useLanguages;
