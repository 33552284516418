import './productmappingtuple.css';
import ProductMappingProduct from '../productmappingproduct/ProductMappingProduct';
import { ReactComponent as IconLinked } from '../../../../../../assets/icon/linked.svg';
import { ReactComponent as IconLink } from '../../../../../../assets/icon/unlinked.svg';
import { ReactComponent as IconArrow } from '../../../../../../assets/icon/right-arrow.svg';
import { ReactComponent as IconProposal } from '../../../../../../assets/icon/special.svg';
import { ReactComponent as IconCheck } from '../../../../../../assets/icon/check.svg';
import { useTranslation } from 'react-i18next';
import { Check } from '../../../../../elements/selectors/Selectors';
import ProductMappingSocket from '../productmappingsocket/ProductMappingSocket';
import { ExtendedProductMapping } from '../ProductMappings';
import { ReactNode } from 'react';

export type ProductMappingTupleGroupBracket =
  | 'start'
  | 'middle'
  | 'end'
  | undefined;

interface ProductMappingTupleProps {
  mapping: ExtendedProductMapping;
  groupBracket?: ProductMappingTupleGroupBracket;
  onSelect?: () => void;
  selected: boolean;
  disabled: boolean;
  setIsDragging?: (bool: boolean) => void;
  isDragging: boolean;
  unlinkDomainProduct: () => void;
  mappings: ExtendedProductMapping[];
  mapProduct: (activeMappingId: string, overMappingId: string) => void;
  onProposalConfirm: () => void;
}

const ProductMappingTuple: React.FC<ProductMappingTupleProps> = ({
  mapping,
  groupBracket,
  onSelect,
  selected,
  disabled,
  setIsDragging,
  isDragging,
  unlinkDomainProduct,
  mappings,
  mapProduct,
  onProposalConfirm,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.productMapping',
  });

  return (
    <div className="productMappingTuple">
      <div className={'productMappingTuple-check'}>
        <Check checked={selected} update={onSelect} disabled={disabled} />
      </div>
      {mapping.shopifyProduct ? (
        <ProductMappingProduct
          item={mapping.shopifyProduct}
          id={`${mapping.id}_integration`}
        />
      ) : (
        <ProductMappingSocket
          type={'empty'}
          id={`${mapping.id}_integration`}
          isDragging={false}
        />
      )}
      {mapping.status === 'proposal' ? (
        <>
          <IconCheck
            className={
              'productMappingTuple-matchStatusButton button button-secondary'
            }
            onClick={onProposalConfirm}
            fill={'var(--color-success)'}
          />
          {matchStatusIcon['proposal']}
        </>
      ) : (
        matchStatusIcon[mapping.status]
      )}
      {mapping.domainProduct ? (
        <div className={'productMappingTuple-productWrapper'}>
          <ProductMappingProduct
            item={mapping.domainProduct}
            id={`${mapping.id}_domain`}
            setIsDragging={setIsDragging}
            unlinkDomainProduct={unlinkDomainProduct}
            isMappable
            showVariantIcon
          />
          <ProductMappingSocket
            type={'create'}
            id={`${mapping.id}_domain`}
            isInBackground
            isDragging={isDragging}
          />
        </div>
      ) : (
        <ProductMappingSocket
          type={'create'}
          id={`${mapping.id}_domain`}
          isDragging={isDragging}
          mappings={mappings}
          mapProduct={mapProduct}
        />
      )}
      {groupBracket ? (
        <div
          className={`productMappingTuple_groupBracket productMappingTuple_groupBracket__${groupBracket}`}
        ></div>
      ) : null}
    </div>
  );
};

export default ProductMappingTuple;

const matchStatusIcon: { [key: string]: ReactNode } = {
  matched: (
    <IconLinked
      className={
        'productMappingTuple-matchStatusIcon productMappingTuple-matchStatusIcon__linked'
      }
    />
  ),
  unlinked: (
    <IconLink
      className={
        'productMappingTuple-matchStatusIcon productMappingTuple-matchStatusIcon__unlinked'
      }
    />
  ),
  create: (
    <IconArrow
      className={
        'productMappingTuple-matchStatusIcon productMappingTuple-matchStatusIcon__create'
      }
    />
  ),
  update: (
    <IconLinked
      className={
        'productMappingTuple-matchStatusIcon productMappingTuple-matchStatusIcon__update'
      }
    />
  ),
  proposal: (
    <IconProposal
      className={
        'productMappingTuple-matchStatusIcon productMappingTuple-matchStatusIcon__proposal'
      }
    />
  ),
};
