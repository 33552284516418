import './mediamanager.css';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import ProductMediaItem from '../../../../elements/productmediaitem/ProductMediaItem';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import {
  AssetResponse,
  ProductAssetResponse,
} from '../../../../api/petcloudapi/api';
import { arrayMoveImmutable } from 'array-move';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';

interface MediaManagerProps {
  assets: any[];
  selectAsset?: (id: string) => void;
  selectAllAssets?: () => void;
  deleteAsset?: (id: string) => void;
  deleteSelected?: (ids: string[]) => void;
  selectedAssets?: any[];
  addAssetsCallback?: (responses: AssetResponse[]) => void;
  sortAssetsCallback?: (assets: any) => void;
}

const MediaManager: React.FC<MediaManagerProps> = ({
  assets,
  selectAsset,
  selectedAssets,
  selectAllAssets,
  deleteAsset,
  deleteSelected,
  addAssetsCallback,
  sortAssetsCallback,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.mediaManager',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (sortAssetsCallback && over) {
      if (active.id !== over.id) {
        const oldPosition = assets.find((a) => a?.id === active.id)?.position;
        const newPosition = assets.find((a) => a?.id === over.id)?.position;
        if (oldPosition !== undefined && newPosition !== undefined) {
          const update = arrayMoveImmutable(assets, oldPosition, newPosition);
          sortAssetsCallback(rePositionByIndex(update));
        }
      }
    }
  };

  const rePositionByIndex = (array: ProductAssetResponse[]) => {
    return array.map((element, i) => {
      return {
        ...element,
        position: i,
      };
    });
  };

  return (
    <div className={'mediaManager'}>
      {selectedAssets && selectedAssets.length > 0 ? (
        <div className="media-actions">
          {selectAllAssets ? (
            <div className="media-action">
              <Button
                cta={t('actions.select_all')}
                look={'secondary'}
                width="minimal"
                action={selectAllAssets}
              />
            </div>
          ) : null}
          {deleteSelected ? (
            <div className="media-action">
              <Button
                cta={t('actions.delete')}
                look="secondary-danger"
                width="minimal"
                action={() => deleteSelected(selectedAssets?.map((x) => x.id))}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={sortAssetsCallback ? handleDragEnd : undefined}
      >
        <SortableContext items={assets} strategy={rectSortingStrategy}>
          <div className="media-assets">
            {assets.map((asset, i) => {
              return (
                <ProductMediaItem
                  key={i + 'current'}
                  isCover={i === 0}
                  asset={asset}
                  onSelect={
                    selectAsset ? () => selectAsset(asset.id) : undefined
                  }
                  onDelete={
                    deleteAsset ? () => deleteAsset(asset.id) : undefined
                  }
                  isSelected={selectedAssets?.includes(asset)}
                />
              );
            })}
            {addAssetsCallback ? (
              <div className="media-assets-dropzone">
                <Dropzone
                  maxFiles={-1}
                  callback={addAssetsCallback}
                  assetContext={'Product'}
                />
              </div>
            ) : null}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default MediaManager;
