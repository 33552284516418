import { useCallback } from 'react';
import _ from 'lodash';

const useDebounce = (callback?: (...args: any[]) => void) => {
  return useCallback(
    _.debounce((callback: () => void) => {
      callback();
    }, 500),
    [callback]
  );
};

export default useDebounce;
