import './analyticconstituentscell.css';
import { ProductAnalyticConstituentResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';

interface AnalyticConstituentsCellProps {
  constituents: ProductAnalyticConstituentResponse[];
  selectCellElement: (element: ProductAnalyticConstituentResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const AnalyticConstituentsCell: React.FC<AnalyticConstituentsCellProps> = ({
  constituents,
  selectCellElement,
  isSelectedCellElement,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={'analyticConstituentsCell'}>
      {constituents?.map((constituent, i) => {
        return (
          <div
            key={i}
            className={`analyticConstituentsCell-constituent jtce-bubble ${
              isSelectedCellElement(constituent) ? 'jtce-bubble__selected' : ''
            }`}
            onClick={() => selectCellElement(constituent)}
          >
            {constituent.name[i18n.language as TranslatedStringIndex]}
            {' | '}
            {constituent.value}%
          </div>
        );
      })}
    </div>
  );
};

export default AnalyticConstituentsCell;
