import './returnpositionsedit.css';
import { useTranslation } from 'react-i18next';
import { ProductStockQuality } from '../../../../api/petcloudapi/api';
import DatePicker from '../../../../elements/datepicker/DatePicker';
import Input from '../../../../elements/input/Input';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import PositionEntry from '../../../../types/PositionEntry';

interface ReturnPositionsEditProps {
  returnPositions: PositionEntry[];
  updatePositions: (returnPositions: PositionEntry[]) => void;
}

const ReturnPositionsEdit: React.FC<ReturnPositionsEditProps> = ({
  returnPositions,
  updatePositions,
}) => {
  const { t } = useTranslation();

  const qualityOptions = [
    {
      id: 'Normal',
      name: t('view.order.returns.position.qualities.Normal'),
    },
    {
      id: 'Defective',
      name: t('view.order.returns.position.qualities.Defective'),
    },
    {
      id: 'Dirty',
      name: t('view.order.returns.position.qualities.Dirty'),
    },
  ];

  return (
    <div className="returnpositionsedit">
      {returnPositions.map((position, i) => {
        return (
          <div key={i} className="returnpositionsedit-position">
            <div className="returnpositionsedit-position-header">
              <div className="returnpositionsedit-position-header-title">
                {position.name}
              </div>
              <div className="returnpositionsedit-position-header-element">
                <div className="list-table-td-monoSpaced">{position.ean}</div>
              </div>
            </div>
            <div className="global-inputGroup">
              <div className="global-inputGroup-input">
                <Input
                  title={t('view.order.returns.position.quantity')}
                  content={position.quantity.toString()}
                  update={(e) => {
                    const update = [...returnPositions];
                    update[i] = {
                      ...update[i],
                      quantity: parseInt(e),
                    };
                    updatePositions(update);
                  }}
                  type={'number'}
                  required
                />
              </div>
              <div className="global-inputGroup-input">
                <Input
                  title={t('view.order.returns.position.batchNumber')}
                  content={position.batchNumber}
                  update={(e) => {
                    const update = [...returnPositions];
                    update[i] = {
                      ...update[i],
                      batchNumber: e,
                    };
                    updatePositions(update);
                  }}
                />
              </div>
              <div className="global-inputGroup-input">
                <DatePicker
                  title={t('view.order.returns.position.bestBefore')}
                  selected={
                    position.bestBefore ? new Date(position.bestBefore) : null
                  }
                  onChange={(date) => {
                    const update = [...returnPositions];
                    const iso = date?.toISOString();
                    update[i] = {
                      ...update[i],
                      bestBefore: iso?.substring(0, iso?.indexOf('T')),
                    };
                    updatePositions(update);
                  }}
                />
              </div>
            </div>
            <div className="global-inputGroup">
              <div className="global-inputGroup-input">
                <Dropdown
                  title={t('view.order.returns.position.quality')}
                  optionObjects={qualityOptions}
                  selected={
                    qualityOptions.find((o) => o.id === position.quality)
                      ?.name ?? qualityOptions[0].id
                  }
                  update={(e) => {
                    const update = [...returnPositions];
                    const value =
                      e.target.selectedOptions[0].getAttribute('data-value');
                    if (value) {
                      update[i] = {
                        ...update[i],
                        quality: value as ProductStockQuality,
                      };
                      updatePositions(update);
                    }
                  }}
                  defaultOptionText={t(
                    'view.order.returns.position.optionalDropdown'
                  )}
                />
              </div>
              <div className="global-inputGroup-input">
                <DatePicker
                  title={t('view.order.returns.position.returnDate')}
                  selected={
                    position.returnDate ? new Date(position.returnDate) : null
                  }
                  onChange={(date) => {
                    const update = [...returnPositions];
                    const iso = date?.toISOString();
                    update[i] = {
                      ...update[i],
                      returnDate: iso?.substring(0, iso?.indexOf('T')),
                    };
                    updatePositions(update);
                  }}
                />
              </div>
            </div>
            <div className="global-inputGroup">
              <div className="global-inputGroup-input">
                <Input
                  title={t('view.order.returns.position.notes')}
                  content={position.notes}
                  update={(e) => {
                    const update = [...returnPositions];
                    update[i] = {
                      ...update[i],
                      notes: e,
                    };
                    updatePositions(update);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReturnPositionsEdit;
