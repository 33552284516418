import './jsontable.css';
import { ReactComponent as CheckIcon } from '../../../assets/icon/check_slim.svg';
import {
  HTMLInputTypeAttribute,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/button/Button';
import JsonTableCell from './jsontablecell/JsonTableCell';
import ToggleSwitch from '../../elements/toggleswitch/ToggleSwitch';
import _ from 'lodash';
import { ReactComponent as IconAdd } from '../../../assets/icon/plus.svg';
import { ReactComponent as IconClear } from '../../../assets/icon/close_thick.svg';
import { SmallHint } from '../../elements/hint/Hint';
import ItemActions, {
  ItemAction,
} from '../../elements/itemactions/ItemActions';
import { Check } from '../../elements/selectors/Selectors';
import ToolsMenu from '../list/listcontrols/toolsmenu/ToolsMenu';
import Popup from '../../elements/popup/Popup';
import InformationBox from '../../elements/informationbox/InformationBox';
import {
  BulkEditorProductMode,
  BulkUpdateInstruction,
} from '../../view/products/BulkEditor';
import KeyHints, { KeyHint } from '../../elements/keyhints/KeyHints';
import HeaderConfigSettings from './headerconfigsettings/HeaderConfigSettings';
import HeaderConfigLoader from './headerconfigloader/HeaderConfigLoader';
import HeaderConfigSave from './headerconfigsave/HeaderConfigSave';
import { columnIcons } from '../../sections/productbulkeditor/utils/configurations';
import TableCompletionBar, {
  ComplexRequiredFieldConfig,
  RequiredFieldValidationMap,
} from './tablecompletionbar/TableCompletionBar';
import useNotifications from '../../hooks/useNotifications';
import JsonTableSearch from './jsontablesearch/JsonTableSearch';
import { SearchHeaderFunctionDict } from '../../sections/productbulkeditor/utils/useSearchConfig';

export type EditorUpdateCallbackInstruction = {
  value: any;
  headerKey?: string;
};

export type EditorUpdateCallback = (
  instructions: EditorUpdateCallbackInstruction[]
) => void;
export type EditorErrorCallback = (error: unknown, asWarning?: boolean) => void;

export type CustomCellConfig = {
  renderMethod:
    | 'default'
    | ((
        value: any,
        updateCallback: EditorUpdateCallback,
        item: any,
        setError: EditorErrorCallback,
        removeError: () => void,
        selectCellElement: (element: any) => void,
        isSelectedCellElement: (element: any) => boolean
      ) => ReactNode);
  editor?: (
    value: any,
    updateCallback: EditorUpdateCallback,
    item: any,
    setError: EditorErrorCallback,
    removeError: () => void
  ) => ReactNode;
  technicalEditor?: (
    value: any,
    updateCallback: EditorUpdateCallback
  ) => ReactNode;
  parseJSON?: boolean;
  valueDependentColumns?: string[];
  condition?: (item: any) => boolean;
  parseValueFunc?: (v: any) => any;
  inputType?: HTMLInputTypeAttribute;
  validationDescriptions?: (item: any, key: string) => string[];
  cellElementSelectionForbidden?: boolean;
};

export type JsonTableSelectedRow = {
  index: number;
  cells: JsonTableSelectedCell[];
};

export type JsonTableSelectedCell = {
  index: number;
  key: string;
};

export type JsonTableHeader = {
  id: string;
  title: string;
  width: number;
  disabled?: boolean;
  hidden: boolean;
  pinned: boolean;
  hint?: string;
};

export type JsonTableCellError = {
  cellId: string;
  error: unknown;
  isWarning: boolean;
};

export type JsonTableDepencies = {
  itemIndex: number;
  dependencies: string[];
};

export type SelectedCellElement = {
  header: string;
  compatibleHeaders?: string[];
  element: any;
};

interface JsonTableProps {
  itemsCount: number;
  items: {
    data: any[];
    groupings?: { [parentId: string]: any[] };
  };
  defaultSearchableHeaders: string[];
  headers: JsonTableHeader[];
  setHeaders: (headers: JsonTableHeader[]) => void;
  hiddenHeadersKeys?: string[];
  rowHeight: number;
  defaultColumnWidth: number;
  viewportHeight?: number;
  rowRenderOffset: number;
  addRow?: () => void;
  deleteRow?: (index: number) => void;
  customCellsConfig?: { [key: string]: CustomCellConfig };
  updateItem?: (
    itemId: string,
    instructions: EditorUpdateCallbackInstruction[]
  ) => void;
  bulkUpdateItem?: (instructions: BulkUpdateInstruction[]) => void;
  onCopy?: (selection: JsonTableSelectedRow[]) => void;
  onPaste?: (
    selection: JsonTableSelectedRow[],
    isCellElement?: boolean
  ) => void;
  itemActions?: ItemAction[];
  toolsMenuActions?: ItemAction[];
  onSelect?: (itemId: any) => void;
  selectedIds?: string[];
  onClearSelectedIds?: () => void;
  ctas?: React.ReactNode[];
  actions?: React.ReactNode[];
  adjustHeightToViewportOffset?: number;
  copyCellElement: (element: SelectedCellElement) => void;
  copiedCellElement?: SelectedCellElement | null;
  deleteCellElement: (
    location: JsonTableSelectedRow,
    element: SelectedCellElement
  ) => void;
  keyHints?: KeyHint[];
  enableValidation?: boolean;
  requiredColumns?: string[];
  complexRequiredFieldConfigs?: ComplexRequiredFieldConfig[];
  requiredFieldValidationMap?: RequiredFieldValidationMap;
  searchHeaderWhitelist: string[];
  searchHeaderFunctions?: SearchHeaderFunctionDict;
}

const JsonTable: React.FC<JsonTableProps> = ({
  itemsCount,
  items,
  defaultSearchableHeaders,
  headers,
  setHeaders,
  hiddenHeadersKeys,
  rowHeight,
  defaultColumnWidth,
  viewportHeight,
  rowRenderOffset,
  addRow,
  deleteRow,
  customCellsConfig,
  updateItem,
  bulkUpdateItem,
  onCopy,
  onPaste,
  itemActions,
  toolsMenuActions,
  onSelect,
  selectedIds,
  onClearSelectedIds,
  ctas,
  actions,
  adjustHeightToViewportOffset,
  copyCellElement,
  copiedCellElement,
  deleteCellElement,
  keyHints,
  enableValidation,
  requiredColumns,
  complexRequiredFieldConfigs,
  requiredFieldValidationMap,
  searchHeaderWhitelist,
  searchHeaderFunctions,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.jsonTable',
  });
  const { pushNotification } = useNotifications();
  const [errors, setErrors] = useState<JsonTableCellError[]>([]);
  const [technicalEditor, setTechnicalEditor] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [selection, setSelection] = useState<JsonTableSelectedRow[] | null>(
    null
  );
  const [cursorLocation, setCursorLocation] =
    useState<JsonTableSelectedRow | null>(null);
  const [shiftStartLocation, setShiftStartLocation] =
    useState<JsonTableSelectedRow | null>(null);
  const [keyShift, setKeyShift] = useState(false);
  const [keyAlt, setKeyAlt] = useState(false);
  const [renderIndex, setRenderIndex] = useState(0);
  const [isKeyEventsEnabled, setIsKeyEventsEnabled] = useState(true);
  const [headerToDrag, setHeaderToDrag] = useState<{
    key: string;
    clientX: number;
  } | null>(null);
  const [dependentColumnsWarning, setDependentColumnsWarning] = useState<
    JsonTableDepencies[] | null
  >(null);
  const jsonTableRef = useRef<HTMLDivElement>(null);
  const [selectedCellElement, setSelectedCellElement] =
    useState<SelectedCellElement | null>(null);
  const [searchResultIds, setSearchResultIds] = useState<string[]>([]);

  const getViewportAdjustedHeight = () => {
    const el = jsonTableRef.current;
    if (el) {
      const elRect = el.getBoundingClientRect();
      return (
        window.innerHeight - elRect.top - (adjustHeightToViewportOffset ?? 0)
      );
    }
  };

  const setCellError = (
    cellId: string,
    errors: JsonTableCellError[],
    error: unknown,
    asWarning?: boolean
  ) => {
    if (!errors.find((err) => err.cellId === cellId)) {
      errors.push({
        cellId: cellId,
        error: error,
        isWarning: asWarning ?? false,
      });
      setErrors(errors);
      if (!asWarning) {
        Store.addNotification({
          message: t('notifications.jsonParseError') + ' ' + error,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
      }
    }
  };

  const removeCellError = (cellId: string, errors: JsonTableCellError[]) => {
    console.log('removing cell error ' + cellId);
    const i = errors.findIndex((err) => err.cellId === cellId);
    if (i !== -1) {
      errors.splice(i, 1);
      setErrors(errors);
    }
  };

  const handleShift = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        if (keyShift) {
          setKeyShift(false);
        } else {
          setKeyShift(true);
          if (cursorLocation) {
            setShiftStartLocation({ ...cursorLocation });
          }
        }
      }
    },
    [keyShift, cursorLocation]
  );

  const handleAlt = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        if (keyAlt) {
          setKeyAlt(false);
        } else {
          setKeyAlt(true);
        }
      }
    },
    [keyShift, cursorLocation]
  );

  const handleAltMouseClick = () => {
    console.log(keyAlt);
    if (!keyAlt) {
      setSelectedCellElement(null);
    }
  };

  const handleCopyPaste = useCallback(
    (e: KeyboardEvent) => {
      if (isKeyEventsEnabled) {
        if ((e.ctrlKey || e.metaKey) && selection) {
          if (e.key === 'c') {
            if (onCopy) {
              if (selectedCellElement) {
                copyCellElement(selectedCellElement);
              } else {
                onCopy(selection);
              }
            }
          }
          if (e.key === 'v') {
            if (onPaste) {
              const locKey = selection[0].cells[0].key;
              console.log(locKey);
              console.log(copiedCellElement?.compatibleHeaders);
              if (
                e.shiftKey &&
                (locKey === copiedCellElement?.header ||
                  copiedCellElement?.compatibleHeaders?.includes(locKey))
              ) {
                onPaste(selection, true);
              } else {
                onPaste(selection);
              }
            }
          }
        }
      }
    },
    [selection, isKeyEventsEnabled, selectedCellElement]
  );

  const handleMouseCellSelect = useCallback(
    (location: JsonTableSelectedRow) => {
      if (!keyShift) {
        setSelection([{ ...location }]);
        setCursorLocation(location);
      } else if (shiftStartLocation) {
        setCursorLocation(location);
        multiCellSelect(shiftStartLocation, location);
      }

      //display warning if cell elements are forbidden to be selected
      if (keyAlt) {
        if (customCellsConfig) {
          const key = location.cells[0].key;
          if (customCellsConfig[key].cellElementSelectionForbidden) {
            pushNotification(
              t('notifications.cellElementSelectionForbidden'),
              'warning'
            );
          }
        }
      }
    },
    [shiftStartLocation, keyShift, keyAlt]
  );

  const multiCellSelect = useCallback(
    (startLoc: JsonTableSelectedRow, endLoc: JsonTableSelectedRow) => {
      console.log(startLoc.cells[0].index, endLoc.cells[0].index);
      const rows: JsonTableSelectedRow[] = [];
      const selectRowsBackwards = endLoc.index < startLoc.index;
      const selectColumnsBackWards =
        endLoc.cells[0].index < startLoc.cells[0].index;
      for (
        let i = startLoc.index;
        selectRowsBackwards ? i >= endLoc.index : i <= endLoc.index;
        selectRowsBackwards ? i-- : i++
      ) {
        const columns: JsonTableSelectedCell[] = [];
        for (
          let j = startLoc.cells[0].index;
          selectColumnsBackWards
            ? j >= endLoc.cells[0].index
            : j <= endLoc.cells[0].index;
          selectColumnsBackWards ? j-- : j++
        ) {
          columns.push({
            index: j,
            key: headers[j].id,
          });
        }
        rows.push({
          index: i,
          cells: _.sortBy(columns, ['index']),
        });
      }
      const sortedRows = _.sortBy(rows, ['index']);
      console.log(sortedRows);
      setSelection(sortedRows);
    },
    []
  );

  const isNavigationKey = (key: string) => {
    return ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(
      key
    );
  };

  const clampValue = (value: number, max: number) => {
    console.log(value);
    if (value >= 0) {
      if (value <= max) {
        return value;
      } else {
        console.log(max);
        return max;
      }
    } else {
      return 0;
    }
  };

  // this deletes cell values and recursively delete all cell values of dependant cells
  const getDeletionDependencies = (selection: JsonTableSelectedRow[]) => {
    const deps: JsonTableDepencies[] = [];

    const getDependantColumns = (k: string, arr: string[]) => {
      const d = customCellsConfig?.[k]?.valueDependentColumns;
      if (d) {
        d.forEach((x) => {
          arr.push(x);
          getDependantColumns(x, arr);
        });
      }
    };

    selection.forEach((s) => {
      s.cells.forEach((cell) => {
        const valueDependentColumns = [cell.key];
        getDependantColumns(cell.key, valueDependentColumns);

        if (valueDependentColumns.length > 1) {
          deps.push({
            itemIndex: s.index,
            dependencies: valueDependentColumns,
          });
        }
      });
    });

    return deps;
  };

  const deleteCellAndDependencies = () => {
    if (dependentColumnsWarning && bulkUpdateItem) {
      const instructions: BulkUpdateInstruction[] = [];
      dependentColumnsWarning.forEach((dep) => {
        dep.dependencies.forEach((key) => {
          instructions.push({
            rowIndex: dep.itemIndex,
            columnKey: key,
            value: null,
          });
        });
      });
      bulkUpdateItem(instructions);
      setDependentColumnsWarning(null);
    }
  };

  const handleKeys = useCallback(
    (e: KeyboardEvent) => {
      if (isNavigationKey(e.key)) {
        e.stopPropagation();
      }
      if (isKeyEventsEnabled) {
        if (cursorLocation && isNavigationKey(e.key)) {
          let newLoc: JsonTableSelectedRow = { ...cursorLocation };
          let newCellIndex: number;
          switch (e.key) {
            case 'ArrowUp':
              newLoc = {
                ...cursorLocation,
                index: clampValue(cursorLocation.index - 1, itemsCount - 1),
              };
              break;
            case 'ArrowDown':
              newLoc = {
                ...cursorLocation,
                index: clampValue(cursorLocation.index + 1, itemsCount - 1),
              };
              break;
            case 'ArrowLeft':
              newCellIndex = clampValue(
                cursorLocation.cells[0].index - 1,
                headers.length - 1
              );
              newLoc = {
                ...cursorLocation,
                cells: [
                  {
                    ...cursorLocation.cells[0],
                    index: newCellIndex,
                    key: headers[newCellIndex].id,
                  },
                ],
              };
              break;
            case 'ArrowRight':
              newCellIndex = clampValue(
                cursorLocation.cells[0].index + 1,
                headers.length - 1
              );
              newLoc = {
                ...cursorLocation,
                cells: [
                  {
                    ...cursorLocation.cells[0],
                    index: newCellIndex,
                    key: headers[newCellIndex].id,
                  },
                ],
              };
              break;
          }
          if (e.shiftKey && shiftStartLocation) {
            multiCellSelect(shiftStartLocation, newLoc);
            setCursorLocation(newLoc);
          } else {
            setSelection([newLoc]);
            setCursorLocation(newLoc);
          }
        } else if (cursorLocation) {
          if (e.key === 'Backspace') {
            // deletion of the selected cell element, if users have the shift key pressed
            if (e.shiftKey && selectedCellElement) {
              deleteCellElement(cursorLocation, selectedCellElement);
            } else if (selection) {
              const deps = getDeletionDependencies(selection);
              if (deps.length > 0) {
                setDependentColumnsWarning(deps);
              } else {
                if (bulkUpdateItem) {
                  const instructions: BulkUpdateInstruction[] = [];
                  selection.forEach((sel) => {
                    sel.cells.forEach((cell) => {
                      instructions.push({
                        rowIndex: sel.index,
                        columnKey: cell.key,
                        value: null,
                      });
                    });
                  });
                  bulkUpdateItem(instructions);
                }
              }
            }
          }
        }
      }
    },
    [shiftStartLocation, cursorLocation, isKeyEventsEnabled, selection]
  );

  const isCellTarget = (event: MouseEvent): boolean => {
    let currentNode = event.target as Node | null;

    while (currentNode) {
      if (
        currentNode instanceof Element &&
        currentNode.classList.contains('jsontable-cell')
      ) {
        return true;
      }
      currentNode = currentNode.parentNode;
    }
    return false;
  };

  const handleMouseDown = (e: MouseEvent) => {
    if (!isCellTarget(e)) {
      setSelection(null);
    }
  };

  const handleColumnDrag = useCallback(
    (e: MouseEvent) => {
      if (headerToDrag) {
        const i = headers.findIndex((h) => h.id === headerToDrag.key);
        if (i !== -1) {
          const update = [...headers];
          console.log(e.clientX, headerToDrag.clientX);
          const width =
            (headers[i].width ?? defaultColumnWidth) +
            (e.clientX - headerToDrag.clientX);
          if (width > 0) {
            update[i] = {
              ...update[i],
              width: width,
            };
          }
          setHeaders(update);
        }
      }
    },
    [headerToDrag]
  );

  const handleColumnDragEnd = useCallback(() => {
    setHeaderToDrag(null);
  }, [headerToDrag]);

  useEffect(() => {
    document.addEventListener('keydown', handleShift, false);
    document.addEventListener('keyup', handleShift, false);
    document.addEventListener('keydown', handleAlt, false);
    document.addEventListener('keyup', handleAlt, false);
    document.addEventListener('mousedown', handleMouseDown, false);
    document.addEventListener('selectstart', () => false, false);
    document.addEventListener('keydown', handleCopyPaste, false);
    document.addEventListener('keydown', handleKeys, false);
    document.addEventListener('mousemove', handleColumnDrag, false);
    document.addEventListener('mouseup', handleColumnDragEnd, false);

    return () => {
      document.removeEventListener('keydown', handleShift, false);
      document.removeEventListener('keyup', handleShift, false);
      document.removeEventListener('keydown', handleAlt, false);
      document.removeEventListener('keyup', handleAlt, false);
      document.removeEventListener('mousedown', handleMouseDown, false);
      document.removeEventListener('selectstart', () => false, false);
      document.removeEventListener('keydown', handleCopyPaste, false);
      document.removeEventListener('keydown', handleKeys, false);
      document.removeEventListener('mousemove', handleColumnDrag, false);
      document.removeEventListener('mouseup', handleColumnDragEnd, false);
    };
  }, [
    handleShift,
    handleCopyPaste,
    handleKeys,
    handleColumnDrag,
    handleColumnDragEnd,
  ]);

  const toggleKeyEvents = (bool: boolean) => {
    console.log('toggle key events: ' + bool);
    setIsKeyEventsEnabled(bool);
  };

  const handleScroll = (scrollTop: number) => {
    const r = Math.floor(scrollTop / (rowHeight + 1));
    setRenderIndex(r);
  };

  const tableHeight = itemsCount * (rowHeight + 1);

  const renderRow = (
    item: any,
    itemIndex: number,
    isVariant?: boolean,
    groupBracket?: 'start' | 'middle' | 'end'
  ) => {
    const dataItem = items.data.find((x) => x.id === item.id);
    const requiredFieldsForThisRow: string[] = [];
    complexRequiredFieldConfigs?.forEach((config) => {
      if (config.conditionFunc(item)) {
        config.requiredFields.forEach((field) => {
          requiredFieldsForThisRow.push(field);
        });
      }
    });
    if (dataItem) {
      if (
        itemIndex <= renderIndex + rowRenderOffset &&
        itemIndex >= renderIndex - rowRenderOffset
      ) {
        return (
          <tr
            key={`row-${itemIndex}`}
            style={{
              top: itemIndex * (rowHeight + 1),
              height: rowHeight,
              maxHeight: rowHeight,
              backgroundColor: isVariant
                ? 'rgba(142, 130, 239, 0.05)'
                : 'transparent',
            }}
          >
            <td
              key={'cellIndex'}
              className={`cell-index jsontable-table-cellIndex ${
                groupBracket
                  ? `jsontable-table-cellIndex-groupBracket jsontable-table-cellIndex-groupBracket__${groupBracket}`
                  : ''
              }`}
            >
              <div className={'cell-index-content'}>
                <div className={'cell-index-content-number'}>
                  {itemIndex + 1}
                </div>
                {onSelect && selectedIds ? (
                  <div className={'cell-index-content-check'}>
                    <Check
                      checked={selectedIds.includes(item.id)}
                      update={() => onSelect(item.id)}
                    />
                  </div>
                ) : null}
                {item.mode !== null ? (
                  <div
                    className={`cell-index-content-mode ${
                      item.mode === BulkEditorProductMode.Create
                        ? 'cell-index-content-mode-create'
                        : 'cell-index-content-mode-update'
                    }`}
                  >
                    {t(`mode.${item.mode}`)}
                  </div>
                ) : null}
              </div>
              {addRow && deleteRow && !itemActions ? (
                <div
                  className={'jsontable-table-cellIndex-actions'}
                  style={
                    isVariant
                      ? { backgroundColor: 'var(--color-inherited_background)' }
                      : undefined
                  }
                >
                  <ItemActions
                    item={item}
                    actions={[
                      {
                        cta: t('actions.addRow'),
                        ctaAlreadyTranslated: true,
                        look: 'blue',
                        action: () => addRow(),
                      },
                      {
                        cta: t('actions.deleteRow'),
                        ctaAlreadyTranslated: true,
                        look: 'danger',
                        action: () => deleteRow(itemIndex),
                      },
                    ]}
                    xOffset={30}
                  />
                </div>
              ) : null}
              {itemActions ? (
                <div className={'jsontable-table-cellIndex-actions'}>
                  <ItemActions item={item} actions={itemActions} xOffset={30} />
                </div>
              ) : null}
            </td>
            {filteredHeaders.map((header, keyIndex) => {
              if (!hiddenHeadersKeys?.includes(header.id)) {
                const cellId = itemIndex + header.id;
                const width = header.width ?? defaultColumnWidth;
                const value = dataItem[header.id];
                const error = errors.find((err) => err.cellId === cellId);
                const conditionFunc = customCellsConfig?.[header.id]?.condition;
                const enabled = conditionFunc ? conditionFunc(dataItem) : true;
                return (
                  <td
                    key={cellId}
                    className={`jsontable-table-td ${
                      header.pinned ? 'jsontable-table-td__sticky' : ''
                    }`}
                    onClick={() => {
                      handleMouseCellSelect({
                        index: itemIndex,
                        cells: [
                          {
                            index: keyIndex,
                            key: header.id,
                          },
                        ],
                      });
                      handleAltMouseClick();
                    }}
                    style={{
                      minWidth: width,
                      width: width,
                      maxWidth: width,
                      height: rowHeight,
                      maxHeight: rowHeight,
                      left: header.pinned ? getStickyPosLeft(header.id) : 0,
                    }}
                  >
                    <SmallHint
                      key={cellId}
                      paragraphs={
                        enabled && error ? [error.error as string] : undefined
                      }
                      isToolTip
                    >
                      <JsonTableCell
                        cellId={cellId}
                        headerId={header.id}
                        height={rowHeight}
                        item={dataItem}
                        value={value}
                        selected={
                          !!selection?.find(
                            (s) =>
                              s.index === itemIndex &&
                              s.cells.find((c) => c.index === keyIndex)
                          )
                        }
                        setCellError={(cellId, error, asWarning) =>
                          setCellError(cellId, errors, error, asWarning)
                        }
                        removeCellError={(cellId) =>
                          removeCellError(cellId, errors)
                        }
                        error={error}
                        updateItem={updateItem}
                        customCellConfig={customCellsConfig?.[header.id]}
                        technicalEditor={technicalEditor}
                        disabled={header.disabled || !enabled}
                        toggleKeyEvents={toggleKeyEvents}
                        selectCellElement={setSelectedCellElement}
                        selectedCellElement={selectedCellElement}
                        requiredFields={requiredFieldsForThisRow}
                        requiredFieldValidationMap={requiredFieldValidationMap}
                        showValidation={showValidation}
                      />
                    </SmallHint>
                  </td>
                );
              } else {
                return null;
              }
            })}
          </tr>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderGroupedRows = () => {
    if (items.groupings) {
      let index = 0;
      return Object.entries(items.groupings).map(([parentId, group]) => {
        return group.map((item: any, i) => {
          const groupBracket =
            i === 0 ? 'start' : i === group.length - 1 ? 'end' : 'middle';
          const row = renderRow(
            item,
            index,
            i > 0,
            group.length > 1 ? groupBracket : undefined
          );
          index++;
          return row;
        });
      });
    } else {
      return null;
    }
  };

  const getToolsMenuActions = (actions: ItemAction[]) => {
    const result = [...actions];
    result.push({
      group: 'columns',
      renderFunc: () => (
        <HeaderConfigSettings headers={headers} setHeaders={setHeaders} />
      ),
    });
    result.push({
      group: 'columns',
      renderFunc: () => (
        <HeaderConfigLoader setHeaders={setHeaders} currentHeaders={headers} />
      ),
    });
    result.push({
      group: 'columns',
      renderFunc: () => <HeaderConfigSave headers={headers} />,
    });
    if (enableValidation) {
      result.push({
        renderFunc: () => (
          <div className={'jsontable-topActions-action'}>
            <ToggleSwitch
              toggled={showValidation}
              toggle={() => setShowValidation(!showValidation)}
              label={t('validation')}
              smallSwitch
              smallLabel
            />
          </div>
        ),
      });
    }
    result.push({
      renderFunc: () => (
        <div className={'jsontable-topActions-action'}>
          <ToggleSwitch
            toggled={technicalEditor}
            toggle={() => setTechnicalEditor(!technicalEditor)}
            label={t('technicalEditor')}
            smallSwitch
            smallLabel
          />
        </div>
      ),
    });
    return result;
  };

  const filteredHeaders = useMemo(() => {
    return headers.filter((h) => !h.hidden);
  }, [headers]);

  // returns the number of pixels that a pinned column should stick to
  // adjust the value that is getting added at the end to the width of the index column should it ever change
  const getStickyPosLeft = useCallback(
    (headerId: string) => {
      const index = headers.findIndex((h) => h.id === headerId);
      const posLeft =
        headers.reduce((prev, next, currentIndex) => {
          if (next.pinned && !next.hidden && currentIndex < index) {
            return prev + (next.width ?? defaultColumnWidth) + 1;
          } else {
            return prev;
          }
        }, 0) + 101;
      console.log(posLeft);
      return posLeft;
    },
    [headers]
  );

  const renderTableBody = () => {
    // prioritize rendering search results
    if (searchResultIds.length > 0) {
      return searchResultIds.map((id, itemIndex) => {
        const item = items.data.find((x) => x.id === id);
        if (item) {
          return renderRow(item, itemIndex);
        } else {
          return null;
        }
      });
    }
    // otherwise render grouped data
    if (items.groupings) {
      return renderGroupedRows();
    }
    // fallback to rendering the data without groups
    return items.data.map((item, itemIndex) => {
      return renderRow(item, itemIndex);
    });
  };

  return (
    <>
      <div ref={jsonTableRef} className={'jsontable'}>
        <div className={'jsontable-topActions'}>
          <div className={'jsontable-topActions-group'}>
            {toolsMenuActions ? (
              <div className={'jsontable-topAction'}>
                <ToolsMenu
                  active={true}
                  actions={getToolsMenuActions(toolsMenuActions)}
                />
              </div>
            ) : null}
            <div className={'jsontable-topAction'}>
              <JsonTableSearch
                items={items.data}
                currentSearchResultIds={searchResultIds}
                onSearchResults={setSearchResultIds}
                headers={headers}
                headerWhitelist={searchHeaderWhitelist}
                defaultSearchHeaders={defaultSearchableHeaders}
                searchHeaderFunctions={searchHeaderFunctions}
              />
            </div>
            <div className={'jsontable-topAction'}>
              <div className={'jsontable-topAction-label'}>{`${itemsCount} ${t(
                'dataCount'
              )}`}</div>
            </div>
            <div className={'jsontable-topAction'}>
              <div className={'jsontable-topAction-label'}>{`${
                headers.length
              } ${t('columnsCount')}`}</div>
            </div>
            {actions}
          </div>
          <div className={'jsontable-topActions-group'}>{ctas}</div>
        </div>
        {showValidation && (requiredColumns || complexRequiredFieldConfigs) ? (
          <TableCompletionBar
            items={items.data}
            requiredFields={requiredColumns}
            complexRequiredFieldConfigs={complexRequiredFieldConfigs}
            requiredFieldValidationMap={requiredFieldValidationMap}
          />
        ) : null}
        <div
          className={'jsontable-table-wrapper'}
          style={{
            height: viewportHeight ?? getViewportAdjustedHeight(),
            maxHeight: viewportHeight ?? getViewportAdjustedHeight(),
          }}
          onScroll={(e) => {
            handleScroll(e.currentTarget.scrollTop);
          }}
        >
          <table className={'table jsontable-headerTable'}>
            <thead>
              <tr className={'jsontable-table-headerRow'}>
                <th
                  key={'columnIndex'}
                  className={'jsontable-table-th jsontable-table-columnIndex'}
                >
                  <div className={'jsontable-table-columnIndex-inner'}>
                    {selectedIds &&
                    onClearSelectedIds &&
                    selectedIds?.length > 0 ? (
                      <Button
                        type={'icon'}
                        width={'tiny'}
                        look={'secondary'}
                        action={onClearSelectedIds}
                        helperCSSClass={
                          'jsontable-table-columnIndex-clearButton'
                        }
                      >
                        <IconClear
                          className={'button-icon button-icon-tertiary'}
                        />
                      </Button>
                    ) : null}
                  </div>
                </th>
                {filteredHeaders.map((header) => {
                  if (!hiddenHeadersKeys?.includes(header.id)) {
                    const width = header.width ?? defaultColumnWidth;
                    return (
                      <th
                        key={header.id}
                        className={`jsontable-table-th ${
                          header.pinned ? 'jsontable-table-th__sticky' : ''
                        }`}
                        style={{
                          minWidth: width,
                          width: width,
                          maxWidth: width,
                          left: header.pinned ? getStickyPosLeft(header.id) : 0,
                        }}
                      >
                        <div
                          className={
                            'table-column-header jsontable-header-cell'
                          }
                        >
                          {columnIcons[header.id]}
                          {header.title}
                          {requiredColumns?.includes(header.id) ? (
                            <div className={'jsontable-header-required'} />
                          ) : null}
                          {header.hint ? (
                            <SmallHint
                              paragraphs={
                                header.hint ? [header.hint] : undefined
                              }
                            />
                          ) : null}
                        </div>
                        <div
                          className={'jsontable-header-sizeHandle'}
                          onMouseDown={(e) => {
                            setHeaderToDrag({
                              key: header.id,
                              clientX: e.clientX,
                            });
                          }}
                        ></div>
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
              </tr>
            </thead>
          </table>
          <table
            className={'table jsontable-table'}
            onMouseDown={() => false}
            onSelect={() => false}
            style={{ height: tableHeight, maxHeight: tableHeight }}
          >
            <tbody>{renderTableBody()}</tbody>
          </table>
        </div>
        <div className={'jsontable-bottomActions'}>
          {addRow ? (
            <Button
              type={'icon'}
              look={'secondary'}
              helperCSSClass={'jsontable-addRowButton'}
              action={() => addRow()}
            >
              <IconAdd className={'button-icon'} />
            </Button>
          ) : null}
          {keyHints ? <KeyHints keyHints={keyHints} /> : null}
        </div>
      </div>
      <Popup
        toggled={!!dependentColumnsWarning}
        width={'10%'}
        close={() => setDependentColumnsWarning(null)}
      >
        <div className={'popup-title'}>{t('dependencyWarning.popupTitle')}</div>
        <InformationBox
          title={t('dependencyWarning.title')}
          type={'warning'}
          content={t('dependencyWarning.text')}
          maxWidth={500}
        />
        {dependentColumnsWarning
          ?.reduce((prev, curr) => {
            curr.dependencies.forEach((dep) => {
              if (!prev.includes(dep)) {
                prev.push(dep);
              }
            });
            return prev;
          }, [] as string[])
          .map((key) => {
            return (
              <InformationBox
                type={'error'}
                content={headers.find((h) => h.id === key)?.title}
              />
            );
          })}
        <div className={'global-cardActions global-cardActions-spaceBetween'}>
          <Button
            cta={t('dependencyWarning.cancel')}
            look={'secondary'}
            width={'minimal'}
            action={() => setDependentColumnsWarning(null)}
          />
          <Button
            cta={t('dependencyWarning.cta')}
            look={'secondary-danger'}
            width={'minimal'}
            action={deleteCellAndDependencies}
          />
        </div>
      </Popup>
    </>
  );
};

export default JsonTable;

export const safelyParseJSON = (
  value: string,
  fallback: string,
  errorCallback: (error: string | null) => void
) => {
  try {
    errorCallback(null);
    return JSON.parse(value);
  } catch (error) {
    errorCallback(error as string);
    return JSON.parse(fallback);
  }
};

interface EditorActionsProps {
  save: () => void;
  buttons?: { cta: string; action: () => void }[];
}

export const EditorActions: React.FC<EditorActionsProps> = ({
  save,
  buttons,
}) => {
  return (
    <div className={'jtce-actions global-cardActions'}>
      {buttons?.map((button) => {
        return (
          <div className={'jtce-action'}>
            <Button
              cta={button.cta}
              action={button.action}
              look={'secondary'}
              width={'full'}
            />
          </div>
        );
      })}
      <div className={'jtce-action'}>
        <Button type={'icon'} width={'full'} look={'save'} action={save}>
          <CheckIcon className={'button-icon button-icon-alwaysWhite'} />
        </Button>
      </div>
    </div>
  );
};
