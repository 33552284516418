import { Has } from '../../contexts/auth/Authorization';
import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import Card, { CardSection } from '../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import PromotionForm from '../../sections/promotions/promotionform/PromotionForm';
import { useEffect, useState } from 'react';
import {
  PromotionResponse,
  UpsertPromotionRequest,
} from '../../api/petcloudapi/api';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import { LoadingContainer } from '../../elements/loading/Loading';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import PromotionCodes from '../../sections/promotions/promotioncodes/PromotionCodes';

const Promotion = () => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const promotionsApi = api.promotionsApi();
  const errorHandler = useErrorHandler();

  const { promotionId } = useParams();
  const [promotion, setPromotion] = useState<UpsertPromotionRequest | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingStatus, setIsSubmittingStatus] = useState(false);

  useEffect(() => {
    getPromotion();
  }, []);

  const getPromotion = () => {
    if (promotionId) {
      promotionsApi
        .promotionsGetPromotionById(promotionId)
        .then((response) => {
          console.log(response);
          const discounts = response.data.discounts?.map((discount) => {
            return {
              ...discount,
              promotedProducts: discount.promotedProducts
                ? discount.promotedProducts.map((p) => p.id)
                : [],
            };
          });
          const request: UpsertPromotionRequest = {
            ...response.data,
            discounts: discounts ?? [],
          };
          setPromotion(request);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const submit = () => {
    if (promotionId && promotion) {
      setIsSubmitting(true);
      promotionsApi
        .promotionsUpdatePromotionById(promotionId, promotion)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.promotion.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const activate = () => {
    if (promotionId) {
      setIsSubmittingStatus(true);
      promotionsApi
        .promotionsActivatePromotionById(promotionId)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.promotion.notifications.activated'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (promotion) {
            setPromotion({ ...promotion, active: true });
          }
          setIsSubmittingStatus(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmittingStatus(false);
        });
    }
  };

  const deactivate = () => {
    if (promotionId) {
      setIsSubmittingStatus(true);
      promotionsApi
        .promotionsDeactivatePromotionById(promotionId)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.promotion.notifications.deactivated'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (promotion) {
            setPromotion({ ...promotion, active: false });
          }
          setIsSubmittingStatus(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmittingStatus(false);
        });
    }
  };

  if (promotion) {
    const codes = (promotion as PromotionResponse).individualCodes;
    return (
      <Has authorizations={['promotions:detail']} showNoAuthorization>
        <Stage>
          <Navigator
            title={promotion.name[i18n.language as TranslatedStringIndex]}
            badges={[
              {
                title: t(
                  `list.states.${promotion.active ? 'Active' : 'Inactive'}`
                ),
                color: promotion.active
                  ? 'var(--color-success)'
                  : 'var(--color-danger)',
              },
            ]}
            allowBackButton
            backLink={'/promotions'}
          >
            <Button
              cta={t('actions.save')}
              look={'save'}
              width={'minimal'}
              action={submit}
              isLoading={isSubmitting}
            />
          </Navigator>
          <StageColumns>
            <StageColumn size={'two'}>
              <Card bigScreenWidth="100%">
                <PromotionForm
                  promotion={promotion}
                  update={(p) => setPromotion(p)}
                />
              </Card>
              {codes && codes.length > 0 ? (
                <Card bigScreenWidth={'100%'}>
                  <CardSection title={t('view.promotion.promotionCodes.title')}>
                    <PromotionCodes codes={codes} />
                  </CardSection>
                </Card>
              ) : null}
            </StageColumn>
            <StageColumn size={'one'}>
              <Card bigScreenWidth={'100%'}>
                <CardSection title={t('view.promotion.status')}>
                  {promotion.active ? (
                    <Button
                      cta={t('actions.deactivate')}
                      look={'secondary-danger'}
                      width={'full'}
                      action={deactivate}
                      isLoading={isSubmittingStatus}
                    />
                  ) : (
                    <Button
                      cta={t('actions.activate')}
                      look={'secondary'}
                      width={'full'}
                      action={activate}
                      isLoading={isSubmittingStatus}
                    />
                  )}
                </CardSection>
              </Card>
            </StageColumn>
          </StageColumns>
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Promotion;
