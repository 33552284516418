import './integrationredirectsshopifybase.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../contexts/auth/User';
import { NoAuthorization } from '../../../../elements/emptystate/EmptyState';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import { useEffect } from 'react';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import useUrlParams from '../../../../hooks/useUrlParams';
import useNotifications from '../../../../hooks/useNotifications';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useCookies } from 'react-cookie';

const IntegrationRedirectsShopifyBase = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'page.integrationRedirectsShopify',
  });
  const api = usePetCloudApi();
  const shopifyAppApi = api.shopifyAppApi();
  const { user } = useUser();
  const { getUrlParam } = useUrlParams();
  const { pushNotification } = useNotifications();
  const errorHandler = useErrorHandler();
  const [cookies] = useCookies([
    'integration_shopify_host',
    'integration_shopify_hmac',
    'integration_shopify_shop',
    'integration_shopify_timestamp',
  ]);

  useEffect(() => {
    if (!user?.isProductOwner) {
      getParams();
    }
  }, []);

  const getParams = () => {
    const host = getUrlParam('host');
    const hmac = getUrlParam('hmac');
    const shop = getUrlParam('shop');
    const timestamp = getUrlParam('timestamp');
    const session = getUrlParam('session');

    if (host && hmac && shop && timestamp) {
      getBase(host, hmac, shop, timestamp, session);
    } else {
      const cookieHost = cookies.integration_shopify_host;
      const cookieHmac = cookies.integration_shopify_hmac;
      const cookieShop = cookies.integration_shopify_shop;
      const cookieTimestamp = cookies.integration_shopify_timestamp;
      const cookieSession = cookies.integration_shopify_timestamp;
      if (cookieHmac && cookieShop && cookieTimestamp && cookieHost) {
        getBase(
          cookieHost,
          cookieHmac,
          cookieShop,
          cookieTimestamp,
          cookieSession
        );
      } else {
        pushNotification(t('notifications.missingURLParams'), 'danger');
      }
    }
  };

  const getBase = (
    host: string,
    hmac: string,
    shop: string,
    timestamp: string,
    session?: string | null
  ) => {
    shopifyAppApi
      .shopifyV1BaseGet(hmac, shop, timestamp, host, session ?? undefined)
      .then((response) => {
        console.log(response);
        const uri = response.data.redirectUri;
        if (uri) {
          window.location.href = uri;
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (user?.isProductOwner) {
    return <NoAuthorization message={t('productOwnerNotAllowed')} />;
  } else {
    return (
      <div className={'main'}>
        <LoadingContainer />
      </div>
    );
  }
};

export default IntegrationRedirectsShopifyBase;
