import './notifications.css';
import { ReactComponent as BellIcon } from '../../../assets/icon/bell.svg';
import { useState } from 'react';
import { LoadingContainer } from '../loading/Loading';
import { useTranslation } from 'react-i18next';

type Notification = {
  type: string;
  link: string;
  msg: string;
  unread: boolean;
};

export const Bell = () => {
  const { t } = useTranslation();

  // make API call here to fetch user notifications
  // const n = [
  //   {
  //     type: 'alert',
  //     link: '/',
  //     msg: 'Das hier ist eine Alert Notification!',
  //     unread: true,
  //   },
  //   {
  //     type: 'warning',
  //     link: '/',
  //     msg: 'Hier steht eine Warnung.',
  //     unread: true,
  //   },
  //   {
  //     type: 'normal',
  //     link: '/',
  //     msg: 'Bloß eine gewöhneliche Information, die jedoch etwas länger ist.',
  //     unread: true,
  //   },
  // ];

  const [notifications] = useState<Notification[] | null>([]);

  const [inboxVisible, setInboxVisible] = useState(false);

  return (
    <div
      className="notifications-bell"
      onClick={() => {
        setInboxVisible(!inboxVisible);
      }}
    >
      <BellIcon
        fill="var(--color-text_tertiary)"
        className="notifications-bell"
      />
      {notifications && notifications.length > 0 ? (
        <div className="notifications-bell-counter">{notifications.length}</div>
      ) : null}
      <div
        className="notifications-inbox"
        style={{ display: inboxVisible ? 'block' : 'none' }}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
      >
        <div
          className="notifications-inbox-before"
          onClick={() => {
            setInboxVisible(!inboxVisible);
          }}
        ></div>
        {notifications ? (
          notifications.length > 0 ? (
            notifications.map((notification, i) => {
              return (
                <div
                  key={i}
                  className="notifications-inbox-msg"
                  style={{
                    backgroundColor:
                      notification.type === 'alert'
                        ? '#fff7f5'
                        : notification.type === 'warning'
                        ? '#fff5ee'
                        : notification.type === 'normal'
                        ? '#f3faff'
                        : 'transparent',
                    borderLeftColor:
                      notification.type === 'alert'
                        ? 'var(--color-alert)'
                        : notification.type === 'warning'
                        ? '#ffa46a'
                        : notification.type === 'normal'
                        ? '#0d7cce'
                        : 'transparent',
                  }}
                >
                  {notification.msg}
                </div>
              );
            })
          ) : (
            <div className="notifications-bell-empty">
              {t('topbar.notifications.empty')}
            </div>
          )
        ) : (
          <LoadingContainer />
        )}
      </div>
    </div>
  );
};

const Notifications = () => {
  return <div className="notifications"></div>;
};

export default Notifications;
