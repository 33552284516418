import './valuecomparison.css';
import { ReactComponent as IndicatorUp } from '../../../assets/icon/indicator_up.svg';
import { ReactComponent as IndicatorDown } from '../../../assets/icon/indicator_down.svg';

interface ValueComparisonProps {
  value: number;
  compareValue: number;
}

const ValueComparison: React.FC<ValueComparisonProps> = ({
  value,
  compareValue,
}) => {
  const getPercentageDifference = (a: number, b: number) => {
    const result = ((a - b) / b) * 100;
    if (Number.isFinite(result)) {
      if (!Number.isNaN(result)) {
        return result.toFixed(0);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };

  return (
    <div className="valuecomparison">
      {value > compareValue ? (
        <>
          <IndicatorUp
            className="valuecomparison-indicator"
            fill="var(--color-success)"
          />
          <div
            className="valuecomparison-value"
            style={{ color: 'var(--color-success)' }}
          >
            {getPercentageDifference(value, compareValue) + '%'}
          </div>
        </>
      ) : (
        <>
          <IndicatorDown
            className="valuecomparison-indicator"
            fill="var(--color-danger)"
          />
          <div
            className="valuecomparison-value"
            style={{ color: 'var(--color-danger)' }}
          >
            {getPercentageDifference(value, compareValue) + '%'}
          </div>
        </>
      )}
    </div>
  );
};

export default ValueComparison;
