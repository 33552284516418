import './promotionform.css';
import {
  DiscountType,
  UpsertPromotionRequest,
} from '../../../api/petcloudapi/api';
import Input from '../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../elements/datepicker/DatePicker';
import { useEffect, useState } from 'react';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import ProductList from '../../../features/productlist/ProductList';
import { ReactComponent as IconTrash } from '../../../../assets/icon/trash.svg';
import { CardSection } from '../../../elements/card/Card';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useUser } from '../../../contexts/auth/User';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useDateTools from '../../../hooks/useDateTools';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import ExcludePromotionsSelect from './excludepromotionsselect/ExcludePromotionsSelect';

interface PromotionFormProps {
  promotion: UpsertPromotionRequest;
  update: (promotion: UpsertPromotionRequest) => void;
}

const PromotionForm: React.FC<PromotionFormProps> = ({ promotion, update }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const {
    dateToISOStringAtMidnight,
    appendTimeZoneOffset,
    getDateOnlyFromDate,
  } = useDateTools();
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();

  const [isUsingMaxRedemption, setIsUsingMaxRedemption] = useState(
    !!(promotion.maxRedemptionsGlobal || promotion.maxRedemptionsPerCustomer)
  );
  const [promotedProducts, setPromotedProducts] = useState<{
    discountIndex: number;
    promotedProducts: string[];
  } | null>(null);
  const [manufacutrersOptions, setManufacturersOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState<
    string | null
  >(promotion.manufacturerId ?? null);

  // this is used as part of the key for discounts
  // after deletion of a discount this gets re-rolled to prevent rendering / memo issues
  const [discountChangeKey, setDiscountChangeKey] = useState(Math.random());

  const getIsSingleDayOnly = () => {
    const validFromDay = promotion.validFrom.split('T')[0];
    const validToDay = promotion.validTo.split('T')[0];
    return validFromDay === validToDay;
  };

  const [isSingleDayOnly, setIsSingleDayOnly] = useState(getIsSingleDayOnly());

  useEffect(() => {
    if (isSingleDayOnly) {
      setSingleDayDates(new Date(promotion.validFrom));
    } else {
      const today = dateToISOStringAtMidnight(new Date());
      if (today) {
        update({
          ...promotion,
          validFrom: appendTimeZoneOffset(today),
          validTo: appendTimeZoneOffset(today),
        });
      }
    }
  }, [isSingleDayOnly]);

  const setSingleDayDates = (date: Date) => {
    const dateOnly = getDateOnlyFromDate(date);
    console.log(dateOnly);
    update({
      ...promotion,
      validFrom: appendTimeZoneOffset(`${dateOnly}T00:00:00.000`),
      validTo: appendTimeZoneOffset(`${dateOnly}T23:59:59.999`),
    });
  };

  useEffect(() => {
    if (user?.isProductOwner) {
      getManufacturers();
    }
  }, []);

  useEffect(() => {
    update({ ...promotion, manufacturerId: selectedManufacturer });
  }, [selectedManufacturer]);

  const getManufacturers = () => {
    manufacturersApi
      .manufacturersGetSimpleManufacturers()
      .then((response) => {
        console.log(response);
        setManufacturersOptions(
          response.data.map((m) => {
            return {
              id: m.id,
              name: m.companyName,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const addDiscount = () => {
    const updatedDiscounts = [...promotion.discounts];
    updatedDiscounts.push({
      type: DiscountType.Percentage,
      value: 0,
      maximumDiscountAmount: null,
      promotedProducts: null,
    });
    update({ ...promotion, discounts: updatedDiscounts });
  };

  const deleteDiscount = (index: number) => {
    const d = [...promotion.discounts];
    d.splice(index, 1);
    update({
      ...promotion,
      discounts: d,
    });
    setDiscountChangeKey(Math.random());
  };

  const updateDiscount = (index: number, key: string, value: any) => {
    const d = [...promotion.discounts];
    d[index] = {
      ...d[index],
      [key]: value,
    };
    update({
      ...promotion,
      discounts: d,
    });
  };

  const togglePromotedProduct = (discountIndex: number, productId: string) => {
    const p = promotion.discounts[discountIndex].promotedProducts ?? [];

    const pIndex = p.indexOf(productId);
    if (pIndex !== -1) {
      p.splice(pIndex, 1);
    } else {
      p.push(productId);
    }

    const d = [...promotion.discounts];
    d[discountIndex] = {
      ...d[discountIndex],
      promotedProducts: [...p],
    };
    update({
      ...promotion,
      discounts: d,
    });
  };

  return (
    <div className={'promotionForm'}>
      <CardSection>
        {manufacutrersOptions ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Dropdown
                optionObjects={manufacutrersOptions}
                selected={
                  manufacutrersOptions.find(
                    (m) => m.id === selectedManufacturer
                  )?.name
                }
                update={(e) => {
                  const id =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  if (id) {
                    setSelectedManufacturer(id);
                  }
                }}
              />
            </div>
          </div>
        ) : null}
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={`${t('view.promotion.name')} (de-DE)`}
              content={promotion.name['de-DE']}
              update={(e) => {
                update({
                  ...promotion,
                  name: {
                    ...promotion.name,
                    'de-DE': e,
                  },
                });
              }}
            />
          </div>
          <div className={'global-inputGroup-input'}>
            <Input
              title={`${t('view.promotion.name')} (en-GB)`}
              content={promotion.name['en-GB']}
              update={(e) => {
                update({
                  ...promotion,
                  name: {
                    ...promotion.name,
                    'en-GB': e,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('view.promotion.priority')}
              content={promotion.priority.toString()}
              update={(e) => {
                update({
                  ...promotion,
                  priority: parseInt(e),
                });
              }}
              type={'number'}
            />
          </div>
        </div>
      </CardSection>
      <CardSection>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Check
              text={t('view.promotion.isSingleDayOnly')}
              checked={isSingleDayOnly}
              update={() => setIsSingleDayOnly(!isSingleDayOnly)}
            />
          </div>
        </div>
        {isSingleDayOnly ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <DatePicker
                title={t('view.promotion.validDay')}
                selected={
                  promotion.validFrom
                    ? new Date(promotion.validFrom)
                    : new Date()
                }
                onChange={(date) => {
                  if (date) {
                    setSingleDayDates(date);
                  }
                }}
                dateFormat={'dd.MM.yyyy'}
              />
            </div>
          </div>
        ) : (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <DatePicker
                title={t('view.promotion.validFrom')}
                selected={
                  promotion.validFrom
                    ? new Date(promotion.validFrom)
                    : new Date()
                }
                onChange={(date) => {
                  const isoString = dateToISOStringAtMidnight(date);
                  if (isoString) {
                    update({
                      ...promotion,
                      validFrom: appendTimeZoneOffset(isoString),
                    });
                  }
                }}
                dateFormat={'dd.MM.yyyy'}
              />
            </div>
            <div className={'global-inputGroup-input'}>
              <DatePicker
                title={t('view.promotion.validTo')}
                selected={
                  promotion.validTo ? new Date(promotion.validTo) : new Date()
                }
                onChange={(date) => {
                  const isoString = dateToISOStringAtMidnight(date);
                  if (isoString) {
                    update({
                      ...promotion,
                      validTo: appendTimeZoneOffset(isoString),
                    });
                  }
                }}
                dateFormat={'dd.MM.yyyy'}
              />
            </div>
          </div>
        )}
      </CardSection>
      <CardSection>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Check
              text={t('view.promotion.isUsingMaxRedemption')}
              checked={isUsingMaxRedemption}
              update={() => setIsUsingMaxRedemption(!isUsingMaxRedemption)}
            />
          </div>
        </div>
        {isUsingMaxRedemption ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('view.promotion.maxRedemptionsGlobal')}
                content={promotion.maxRedemptionsGlobal?.toString()}
                update={(e) => {
                  update({
                    ...promotion,
                    maxRedemptionsGlobal: parseInt(e),
                  });
                }}
                type={'number'}
              />
            </div>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('view.promotion.maxRedemptionsPerCustomer')}
                content={promotion.maxRedemptionsPerCustomer?.toString()}
                update={(e) => {
                  update({
                    ...promotion,
                    maxRedemptionsPerCustomer: parseInt(e),
                  });
                }}
                type={'number'}
              />
            </div>
          </div>
        ) : null}
      </CardSection>
      <CardSection>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Check
              text={t('view.promotion.useIndividualCodes')}
              checked={promotion.useIndividualCodes ?? false}
              update={() =>
                update({
                  ...promotion,
                  useIndividualCodes: !promotion.useIndividualCodes,
                })
              }
            />
          </div>
        </div>
        {promotion.useIndividualCodes ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                key={'individualCodePrefix'}
                title={t('view.promotion.individualCodePrefix')}
                content={promotion.individualCodePrefix}
                update={(e) => {
                  update({
                    ...promotion,
                    individualCodePrefix: e,
                  });
                }}
              />
            </div>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('view.promotion.individualCodeSuffix')}
                content={promotion.individualCodeSuffix}
                update={(e) => {
                  update({
                    ...promotion,
                    individualCodeSuffix: e,
                  });
                }}
              />
            </div>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('view.promotion.individualCodeLength')}
                content={promotion.individualCodeLength?.toString()}
                update={(e) => {
                  update({
                    ...promotion,
                    individualCodeLength: parseInt(e),
                  });
                }}
                type={'number'}
              />
            </div>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('view.promotion.numberOfPromotionCodes')}
                content={promotion.numberOfPromotionCodes?.toString()}
                update={(e) => {
                  update({
                    ...promotion,
                    numberOfPromotionCodes: parseInt(e),
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                key={'code'}
                title={t('view.promotion.code')}
                content={promotion.code}
                update={(e) => {
                  update({
                    ...promotion,
                    code: e,
                  });
                }}
              />
            </div>
          </div>
        )}
      </CardSection>
      <CardSection>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Check
              text={t('view.promotion.isValidForWholeAssortment')}
              checked={promotion.isValidForWholeAssortment ?? false}
              update={() =>
                update({
                  ...promotion,
                  isValidForWholeAssortment:
                    !promotion.isValidForWholeAssortment,
                })
              }
            />
          </div>
        </div>
        <div className={'promotionForm-discounts'}>
          {promotion.discounts.map((discount, i) => {
            return (
              <div
                key={i + discountChangeKey}
                className={'promotionForm-discount'}
              >
                <div className={'promotionForm-discount-inputs'}>
                  <div
                    className={
                      'global-inputGroup promotionForm-discount-inputs-inputGroup'
                    }
                  >
                    <div className={'global-inputGroup-input'}>
                      <Dropdown
                        title={t('view.promotion.discount.type.title')}
                        selected={discount.type}
                        options={['Absolute', 'Percentage']}
                        translationPath={'view.promotion.discount.type.'}
                        update={(e) =>
                          updateDiscount(
                            i,
                            'type',
                            e.target.selectedOptions[0].value
                          )
                        }
                        disabled
                      />
                    </div>
                    <div className={'global-inputGroup-input'}>
                      <Input
                        title={t('view.promotion.discount.value')}
                        content={discount.value.toString()}
                        update={(e) => updateDiscount(i, 'value', e)}
                        unit={discount.type === 'Absolute' ? '€' : '%'}
                        type={'number'}
                      />
                    </div>
                    {discount.type === 'Percentage' ? (
                      <div className={'global-inputGroup-input'}>
                        <Input
                          title={t(
                            'view.promotion.discount.maximumDiscountAmount'
                          )}
                          content={discount.maximumDiscountAmount?.toString()}
                          update={(e) =>
                            updateDiscount(i, 'maximumDiscountAmount', e)
                          }
                          unit={'€'}
                          type={'number'}
                        />
                      </div>
                    ) : null}
                  </div>
                  {!promotion.isValidForWholeAssortment ? (
                    <div className={'promotionForm-discount-inputs-products'}>
                      <Button
                        cta={`${discount.promotedProducts?.length ?? 0} ${t(
                          'view.promotion.discount.promotedProducts'
                        )}`}
                        look={'secondary'}
                        width={'tiny'}
                        action={() =>
                          setPromotedProducts({
                            discountIndex: i,
                            promotedProducts: discount.promotedProducts ?? [],
                          })
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <div
                  className={'promotionForm-discount-delete'}
                  onClick={() => deleteDiscount(i)}
                >
                  <IconTrash
                    className={'promotionForm-discount-delete-icon'}
                    fill={'var(--color-danger)'}
                  />
                </div>
              </div>
            );
          })}
          <Button
            cta={t('view.promotion.addDiscount')}
            look={'secondary'}
            width={'minimal'}
            action={addDiscount}
          />
        </div>
      </CardSection>
      {promotion.preventCombination ? null : (
        <CardSection>
          <ExcludePromotionsSelect promotion={promotion} update={update} />
        </CardSection>
      )}
      {user?.isProductOwner ? (
        <CardSection>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGro2253up-input'}>
              <ToggleSwitch
                label={t('view.promotion.preventCombination')}
                toggled={promotion.preventCombination ?? false}
                toggle={() =>
                  update({
                    ...promotion,
                    preventCombination: !promotion.preventCombination,
                  })
                }
              />
            </div>
          </div>
        </CardSection>
      ) : null}
      {promotedProducts ? (
        <Popup
          width={'80%'}
          toggled={true}
          close={() => setPromotedProducts(null)}
        >
          <div className={'popup-title'}>
            {t('view.promotion.discount.choosePromotedProducts')}
          </div>
          <ProductList
            key={'productList'}
            selectedIds={promotedProducts.promotedProducts}
            selectProductCallback={(p) =>
              togglePromotedProduct(promotedProducts.discountIndex, p.id)
            }
            selectAllProductsCallback={(products) => {
              //as this callback always returns the array of selected products, to de-select products in this component the delta of currently selected products and the callback array needs to be toggled
              const delta: string[] = [];
              products.forEach((product) => {
                if (
                  !promotedProducts.promotedProducts.find(
                    (id) => id === product.id
                  )
                ) {
                  delta.push(product.id);
                }
              });

              delta.forEach((p) => {
                togglePromotedProduct(promotedProducts.discountIndex, p);
              });
            }}
            adjustHeightToViewportOffset={300}
          />
          <div
            className={'global-cardActions promotionForm-productsPopupActions'}
          >
            <Button
              look={'secondary'}
              width={'minimal'}
              cta={'Ok'}
              action={() => setPromotedProducts(null)}
            />
          </div>
        </Popup>
      ) : null}
    </div>
  );
};
export default PromotionForm;
