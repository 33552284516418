import { useState } from 'react';
import Button from '../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { Check } from '../elements/selectors/Selectors';
import useLocalStorageSettings from './useLocalStorageSettings';

const useHelperPopup = () => {
  const { t } = useTranslation();
  const [isShowing, setIsShowing] = useState(false);
  const [shouldNotShowAgain, setShouldNowShowAgain] = useState(false);
  const { setToggledSetting, getToggledSetting } = useLocalStorageSettings();

  const toggleHelperPopup = () => {
    setIsShowing(!isShowing);
  };

  const dontShowThisAgain = (cookieName: string) => {
    setToggledSetting(cookieName, true);
  };

  const dismiss = (cookieName?: string) => {
    toggleHelperPopup();
    if (shouldNotShowAgain && cookieName) {
      dontShowThisAgain(cookieName);
    }
  };

  const renderHelperPopup = (content: React.ReactNode, cookieName?: string) => {
    if (isShowing) {
      if (cookieName) {
        if (getToggledSetting(cookieName)) {
          return null;
        } else {
          return helperPopup(content, cookieName);
        }
      } else {
        return helperPopup(content, cookieName);
      }
    } else {
      return null;
    }
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const helperPopup = (content: React.ReactNode, cookieName?: string) => {
    return (
      <div className={'popup'} onClick={toggleHelperPopup}>
        <div className={'popup-card'} onClick={stopPropagation}>
          <div className={'popup-card-content'}>
            {content}
            <div className={'popup-card-helpOverlayActions global-cardActions'}>
              {cookieName ? (
                <div className={'popup-card-helpOverlayActions-dontShowAgain'}>
                  <Check
                    checked={shouldNotShowAgain}
                    update={() => setShouldNowShowAgain(!shouldNotShowAgain)}
                    text={t('components.helpOverlay.dontShowThisAgain')}
                  ></Check>
                </div>
              ) : null}
              <Button
                cta={t('actions.close')}
                width={'minimal'}
                look={'secondary'}
                action={() => dismiss(cookieName)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return {
    toggleHelperPopup,
    renderHelperPopup,
  };
};

export default useHelperPopup;
