import './checkboxcell.css';
import { Check } from '../../../../../elements/selectors/Selectors';
import { EditorUpdateCallback } from '../../../../../features/jsontable/JsonTable';

interface CheckboxCellProps {
  value: boolean;
  updateCallBack?: EditorUpdateCallback;
}

const CheckboxCell: React.FC<CheckboxCellProps> = ({
  value,
  updateCallBack,
}) => {
  return (
    <div className={'jtce-checkbox'}>
      <Check
        checked={value}
        update={
          updateCallBack ? () => updateCallBack([{ value: !value }]) : undefined
        }
        selectedClassOverride={
          'global-selector-selected jtce-checkbox-selected'
        }
      />
    </div>
  );
};

export default CheckboxCell;
