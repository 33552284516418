import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomerResponse } from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';
import './customerbrief.css';

interface CustomerBriefProps {
  customer: CustomerResponse;
}

const CustomerBrief: React.FC<CustomerBriefProps> = ({ customer }) => {
  const { t } = useTranslation();
  const link = useNavigate();

  const getName = () => {
    let name = '';
    if (customer.salutation) {
      name += customer.salutation + ' ';
    }
    if (customer.title) {
      name += customer.title + ' ';
    }
    name += customer.firstName + ' ' + customer.lastName;
    return name;
  };

  const prepareDataForList = (customer: CustomerResponse) => {
    const entries = [
      {
        title: t('view.payment.customer.name'),
        value: getName(),
      },
      {
        title: t('view.payment.customer.email'),
        value: customer.email,
      },
      {
        title: t('view.payment.customer.company'),
        value: customer.company,
      },
      {
        title: t('view.payment.customer.number'),
        value: customer.customerNumber,
      },
    ];

    return entries;
  };

  return (
    <div className="customerbrief">
      <TitleValueList entries={prepareDataForList(customer)} />
      <div className="customerbrief-actions">
        <Button
          cta={t('view.payment.order.cta')}
          action={() => link('/customers/' + customer.id)}
          look={'secondary'}
          width="minimal"
        />
      </div>
    </div>
  );
};

export default CustomerBrief;
