import './manufacturerconfiguration.css';
import { TFunction } from 'react-i18next';
import Input from '../../../elements/input/Input';
import {
  CreateManufacturerRequest,
  UpdateManufacturerRequest,
} from '../../../api/petcloudapi/api';
import { hasError, Error } from '../../../contexts/errorhandler/ErrorHandler';

interface ManufacturerConfigurationProps {
  t: TFunction<'translation'>;
  updateNewManufacturer?: (manufacturer: CreateManufacturerRequest) => void;
  updateCurrentManufacturer?: (manufacturer: UpdateManufacturerRequest) => void;
  manufacturer: CreateManufacturerRequest | UpdateManufacturerRequest;
  errors?: Error[] | null;
}

const ManufacturerConfiguration: React.FC<ManufacturerConfigurationProps> = ({
  t,
  updateNewManufacturer,
  updateCurrentManufacturer,
  manufacturer,
  errors,
}) => {
  const updateManufacturer = (key: string, e: string) => {
    if (manufacturer) {
      const update = { ...manufacturer, [key]: e };
      if (updateNewManufacturer) {
        updateNewManufacturer(update as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(update as UpdateManufacturerRequest);
      }
    }
  };

  return (
    <div className="manufacturerconfiguration">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            content={manufacturer.shortCode}
            update={(e) => updateManufacturer('shortCode', e)}
            title={t('view.manufacturer.meta.configuration.shortCode')}
            required={true}
            errors={hasError(errors, ['ShortCode'])}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            content={manufacturer.slug}
            update={(e) => updateManufacturer('slug', e)}
            title={t('view.manufacturer.meta.configuration.slug')}
            required={true}
            errors={hasError(errors, ['Slug'])}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            content={manufacturer.customerNumber}
            update={(e) => updateManufacturer('customerNumber', e)}
            title={t('view.manufacturer.meta.configuration.customerNumber')}
            required={true}
            errors={hasError(errors, ['CustomerNumber'])}
            type={'number'}
            minValue={10001}
            maxValue={99999}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            content={manufacturer.termsAndConditionsUrl}
            update={(e) => updateManufacturer('termsAndConditionsUrl', e)}
            title={t(
              'view.manufacturer.meta.configuration.termsAndConditionsUrl'
            )}
            errors={hasError(errors, ['TermsAndConditionsUrl'])}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            content={manufacturer.dataPrivacyUrl}
            update={(e) => updateManufacturer('dataPrivacyUrl', e)}
            title={t('view.manufacturer.meta.configuration.dataPrivacyUrl')}
            errors={hasError(errors, ['DataPrivacyUrl'])}
          />
        </div>
      </div>
    </div>
  );
};

export default ManufacturerConfiguration;
