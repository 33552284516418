import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card, { CardSection, HelperCard } from '../../elements/card/Card';
import Roles from '../../sections/settings/roles/Roles';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { ManufacturerResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import TeamMembers from '../../sections/settings/teammembers/TeamMembers';
import Contacts from '../../sections/settings/contacts/Contacts';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import { useUser } from '../../contexts/auth/User';
import { Stage, Navigator } from '../../layout/stage/Stage';

const Team: React.FC = () => {
  const { user } = useUser();
  const { authorizations } = useAuthorization();
  const { t } = useTranslation();
  const manufacturersApi = usePetCloudApi().manufacturersApi();
  const errorHandler = useErrorHandler();

  const [manufacturer, setManufacturer] = useState<ManufacturerResponse | null>(
    null
  );
  const [manufacturers, setManufacturers] = useState<
    ManufacturerResponse[] | null
  >(null);

  useEffect(() => {
    getManufacturer();
  }, []);

  useEffect(() => {
    getManufacturers();
  }, [authorizations]);

  const getManufacturer = () => {
    if (user && user.manufacturerId) {
      manufacturersApi
        .manufacturersGetManufacturerById(user.manufacturerId)
        .then((response) => {
          console.log(response);
          setManufacturer({ ...response.data });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const getManufacturers = () => {
    if (authorizations?.includes('manufacturers:list')) {
      manufacturersApi
        .manufacturersGetManufacturers()
        .then((response) => {
          console.log(response);
          setManufacturers(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  if (user && authorizations) {
    return (
      <Stage>
        <Navigator title={t('view.team.title')}></Navigator>
        <HelperCard
          text={t('view.team.team.helperCard.addMember')}
          knowledgeBaseItems={[
            {
              title: t('knowledgebase.settings.team.addMember.title'),
              text: t('knowledgebase.settings.team.addMember.text'),
              videoUri: t('knowledgebase.settings.team.addMember.media'),
            },
          ]}
          settingName={'settings_team'}
        />
        <Has
          authorizations={['users:list', 'user_roles:list']}
          showNoAuthorization
        >
          <Card bigScreenWidth="100%">
            <CardSection title={t('view.team.team.members')}>
              <TeamMembers
                t={t}
                manufacturer={manufacturer}
                manufacturers={manufacturers}
                manufacturerId={
                  user.isProductOwner ? undefined : user.manufacturerId
                }
              />
            </CardSection>
          </Card>
        </Has>
        <Card bigScreenWidth="100%">
          <Has authorizations={['user_roles:list']}>
            <CardSection title={t('view.team.roles.title')}>
              <Roles manufacturers={manufacturers} />
            </CardSection>
          </Has>
        </Card>
        <Card bigScreenWidth="100%">
          <CardSection title={t('view.team.contacts.title')}>
            {manufacturer ? (
              manufacturer.manufacturerContacts ? (
                <Contacts t={t} manufacturer={manufacturer} />
              ) : (
                <LoadingContainer />
              )
            ) : (
              <EmptyState message={t('view.team.contacts.empty')} />
            )}
          </CardSection>
        </Card>
      </Stage>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Team;
