import './productbulkeditor.css';
import {
  AnimalSpeciesResponse,
  AssetFolderResponse,
  BrandResponse,
  BulkUpsertProductRequest,
  DeliveryTimeResponse,
  ProductAdditionalInformationResponse,
  ProductAnalyticConstituentResponse,
  ProductAssetResponse,
  ProductCategoryResponse,
  ProductGroupResponse,
  ProductInformationContentType,
  ProductPriceResponse,
  ProductResponse,
  ProductStockResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
  TranslatedTypeRequestOfGuid,
  TranslatedTypeResponseOfString,
  UpsertProductAdditionalInformationBulletPointsRequest,
  UpsertProductAdditionalInformationDownloadRequest,
  UpsertProductAdditionalInformationMediaGalleryRequest,
  UpsertProductAdditionalInformationRichTextRequest,
  UpsertProductAdditionalInformationTableRequest,
  WarehouseResponse,
} from '../../api/petcloudapi/api';
import JsonTable, {
  CustomCellConfig,
  EditorErrorCallback,
  EditorUpdateCallback,
  EditorUpdateCallbackInstruction,
  JsonTableHeader,
  JsonTableSelectedRow,
  SelectedCellElement,
} from '../../features/jsontable/JsonTable';
import TranslatedSimpleTextEditor from '../../features/jsontable/editors/translatedsimpletext/TranslatedSimpleTextEditor';
import JsonEditor from '../../features/jsontable/editors/jsoneditor/JsonEditor';
import PillsCell from '../../features/jsontable/jsontablecell/cells/pills/PillsCell';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TranslatedRichTextEditor from '../../features/jsontable/editors/translatedrichtext/TranslatedRichTextEditor';
import PropertiesCell from '../../features/jsontable/jsontablecell/cells/properties/PropertiesCell';
import PropertiesEditor from '../../features/jsontable/editors/propertieseditor/PropertiesEditor';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useTranslation } from 'react-i18next';
import {
  BulkEditorProduct,
  BulkUpdateInstruction,
  SessionInfo,
  ValidatedBulkEditorProduct,
} from '../../view/products/BulkEditor';
import CheckboxCell from '../../features/jsontable/jsontablecell/cells/checkbox/CheckboxCell';
import DropdownCell from '../../features/jsontable/jsontablecell/cells/dropdown/DropdownCell';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import DeliveryTimesCell from '../../features/jsontable/jsontablecell/cells/deliveryTimes/DeliveryTimesCell';
import DeliveryTimesEditor from '../../features/jsontable/editors/deliveryTimes/DeliveryTimesEditor';
import CategoriesCell from '../../features/jsontable/jsontablecell/cells/categories/CategoriesCell';
import CategoriesEditor from '../../features/jsontable/editors/categories/CategoriesEditor';
import { ReactComponent as IconTime } from '../../../assets/icon/jsontable/time.svg';
import { ReactComponent as IconOpenFile } from '../../../assets/icon/jsontable/menu/openFile.svg';
import { ReactComponent as IconSaveFile } from '../../../assets/icon/jsontable/menu/saveToFile.svg';
import { ReactComponent as IconConvert } from '../../../assets/icon/convert.svg';
import PricesCell from '../../features/jsontable/jsontablecell/cells/prices/PricesCell';
import PricesEditor from '../../features/jsontable/editors/priceseditor/PricesEditor';
import StocksCell from '../../features/jsontable/jsontablecell/cells/stocks/StocksCell';
import StocksEditor from '../../features/jsontable/editors/stocks/StocksEditor';
import MediaCell from '../../features/jsontable/jsontablecell/cells/media/MediaCell';
import MediaEditor from '../../features/jsontable/editors/media/MediaEditor';
import AnalyticConstituentsCell from '../../features/jsontable/jsontablecell/cells/analyticconstituents/AnalyticConstituentsCell';
import AnalyticConstituentsEditor from '../../features/jsontable/editors/analyticconstituents/AnalyticConstituentsEditor';
import AdditionalInformationCell from '../../features/jsontable/jsontablecell/cells/additionalinformation/AdditionalInformationCell';
import AnimalSpeciesCell from '../../features/jsontable/jsontablecell/cells/animalspecies/AnimalSpeciesCell';
import AnimalSpeciesEditor from '../../features/jsontable/editors/animalspecies/AnimalSpeciesEditor';
import AdditionalInformationEditor from '../../features/jsontable/editors/additionalinformation/AdditionalInformationEditor';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import {
  deserializeAssets,
  deserializeBulletpointsItems,
  deserializeRichTextContent,
  deserializeTable,
} from '../product/additionalinformation/AdditionalInformation';
import useNotifications from '../../hooks/useNotifications';
import Dropzone from '../../elements/dropzone/Dropzone';
import InformationBox from '../../elements/informationbox/InformationBox';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';
import { useUser } from '../../contexts/auth/User';
import useManufacturerConditions from '../../hooks/useManufacturerConditions';
import SaveCreation from './savecreation/SaveCreation';
import {
  defaultColumnWidths,
  defaultHeaderOrder,
  disabledColumns,
  getBlackListedColumns,
  requiredColumns,
  requiredFieldConfigs,
  requiredFieldValidationMap,
} from './utils/configurations';
import useBulkEditorHints from './utils/useBulkEditorHints';
import useSearchConfig from './utils/useSearchConfig';
import DropdownMenu from '../../elements/dropdownmenu/DropdownMenu';
import MainCategoryIdCell from '../../features/jsontable/jsontablecell/cells/maincategoryid/MainCategoryIdCell';
import MainCategoryIdEditor from '../../features/jsontable/editors/maincategoryid/MainCategoryIdEditor';

export type ClipboardFunction = { key: string; func: (data: any) => string };

interface ProductBulkEditorProps {
  products: any[];
  groupedProducts: { [parentId: string]: any[] };
  updateItem: (
    itemId: string,
    instructions: EditorUpdateCallbackInstruction[]
  ) => void;
  bulkUpdateItem: (instructions: BulkUpdateInstruction[]) => void;
  addEmptyProduct: (item?: BulkEditorProduct) => void;
  deleteProduct: (id: string) => void;
  deleteProducts: (ids: string[]) => void;
  setAsParentForSelection: (item: any, selectedIds: string[]) => void;
  sessionInfo?: SessionInfo | null;
  setSessionInfo: (info: SessionInfo) => void;
  saveStateToFile: () => void;
  loadStateFromFile: (files: File[]) => void;
  defaultSearchableHeaders: string[];
  clipboardFunctions?: ClipboardFunction[];
}

const ProductBulkEditor: React.FC<ProductBulkEditorProps> = ({
  products,
  groupedProducts,
  updateItem,
  bulkUpdateItem,
  addEmptyProduct,
  deleteProduct,
  deleteProducts,
  setAsParentForSelection,
  setSessionInfo,
  sessionInfo,
  saveStateToFile,
  loadStateFromFile,
  defaultSearchableHeaders,
  clipboardFunctions,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor',
  });
  const { user } = useUser();
  const api = usePetCloudApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const productUnitsApi = api.productUnitsApi();
  const productGroupsApi = api.productGroupsApi();
  const productCategoriesApi = api.productCategoriesApi();
  const productsApi = api.productsApi();
  const currenciesApi = api.currenciesApi();
  const countriesApi = api.countriesApi();
  const warehousesApi = api.warehousesApi();
  const assetFoldersApi = api.assetFoldersApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const brandsApi = api.brandsApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();
  const { getToggledSetting } = useLocalStorageSettings();
  const { pushNotification } = useNotifications();
  const { headerHints } = useBulkEditorHints();

  const { searchHeaderWhitelist, searchHeaderFunctionDict } = useSearchConfig();

  // INIT
  const [properties, setProperties] = useState<PropertyGroupResponse[] | null>(
    null
  );
  const [productUnitOptions, setProductUnitOptions] = useState<
    DropdownOption[]
  >([]);
  const [productGroups, setProductGroups] = useState<ProductGroupResponse[]>(
    []
  );
  const [productCategories, setProductCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const [countriesOptions, setCountries] = useState<DropdownOption[]>([]);
  const [currenciesOptions, setCurrencies] = useState<DropdownOption[]>([]);
  const [brands, setBrands] = useState<BrandResponse[] | null>(null);
  const [brandOptions, setBrandOptions] = useState<DropdownOption[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseResponse[] | null>(
    null
  );
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [availableAnimalSpecies, setAvailableAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);

  const [copiedSelection, setCopiedSelection] = useState<
    JsonTableSelectedRow[]
  >([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [convertPopup, setConvertPopup] = useState(false);
  const [manufacturerOptions, setManufacturerOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<DropdownOption | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadSaveFilePopup, setLoadSaveFilePopup] = useState(false);
  const [copiedCellElement, setCopiedCellElement] =
    useState<SelectedCellElement | null>(null);
  const [autoRequestApproval, setAutoRequestApproval] = useState(false);

  const [headers, setHeaders] = useState<JsonTableHeader[] | null>(null);
  const { isUsingManufacturerWarehouse, isNotUsingCentralWarehouse, ready } =
    useManufacturerConditions();

  useEffect(() => {
    getHeaders();
  }, []);

  // structure the headers for the columns and inject them with additional information
  const getHeaders = () => {
    const headers: JsonTableHeader[] = [];
    Object.keys(products[0])
      .sort(
        (a, b) => defaultHeaderOrder.indexOf(a) - defaultHeaderOrder.indexOf(b)
      )
      .forEach((key) => {
        if (
          !getBlackListedColumns(
            isUsingManufacturerWarehouse() && !user?.isProductOwner
          ).includes(key)
        ) {
          headers.push({
            id: key,
            title: t(`headers.${key}`),
            width: defaultColumnWidths[key],
            disabled: disabledColumns.includes(key),
            hidden: false,
            pinned: false,
            hint: headerHints[key],
          });
        }
      });
    console.log(products[0]);
    console.log(headers);
    setHeaders(headers);
    return headers;
  };

  useEffect(() => {
    getPropertyGroups();
    getProductUnits();
    getProductGroups();
    getProductCategories();
    getCountries();
    getCurrencies();
    getAssetFolders();
    getBrands();
    getAvailableAnimalSpecies();
    getManufacturerOptions();
  }, []);

  const getManufacturerOptions = () => {
    if (user?.isProductOwner) {
      manufacturersApi
        .manufacturersGetManufacturers()
        .then((response) => {
          console.log(response);
          setManufacturerOptions(
            response.data.map((x) => ({
              id: x.id,
              name: x.companyName,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, undefined, true);
        });
    }
  };

  const getAvailableAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAvailableAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response, undefined, true);
      });
  };

  const getBrands = () => {
    brandsApi
      .brandsGetBrands()
      .then((response) => {
        console.log(response);
        setBrands(response.data);
        setBrandOptions(
          response.data.map((brand) => {
            return {
              id: brand.id,
              name:
                brand.name[i18n.language as TranslatedStringIndex] ??
                'missing translation',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response, undefined, true);
      });
  };

  useEffect(() => {
    if (ready) {
      getWarehouses();
    }
  }, [ready]);

  const getWarehouses = () => {
    warehousesApi
      .warehousesGetWarehouses()
      .then((response) => {
        console.log(response);
        console.log(isNotUsingCentralWarehouse());
        if (isNotUsingCentralWarehouse() === true) {
          setWarehouses(
            response.data.filter((x) => x.type !== 'CentralWarehouse')
          );
        } else {
          setWarehouses(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getCurrencies = () => {
    currenciesApi
      .currenciesGetCurrencies()
      .then((response) => {
        console.log(response);
        setCurrencies(
          response.data.map((currency) => {
            return {
              id: currency.id,
              name: currency.symbol,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getCountries = () => {
    countriesApi
      .countriesGetCountries()
      .then((response) => {
        console.log(response);
        setCountries(
          response.data.map((country) => {
            return {
              id: country.id,
              name:
                country.name[i18n.language as TranslatedStringIndex] ??
                'missing translation',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getPropertyGroups = () => {
    propertyGroupsApi
      .propertyGroupsGetPropertyGroups(
        undefined,
        undefined,
        getToggledSetting('excludeUnlockedOptions')
      )
      .then((response) => {
        console.log(response);
        setProperties(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductUnits = () => {
    productUnitsApi
      .productUnitsGetProductUnits(true)
      .then((response) => {
        console.log(response);
        setProductUnitOptions(
          response.data.map((u) => {
            return {
              id: u.id,
              name:
                u.name[i18n.language as TranslatedStringIndex] ??
                'missing translation',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        setProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        errorHandler.addError(error.response);
      });
  };

  const getProductCategories = () => {
    productCategoriesApi
      .productCategoriesGetProductCategories(true)
      .then((response) => {
        console.log(response);
        setProductCategories(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        errorHandler.addError(error.response);
      });
  };

  // *****
  // CELLS
  // *****

  const propertiesCell = useCallback(
    (
      options: PropertyOptionResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any, compatibleHeaders?: string[]) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      if (!product.productGroupId || product.animalSpecies?.length === 0) {
        setError(t('warnings.emptyProperties'), true);
      } else {
        removeError();
      }

      if (options) {
        return (
          <PropertiesCell
            options={options}
            selectCellElement={selectCellElement}
            isSelectedCellElement={isSelectedCellElement}
          />
        );
      } else {
        return null;
      }
    },
    []
  );

  const pillsCell = useCallback(
    (value: TranslatedTypeResponseOfString, shorten?: number) => {
      if (value) {
        return (
          <PillsCell
            pillValues={Object.entries(value).map(([k, v]) => {
              return {
                capsule: k,
                value: shorten && v ? v.substring(0, shorten) + '...' : v,
              };
            })}
          />
        );
      } else {
        return null;
      }
    },
    []
  );

  const checkboxCell = useCallback(
    (value: boolean, updateCallback: EditorUpdateCallback) => {
      return <CheckboxCell value={value} updateCallBack={updateCallback} />;
    },
    []
  );

  const taxTypeIdentifierCell = useCallback(
    (value: string, updateCallback: EditorUpdateCallback) => {
      return (
        <DropdownCell
          value={value}
          updateCallBack={updateCallback}
          options={['Full', 'Reduced', 'Free']}
        />
      );
    },
    []
  );

  const productUnitIdCell = useCallback(
    (value: string, updateCallback: EditorUpdateCallback) => {
      return (
        <DropdownCell
          value={value}
          updateCallBack={updateCallback}
          optionObjects={productUnitOptions}
        />
      );
    },
    [productUnitOptions]
  );

  const productGroupIdCell = useCallback(
    (
      value: string,
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void
    ) => {
      const options = productGroups
        .filter((g) =>
          g.animalSpecies?.find((s) =>
            product.animalSpecies?.find((a) => a.id === s.id)
          )
        )
        .map((x) => ({
          id: x.id,
          name:
            x.name[i18n.language as TranslatedStringIndex] ??
            'missing translation',
        }));

      if (options.length === 0) {
        setError(t('warnings.emptyProductGroupOptions'), true);
      } else {
        removeError();
      }

      return (
        <DropdownCell
          value={value}
          updateCallBack={updateCallback}
          optionObjects={options}
        />
      );
    },
    [productGroups, products]
  );

  const brandIdCell = useCallback(
    (value: string, updateCallback: EditorUpdateCallback) => {
      return (
        <DropdownCell
          value={value}
          updateCallBack={updateCallback}
          optionObjects={brandOptions}
        />
      );
    },
    [brandOptions]
  );

  const deliveryTimesCell = useCallback(
    (deliveryTimes: DeliveryTimeResponse[]) => {
      return <DeliveryTimesCell deliveryTimes={deliveryTimes} />;
    },
    []
  );

  const categoriesCell = useCallback(
    (
      categories: ProductCategoryResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      if (!product.productGroupId) {
        setError(t('warnings.emptyCategoriesOptions'), true);
        return null;
      } else {
        removeError();
        return (
          <CategoriesCell
            categories={categories}
            selectCellElement={selectCellElement}
            isSelectedCellElement={isSelectedCellElement}
            mainCategoryId={product.mainCategoryId}
          />
        );
      }
    },
    []
  );

  const mainCategoryIdCell = useCallback(
    (
      categories: ProductCategoryResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void
    ) => {
      if (!product.categories || product.categories.length === 0) {
        setError(t('warnings.emptyCategoriesOptions'), true);
        return null;
      } else {
        removeError();
        return (
          <MainCategoryIdCell
            availableCategories={product.categories}
            mainCategoryId={product.mainCategoryId}
          />
        );
      }
    },
    []
  );

  const pricesCell = useCallback(
    (
      prices: ProductPriceResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      return (
        <PricesCell
          prices={prices}
          selectCellElement={selectCellElement}
          isSelectedCellElement={isSelectedCellElement}
        />
      );
    },
    []
  );

  const stocksCell = useCallback(
    (
      stocks: ProductStockResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      if (warehouses) {
        return (
          <StocksCell
            stocks={stocks}
            warehouses={warehouses}
            selectCellElement={selectCellElement}
            isSelectedCellElement={isSelectedCellElement}
          />
        );
      } else {
        return <LoadingContainer />;
      }
    },
    [warehouses]
  );

  const mediaCell = useCallback(
    (
      assets: ProductAssetResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      return (
        <MediaCell
          assets={assets}
          selectCellElement={selectCellElement}
          isSelectedCellElement={isSelectedCellElement}
        />
      );
    },
    []
  );

  const analyticConstituentsCell = useCallback(
    (
      constituents: ProductAnalyticConstituentResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      return (
        <AnalyticConstituentsCell
          constituents={constituents}
          selectCellElement={selectCellElement}
          isSelectedCellElement={isSelectedCellElement}
        />
      );
    },
    []
  );

  const additionalInformationCell = useCallback(
    (
      additionalInformation: ProductAdditionalInformationResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      return (
        <AdditionalInformationCell
          additionalInformation={additionalInformation}
          selectCellElement={selectCellElement}
          isSelectedCellElement={isSelectedCellElement}
        />
      );
    },
    []
  );

  const animalSpeciesCell = useCallback(
    (
      animalSpecies: AnimalSpeciesResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse,
      setError: EditorErrorCallback,
      removeError: () => void,
      selectCellElement: (element: any) => void,
      isSelectedCellElement: (element: any) => boolean
    ) => {
      return (
        <AnimalSpeciesCell
          animalSpecies={animalSpecies}
          selectCellElement={selectCellElement}
          isSelectedCellElement={isSelectedCellElement}
        />
      );
    },
    []
  );

  // *******
  // EDITORS
  // *******

  const jsonEditor = useCallback(
    (value: object, updateCallback: EditorUpdateCallback) => {
      return <JsonEditor json={value} updateCallback={updateCallback} />;
    },
    []
  );

  const translatedSimpleTextEditor = useCallback(
    (
      value: TranslatedTypeResponseOfString,
      updateCallback: EditorUpdateCallback,
      product: BulkEditorProduct,
      prefixKey?: string
    ) => {
      return (
        <TranslatedSimpleTextEditor
          value={value}
          updateCallback={updateCallback}
          translatedPrefixObject={
            prefixKey
              ? brands?.find((x) => x.id === product.brandId)?.name
              : undefined
          }
        />
      );
    },
    [brands]
  );

  const translatedRichTextEditor = useCallback(
    (
      value: TranslatedTypeResponseOfString,
      updateCallback: EditorUpdateCallback
    ) => {
      return (
        <TranslatedRichTextEditor
          value={value}
          updateCallback={updateCallback}
        />
      );
    },
    []
  );

  const propertiesEditor = useCallback(
    (
      value: PropertyOptionResponse[],
      updateCallback: EditorUpdateCallback,
      product: BulkEditorProduct,
      variantOptionsOnly?: boolean
    ) => {
      if (properties) {
        return (
          <PropertiesEditor
            value={value}
            updateCallback={updateCallback}
            propertyGroups={properties}
            availableProductGroups={productGroups}
            variantOptionsOnly={variantOptionsOnly}
            product={product}
            validationOptional={!product.parentId}
          />
        );
      } else {
        return <LoadingContainer />;
      }
    },
    [properties, productGroups, products]
  );

  const deliveryTimesEditor = useCallback(
    (
      deliveryTimes: DeliveryTimeResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse
    ) => {
      return (
        <DeliveryTimesEditor
          deliveryTimes={deliveryTimes}
          updateCallback={updateCallback}
          productId={product.id}
        />
      );
    },
    []
  );

  const categoriesEditor = useCallback(
    (
      categories: ProductCategoryResponse[],
      updateCallback: EditorUpdateCallback,
      item: BulkEditorProduct
    ) => {
      if (item.productGroupId) {
        if (productCategories) {
          return (
            <CategoriesEditor
              categories={categories}
              availableCategories={productCategories}
              updateCallback={updateCallback}
              selectedAnimalSpeciesIds={item.animalSpecies?.map((x) => x.id)}
              selectedProductGroupId={item.productGroupId}
              mainCategoryId={item.mainCategoryId}
            />
          );
        } else {
          return <LoadingContainer />;
        }
      } else {
        return (
          <InformationBox
            type={'warning'}
            paragraphs={[t('warnings.emptyCategoriesOptions')]}
            noMargin
          />
        );
      }
    },
    [productCategories]
  );

  const mainCategoryIdEditor = useCallback(
    (
      categories: ProductCategoryResponse[],
      updateCallback: EditorUpdateCallback,
      item: BulkEditorProduct
    ) => {
      if (item.categories) {
        return (
          <MainCategoryIdEditor
            availableCategories={item.categories}
            updateCallback={updateCallback}
            mainCategoryId={item.mainCategoryId}
          />
        );
      } else {
        return (
          <InformationBox
            type={'warning'}
            paragraphs={[t('warnings.emptyMainCategoryId')]}
            noMargin
          />
        );
      }
    },
    []
  );

  const pricesEditor = useCallback(
    (
      prices: ProductPriceResponse[],
      updateCallback: EditorUpdateCallback,
      item: BulkEditorProduct
    ) => {
      return (
        <PricesEditor
          value={prices}
          currencyOptions={currenciesOptions}
          countryOptions={countriesOptions}
          updateCallback={updateCallback}
          productId={item.id}
        />
      );
    },
    [currenciesOptions, countriesOptions]
  );

  const stocksEditor = useCallback(
    (
      stocks: ProductStockResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse
    ) => {
      if (warehouses) {
        return (
          <StocksEditor
            value={stocks}
            warehouses={warehouses}
            updateCallback={updateCallback}
            productId={product.id}
          />
        );
      }
    },
    [warehouses]
  );

  const mediaEditor = useCallback(
    (assets: ProductAssetResponse[], updateCallback: EditorUpdateCallback) => {
      console.log(assetFolders);
      if (assetFolders) {
        return (
          <MediaEditor
            assets={assets}
            updateCallback={updateCallback}
            assetFolders={assetFolders}
          />
        );
      }
    },
    [assetFolders]
  );

  const analyticConstituentsEditor = useCallback(
    (
      constituents: ProductAnalyticConstituentResponse[],
      updateCallback: EditorUpdateCallback
    ) => {
      return (
        <AnalyticConstituentsEditor
          constituents={constituents}
          updateCallback={updateCallback}
        />
      );
    },
    []
  );

  const additionalInformationEditor = useCallback(
    (
      additionalInformation: ProductAdditionalInformationResponse[],
      updateCallback: EditorUpdateCallback,
      product: ProductResponse
    ) => {
      return (
        <AdditionalInformationEditor
          productId={product.id}
          additionalInformation={additionalInformation}
          updateCallback={updateCallback}
        />
      );
    },
    []
  );

  const animalSpeciesEditor = useCallback(
    (
      animalSpecies: AnimalSpeciesResponse[],
      updateCallback: EditorUpdateCallback
    ) => {
      if (availableAnimalSpecies) {
        return (
          <AnimalSpeciesEditor
            animalSpecies={animalSpecies}
            updateCallback={updateCallback}
            availableAnimalSpecies={availableAnimalSpecies}
          />
        );
      } else {
        return <LoadingContainer />;
      }
    },
    [availableAnimalSpecies]
  );

  // *****************
  // UTILITY FUNCTIONS
  // *****************

  const copyToClipboard = (value: any) => {
    if (value) {
      navigator.clipboard.writeText(value.toString());
    }
  };

  const onCopy = (selection: JsonTableSelectedRow[]) => {
    setCopiedSelection(selection);

    // copy all cell values to clipboard in case they are strings
    let dataStr = '';
    selection.forEach((row) => {
      row.cells.forEach((cell) => {
        const data = products[row.index][cell.key];
        if (typeof data === 'string' || typeof data === 'number')
          dataStr += data + '\n';
        const clipboardFunction = clipboardFunctions?.find(
          (x) => x.key === cell.key
        )?.func;
        if (clipboardFunction) {
          dataStr += clipboardFunction(data) + '\n';
        }
      });
    });
    copyToClipboard(dataStr);
    pushNotification(t('notifications.copied'), 'info', 1000);
  };

  const copyCellElement = (element: SelectedCellElement) => {
    setCopiedCellElement(element);

    // copy the single cell value to clipboard in case it is a string
    const value = element.element;
    if (typeof value === 'string' || typeof value === 'number')
      copyToClipboard(value);
    pushNotification(t('notifications.cellElementCopied'), 'info', 1000);
  };

  const onPaste = (
    selection: JsonTableSelectedRow[],
    isCellElement?: boolean
  ) => {
    const instructions: BulkUpdateInstruction[] = [];
    const startLocationRow = selection[0];

    if (!isCellElement) {
      // pastes full cell values for selection

      // if the copied selection includes only one row,
      // this value should be pasted for all rows of the current selection
      if (copiedSelection.length === 1) {
        const row = copiedSelection[0];
        row.cells.forEach((cell) => {
          const copy = products[row.index][cell.key];
          selection.forEach((selectedRow) => {
            instructions.push({
              rowIndex: selectedRow.index,
              columnKey: cell.key,
              value: JSON.parse(JSON.stringify(copy)),
            });
          });
        });
      } else {
        // otherwise do the regular-behaved pasting
        copiedSelection.forEach((row, i) => {
          row.cells.forEach((cell) => {
            const copy = products[row.index][cell.key];
            instructions.push({
              rowIndex: startLocationRow.index + i,
              columnKey: cell.key,
              value: JSON.parse(JSON.stringify(copy)),
            });
          });
        });
      }
    } else {
      if (copiedCellElement) {
        // pastes the single cell / array element as addition to the existing cells content
        const current =
          products[startLocationRow.index][startLocationRow.cells[0].key];
        if (current) {
          current.push({ ...copiedCellElement.element });
        }
        instructions.push({
          rowIndex: startLocationRow.index,
          columnKey: startLocationRow.cells[0].key,
          value: current ?? [{ ...copiedCellElement.element }],
        });
      }
    }
    bulkUpdateItem(instructions);
  };

  const onSelect = (productId: string) => {
    console.log(productId);
    const update = [...selectedIds];
    const i = update.indexOf(productId);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(productId);
    }
    setSelectedIds(update);
  };

  // takes the delta between the currently selected row and the row action item,
  // and select all products in between
  const handleCompleteSelectionToHere = (item: BulkEditorProduct) => {
    const productsFlat = Object.values(groupedProducts).flat();
    const selectedIndex = productsFlat.findIndex(
      (x) => x.id === selectedIds[0]
    );
    const actionIndex = productsFlat.findIndex((x) => x.id === item.id);
    if (selectedIndex > -1 && actionIndex > -1) {
      const updatedSelectedIds = [...selectedIds];
      const direction = actionIndex < selectedIndex ? 'backwards' : 'forwards';
      if (direction === 'backwards') {
        for (let i = selectedIndex - 1; i >= actionIndex; i--) {
          updatedSelectedIds.push(productsFlat[i].id);
        }
      } else {
        for (let i = selectedIndex + 1; i <= actionIndex; i++) {
          updatedSelectedIds.push(productsFlat[i].id);
        }
      }
      setSelectedIds(updatedSelectedIds);
    }
  };

  const deleteItem = (id: string) => {
    deleteProduct(id);
    const update = [...selectedIds];
    const i = update.indexOf(id);
    if (i !== -1) {
      console.log('deleting selected id');
      update.splice(i, 1);
      setSelectedIds(update);
    }
  };

  const deleteCellElement = (
    location: JsonTableSelectedRow,
    cellElement: SelectedCellElement
  ) => {
    const product = products[location.index];
    const update = product[location.cells[0].key];
    if (update) {
      try {
        const i = update.findIndex((x: any) => x.id === cellElement.element.id);
        update.splice(i, 1);
        updateItem(product.id, [
          {
            headerKey: cellElement.header,
            value: update,
          },
        ]);
      } catch {
        console.log('Error: Could not delete cell element!');
      }
    }
  };

  const parseToFloat = (v: string) => parseFloat(v.replace(',', '.'));

  const getValidationDescriptions = useCallback(
    (product: ProductResponse, key: string) => {
      const productGroup = productGroups.find(
        (x) => x.id === product.productGroupId
      );
      const descs: string[] = [];
      productGroup?.additionalPropertyValidationRules?.forEach((rule) => {
        if (rule.identifier === key) {
          const d =
            rule.description?.[i18n.language as TranslatedStringIndex] ??
            'missing translation description';
          descs.push(d);
        }
      });
      return descs;
    },
    [productGroups]
  );

  const customCellConfig: { [p: string]: CustomCellConfig } = useMemo(() => {
    return {
      name: {
        renderMethod: (v) => pillsCell(v, undefined),
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          translatedSimpleTextEditor(value, updateCallback, product),
        technicalEditor: jsonEditor,
        cellElementSelectionForbidden: true,
      },
      seoProductTitle: {
        renderMethod: (v) => pillsCell(v, undefined),
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          translatedSimpleTextEditor(value, updateCallback, product, 'brandId'),
        technicalEditor: jsonEditor,
        cellElementSelectionForbidden: true,
      },
      productLine: {
        renderMethod: (v) => pillsCell(v, undefined),
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          translatedSimpleTextEditor(value, updateCallback, product),
        technicalEditor: jsonEditor,
        cellElementSelectionForbidden: true,
      },
      packUnit: {
        renderMethod: (v) => pillsCell(v, undefined),
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          translatedSimpleTextEditor(value, updateCallback, product),
        technicalEditor: jsonEditor,
        cellElementSelectionForbidden: true,
      },
      packUnitPlural: {
        renderMethod: (v) => pillsCell(v, undefined),
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          translatedSimpleTextEditor(value, updateCallback, product),
        technicalEditor: jsonEditor,
        cellElementSelectionForbidden: true,
      },
      description: {
        renderMethod: (v) => pillsCell(v, 20),
        parseJSON: true,
        editor: translatedRichTextEditor,
        technicalEditor: jsonEditor,
        cellElementSelectionForbidden: true,
      },
      properties: {
        renderMethod: propertiesCell,
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          propertiesEditor(value, updateCallback, product, false),
        technicalEditor: jsonEditor,
      },
      variantOptions: {
        renderMethod: propertiesCell,
        parseJSON: true,
        editor: (value, updateCallback, product) =>
          propertiesEditor(value, updateCallback, product, true),
        technicalEditor: jsonEditor,
        condition: (product) => product.parentId,
      },
      deliveryTimes: {
        renderMethod: deliveryTimesCell,
        parseJSON: true,
        editor: deliveryTimesEditor,
        technicalEditor: jsonEditor,
      },
      categories: {
        renderMethod: categoriesCell,
        parseJSON: true,
        editor: categoriesEditor,
        technicalEditor: jsonEditor,
        valueDependentColumns: ['mainCategoryId'],
      },
      mainCategoryId: {
        renderMethod: mainCategoryIdCell,
        editor: mainCategoryIdEditor,
      },
      isCloseout: {
        renderMethod: checkboxCell,
      },
      isShippingFree: {
        renderMethod: checkboxCell,
      },
      isBatchControlled: {
        renderMethod: checkboxCell,
      },
      isBestBeforeControlled: {
        renderMethod: checkboxCell,
      },
      isDangerousGoods: {
        renderMethod: checkboxCell,
      },
      taxTypeIdentifier: {
        renderMethod: taxTypeIdentifierCell,
      },
      productUnitId: {
        renderMethod: productUnitIdCell,
      },
      productGroupId: {
        renderMethod: productGroupIdCell,
        valueDependentColumns: ['properties', 'variantOptions', 'categories'],
      },
      brandId: {
        renderMethod: brandIdCell,
      },
      productPrices: {
        renderMethod: pricesCell,
        parseJSON: true,
        editor: pricesEditor,
        technicalEditor: jsonEditor,
      },
      productStocks: {
        renderMethod: stocksCell,
        parseJSON: true,
        editor: stocksEditor,
        technicalEditor: jsonEditor,
      },
      productAssets: {
        renderMethod: mediaCell,
        parseJSON: true,
        editor: mediaEditor,
        technicalEditor: jsonEditor,
      },
      analyticConstituents: {
        renderMethod: analyticConstituentsCell,
        parseJSON: true,
        editor: analyticConstituentsEditor,
        technicalEditor: jsonEditor,
      },
      additionalInformation: {
        renderMethod: additionalInformationCell,
        parseJSON: true,
        editor: additionalInformationEditor,
        technicalEditor: jsonEditor,
        validationDescriptions: getValidationDescriptions,
      },
      animalSpecies: {
        renderMethod: animalSpeciesCell,
        parseJSON: true,
        editor: animalSpeciesEditor,
        technicalEditor: jsonEditor,
        valueDependentColumns: ['productGroupId'],
      },
      purchaseUnit: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
      restockTimeDays: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
      purchaseSteps: {
        renderMethod: 'default',
        parseValueFunc: (v: string) => parseInt(v),
        inputType: 'number',
      },
      minPurchase: {
        renderMethod: 'default',
        parseValueFunc: (v: string) => parseInt(v),
        inputType: 'number',
      },
      maxPurchase: {
        renderMethod: 'default',
        parseValueFunc: (v: string) => parseInt(v),
        inputType: 'number',
      },
      referenceUnit: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
      weight: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
      length: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
      width: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
      height: {
        renderMethod: 'default',
        parseValueFunc: parseToFloat,
        inputType: 'number',
      },
    };
  }, [
    propertiesEditor,
    productGroupIdCell,
    productUnitIdCell,
    categoriesEditor,
    pricesEditor,
    stocksEditor,
    mediaEditor,
    analyticConstituentsEditor,
    additionalInformationEditor,
    animalSpeciesEditor,
  ]);

  const transformAdditionalInformationResponse = (
    responses: ProductAdditionalInformationResponse[] | null | undefined,
    filterContentType: ProductInformationContentType,
    targetKey: string,
    deserializationFunc: (value: TranslatedTypeResponseOfString) => any
  ) => {
    return responses
      ?.filter((r) => r.contentType === filterContentType)
      .map((a) => {
        return {
          id: a.id,
          informationGroupId: a.informationGroupId,
          position: a.position,
          [targetKey]: deserializationFunc(a.value),
        };
      });
  };

  const bulkUpsertProducts = () => {
    const manufacturerId = selectedManufacturer?.id ?? user?.manufacturerId;
    if (manufacturerId) {
      setIsSubmitting(true);
      const bulkUpsertProductRequests: BulkUpsertProductRequest[] =
        products.map(
          (p: ValidatedBulkEditorProduct): BulkUpsertProductRequest => {
            const { categories, ...rest } = p;
            return {
              ...rest,
              brandId: p.brandId ?? '',
              productGroupId: p.productGroupId ?? '',
              animalSpeciesIds: p.animalSpecies?.map((x) => x.id) ?? [],
              categoryIds: p.categories?.map((x) => x.id) ?? [],
              variantOptionIds: p.variantOptions?.map((x) => x.id) ?? [],
              propertyIds: p.properties?.map((x) => x.id) ?? [],
              deliveryTimes: p.deliveryTimes?.map((x) => ({
                ...x,
                productId: p.id,
              })),
              productPrices: p.productPrices?.map((x) => ({
                ...x,
                productId: p.id,
              })),
              productAssets: p.productAssets?.map((x) => {
                const translatedIds: TranslatedTypeRequestOfGuid = {};
                Object.entries(x.asset).forEach(([locale, asset]) => {
                  translatedIds[locale as TranslatedStringIndex] = asset.id;
                });
                return {
                  position: x.position,
                  assetId: translatedIds,
                  description: x.description,
                  alternative: x.alternative,
                  productId: p.id,
                };
              }),
              productStocks: p.productStocks?.map((x) => {
                return {
                  ...x,
                  productId: p.id,
                };
              }),
              contentDownloads: transformAdditionalInformationResponse(
                p.additionalInformation,
                ProductInformationContentType.Downloads,
                'assetIds',
                deserializeAssets
              ) as UpsertProductAdditionalInformationDownloadRequest[],
              contentBulletPoints: transformAdditionalInformationResponse(
                p.additionalInformation,
                ProductInformationContentType.BulletPoints,
                'items',
                deserializeBulletpointsItems
              ) as UpsertProductAdditionalInformationBulletPointsRequest[],
              contentMediaGalleries: transformAdditionalInformationResponse(
                p.additionalInformation,
                ProductInformationContentType.MediaGallery,
                'assetIds',
                deserializeAssets
              ) as UpsertProductAdditionalInformationMediaGalleryRequest[],
              contentRichTexts: transformAdditionalInformationResponse(
                p.additionalInformation,
                ProductInformationContentType.RichText,
                'value',
                deserializeRichTextContent
              ) as UpsertProductAdditionalInformationRichTextRequest[],
              contentTables: p.additionalInformation
                ?.filter(
                  (r) => r.contentType === ProductInformationContentType.Table
                )
                .map((a) => {
                  return {
                    id: a.id,
                    informationGroupId: a.informationGroupId,
                    position: a.position,
                    header: deserializeTable(a.value, 'header'),
                    rows: deserializeTable(a.value, 'rows'),
                  };
                }) as UpsertProductAdditionalInformationTableRequest[],
            };
          }
        );
      productsApi
        .productsBulkUpsertProducts(
          bulkUpsertProductRequests,
          autoRequestApproval,
          manufacturerId
        )
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          setConvertPopup(false);
          pushNotification(t('notifications.conversion_success'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  if (productCategories && productGroups && productUnitOptions && headers) {
    return (
      <>
        <JsonTable
          itemsCount={products.length}
          items={{
            data: products,
            groupings: groupedProducts,
          }}
          defaultSearchableHeaders={defaultSearchableHeaders}
          enableValidation={true}
          headers={headers}
          setHeaders={setHeaders}
          requiredColumns={requiredColumns}
          complexRequiredFieldConfigs={requiredFieldConfigs}
          requiredFieldValidationMap={requiredFieldValidationMap}
          hiddenHeadersKeys={['idKeyValue', 'parentIdKeyValue']}
          rowHeight={50}
          defaultColumnWidth={200}
          adjustHeightToViewportOffset={180}
          rowRenderOffset={20}
          updateItem={updateItem}
          bulkUpdateItem={bulkUpdateItem}
          deleteCellElement={deleteCellElement}
          onCopy={onCopy}
          onPaste={onPaste}
          onSelect={onSelect}
          copyCellElement={copyCellElement}
          copiedCellElement={copiedCellElement}
          selectedIds={selectedIds}
          onClearSelectedIds={() => setSelectedIds([])}
          customCellsConfig={customCellConfig}
          addRow={addEmptyProduct}
          searchHeaderWhitelist={searchHeaderWhitelist}
          searchHeaderFunctions={searchHeaderFunctionDict}
          ctas={[
            <SaveCreation
              sessionInfo={sessionInfo}
              setSessionInfo={setSessionInfo}
              products={products}
            />,
            <Button
              type={'icon-text'}
              look={'primary'}
              width={'minimal'}
              cta={t('saveCreation.cta.convert')}
              action={() => setConvertPopup(true)}
              margin={'left'}
            >
              <IconConvert className={'button-icon button-icon-primary'} />
            </Button>,
          ]}
          actions={[
            <div className={'jsontable-topAction'}>
              <Button
                cta={t('actions.delete')}
                look={'secondary-danger'}
                width={'minimal'}
                action={() => {
                  deleteProducts(selectedIds);
                  setSelectedIds([]);
                }}
                active={selectedIds?.length > 0}
              />
            </div>,
          ]}
          itemActions={[
            {
              cta: t('itemActions.addRow'),
              ctaAlreadyTranslated: true,
              look: 'blue',
              action: (item) => addEmptyProduct(item),
            },
            {
              cta: t('itemActions.setAsParent'),
              ctaAlreadyTranslated: true,
              look: 'blue',
              action: (item) => {
                setAsParentForSelection(item, selectedIds);
                setSelectedIds([]);
              },
              hide: (item) => {
                return (
                  selectedIds.length === 0 ||
                  item.parentId ||
                  selectedIds.includes(item.id)
                );
              },
            },
            {
              cta: t('itemActions.completeSelectionToHere'),
              ctaAlreadyTranslated: true,
              look: 'blue',
              action: handleCompleteSelectionToHere,
              hide: (item) =>
                selectedIds.length !== 1 || selectedIds[0] === item.id,
            },
            {
              cta: t('itemActions.deleteRow'),
              ctaAlreadyTranslated: true,
              look: 'danger',
              action: (item) => deleteItem(item.id),
            },
          ]}
          toolsMenuActions={[
            {
              icon: <IconOpenFile />,
              cta: t('toolsMenuActions.loadFromFile'),
              ctaAlreadyTranslated: true,
              look: 'secondary',
              action: () => setLoadSaveFilePopup(true),
              group: 'file',
            },
            {
              icon: <IconSaveFile />,
              cta: t('toolsMenuActions.saveToFile'),
              ctaAlreadyTranslated: true,
              look: 'secondary',
              action: saveStateToFile,
              group: 'file',
            },
          ]}
          keyHints={[
            {
              keys: ['Ctrl', 'C'],
              explanationText: t('keyHints.copy.expl'),
              hintParagraphs: [t('keyHints.copy.hint')],
            },
            {
              keys: ['Ctrl', 'V'],
              explanationText: t('keyHints.paste.expl'),
              hintParagraphs: [t('keyHints.paste.hint')],
            },
            {
              keys: ['Alt', 'Click'],
              explanationText: t('keyHints.elementSelect.expl'),
              hintParagraphs: [
                t('keyHints.elementSelect.hint'),
                t('keyHints.cellSelect'),
              ],
            },
            {
              keys: ['Ctrl', 'Shift', 'V'],
              explanationText: t('keyHints.elementPaste.expl'),
              hintParagraphs: [
                t('keyHints.elementPaste.hint'),
                t('keyHints.cellSelect'),
              ],
            },
            {
              keys: ['Shift', 'Del'],
              explanationText: t('keyHints.elementDel.expl'),
              hintParagraphs: [
                t('keyHints.elementDel.hint'),
                t('keyHints.cellSelect'),
              ],
            },
          ]}
        />
        <Popup
          toggled={convertPopup}
          width={'30%'}
          close={() => setConvertPopup(false)}
        >
          <div className={'popup-title'}>{t('convertPopup.title')}</div>
          <InformationBox
            type={'info'}
            content={t('convertPopup.warning')}
            maxWidth={500}
          />
          <InformationBox
            type={'warning'}
            content={t('convertPopup.timing')}
            maxWidth={500}
            icon={
              <IconTime
                fill={'#ffa46a'}
                className={'informationbox-icon-img'}
              />
            }
          />
          <div className={'productBulkEditor-convertPopup-check'}>
            <Check
              checked={autoRequestApproval}
              update={() => setAutoRequestApproval(!autoRequestApproval)}
              text={t('convertPopup.autoRequestApproval')}
            />
          </div>
          {manufacturerOptions ? (
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  title={t('convertPopup.manufacturer')}
                  optionObjects={manufacturerOptions}
                  selected={selectedManufacturer?.name}
                  onSelect={(name, id) => {
                    const m = manufacturerOptions?.find((m) => m.id === id);
                    if (m) {
                      setSelectedManufacturer(m);
                    }
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className={'global-cardActions'}>
            <Button
              cta={t('convertPopup.cta')}
              look={'save'}
              action={bulkUpsertProducts}
              active={!!selectedManufacturer || !!user?.manufacturerId}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
        <Popup
          toggled={loadSaveFilePopup}
          width={'30%'}
          close={() => setLoadSaveFilePopup(false)}
        >
          <div className={'popup-title'}>{t('loadSaveFilePopup.title')}</div>
          <InformationBox
            type={'warning'}
            title={t('loadSaveFilePopup.warning.title')}
            content={t('loadSaveFilePopup.warning.text')}
          />
          <Dropzone
            maxFiles={1}
            callbackWithoutUpload={(files) => {
              loadStateFromFile(files);
              setLoadSaveFilePopup(false);
            }}
            height={200}
          />
        </Popup>
      </>
    );
  } else {
    return <LoadingContainer message={t('notifications.loadingTable')} />;
  }
};

export default ProductBulkEditor;
