import './mockshop.css';
import { BrandStoreResponse } from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import signal from '../../../../assets/icon/signal.svg';
import logo from '../../../../assets/img/logo.svg';
import { useState } from 'react';
import { LanguageSwitcher } from '../../../elements/languageswitcher/LanguageSwitcher';
import { Tabs, Tab } from '../../../elements/card/Card';

type HeroBannerAssetUri = {
  'de-DE': string | null;
  'en-GB': string | null;
};

interface MockShopProps {
  shop: BrandStoreResponse;
  heroBannerAssetUri: HeroBannerAssetUri;
}

const MockShop: React.FC<MockShopProps> = ({ shop, heroBannerAssetUri }) => {
  const [lang, setLang] = useState<TranslatedStringIndex>('de-DE');

  const logoUri = shop.brand?.logo?.[lang]?.uri;
  const bannerUri = heroBannerAssetUri[lang];

  return (
    <div className={'mockShop'}>
      <Tabs
        look={'card'}
        tabs={[
          { key: 'desktop', title: 'Desktop' },
          {
            key: 'mobile',
            title: 'Mobile',
          },
        ]}
      >
        <Tab>
          <>
            <LanguageSwitcher lang={lang} onLanguageSwitch={setLang} />
            <div className="mockShop-preview-desktop">
              <div className="mockShop-topbar">
                <img
                  src={logo}
                  className="mockShop-topbar-logo"
                  alt="inpetto logo"
                />
                <div className={'mockShop-topbar-search'}></div>
                <div className={'mockShop-topbar-els'}>
                  <div className={'mockShop-topbar-el'}></div>
                  <div className={'mockShop-topbar-el'}></div>
                </div>
              </div>
              <div className={'mockShop-d-hero'}>
                <div className={'mockShop-d-hero-content'}>
                  {logoUri ? (
                    <img
                      src={logoUri}
                      className="mockShop-d-hero-content-logo"
                      alt="logo"
                    />
                  ) : null}
                  <div className={'mockShop-d-hero-content-brandName'}>
                    {shop.brand?.name[lang]}
                  </div>
                  <div className="mockShop-d-hero-content-claim">
                    {shop.claim ? shop.claim[lang] : null}
                  </div>
                  <div
                    className="mockShop-d-hero-content-description"
                    dangerouslySetInnerHTML={{
                      __html: shop.description[lang] ?? '',
                    }}
                  ></div>
                </div>
                <div className={'mockShop-d-hero-image'}>
                  {bannerUri ? (
                    <img
                      src={bannerUri}
                      className="mockShop-d-hero-image-img"
                      alt="hero"
                    />
                  ) : (
                    <div className={'mockShop-d-hero-image-img'}></div>
                  )}
                  <div
                    className={'mockShop-d-hero-image-background'}
                    style={{ backgroundColor: shop.primaryColorHex }}
                  ></div>
                </div>
              </div>
              <div className="mockShop-m-products mockShop-d-products">
                <div className={'mockShop-m-products-els'}>
                  <div className={'mockShop-m-products-el'}></div>
                  <div className={'mockShop-m-products-el'}></div>
                  <div className={'mockShop-m-products-el'}></div>
                </div>
                <div className="mockShop-m-products-grid mockShop-d-products-grid">
                  <div className="mockShop-m-product mockShop-d-product"></div>
                  <div className="mockShop-m-product mockShop-d-product"></div>
                  <div className="mockShop-m-product mockShop-d-product"></div>
                  <div className="mockShop-m-product mockShop-d-product"></div>
                  <div className="mockShop-m-product mockShop-d-product"></div>
                </div>
              </div>
            </div>
          </>
        </Tab>
        <Tab>
          <>
            <LanguageSwitcher lang={lang} onLanguageSwitch={setLang} />
            <div className="mockShop-preview-mobile">
              <div className="mockShop-m-phonebar">
                <div className="mockShop-m-phonebar-clock">9:00</div>
                <div className="mockShop-m-phonebar-nodge"></div>
                <div className="mockShop-m-phonebar-signal">
                  <img
                    src={signal}
                    alt="signal"
                    className="mockShop-m-phonebar-signal-img"
                  />
                </div>
              </div>
              <div className="mockShop-topbar">
                <div className={'mockShop-topbar-els'}>
                  <div className={'mockShop-topbar-el'}></div>
                  <div className={'mockShop-topbar-el'}></div>
                </div>
                <img
                  src={logo}
                  className="mockShop-topbar-logo"
                  alt="inpetto logo"
                />
                <div className={'mockShop-topbar-els'}>
                  <div className={'mockShop-topbar-el'}></div>
                  <div className={'mockShop-topbar-el'}></div>
                </div>
              </div>
              <div className={'mockShop-m-hero'}>
                <div className={'mockShop-m-hero-image'}>
                  {bannerUri ? (
                    <img
                      src={bannerUri}
                      className="mockShop-m-hero-image-img"
                      alt="hero"
                    />
                  ) : (
                    <div className={'mockShop-m-hero-image-img'}></div>
                  )}
                  <div
                    className={'mockShop-m-hero-image-background'}
                    style={{ backgroundColor: shop.primaryColorHex }}
                  ></div>
                </div>
                <div className={'mockShop-m-hero-content'}>
                  {logoUri ? (
                    <img
                      src={logoUri}
                      className="mockShop-m-hero-content-logo"
                      alt="logo"
                    />
                  ) : null}
                  <div className={'mockShop-m-hero-content-brandName'}>
                    {shop.brand?.name[lang]}
                  </div>
                  <div className="mockShop-m-hero-content-claim">
                    {shop.claim ? shop.claim[lang] : null}
                  </div>
                  <div
                    className="mockShop-m-hero-content-description"
                    dangerouslySetInnerHTML={{
                      __html: shop.description[lang] ?? '',
                    }}
                  ></div>
                </div>
              </div>
              <div className="mockShop-m-products">
                <div className={'mockShop-m-products-els'}>
                  <div className={'mockShop-m-products-el'}></div>
                  <div className={'mockShop-m-products-el'}></div>
                  <div className={'mockShop-m-products-el'}></div>
                </div>
                <div className="mockShop-m-products-grid">
                  <div className="mockShop-m-product"></div>
                  <div className="mockShop-m-product"></div>
                  <div className="mockShop-m-product"></div>
                  <div className="mockShop-m-product"></div>
                </div>
              </div>
            </div>
          </>
        </Tab>
      </Tabs>
    </div>
  );
};

export default MockShop;
