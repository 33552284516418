import './dropdowncell.css';
import { DropdownOption } from '../../../../../elements/selectors/Selectors';
import { EditorUpdateCallback } from '../../../../../features/jsontable/JsonTable';
import DropdownMenu from '../../../../../elements/dropdownmenu/DropdownMenu';

interface DropdownCellProps {
  value: string;
  options?: string[];
  optionObjects?: DropdownOption[];
  translationPath?: string;
  updateCallBack?: EditorUpdateCallback;
}

const DropdownCell: React.FC<DropdownCellProps> = ({
  value,
  options,
  optionObjects,
  translationPath,
  updateCallBack,
}) => {
  return (
    <div className={'jtce-dropdown'}>
      <DropdownMenu
        selected={
          optionObjects
            ? optionObjects?.find((o) => o.id === value)?.name
            : value
        }
        options={options}
        optionObjects={optionObjects}
        onSelect={
          updateCallBack
            ? (value, id) => {
                updateCallBack([{ value: optionObjects ? id : value }]);
              }
            : undefined
        }
        height={30}
        disabled={
          options
            ? options.length === 0
            : optionObjects
            ? optionObjects.length === 0
            : false
        }
      />
    </div>
  );
};

export default DropdownCell;
