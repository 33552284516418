import { Store } from 'react-notifications-component';

const useNotifications = () => {
  const pushNotification = (
    message: string,
    type?: 'danger' | 'warning' | 'info' | 'success',
    duration?: number
  ) => {
    Store.addNotification({
      message: message,
      type: type ?? 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: duration ?? 5000,
      },
    });
  };

  return { pushNotification };
};

export default useNotifications;
