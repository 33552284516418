import './recurringcustomersbarpopup.css';
import { ReturningCustomersStatisticsResponse } from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import Badge from '../../../../elements/badge/Badge';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface RecurringCustomersBarPopupProps {
  data: ReturningCustomersStatisticsResponse;
  showBadge?: boolean;
}

const RecurringCustomersBarPopup: React.FC<RecurringCustomersBarPopupProps> = ({
  data,
  showBadge,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.widgets.recurringCustomers.tooltip',
  });

  return (
    <div className={'recurringCustomersBarPopup'}>
      {showBadge ? (
        <div
          className={
            'recurringCustomersBarPopup-element recurringCustomersBarPopup-element-badge'
          }
        >
          {data.guestAccount ? (
            <Badge title={t('guestAccount')} color={'var(--color-primary)'} />
          ) : (
            <Badge title={t('account')} color={'var(--color-blue)'} />
          )}
        </div>
      ) : null}
      <div className={'recurringCustomersBarPopup-element'}>
        <span>{data.numberOfCustomers}</span> {t('numberOfCustomers')}
      </div>
      <div className={'recurringCustomersBarPopup-element'}>
        <span>{data.totalRevenue}€</span> {t('totalRevenue')}
      </div>
      <div className={'recurringCustomersBarPopup-element'}>
        <span>{data.totalCustomersPercentage}%</span>{' '}
        {t('totalCustomersPercentage')}
      </div>
      {data.emails && data.emails.length > 0 ? (
        <div className={'recurringCustomersBarPopup-emails'}>
          {data.emails?.map((email) => (
            <div className={'recurringCustomersBarPopup-email'}>{email}</div>
          ))}
        </div>
      ) : (
        <EmptyState message={t('noEmails')} />
      )}
    </div>
  );
};

export default RecurringCustomersBarPopup;
