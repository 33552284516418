import './variantoptions.css';
import {
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../api/petcloudapi/api';
import PropertiesList from '../properties/propertieslist/PropertiesList';

interface VariantOptionsProps {
  options: PropertyOptionResponse[];
  availablePropertyGroups: PropertyGroupResponse[];
}

const VariantOptions: React.FC<VariantOptionsProps> = ({
  options,
  availablePropertyGroups,
}) => {
  const constructPropertyGroupsFromOptions = () => {
    return availablePropertyGroups
      .filter((group) => options.some((o) => o.propertyGroupId === group.id))
      .map((group) => {
        const groupOptions = options.filter(
          (option) => option.propertyGroupId === group.id
        );
        return {
          ...group,
          options: groupOptions,
        };
      });
  };

  return (
    <div className={'variantOptions'}>
      <PropertiesList
        availablePropertyGroups={availablePropertyGroups}
        propertyGroups={constructPropertyGroupsFromOptions()}
        productGroupMappings={[]}
        hideEmptyGroups
      />
    </div>
  );
};

export default VariantOptions;
