import './lazyimage.css';
import { useRef } from 'react';

interface LazyImageProps {
  src: string;
  className?: string;
  alt: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, className, alt }) => {
  const ref = useRef<HTMLImageElement>(null);

  const attachLoadedClass = () => {
    const img = ref.current;
    console.log('loaded img');
    if (img) {
      img.className = `${img.className} lazyImage-loaded`;
    }
  };

  return (
    <img
      ref={ref}
      src={src}
      className={`lazyImage ${className ?? ''}`}
      loading={'lazy'}
      alt={alt}
      onLoad={attachLoadedClass}
    />
  );
};

export default LazyImage;
