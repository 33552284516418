import './customer.css';
import { useNavigate } from 'react-router-dom';
import { CustomerResponse } from '../../../api/petcloudapi/api';
import { ReactComponent as IconGuest } from '../../../../assets/icon/guest.svg';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';

interface CustomerProps {
  customer: CustomerResponse;
  orderId: string;
}

const Customer: React.FC<CustomerProps> = ({ customer, orderId }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.order.customer',
  });
  const link = useNavigate();

  return (
    <div className="customer">
      <div className="customer-customer">
        <div
          className="global-textElement global-textLink"
          onClick={() => link('/customers/' + customer.id)}
        >
          {customer.firstName + ' ' + customer.lastName}
          {customer?.isGuest ? (
            <IconGuest
              className={'orderList-customerName-isGuest'}
              fill={'var(--color-primary)'}
            />
          ) : null}
        </div>
        <div className="customer-customer-number">
          {customer.customerNumber}
        </div>
      </div>
      <div className="global-textElement customer-email">{customer.email}</div>
      <div className={'customer-cta'}>
        <Button
          cta={t('cta')}
          look={'secondary'}
          action={() => {
            window.location.href = 'mailto:' + customer.email;
          }}
        />
      </div>
    </div>
  );
};

export default Customer;
