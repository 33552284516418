import { AnimalSpeciesResponse } from '../../../../api/petcloudapi/api';
import { useState } from 'react';
import AnimalSpeciesEditor from '../animalspecieseditor/AnimalSpeciesEditor';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';

interface AnimalSpeciesBulkUpdaterProps {
  availableAnimalSpecies: AnimalSpeciesResponse[];
  onSubmit: (animalSpecies: AnimalSpeciesResponse[]) => void;
}

const AnimalSpeciesBulkUpdater: React.FC<AnimalSpeciesBulkUpdaterProps> = ({
  availableAnimalSpecies,
  onSubmit,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.animalSpeciesBulkUpdater',
  });
  const [selectedAnimalSpecies, setSelectedAnimalSpecies] = useState<
    AnimalSpeciesResponse[]
  >([]);
  return (
    <div className={'animalSpeciesBulkUpdater'}>
      <AnimalSpeciesEditor
        availableAnimalSpecies={availableAnimalSpecies}
        animalSpecies={selectedAnimalSpecies}
        onDelete={(i) => {
          const update = [...selectedAnimalSpecies];
          update.splice(i, 1);
          setSelectedAnimalSpecies(update);
        }}
        onSubmit={(species) => {
          const update = [...selectedAnimalSpecies];
          update.push(species);
          setSelectedAnimalSpecies(update);
        }}
      />
      <div className={'global-cardActions'}>
        <Button
          cta={t('cta')}
          look={'save'}
          action={() => onSubmit(selectedAnimalSpecies)}
          width={'minimal'}
        />
      </div>
    </div>
  );
};

export default AnimalSpeciesBulkUpdater;
