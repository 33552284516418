import './titlevaluelist.css';
import TextHighlight from '../texthighlight/TextHighlight';
import { SmallHint } from '../hint/Hint';

interface TitleValueListProps {
  entries: {
    title: string;
    value?: any | null;
    renderMethod?: (value: any) => React.ReactNode;
    asHighlight?: boolean;
    highlightColor?: string;
    hint?: string;
  }[];
  inline?: boolean;
  spaceBetween?: boolean;
  showNonValues?: boolean;
  verticalSpacing?: 'small' | 'normal' | 'big';
}

const verticalSpacings = {
  small: 4,
  normal: 10,
  big: 20,
};

const TitleValueList: React.FC<TitleValueListProps> = ({
  entries,
  inline,
  spaceBetween,
  showNonValues,
  verticalSpacing,
}) => {
  return (
    <div
      className={`titlevaluelist ${inline ? 'titlevaluelist-inline' : null}`}
    >
      {entries.map((entry, i) => {
        if (entry.value || showNonValues) {
          return (
            <div
              key={i}
              className="titlevaluelist-entry"
              style={{
                justifyContent: spaceBetween ? 'space-between' : 'flex-start',
                marginBottom: !inline
                  ? verticalSpacing
                    ? verticalSpacings[verticalSpacing]
                    : verticalSpacings['normal']
                  : 0,
              }}
            >
              <div className="titlevaluelist-entry-title">{entry.title}</div>
              {entry.renderMethod ? (
                <div className="titlevaluelist-entry-value">
                  {entry.renderMethod(entry.value)}
                </div>
              ) : (
                <div className="titlevaluelist-entry-value">
                  {entry.asHighlight ? (
                    <TextHighlight
                      text={entry.value}
                      color={entry.highlightColor}
                    />
                  ) : (
                    entry.value ?? (
                      <div className={'titlevaluelist-entry-value-na'}>-</div>
                    )
                  )}
                  {entry.hint ? (
                    <div className={'titlevaluelist-entry-value-hint'}>
                      <SmallHint paragraphs={[entry.hint]} />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default TitleValueList;
