import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import ProductValidationList from '../../sections/productvalidation/ProductValidationList';
import { Dropdown } from '../../elements/selectors/Selectors';
import useManufacturerOptions from '../../hooks/useManufacturerOptions';
import { useEffect, useState } from 'react';
import { ProductValidationResultResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';

const ProductValidation = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productValidation',
  });
  const { manufacturerOptions } = useManufacturerOptions();
  const [mOpt, setMOpt] = useState(manufacturerOptions);
  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | null
  >(null);

  useEffect(() => {
    setMOpt(manufacturerOptions);
  }, [manufacturerOptions]);

  const filterMOpt = (results: ProductValidationResultResponse[]) => {
    if (manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION') {
      const filteredOptions = manufacturerOptions.filter(
        (option) =>
          results.some((result) => result.manufacturerId === option.id) ||
          option.id === 'all'
      );
      setMOpt(filteredOptions);
    }
  };

  if (mOpt) {
    return (
      <Has authorizations={['products:list']} showNoAuthorization>
        <Stage>
          <Navigator title={t('title')}>
            {mOpt && mOpt !== 'NO_PERMISSION' ? (
              <Dropdown
                optionObjects={mOpt}
                selected={
                  mOpt.find((x) => x.id === selectedManufacturerId)?.name
                }
                update={(e) => {
                  const id =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  setSelectedManufacturerId(id);
                }}
              />
            ) : null}
          </Navigator>
          <Card bigScreenWidth="100%" noMargin>
            <CardSection>
              <ProductValidationList
                manufacturerId={
                  selectedManufacturerId === 'all'
                    ? null
                    : selectedManufacturerId
                }
                callback={(results) => filterMOpt(results)}
              />
            </CardSection>
          </Card>
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ProductValidation;
