import './hoverable.css';
import { CSSProperties, useEffect, useState } from 'react';

interface HoverableProps {
  delay?: boolean;
  disabled?: boolean;
  onHoverNode?: React.ReactNode;
  stretchToLeft?: boolean;
  style?: CSSProperties;
  helperClass?: string;
  onClick?: () => void;
  onHoverStart?: () => void;
  onHoverEnd?: () => void;
  sticky?: boolean;
}

const Hoverable: React.FC<HoverableProps> = ({
  delay,
  disabled,
  onHoverNode,
  children,
  stretchToLeft,
  style,
  helperClass,
  onClick,
  onHoverStart,
  onHoverEnd,
  sticky,
}) => {
  const [show, setShow] = useState<{
    x: number;
    y: number;
    isRightHalf: boolean;
    isBottomHalf: boolean;
  } | null>(null);

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const setMouseLocation = (e: React.MouseEvent<HTMLDivElement>) => {
    const isRightHalf = e.clientX > window.innerWidth / 2;
    const isBottomHalf = e.clientY > window.innerHeight / 2;
    const loc = {
      x: e.clientX,
      y: e.clientY,
      isRightHalf,
      isBottomHalf,
    };
    if (delay) {
      timeout = setTimeout(() => setShow(loc), 1000);
    } else {
      setShow(loc);
    }
  };

  const handleMouseLeave = () => {
    setShow(null);
    console.log('leaving');
    clearTimeout(timeout);
    if (onHoverEnd) {
      onHoverEnd();
    }
  };

  return (
    <>
      <div
        className={`hoverable ${helperClass}`}
        onMouseEnter={
          !disabled
            ? (e) => {
                setMouseLocation(e);
                if (onHoverStart) {
                  onHoverStart();
                }
              }
            : undefined
        }
        onMouseLeave={!disabled && !sticky ? handleMouseLeave : undefined}
        style={style}
        onClick={onClick}
      >
        {children}
      </div>
      {show && onHoverNode ? (
        <div
          className={`hoverable-pop ${
            stretchToLeft ? 'hoverable-pop-stretchToLeft' : ''
          }`}
          style={{
            top: show.isBottomHalf ? 'auto' : show.y,
            bottom: show.isBottomHalf ? window.innerHeight - show.y : 'auto',
            left: show.isRightHalf ? 'auto' : show.x,
            right: show.isRightHalf ? window.innerWidth - show.x : 'auto',
            pointerEvents: sticky ? 'auto' : 'none',
          }}
          onMouseLeave={
            sticky
              ? () => {
                  handleMouseLeave();
                }
              : undefined
          }
        >
          {onHoverNode}
        </div>
      ) : null}
    </>
  );
};

export default Hoverable;
