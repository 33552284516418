import './shippingaddress.css';
import { useTranslation } from 'react-i18next';
import { OrderShippingAddressResponse } from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';

interface ShippingAddressProps {
  address: OrderShippingAddressResponse;
}

const ShippingAddress: React.FC<ShippingAddressProps> = ({ address }) => {
  const { t, i18n } = useTranslation();

  const getFullName = () => {
    const { salutation, firstName, lastName } = address;
    let fullName = '';
    if (salutation) {
      fullName += ' ' + salutation;
    }
    if (firstName) {
      fullName += ' ' + firstName;
    }
    if (lastName) {
      fullName += ' ' + lastName;
    }
    return fullName;
  };

  return (
    <div className="shippingaddress">
      <TitleValueList
        entries={[
          {
            title: t('view.order.address.name'),
            value: getFullName(),
          },
          {
            title: t('view.order.address.company'),
            value: address.company,
          },
          {
            title: t('view.order.address.street'),
            value: address.street,
          },
          {
            title: t('view.order.address.number'),
            value: address.streetNumber,
          },
          {
            title: t('view.order.address.additional1'),
            value: address.additionalAddressLine1,
          },
          {
            title: t('view.order.address.additional2'),
            value: address.additionalAddressLine2,
          },
          {
            title: t('view.order.address.zipCode'),
            value: address.zipCode,
          },
          {
            title: t('view.order.address.city'),
            value: address.city,
          },
          {
            title: t('view.order.address.state'),
            value: address.state,
          },
          {
            title: t('view.order.address.country'),
            value:
              address.country?.name[i18n.language as TranslatedStringIndex],
          },
        ]}
        spaceBetween
        showNonValues
        verticalSpacing={'small'}
      />
    </div>
  );
};

export default ShippingAddress;
