import './animalspeciesform.css';
import Input from '../../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import {
  AnimalSpeciesResponse,
  CreateAnimalSpeciesRequest,
} from '../../../../api/petcloudapi/api';

interface AnimalSpeciesFormProps {
  species: CreateAnimalSpeciesRequest | AnimalSpeciesResponse;
  update: (species: CreateAnimalSpeciesRequest | AnimalSpeciesResponse) => void;
}

const AnimalSpeciesForm: React.FC<AnimalSpeciesFormProps> = ({
  species,
  update,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.animalSpecies.new',
  });

  return (
    <div className={'animalSpeciesForm'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('name_de-DE')}
            content={species.name['de-DE']}
            update={(value) => {
              update({
                ...species,
                name: {
                  ...species.name,
                  'de-DE': value,
                },
              });
            }}
            required
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('slug_de-DE')}
            content={species.slug['de-DE']}
            update={(value) => {
              update({
                ...species,
                slug: {
                  ...species.slug,
                  'de-DE': value,
                },
              });
            }}
            required
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('name_en-GB')}
            content={species.name['en-GB']}
            update={(value) => {
              update({
                ...species,
                name: {
                  ...species.name,
                  'en-GB': value,
                },
              });
            }}
            required
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('slug_en-GB')}
            content={species.slug['en-GB']}
            update={(value) => {
              update({
                ...species,
                slug: {
                  ...species.slug,
                  'en-GB': value,
                },
              });
            }}
            required
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('identifier')}
            content={species.identifier}
            update={(value) => {
              update({
                ...species,
                identifier: value,
              });
            }}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default AnimalSpeciesForm;
