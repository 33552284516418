import './stockperproductlist.css';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../features/list/List';
import StocksPerProductObject, {
  WarehouseStock,
} from '../../types/StocksPerProductObject';
import useWarehouseColors from '../../hooks/useWarehouseColors';
import useListControlsSearch from '../../features/list/listcontrols/utils/useListControlsSearch';

interface StockPerProductListProps {
  productStocks: StocksPerProductObject[];
}

const StockPerProductList: React.FC<StockPerProductListProps> = ({
  productStocks,
}) => {
  const { t } = useTranslation('translations', { keyPrefix: 'view.logistics' });
  const getWarehouseColor = useWarehouseColors();
  const { listControlSearch, currentItems, query } = useListControlsSearch(
    t('search'),
    productStocks,
    ['manufacturerProductNumber', 'ean', 'productNumber', 'productName']
  );

  const renderStockTotal = useCallback((stock: number) => {
    console.log(stock);
    return (
      <div
        style={{
          color:
            stock > 10
              ? 'var(--color-success)'
              : stock > 0
              ? 'var(--color-yellow)'
              : 'var(--color-danger)',
        }}
      >
        {stock}
      </div>
    );
  }, []);

  const renderWarehouses = useCallback((warehouses: WarehouseStock[]) => {
    return (
      <div className="stockperproductlist-listItem">
        {warehouses.map((warehouse) => {
          return (
            <div className="stockperproductlist-listItem-warehouse">
              <div
                className={'stockperproductlist-listItem-warehouse-bar'}
                style={{
                  backgroundColor: getWarehouseColor(warehouse.name),
                }}
              ></div>
              <div
                className="stockperproductlist-listItem-warehouse-name"
                style={{
                  color: getWarehouseColor(warehouse.name),
                }}
              >
                {warehouse.name}
              </div>
              <div className="stockperproductlist-listItem-warehouse-stocks">
                <div className="stockperproductlist-listItem-warehouse-stock">
                  <span className="stockperproductlist-listItem-warehouse-stock-quantity">
                    <span className="stockperproductlist-listItem-warehouse-stock-quantity">
                      {warehouse.stock}
                      <span className="stockperproductlist-listItem-warehouse-stock-label">
                        {t('listItem.stock')}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  return (
    <div className="stockperproductlist">
      <List
        ignore={['productId']}
        items={currentItems}
        listControls={{
          search: listControlSearch,
        }}
        renderObjects={[
          {
            key: 'warehouses',
            renderMethod: renderWarehouses,
          },
          {
            key: 'totalStock',
            renderMethod: renderStockTotal,
            receiveNullValues: true,
          },
        ]}
        linkedKey={'productId'}
        linkPrefix={() => '/stocks/'}
        itemImgKey={'productCoverUri'}
        monoSpaceStrings={['productNumber', 'manufacturerProductNumber', 'ean']}
        queryString={query}
        queryKeys={[
          'ean',
          'productName',
          'productNumber',
          'manufacturerProductNumber',
        ]}
        sortValueFunctions={{
          productName: (stock) => {
            return stock.productName.toLowerCase();
          },
        }}
        adjustHeightToViewport
        isShowingIndex
      />
    </div>
  );
};

export default StockPerProductList;
