import './animalspeciescell.css';
import { AnimalSpeciesResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';

interface AnimalSpeciesCellProps {
  animalSpecies: AnimalSpeciesResponse[];
  selectCellElement: (element: AnimalSpeciesResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const AnimalSpeciesCell: React.FC<AnimalSpeciesCellProps> = ({
  animalSpecies,
  selectCellElement,
  isSelectedCellElement,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={'animalSpeciesCell'}>
      {animalSpecies?.map((species, i) => {
        return (
          <div
            key={i}
            className={`animalSpeciesCell-info ${
              isSelectedCellElement(species)
                ? 'animalSpeciesCell-info__selected'
                : ''
            }`}
            onClick={() => selectCellElement(species)}
          >
            {species.name[i18n.language as TranslatedStringIndex]}
          </div>
        );
      })}
    </div>
  );
};

export default AnimalSpeciesCell;
