const useBrowserTab = () => {
  const setTabTitle = (title: string, subtitle?: string | undefined | null) => {
    if (subtitle) {
      document.title = title + ' - ' + subtitle;
    } else {
      document.title = title;
    }
  };

  return setTabTitle;
};

export default useBrowserTab;
