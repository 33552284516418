import { Has } from '../../contexts/auth/Authorization';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useEffect, useState } from 'react';
import {
  ManufacturerDataExchangeDetailResponse,
  UserResponse,
} from '../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useParams } from 'react-router-dom';
import Card, { CardSection } from '../../elements/card/Card';
import { Navigator, Stage } from '../../layout/stage/Stage';
import TitleValueList from '../../elements/titlevaluelist/TitleValueList';
import Badge from '../../elements/badge/Badge';
import DateTime from '../../elements/datetime/DateTime';
import List from '../../features/list/List';
import { NoResults } from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import Button from '../../elements/button/Button';
import { ReactComponent as Copy } from '../../../assets/icon/copy.svg';
import { Store } from 'react-notifications-component';
import Popup from '../../elements/popup/Popup';
import JSONViewer from '../../elements/jsonviewer/JSONViewer';
import Input from '../../elements/input/Input';

const DataExchange = () => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const manufacturerDataExchangesApi = api.manufacturerDataExchangesApi();
  const params = useParams();
  const dataExchangeId = params.dataExchangeId;
  const errorHandler = useErrorHandler();
  const { user } = useUser();

  const [dataExchange, setDataExchange] =
    useState<ManufacturerDataExchangeDetailResponse | null>(null);
  const [JSONString, setJSONString] = useState<string | null>(null);

  useEffect(() => {
    getDataExchange();
  }, []);

  const getDataExchange = () => {
    if (dataExchangeId) {
      manufacturerDataExchangesApi
        .manufacturerDataExchangesGetManufacturerDataExchangeById(
          dataExchangeId
        )
        .then((response) => {
          console.log(response);
          setDataExchange(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const renderDirection = (value: string) => {
    return (
      <Badge
        title={t('list.states.' + value)}
        color={value === 'In' ? 'var(--color-blue)' : 'var(--color-primary)'}
      />
    );
  };

  const renderErrors = (count: number) => {
    if (count === 0) {
      return (
        <Badge
          title={t('view.dataExchanges.noErrors')}
          color={'var(--color-success)'}
        />
      );
    } else {
      return <Badge title={count.toString()} color={'var(--color-danger)'} />;
    }
  };

  const renderCreationDate = (dateString: string) => {
    return (
      <DateTime
        dateString={dateString}
        show={['day', 'month', 'year', 'time']}
      />
    );
  };

  // based on user type, some columns should not show
  const getIgnore = (userResponse: UserResponse) => {
    const result = [
      'id',
      'manufacturerDataExchangeId',
      'messageId',
      'updatedAt',
    ];
    if (!userResponse.isProductOwner) {
      result.push('sender');
      result.push('stackTrace');
    }
    return result;
  };

  const renderPayload = (payload: string) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type={'icon'}
          look={'secondary'}
          width={'minimal'}
          action={() => {
            navigator.clipboard.writeText(payload).then(() => {
              Store.addNotification({
                message: t('notifications.copiedToClipboard'),
                type: 'info',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 2000,
                },
              });
            });
          }}
        >
          <Copy
            className={'button-icon'}
            fill={'var(--color-text_secondary)'}
          />
        </Button>
        <Button
          look={'secondary'}
          width={'tiny'}
          action={() => setJSONString(payload)}
          cta={t('view.dataExchange.viewPayload')}
          margin={'left'}
        />
      </div>
    );
  };

  const renderStackTrace = (value: string) => {
    return <Input content={value} />;
  };

  return (
    <Has
      authorizations={['manufacturer_data_exchanges:detail']}
      showNoAuthorization
    >
      {dataExchange && user ? (
        <Stage>
          <Navigator
            title={t('view.dataExchange.title')}
            allowBackButton
          ></Navigator>
          <Card bigScreenWidth={'full'}>
            <CardSection>
              <TitleValueList
                entries={[
                  {
                    title: t('view.dataExchange.direction'),
                    value: dataExchange.direction,
                    renderMethod: renderDirection,
                  },
                  {
                    title: t('view.dataExchange.entity'),
                    value: dataExchange.entity,
                  },
                  {
                    title: t('view.dataExchange.action'),
                    value: dataExchange.action,
                  },
                  {
                    title: t('view.dataExchange.itemCount'),
                    value: dataExchange.itemCount,
                  },
                  {
                    title: t('view.dataExchange.errorCount'),
                    value: dataExchange.errorCount,
                    renderMethod: renderErrors,
                  },
                  {
                    title: t('view.dataExchange.createdAt'),
                    value: dataExchange.createdAt,
                    renderMethod: renderCreationDate,
                  },
                ]}
              />
              {dataExchange.manufacturerDataExchangeLogs.length > 0 ? (
                <List
                  items={dataExchange.manufacturerDataExchangeLogs}
                  ignore={getIgnore(user)}
                  renderObjects={[
                    {
                      key: 'payload',
                      renderMethod: renderPayload,
                    },
                    {
                      key: 'stackTrace',
                      renderMethod: renderStackTrace,
                    },
                  ]}
                  monoSpaceStrings={['message']}
                  dateStrings={['createdAt']}
                />
              ) : (
                <NoResults />
              )}
              {JSONString ? (
                <Popup
                  toggled={true}
                  width={'50%'}
                  close={() => setJSONString(null)}
                >
                  <div className={'popup-title'}>
                    {t('view.dataExchange.payload')}
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    <Button
                      type={'icon'}
                      look={'secondary'}
                      width={'minimal'}
                      action={() => {
                        navigator.clipboard.writeText(JSONString).then(() => {
                          Store.addNotification({
                            message: t('notifications.copiedToClipboard'),
                            type: 'info',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: [
                              'animate__animated',
                              'animate__fadeIn',
                            ],
                            animationOut: [
                              'animate__animated',
                              'animate__fadeOut',
                            ],
                            dismiss: {
                              duration: 2000,
                            },
                          });
                        });
                      }}
                    >
                      <Copy
                        className={'button-icon'}
                        fill={'var(--color-text_secondary)'}
                      />
                    </Button>
                  </div>
                  <JSONViewer jsonString={JSONString} />
                </Popup>
              ) : null}
            </CardSection>
          </Card>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default DataExchange;
