import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import languageTabs from '../../../../locate/LanguageTabs';
import {
  CreateProductRequest,
  ProductResponse,
  TranslatedTypeResponseOfString,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import { setErrorTab, Tab, TabConfig, Tabs } from '../../../elements/card/Card';
import FullEditor from '../../../elements/fulleditor/FullEditor';
import Input from '../../../elements/input/Input';
import { Error, hasError } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import './information.css';

interface InformationProps {
  product: ProductResponse | CreateProductRequest;
  brandName?: TranslatedTypeResponseOfString;
  parentProduct?: ProductResponse | null;
  updateProduct?: (product: ProductResponse) => void;
  updateNewProduct?: (product: CreateProductRequest) => void;
  submitCurrentProduct?: () => void;
  buttonActive?: boolean;
  productIsSubmitting?: boolean;
  readonly?: boolean;
  errors?: Error[];
  errorCallback?: () => void;
}

const Information: React.FC<InformationProps> = ({
  product,
  brandName,
  parentProduct,
  updateProduct,
  updateNewProduct,
  submitCurrentProduct,
  buttonActive,
  productIsSubmitting,
  readonly,
  errors,
  errorCallback,
}) => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<TabConfig[]>(languageTabs);

  const updateName = (content: string, lang: TranslatedStringIndex) => {
    if (updateProduct) {
      updateProduct({
        ...(product as ProductResponse),
        name: {
          ...product.name,
          [lang]: content,
        },
      });
    } else if (updateNewProduct) {
      updateNewProduct({
        ...(product as CreateProductRequest),
        name: {
          ...product.name,
          [lang]: content,
        },
      });
    }
  };

  const updateSeoTitle = (content: string, lang: TranslatedStringIndex) => {
    if (updateProduct) {
      updateProduct({
        ...(product as ProductResponse),
        seoProductTitle: {
          ...product.seoProductTitle,
          [lang]: content,
        },
      });
    } else if (updateNewProduct) {
      updateNewProduct({
        ...(product as CreateProductRequest),
        seoProductTitle: {
          ...product.seoProductTitle,
          [lang]: content,
        },
      });
    }
  };

  const updateDescription = (content: string, lang: TranslatedStringIndex) => {
    if (updateProduct) {
      updateProduct({
        ...(product as ProductResponse),
        description: {
          ...product.description,
          [lang]: content,
        },
      });
    } else if (updateNewProduct) {
      updateNewProduct({
        ...(product as CreateProductRequest),
        description: {
          ...product.description,
          [lang]: content,
        },
      });
    }
  };

  const renderTab = (lang: TranslatedStringIndex) => {
    return (
      <Tab key={lang}>
        <div className="card-tab-content">
          <div className="global-inputGroup">
            <div className="global-inputGroup-input">
              <Input
                title={t('view.product.information.name')}
                content={product.name[lang]}
                update={(e) => {
                  updateName(e, lang);
                }}
                required={true}
                errors={hasError(
                  errors,
                  lang === 'de-DE'
                    ? [
                        'Name.TranslatedName.German.Name',
                        'Translations[0].Name',
                      ]
                    : [
                        'Name.TranslatedName.English.Name',
                        'Translations[1].Name',
                      ],
                  () => setErrorTab(tabs, setTabs, lang, true, errorCallback)
                )}
              />
            </div>
            <div className="global-inputGroup-input">
              <Input
                title={t('view.product.information.seoName')}
                content={product.seoProductTitle?.[lang]}
                update={(e) => {
                  updateSeoTitle(e, lang);
                }}
                prefix={
                  brandName
                    ? brandName[lang]
                    : t('view.product.information.brandNamePlaceholder')
                }
                hint={t('view.product.information.seoName_hint')}
              />
            </div>
          </div>
          <FullEditor
            title={t('view.product.information.description')}
            required={true}
            initialHTMLValue={product.description[lang]}
            inherited={parentProduct?.description[lang]}
            onUpdate={(content: string) => {
              updateDescription(content, lang);
            }}
            errors={hasError(
              errors,
              lang === 'de-DE'
                ? [
                    'Description.TranslatedDescription.German.Description',
                    'Translations[0].Description',
                  ]
                : [
                    'Description.TranslatedDescription.English.Description',
                    'Translations[1].Description',
                  ],
              () => setErrorTab(tabs, setTabs, lang, true, errorCallback)
            )}
          />
        </div>
      </Tab>
    );
  };

  return (
    <div className="information">
      <div className="card-tabs-wrapper">
        <Tabs tabs={tabs} parentTab={'#product'}>
          {renderTab('de-DE')}
          {renderTab('en-GB')}
        </Tabs>
      </div>
      {submitCurrentProduct && !readonly ? (
        <div className="information-actions global-cardActions">
          <Button
            cta={t('actions.save')}
            look="save"
            width="minimal"
            action={() => submitCurrentProduct()}
            active={readonly ? false : buttonActive}
            isLoading={productIsSubmitting}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Information;
