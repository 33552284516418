import './monthlymanufacturerinvoices.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useState } from 'react';
import DatePicker from '../../../elements/datepicker/DatePicker';
import Button from '../../../elements/button/Button';
import useFileDownload from '../../../hooks/useFileDownload';

const MonthlyManufacturerInvoices = () => {
  const { t } = useTranslation();

  const api = usePetCloudApi();
  const manufacturerMonthlyInvoicesApi = api.manufacturerMonthlyInvoicesApi();
  const errorHandler = useErrorHandler();
  const { downloadFromBlob } = useFileDownload();

  const [date, setDate] = useState(new Date());
  const [commissionDownloading, setCommissionDownloading] = useState(false);
  const [reportDownloading, setReportDownloading] = useState(false);

  const getCommission = () => {
    setCommissionDownloading(true);
    manufacturerMonthlyInvoicesApi
      .manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(
        date.getMonth(),
        date.getFullYear(),
        undefined,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        console.log(response);
        downloadFromBlob(response);
        setCommissionDownloading(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setCommissionDownloading(false);
      });
  };

  const getReport = () => {
    setReportDownloading(true);
    manufacturerMonthlyInvoicesApi
      .manufacturerMonthlyInvoicesGetManufacturerReportCsv(
        date.getMonth(),
        date.getFullYear(),
        undefined,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        console.log(response);
        downloadFromBlob(response);
        setReportDownloading(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setReportDownloading(false);
      });
  };

  return (
    <div className={'monthlyManufacturerInvoices'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-inputInline'}>
          <DatePicker
            title={t('view.invoices.monthlyManufacturerInvoices.date')}
            selected={date}
            onChange={(date) => (date ? setDate(date) : null)}
            dateFormat={'MM/y'}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-inputInline'}>
          <Button
            cta={t('view.invoices.monthlyManufacturerInvoices.cta_report')}
            look={'secondary'}
            width={'minimal'}
            action={getCommission}
            isLoading={commissionDownloading}
          />
        </div>
        <div className={'global-inputGroup-inputInline'}>
          <Button
            cta={t('view.invoices.monthlyManufacturerInvoices.cta_commission')}
            look={'secondary'}
            width={'minimal'}
            action={getReport}
            isLoading={reportDownloading}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyManufacturerInvoices;
