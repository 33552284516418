import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  ProductResponse,
  ProductStockResponse,
} from '../../api/petcloudapi/api';
import Badge from '../../elements/badge/Badge';
import { CardSection } from '../../elements/card/Card';
import List from '../../features/list/List';
import { LoadingContainer } from '../../elements/loading/Loading';
import Retractable from '../../elements/retractable/Retractable';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Has } from '../../contexts/auth/Authorization';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import { renderQuality } from '../purchaseorders/PurchaseOrder';

const StocksOfOneProduct = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const productId = params.productId;
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const productStocksApi = api.productStocksApi();
  const errorHandler = useErrorHandler();

  const [product, setProduct] = useState<ProductResponse | null>(null);
  const [stocks, setStocks] = useState<ProductStockResponse[] | null>(null);

  useEffect(() => {
    getProduct();
    getProductStocks();
  }, [productId]);

  const getProduct = () => {
    if (productId) {
      productsApi
        .productsGetProductById(productId, true)
        .then((response) => {
          console.log(response);
          setProduct(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const getProductStocks = () => {
    if (productId) {
      productStocksApi
        .productStocksGetProductStocks(undefined, productId)
        .then((response) => {
          console.log(response);
          setStocks(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const renderStockHead = (stock: ProductStockResponse) => {
    return (
      <>
        <div className="retractable-head-element">
          {t('view.logistics.stocksofoneproduct.stock.stockTotal') +
            ': ' +
            stock.stock}
        </div>
        <div className="retractable-head-element">
          {t('view.logistics.stocksofoneproduct.stock.warehouse') +
            ': ' +
            stock.warehouse.name}
        </div>
        <div className="retractable-head-element">
          <Badge
            title={
              stock.available
                ? t('view.logistics.stocksofoneproduct.stock.available')
                : t('view.logistics.stocksofoneproduct.stock.not_available')
            }
            color={
              stock.available ? 'var(--color-save)' : 'var(--color-danger)'
            }
          />
        </div>
      </>
    );
  };

  // const renderQuality = (quality: string | null) => {
  //   return (
  //     <Badge
  //       value={
  //         quality === 'Defective'
  //           ? t('view.purchaseorder.positions.quality_Defective')
  //           : t('view.purchaseorder.positions.quality_OK')
  //       }
  //       color={
  //         quality === 'Defective' ? 'var(--color-danger)' : 'var(--color-save)'
  //       }
  //     />
  //   );
  // };

  return (
    <Has authorizations={['product_stocks:list']} showNoAuthorization>
      {product !== null && stocks !== null ? (
        <Stage>
          <Navigator
            title={
              product.name[i18n.language as TranslatedStringIndex] +
              ' ' +
              t('view.logistics.stocksofoneproduct.titlePostfix')
            }
            allowBackButton={true}
          ></Navigator>
          {stocks.length > 0 ? (
            stocks.map((stock, i) => {
              return (
                <Retractable
                  key={i}
                  title={
                    t('view.logistics.stocksofoneproduct.stock.title') +
                    ' #' +
                    i
                  }
                  head={renderStockHead(stock)}
                  forbidRetraction={
                    !(
                      stock.productStockDetails &&
                      stock.productStockDetails.length > 0
                    )
                  }
                  hasBottomMargin={true}
                >
                  {stock.productStockDetails &&
                  stock.productStockDetails.length > 0 ? (
                    <CardSection>
                      <List
                        items={stock.productStockDetails}
                        ignore={[
                          'productStockId',
                          'id',
                          'createdAt',
                          'updatedAt',
                        ]}
                        renderObjects={[
                          {
                            key: 'quality',
                            renderMethod: (q) => renderQuality(q, t),
                          },
                        ]}
                      />
                    </CardSection>
                  ) : null}
                </Retractable>
              );
            })
          ) : (
            <EmptyState
              message={t('view.logistics.stocksofoneproduct.stock.empty')}
            />
          )}
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default StocksOfOneProduct;
