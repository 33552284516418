import './dailybestsellers.css';
import { useState } from 'react';
import {
  BestSellingProductsOrderBy,
  DailyBestSellingProductsResponse,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useTranslation } from 'react-i18next';
import DailyBestsellersChart from './dailybestsellerschart/DailyBestsellersChart';
import { Tab, TabConfig, Tabs } from '../../../elements/card/Card';
import DailyBestsellerList from './dailybestsellerslist/DailyBestsellerList';
import BestSellersFetcher from '../common/bestsellersfetcher/BestSellersFetcher';

// date ranges are in DateOnly (yyyy-mm-dd) format
interface DailyBestSellersProps {
  dateRangeFrom: string;
  dateRangeTo: string;
  selectedManufacturerId?: string | null;
}

const DailyBestSellers: React.FC<DailyBestSellersProps> = ({
  dateRangeFrom,
  dateRangeTo,
  selectedManufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.widgets.dailyBestsellers',
  });

  const [dailyBestSellers, setDailyBestSellers] = useState<
    DailyBestSellingProductsResponse[] | null
  >(null);
  const [selectedOrderBy, setSelectedOrderBy] =
    useState<BestSellingProductsOrderBy>(BestSellingProductsOrderBy.Revenue);

  const tabs: TabConfig[] = [
    {
      key: 'chart',
      title: t('tabs.chart'),
    },
    {
      key: 'list',
      title: t('tabs.list'),
    },
  ];

  return (
    <div className={'dailyBestsellers'}>
      <BestSellersFetcher
        dateRangeFrom={dateRangeFrom}
        dateRangeTo={dateRangeTo}
        selectedManufacturerId={selectedManufacturerId}
        onFetch={(responses) =>
          setDailyBestSellers(responses as DailyBestSellingProductsResponse[])
        }
        onOrder={(order) => setSelectedOrderBy(order)}
        mode={'daily'}
      />
      {dailyBestSellers ? (
        <div className={'dailyBestsellers-tabs'}>
          <Tabs tabs={tabs}>
            <Tab>
              <div className={'dailyBestsellers-tab'}>
                <DailyBestsellersChart
                  dailyBestsellers={dailyBestSellers}
                  orderBy={selectedOrderBy}
                />
              </div>
            </Tab>
            <Tab>
              <div className={'dailyBestsellers-tab'}>
                <DailyBestsellerList
                  dailyBestsellers={dailyBestSellers}
                  orderBy={selectedOrderBy}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      ) : (
        <LoadingContainer />
      )}
    </div>
  );
};

export default DailyBestSellers;
