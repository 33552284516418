import './salesoverview.css';
import { useTranslation } from 'react-i18next';
import { DailyRevenueResponse } from '../../../api/petcloudapi/api';
import ValueComparison from '../../../elements/valuecomparison/ValueComparison';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { injectMissingDays } from '../../../view/dashboard/Dashboard';
import { SmallHint } from '../../../elements/hint/Hint';
import { useUser } from '../../../contexts/auth/User';
import useDateTools from '../../../hooks/useDateTools';

type SalesOverViewMode = 'revenue' | 'orders';

interface SalesOverviewProps {
  height?: number;
  revenueData: DailyRevenueResponse[];
  mode: SalesOverViewMode;
  selectedManufacturerId?: string | null;
}

const SalesOverview: React.FC<SalesOverviewProps> = ({
  height,
  revenueData,
  mode,
  selectedManufacturerId,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const dashboardsApi = api.dashboardsApi();
  const errorHandler = useErrorHandler();
  const { getDateOnlyFromDate } = useDateTools();
  const { user } = useUser();

  const [fixedRevenueData, setFixedRevenueData] = useState<
    DailyRevenueResponse[] | null
  >(null);
  const [sortedFixedRevenueData, setSortedFixedRevenueData] = useState<{
    thisMonth: DailyRevenueResponse[];
    lastMonth: DailyRevenueResponse[];
    twoMonthsAgo: DailyRevenueResponse[];
  }>({
    thisMonth: [],
    lastMonth: [],
    twoMonthsAgo: [],
  });

  useEffect(() => {
    getFixedData();
  }, [selectedManufacturerId]);

  // this is to display revenues like past week or month regardless of what time range is currently selected by the user
  const getFixedData = () => {
    const date = new Date();
    const today = getDateOnlyFromDate(date) ?? 'error';
    const twoMonthsAgo = new Date(date.setMonth(date.getMonth() - 2));
    const beginningOfTwoMonthsAgo =
      twoMonthsAgo.getFullYear() +
      '-' +
      (twoMonthsAgo.getMonth() + 1) +
      '-' +
      '01';

    dashboardsApi
      .dashboardsGetDailyRevenue(
        selectedManufacturerId && selectedManufacturerId !== 'all'
          ? selectedManufacturerId
          : undefined,
        beginningOfTwoMonthsAgo,
        today
      )
      .then((response) => {
        console.log(response);
        const result = injectMissingDays(
          response.data,
          new Date(beginningOfTwoMonthsAgo),
          new Date()
        );
        setFixedRevenueData(result);
        setSortedFixedRevenueData(splitDataByMonths(result));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const splitDataByMonths = (dailyRevenues: DailyRevenueResponse[]) => {
    const thisMonth: DailyRevenueResponse[] = [];
    const lastMonth: DailyRevenueResponse[] = [];
    const twoMonthsAgo: DailyRevenueResponse[] = [];

    const date = new Date();
    const todayDate = new Date(date);
    const lastMonthDate = new Date(date.setMonth(date.getMonth() - 1));
    const twoMonthsAgoDate = new Date(date.setMonth(date.getMonth() - 1));

    const thisMonthString = (todayDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const lastMonthString = (lastMonthDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const twoMonthsAgoString = (twoMonthsAgoDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');

    console.log(thisMonthString, lastMonthString, twoMonthsAgoString);

    dailyRevenues.forEach((revenue) => {
      if (revenue.date.substring(5, 7) === thisMonthString) {
        thisMonth.push(revenue);
      } else if (revenue.date.substring(5, 7) === lastMonthString) {
        lastMonth.push(revenue);
      } else if (revenue.date.substring(5, 7) === twoMonthsAgoString) {
        twoMonthsAgo.push(revenue);
      }
    });

    return {
      thisMonth: thisMonth,
      lastMonth: lastMonth,
      twoMonthsAgo: twoMonthsAgo,
    };
  };

  const getTotal = (
    array: DailyRevenueResponse[],
    countFailedOrders?: boolean
  ) => {
    let initialValue = 0;
    const total = array.reduce((prev, next) => {
      return (
        prev +
        (mode === 'revenue'
          ? next.totalRevenue
          : countFailedOrders
          ? next.totalFailedOrders
          : next.totalSuccessOrders)
      );
    }, initialValue);
    return parseFloat(total.toFixed(2));
  };

  const getAverage = (array: DailyRevenueResponse[]) => {
    const total = getTotal(array);
    return parseFloat((total / revenueData.length).toFixed(2));
  };

  const getToday = () => {
    const today = getDateOnlyFromDate(new Date());
    console.log(today);
    console.log(fixedRevenueData);
    const result = fixedRevenueData?.find((r) => r.date === today);
    return result
      ? mode === 'revenue'
        ? parseFloat(result.totalRevenue.toFixed(2))
        : result.totalSuccessOrders
      : null;
  };

  const getPastDay = (daysBack: number) => {
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - daysBack))
      .toISOString()
      .split('T')[0];
    const result = fixedRevenueData?.find((r) => r.date === yesterday);
    return result
      ? mode === 'revenue'
        ? parseFloat(result.totalRevenue.toFixed(2))
        : result.totalSuccessOrders
      : null;
  };

  const getPast7Days = () => {
    if (fixedRevenueData) {
      const past7DaysStart = fixedRevenueData.length - 7;
      const array = fixedRevenueData.slice(past7DaysStart);
      return getTotal(array);
    }
  };

  const getPast7DaysBefore = () => {
    if (fixedRevenueData) {
      const past7DaysStart = fixedRevenueData.length - 7;
      const past14DaysStart = fixedRevenueData.length - 14;
      const array = fixedRevenueData.slice(past14DaysStart, past7DaysStart);
      return getTotal(array);
    }
  };

  return (
    <div className="salesOverview" style={{ height: height }}>
      <div className="salesOverview-spotlights">
        <div className="salesOverview-spotlight">
          <SmallHint
            paragraphs={[t('components.salesOverview.successAndFailedHint')]}
            isToolTip
            disabled={mode === 'revenue' || !user?.isProductOwner}
          >
            <div className="salesOverview-spotlight-value">
              <div
                style={
                  mode === 'orders' && user?.isProductOwner
                    ? { color: 'var(--color-success)' }
                    : undefined
                }
              >
                {getTotal(revenueData) + (mode === 'revenue' ? ' €' : '')}
              </div>
              {mode === 'orders' && user?.isProductOwner ? (
                <>
                  <span>/</span>
                  <div style={{ color: 'var(--color-danger)' }}>
                    {getTotal(revenueData, true)}
                  </div>
                </>
              ) : null}
            </div>
          </SmallHint>
          <div className="salesOverview-spotlight-title">
            {t('components.salesOverview.total')}
          </div>
        </div>
        <div className="salesOverview-spotlight">
          <div className="salesOverview-spotlight-value">
            {getAverage(revenueData) + (mode === 'revenue' ? ' €' : '')}
          </div>
          <div className="salesOverview-spotlight-title">
            {t('components.salesOverview.avgPerDay')}
          </div>
        </div>
      </div>
      <SalesOverViewElement
        title={t('components.salesOverview.today')}
        value={getToday() ?? 0}
        compareValue={getPastDay(1) ?? 0}
        mode={mode}
      />
      <SalesOverViewElement
        title={t('components.salesOverview.yesterday')}
        value={getPastDay(1) ?? 0}
        compareValue={getPastDay(2) ?? 0}
        mode={mode}
      />
      <SalesOverViewElement
        title={t('components.salesOverview.past7days')}
        value={getPast7Days() ?? 0}
        compareValue={getPast7DaysBefore() ?? 0}
        mode={mode}
      />
      <SalesOverViewElement
        title={t('components.salesOverview.thisMonth')}
        value={getTotal(sortedFixedRevenueData.thisMonth) ?? 0}
        compareValue={getTotal(sortedFixedRevenueData.lastMonth) ?? 0}
        mode={mode}
      />
      <SalesOverViewElement
        title={t('components.salesOverview.lastMonth')}
        value={getTotal(sortedFixedRevenueData.lastMonth) ?? 0}
        compareValue={getTotal(sortedFixedRevenueData.twoMonthsAgo) ?? 0}
        mode={mode}
      />
    </div>
  );
};

interface SalesOverViewElementProps {
  title: string;
  value: number | null;
  compareValue: number | null;
  mode: SalesOverViewMode;
}

const SalesOverViewElement: React.FC<SalesOverViewElementProps> = ({
  title,
  value,
  compareValue,
  mode,
}) => {
  return (
    <div className="salesOverviewElement">
      <div className="salesOverviewElement-title">{title}</div>
      <div className="salesOverviewElement-value">
        <div className="salesOverviewElement-value-amount">
          {value + (mode === 'revenue' ? ' €' : '')}
        </div>
        {value !== null && compareValue !== null ? (
          <div className="salesOverviewElement-value-comparison">
            <ValueComparison value={value} compareValue={compareValue} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SalesOverview;
