import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  OrderPositionResponse,
  OrderResponse,
  OrderReturnResponse,
} from '../../../api/petcloudapi/api';
import Badge from '../../../elements/badge/Badge';
import Button from '../../../elements/button/Button';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import List from '../../../features/list/List';
import Popup from '../../../elements/popup/Popup';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import NewReturn from './newreturn/NewReturn';
import ReturnEdit from './returnedit/ReturnEdit';
import './returns.css';
import packageReturn from '../../../../assets/img/package_return.svg';
import { useAuthorization } from '../../../contexts/auth/Authorization';
import { useUser } from '../../../contexts/auth/User';
import { getOrderReturnPositionEntries } from '../positions/Positions';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { ReactComponent as IconEye } from '../../../../assets/icon/eye.svg';

interface ReturnsProps {
  order: OrderResponse;
  returns: OrderReturnResponse[] | null | undefined;
  userCanCreate?: boolean;
  orderPositions: OrderPositionResponse[];
  getOrder: () => void;
  isViableForRefunding?: boolean;
}

const Returns: React.FC<ReturnsProps> = ({
  returns,
  userCanCreate,
  orderPositions,
  order,
  getOrder,
  isViableForRefunding,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const { authorizations } = useAuthorization();
  const { user } = useUser();
  const orderReturnsApi = api.orderReturnsApi();
  const errorHandler = useErrorHandler();
  const [newReturnPopup, setNewReturnPopup] = useState(false);
  const [returnInFocus, setReturnInFocus] =
    useState<OrderReturnResponse | null>(null);
  const [notesInFocus, setNotesInFocus] = useState<string | null>(null);

  const renderOrderReturnPositions = (positions: OrderReturnResponse[]) => {
    return <div>{positions.length}</div>;
  };

  const toTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${hours.toString().padStart(2, '0')}h:${mins
      .toString()
      .padStart(2, '0')}m`;
  };

  const renderLocked = (locked: boolean, position: OrderReturnResponse) => {
    const now = new Date();
    const createdAt = new Date(position.createdAt);
    const diffInMinutes = Math.floor(
      (now.getTime() - createdAt.getTime()) / 60000
    );
    const remainingMinutes = 2880 - diffInMinutes;
    console.log(remainingMinutes);

    return (
      <div>
        <Badge
          title={
            locked
              ? t('view.order.returns.locked.true') +
                ' ' +
                toTime(remainingMinutes)
              : t('view.order.returns.locked.false')
          }
          color={locked ? 'var(--color-primary)' : 'var(--color-success)'}
        />
      </div>
    );
  };

  const deleteReturn = (orderReturnId: string) => {
    orderReturnsApi
      .orderReturnsDeleteOrderReturn(orderReturnId)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.order.returns.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        getOrder();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderNotes = useCallback((value: string) => {
    return (
      <Button
        type={'icon'}
        look={'secondary'}
        width={'minimal'}
        action={() => setNotesInFocus(value)}
      >
        <IconEye className={'button-icon'} />
      </Button>
    );
  }, []);

  return (
    <div className="returns">
      {returns && returns.length > 0 ? (
        <List
          items={returns}
          ignore={['id', 'orderId', 'updatedAt']}
          dateStrings={['createdAt']}
          renderObjects={[
            {
              key: 'orderReturnPositions',
              renderMethod: renderOrderReturnPositions,
            },
            {
              key: 'locked',
              renderMethod: renderLocked,
            },
          ]}
          onRowClick={(returnResponse) => setReturnInFocus(returnResponse)}
          actions={[
            {
              cta: 'delete',
              look: 'danger',
              action: (orderReturn) => {
                deleteReturn(orderReturn.id);
              },
            },
            {
              cta: 'detail',
              look: 'blue',
              action: (orderReturn) => {
                setReturnInFocus(orderReturn);
              },
            },
          ]}
        />
      ) : (
        <EmptyState
          img={packageReturn}
          message={t('view.order.returns.empty')}
        />
      )}
      {userCanCreate && authorizations?.includes('order_returns:create') ? (
        <div className="global-cardActions-postBorder">
          <Button
            cta={t('view.order.returns.add')}
            look={'secondary'}
            width="minimal"
            action={() => setNewReturnPopup(true)}
          />
        </div>
      ) : null}
      <Popup
        toggled={newReturnPopup}
        width="50%"
        close={() => setNewReturnPopup(false)}
      >
        <div className="popup-title">{t('view.order.returns.new.title')}</div>
        <NewReturn
          order={order}
          orderPositions={orderPositions}
          closePopup={() => setNewReturnPopup(false)}
          getOrder={getOrder}
        />
      </Popup>
      <Popup
        toggled={!!returnInFocus}
        width="50%"
        close={() => setReturnInFocus(null)}
      >
        <div className="popup-title">{t('view.order.returns.edit.title')}</div>
        {returnInFocus ? (
          order.warehouse?.type === 'CentralWarehouse' ||
          user?.isProductOwner ? (
            <ReturnEdit
              order={order}
              orderPositions={orderPositions}
              orderReturn={returnInFocus}
              closePopup={() => setReturnInFocus(null)}
              getOrder={getOrder}
              isViableForRefunding={isViableForRefunding}
              isEditable={order.warehouse?.type === 'ManufacturerWarehouse'}
            />
          ) : (
            <List
              items={getOrderReturnPositionEntries(
                orderPositions,
                returnInFocus.orderReturnPositions,
                i18n.language as TranslatedStringIndex
              )}
              itemImgKey={'coverUri'}
              ignore={[
                'id',
                'positionId',
                'orderReturnId',
                'productId',
                'createdAt',
                'price',
              ]}
              monoSpaceStrings={['ean']}
              renderObjects={[
                {
                  key: 'notes',
                  renderMethod: renderNotes,
                },
              ]}
            />
          )
        ) : null}
        <Popup
          toggled={!!notesInFocus}
          width={'40%'}
          close={() => setNotesInFocus(null)}
        >
          <div className={'popup-title'}>
            {t('view.order.returns.notesPopupTitle')}
          </div>
          {notesInFocus}
        </Popup>
      </Popup>
    </div>
  );
};

export default Returns;
