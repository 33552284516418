import './profileoverview.css';
import { UserResponse } from '../../../../api/petcloudapi/api';
import ProfileImage from '../../../../elements/dogtag/profileimage/ProfileImage';
import RoleNameMapper from '../../roles/rolenamemapper/RoleNameMapper';

interface ProfileOverviewProps {
  user: UserResponse;
}

const ProfileOverview: React.FC<ProfileOverviewProps> = ({ user }) => {
  return (
    <div className="profileOverview">
      <div className="profileOverview-image">
        <ProfileImage
          name={user.firstName}
          lastName={user.lastName}
          size={100}
        />
      </div>
      <div className={'profileOverview-meta'}>
        <div className={'profileOverview-meta-name'}>
          {`${user.firstName} ${user.lastName}`}
          <span>@{user.username}</span>
        </div>
        <div className={'profileOverview-meta-email'}>{user.email}</div>
        <div className={'profileOverview-meta-roles'}>
          {user.userRoles.map((role) => {
            return (
              <div className={'profileOverview-meta-role'}>
                <RoleNameMapper role={role} withBackground={true} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
