import { BadgeProps } from '../../../elements/badge/Badge';
import { IntegrationResponse } from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';

const useIntegrationBadges = (integration?: IntegrationResponse) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.integrationCard',
  });

  const getBadges = () => {
    const badges: BadgeProps[] = [];
    if (!integration) {
      badges.push({
        title: t('badge.notPresent'),
        color: 'var(--color-danger)',
      });
    } else {
      if (!integration.config) {
        badges.push({
          title: t('badge.notConfigured'),
          color: 'var(--color-text_secondary)',
        });
      } else {
        badges.push({
          title: t('badge.configured'),
          color: 'var(--color-success)',
        });
        if (integration.config.installed) {
          badges.push({
            title: t('badge.installed'),
            color: 'var(--color-success)',
          });
        } else {
          badges.push({
            title: t('badge.notInstalled'),
            color: 'var(--color-text_secondary)',
          });
        }
      }
    }
    return badges;
  };

  return {
    getBadges,
  };
};

export default useIntegrationBadges;
