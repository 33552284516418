import './dailybestsellerschart.css';
import {
  BestSellingProductsOrderBy,
  DailyBestSellingProductsResponse,
} from '../../../../api/petcloudapi/api';
import BarChart from '../../common/charts/barchart/BarChart';
import DailyBestSellersBarTooltip from '../../common/charts/bartooltip/DailyBestSellersBarTooltip';

interface DailyBestsellersChartProps {
  dailyBestsellers: DailyBestSellingProductsResponse[];
  orderBy: BestSellingProductsOrderBy;
}

const DailyBestsellersChart: React.FC<DailyBestsellersChartProps> = ({
  dailyBestsellers,
  orderBy,
}) => {
  return (
    <BarChart
      data={dailyBestsellers}
      stackKey={'products'}
      xAxisKey={'date'}
      yAxisKey={orderBy === 'Revenue' ? 'totalRevenue' : 'quantity'}
      yAxisUnit={'€'}
      xAxisIsDate
      barColorIdKey={'productId'}
      onHover={(x) => <DailyBestSellersBarTooltip soldProduct={x} />}
      keyHighlightOnHover
    />
  );
};

export default DailyBestsellersChart;
