import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Has } from '../../contexts/auth/Authorization';
import {
  Stage,
  Navigator,
  StageColumns,
  StageColumn,
} from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import Button from '../../elements/button/Button';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { CustomerResponse } from '../../api/petcloudapi/api';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import Profile from '../../sections/customer/profile/Profile';
import OrderList from '../../features/orderlist/OrderList';
import RankOverview from '../../sections/customer/rankoverview/RankOverview';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import prohibit from '../../../assets/icon/prohibit_gray.svg';

const Customer = () => {
  const { t } = useTranslation();
  const params = useParams();
  const customerId = params.customerId;
  const api = usePetCloudApi();
  const customersApi = api.customersApi();
  const errorHandler = useErrorHandler();
  const [customer, setCustomer] = useState<CustomerResponse | null>(null);

  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = () => {
    if (customerId) {
      customersApi
        .customersGetCustomerById(customerId)
        .then((response) => {
          console.log(response);
          setCustomer(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  return (
    <Has authorizations={['customers:detail']} showNoAuthorization>
      {customer ? (
        <Stage>
          <Navigator
            title={
              t('view.customer.title') +
              ' ' +
              customer.firstName +
              ' ' +
              customer.lastName
            }
            badges={
              customer.isGuest
                ? [
                    {
                      title: t('view.customer.isGuest'),
                      color: 'var(--color-primary)',
                    },
                  ]
                : undefined
            }
            allowBackButton
          ></Navigator>
          <StageColumns>
            <StageColumn size="two">
              <Card
                bigScreenWidth="100%"
                title={t('view.customer.profile.title')}
              >
                <CardSection>
                  <Profile customer={customer} />
                </CardSection>
              </Card>
              <Card
                bigScreenWidth="100%"
                title={t('view.customer.orders.title')}
              >
                <CardSection>
                  <OrderList customerId={customer.id} hideUnfulfilledOrders />
                </CardSection>
              </Card>
            </StageColumn>
            <StageColumn size="one">
              <Card
                bigScreenWidth="100%"
                title={t('view.customer.loyaltyRank.title')}
              >
                <CardSection>
                  {customer.attributes?.LoyaltyOptIn ? (
                    <RankOverview
                      customer={customer}
                      activeRank={customer.latestActiveRank}
                    />
                  ) : (
                    <EmptyState
                      img={prohibit}
                      message={t('view.customer.loyaltyRank.noOptIn')}
                    />
                  )}
                </CardSection>
              </Card>
              <Card
                bigScreenWidth="100%"
                title={t('view.customer.interaction.title')}
              >
                <CardSection>
                  <Button
                    cta={t('view.customer.interaction.email')}
                    look={'secondary'}
                    action={() => {
                      window.location.href = 'mailto:' + customer.email;
                    }}
                  />
                </CardSection>
              </Card>
            </StageColumn>
          </StageColumns>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Customer;
