import './loyaltypointtransactions.css';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  CustomerResponse,
  LoyaltyPointTransactionResponse,
} from '../../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import LoyaltyPointTransaction from './loyaltypointtransaction/LoyaltyPointTransaction';
import NewLoyaltyPointTransaction from './newloyaltypointtransaction/NewLoyaltyPointTransaction';
import _ from 'lodash';
import { useUser } from '../../../../contexts/auth/User';

interface LoyaltyPointTransactionsProps {
  customer: CustomerResponse;
  orderId?: string;
}

const LoyaltyPointTransactions: React.FC<LoyaltyPointTransactionsProps> = ({
  customer,
}) => {
  const api = usePetCloudApi();
  const loyaltyPointTransactionsApi = api.loyaltyPointTransactionsApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const [transactions, setTransactions] = useState<
    LoyaltyPointTransactionResponse[] | null
  >(null);

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = () => {
    loyaltyPointTransactionsApi
      .loyaltyPointTransactionsGetLoyaltyPointTransactions(customer.id)
      .then((response) => {
        console.log(response);
        setTransactions(_.sortBy(response.data, 'createdAt'));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (transactions) {
    return (
      <div className={'loyaltyPointTransactions'}>
        <div className={'loyaltyPointTransactions-transactions'}>
          {transactions.map((x) => (
            <LoyaltyPointTransaction
              transaction={x}
              getTransactions={getTransactions}
            />
          ))}
        </div>
        {user?.isProductOwner ? (
          <div className={'global-cardActions'}>
            <NewLoyaltyPointTransaction
              customer={customer}
              callbackFunc={getTransactions}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};
export default LoyaltyPointTransactions;
