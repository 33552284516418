import './listpagination.css';
import { Dropdown } from '../../../elements/selectors/Selectors';
import React, { useEffect } from 'react';
import { ReactComponent as IconPrevious } from '../../../../assets/icon/chevron_back.svg';
import { ReactComponent as IconNext } from '../../../../assets/icon/chevron_next.svg';

interface ListPaginationProps {
  totalItemCount: number;
  pageItemCount: number[];
  currentPageItemCount: number;
  setCurrentPageItemCount: (count: number) => void;
  page: number;
  setPage: (page: number) => void;
}

const ListPagination: React.FC<ListPaginationProps> = ({
  totalItemCount,
  pageItemCount,
  currentPageItemCount,
  setCurrentPageItemCount,
  page,
  setPage,
}) => {
  const maxPages = Math.ceil(totalItemCount / (currentPageItemCount ?? 1));

  useEffect(() => {
    setPage(1);
  }, [currentPageItemCount]);

  const pageRenderOffset = 5;

  const getBracket = (i: number) => {
    return Math.ceil(i / pageRenderOffset);
  };

  const shouldRender = (i: number) => {
    const currentBracket = getBracket(page);
    return (
      i >= (currentBracket - 1) * pageRenderOffset &&
      i < currentBracket * pageRenderOffset &&
      !(i === maxPages - 1 && maxPages > pageRenderOffset)
    );
  };

  const renderPageSelector = () => {
    return (
      <div className={'listPagination-pageSelector'}>
        <IconPrevious
          className={`listPagination-pageSelector-btn ${
            page === 1 ? 'listPagination-pageSelector-btn__inactive' : ''
          }`}
          onClick={page !== 1 ? () => setPage(page - 1) : undefined}
          fill={
            page !== 1
              ? 'var(--color-text_primary)'
              : 'var(--color-text_tertiary)'
          }
        />
        <div className={'listPagination-pageSelector-pages'}>
          {getBracket(maxPages) > 1 && getBracket(page) > 1 ? (
            <div
              className={'listPagination-pageSelector-page'}
              onClick={() => {
                const v = (getBracket(page) - 2) * pageRenderOffset + 1;
                console.log(v);
                setPage(v);
              }}
            >
              ...
            </div>
          ) : null}
          {Array.from({ length: maxPages }, (_, i) => i + 1).map(
            (pageNumber, i) => {
              if (shouldRender(i)) {
                return (
                  <div
                    key={pageNumber}
                    className={`listPagination-pageSelector-page ${
                      pageNumber === page
                        ? 'listPagination-pageSelector-page__active'
                        : ''
                    }`}
                    onClick={() => setPage(pageNumber)}
                  >
                    {pageNumber}
                  </div>
                );
              } else {
                return null;
              }
            }
          )}
          {getBracket(maxPages) > 1 &&
          getBracket(page) < getBracket(maxPages) ? (
            <div
              className={'listPagination-pageSelector-page'}
              onClick={() => setPage(getBracket(page) * pageRenderOffset + 1)}
            >
              ...
            </div>
          ) : null}
          {maxPages > pageRenderOffset ? (
            <div
              className={`listPagination-pageSelector-page ${
                maxPages === page
                  ? 'listPagination-pageSelector-page__active'
                  : ''
              }`}
              onClick={() => setPage(maxPages)}
            >
              {maxPages}
            </div>
          ) : null}
        </div>
        <IconNext
          className={`listPagination-pageSelector-btn ${
            page === maxPages ? 'listPagination-pageSelector-btn__inactive' : ''
          }`}
          onClick={page !== maxPages ? () => setPage(page + 1) : undefined}
          fill={
            page !== maxPages
              ? 'var(--color-text_primary)'
              : 'var(--color-text_tertiary)'
          }
        />
      </div>
    );
  };

  return (
    <div className={'listPagination'}>
      <div className={'listPagination-dropdown'}>
        <Dropdown
          options={pageItemCount.map((x) => x.toString())}
          selected={currentPageItemCount?.toString()}
          update={(e) => {
            setCurrentPageItemCount(
              parseInt(e.currentTarget.selectedOptions[0]?.value)
            );
          }}
          disableAutoOptionSorting
        />
      </div>
      <div className={'listPagination-selection'}>{renderPageSelector()}</div>
    </div>
  );
};

export default ListPagination;
