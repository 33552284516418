import './labeltext.css';

interface LabelTextProps {
  title: string;
  content: string | undefined | null;
}

const LabelText: React.FC<LabelTextProps> = ({ title, content }) => {
  return (
    <div className="labeltext">
      <div className="labeltext-title">{title}</div>
      <div className="labeltext-content">{content}</div>
    </div>
  );
};

export default LabelText;
