import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  CreateAdyenPaymentRefundRequest,
  OrderResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import Input from '../../../elements/input/Input';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import PositionEntry from '../../../types/PositionEntry';
import './refunding.css';
import InformationBox from '../../../elements/informationbox/InformationBox';
import RefundPositionEntry from '../../../types/RefundPositionEntry';
import List from '../../../features/list/List';
import RefundingSummary from './refundingsummary/RefundingSummary';

interface RefundingProps {
  order: OrderResponse;
  positions: RefundPositionEntry[];
  currency?: string | null;
  refreshOrder: () => void;
  successCallback?: () => void;
}

const Refunding: React.FC<RefundingProps> = ({
  order,
  positions,
  currency,
  refreshOrder,
  successCallback,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const adyenPaymentRefundsApi = api.adyenPaymentRefundsApi();
  const errorHandler = useErrorHandler();
  const [selectedPositionEntries, setSelectedPositionEntries] = useState<
    RefundPositionEntry[]
  >([]);
  const [positionEntries, setPositionEntries] =
    useState<RefundPositionEntry[]>(positions);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // make sure positionEntries and selectedPositionEntries are synced
  useEffect(() => {
    setSelectedPositionEntries([
      ...positionEntries.filter((p) =>
        selectedPositionEntries.find((s) => s.id === p.id)
      ),
    ]);
  }, [positionEntries]);

  const setRefundAmountByFactor = (
    positionIndex: number,
    price: number,
    factor: number
  ) => {
    const update = positionEntries;
    if (update) {
      update[positionIndex].refundAmount = parseFloat(
        (price * factor).toFixed(2)
      );
      update[positionIndex].refundPercentage = factor;
      setPositionEntries([...update]);
    }
  };

  const refundAmountsOK = () => {
    const notOK = [];
    selectedPositionEntries.forEach((entry) => {
      if (
        entry.refundAmount &&
        entry.maxRefundAmount !== null &&
        entry.maxRefundAmount !== undefined &&
        entry.refundAmount > entry.maxRefundAmount
      ) {
        notOK.push(entry);
      }
    });
    return notOK.length === 0;
  };

  const submitRefund = () => {
    setIsSubmitting(true);
    const request: CreateAdyenPaymentRefundRequest = {
      refundOrderPositions: selectedPositionEntries.map((entry) => {
        return {
          orderPositionId: entry.positionId,
          amount: entry.refundAmount,
          orderReturnPositionId: entry.orderReturnPositionId ?? undefined,
          orderPositionUnitId: entry.id,
        };
      }),
    };
    adyenPaymentRefundsApi
      .adyenPaymentRefundsCreateRefund(order.id, request)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.order.refunding.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setIsSubmitting(false);
        if (refreshOrder) {
          refreshOrder();
        }
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const renderRefundPercentage = (
    value: number,
    position: PositionEntry,
    index: number
  ) => {
    const selectedStyle = {
      border: '2px solid var(--color-text_primary)',
      color: 'var(--color-text_primary)',
    };
    return (
      <div className="positions-refundPercentage">
        <div
          className="positions-refundPercentage-element"
          style={position.refundPercentage === 1 ? selectedStyle : undefined}
          onClick={() => {
            setRefundAmountByFactor(index, position.price, 1);
          }}
        >
          100%
        </div>
        <div
          className="positions-refundPercentage-element"
          style={position.refundPercentage === 0.5 ? selectedStyle : undefined}
          onClick={() => {
            setRefundAmountByFactor(index, position.price, 0.5);
          }}
        >
          50%
        </div>
        <div
          className="positions-refundPercentage-element"
          style={position.refundPercentage === 0.25 ? selectedStyle : undefined}
          onClick={() => {
            setRefundAmountByFactor(index, position.price, 0.25);
          }}
        >
          25%
        </div>
        <div
          className="positions-refundPercentage-element"
          style={position.refundPercentage === 0.1 ? selectedStyle : undefined}
          onClick={() => {
            setRefundAmountByFactor(index, position.price, 0.1);
          }}
        >
          10%
        </div>
      </div>
    );
  };

  const renderRefundAmount = (
    value: number,
    position: PositionEntry,
    index: number
  ) => {
    console.log('rendered refundAmount');
    return (
      <div className="positions-refundAmount">
        <Input
          content={value.toString()}
          update={(e) => {
            console.log('refundAmount update');
            const update = [...positionEntries];
            if (update) {
              update[index] = {
                ...update[index],
                refundAmount: parseFloat(e),
                refundPercentage: 0,
              };
              setPositionEntries(update);
            }
          }}
          unit={currency}
          type={'number'}
          errors={
            position.maxRefundAmount !== null &&
            position.maxRefundAmount !== undefined &&
            value > position.maxRefundAmount
              ? [t('view.order.refunding.errors.amountToHigh')]
              : null
          }
        />
      </div>
    );
  };

  const selectAllPositions = () => {
    if (selectedPositionEntries.length > 0) {
      setSelectedPositionEntries([]);
    } else {
      setSelectedPositionEntries([...positionEntries]);
    }
  };

  return (
    <div className="refunding">
      <InformationBox
        title={t('view.order.refunding.info.title')}
        content={t('view.order.refunding.info.text')}
        type={'info'}
        dismissSettingName={'order_refunding'}
      />
      <div className="refunding-positions">
        <List
          items={positionEntries}
          onSelect={(entry) => {
            const update = selectedPositionEntries;
            const index = update.findIndex((p) => p.id === entry.id);
            if (index !== -1) {
              update.splice(index, 1);
            } else {
              update.push(entry as RefundPositionEntry);
            }
            setSelectedPositionEntries([...update]);
          }}
          onSelectAll={selectAllPositions}
          selectedItems={selectedPositionEntries}
          renderObjects={[
            {
              key: 'refundPercentage',
              renderMethod: renderRefundPercentage,
              stopPropagation: true,
            },
            {
              key: 'refundAmount',
              renderMethod: renderRefundAmount,
            },
          ]}
          priceKeysConfig={{
            priceKeys: ['price', 'openRefundAmount'],
            currencySymbol: currency,
          }}
          ignore={[
            'id',
            'productId',
            'positionId',
            'refundAmountCurrency',
            'orderReturnPositionId',
            'batchNumber',
            'bestBefore',
            'logisticsReturnReason',
            'quality',
            'notes',
            'returnDate',
            'currency',
            'maxQuantity',
            'orderPositionUnitPriceId',
            'isRefundable',
          ]}
          itemImgKey={'coverUri'}
          monoSpaceStrings={['ean']}
          unselectableIds={positionEntries
            .filter((e) => !e.isRefundable)
            .map((x) => x.id)}
          unselectableIdsDisableRows={true}
        />
      </div>
      {refundAmountsOK() ? (
        <RefundingSummary
          selectedPositionEntries={selectedPositionEntries}
          commission={order.commission}
          orderAmountTotal={order.amountTotal}
          currency={order.currency?.symbol}
        />
      ) : (
        <div className="refunding-error">
          {t('view.order.refunding.errors.general')}
        </div>
      )}
      <div className="global-cardActions refunding-actions">
        <Button
          cta={t('view.order.refunding.submit')}
          look="danger"
          width="minimal"
          action={submitRefund}
          isLoading={isSubmitting}
          active={selectedPositionEntries.length > 0 && refundAmountsOK()}
        />
      </div>
    </div>
  );
};

export default Refunding;
