import './jsontablesearch.css';
import Searchbar from '../../../elements/searchbar/Searchbar';
import { useTranslation } from 'react-i18next';
import { JsonTableHeader } from '../JsonTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Popup from '../../../elements/popup/Popup';
import Pill from '../../../elements/pill/Pill';
import DropdownMenu from '../../../elements/dropdownmenu/DropdownMenu';
import Button from '../../../elements/button/Button';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import { SearchHeaderFunctionDict } from '../../../sections/productbulkeditor/utils/useSearchConfig';

interface JsonTableSearchProps {
  items: any[];
  currentSearchResultIds: string[];
  onSearchResults: (ids: string[]) => void;
  headers: JsonTableHeader[];
  defaultSearchHeaders: string[];
  headerWhitelist: string[];
  searchHeaderFunctions?: SearchHeaderFunctionDict;
}

const JsonTableSearch: React.FC<JsonTableSearchProps> = ({
  items,
  currentSearchResultIds,
  onSearchResults,
  headers,
  defaultSearchHeaders,
  headerWhitelist,
  searchHeaderFunctions,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.jsonTable.search',
  });
  const [popup, setPopup] = useState(false);
  const [searchHeaders, setSearchHeaders] = useState<JsonTableHeader[]>([]);
  const [noResults, setNoResults] = useState(false);
  // determines whether the entire data set will be searched or if the search queries always search previous results
  const [isSearchPreviousResults, setIsSearchPreviousResults] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (noResults) {
      timer = setTimeout(() => {
        setNoResults(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [noResults]);

  useEffect(() => {
    getDefaultHeaders();
  }, []);

  const getDefaultHeaders = () => {
    const results = headers.filter((x) => defaultSearchHeaders.includes(x.id));
    setSearchHeaders(results);
  };

  const removeHeader = (index: number) => {
    const update = [...searchHeaders];
    update.splice(index, 1);
    setSearchHeaders(update);
  };

  const addHeader = (value: string, id?: string) => {
    if (id) {
      setSearchHeaders((prev) => {
        const result = headers.find((x) => x.id === id);
        if (result) {
          return [...prev, result];
        } else {
          return prev;
        }
      });
    }
  };

  const headerOptions = useMemo(() => {
    return headers
      .filter((x) => headerWhitelist.includes(x.id))
      .map((x) => ({
        id: x.id,
        name: x.title,
      }));
  }, [headers]);

  const handleSearch = (query: string) => {
    let data = items;

    if (isSearchPreviousResults && currentSearchResultIds.length > 0) {
      data = data.filter((x) => currentSearchResultIds.includes(x.id));
    }

    console.log(searchHeaders);

    const results = data.filter((item) => {
      for (const header of searchHeaders) {
        const value = item[header.id];
        console.log(header, value);

        if (query && query !== '' && value) {
          console.log(header.id);
          if (searchHeaderFunctions && searchHeaderFunctions[header.id]) {
            const searchFunction = searchHeaderFunctions[header.id];
            if (searchFunction(query.toLowerCase(), value)) {
              return true;
            }
          } else if (
            value.toString().toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    });
    console.log(results);

    if (query.length > 0) {
      if (results.length === 0) {
        setNoResults(true);
      } else {
        onSearchResults(results.map((x) => x.id));
      }
    } else {
      if (!isSearchPreviousResults) {
        onSearchResults([]);
      }
    }
  };

  const handleReset = () => {
    if (isSearchPreviousResults) {
      onSearchResults([]);
    }
  };

  return (
    <div className={'jsonTableSearch'}>
      <Searchbar
        cta={t('cta')}
        height={'normal'}
        onSettingsButtonClick={() => setPopup(true)}
        onReset={
          isSearchPreviousResults && currentSearchResultIds.length > 0
            ? handleReset
            : undefined
        }
        onDebouncedSearch={handleSearch}
        isError={noResults}
      />
      <Popup toggled={popup} width={'30%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('popup.title')}</div>
        <div className={'global-textElement'}>{t('popup.text')}</div>
        <div className={'jsonTableSearch-searchableHeaders pills'}>
          {searchHeaders.map((header, index) => {
            return (
              <div className={'jsonTableSearch-searchableHeader'}>
                <Pill
                  value={header.title}
                  onRemove={() => removeHeader(index)}
                />
              </div>
            );
          })}
        </div>
        <DropdownMenu
          title={t('popup.dropdown')}
          optionObjects={headerOptions}
          highlightedOptions={searchHeaders.map((x) => x.title)}
          onSelect={addHeader}
        />
        <div className={'jsonTableSearch-setting'}>
          <ToggleSwitch
            toggled={isSearchPreviousResults}
            toggle={() => setIsSearchPreviousResults(!isSearchPreviousResults)}
            label={t('popup.isSearchPreviousResults')}
            smallSwitch
            smallLabel
          />
        </div>
        <div className={'global-cardActions-margin'}>
          <Button
            cta={t('popup.cta')}
            width={'minimal'}
            look={'secondary'}
            action={() => setPopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
};

export default JsonTableSearch;
