import './profileimage.css';
import { ReactComponent as IconUser } from '../../../../assets/icon/user.svg';

interface InitialsProps {
  name?: string;
  lastName?: string;
  imgSrc?: string;
  size?: number;
}

const ProfileImage: React.FC<InitialsProps> = ({
  name,
  lastName,
  imgSrc,
  size,
}) => {
  return (
    <div
      className={`profileImage ${
        !name && !imgSrc ? 'profileImage-default' : null
      }`}
      style={
        size
          ? {
              width: size,
              height: size,
              fontSize: size / 3,
              fontWeight: 'normal',
            }
          : undefined
      }
    >
      {imgSrc ? (
        <img src={imgSrc} className={'profileImage-img'} alt={'profile'} />
      ) : name ? (
        name.charAt(0) + lastName?.charAt(0)
      ) : (
        <IconUser
          className={'profileImage-img'}
          fill={'var(--color-primary)'}
        />
      )}
    </div>
  );
};

export default ProfileImage;
