import './sheetparser.css';
import useNotifications from '../../../../hooks/useNotifications';
import Papa from 'papaparse';
import { read, utils } from 'xlsx';
import { useTranslation } from 'react-i18next';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import { useState } from 'react';
import Popup from '../../../../elements/popup/Popup';
import Button from '../../../../elements/button/Button';
import Input from '../../../../elements/input/Input';
import { SheetData } from '../ProductImporter';
import DropdownMenu from '../../../../elements/dropdownmenu/DropdownMenu';

interface SheetParserProps {
  setSheetData: (data: SheetData) => void;
}

enum SheetParserStep {
  HeaderRowIndex,
  IdKeys,
}

const SheetParser: React.FC<SheetParserProps> = ({ setSheetData }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter.sheetParser',
  });
  const { pushNotification } = useNotifications();
  const [step, setStep] = useState(SheetParserStep.HeaderRowIndex);
  const [rawData, setRawData] = useState<any[] | null>(null);
  const [headerRowIndex, setHeaderRowIndex] = useState(0);
  const [headerRowIndexOutOfBounds, setHeaderRowIndexOutOfBounds] =
    useState(false);
  const [idKey, setIdKey] = useState<string | null>(null);
  const [parentIdKey, setParentIdKey] = useState<string | null>(null);

  const onUpload = (files: File[]) => {
    const file = files[0];
    const fileType = file.type;
    if (fileType === 'text/csv') {
      parseCSV(file);
    } else if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      parseXLSX(file);
    } else {
      pushNotification(t('notifications.fileType_invalid'), 'warning');
    }
  };

  const parseCSV = (file: File) => {
    Papa.parse(file, {
      complete: (results) => {
        console.log(results.data);
        setRawData(results.data);
      },
    });
  };

  const parseXLSX = async (file: File) => {
    const f = await file.arrayBuffer();
    const wb = read(f, { type: 'array' });
    const ws = wb.Sheets[wb.SheetNames[0]];
    let data = utils.sheet_to_json(ws, {
      header: 1,
      blankrows: false,
    });

    // Convert numeric values to string
    data = data.map((row: any) =>
      row.map((item: any) =>
        typeof item === 'number' ? item.toString() : item
      )
    );

    console.log(data);
    setRawData(data);
  };

  const prepareSheetData = () => {
    if (rawData) {
      setSheetData({
        data: rawData.slice(headerRowIndex + 1),
        headers: rawData[headerRowIndex],
        idKey: idKey ?? undefined,
        parentIdKey: parentIdKey ?? undefined,
      });
    }
  };

  return (
    <div className={'sheetParser'}>
      <Dropzone maxFiles={1} height={'100%'} callbackWithoutUpload={onUpload} />
      {rawData ? (
        <Popup toggled={true} width={'20%'} close={() => setRawData(null)}>
          {step === SheetParserStep.HeaderRowIndex ? (
            <>
              <div className={'popup-title'}>{t('uploadConfirm.title')}</div>
              <div
                className={'global-textElement sheetParser-uploadConfirmText'}
              >
                {t('uploadConfirm.text_1')}
                <span>{`${rawData.length} ${t('uploadConfirm.rows')}`}</span>
                {t('uploadConfirm.text_2')}
                <span>{`${rawData[headerRowIndex].length} ${t(
                  'uploadConfirm.columns'
                )}`}</span>
                {t('uploadConfirm.text_3')}
              </div>
              <div className={'global-inputGroup'}>
                <div className={'global-inputGroup-input'}>
                  <Input
                    title={t('uploadConfirm.headerRowIndex')}
                    content={(headerRowIndex + 1).toString()}
                    update={(v) => {
                      const n = parseInt(v);
                      if (n <= rawData.length) {
                        setHeaderRowIndex(n - 1);
                        setHeaderRowIndexOutOfBounds(false);
                      } else {
                        setHeaderRowIndexOutOfBounds(true);
                      }
                    }}
                    type={'number'}
                    maxValue={rawData.length}
                    unit={'#'}
                    hint={t('uploadConfirm.headerRowIndex_hint')}
                    errors={
                      headerRowIndexOutOfBounds
                        ? [t('notifications.headerRowIndexOutOfBounds')]
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className={'sheetParser-exampleHeaders-title'}>
                {t('exampleHeaders')}
              </div>
              <div className={'sheetParser-exampleHeaders'}>
                {rawData[headerRowIndex].map((h: string, index: number) => {
                  if (index <= 9) {
                    return (
                      <div key={index} className={'sheetParser-exampleHeader'}>
                        {h}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className={'global-cardActions'}>
                <Button
                  cta={t('uploadConfirm.cta')}
                  action={() => setStep(SheetParserStep.IdKeys)}
                  look={'secondary'}
                  width={'minimal'}
                  active={!headerRowIndexOutOfBounds}
                />
              </div>
            </>
          ) : null}
          {step === SheetParserStep.IdKeys ? (
            <>
              <div className={'popup-title'}>{t('idKeyMapping.title')}</div>
              <div
                className={'global-textElement sheetParser-idKeyMappingText'}
              >
                {t('idKeyMapping.text')}
              </div>
              <div className={'global-inputGroup'}>
                <div className={'global-inputGroup-input'}>
                  <DropdownMenu
                    title={t('idKeyMapping.idKey')}
                    options={[...rawData[headerRowIndex]]}
                    selected={idKey}
                    onSelect={(v) => {
                      setIdKey(v);
                    }}
                  />
                </div>
                <div className={'global-inputGroup-input'}>
                  <DropdownMenu
                    title={t('idKeyMapping.parentIdKey')}
                    options={[...rawData[headerRowIndex]]}
                    selected={parentIdKey}
                    onSelect={(v) => {
                      setParentIdKey(v);
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  'global-cardActions-spaceBetween global-cardActions-margin'
                }
              >
                <Button
                  cta={t('idKeyMapping.cta.back')}
                  action={() => setStep(SheetParserStep.HeaderRowIndex)}
                  look={'secondary'}
                  width={'minimal'}
                />
                <Button
                  cta={
                    idKey && parentIdKey
                      ? t('idKeyMapping.cta.confirm')
                      : t('idKeyMapping.cta.skip')
                  }
                  action={prepareSheetData}
                  look={idKey && parentIdKey ? 'save' : 'secondary'}
                  width={'minimal'}
                />
              </div>
            </>
          ) : null}
        </Popup>
      ) : null}
    </div>
  );
};

export default SheetParser;
