import { useTranslation } from 'react-i18next';
import { Has } from '../../contexts/auth/Authorization';
import Card, { CardSection } from '../../elements/card/Card';
import { Navigator, Stage } from '../../layout/stage/Stage';
import OrderList from '../../features/orderlist/OrderList';

const Orders = () => {
  const { t } = useTranslation();

  return (
    <Has authorizations={['orders:list']} showNoAuthorization>
      <Stage>
        <Navigator title={t('view.orders.title')}></Navigator>
        <Card bigScreenWidth="100%">
          <CardSection>
            <OrderList />
          </CardSection>
        </Card>
      </Stage>
    </Has>
  );
};

export default Orders;
