import './headerconfigsave.css';
import { JsonTableHeader } from '../JsonTable';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ReactComponent as IconSave } from '../../../../assets/icon/jsontable/menu/columns_save.svg';
import Popup from '../../../elements/popup/Popup';
import Input from '../../../elements/input/Input';
import Button from '../../../elements/button/Button';
import useNotifications from '../../../hooks/useNotifications';
import useUserSettings from '../../../hooks/useUserSettings';

export type UserSettingHeaderConfig = {
  id: string;
  name: string;
  createdAt: string;
  headers: JsonTableHeader[];
};

interface IHeaderConfigSaveProps {
  headers: JsonTableHeader[];
}

const HeaderConfigSave: React.FC<IHeaderConfigSaveProps> = ({ headers }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.jsonTable.headerConfigSave',
  });
  const { pushNotification } = useNotifications();
  const { currentUserSettings, isSubmitting, updateUserSettings } =
    useUserSettings(() => {
      pushNotification(t('notifications.create_success'));
      setPopup(false);
    });

  const [popup, setPopup] = useState(false);
  const [name, setName] = useState<string>('');

  const save = () => {
    if (currentUserSettings) {
      const productBulkEditorSettings =
        currentUserSettings.productBulkEditor ?? { headerConfigs: [] };
      const userSettingHeaderConfig: UserSettingHeaderConfig = {
        id: crypto.randomUUID(),
        name: name,
        createdAt: new Date().toUTCString(),
        headers: headers,
      };
      productBulkEditorSettings.headerConfigs.push(userSettingHeaderConfig);

      // submit to backend
      updateUserSettings('productBulkEditor', productBulkEditorSettings);
    }
  };

  console.log(currentUserSettings);

  return (
    <div className="headerConfigSave">
      <div
        className={'actionsPopout-action actionsPopout-action__secondary'}
        onClick={() => setPopup(true)}
      >
        <div className={'actionsPopout-action-icon'}>
          <IconSave className={'actionsPopout-action-icon'} />
        </div>
        {t('cta')}
      </div>
      <Popup toggled={popup} width={'30%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('popup.title')}</div>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input title={t('popup.name')} content={name} update={setName} />
          </div>
        </div>
        <Button
          cta={t('popup.cta')}
          width={'full'}
          look={'save'}
          action={save}
          isLoading={isSubmitting}
        />
      </Popup>
    </div>
  );
};

export default HeaderConfigSave;
