import './excludepromotionsselect.css';
import {
  PromotionResponse,
  UpsertPromotionRequest,
} from '../../../../api/petcloudapi/api';
import ValueArray from '../../../../elements/valuearray/ValueArray';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import Popup from '../../../../elements/popup/Popup';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import PromotionsList from '../../promotionslist/PromotionsList';

interface ExcludePromotionsSelectProps {
  promotion: UpsertPromotionRequest;
  update: (promotion: UpsertPromotionRequest) => void;
}

const ExcludePromotionsSelect: React.FC<ExcludePromotionsSelectProps> = ({
  promotion,
  update,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.promotion.excludedFromPromotionIds',
  });
  const api = usePetCloudApi();
  const promotionsApi = api.promotionsApi();
  const errorHandler = useErrorHandler();

  const [popup, setPopup] = useState(false);
  const [promotions, setPromotions] = useState<PromotionResponse[] | null>(
    null
  );

  useEffect(() => {
    getPromotions();
  }, []);

  const getPromotions = () => {
    promotionsApi
      .promotionsGetPromotions()
      .then((response) => {
        console.log(response);
        setPromotions(
          response.data.map((promotion) => {
            const { name, code, manufacturer, ...rest } = promotion;
            return {
              name: name,
              code: code,
              ...rest,
              manufacturer: manufacturer,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (promotions) {
    const selectedPromotions = promotions.filter((p) =>
      promotion.excludedFromPromotionIds?.includes(p.id)
    );

    return (
      <div className={'excludePromotionsSelect'}>
        <ValueArray
          title={t('title')}
          items={selectedPromotions}
          titleKey={'name'}
          titleKeyLang={i18n.language as TranslatedStringIndex}
          onDelete={(index) => {
            const excludedFromPromotionIds = promotion.excludedFromPromotionIds;
            if (excludedFromPromotionIds) {
              excludedFromPromotionIds.splice(index, 1);
              update({
                ...promotion,
                excludedFromPromotionIds: excludedFromPromotionIds,
              });
            }
          }}
          onEdit={() => setPopup(true)}
        />
        <Popup toggled={popup} width={'50%'} close={() => setPopup(false)}>
          <div className={'popup-title'}>{t('popup.title')}</div>
          <PromotionsList
            promotions={promotions.filter(
              (p) => p.id !== (promotion as PromotionResponse).id
            )}
            selectedItems={selectedPromotions}
            onSelect={(item) => {
              const excludedFromPromotionIds =
                promotion.excludedFromPromotionIds ?? [];
              const i = excludedFromPromotionIds.findIndex(
                (id) => id === item.id
              );
              if (i !== -1) {
                excludedFromPromotionIds.splice(i, 1);
              } else {
                excludedFromPromotionIds.push(item.id);
              }
              update({
                ...promotion,
                excludedFromPromotionIds: excludedFromPromotionIds,
              });
            }}
            onSelectAll={() =>
              update({
                ...promotion,
                excludedFromPromotionIds:
                  promotion.excludedFromPromotionIds?.length ===
                  promotions?.length
                    ? []
                    : promotions?.map((x) => x.id),
              })
            }
          />
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ExcludePromotionsSelect;
