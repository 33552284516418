const usePrice = () => {
  return (price: number | undefined, currency: string | undefined | null) => {
    if (price !== undefined) {
      return `${price.toFixed(2)} ${currency}`;
    } else {
      return null;
    }
  };
};

export default usePrice;
