import './integrationredirectsshopifyoauth.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useUser } from '../../../../../contexts/auth/User';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useNotifications from '../../../../../hooks/useNotifications';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import { useEffect } from 'react';
import { NoAuthorization } from '../../../../../elements/emptystate/EmptyState';
import { LoadingContainer } from '../../../../../elements/loading/Loading';

const IntegrationRedirectsShopifyOAuth = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'page.integrationRedirectsShopify',
  });
  const api = usePetCloudApi();
  const shopifyAppApi = api.shopifyAppApi();
  const { user } = useUser();
  const { getUrlParam } = useUrlParams();
  const { pushNotification } = useNotifications();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (!user?.isProductOwner) {
      getOAuth();
    }
  }, []);

  const getOAuth = () => {
    const code = getUrlParam('code');
    const hmac = getUrlParam('hmac');
    const shop = getUrlParam('shop');
    const host = getUrlParam('host');
    const timestamp = getUrlParam('timestamp');

    if (code && hmac && host && shop && timestamp) {
      shopifyAppApi
        .shopifyV1OauthCodeGet(code, hmac, host, shop, timestamp)
        .then((response) => {
          console.log(response);
          const uri = response.data.redirectUri;
          if (uri) {
            window.location.href = uri;
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      pushNotification(t('errors.missingOAuthParams'), 'danger');
    }
  };

  if (user?.isProductOwner) {
    return <NoAuthorization message={t('productOwnerNotAllowed')} />;
  } else {
    return (
      <div className={'main'}>
        <LoadingContainer />
      </div>
    );
  }
};

export default IntegrationRedirectsShopifyOAuth;
