import './pillscell.css';

interface PillsCellProps {
  pillValues: { capsule: string; value: string }[];
}

const PillsCell: React.FC<PillsCellProps> = ({ pillValues }) => {
  return (
    <div className={'jtce-pills'}>
      {pillValues.map((pill) => {
        if (pill.value) {
          return (
            <div key={pill.capsule + pill.value} className={'jtce-pill'}>
              <div className={'jtce-pill-capsule'}>{pill.capsule}</div>
              {pill.value}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default PillsCell;
