import './statehistories.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Has, useAuthorization } from '../../../contexts/auth/Authorization';
import { Store } from 'react-notifications-component';
import {
  CreateOrderStateHistoryRequest,
  OrderResponse,
  OrderStateContext,
  OrderStateHistoryResponse,
  OrderStateResponse,
} from '../../../api/petcloudapi/api';
import OrderStateHistory from '../../../types/OrderStateHistory';
import { CardSection, Tab, Tabs } from '../../../elements/card/Card';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import DateTime from '../../../elements/datetime/DateTime';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import { Dropdown } from '../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { ReactComponent as Drive } from '../../../../assets/icon/drive.svg';
import Badge from '../../../elements/badge/Badge';
import { NoAuthorization } from '../../../elements/emptystate/EmptyState';
import { useUser } from '../../../contexts/auth/User';
import InformationBox from '../../../elements/informationbox/InformationBox';
import { states } from '../../../features/list/List';

interface StateHistoriesProps {
  orderId: string;
  order: OrderResponse;
  stateHistories: OrderStateHistoryResponse[];
  refreshOrder: () => void;
}

const StateHistories: React.FC<StateHistoriesProps> = ({
  orderId,
  order,
  stateHistories,
  refreshOrder,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const api = usePetCloudApi();
  const usersApi = api.usersApi();
  const orderStateHistoriesApi = api.orderStateHistoriesApi();
  const errorHandler = useErrorHandler();
  const { authorizations } = useAuthorization();
  const [orderHistory, setOrderHistory] = useState<OrderStateHistory[]>([]);
  const [shippingHistory, setShippingHistory] = useState<OrderStateHistory[]>(
    []
  );
  const [transactionHistory, setTransactionHistory] = useState<
    OrderStateHistory[]
  >([]);

  const [popup, setPopup] = useState<OrderStateHistory | null>(null);
  const [selectedOrderState, setSelectedOrderState] =
    useState<OrderStateResponse | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getOrderStates();
  }, []);

  const getOrderStates = () => {
    const oH: OrderStateHistory[] = [];
    const sH: OrderStateHistory[] = [];
    const tH: OrderStateHistory[] = [];

    const sortByDate = (a: OrderStateHistory, b: OrderStateHistory) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    };

    var counter = 1;

    stateHistories.forEach((history) => {
      if (history.userId) {
        usersApi
          .usersGetUserById(history.userId)
          .then((response) => {
            console.log(response);
            if (history.orderStateContext === 'Order') {
              oH.push({ ...history, user: response.data });
            } else if (history.orderStateContext === 'Shipping') {
              sH.push({ ...history, user: response.data });
            } else if (history.orderStateContext === 'Transaction') {
              tH.push({ ...history, user: response.data });
            }
            if (counter === stateHistories.length) {
              console.log('was set in 1');
              oH.sort(sortByDate).reverse();
              sH.sort(sortByDate).reverse();
              tH.sort(sortByDate).reverse();
              setOrderHistory(oH);
              setShippingHistory(sH);
              setTransactionHistory(tH);
            }
            counter += 1;
          })
          .catch((error) => {
            errorHandler.addError(error.response);
          });
      } else {
        if (history.orderStateContext === 'Order') {
          oH.push({ ...history, user: null });
        } else if (history.orderStateContext === 'Shipping') {
          sH.push({ ...history, user: null });
        } else if (history.orderStateContext === 'Transaction') {
          tH.push({ ...history, user: null });
        }
        if (counter === stateHistories.length) {
          console.log('was set in 2');
          oH.sort(sortByDate).reverse();
          sH.sort(sortByDate).reverse();
          tH.sort(sortByDate).reverse();
          setOrderHistory(oH);
          setShippingHistory(sH);
          setTransactionHistory(tH);
        }
        counter += 1;
      }
    });
  };

  const getTransitionOptions = (history: OrderStateHistory) => {
    return history.transitions.map((transition) => {
      const name = transition.name[i18n.language as TranslatedStringIndex];
      return {
        id: transition.id,
        name: name ?? 'No name for state',
      };
    });
  };

  const selectOrderState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (popup) {
      const selection = popup.transitions.find(
        (transition) =>
          transition.id ===
          e.target.selectedOptions[0].getAttribute('data-value')
      );
      if (selection) {
        setSelectedOrderState(selection);
      }
    }
  };

  const submitOrderState = () => {
    setIsSubmitting(true);
    if (popup && selectedOrderState) {
      const payload: CreateOrderStateHistoryRequest = {
        orderStateContext: popup.orderStateContext as OrderStateContext,
        orderStateTechnicalName: selectedOrderState.technicalName,
        orderStateId: selectedOrderState.id,
        orderId: orderId,
      };

      orderStateHistoriesApi
        .orderStateHistoriesCreateOrderStateHistory(payload)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.order.notifications.stateUpdate_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setPopup(null);
          setIsSubmitting(false);
          refreshOrder();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const isAllowedToChangeOrderState = () => {
    if (order.warehouse?.type !== 'ManufacturerWarehouse') {
      return (
        order.currentOrderState.orderState?.technicalName === 'Returned' ||
        order.currentOrderState.orderState?.technicalName ===
          'ReturnedPartially'
      );
    } else {
      return true;
    }
  };

  if (authorizations?.includes('order_state_histories:list')) {
    return (
      <div className="statehistories">
        <Tabs
          tabs={[
            { title: t('view.order.stateHistories.order'), key: 'order' },
            { title: t('view.order.stateHistories.shipping'), key: 'shipping' },
            {
              title: t('view.order.stateHistories.transaction'),
              key: 'transaction',
            },
          ]}
        >
          <Tab>
            <CardSection>
              {isAllowedToChangeOrderState() || user?.isProductOwner ? (
                <Has authorizations={['order_state_histories:create']}>
                  <Button
                    cta={t('view.order.stateHistories.change_status')}
                    action={() => setPopup(orderHistory[0])}
                    look={'secondary'}
                    width="minimal"
                    active={!!orderHistory[0]?.transitions}
                  />
                </Has>
              ) : null}
              <History histories={orderHistory} />
            </CardSection>
          </Tab>
          <Tab>
            <CardSection>
              {order.warehouse?.type !== 'CentralWarehouse' ||
              user?.isProductOwner ? (
                <Has authorizations={['order_state_histories:create']}>
                  <Button
                    cta={t('view.order.stateHistories.change_status')}
                    action={() => setPopup(shippingHistory[0])}
                    look={'secondary'}
                    width="minimal"
                    active={!!shippingHistory[0]?.transitions}
                  />
                </Has>
              ) : null}
              <History histories={shippingHistory} />
            </CardSection>
          </Tab>
          <Tab>
            <CardSection>
              <History histories={transactionHistory} />
            </CardSection>
          </Tab>
        </Tabs>
        <Popup toggled={!!popup} width="20%" close={() => setPopup(null)}>
          {popup ? (
            <>
              <div className="statehistories-popup-title">
                {t(
                  'view.order.stateHistories.popup.title_' +
                    popup?.orderStateContext.toLowerCase()
                )}
              </div>
              {order.warehouse?.type !== 'ManufacturerWarehouse' ? (
                <InformationBox
                  type={'warning'}
                  content={t('view.order.stateHistories.popup.overrideWarning')}
                />
              ) : null}
              <div className="statehistories-popup-dropdown">
                <Dropdown
                  optionObjects={getTransitionOptions(popup)}
                  selected={
                    selectedOrderState?.name[
                      i18n.language as TranslatedStringIndex
                    ]
                  }
                  update={selectOrderState}
                />
              </div>
              <div className="statehistories-popup-actions">
                <Button
                  cta={t('view.order.stateHistories.popup.cta')}
                  look="save"
                  action={submitOrderState}
                  width="minimal"
                  isLoading={isSubmitting}
                  active={!!selectedOrderState}
                />
              </div>
            </>
          ) : null}
        </Popup>
      </div>
    );
  } else {
    return <NoAuthorization />;
  }
};

interface HistoryProps {
  histories: OrderStateHistory[];
}

const History: React.FC<HistoryProps> = ({ histories }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="statehistories-history">
      {histories.map((entry) => {
        const creatorName = entry.user
          ? entry.user.firstName + ' ' + entry.user.lastName
          : 'System';

        const initials = entry.user
          ? entry.user.firstName.charAt(0) + entry.user.lastName.charAt(0)
          : null;

        return (
          <div key={entry.id} className="statehistories-history-entry">
            <div className="statehistories-history-entry-left">
              {initials ? (
                <div className="statehistories-history-entry-initials">
                  {initials}
                </div>
              ) : (
                <Drive
                  className="statehistories-history-entry-icon"
                  fill="var(--color-text_secondary)"
                />
              )}
            </div>
            <div className="statehistories-history-entry-right">
              <div className="statehistories-history-entry-state">
                {entry.orderState ? (
                  <Badge
                    title={
                      entry.orderState?.name[
                        i18n.language as TranslatedStringIndex
                      ] ?? 'translation missing'
                    }
                    color={states[entry.orderState.technicalName]}
                  />
                ) : null}
              </div>
              <div className="statehistories-history-entry-meta">
                {`${creatorName} ${t(
                  'view.order.stateHistories.entry.createdAt'
                )}`}
                <div className="statehistories-history-entry-meta-date">
                  <DateTime
                    dateString={entry.createdAt}
                    show={['day', 'month', 'time']}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StateHistories;
