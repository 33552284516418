import './propertyvalidationrulearray.css';
import ValueArray from '../../../../elements/valuearray/ValueArray';
import Button from '../../../../elements/button/Button';
import Popup from '../../../../elements/popup/Popup';
import List from '../../../../features/list/List';
import NewPropertyValidationRule from '../newpropertyvalidationrule/NewPropertyValidationRule';
import { PropertyValidationRuleResponse } from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface PropertyValidationRuleArrayProps {
  title?: string;
  availablePropertyValidationRules: PropertyValidationRuleResponse[];
  request: any;
  update: (request: any) => void;
  refreshAvailablePropertyValidationRules: () => void;
}

const PropertyValidationRuleArray: React.FC<
  PropertyValidationRuleArrayProps
> = ({
  title,
  availablePropertyValidationRules,
  request,
  update,
  refreshAvailablePropertyValidationRules,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.propertyValidationRules',
  });

  const [popup, setPopup] = useState(false);
  const [newPropertyValidationRulePopup, setNewPropertyValidationRulePopup] =
    useState(false);

  return (
    <div className={'propertyValidationRuleArray'}>
      <ValueArray
        title={title}
        items={availablePropertyValidationRules.filter((r) =>
          request.propertyValidationRuleIds?.includes(r.id)
        )}
        titleKey={'name'}
        editorOverride={
          <Button
            cta={t('add.title')}
            look={'secondary'}
            width={'tiny'}
            action={() => {
              setPopup(true);
            }}
          />
        }
      />
      <Popup toggled={popup} width={'40%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('add.title')}</div>
        {availablePropertyValidationRules.length > 0 ? (
          <List
            items={availablePropertyValidationRules}
            selectedItems={availablePropertyValidationRules.filter((r) =>
              request.propertyValidationRuleIds?.includes(r.id)
            )}
            onSelect={(item) => {
              const rules = request.propertyValidationRuleIds
                ? [...request.propertyValidationRuleIds]
                : [];
              const i = rules.findIndex((x) => x === item.id);
              if (i !== -1) {
                rules.splice(i, 1);
              } else {
                rules.push(item.id);
              }
              update({ ...request, propertyValidationRuleIds: rules });
            }}
            ignore={['id', 'updatedAt', 'message', 'description']}
            dateStrings={['createdAt']}
          />
        ) : (
          <EmptyState />
        )}
        <NewPropertyValidationRule
          popup={newPropertyValidationRulePopup}
          closePopup={() => setNewPropertyValidationRulePopup(false)}
          refreshValidationRules={refreshAvailablePropertyValidationRules}
        />
        <div
          className={
            'global-cardActions-postBorder global-cardActions-spaceBetween'
          }
        >
          <Button
            cta={t('add.new')}
            look={'secondary'}
            width={'minimal'}
            action={() => setNewPropertyValidationRulePopup(true)}
          />
          <Button
            cta={t('add.close')}
            look={'secondary'}
            width={'minimal'}
            action={() => setPopup(false)}
          />
        </div>
      </Popup>
    </div>
  );
};

export default PropertyValidationRuleArray;
