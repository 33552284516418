import './positions.css';
import {
  AdyenPaymentRefundDetailResponse,
  OrderPositionResponse,
  OrderReturnPositionResponse,
  OrderShippingDetailPositionResponse,
  UpsertOrderShippingDetailPositionRequest,
} from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import PositionEntry from '../../../types/PositionEntry';
import RefundPositionEntry from '../../../types/RefundPositionEntry';
import RefundDetailPosition from '../../../types/RefundDetailPosition';

export const getOrderPositionEntries = (
  positionsArray: OrderPositionResponse[],
  selectedLanguage: TranslatedStringIndex,
  currency?: string | null,
  ignoreDiscount?: true
) => {
  const results: PositionEntry[] = [];
  positionsArray.forEach((position) => {
    const product = position.product;
    if (product) {
      const result = {
        id: position.id,
        productId: product.id,
        positionId: position.id,
        isVariant: product.parentId ? true : false,
        coverUri: position.cover?.asset[selectedLanguage]?.uri,
        name: product.name[selectedLanguage],
        quantity: position.quantity,
        ean: product.ean,
        price: position.unitPrice,
        productNumber: product.productNumber,
        manufacturerProductNumber: product.manufacturerProductNumber,
        bundleName: position.productBundleDefinition?.productBundleName,
        currency: currency,
      };
      results.push(result);
    } else if (
      (position.positionType === 'Promotion' ||
        position.positionType === 'LoyaltyDiscount') &&
      !ignoreDiscount
    ) {
      const result = {
        id: position.id,
        productId: '',
        positionId: position.id,
        coverUri: position.cover?.asset[selectedLanguage]?.uri,
        name: position.label,
        quantity: position.quantity,
        ean: null,
        price: position.totalPrice,
        productNumber: null,
        manufacturerProductNumber: null,
        currency: currency,
      };
      results.push(result);
    }
  });
  return results;
};

export const getRefundPositionEntries = (
  positionsArray: OrderPositionResponse[],
  selectedLanguage: TranslatedStringIndex,
  currency?: string | null
) => {
  const results: RefundPositionEntry[] = [];
  positionsArray.forEach((position) => {
    const product = position.product;
    if (product) {
      position.orderPositionUnitPrices?.forEach((unit) => {
        const result: RefundPositionEntry = {
          id: unit.id,
          productId: product.id,
          positionId: position.id,
          coverUri: position.cover?.asset[selectedLanguage]?.uri,
          name: product.name[selectedLanguage],
          ean: product.ean,
          price: unit.discountedUnitPrice
            ? unit.discountedUnitPrice.unitPriceGross
            : unit.unitPrice.unitPriceGross,
          productNumber: product.productNumber,
          refundPercentage: 1,
          refundAmount: unit.maxRefundAmount,
          maxRefundAmount: unit.maxRefundAmount,
          isRefundable: unit.isRefundable,
          currency: currency,
        };
        results.push(result);
      });
    }
  });
  return results;
};

export const getNewShippingDetailPositionEntries = (
  positionsArray: OrderPositionResponse[],
  selectedLanguage: TranslatedStringIndex,
  currency?: string | null
) => {
  const results: PositionEntry[] = [];
  positionsArray.forEach((position) => {
    const product = position.product;
    if (product) {
      const result = {
        id: position.id,
        productId: product.id,
        positionId: position.id,
        coverUri: position.cover?.asset[selectedLanguage]?.uri,
        name: product.name[selectedLanguage],
        ean: product.ean,
        price: position.totalPrice,
        productNumber: product.productNumber,
        currency: currency,
        quantity: position.quantity,
        batchNumber: null,
        bestBefore: new Date().toISOString().split('T')[0],
      };
      results.push(result);
    }
  });
  return results;
};

export const getShippingDetailPositionEntries = (
  selectedLanguage: TranslatedStringIndex,
  orderPositionsArray?: OrderPositionResponse[] | null,
  positionsArray?:
    | OrderShippingDetailPositionResponse[]
    | UpsertOrderShippingDetailPositionRequest[]
    | null,
  currency?: string | null
) => {
  const results: PositionEntry[] = [];
  positionsArray?.forEach((position) => {
    const orderPosition = orderPositionsArray?.find(
      (p) => p.id === position.orderPositionId
    );
    if (orderPosition) {
      const product = orderPosition.product;
      if (product) {
        const result = {
          id: position.id ?? Math.random().toString(),
          productId: product.id,
          positionId: orderPosition.id,
          coverUri: product.cover?.asset[selectedLanguage]?.uri,
          name: product.name[selectedLanguage],
          ean: product.ean,
          price: orderPosition.totalPrice,
          currency: currency,
          productNumber: product.productNumber,
          quantity: position.quantity ?? 0,
          batchNumber: position.batchNumber,
          bestBefore: position.bestBefore,
        };
        results.push(result);
      }
    }
  });
  return results;
};

export const getOrderReturnPositionEntries = (
  orderPositionsArray: OrderPositionResponse[],
  positionsArray: OrderReturnPositionResponse[],
  selectedLanguage: TranslatedStringIndex
) => {
  const results: PositionEntry[] = [];
  positionsArray.forEach((position) => {
    const orderPosition = orderPositionsArray.find(
      (p) => p.id === position.orderPositionId
    );
    if (orderPosition) {
      const product = orderPosition.product;
      if (product) {
        const result = {
          coverUri: product.cover?.asset[selectedLanguage]?.uri,
          name: product.name[selectedLanguage],
          ean: product.ean,
          price: orderPosition.totalPrice,
          productNumber: product.productNumber,
          id: position.id,
          createdAt: position.createdAt,
          quantity: position.quantity,
          positionId: position.id,
          orderReturnId: position.orderReturnId,
          batchNumber: position.batchNumber,
          bestBefore: position.bestBefore,
          logisticsReturnReason: position.logisticsReturnReason,
          quality: position.quality,
          notes: position.notes,
          returnDate: position.returnDate,
          productId: product.id,
        };
        results.push(result);
      }
    }
  });
  return results;
};

export const getOrderRefundDetailPositionUnitEntries = (
  orderPositionsArray: OrderPositionResponse[],
  refundDetails: AdyenPaymentRefundDetailResponse[],
  selectedLanguage: TranslatedStringIndex
) => {
  const results: RefundDetailPosition[] = [];
  refundDetails.forEach((detail) => {
    const orderPosition = orderPositionsArray.find(
      (p) => p.id === detail.orderPositionId
    );
    if (orderPosition) {
      const product = orderPosition.product;
      if (product) {
        detail.orderPositionUnitRefundAmounts.forEach((unit) => {
          const result = {
            id: detail.id,
            coverUri: product.cover?.asset[selectedLanguage]?.uri,
            name: product.name[selectedLanguage],
            ean: product.ean,
            productNumber: product.productNumber,
            refundAmount: unit.unitPrice.totalGross,
          };
          results.push(result);
        });
      }
    }
  });
  return results;
};
