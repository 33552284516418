import { SmallHint } from '../hint/Hint';
import './toggleswitch.css';

interface ToggleSwitchProps {
  title?: string;
  label?: string;
  smallSwitch?: boolean;
  smallLabel?: boolean;
  toggle?: () => void;
  toggled: boolean | undefined;
  toggledColor?: string;
  hint?: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  title,
  label,
  smallSwitch,
  smallLabel,
  toggle,
  toggled,
  toggledColor,
  hint,
  icon,
  disabled,
}) => {
  return (
    <div className={`toggleswitch ${disabled ? 'toggleswitch__disabled' : ''}`}>
      {title ? (
        <div className={'input-info'}>
          <div className={'input-title'}>{title}</div>
        </div>
      ) : null}
      <div className={'toggleswitch-inner'}>
        {icon ? <div className={'toggleswitch-inner-icon'}>{icon}</div> : null}
        <div
          className={`toggleswitch-switch ${
            toggled ? 'toggleswitch-switch-on' : 'toggleswitch-switch-off'
          } ${smallSwitch ? 'toggleswitch-switch-small' : ''}`}
          style={
            toggledColor && toggled
              ? { backgroundColor: toggledColor }
              : undefined
          }
          onClick={!disabled ? toggle : undefined}
        >
          <div className="toggleswitch-slider">
            <div
              className={`toggleswitch-switch-lever ${
                smallSwitch ? 'toggleswitch-switch-lever-small' : ''
              } ${
                toggled
                  ? 'toggleswitch-switch-lever-on'
                  : 'toggleswitch-switch-lever-off'
              }`}
            ></div>
          </div>
        </div>
        {label ? (
          <div
            className={
              smallLabel
                ? 'toggleswitch-label toggleswitch-label-small'
                : 'toggleswitch-label'
            }
          >
            {label}
          </div>
        ) : null}
        {hint ? <SmallHint paragraphs={[hint]} /> : null}
      </div>
    </div>
  );
};

export default ToggleSwitch;
