const useLocalStorageSettings = () => {
  const setToggledSetting = (name: string, bool: boolean) => {
    localStorage.setItem(name, bool.toString());
  };

  const getToggledSetting = (name: string) => {
    const bool = localStorage.getItem(name);
    if (bool && bool === 'true') {
      return true;
    } else if (bool && bool === 'false') {
      return false;
    } else {
      return false;
    }
  };

  return {
    setToggledSetting,
    getToggledSetting,
  };
};

export default useLocalStorageSettings;
