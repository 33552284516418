import './actionspopout.css';
import { ItemAction } from '../ItemActions';
import { useTranslation } from 'react-i18next';
import ClickOutsideContainer from '../../clickoutsidecontianer/ClickOutsideContainer';
import { RefObject } from 'react';
import _ from 'lodash';

interface ActionsPopoutProps {
  item?: {
    [key: string]: any;
  };
  show: boolean;
  hide: () => void;
  actions: ItemAction[];
  anchorRef?: RefObject<HTMLDivElement>;
  xOffset?: number;
  yOffset?: number;
}

const ActionsPopout: React.FC<ActionsPopoutProps> = ({
  item,
  show,
  hide,
  actions,
  anchorRef,
  xOffset,
  yOffset,
}) => {
  const { t } = useTranslation();

  const groupedActions = _.groupBy(actions, 'group');

  return (
    <ClickOutsideContainer
      onClickOutside={hide}
      fixedViewportAwareElementRef={anchorRef}
      offsetX={xOffset}
      offsetY={yOffset}
    >
      <div
        style={{
          display: show ? 'block' : 'none',
        }}
        className="actionsPopout"
        onMouseLeave={hide}
      >
        {Object.entries(groupedActions).map(([groupName, actions], i) => {
          return (
            <div className={'actionsPopout-action-group'}>
              {actions.map((action, i) => {
                if (!action.hide || !action.hide(item)) {
                  if (action.renderFunc) {
                    return action.renderFunc();
                  } else {
                    return (
                      <div
                        key={i}
                        className={`actionsPopout-action actionsPopout-action__${action.look}`}
                        onClick={(e) => {
                          if (action.action) action.action(item);
                          e.stopPropagation();
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        {action.icon ? (
                          <div
                            className={`actionsPopout-action-icon actionsPopout-action-icon__${action.look}`}
                          >
                            {action.icon}
                          </div>
                        ) : null}
                        {action.ctaAlreadyTranslated
                          ? action.cta
                          : t('actions.' + action.cta)}
                      </div>
                    );
                  }
                } else {
                  return null;
                }
              })}
            </div>
          );
        })}
      </div>
    </ClickOutsideContainer>
  );
};

export default ActionsPopout;
