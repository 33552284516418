import './loyaltypointtransaction.css';
import {
  LoyaltyPointTransactionResponse,
  UpdateLoyaltyPointTransactionRequest,
} from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import useDateTools from '../../../../../hooks/useDateTools';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import Button from '../../../../../elements/button/Button';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../../contexts/auth/User';
import { useState } from 'react';
import Popup from '../../../../../elements/popup/Popup';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import useNotifications from '../../../../../hooks/useNotifications';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import InformationBox from '../../../../../elements/informationbox/InformationBox';
import LoyaltyPointTransactionEdit from '../loyaltypointtransactionedit/LoyaltyPointTransactionEdit';

interface LoyaltyPointTransactionProps {
  transaction: LoyaltyPointTransactionResponse;
  getTransactions: () => void;
}

const LoyaltyPointTransaction: React.FC<LoyaltyPointTransactionProps> = ({
  transaction,
  getTransactions,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.customer.loyaltyRank.loyaltyPointTransactions',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const loyaltyPointTransactionsApi = api.loyaltyPointTransactionsApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const { displayReadableDate } = useDateTools();
  const link = useNavigate();
  const [deletePopup, setDeletePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedTransaction, setUpdatedTransaction] = useState(
    transaction as UpdateLoyaltyPointTransactionRequest
  );

  const deleteTransaction = () => {
    setIsSubmitting(true);
    loyaltyPointTransactionsApi
      .loyaltyPointTransactionsDeleteLoyaltyPointTransaction(transaction.id)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.delete_success'));
        setIsSubmitting(false);
        setDeletePopup(false);
        getTransactions();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const updateTransaction = () => {
    setIsSubmitting(true);
    loyaltyPointTransactionsApi
      .loyaltyPointTransactionsUpdateLoyaltyPointTransaction(
        transaction.id,
        updatedTransaction
      )
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_success'));
        setIsSubmitting(false);
        setEditPopup(false);
        getTransactions();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'loyaltyPointTransaction'}>
      <div className={'loyaltyPointTransaction-brief'}>
        {t('at')}
        <div
          className={
            'loyaltyPointTransaction-brief-date global-monospaced-contrast'
          }
        >
          {displayReadableDate(transaction.createdAt)}
        </div>
        {t('fill')}
        <div
          className={`loyaltyPointTransaction-brief-points ${
            transaction.type === 'Credit'
              ? 'loyaltyPointTransaction-brief-points__credit'
              : 'loyaltyPointTransaction-brief-points__redemption'
          }`}
        >
          {transaction.points}
        </div>
        {`${t('points')} ${t(`type.${transaction.type}`)}.`}
      </div>
      {transaction.description ? (
        <div className={'loyaltyPointTransaction-description'}>
          {`${t('description')}: ${
            transaction.description[i18n.language as TranslatedStringIndex]
          }`}
        </div>
      ) : null}
      {user?.isProductOwner || transaction.orderId ? (
        <div className={'loyaltyPointTransaction-actions'}>
          {user?.isProductOwner ? (
            <>
              <Button
                look={'secondary-danger'}
                width={'tiny'}
                cta={t('delete')}
                action={() => setDeletePopup(true)}
                margin={'right'}
              />
              <Button
                look={'secondary'}
                width={'tiny'}
                cta={t('edit')}
                action={() => setEditPopup(true)}
                margin={'right'}
              />
            </>
          ) : null}
          {transaction.orderId ? (
            <Button
              look={'secondary'}
              width={'tiny'}
              cta={t('toOrder')}
              action={() => link(`/orders/${transaction.orderId}`)}
            />
          ) : null}
        </div>
      ) : null}
      <Popup
        toggled={deletePopup}
        width={'30%'}
        close={() => setDeletePopup(false)}
      >
        <div className={'popup-title'}>{t('deletePopup.title')}</div>
        <InformationBox
          type={'warning'}
          title={t('deletePopup.warning.title')}
          content={t('deletePopup.warning.text')}
        />
        <Button
          cta={t('deletePopup.cta')}
          look={'danger'}
          width={'full'}
          isLoading={isSubmitting}
          action={deleteTransaction}
        />
      </Popup>
      <Popup
        toggled={editPopup}
        width={'30%'}
        close={() => setEditPopup(false)}
      >
        <div className={'popup-title'}>{t('editPopup.title')}</div>
        <LoyaltyPointTransactionEdit
          transaction={updatedTransaction}
          updateTransaction={setUpdatedTransaction}
        />
        <div className={'global-cardActions'}>
          <Button
            cta={t('editPopup.cta')}
            look={'save'}
            width={'full'}
            isLoading={isSubmitting}
            action={updateTransaction}
          />
        </div>
      </Popup>
    </div>
  );
};

export default LoyaltyPointTransaction;
