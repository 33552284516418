import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  CreateProductRequest,
  ProductResponse,
} from '../../../api/petcloudapi/api';
import Input from '../../../elements/input/Input';
import { LoadingContainer } from '../../../elements/loading/Loading';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import {
  useErrorHandler,
  Error,
  hasError,
} from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import './brandproductline.css';

interface BrandProductLineProps {
  product: ProductResponse | CreateProductRequest;
  parentProduct?: ProductResponse | null;
  updateProduct?: (product: ProductResponse) => void;
  updateNewProduct?: (product: CreateProductRequest) => void;
  availabeBrandOptions?: DropdownOption[];
  errors?: Error[];
}

const BrandProductLine: React.FC<BrandProductLineProps> = ({
  product,
  parentProduct,
  updateNewProduct,
  updateProduct,
  availabeBrandOptions,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const brandsApi = api.brandsApi();
  const errorHandler = useErrorHandler();

  const [brandOptions, setBrandOptions] = useState<DropdownOption[] | null>(
    availabeBrandOptions ?? null
  );

  // only getting brands here if not already provided by parent component
  useEffect(() => {
    if (brandOptions === null) {
      getBrands();
    }
  }, []);

  const getBrands = () => {
    brandsApi
      .brandsGetBrands()
      .then((response) => {
        console.log(response);
        setBrandOptions(
          response.data.map((brand) => {
            return {
              id: brand.id,
              name:
                brand.name[i18n.language as TranslatedStringIndex] ??
                brand.name['de-DE']!,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (brandOptions) {
    return (
      <div className="brandproductline">
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Dropdown
              title={t('view.product.brandproductline.brand')}
              optionObjects={brandOptions}
              selected={
                brandOptions.find((o) => o.id === product.brandId)?.name
              }
              update={(e) => {
                const value =
                  e.target.selectedOptions[0].getAttribute('data-value');
                if (value) {
                  if (updateProduct) {
                    updateProduct({
                      ...(product as ProductResponse),
                      brandId: value,
                    });
                  } else if (updateNewProduct) {
                    updateNewProduct({
                      ...(product as CreateProductRequest),
                      brandId: value,
                    });
                  }
                }
              }}
              required
              errors={hasError(errors, ['BrandId'])}
              disabled={!!parentProduct}
              inherited={
                brandOptions.find((o) => o.id === parentProduct?.brandId)?.name
              }
              locked={!!parentProduct}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              key={'productline'}
              title={t('view.product.brandproductline.productline')}
              translatedContent={product.productLine}
              update={
                !parentProduct
                  ? (e, lang) => {
                      if (lang) {
                        if (updateProduct) {
                          updateProduct({
                            ...(product as ProductResponse),
                            productLine: {
                              ...(product as ProductResponse).productLine,
                              [lang]: e,
                            },
                          });
                        } else if (updateNewProduct) {
                          updateNewProduct({
                            ...(product as CreateProductRequest),
                            productLine: {
                              ...(product as CreateProductRequest).productLine,
                              [lang]: e,
                            },
                          });
                        }
                      }
                    }
                  : undefined
              }
              inherited={
                parentProduct?.productLine?.[
                  i18n.language as TranslatedStringIndex
                ]
              }
              locked={true}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default BrandProductLine;
