import {
  TranslatedTypeResponseOfString,
  UpdateProductAnalyticConstituentRequest,
} from '../../../../api/petcloudapi/api';
import {
  Dropdown,
  DropdownOption,
} from '../../../../elements/selectors/Selectors';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { constituentslist } from '../constituentslist';
import Input from '../../../../elements/input/Input';
import Button from '../../../../elements/button/Button';
import { ReactComponent as IconTrash } from '../../../../../assets/icon/trash.svg';

interface AnalyticConstituentProps {
  constituent: UpdateProductAnalyticConstituentRequest;
  updateConstituent: (
    constituent: UpdateProductAnalyticConstituentRequest
  ) => void;
  deleteConstituent: () => void;
  readonly?: boolean;
  options: DropdownOption[];
}

export const AnalyticConstituent: React.FC<AnalyticConstituentProps> = ({
  constituent,
  updateConstituent,
  deleteConstituent,
  readonly,
  options,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.product.analyticConstituents.constituent',
  });
  const [manualInput, setManualInput] = useState(false);
  const [selected, setSelected] = useState<string | null>(
    constituent.name
      ? constituent.name[i18n.language as TranslatedStringIndex] ??
          'missing translation'
      : null
  );
  const constituentsList = constituentslist;

  useEffect(() => {
    if (nameNotInit(constituent.name)) {
      if (nameNotKnown(constituent.name)) {
        setManualInput(true);
      }
    }
  }, []);

  const nameNotKnown = (name: TranslatedTypeResponseOfString) => {
    return !constituentsList.find(
      (c) => JSON.stringify(c.name) === JSON.stringify(name)
    );
  };

  const nameNotInit = (name: TranslatedTypeResponseOfString) => {
    return (
      JSON.stringify(name) !==
      JSON.stringify({
        'de-DE': '',
        'en-GB': '',
      })
    );
  };

  return (
    <div className="analyticconstituent">
      {manualInput ? (
        Object.entries(constituent.name).map(
          ([language, title]: [string, string]) => {
            return (
              <div key={language} className="analyticconstituent-input">
                <Input
                  key={language}
                  title={t('name') + ` (${language})`}
                  content={title}
                  update={(e) => {
                    updateConstituent({
                      ...constituent,
                      name: {
                        ...constituent.name,
                        [language as TranslatedStringIndex]: e,
                      },
                    });
                  }}
                />
              </div>
            );
          }
        )
      ) : (
        <div className="analyticconstituent-input">
          <Dropdown
            title={t('title')}
            optionObjects={options}
            selected={selected}
            update={(e) => {
              const id =
                e.currentTarget.selectedOptions[0].getAttribute('data-value');
              if (id) {
                const selectedConstituent = constituentsList.find(
                  (c) => c.id === id
                );
                if (selectedConstituent) {
                  updateConstituent({
                    ...constituent,
                    name: selectedConstituent.name,
                  });
                }
                setSelected(e.currentTarget.selectedOptions[0].value);
              }
            }}
          />
        </div>
      )}
      <div className="analyticconstituent-input">
        <Input
          title={t('value')}
          content={constituent.value.toString()}
          update={(e) => {
            updateConstituent({
              ...constituent,
              value: parseFloat(e !== '' ? e : '0'),
            });
          }}
          type={'number'}
          unit={'%'}
        />
      </div>
      {!readonly ? (
        <div className="analyticconstituent-delete">
          <Button type="icon" look={'secondary'} action={deleteConstituent}>
            <IconTrash className="button-icon button-icon-danger" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};
