import './updateshippingdetail.css';
import {
  OrderPositionResponse,
  OrderResponse,
  OrderShippingDetailResponse,
  UpdateOrderShippingDetailRequest,
} from '../../../../api/petcloudapi/api';
import ShippingDetailEdit from '../shippingdetailedit/ShippingDetailEdit';
import useNotifications from '../../../../hooks/useNotifications';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UpdateShippingDetailProps {
  order: OrderResponse;
  orderPositions: OrderPositionResponse[];
  shippingDetail: OrderShippingDetailResponse;
  refreshOrder: () => void;
  closePopup: () => void;
}

const UpdateShippingDetail: React.FC<UpdateShippingDetailProps> = ({
  order,
  orderPositions,
  shippingDetail,
  refreshOrder,
  closePopup,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.order.shippingDetails.update',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const orderShippingDetailsApi = api.orderShippingDetailsApi();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const convertResponseToRequest = (): UpdateOrderShippingDetailRequest => {
    return {
      ...shippingDetail,
      positions: shippingDetail.positions
        ? shippingDetail.positions.map((position) => {
            return {
              ...position,
              orderId: order.id,
              quantity: position.quantity ?? 0,
              orderPositionId:
                position.orderPositionId ?? 'missing orderPositionId',
            };
          })
        : [],
    };
  };

  const submit = (request: UpdateOrderShippingDetailRequest) => {
    setIsSubmitting(true);
    orderShippingDetailsApi
      .orderShippingDetailsUpdateOrderShippingDetail(shippingDetail.id, request)
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        pushNotification(t('notifications.success'));
        refreshOrder();
        closePopup();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'updateShippingDetail'}>
      <ShippingDetailEdit
        order={order}
        orderPositions={orderPositions}
        shippingDetail={convertResponseToRequest()}
        submit={submit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default UpdateShippingDetail;
