import './transactionbrief.css';
import { Amount, Transaction } from '../../../api/petcloudapi/api';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';
import { useTranslation } from 'react-i18next';
import Badge from '../../../elements/badge/Badge';
import { states } from '../../../features/list/List';
import DateTime from '../../../elements/datetime/DateTime';

interface TransactionBriefProps {
  transaction: Transaction;
}

const TransactionBrief: React.FC<TransactionBriefProps> = ({ transaction }) => {
  const { t } = useTranslation();

  const renderAmount = (amount: Amount) => {
    return <span>{amount.value / 100 + ' ' + amount.currency}</span>;
  };

  const renderStatus = (status: string) => {
    return (
      <Badge
        title={t('list.states.' + status)}
        color={states[status] ?? 'var(--color-gray)'}
      />
    );
  };

  const renderDate = (date: string) => {
    return (
      <DateTime dateString={date} show={['day', 'month', 'year', 'time']} />
    );
  };

  const prepareDataForList = () => {
    return [
      {
        title: t('view.payment.transaction.status'),
        value: transaction.status,
        renderMethod: renderStatus,
      },
      {
        title: t('view.payment.transaction.type'),
        value: transaction.type,
      },
      {
        title: t('view.payment.transaction.amount'),
        value: transaction.amount,
        renderMethod: renderAmount,
      },
      {
        title: t('view.payment.transaction.bookingDate'),
        value: transaction.bookingDate,
        renderMethod: renderDate,
      },
      {
        title: t('view.payment.transaction.payedby'),
        value: transaction.counterparty?.bankAccount?.accountHolder?.fullName,
      },
    ];
  };

  return (
    <div className="transactionbrief">
      <TitleValueList entries={prepareDataForList()} />
    </div>
  );
};

export default TransactionBrief;
