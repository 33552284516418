import './newuserpropertyoption.css';
import Input from '../../input/Input';
import Button from '../../button/Button';
import { useState } from 'react';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import {
  ProductGroupMappingResponse,
  PropertyOptionResponse,
} from '../../../api/petcloudapi/api';
import InformationBox from '../../informationbox/InformationBox';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import useLanguages from '../../../hooks/useLanguages';
import Popup from '../../popup/Popup';

interface NewPropertyOptionProps {
  productGroupMapping: ProductGroupMappingResponse;
  postSubmit: (option: PropertyOptionResponse) => void;
  selectedAnimalSpeciesIds?: string[];
  isVariantOption?: boolean;
  isLanguageNeutral?: boolean;
}

const NewUserPropertyOption: React.FC<NewPropertyOptionProps> = ({
  productGroupMapping,
  postSubmit,
  selectedAnimalSpeciesIds,
  isVariantOption,
  isLanguageNeutral,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.propertyBrowser.newPropertyOption',
  });
  const { emptyTranslatableContent } = useLanguages();
  const api = usePetCloudApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState({
    ...emptyTranslatableContent,
  });
  const [suggestUnitPopup, setSuggestUnitPopup] = useState(false);

  const isNum = (value: string) => /^-?[0-9]*[.,]?[0-9]+$/.test(value);

  const validateAndSubmitNewOption = () => {
    console.log(productGroupMapping.propertyGroup?.productUnitId);
    if (productGroupMapping.propertyGroup?.productUnitId) {
      //in case of present productUnit make sure the input is a number or empty string
      const validation = Object.entries(newOptionValue).map(([lang, value]) => {
        if (value) {
          if (isNum(value)) {
            return 'pass';
          } else {
            return 'fail';
          }
        } else {
          return 'pass';
        }
      });
      console.log(validation);
      if (!validation.includes('fail')) {
        submitNewOption();
      } else {
        pushNotification(t('notifications.onlyNumberAllowed'), 'warning');
      }
    } else {
      // in case of no present productUnit make sure there is letters (a unit) in the user input
      const validation = Object.entries(newOptionValue).map(([lang, value]) => {
        if (value) {
          const containsLetters = !isNum(value);
          const lastCharIsLetter = !isNum(value.slice(-1));
          console.log(containsLetters);
          if (containsLetters && lastCharIsLetter) {
            return 'pass';
          } else {
            return 'fail';
          }
        } else {
          return 'pass';
        }
      });
      console.log(validation);
      if (!validation.includes('fail')) {
        submitNewOption();
      } else {
        setSuggestUnitPopup(true);
      }
    }
  };

  const submitNewOption = () => {
    setIsSubmitting(true);
    console.log(selectedAnimalSpeciesIds);
    productGroupMappingsApi
      .productGroupMappingsValidateAndCreateUserInput(productGroupMapping.id, {
        propertyGroupId: productGroupMapping.propertyGroupId,
        propertyValue: newOptionValue,
        animalSpeciesIds: selectedAnimalSpeciesIds,
        isVariantOption: isVariantOption,
      })
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        pushNotification(t('notifications.success'));
        setNewOptionValue({ ...emptyTranslatableContent });
        postSubmit(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  console.log(productGroupMapping);

  const unit =
    productGroupMapping.propertyGroup?.productUnit?.shortCode[
      i18n.language as TranslatedStringIndex
    ];

  return (
    <div className={'newPropertyOption'}>
      <InformationBox
        title={t('infoTitle')}
        content={
          <div className={'newPropertyOption-rules'}>
            {productGroupMapping.propertyValidationRules?.map((rule) => {
              return (
                <div className={'newPropertyOption-rule'}>
                  {`• ${
                    rule.description?.[
                      i18n.language as TranslatedStringIndex
                    ] ?? 'missing translation'
                  }`}
                </div>
              );
            })}
          </div>
        }
        type={'info'}
      />
      <div className={'global-inputGroup'}>
        {isLanguageNeutral ? (
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('value')}
              content={newOptionValue['de-DE']}
              update={(value) => {
                setNewOptionValue({ ...newOptionValue, 'de-DE': value });
              }}
              unit={unit}
            />
          </div>
        ) : (
          <>
            <div className={'global-inputGroup-input'}>
              <Input
                title={`${t('value')} (de-DE)`}
                content={newOptionValue['de-DE']}
                update={(value) => {
                  setNewOptionValue({ ...newOptionValue, 'de-DE': value });
                }}
                unit={unit}
              />
            </div>
            <div className={'global-inputGroup-input'}>
              <Input
                title={`${t('value')} (en-GB)`}
                content={newOptionValue['en-GB']}
                update={(value) => {
                  setNewOptionValue({ ...newOptionValue, 'en-GB': value });
                }}
                unit={unit}
              />
            </div>
          </>
        )}
      </div>
      <div className={'global-cardActions'}>
        <Button
          cta={t('cta')}
          look={'save'}
          width={'full'}
          action={validateAndSubmitNewOption}
          isLoading={isSubmitting}
        />
      </div>
      <Popup
        toggled={suggestUnitPopup}
        width={'20%'}
        close={() => setSuggestUnitPopup(false)}
      >
        <div className={'popup-title'}>{t('suggestUnitPopup.title')}</div>
        <InformationBox
          type={'warning'}
          content={t('suggestUnitPopup.text')}
          maxWidth={400}
        />
        <div className={'global-cardActions global-cardActions-spaceBetween'}>
          <Button
            cta={t('suggestUnitPopup.actions.cancel')}
            look={'secondary'}
            width={'minimal'}
            action={() => setSuggestUnitPopup(false)}
          />
          <Button
            cta={t('suggestUnitPopup.actions.save')}
            look={'save'}
            width={'minimal'}
            action={() => {
              setSuggestUnitPopup(false);
              submitNewOption();
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default NewUserPropertyOption;
