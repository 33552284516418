// this is used to control which policies are shown in the PolicyMatrix
// as there are some policies which are never of interest for the frontend

const blacklist = [
  'mail_templates',
  'credentials',
  'order_invoice_addresses',
  'order_payment_transactions',
  'order_positions',
  'order_shipping_addresses',
  'number_range_states',
  'payment_providers',
  'synchronizations',
  'number_ranges',
  'tax_types',
  'taxes',
  'pet_cloud_financials',
  'document_layout',
];

export default blacklist;
