import {
  Dropdown,
  DropdownOption,
} from '../../../../../elements/selectors/Selectors';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from '../../../../../elements/button/Button';
import { AdyenWizardStep } from '../AdyenWizard';

interface CompanyTypeProps {
  setStep: (step: AdyenWizardStep) => void;
  setPopupWidth: (width: string) => void;
}

const CompanyType: React.FC<CompanyTypeProps> = ({
  setStep,
  setPopupWidth,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard.companyType',
  });

  const companyTypeOptions: DropdownOption[] = [
    {
      id: 'company',
      name: t('type.company'),
    },
    {
      id: 'soleProprietorship',
      name: t('type.soleProprietorship'),
    },
  ];

  const [selectedTypeId, setSelectedTypeId] = useState(
    companyTypeOptions[0].id
  );

  useEffect(() => {
    setPopupWidth('20%');
  }, []);

  return (
    <div className={'companyType'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement global-textElement'}>{t('text')}</div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('type.title')}
            optionObjects={companyTypeOptions}
            selected={
              companyTypeOptions.find((x) => x.id === selectedTypeId)?.name
            }
            update={(e) => {
              const id =
                e.currentTarget.selectedOptions[0].getAttribute('data-value');
              if (id) setSelectedTypeId(id);
            }}
          />
        </div>
      </div>
      <div className={'global-cardActions'}>
        <Button
          cta={t('cta_next')}
          look={'secondary'}
          width={'minimal'}
          action={() =>
            setStep(
              selectedTypeId === 'company'
                ? AdyenWizardStep.Shareholders
                : AdyenWizardStep.SoleProprietorshipCompanyInfo
            )
          }
        />
      </div>
    </div>
  );
};

export default CompanyType;
