import './stringarraytable.css';
import { ReactComponent as PlusIcon } from '../../../assets/icon/plus.svg';
import { useTranslation } from 'react-i18next';
import ItemActions from '../itemactions/ItemActions';

interface StringArrayTableProps {
  header: string[];
  rows: string[][];
  updateHeader: (value: string, index: number) => void;
  updateCell: (value: string, rowIndex: number, cellIndex: number) => void;
  addColumn?: (index: number) => void;
  deleteColumn?: (index: number) => void;
  addRow?: (index: number) => void;
  deleteRow?: (index: number) => void;
  onPaste?: (
    pastedInput: string,
    startCoords: { column: number; row: number }
  ) => void;
}

const StringArrayTable: React.FC<StringArrayTableProps> = ({
  header,
  rows,
  updateHeader,
  updateCell,
  addColumn,
  deleteColumn,
  addRow,
  deleteRow,
  onPaste,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="table-wrapper">
        <table className="table stringArrayTable">
          <thead>
            <tr>
              <th key={'column-index'}></th>
              {header.map((column, i) => {
                return (
                  <th key={'column' + i}>
                    <div className={'table-column-header'}>
                      <TableInput
                        content={column}
                        update={(value) => updateHeader(value, i)}
                        onPaste={
                          onPaste
                            ? (input) =>
                                onPaste(input, {
                                  column: i,
                                  row: 0,
                                })
                            : undefined
                        }
                      />
                      <div className={'table-column-header-options-wrapper'}>
                        {addColumn && deleteColumn ? (
                          <ItemActions
                            item={{}}
                            actions={[
                              {
                                cta: t(
                                  'components.stringarraytable.columnMenu.add'
                                ),
                                ctaAlreadyTranslated: true,
                                look: 'blue',
                                action: () => addColumn(i),
                              },
                              {
                                cta: t(
                                  'components.stringarraytable.columnMenu.delete'
                                ),
                                ctaAlreadyTranslated: true,
                                look: 'danger',
                                action: () => deleteColumn(i),
                              },
                            ]}
                            xOffset={30}
                          />
                        ) : null}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => {
              return (
                <tr key={'row' + i}>
                  <td key={'cell-index'} className={'cell-index'}>
                    <div className={'cell-index-number'}>{i + 1}</div>
                    {addRow && deleteRow ? (
                      <div className={'table-row-options-wrapper'}>
                        <ItemActions
                          item={{}}
                          actions={[
                            {
                              cta: t('components.stringarraytable.rowMenu.add'),
                              ctaAlreadyTranslated: true,
                              look: 'blue',
                              action: () => addRow(i),
                            },
                            {
                              cta: t(
                                'components.stringarraytable.rowMenu.delete'
                              ),
                              ctaAlreadyTranslated: true,
                              look: 'danger',
                              action: () => deleteRow(i),
                            },
                          ]}
                          xOffset={30}
                        />
                      </div>
                    ) : null}
                  </td>
                  {row.map((cell, j) => {
                    return (
                      <td key={'cell' + j}>
                        <TableInput
                          content={cell}
                          update={(value) => updateCell(value, i, j)}
                          onPaste={
                            onPaste
                              ? (input) =>
                                  onPaste(input, {
                                    column: j,
                                    row: i + 1,
                                  })
                              : undefined
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {addColumn ? (
          <div
            className={'table-addColumn'}
            onClick={() => addColumn(header.length - 1)}
          >
            <PlusIcon
              className="table-icon"
              fill={'var(--color-text_secondary)'}
            />
          </div>
        ) : null}
      </div>
      {addRow ? (
        <div className={'table-addRow'} onClick={() => addRow(rows.length)}>
          <PlusIcon
            className="table-icon"
            fill={'var(--color-text_secondary)'}
          />
        </div>
      ) : null}
    </>
  );
};

interface TableInputProps {
  content?: string;
  update?: (value: string) => void;
  onPaste?: (pastedInput: string) => void;
  hasError?: boolean;
}

export const TableInput: React.FC<TableInputProps> = ({
  content,
  update,
  onPaste,
  hasError,
}) => {
  return (
    <input
      className={`table-input ${hasError ? 'table-input_error' : null}`}
      defaultValue={content}
      onBlur={update ? (e) => update(e.target.value) : undefined}
      onPaste={
        onPaste
          ? (e) => {
              e.preventDefault();
              onPaste(e.clipboardData.getData('text'));
            }
          : undefined
      }
    />
  );
};

export default StringArrayTable;
