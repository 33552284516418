import './texthighlight.css';

interface TextHighlightProps {
  text: string | number;
  isEmbeddedInText?: boolean;
  color?: string;
}

const TextHighlight: React.FC<TextHighlightProps> = ({
  text,
  isEmbeddedInText,
  color,
}) => {
  const c = color ?? 'var(--color-text_primary)';

  return (
    <div
      className={`textHighlight ${
        isEmbeddedInText ? 'textHighlight-embedded' : null
      }`}
    >
      <span className={'textHighlight-text'} style={{ color: c }}>
        {text}
      </span>
      <div
        className={'textHighlight-background'}
        style={{ backgroundColor: c }}
      ></div>
    </div>
  );
};

export default TextHighlight;
