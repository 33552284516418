import './stockseditor.css';
import {
  ProductStockResponse,
  SimpleProductResponse,
  WarehouseResponse,
} from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Stock from '../../../../sections/product/stocks/Stock';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import {
  getUnusedWarehouses,
  newStock,
} from '../../../../sections/product/stocks/Stocks';

interface StocksEditorProps {
  value: ProductStockResponse[];
  warehouses: WarehouseResponse[];
  updateCallback: EditorUpdateCallback;
  productId: string;
}

const StocksEditor: React.FC<StocksEditorProps> = ({
  value,
  warehouses,
  updateCallback,
  productId,
}) => {
  const { t } = useTranslation();
  const [stocks, setStocks] = useState(value ?? []);

  const addStock = () => {
    const update = [...stocks];
    update.push({
      ...newStock(stocks.length + 1),
      createdAt: 'createdAt',
      product: {} as SimpleProductResponse,
      warehouse: {} as WarehouseResponse,
      productId: productId,
    });
    setStocks(update);
  };

  useEffect(() => {
    console.log(warehouses);
  }, [warehouses]);

  return (
    <div className={'jtce-stocks-editor'}>
      <div className={'jtce-title'}>
        {t('view.productBulkEditor.headers.productStocks')}
      </div>
      <div className={'jtce-stocks-editor-stocks'}>
        {stocks.map((stock) => {
          return (
            <Stock
              key={stock.id}
              stock={stock}
              isNewStock={true}
              update={(stock) => {
                console.log(stock.id);
                const update = [...stocks];
                const i = update.findIndex((s) => s.id === stock.id);
                if (i !== -1) {
                  update[i] = { ...(stock as ProductStockResponse) };
                  setStocks(update);
                }
              }}
              deleteStock={(id) => {
                const update = [...stocks];
                const i = update.findIndex((s) => s.id === id);
                console.log(id);
                if (i !== -1) {
                  update.splice(i, 1);
                  setStocks(update);
                }
              }}
              totalStocksLength={stocks.length}
              warehouses={warehouses}
              unusedWarehouseOptions={getUnusedWarehouses(warehouses, stocks)}
            />
          );
        })}
      </div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: stocks }]);
            } catch {}
          }}
          buttons={[
            {
              cta: t('view.product.stocks.new'),
              action: addStock,
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default StocksEditor;
