import './propertieslist.css';
import {
  ProductGroupMappingResponse,
  PropertyGroupResponse,
} from '../../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import Badge from '../../../../elements/badge/Badge';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

interface PropertiesListProps {
  availablePropertyGroups: PropertyGroupResponse[];
  propertyGroups: PropertyGroupResponse[];
  validationOptional?: boolean;
  productGroupMappings: ProductGroupMappingResponse[];
  hideEmptyGroups?: boolean;
}

const PropertiesList: React.FC<PropertiesListProps> = ({
  availablePropertyGroups,
  propertyGroups,
  validationOptional,
  productGroupMappings,
  hideEmptyGroups,
}) => {
  const { i18n, t } = useTranslation();

  const renderPropertyGroupRow = (
    availableGroups: PropertyGroupResponse[],
    group: PropertyGroupResponse,
    key: string | number,
    isExtra?: true
  ) => {
    return (
      <div key={key} className="propertiesList-property">
        <div className="propertiesList-property-name">
          <div className="propertiesList-property-name-title">
            {group.name[i18n.language as TranslatedStringIndex]}
            {group.productUnit ? (
              <div className="propertiesList-property-name-title-unit">
                (
                {group.productUnit.name[i18n.language as TranslatedStringIndex]}
                )
              </div>
            ) : null}
          </div>
          {!validationOptional &&
          productGroupMappings.find(
            (m) => m.propertyGroupId === group.id && !m.isOptional
          ) ? (
            <div className="propertiesList-property-name-badge">
              <Badge
                title={t('view.product.properties.required')}
                color={'var(--color-danger)'}
              />
            </div>
          ) : null}
          {isExtra ? (
            <div className="propertiesList-property-name-badge">
              <Badge
                title={t('view.product.properties.isExtra')}
                color={'var(--color-yellow)'}
                hintParagraphs={[t('view.product.properties.isExtra_hint')]}
              />
            </div>
          ) : null}
        </div>
        <div className="propertiesList-property-values">
          {availableGroups
            .find((p) => p.id === group.id)
            ?.options.map((property, i) => {
              return (
                <div key={i} className="propertiesList-property-value">
                  {property.name[i18n.language as TranslatedStringIndex]}
                </div>
              );
            }) ?? <div className="propertiesList-property-noValue">-</div>}
        </div>
      </div>
    );
  };

  const renderPropertyGroupRows = () => {
    const nodes: ReactNode[] = [];
    const extraNodes: ReactNode[] = [];

    // all properties that are available with their values for this product, if present
    if (hideEmptyGroups) {
      propertyGroups?.forEach((group, i) => {
        nodes.push(renderPropertyGroupRow(propertyGroups, group, i));
      });
    } else {
      availablePropertyGroups?.forEach((group, i) => {
        nodes.push(renderPropertyGroupRow(propertyGroups, group, i));
      });
    }

    propertyGroups
      .filter((x) => !availablePropertyGroups?.find((p) => p.id === x.id))
      .forEach((group, i) => {
        extraNodes.push(renderPropertyGroupRow(propertyGroups, group, i, true));
      });
    return nodes?.concat(extraNodes);
  };

  return <div className={'propertiesList'}>{renderPropertyGroupRows()}</div>;
};

export default PropertiesList;
