import './loyaltypointprocessingconfigs.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  CreateLoyaltyPointProcessingConfigRequest,
  LoyaltyPointHandlerConfig,
  LoyaltyPointProcessingConfigResponse,
} from '../../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';
import LoyaltyPointProcessingConfig from './loyaltypointprocessingconfig/LoyaltyPointProcessingConfig';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { ReactComponent as IconPlus } from '../../../../../assets/icon/plus.svg';
import { ReactComponent as IconArrow } from '../../../../../assets/icon/arrow_down.svg';
import { ReactComponent as IconHelp } from '../../../../../assets/icon/question.svg';
import Button from '../../../../elements/button/Button';
import useNotifications from '../../../../hooks/useNotifications';
import Popup from '../../../../elements/popup/Popup';
import InformationBox from '../../../../elements/informationbox/InformationBox';

const LoyaltyPointProcessingConfigs = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.loyalty.loyaltyPointProcessingConfigs',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const loyaltyPointProcessingConfigsApi =
    api.loyaltyPointProcessingConfigsApi();
  const errorHandler = useErrorHandler();

  const [
    newLoyaltyPointProcessingConfigId,
    setNewLoyaltyPointProcessingConfigId,
  ] = useState<string | null>(null);
  const [loyaltyPointProcessingConfigs, setLoyaltyPointProcessingConfigs] =
    useState<LoyaltyPointProcessingConfigResponse[] | null>(null);
  const [
    originalLoyaltyPointProcessingConfigs,
    setOriginalLoyaltyPointProcessingConfigs,
  ] = useState<LoyaltyPointProcessingConfigResponse[] | null>(null);
  const [loyaltyPointHandlerConfigs, setLoyaltyPointHandlerConfigs] = useState<
    LoyaltyPointHandlerConfig[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handlerNameLegend, setHandlerNameLegend] = useState(false);

  useEffect(() => {
    getLoyaltyPointProcessingConfigs();
    getLoyaltyPointHandlerConfigs();
  }, []);

  const getLoyaltyPointProcessingConfigs = () => {
    loyaltyPointProcessingConfigsApi
      .loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs()
      .then((response) => {
        console.log(response);

        // Create a sorted array
        let sortedArray = [];
        let idMap = new Map();

        // Map each element by its ID
        response.data.forEach((config) => {
          idMap.set(config.id, config);
        });

        // Look for the initial element
        let currentElement = response.data.find(
          (config) => config.isInitialHandler
        );

        while (currentElement) {
          sortedArray.push(currentElement);

          // Look for the next element
          currentElement = idMap.get(currentElement.nextHandlerId);
        }

        setLoyaltyPointProcessingConfigs(sortedArray);
        setOriginalLoyaltyPointProcessingConfigs(
          JSON.parse(JSON.stringify(sortedArray))
        );
        setNewLoyaltyPointProcessingConfigId(null);
      })
      .catch((error) => {
        errorHandler.addError(error.response);
      });
  };

  const getLoyaltyPointHandlerConfigs = () => {
    loyaltyPointProcessingConfigsApi
      .loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs()
      .then((response) => {
        console.log(response);
        setLoyaltyPointHandlerConfigs(response.data);
      })
      .catch((error) => {
        errorHandler.addError(error.response);
      });
  };

  const addConfig = (index?: number) => {
    if (loyaltyPointProcessingConfigs) {
      if (!newLoyaltyPointProcessingConfigId) {
        const newConfig: LoyaltyPointProcessingConfigResponse = {
          id: crypto.randomUUID(),
          createdAt: '',
          updatedAt: '',
          handlerName: '',
          handlerArguments: null,
          isEnabled: true,
          isInitialHandler: false,
          nextHandlerId: '',
        };

        const updatedConfigs = [...loyaltyPointProcessingConfigs];
        if (index !== undefined) {
          console.log(index);
          updatedConfigs.splice(index + 1, 0, newConfig);
        } else {
          updatedConfigs.push(newConfig);
        }
        setNewLoyaltyPointProcessingConfigId(newConfig.id);
        handleConfigChaining(updatedConfigs);
      } else {
        pushNotification(t('notifications.newConfigProhibited'), 'warning');
      }
    }
  };

  const updateConfig = (config: LoyaltyPointProcessingConfigResponse) => {
    const i = loyaltyPointProcessingConfigs?.findIndex(
      (x) => x.id === config.id
    );
    console.log(i);
    if (loyaltyPointProcessingConfigs && i !== undefined && i !== -1) {
      console.log('update');
      const updatedConfigs = [...loyaltyPointProcessingConfigs];
      updatedConfigs[i] = { ...config };
      setLoyaltyPointProcessingConfigs(updatedConfigs);
    }
  };

  const deleteConfig = (configId: string) => {
    if (loyaltyPointProcessingConfigs) {
      const updatedConfigs = loyaltyPointProcessingConfigs.filter(
        (config) => config.id !== configId
      );
      handleConfigChaining(updatedConfigs);
    }
  };

  const handleConfigChaining = (
    configs: LoyaltyPointProcessingConfigResponse[]
  ) => {
    if (configs.length > 0) {
      const updatedConfigs = [...configs];
      updatedConfigs[0].isInitialHandler = true;
      for (let i = 0; i < updatedConfigs.length - 1; i++) {
        updatedConfigs[i].nextHandlerId = updatedConfigs[i + 1].id;
      }
      updatedConfigs[updatedConfigs.length - 1].nextHandlerId = null;
      setLoyaltyPointProcessingConfigs(updatedConfigs);
    } else {
      setLoyaltyPointProcessingConfigs([]);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (loyaltyPointProcessingConfigs && over) {
      const updatedConfigs = [...loyaltyPointProcessingConfigs];
      const activeIndex = loyaltyPointProcessingConfigs.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = loyaltyPointProcessingConfigs.findIndex(
        (item) => item.id === over.id
      );
      if (activeIndex !== -1 && overIndex !== -1) {
        const [removed] = updatedConfigs.splice(activeIndex, 1);
        updatedConfigs.splice(overIndex, 0, removed);

        // update "isInitialHandler" and "nextHandlerId" properties
        handleConfigChaining(updatedConfigs);
      }
    }
  };

  const submit = async () => {
    setIsSubmitting(true);
    const newConfig = loyaltyPointProcessingConfigs?.find(
      (x) => x.id === newLoyaltyPointProcessingConfigId
    );

    let currentConfigs = loyaltyPointProcessingConfigs;
    // first create new config and replace the temporarily set id with the one returned from the api
    if (newConfig) {
      await apiCreateConfig(newConfig)
        .then((config) => {
          if (loyaltyPointProcessingConfigs) {
            const actualId = (config as LoyaltyPointProcessingConfigResponse)
              .id;
            const update = [...loyaltyPointProcessingConfigs];
            const index = update.findIndex(
              (x) => x.nextHandlerId === newLoyaltyPointProcessingConfigId
            );
            if (index !== -1) {
              update[index] = {
                ...update[index],
                nextHandlerId: actualId,
              };
            }
            setLoyaltyPointProcessingConfigs(update);
            currentConfigs = update;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const deleteConfigs = originalLoyaltyPointProcessingConfigs?.filter(
      (x) => !currentConfigs?.find((y) => y.id === x.id)
    );
    const updateConfigs = currentConfigs?.filter((x) => {
      const og = originalLoyaltyPointProcessingConfigs?.find(
        (y) => y.id === x.id
      );
      return !!(og && JSON.stringify(x) !== JSON.stringify(og));
    });
    const nextHandlerIdChanged = currentConfigs?.filter((x) => {
      const og = originalLoyaltyPointProcessingConfigs?.find(
        (y) => y.id === x.id
      );
      return og?.nextHandlerId !== x.nextHandlerId && newConfig?.id !== x.id;
    });

    if (deleteConfigs && deleteConfigs.length > 0) {
      await Promise.all(deleteConfigs.map((x) => apiDeleteConfig(x)));
    }
    if (nextHandlerIdChanged && nextHandlerIdChanged.length > 0) {
      await Promise.all(
        nextHandlerIdChanged.map((x) =>
          apiUpdateConfig({
            ...x,
            nextHandlerId: null,
          })
        )
      );
    }
    if (updateConfigs && updateConfigs.length > 0) {
      await Promise.all(updateConfigs.map((x) => apiUpdateConfig(x)));
    }

    setIsSubmitting(false);
    pushNotification(t('notifications.update_success'));
    getLoyaltyPointProcessingConfigs();
  };

  const apiCreateConfig = (
    config: CreateLoyaltyPointProcessingConfigRequest
  ) => {
    return new Promise((resolve, reject) => {
      loyaltyPointProcessingConfigsApi
        .loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(config)
        .then((response) => {
          console.log('created config');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const apiUpdateConfig = (config: LoyaltyPointProcessingConfigResponse) => {
    return new Promise((resolve, reject) => {
      loyaltyPointProcessingConfigsApi
        .loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(
          config.id,
          config
        )
        .then((response) => {
          console.log('updated config');
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const apiDeleteConfig = (config: LoyaltyPointProcessingConfigResponse) => {
    return new Promise((resolve, reject) => {
      loyaltyPointProcessingConfigsApi
        .loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(
          config.id
        )
        .then((response) => {
          console.log('deleted config');
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          errorHandler.addError(error.response);
        });
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  if (loyaltyPointHandlerConfigs && loyaltyPointProcessingConfigs) {
    return (
      <div className={'loyaltyPointProcessingConfigs'}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={loyaltyPointProcessingConfigs}
            strategy={rectSortingStrategy}
          >
            <div className={'loyaltyPointProcessingConfigs-configs'}>
              {loyaltyPointProcessingConfigs.length > 0 ? (
                loyaltyPointProcessingConfigs.map((config, i) => {
                  return (
                    <div className={'loyaltyPointProcessingConfigs-batch'}>
                      <LoyaltyPointProcessingConfig
                        key={config.id}
                        updateConfig={updateConfig}
                        deleteConfig={() => deleteConfig(config.id)}
                        loyaltyPointHandlerConfigs={loyaltyPointHandlerConfigs}
                        config={{ ...config }}
                      />
                      <div
                        className={`loyaltyPointProcessingConfigs-addBetween ${
                          i === loyaltyPointProcessingConfigs.length - 1
                            ? 'loyaltyPointProcessingConfigs-addBetween__last'
                            : ''
                        }`}
                      >
                        {i === loyaltyPointProcessingConfigs.length - 1 ? (
                          <IconPlus
                            onClick={() => addConfig(i)}
                            className={
                              'loyaltyPointProcessingConfigs-addBetween-button'
                            }
                            fill={'var(--color-text_primary)'}
                          />
                        ) : (
                          <IconArrow
                            className={
                              'loyaltyPointProcessingConfigs-addBetween-icon'
                            }
                            fill={'var(--color-text_tertiary)'}
                          />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <EmptyState />
                  <div
                    className={
                      'loyaltyPointProcessingConfigs-configs-initialButton'
                    }
                  >
                    <Button
                      cta={t('actions.addInitial')}
                      look={'secondary'}
                      width={'minimal'}
                      action={() => addConfig()}
                    />
                  </div>
                </>
              )}
            </div>
          </SortableContext>
        </DndContext>
        <Popup
          toggled={handlerNameLegend}
          width={'30%'}
          close={() => setHandlerNameLegend(false)}
        >
          <div className={'popup-title'}>{t('handlerNameLegend.title')}</div>
          <InformationBox
            type={'info'}
            paragraphs={loyaltyPointHandlerConfigs.map(
              (x) =>
                `${x.handlerName?.split('.').pop()}: ${x.parameterDescription}`
            )}
          />
        </Popup>
        <div className={'global-cardActions global-cardActions-spaceBetween'}>
          <Button
            type={'icon'}
            look={'secondary'}
            width={'minimal'}
            action={() => setHandlerNameLegend(true)}
          >
            <IconHelp className={'button-icon-tiny'} />
          </Button>
          <Button
            cta={t('actions.save')}
            look={'save'}
            action={submit}
            isLoading={isSubmitting}
            active={
              JSON.stringify(originalLoyaltyPointProcessingConfigs) !==
              JSON.stringify(loyaltyPointProcessingConfigs)
            }
          />
        </div>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default LoyaltyPointProcessingConfigs;
