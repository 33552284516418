import _ from 'lodash';
import AdyenPaymentRefundStatusDictionary from '../../types/AdyenPaymentRefundStatusDictionary';

const useStatus = () => {
  const getSortedStatus = (status: AdyenPaymentRefundStatusDictionary) => {
    const arr = Object.entries(status);
    const sorted = _.sortBy(arr, ([key, value]) => key);
    return sorted.map((x) => x[1]);
  };

  const getLatestStatus = (status: AdyenPaymentRefundStatusDictionary) => {
    return getSortedStatus(status).at(-1);
  };

  return {
    getSortedStatus,
    getLatestStatus,
  };
};

export default useStatus;
