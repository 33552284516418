import { useTranslation } from 'react-i18next';

const useBulkEditorHints = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.headerHints',
  });

  const headerHints: { [key: string]: string } = {
    purchaseUnit: t('purchaseUnit'),
    referenceUnit: t('referenceUnit'),
    seoProductTitle: t('seoProductTitle'),
  };

  return {
    headerHints,
  };
};

export default useBulkEditorHints;
