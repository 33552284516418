import { TranslatedTypeResponseOfString } from '../../../api/petcloudapi/api';

export type SearchHeaderFunction = (
  lowerCasedQuery: string,
  value: any
) => boolean;

export type SearchHeaderFunctionDict = { [key: string]: SearchHeaderFunction };

const useSearchConfig = () => {
  const searchHeaderWhitelist: string[] = [
    'ean',
    'manufacturerProductNumber',
    'productLine',
    'purchaseUnit',
    'referenceUnit',
    'purchaseSteps',
    'minPurchase',
    'maxPurchase',
    'weight',
    'width',
    'height',
    'length',
    'restockTimeDays',
    'name',
    'seoProductTitle',
  ];

  const searchTranslatedContent = (
    query: string,
    content: TranslatedTypeResponseOfString
  ): boolean => {
    console.log(Object.values(content));
    return !!Object.values(content).find((value) =>
      value.toString().toLowerCase().includes(query)
    );
  };

  const searchHeaderFunctionDict: SearchHeaderFunctionDict = {
    name: searchTranslatedContent,
    seoProductTitle: searchTranslatedContent,
    productLine: searchTranslatedContent,
  };

  return {
    searchHeaderWhitelist,
    searchHeaderFunctionDict,
  };
};

export default useSearchConfig;
