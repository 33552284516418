import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../selectors/Selectors';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';

interface LanguageSwitcherProps {
  global?: boolean;
  onLanguageSwitch?: (lang: TranslatedStringIndex) => void;
  lang?: TranslatedStringIndex;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  global,
  onLanguageSwitch,
  lang,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(lang ?? i18n.language);

  const handleLang = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    var newLang = e.target.selectedOptions[0].getAttribute('data-value');
    e.preventDefault();

    if (newLang) {
      if (onLanguageSwitch) {
        onLanguageSwitch(newLang as TranslatedStringIndex);
      }
      if (global) {
        i18n.changeLanguage(newLang);
      }
      setSelectedLang(newLang);
    }
  };

  return (
    <div className={'global-inputGroup'}>
      <div className={'global-inputGroup-inputInline'}>
        <Dropdown
          title={global ? t('components.languageSwitcher.title') : undefined}
          options={['de-DE', 'en-GB']}
          selected={selectedLang}
          translationPath={'components.languageSwitcher.languages.'}
          update={(e) => {
            handleLang(e);
          }}
        />
      </div>
    </div>
  );
};
