import './documentlayout.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FooterColumnWidth,
  ManufacturerResponse,
  UpsertOrderReceiptDocumentLayoutDataRequest,
} from '../../../api/petcloudapi/api';
import Card, { CardSection } from '../../../elements/card/Card';
import Dropzone from '../../../elements/dropzone/Dropzone';
import Input from '../../../elements/input/Input';
import FormBuilder from '../../../elements/formbuilder/FormBuilder';
import Button from '../../../elements/button/Button';
import { ReactComponent as IconDoubleArrow } from '../../../../assets/icon/arrrow_double.svg';
import useNotifications from '../../../hooks/useNotifications';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { StageColumn, StageColumns } from '../../../layout/stage/Stage';
import DocumentPreview from './documentpreview/DocumentPreview';

const emptyRequest = (
  manufacturer: ManufacturerResponse
): UpsertOrderReceiptDocumentLayoutDataRequest => ({
  logo: null,
  logoMimeType: 'image/svg+xml',
  companyName: manufacturer.companyName,
  companySenderAddressLine: `${manufacturer.companyName} | ${manufacturer.companyAddress?.street} | ${manufacturer.companyAddress?.zipCode} ${manufacturer.companyAddress?.city}`,
  companyAddressStreet: manufacturer.companyAddress?.street,
  companyAddressZipCode: manufacturer.companyAddress?.zipCode,
  companyAddressCity: manufacturer.companyAddress?.city,
  companyAddressCountry: manufacturer.companyAddress?.country?.name['de-DE'],
  additionalContactLines: ['Tel:', 'Email:'],
  additionalBottomTextLines: [],
  footerColumnCount: 1,
  footerColumnsWidths: [
    {
      columnIndex: 1,
      width: 475,
    },
  ],
  footerContents: [
    {
      columnIndex: 1,
      content: ['Footer Text'],
    },
  ],
});

interface DocumentLayoutProps {
  manufacturer: ManufacturerResponse;
}

const DocumentLayout: React.FC<DocumentLayoutProps> = ({ manufacturer }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.documentLayout',
  });
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const footerRef = useRef<HTMLDivElement>(null);
  const [footer, setFooter] = useState({
    width: 0,
    clientX: 0,
  });
  const [columnToDrag, setColumnToDrag] = useState<{
    index: number;
    clientX: number;
  } | null>(null);

  const [layout, setLayout] =
    useState<UpsertOrderReceiptDocumentLayoutDataRequest | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLayout();
  }, []);

  useEffect(() => {
    if (footerRef.current) {
      setFooter({
        width: footerRef.current.clientWidth,
        clientX: footerRef.current.getBoundingClientRect().x,
      });
    }
  }, [footerRef, layout]);

  const getLayout = () => {
    manufacturersApi
      .manufacturersGetOrderReceiptLayoutData(manufacturer.id)
      .then((response) => {
        console.log(response);
        setLayout({ ...response.data });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          setLayout(emptyRequest(manufacturer));
        } else {
          errorHandler.addError(error.response);
        }
      });
  };

  const fixColumnWidths = (widths: FooterColumnWidth[] | null | undefined) => {
    if (widths && widths.length > 0) {
      const totalWidth = widths.reduce(
        (prev, next) => prev + (next.width ?? 0),
        0
      );
      if (totalWidth === 475) {
        return widths;
      } else {
        const fill = 475 - totalWidth;
        const lastIndex = widths.length - 1;
        widths[lastIndex].width = (widths[lastIndex].width ?? 0) + fill;
        return widths;
      }
    } else {
      return null;
    }
  };

  const submit = () => {
    if (layout) {
      if (!layout.logo) {
        pushNotification(t('notifications.logoMissing'), 'danger');
        return;
      }
      setIsLoading(true);
      manufacturersApi
        .manufacturersUpsertOrderReceiptLayoutData(manufacturer.id, {
          ...layout,
          footerColumnsWidths: fixColumnWidths(layout.footerColumnsWidths),
        })
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.update_successful'));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsLoading(false);
        });
    }
  };

  const handleLogoUpload = (files: File[]) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // The file's text will be printed here
      setLayout({ ...layout, logo: (reader.result as string).split(',')[1] });
    };

    if (files.length > 0) {
      const file = files[0];
      // check to ensure the file is SVG format
      if (file.type === 'image/svg+xml') {
        reader.readAsDataURL(file);
      } else {
        pushNotification(t('notifications.logoFile_invalid'), 'danger');
      }
    }
  };

  const handleColumnDrag = (e: MouseEvent) => {
    if (!layout || !columnToDrag || !layout.footerColumnsWidths) return;

    const { index } = columnToDrag;
    const footerColumnsWidths = [...layout.footerColumnsWidths];
    const currentWidth = footerColumnsWidths[index].width;
    const priorColumns = footerColumnsWidths.slice(0, index);
    const restColumns = [...layout.footerColumnsWidths];
    restColumns.splice(index, 1);

    const calculateTakenWidth = (columns: typeof restColumns) =>
      columns.reduce((prev, next) => prev + (next.width ?? 0), 0);

    const takenWidth = calculateTakenWidth(restColumns);
    const priorWidth = calculateTakenWidth(priorColumns);
    const priorWidthPx = footer.width * (priorWidth / 475);

    if (!currentWidth) return;

    const width =
      ((e.clientX - footer.clientX - priorWidthPx) / footer.width) * 475;

    if (width <= 0) return;

    const overflow = takenWidth + width - 475;
    const nextColumn = footerColumnsWidths[index + 1];
    const nextColumnWidth = nextColumn
      ? Math.floor(nextColumn.width! - overflow)
      : undefined;
    let nextColumnIsOkaySize = true;

    if (nextColumn && nextColumnWidth && nextColumnWidth <= 20) {
      console.log('next column too small!');
      nextColumnIsOkaySize = false;
    }

    if (overflow > 0 && !nextColumn) {
      console.log('column too big!');
      return;
    }

    if (nextColumnIsOkaySize) {
      footerColumnsWidths[index] = {
        ...footerColumnsWidths[index],
        width: Math.floor(width),
      };

      if (nextColumn) {
        footerColumnsWidths[index + 1] = {
          ...nextColumn,
          width: nextColumnWidth,
        };
      }
    }

    setLayout({ ...layout, footerColumnsWidths });
  };

  const handleColumnDragEnd = useCallback(() => {
    setColumnToDrag(null);
  }, [columnToDrag]);

  useEffect(() => {
    document.addEventListener('mousemove', handleColumnDrag, false);
    document.addEventListener('mouseup', handleColumnDragEnd, false);
    return () => {
      document.removeEventListener('mousemove', handleColumnDrag, false);
      document.removeEventListener('mouseup', handleColumnDragEnd, false);
    };
  }, [handleColumnDrag, handleColumnDragEnd]);

  const addFooterColumn = () => {
    const update = { ...layout };
    const newColumnCount = (update.footerColumnCount ?? 0) + 1;
    const lastIndex = update.footerColumnsWidths
      ? update.footerColumnsWidths?.length - 1
      : 0;

    if (update.footerColumnsWidths && newColumnCount > 1 && lastIndex >= 0) {
      update.footerColumnsWidths[lastIndex] = {
        ...update.footerColumnsWidths[lastIndex],
        width: update.footerColumnsWidths[lastIndex].width! - 40,
      };
      update.footerColumnsWidths.push({
        columnIndex: newColumnCount,
        width: 40,
      });
    } else {
      update.footerColumnsWidths = [{ columnIndex: 0, width: 475 }];
    }

    const newFooterContent = [
      {
        columnIndex: newColumnCount,
        content: ['Text'],
      },
    ];
    update.footerContents = update.footerContents
      ? [...update.footerContents, ...newFooterContent]
      : newFooterContent;

    update.footerColumnCount = newColumnCount;
    setLayout(update);
  };

  const deleteFooterColumn = () => {
    const update = { ...layout };
    update.footerColumnCount = (update.footerColumnCount ?? 0) - 1;
    update.footerContents?.pop();
    update.footerColumnsWidths?.pop();
    if (update.footerColumnsWidths && update.footerColumnsWidths.length > 0) {
      update.footerColumnsWidths = fixColumnWidths(update.footerColumnsWidths);
    }
    setLayout(update);
  };

  const renderFooterColumns = () => {
    if (layout && layout.footerColumnsWidths && layout.footerColumnCount) {
      const nodes = [];
      for (let i = 1; i <= layout.footerColumnCount; i++) {
        const footerColumnWidth = layout.footerColumnsWidths[i - 1].width;
        nodes.push(
          <div
            key={i}
            className={'documentLayout-footer-column'}
            style={{
              width: `${((footerColumnWidth ?? 0) / 475) * 100}%`,
            }}
          >
            <FormBuilder
              contentObject={layout}
              setContentObject={setLayout}
              elements={[
                {
                  type: 'inputArray',
                  objectPath: `footerContents[${i - 1}].content`,
                  helperCSSClass: 'documentLayout-input',
                },
              ]}
            />
            {i < layout.footerColumnCount ? (
              <div
                className={'documentLayout-footer-column-dragHandle'}
                onMouseDown={(e) => {
                  setColumnToDrag({
                    index: i - 1,
                    clientX: e.clientX,
                  });
                }}
              >
                <IconDoubleArrow
                  className={'documentLayout-footer-column-dragHandle-icon'}
                />
              </div>
            ) : null}
          </div>
        );
      }
      return nodes;
    } else {
      return null;
    }
  };

  return (
    <StageColumns>
      <StageColumn size={'two'}>
        <Card bigScreenWidth={'100%'}>
          {layout ? (
            <CardSection>
              <div className="documentLayout">
                <div className="documentLayout-logo">
                  {layout.logo ? (
                    <div className="documentLayout-logo-container">
                      <img
                        src={`data:${layout.logoMimeType};base64,${layout.logo}`}
                        alt={'Company Logo'}
                        className="documentLayout-logo-img"
                      />
                      <div className={'documentLayout-logo-container-button'}>
                        <Button
                          width={'tiny'}
                          look={'secondary-danger'}
                          cta={t('remove_logo')}
                          action={() => {
                            setLayout({ ...layout, logo: null });
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="documentLayout-logo-dropzone">
                      <Dropzone
                        maxFiles={1}
                        callbackWithoutUpload={handleLogoUpload}
                        height={150}
                      />
                    </div>
                  )}
                </div>
                <div className={'documentLayout-section'}>
                  <div className={'documentLayout-section-2'}>
                    <Input
                      content={layout.companySenderAddressLine}
                      update={(v) => {
                        setLayout({
                          ...layout,
                          companySenderAddressLine: v,
                        });
                      }}
                      helperCSSClass={'documentLayout-input'}
                      maxWidth={400}
                    />
                    <div className={'documentLayout-dummyCustomer'}>
                      <div>Herr</div>
                      <div>Max Mustermann</div>
                      <div>Musterstraße 101</div>
                      <div>12345 Musterstadt</div>
                    </div>
                  </div>
                  <div className={'documentLayout-section-1'}>
                    <FormBuilder
                      contentObject={layout}
                      setContentObject={setLayout}
                      elements={[
                        {
                          type: 'input',
                          objectPath: 'companyName',
                          helperCSSClass: 'documentLayout-input',
                        },
                        {
                          type: 'input',
                          objectPath: 'companyAddressStreet',
                          helperCSSClass: 'documentLayout-input',
                        },
                        {
                          type: 'group',
                          children: [
                            {
                              type: 'input',
                              objectPath: 'companyAddressZipCode',
                              helperCSSClass: 'documentLayout-input',
                            },
                            {
                              type: 'input',
                              objectPath: 'companyAddressCity',
                              helperCSSClass: 'documentLayout-input',
                              size: 2,
                            },
                          ],
                        },
                        {
                          type: 'inputArray',
                          objectPath: 'additionalContactLines',
                          helperCSSClass: 'documentLayout-input',
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className={'documentLayout-section'}>
                  <div className={'documentLayout-section-2'}></div>
                  <div
                    className={
                      'documentLayout-section-1 documentLayout-dummyHeader'
                    }
                  >
                    <div>Rechnungs-Nr.: TEST-R-1042</div>
                    <div>Lieferdatum: 21.06.2024</div>
                    <div>Rechnungsdatum: 21.06.2024</div>
                    <div>Kunden-Nr.: 12345</div>
                    <div>Bestell-Nr.: 11234</div>
                    <div>Seite 1 von 1</div>
                  </div>
                </div>
                <div className={'documentLayout-section'}>
                  <div className={'documentLayout-title'}>
                    Rechnung TEST-R-1042
                    <div className={'documentLayout-dummyCustomer-small'}>
                      <strong>Lieferadresse:</strong> Herr Max Mustermann |
                      Musterstraße 33 | 12345 Musterstadt
                    </div>
                  </div>
                </div>
                <div className={'documentLayout-placeholder'}></div>
                <div className={'documentLayout-placeholder'}></div>
                <div className={'documentLayout-placeholder'}></div>
                <div className={'documentLayout-section'}>
                  <div className={'documentLayout-section-2'}></div>
                  <div className={'documentLayout-section-1'}>
                    <div className={'documentLayout-placeholder'}></div>
                    <div className={'documentLayout-placeholder'}></div>
                    <div className={'documentLayout-placeholder'}></div>
                  </div>
                </div>
                <div className={'documentLayout-bottomTextLines'}>
                  <div>Zahlungsbedingung: Bezahlt über inpetto.com</div>
                  <div>Zahlungsart: Kreditkarte</div>
                </div>
                <div className={'documentLayout-additionalBottomTextLines'}>
                  <FormBuilder
                    contentObject={layout}
                    setContentObject={setLayout}
                    elements={[
                      {
                        type: 'inputArray',
                        objectPath: 'additionalBottomTextLines',
                        helperCSSClass: 'documentLayout-input',
                      },
                    ]}
                  />
                </div>
                <div ref={footerRef} className={'documentLayout-footer'}>
                  <div className={'documentLayout-footer-columns'}>
                    {renderFooterColumns()}
                  </div>
                  <div className={'documentLayout-footer-actions'}>
                    <Button
                      cta={t('column_add')}
                      width={'tiny'}
                      look={'secondary'}
                      action={addFooterColumn}
                      active={(layout.footerColumnCount ?? 0) < 4}
                    />
                    {layout.footerColumnCount &&
                    layout.footerColumnCount > 0 ? (
                      <Button
                        cta={t('column_remove')}
                        width={'tiny'}
                        look={'secondary-danger'}
                        action={deleteFooterColumn}
                        active={(layout.footerColumnCount ?? 0) > 1}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={'global-cardActions-margin'}>
                <Button
                  cta={t('save')}
                  width={'minimal'}
                  look={'save'}
                  action={submit}
                  isLoading={isLoading}
                />
              </div>
            </CardSection>
          ) : (
            <LoadingContainer />
          )}
        </Card>
      </StageColumn>
      <StageColumn size={'one'}>
        <Card bigScreenWidth={'100%'}>
          <CardSection title={t('documentPreview.title')}>
            <DocumentPreview manufacturerId={manufacturer.id} />
          </CardSection>
        </Card>
      </StageColumn>
    </StageColumns>
  );
};

export default DocumentLayout;
