import './animalspecieseditor.css';
import { AnimalSpeciesResponse } from '../../../../api/petcloudapi/api';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import { default as SpeciesEditor } from '../../../../sections/admin/animalspecies/animalspecieseditor/AnimalSpeciesEditor';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface AnimalSpeciesEditorProps {
  availableAnimalSpecies: AnimalSpeciesResponse[];
  animalSpecies: AnimalSpeciesResponse[] | null;
  updateCallback: EditorUpdateCallback;
}

const AnimalSpeciesEditor: React.FC<AnimalSpeciesEditorProps> = ({
  availableAnimalSpecies,
  animalSpecies,
  updateCallback,
}) => {
  const { t } = useTranslation();
  const [species, setSpecies] = useState(animalSpecies);

  return (
    <div className={'jtce-animalSpecies'}>
      <div className={'jtce-title'}>
        {t('components.animalSpeciesArray.animalSpecies')}
      </div>
      <SpeciesEditor
        availableAnimalSpecies={availableAnimalSpecies}
        animalSpecies={species}
        onSubmit={(s) => {
          const update = species ? [...species] : [];
          update.push(s);
          setSpecies(update);
        }}
        onDelete={(index) => {
          if (species) {
            const update = [...species];
            update.splice(index, 1);
            setSpecies(update);
          }
        }}
      />
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: species }]);
            } catch {}
          }}
        />
      ) : null}
    </div>
  );
};

export default AnimalSpeciesEditor;
