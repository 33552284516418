import './menulist.css';
import { ReactNode } from 'react';
import Button, { ButtonProps } from '../button/Button';
import { EmptyState } from '../emptystate/EmptyState';
import { Check } from '../selectors/Selectors';

export type MenuListItem = {
  title: string;
  actions?: ButtonProps[];
  toggled?: boolean;
  onToggle?: () => void;
  renderMethod?: () => ReactNode;
};

export type MenuListItemAction = {
  cta?: string;
  icon?: ReactNode;
  action: (id: string) => void;
};

interface MenuListProps {
  items: MenuListItem[];
  addButton?: ButtonProps;
}

const MenuList: React.FC<MenuListProps> = ({ items, addButton }) => {
  return (
    <div className="menuList">
      {items.length > 0 ? (
        items.map((item, index) => {
          const { title, toggled, onToggle, renderMethod } = item;
          return (
            <div className={'menuList-item'} key={index}>
              {onToggle && toggled !== undefined ? (
                <div className={'menuList-item-checkbox'}>
                  <Check checked={toggled} update={onToggle} />
                </div>
              ) : null}
              {renderMethod ? (
                renderMethod()
              ) : (
                <div className={'menuList-item-title'}>{title}</div>
              )}
              <div className={'menuList-item-actions'}>
                {item.actions?.map((action, index) => (
                  <Button {...action} key={index} />
                ))}
              </div>
            </div>
          );
        })
      ) : (
        <EmptyState small />
      )}
      {addButton ? (
        <div className={'menuList-addButton'}>
          <Button {...addButton} />
        </div>
      ) : null}
    </div>
  );
};

export default MenuList;
