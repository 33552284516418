import './reference.css';
import { OrderResponse } from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { SmallHint } from '../../../elements/hint/Hint';

interface ReferenceProps {
  order: OrderResponse;
}

const Reference: React.FC<ReferenceProps> = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div className={'reference'}>
      {order.originOrderId ? (
        <div className={'reference-element'}>
          <div className={'reference-element-title'}>
            {t('view.order.reference.originOrderNumber')}
            <SmallHint
              paragraphs={[t('view.order.reference.originOrderNumber_hint')]}
              stretchToLeft={true}
            />
          </div>
          <div
            className={
              'list-table-td-monoSpaced reference-monospaced reference-linked'
            }
          >
            {
              order.splitOrders?.find((s) => s.orderId === order.originOrderId)
                ?.orderNumber
            }
          </div>
        </div>
      ) : null}
      {order.splitOrders ? (
        <div className={'reference-element'}>
          <div className={'reference-element-title'}>
            {t('view.order.reference.splitOrders')}
            <SmallHint
              paragraphs={[t('view.order.reference.splitOrders_hint')]}
              stretchToLeft={true}
            />
          </div>
          {order.splitOrders.map((splitOrder) => {
            if (splitOrder.orderNumber !== order.orderNumber) {
              return (
                <div
                  className={
                    'list-table-td-monoSpaced reference-monospaced reference-linked'
                  }
                >
                  {splitOrder.orderNumber}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      ) : null}
      {order.fiegeSalesOrderNumber ? (
        <div className={'reference-element'}>
          <div className={'reference-element-title'}>
            {t('view.order.reference.fiegeSalesOrderNumber')}
          </div>
          <div className={'list-table-td-monoSpaced reference-monospaced'}>
            {order.fiegeSalesOrderNumber}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Reference;
