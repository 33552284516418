const useStoreHost = () => {
  if (process.env.REACT_APP_BUILD_ENV === 'production') {
    return `${window.location.protocol}//inpetto.com`;
  } else if (process.env.REACT_APP_BUILD_ENV === 'staging') {
    return `${window.location.protocol}//store.staging.inpetto.com`;
  } else {
    return `${window.location.protocol}//store.test.pet.cloud`;
  }
};

export default useStoreHost;
