import './pill.css';
import { ReactComponent as IconRemove } from '../../../assets/icon/close.svg';

interface PillProps {
  value: string;
  onRemove: () => void;
}

const Pill: React.FC<PillProps> = ({ value, onRemove }) => {
  return (
    <div className={'pill'}>
      {value}
      {onRemove ? (
        <IconRemove className={'pill-remove'} onClick={onRemove} />
      ) : null}
    </div>
  );
};

export default Pill;
