import './stocks.css';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import { Has } from '../../../contexts/auth/Authorization';
import Button from '../../../elements/button/Button';
import Input from '../../../elements/input/Input';
import UniversalProductStock from '../../../types/UniversalProductStock';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import { Store } from 'react-notifications-component';

interface StockProps {
  stock: UniversalProductStock;
  isNewStock: boolean;
  warehouses: DropdownOption[];
  unusedWarehouseOptions?: DropdownOption[];
  update: (stock: UniversalProductStock, updatesNew: boolean) => void;
  deleteStock?: (id: string) => void;
  totalStocksLength: number;
}

const Stock: React.FC<StockProps> = ({
  stock,
  isNewStock,
  warehouses,
  unusedWarehouseOptions,
  update,
  deleteStock,
  totalStocksLength,
}) => {
  const { t } = useTranslation();

  const getPriorityOptions = () => {
    console.log(totalStocksLength);
    const o = [];
    for (let i = 1; i <= totalStocksLength; i++) {
      o.push(i.toString());
    }
    return o;
  };

  return (
    <div className="stock">
      <div className="global-inputGroup">
        {warehouses ? (
          <div className="global-inputGroup-inputInline">
            <Dropdown
              title={t('view.product.stocks.warehouse')}
              optionObjects={warehouses}
              selected={
                warehouses.find((w) => w.id === stock.warehouseId)?.name
              }
              update={(e) => {
                const id =
                  e.target.selectedOptions[0].getAttribute('data-value');
                const warehouse = warehouses.find((w) => w.id === id);
                if (id && warehouse) {
                  if (unusedWarehouseOptions?.find((w) => w.id === id)) {
                    update(
                      {
                        ...stock,
                        warehouseId: id,
                      },
                      isNewStock
                    );
                  } else {
                    Store.addNotification({
                      message: t(
                        'view.product.stocks.notifications.warehouseAlreadyInUse'
                      ),
                      type: 'warning',
                      insert: 'top',
                      container: 'top-right',
                      animationIn: ['animate__animated', 'animate__fadeIn'],
                      animationOut: ['animate__animated', 'animate__fadeOut'],
                      dismiss: {
                        duration: 3000,
                      },
                    });
                  }
                }
              }}
              required={true}
              disabled={!isNewStock}
            />
          </div>
        ) : null}
        <div className="global-inputGroup-inputInline">
          <Dropdown
            title={t('view.product.stocks.priority')}
            hint={{
              paragraphs: [t('view.product.stocks.priorityDisclaimer')],
            }}
            selected={stock.priority.toString()}
            update={(e) => {
              const newPrio = e.currentTarget.selectedOptions[0].value;
              if (newPrio !== null) {
                update(
                  {
                    ...stock,
                    priority: parseInt(newPrio),
                  },
                  isNewStock
                );
              }
            }}
            options={getPriorityOptions()}
          />
        </div>
        {stock.warehouse?.type !== 'CentralWarehouse' ? (
          <Has
            authorizations={['product_stocks:delete']}
            isBypassed={isNewStock}
          >
            <div className="global-inputGroup-input global-inputGroup-input-noTitle">
              <div className={'stock-deleteButton'}>
                <Button
                  key={stock.id}
                  type="icon"
                  look="secondary-danger"
                  action={
                    deleteStock
                      ? () => {
                          console.log(stock.id);
                          deleteStock(stock.id);
                        }
                      : undefined
                  }
                >
                  <Trash className="button-icon button-icon-danger" />
                </Button>
              </div>
            </div>
          </Has>
        ) : null}
      </div>
      <div className="stock-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('view.product.stocks.stock')}
            content={stock.stock.toString()}
            update={
              stock.warehouse?.type !== 'CentralWarehouse'
                ? (e) => {
                    update(
                      {
                        ...stock,
                        stock: parseFloat(e),
                      },
                      isNewStock
                    );
                  }
                : undefined
            }
            type="number"
          />
        </div>
      </div>
      {stock.warehouse?.type === 'CentralWarehouse' ? (
        <div className="stock-disclaimer">
          {t('view.product.stocks.centralWarehouseDisclaimer')}
        </div>
      ) : null}
    </div>
  );
};

export default Stock;

// <div className="global-inputGroup-input global-inputGroup-input-noTitle">
//   <ToggleSwitch
//     toggled={stock.available}
//     toggle={() => {
//       update(
//         {
//           ...stock,
//           available: !stock.available,
//         },
//         isNewStock
//       );
//     }}
//     label={t('view.product.stocks.availability')}
//   />
// </div>
