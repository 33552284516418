import './payment.css';
import { useTranslation } from 'react-i18next';
import {
  OrderPriceResponse,
  OrderShippingCostsResponse,
} from '../../../api/petcloudapi/api';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import usePrice from '../../../hooks/usePrice';
import PaymentMethod from './paymentmethod/PaymentMethod';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';

interface PaymentProps {
  price: OrderPriceResponse;
  shippingCosts: OrderShippingCostsResponse;
  commission?: number;
  currencySymbol?: string;
  initialPaymentMethodBrand?: string | null;
}

const Payment: React.FC<PaymentProps> = ({
  price,
  shippingCosts,
  commission,
  currencySymbol,
  initialPaymentMethodBrand,
}) => {
  const { t } = useTranslation();
  const renderPrice = usePrice();

  if (
    price.netPrice !== undefined &&
    price.netPrice !== null &&
    price.positionPrice !== undefined &&
    price.positionPrice !== null &&
    price.totalPrice !== undefined &&
    price.totalPrice !== null &&
    shippingCosts.totalPrice !== undefined &&
    shippingCosts.totalPrice !== null
  ) {
    const getEntries = () => {
      const entries = [
        {
          title: t('view.order.payment.positionPrice'),
          value: renderPrice(price.positionPrice, currencySymbol),
        },
        {
          title: t('view.order.payment.shipping'),
          value: renderPrice(shippingCosts.totalPrice, currencySymbol),
        },
        {
          title: t('view.order.payment.netPrice'),
          value: renderPrice(price.netPrice, currencySymbol),
        },
      ];

      price.calculatedTaxes?.forEach((tax) => {
        entries.push({
          title: `${t('view.order.payment.tax')} ${tax.taxRate}%`,
          value: renderPrice(tax.tax, currencySymbol),
        });
      });
      return entries;
    };

    return (
      <div className="payment">
        <div className="payment-section payment-section-method">
          {t('view.order.payment.method.title')}
          <PaymentMethod
            initialPaymentMethodBrand={initialPaymentMethodBrand}
          />
        </div>
        <div className={'payment-section'}>
          <TitleValueList entries={getEntries()} spaceBetween />
        </div>
        <div className={'payment-section'}>
          <TitleValueList
            entries={[
              {
                title: t('view.order.payment.total'),
                value: renderPrice(price.totalPrice, currencySymbol),
                asHighlight: true,
              },
              {
                title: t('view.order.payment.commission'),
                value: `-${renderPrice(commission ?? 0, currencySymbol)}`,
                asHighlight: true,
                highlightColor: 'var(--color-primary)',
              },
              {
                title: t('view.order.payment.commission_subtracted'),
                value: renderPrice(
                  price.positionPrice +
                    shippingCosts.totalPrice -
                    (commission ?? 0),
                  currencySymbol
                ),
                asHighlight: true,
                highlightColor: 'var(--color-success)',
              },
            ]}
            spaceBetween
            verticalSpacing={'normal'}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="payment">
        <EmptyState message={t('view.order.payment.error')} />
      </div>
    );
  }
};

export default Payment;
