import './hint.css';
import { CSSProperties, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconHint } from '../../../assets/icon/hint.svg';
import { ReactComponent as IconQuestion } from '../../../assets/icon/question.svg';
import Hoverable from '../hoverable/Hoverable';

// interface WithHintProps {
//   message: string;
//   isToolTip?: boolean;
// }
//
// export const WithHint: React.FC<WithHintProps> = ({ message, isToolTip }) => {
//   const [showHint, setShowHint] = useState(false);
//
//   return (
//     <div className="hint-wrapper">
//       <div
//         className="hint"
//         onMouseEnter={() => setShowHint(true)}
//         onMouseLeave={() => setShowHint(false)}
//       >
//         <IconHint className="hint-icon" fill="var(--color-text_secondary)" />
//         <div className="hint-label">{t('hints.label')}</div>
//         <HintBox show={showHint} className="hint-hintBox">
//           <div className="hint-hintBox-text">{t(msgPath)}</div>
//           <div className="hint-hintBox-more">{t('hints.more')}</div>
//         </HintBox>
//       </div>
//     </div>
//   );
// };

interface HintProps {
  knowledgebaseLink: string;
  msgPath: string;
}

const Hint: React.FC<HintProps> = ({ knowledgebaseLink, msgPath }) => {
  const { t } = useTranslation();

  const [showHint, setShowHint] = useState(false);

  return (
    <a
      href={knowledgebaseLink}
      target="_blank"
      rel="noreferrer"
      className="hint-link"
    >
      <div className="hint-wrapper">
        <div
          className="hint"
          onMouseEnter={() => setShowHint(true)}
          onMouseLeave={() => setShowHint(false)}
        >
          <IconHint className="hint-icon" fill="var(--color-text_secondary)" />
          <div className="hint-label">{t('hints.label')}</div>
          <div
            style={{ display: showHint ? 'block' : 'none' }}
            className="hint-hintBox"
          >
            <div className="hint-hintBox-text">{t(msgPath)}</div>
            <div className="hint-hintBox-more">{t('hints.more')}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Hint;

interface SmallHintProps {
  paragraphs?: (string | React.ReactNode)[];
  title?: string;
  icon?: JSX.Element;
  stretchToLeft?: boolean;
  helperClass?: string;
  style?: CSSProperties;
  isToolTip?: boolean;
  delay?: boolean;
  disabled?: boolean;
}

export const SmallHint: React.FC<SmallHintProps> = ({
  paragraphs,
  title,
  icon,
  stretchToLeft,
  helperClass,
  style,
  isToolTip,
  delay,
  disabled,
  children,
}) => {
  return (
    <Hoverable
      onHoverNode={
        !disabled ? (
          <HintBox paragraphs={paragraphs} isToolTip={isToolTip} />
        ) : undefined
      }
      stretchToLeft={stretchToLeft}
      delay={delay}
      style={style}
      helperClass={helperClass}
    >
      <div className={'hint-small'}>
        {icon ? (
          icon
        ) : title ? (
          <div className="hint-small-bubble">{title}</div>
        ) : children ? (
          <div className={!disabled ? 'hint-small-children' : ''}>
            {children}
          </div>
        ) : (
          <IconQuestion className="hint-small-icon" fill="var(--color-blue)" />
        )}
      </div>
    </Hoverable>
  );
};

interface HintBoxProps {
  paragraphs?: (string | ReactNode)[];
  isToolTip?: boolean;
}

export const HintBox: React.FC<HintBoxProps> = ({ paragraphs, isToolTip }) => {
  if (paragraphs) {
    return (
      <div
        className={`hint-small-message ${
          isToolTip ? 'hint-small-message-toolTip' : ''
        }`}
      >
        {paragraphs.map((p, i) => {
          return (
            <div key={i} className={'hint-small-message-paragraph'}>
              {p}
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
