import './documentpreview.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import { useState } from 'react';
import Button from '../../../../elements/button/Button';
import useFileDownload from '../../../../hooks/useFileDownload';
import { DocumentType } from '../../../../api/petcloudapi/api';

interface DocumentPreviewProps {
  manufacturerId: string;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.documentLayout.documentPreview',
  });
  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();
  const { downloadFromBlob } = useFileDownload();

  const [documentType, setDocumentType] = useState<DocumentType | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const documentTypeOptions = ['OrderInvoice', 'OrderCreditNote'];

  const download = () => {
    if (documentType) {
      setIsDownloading(true);
      manufacturersApi
        .manufacturersPreviewOrderReceiptLayoutData(
          manufacturerId,
          documentType,
          undefined,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          console.log(response);
          downloadFromBlob(response);
          setIsDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsDownloading(false);
        });
    }
  };

  return (
    <div className="documentPreview">
      <Dropdown
        title={t('documentType')}
        options={documentTypeOptions}
        selected={documentType}
        update={(e) => {
          setDocumentType(
            e.currentTarget.selectedOptions[0].value as DocumentType
          );
        }}
      />
      <div className="documentPreview-button">
        <Button
          cta={t('cta')}
          width={'full'}
          look={'secondary'}
          action={download}
          isLoading={isDownloading}
          active={!!documentType}
        />
      </div>
    </div>
  );
};

export default DocumentPreview;
