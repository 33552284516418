import { RequiredFieldValidationMap } from '../tablecompletionbar/TableCompletionBar';

const useValidation = (
  requiredFieldValidationMap?: RequiredFieldValidationMap
) => {
  const isCompleteValue = (item: any, field: string) => {
    const v = item[field];
    if (v !== undefined && v !== null && v !== '') {
      const valFunc = requiredFieldValidationMap?.[field];
      if (valFunc) {
        return valFunc(v);
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return {
    isCompleteValue,
  };
};

export default useValidation;
