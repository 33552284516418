import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  CreateDeliveryTimeRequest,
  DeliveryTimeResponse,
  DeliveryTimeType,
  DeliveryTimeUnit,
  InlineCreateDeliveryTimeRequest,
} from '../../../api/petcloudapi/api';
import { useAuthorization } from '../../../contexts/auth/Authorization';
import Button from '../../../elements/button/Button';
import { CardTitle } from '../../../elements/card/Card';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import Input from '../../../elements/input/Input';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { Dropdown } from '../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import './shipping.css';
import usePersistInCookies from '../../../hooks/useStorageData';

interface ShippingProps {
  productId?: string;
  productVersionId?: string;
  callback?: (times: InlineCreateDeliveryTimeRequest[]) => void;
  readonly?: boolean;
  currentDeliveryTimes?: DeliveryTimeResponse[];
}

export const shippingTypeOptions = ['Standard', 'Express', 'SelfPickup'];

const Shipping: React.FC<ShippingProps> = ({
  productId,
  productVersionId,
  callback,
  readonly,
  currentDeliveryTimes,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const deliveryTimesApi = api.deliveryTimesApi();
  const productStocksApi = api.productStocksApi();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [deliveryTimes, setDeliveryTimes] = useState<
    DeliveryTimeResponse[] | null
  >(null);
  const [originalDeliveryTimes, setOriginalDeliveryTimes] = useState<
    DeliveryTimeResponse[] | null
  >(null);
  const [newDeliveryTimes, setNewDeliveryTimes] = useState<
    CreateDeliveryTimeRequest[]
  >([]);
  const [newInlineDeliveryTimes, setNewInlineDeliveryTimes] = useState<
    InlineCreateDeliveryTimeRequest[]
  >([]);
  const [enabled, setEnabled] = useState(false);

  const { authorizations: userAuthorizations } = useAuthorization();
  const { persistObject, getPersistedObject } = usePersistInCookies();

  console.log('Shipping has rendered');

  useEffect(() => {
    getDeliveryTimes();
    checkIfEnabled();
  }, []);

  const checkIfEnabled = () => {
    if (productId) {
      productStocksApi
        .productStocksGetProductStocks(undefined, productId)
        .then((response) => {
          console.log(response);
          const stocks = response.data;
          if (
            stocks.find(
              (stock) => stock.warehouse.type === 'ManufacturerWarehouse'
            )
          ) {
            setEnabled(true);
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setEnabled(true);
    }
  };

  const getDeliveryTimes = () => {
    if (productId) {
      deliveryTimesApi
        .deliveryTimesGetDeliveryTimes(productId, productVersionId)
        .then((response) => {
          console.log(response);
          setDeliveryTimes(response.data);
          setOriginalDeliveryTimes(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      const persisted = getPersistedObject('new_product_shipping');
      setNewInlineDeliveryTimes(persisted ?? []);
      setDeliveryTimes(currentDeliveryTimes ?? []);
      setOriginalDeliveryTimes([]);
    }
  };

  const updateDeliveryTimes = (deliveryTimes: DeliveryTimeResponse[]) => {
    setDeliveryTimes([...deliveryTimes]);
  };

  const addDeliveryTime = () => {
    console.log('adding...');
    if (productId) {
      const newDeliveryTime = {
        min: 1,
        max: 3,
        unit: 'Days' as DeliveryTimeUnit,
        type: 'Standard' as DeliveryTimeType,
        productId: productId,
      };
      updateNewDeliveryTimes([...newDeliveryTimes, newDeliveryTime]);
    } else {
      const newDeliveryTime = {
        min: 1,
        max: 3,
        unit: 'Days' as DeliveryTimeUnit,
        type: 'Standard' as DeliveryTimeType,
        productId: null,
      };
      updateNewInlineDeliveryTimes([
        ...newInlineDeliveryTimes,
        newDeliveryTime,
      ]);
    }
  };

  const updateNewDeliveryTimes = (
    deliveryTimes: CreateDeliveryTimeRequest[]
  ) => {
    setNewDeliveryTimes([...deliveryTimes]);
  };

  const updateNewInlineDeliveryTimes = (
    deliveryTimes: InlineCreateDeliveryTimeRequest[]
  ) => {
    console.log(deliveryTimes);
    setNewInlineDeliveryTimes([...deliveryTimes]);
    if (callback) {
      callback([...deliveryTimes]);
      persistObject([...deliveryTimes], 'new_product_shipping');
    }
  };

  const submitNewDeliveryTimes = () => {
    setIsSubmitting(true);
    if (newDeliveryTimes.length > 0) {
      newDeliveryTimes.forEach((deliveryTime) => {
        deliveryTimesApi
          .deliveryTimesCreateDeliveryTime(deliveryTime)
          .then((response) => {
            console.log(response);
            setNewDeliveryTimes([]);
            setIsSubmitting(false);
            Store.addNotification({
              message: t(
                'view.product.notifications.deliveryTimeSubmit_successful'
              ),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
              },
            });
            getDeliveryTimes();
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
          });
      });
    }
  };

  const deleteDeliveryTime = (deliveryTimeId: string) => {
    deliveryTimesApi
      .deliveryTimesDeleteDeliveryTime(deliveryTimeId)
      .then((response) => {
        console.log(response);
        getDeliveryTimes();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitUpdatedDeliveryTimes = (
    deliveryTimesArray: DeliveryTimeResponse[] | null
  ) => {
    if (deliveryTimesArray) {
      let counter = 0;
      deliveryTimesArray.forEach((deliveryTime) => {
        setIsSubmitting(true);
        deliveryTimesApi
          .deliveryTimesUpdateDeliveryTime(deliveryTime.id, deliveryTime)
          .then((response) => {
            console.log(response);
            counter += 1;
            if (counter === deliveryTimesArray.length) {
              console.log(response);
              Store.addNotification({
                message: t(
                  'view.product.notifications.deliveryTimeUpdate_successful'
                ),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 5000,
                },
              });
              getDeliveryTimes();
              setIsSubmitting(false);
            }
          })
          .catch((error) => {
            console.log(error);
            errorHandler.addError(error.response);
          });
      });
    }
  };

  const update = (
    updatedTimes:
      | DeliveryTimeResponse[]
      | InlineCreateDeliveryTimeRequest[]
      | CreateDeliveryTimeRequest[],
    updatesNew: boolean
  ) => {
    if (updatesNew) {
      if (productId) {
        updateNewDeliveryTimes(updatedTimes as CreateDeliveryTimeRequest[]);
      } else {
        updateNewInlineDeliveryTimes(
          updatedTimes as InlineCreateDeliveryTimeRequest[]
        );
      }
    } else {
      updateDeliveryTimes(updatedTimes as DeliveryTimeResponse[]);
    }
  };

  return (
    <div className="shipping">
      <CardTitle title={t('view.product.shipping.title')} hasBottomMargin />
      {enabled ? (
        <>
          {deliveryTimes ? (
            deliveryTimes.map((deliveryTime, i) => {
              return (
                <DeliveryTime
                  key={`deliveryTime_${i}`}
                  deliveryTime={deliveryTime}
                  update={(deliveryTime) => {
                    const u = [...deliveryTimes];
                    u[i] = { ...(deliveryTime as DeliveryTimeResponse) };
                    update(u, false);
                  }}
                  onDelete={() => {
                    deleteDeliveryTime(deliveryTime.id);
                  }}
                  shippingTypeOptions={shippingTypeOptions}
                  canEditType={false}
                />
              );
            })
          ) : (
            <LoadingContainer />
          )}
          {newDeliveryTimes.map((deliveryTime, i) => {
            return (
              <DeliveryTime
                key={`newDeliveryTime_${i}`}
                deliveryTime={deliveryTime}
                update={(deliveryTime) => {
                  const u = [...newInlineDeliveryTimes];
                  u[i] = {
                    ...(deliveryTime as InlineCreateDeliveryTimeRequest),
                  };
                  update(u, true);
                }}
                onDelete={() => {
                  const u = [...newInlineDeliveryTimes];
                  u.splice(i, 1);
                  update(u, true);
                }}
                shippingTypeOptions={shippingTypeOptions}
                canEditType={true}
              />
            );
          })}
          {newInlineDeliveryTimes.map((deliveryTime, i) => {
            return (
              <DeliveryTime
                key={`newInlineDeliveryTime_${i}`}
                deliveryTime={deliveryTime}
                update={(deliveryTime) => {
                  const u = [...newDeliveryTimes];
                  u[i] = { ...(deliveryTime as CreateDeliveryTimeRequest) };
                  update(u, true);
                }}
                onDelete={() => {
                  const u = [...newDeliveryTimes];
                  u.splice(i, 1);
                  update(u, true);
                }}
                shippingTypeOptions={shippingTypeOptions}
                canEditType={true}
              />
            );
          })}
          {!readonly ? (
            <div className="shipping-actions global-cardActions-postBorderWithMargin">
              <Button
                cta={t('view.product.shipping.new')}
                look={'secondary'}
                action={addDeliveryTime}
                width="minimal"
                active={
                  !!(
                    deliveryTimes &&
                    deliveryTimes.length + newDeliveryTimes.length < 3 &&
                    !readonly
                  )
                }
                hasPermission={userAuthorizations?.includes(
                  'delivery_times:create'
                )}
              />
              {deliveryTimes &&
              deliveryTimes.length + newDeliveryTimes.length < 3 ? null : (
                <div className="shipping-actions-text">
                  {t('view.product.shipping.reachedMax')}
                </div>
              )}
              {productId ? (
                <Button
                  cta={t('actions.save')}
                  look="save"
                  action={() => {
                    submitNewDeliveryTimes();
                    submitUpdatedDeliveryTimes(deliveryTimes);
                  }}
                  width="minimal"
                  active={
                    (deliveryTimes !== originalDeliveryTimes ||
                      newDeliveryTimes.length > 0) &&
                    !readonly
                  }
                  isLoading={isSubmitting}
                />
              ) : null}
            </div>
          ) : null}
        </>
      ) : (
        <EmptyState message={t('view.product.shipping.disabled')} />
      )}
    </div>
  );
};

interface DeliveryTimeProps {
  deliveryTime: DeliveryTimeResponse | InlineCreateDeliveryTimeRequest;
  update: (
    deliveryTime: DeliveryTimeResponse | InlineCreateDeliveryTimeRequest
  ) => void;
  onDelete: () => void;
  shippingTypeOptions: string[];
  canEditType: boolean;
}

export const DeliveryTime: React.FC<DeliveryTimeProps> = ({
  deliveryTime,
  update,
  onDelete,
  shippingTypeOptions,
  canEditType,
}) => {
  const { t } = useTranslation();
  return (
    <div className="shipping-deliveryTime">
      <div className="shipping-deliveryTime-inputGroup">
        <div className="global-inputGroup-inputInline">
          <Dropdown
            title={t('view.product.shipping.type')}
            options={shippingTypeOptions}
            selected={
              deliveryTime.type === ('Default' as DeliveryTimeType)
                ? null
                : deliveryTime.type
            }
            defaultOptionText="Bitte auswählen..."
            update={(e) =>
              update({
                ...deliveryTime,
                type: e.target.selectedOptions[0].value as DeliveryTimeType,
              })
            }
            translationPath="view.product.shipping.types."
            required={true}
            disabled={!canEditType}
          />
        </div>
        <div className="shipping-deliveryTime-inputGroup-inputDelete">
          <Button type="icon" look={'secondary'} action={onDelete}>
            <Trash
              className="shipping-deliveryTime-inputGroup-inputDelete-icon"
              fill="var(--color-danger)"
            />
          </Button>
        </div>
      </div>
      <div className="shipping-deliveryTime-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('view.product.shipping.min')}
            content={deliveryTime.min.toString()}
            update={(e) =>
              update({
                ...deliveryTime,
                min: parseInt(e),
              })
            }
            type="number"
            required={true}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('view.product.shipping.max')}
            content={deliveryTime.max.toString()}
            update={(e) =>
              update({
                ...deliveryTime,
                max: parseInt(e),
              })
            }
            type="number"
            required={true}
          />
        </div>
        <div className="global-inputGroup-input">
          <Dropdown
            title={t('view.product.shipping.unit')}
            options={['Hours', 'Days', 'Weeks', 'Months', 'Years']}
            selected={deliveryTime.unit}
            update={(e) =>
              update({
                ...deliveryTime,
                unit: e.target.selectedOptions[0].value as DeliveryTimeUnit,
              })
            }
            translationPath="view.product.shipping.units."
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

// const deepCompare = (prevProps: ShippingProps, nextProps: ShippingProps) => {
//   return (
//     prevProps.deliveryTimes === nextProps.deliveryTimes &&
//     prevProps.newDeliveryTimes === nextProps.newDeliveryTimes
//   );
// };

export default memo(Shipping);
