import './regulations.css';
import { useTranslation } from 'react-i18next';
import { Check } from '../../../elements/selectors/Selectors';

interface RegulationsProps {
  isBatchControlled?: boolean;
  inheritedIsBatchControlled?: boolean;
  toggleIsBatchControlled: () => void;
  isBestBeforeControlled?: boolean;
  inheritedIsBestBeforeControlled?: boolean;
  toggleIsBestBeforeControlled: () => void;
  isDangerousGoods?: boolean;
  inheritedIsDangerousGoods?: boolean;
  toggleIsDangerousGoods: () => void;
}

const Regulations: React.FC<RegulationsProps> = ({
  isBatchControlled,
  inheritedIsBatchControlled,
  toggleIsBatchControlled,
  isBestBeforeControlled,
  inheritedIsBestBeforeControlled,
  toggleIsBestBeforeControlled,
  isDangerousGoods,
  inheritedIsDangerousGoods,
  toggleIsDangerousGoods,
}) => {
  const { t } = useTranslation();

  return (
    <div className="regulations">
      <div className="regulations-setting">
        <Check
          checked={isBatchControlled ? true : false}
          update={toggleIsBatchControlled}
          inherited={inheritedIsBatchControlled}
        />
        <div className="regulations-setting-text">
          {t('view.product.regulations.isBatchControlled')}
        </div>
      </div>
      <div className="regulations-setting">
        <Check
          checked={isBestBeforeControlled ? true : false}
          update={toggleIsBestBeforeControlled}
          inherited={inheritedIsBestBeforeControlled}
        />
        <div className="regulations-setting-text">
          {t('view.product.regulations.isBestBeforeControlled')}
        </div>
      </div>
      <div className="regulations-setting">
        <Check
          checked={isDangerousGoods ? true : false}
          update={toggleIsDangerousGoods}
          inherited={inheritedIsDangerousGoods}
        />
        <div className="regulations-setting-text">
          {t('view.product.regulations.isDangerousGoods')}
        </div>
      </div>
    </div>
  );
};

export default Regulations;
