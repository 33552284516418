import './integrationshopifysuccess.css';
import appConnected from '../../../assets/img/app_connected.svg';
import { useTranslation } from 'react-i18next';

const IntegrationShopifySuccess = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'page.integrationShopifySuccess',
  });

  return (
    <div className={'main'}>
      <div className={'integrationShopifySuccess-wrapper'}>
        <div className={'integrationShopifySuccess'}>
          <img
            src={appConnected}
            alt={'done!'}
            className={'integrationShopifySuccess-img'}
          />
          <div className={'integrationShopifySuccess-title'}>{t('title')}</div>
          <div className={'integrationShopifySuccess-text'}>{t('text')}</div>
        </div>
      </div>
    </div>
  );
};
export default IntegrationShopifySuccess;
