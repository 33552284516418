import './refundingsummary.css';
import RefundPositionEntry from '../../../../types/RefundPositionEntry';
import { useTranslation } from 'react-i18next';
import TitleValueList from '../../../../elements/titlevaluelist/TitleValueList';

interface RefundingSummaryProps {
  commission: number;
  orderAmountTotal: number;
  selectedPositionEntries: RefundPositionEntry[];
  currency?: string;
}

const RefundingSummary: React.FC<RefundingSummaryProps> = ({
  selectedPositionEntries,
  orderAmountTotal,
  commission,
  currency,
}) => {
  const { t } = useTranslation();

  const getTotalAmount = () => {
    let initialAmount = 0.0;
    return selectedPositionEntries.reduce((prev, next) => {
      return prev + (next.refundAmount ?? 0);
    }, initialAmount);
  };

  const getCommissionPercentage = () => {
    return commission / orderAmountTotal;
  };

  const getPetCloudAmount = () => {
    return getTotalAmount() * getCommissionPercentage();
  };

  const getManufacturerAmount = () => {
    return getTotalAmount() - getPetCloudAmount();
  };

  return (
    <div className={'refundingSummary'}>
      <TitleValueList
        entries={[
          {
            title: t('view.order.refunding.summary.units'),
            value: selectedPositionEntries.length.toString(),
            asHighlight: true,
          },
          {
            title: t('view.order.refunding.summary.total'),
            value: getTotalAmount().toFixed(2) + '' + currency,
            asHighlight: true,
          },
          {
            title: t('view.order.refunding.summary.manufacturerAmount'),
            value: getManufacturerAmount().toFixed(2) + currency,
            hint: t('view.order.refunding.summary.manufacturerAmount_hint'),
            asHighlight: true,
            highlightColor: 'var(--color-yellow)',
          },
          {
            title: t('view.order.refunding.summary.petcloudAmount'),
            value: getPetCloudAmount().toFixed(2) + currency,
            hint: t('view.order.refunding.summary.petcloudAmount_hint'),
            asHighlight: true,
            highlightColor: 'var(--color-primary)',
          },
        ]}
        inline
      />
    </div>
  );
};

export default RefundingSummary;
