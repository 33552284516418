import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import {
  AssetFolderResponse,
  AssetResponse,
  UpsertProductAdditionalInformationMediaGalleryRequest,
} from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import FileBrowser from '../../../../elements/filebrowser/FileBrowser';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import { MediaItem } from '../../../../elements/mediaitem/MediaItem';
import Popup from '../../../../elements/popup/Popup';
import { TabSelector } from '../../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import './additionalinformationgallery.css';

interface AdditionalInformationGalleryProps {
  productId?: string;
  body: UpsertProductAdditionalInformationMediaGalleryRequest;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  getProduct?: () => void;
  closePopup?: () => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  onSave?: (request: any) => void;
  onAdd?: (request: any) => void;
}

const AdditionalInformationGallery: React.FC<
  AdditionalInformationGalleryProps
> = ({
  productId,
  body,
  queueCriticalAction,
  killCriticalAction,
  getProduct,
  closePopup,
  readonly,
  toggleProductSavingHelpOverlay,
  onSave,
  onAdd,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const assetsApi = api.assetsApi();
  const assetFoldersApi = api.assetFoldersApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const [payload, setPayload] =
    useState<UpsertProductAdditionalInformationMediaGalleryRequest>(body);
  const [selectedLanguage, setSelectedLanguage] = useState('de-DE');
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [assets, setAssets] = useState<AssetResponse[]>([]);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onSave) {
      onSave(payload);
    }
  }, [payload]);

  useEffect(() => {
    getAssets();
    getAssetFolders();
  }, []);

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssets = () => {
    const allAssetIds: string[] = [];
    Object.entries(payload.assetIds).forEach(
      ([language, assetIds]: [string, string[]]) => {
        assetIds.forEach((id) => {
          allAssetIds.push(id);
        });
      }
    );

    if (allAssetIds.length > 0) {
      setIsLoadingAssets(true);
      const allAssets = allAssetIds.map(async (assetId) => {
        return await getAssetById(assetId);
      });

      Promise.all(allAssets)
        .then((responses) => {
          setAssets(responses);
          setIsLoadingAssets(false);
        })
        .catch((error) => {
          errorHandler.addError(error.response);
          setIsLoadingAssets(false);
        });
    }
  };

  const getAssetById = (assetId: string) => {
    return new Promise<AssetResponse>((resolve, reject) => {
      assetsApi
        .assetsGetAssetById(assetId)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const handleAssetUpload = (assetsArray: AssetResponse[]) => {
    const updatedAssets = assets;
    const updatedPayload = payload;
    assetsArray.forEach((asset) => {
      updatedAssets.push(asset);
      updatedPayload.assetIds[selectedLanguage as TranslatedStringIndex]?.push(
        asset.id
      );
    });
    console.log([...updatedAssets]);
    setAssets([...updatedAssets]);
    setPayload({ ...updatedPayload });
  };

  const removeAsset = (assetId: string) => {
    const updatedAssets = assets;
    const updatedPayload = payload;

    const assetsIndex = updatedAssets.findIndex((a) => a.id === assetId);
    if (assetsIndex !== -1) {
      updatedAssets.splice(assetsIndex, 1);
    }

    const payloadIndex = payload.assetIds[
      selectedLanguage as TranslatedStringIndex
    ]?.findIndex((id) => id === assetId);
    if (payloadIndex !== undefined && payloadIndex !== -1) {
      updatedPayload.assetIds[
        selectedLanguage as TranslatedStringIndex
      ]?.splice(payloadIndex, 1);
    }

    setAssets([...updatedAssets]);
    setPayload({ ...updatedPayload });
  };

  const submitGallery = (
    request: UpsertProductAdditionalInformationMediaGalleryRequest
  ) => {
    if (productId && !onSave && !onAdd) {
      if (queueCriticalAction) {
        queueCriticalAction('updatingGallery');
      }
      setIsSubmitting(true);
      productsApi
        .productsUpsertProductAdditionalInformationMediaGallery(
          productId,
          request
        )
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          Store.addNotification({
            message: t(
              'view.product.additionalinformation.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (getProduct) {
            getProduct();
          }
          if (killCriticalAction) {
            killCriticalAction('updatingGallery');
          }
          if (closePopup) {
            closePopup();
          }
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          errorHandler.addError(error.response);
          if (killCriticalAction) {
            killCriticalAction('updatingGallery');
          }
        });
    } else if (onAdd) {
      onAdd(request);
    }
  };

  return (
    <div className="additionalinformationgallery">
      <TabSelector
        tabs={[
          {
            title: 'de-DE',
            key: 'de-DE',
          },
          {
            title: 'en-GB',
            key: 'en-GB',
          },
        ]}
        activeTabKey={selectedLanguage}
        update={(key: string) => setSelectedLanguage(key)}
      />
      <div className="additionalinformationgallery-items">
        {isLoadingAssets ? (
          <LoadingContainer />
        ) : (
          assets.map((asset) => {
            if (
              payload.assetIds[
                selectedLanguage as TranslatedStringIndex
              ]?.includes(asset.id)
            ) {
              return (
                <MediaItem
                  key={asset.id}
                  id={asset.id}
                  src={asset.uri}
                  onDelete={() => removeAsset(asset.id)}
                  disabled={false}
                  width={'calc(33% - 10px)'}
                  height={'calc(33% - 10px)'}
                  margin={5}
                />
              );
            } else {
              return null;
            }
          })
        )}
        {!readonly ? (
          <div className="additionalinformationgallery-dropzone">
            <Dropzone
              maxFiles={-1}
              callback={handleAssetUpload}
              assetContext={'Product'}
            />
          </div>
        ) : null}
      </div>
      {!readonly ? (
        <div className="additionalinformationgallery-actions">
          <Button
            cta={t(
              'view.product.additionalinformation.newPopup.downloads.gallery'
            )}
            action={() => setGalleryPopup(true)}
            width="minimal"
            look={'secondary'}
          />
          {!onSave ? (
            <Button
              cta={t('actions.save')}
              action={() => submitGallery(payload)}
              width="minimal"
              look="save"
              isLoading={isSubmitting}
            />
          ) : null}
        </div>
      ) : null}
      <Popup
        width="60%"
        toggled={galleryPopup}
        close={() => setGalleryPopup(false)}
      >
        {assetFolders ? (
          <FileBrowser
            assetFolders={assetFolders}
            allowUrlNavigation={false}
            allowAssetUpload={false}
            onChooseAssets={(assets: AssetResponse[]) => {
              handleAssetUpload(assets);
              setGalleryPopup(false);
            }}
          />
        ) : (
          <LoadingContainer />
        )}
      </Popup>
    </div>
  );
};

export default AdditionalInformationGallery;
