import './columnheaderconfig.css';
import { SheetData } from '../ProductImporter';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ReactComponent as IconError } from '../../../../../assets/icon/warning.svg';
import { ReactComponent as IconArrow } from '../../../../../assets/icon/right-arrow.svg';
import { ReactComponent as IconTrash } from '../../../../../assets/icon/trash.svg';
import Button from '../../../../elements/button/Button';
import DropdownMenu from '../../../../elements/dropdownmenu/DropdownMenu';
import MappingOptions from './mappingoptions/MappingOptions';
import { ReactComponent as IconString } from '../../../../../assets/icon/jsontable/textedit.svg';
import { ReactComponent as IconTranslated } from '../../../../../assets/icon/jsontable/translate.svg';
import { ReactComponent as IconNumber } from '../../../../../assets/icon/jsontable/123.svg';
import { ReactComponent as IconA } from '../../../../../assets/icon/jsontable/a.svg';
import { ReactComponent as IconCheckbox } from '../../../../../assets/icon/jsontable/checkbox.svg';
import { ReactComponent as IconLink } from '../../../../../assets/icon/jsontable/link.svg';
import { ReactComponent as IconStar } from '../../../../../assets/icon/special.svg';
import {
  BulkEditorProduct,
  getEmptyBulkEditorProduct,
} from '../../../../view/products/BulkEditor';
import useDefaultMappings, {
  ColumnHeaderMapping,
  ColumnHeaderMappingError,
} from './utils/useDefaultMappings';
import ColumnHeaderConfigProfiles from './columnheaderconfigprofiles/ColumnHeaderConfigProfiles';
import Hoverable from '../../../../elements/hoverable/Hoverable';
import useNotifications from '../../../../hooks/useNotifications';
import { LoadingContainer } from '../../../../elements/loading/Loading';

interface ColumnHeaderConfigProps {
  sheetData: SheetData;
  setProducts: (products: BulkEditorProduct[]) => void;
}

const ColumnHeaderConfig: React.FC<ColumnHeaderConfigProps> = ({
  sheetData,
  setProducts,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter.columnHeaderConfig',
  });
  const { pushNotification } = useNotifications();
  const { defaultMappings, mappingsReady } = useDefaultMappings();

  const [mappings, setMappings] = useState<ColumnHeaderMapping[]>([]);
  const [selectedMappingKey, setSelectedMappingKey] = useState<string>('ean');

  useEffect(() => {
    if (mappingsReady) {
      setMappings(defaultMappings);
    }
  }, [mappingsReady]);

  const setMapping = (m: ColumnHeaderMapping, index: number) => {
    setMappings((prev) => {
      const update = [...prev];
      update[index] = { ...m };
      return update;
    });
  };

  const getExampleValues = (key: string, limitLength?: boolean) => {
    const rowIndex = sheetData.headers.indexOf(key);
    const values: string[] = [];
    sheetData.data.slice(0, 10).forEach((row) => {
      const v: string = row[rowIndex]?.toString();
      if (!!v) {
        if (limitLength) {
          const sliced = v.slice(0, 20);
          values.push(v.length > 20 ? `${sliced}...` : sliced);
        } else {
          values.push(v);
        }
      }
    });
    return values;
  };

  const mapData = () => {
    // only consider mappings that have a mapped key
    const selectedMappings = mappings.filter((x) => x.mappedKey !== undefined);

    // check if there are any errors within the configuration
    const updatedMappings = mappings.map((mapping) => {
      if (selectedMappings.includes(mapping) && mapping.validationFunc) {
        mapping.errors = mapping.validationFunc(mapping);
        return mapping;
      } else {
        return mapping;
      }
    });

    // if there are any errors, stop the process and alert the user
    if (updatedMappings.some((x) => x.errors)) {
      setMappings(updatedMappings);
      pushNotification(t('notifications.mapping_errors_found'), 'danger');
      return;
    }

    const parentIdKeyIndex = sheetData.parentIdKey
      ? sheetData.headers.indexOf(sheetData.parentIdKey)
      : null;
    const idKeyIndex = sheetData.idKey
      ? sheetData.headers.indexOf(sheetData.idKey)
      : null;

    // import data and check if there are any errors while importing
    let hasErrors = false;
    const updatedMappings2 = [...mappings];
    const products: BulkEditorProduct[] = sheetData.data.map((row) => {
      let product: BulkEditorProduct = { ...getEmptyBulkEditorProduct() };
      selectedMappings.forEach((mapping) => {
        const importResult = mapping.importFunc(
          product,
          row,
          mapping.mappedKey
            ? sheetData.headers.indexOf(mapping.mappedKey)
            : undefined,
          mapping.options,
          sheetData.headers
        );

        // if the import function returned an error, add it the mappings
        if ('identifier' in importResult) {
          const mappingIndex = updatedMappings2.findIndex(
            (x) => x.key === mapping.key
          );
          if (mappingIndex > -1) {
            const errors = updatedMappings2[mappingIndex].errors ?? [];
            errors.push(importResult);
            updatedMappings2[mappingIndex].errors = errors;
            hasErrors = true;
          }
          // otherwise add the data to the product
        } else {
          product = mapping.importFunc(
            product,
            row,
            mapping.mappedKey
              ? sheetData.headers.indexOf(mapping.mappedKey)
              : undefined,
            mapping.options,
            sheetData.headers
          ) as BulkEditorProduct;
        }
      });
      if (parentIdKeyIndex && idKeyIndex) {
        product.parentIdKeyValue = row[parentIdKeyIndex];
        product.idKeyValue = row[idKeyIndex];
      }
      return product;
    });

    // sets parentIds according to import config for creation of parent / child groups.
    if (parentIdKeyIndex && idKeyIndex) {
      products.forEach((product) => {
        product.parentId = products.find(
          (p) => p.idKeyValue === product.parentIdKeyValue
        )?.id;
      });

      // removes attributes that are no longer needed
      products.forEach((p) => {
        p.parentIdKeyValue = undefined;
        p.idKeyValue = undefined;
      });
    }

    if (!hasErrors) {
      setProducts(products);
    } else {
      setMappings(updatedMappings2);
      pushNotification(t('notifications.import_errors_found'), 'danger');
    }
  };

  const removeMappingError = (index: number, identifier: string) => {
    setMappings((prevMappings) => {
      const updatedMappings = prevMappings ? [...prevMappings] : [];
      const errors = updatedMappings[index].errors;
      if (errors) {
        errors.splice(
          errors.findIndex((err) => err.identifier === identifier),
          1
        );
      }
      updatedMappings[index].errors = errors;
      return updatedMappings;
    });
  };

  const renderMappingErrors = useCallback(
    (errors: ColumnHeaderMappingError[]) => {
      // combine errors that have the exact same messages
      const combinedErrors: (ColumnHeaderMappingError & {
        count: number;
      })[] = [];
      errors.forEach((error) => {
        const i = combinedErrors.findIndex(
          (err) =>
            err.identifier === error.identifier &&
            JSON.stringify(err.messages) === JSON.stringify(error.messages)
        );
        if (i > -1) {
          combinedErrors[i].count += 1;
        } else {
          combinedErrors.push({ ...error, count: 1 });
        }
      });

      return (
        <div className={'columnHeaderConfig-list-item-mapping-errors'}>
          {combinedErrors.map((err) => (
            <div className={'columnHeaderConfig-list-item-mapping-error'}>
              <div
                className={
                  'columnHeaderConfig-list-item-mapping-error-messages'
                }
              >
                {err.messages.map((msg) => (
                  <div
                    className={
                      'columnHeaderConfig-list-item-mapping-error-message'
                    }
                  >
                    {msg}
                  </div>
                ))}
              </div>
              {err.count > 1 ? (
                <div
                  className={'columnHeaderConfig-list-item-mapping-error-count'}
                >
                  {err.count}x
                </div>
              ) : null}
            </div>
          ))}
        </div>
      );
    },
    []
  );

  if (mappingsReady && mappings.length > 0) {
    const selectedMappingIndex = mappings.findIndex(
      (m) => m.key === selectedMappingKey
    );

    const selectedMapping = mappings[selectedMappingIndex];
    return (
      <>
        <div className={'columnHeaderConfig'}>
          <div className={'columnHeaderConfig-list'}>
            <div className={'columnHeaderConfig-list-header'}>
              <div className={'columnHeaderConfig-list-header-column'}>
                {t('targetHeader')}
              </div>
              <div className={'columnHeaderConfig-list-header-column'}>
                <div className={'columnHeaderConfig-list-header-column-title'}>
                  {t('sourceHeader')}
                </div>
                <ColumnHeaderConfigProfiles
                  currentMappings={mappings}
                  setCurrentMappings={setMappings}
                  sheetData={sheetData}
                />
              </div>
            </div>
            <div className={'columnHeaderConfig-list-items-wrapper'}>
              <div className={'columnHeaderConfig-list-items'}>
                {mappings.map((mapping, index) => {
                  const selected = selectedMappingKey === mapping.key;
                  const mapped = !!mapping.mappedKey;
                  return (
                    <div
                      key={mapping.key}
                      className={`columnHeaderConfig-list-item ${
                        selected ? 'columnHeaderConfig-list-item_selected' : ''
                      }`}
                      onClick={() => {
                        setSelectedMappingKey(mapping.key);
                      }}
                    >
                      <div className={'columnHeaderConfig-list-item-title'}>
                        {t(`headers.${mapping.key}`)}
                      </div>
                      <div className={'columnHeaderConfig-list-item-mapping'}>
                        {mapping.errors ? (
                          <Hoverable
                            onHoverNode={renderMappingErrors(mapping.errors)}
                            helperClass={
                              'columnHeaderConfig-list-item-mapping-icon'
                            }
                            sticky
                          >
                            <IconError
                              className={
                                'columnHeaderConfig-list-item-mapping-icon columnHeaderConfig-list-item-mapping-icon__error'
                              }
                            />
                          </Hoverable>
                        ) : (
                          <IconArrow
                            className={
                              'columnHeaderConfig-list-item-mapping-icon'
                            }
                            fill={'var(--color-text_tertiary)'}
                          />
                        )}
                        <div
                          className={
                            'columnHeaderConfig-list-item-mapping-dropdown'
                          }
                        >
                          <DropdownMenu
                            options={[...sheetData.headers]}
                            selected={mapping.mappedKey}
                            onSelect={(value) => {
                              setMappings((prevMappings) => {
                                const updatedMappings = prevMappings
                                  ? [...prevMappings]
                                  : [];
                                updatedMappings[index].mappedKey = value;
                                if (
                                  updatedMappings[index].options
                                    ?.languageMap !== undefined
                                ) {
                                  updatedMappings[index].options.languageMap[
                                    'de-DE'
                                  ] = value;
                                }
                                updatedMappings[index].errors = undefined;
                                return updatedMappings;
                              });
                              removeMappingError(index, 'mappedKey');
                            }}
                            highlightedOptions={mappings
                              .filter((x) => x.mappedKey)
                              .map((x) => x.mappedKey ?? '')}
                            errors={
                              mapping.errors?.find(
                                (err) => err.identifier === 'mappedKey'
                              )?.messages ?? undefined
                            }
                          />
                        </div>
                        {mapped ? (
                          <div
                            className={
                              'columnHeaderConfig-list-item-mapping-button'
                            }
                          >
                            <Button
                              type={'icon'}
                              look={'secondary'}
                              margin={'left'}
                              action={() => {
                                setMappings((prevMappings) => {
                                  const updatedMappings = prevMappings
                                    ? [...prevMappings]
                                    : [];
                                  updatedMappings[index].mappedKey = undefined;
                                  updatedMappings[index].errors = undefined;
                                  return updatedMappings;
                                });
                              }}
                            >
                              <IconTrash
                                className={'button-icon button-icon-danger'}
                              />
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <div className={'columnHeaderConfig-list-item-icon'}>
                        {
                          columnHeaderMappingContentTypeIcons[
                            mapping.contentType
                          ]
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={'columnHeaderConfig-info'} key={selectedMapping.key}>
            <div className={'columnHeaderConfig-info-config'}>
              <div className={'columnHeaderConfig-info-config-title'}>
                {t(`headers.${selectedMapping.key}`)}
              </div>
              <div className={'columnHeaderConfig-info-config-contentType'}>
                <div
                  className={'columnHeaderConfig-info-config-contentType-icon'}
                >
                  {
                    columnHeaderMappingContentTypeIcons[
                      selectedMapping.contentType
                    ]
                  }
                </div>
                <div
                  className={'columnHeaderConfig-info-config-contentType-name'}
                >
                  {t(`contentType.${selectedMapping.contentType}`)}
                </div>
              </div>
              <div
                className={
                  'columnHeaderConfig-info-config-text global-textElement'
                }
              >
                {t(`descriptions.${selectedMapping.key}`)}
              </div>
              <MappingOptions
                mapping={selectedMapping}
                setMapping={(m) => setMapping(m, selectedMappingIndex)}
                sheetData={sheetData}
              />
              {selectedMapping.previewFunc && !!selectedMapping.mappedKey ? (
                <div className={'columnHeaderConfig-info-config-preview'}>
                  <div
                    className={'columnHeaderConfig-info-config-preview-title'}
                  >
                    {t('preview')}
                  </div>
                  <div
                    className={'columnHeaderConfig-info-config-preview-values'}
                  >
                    {selectedMapping.previewFunc(
                      selectedMapping,
                      getExampleValues(selectedMapping.mappedKey)
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={'columnHeaderConfig-info-examples'}>
              <div className={'columnHeaderConfig-info-examples-title'}>
                {t('examples')}
              </div>
              <div className={'columnHeaderConfig-info-examples-values'}>
                {!!selectedMapping.mappedKey ? (
                  getExampleValues(selectedMapping.mappedKey, true).map(
                    (value) => (
                      <div className={'columnHeaderConfig-info-examples-value'}>
                        {value}
                      </div>
                    )
                  )
                ) : (
                  <div
                    className={'columnHeaderConfig-info-examples-values-empty'}
                  >
                    {t('exampleValues_empty')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={'global-cardActions global-cardActions-margin'}>
          <Button
            cta={t('cta')}
            width={'minimal'}
            look={'save'}
            action={mapData}
            active={!!mappings.find((m) => m.mappedKey)}
          />
        </div>
      </>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ColumnHeaderConfig;

const columnHeaderMappingContentTypeIcons: { [key: string]: ReactNode } = {
  0: (
    <IconString
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  1: (
    <IconTranslated
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  2: (
    <IconNumber
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  3: (
    <IconA
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  4: (
    <IconTranslated
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  5: (
    <IconCheckbox
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  6: (
    <IconLink
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
  7: (
    <IconStar
      fill={'var(--color-text_tertiary)'}
      className={'columnHeaderConfig-contentTypeIcon'}
    />
  ),
};
