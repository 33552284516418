import './productmappingsocket.css';
import { useDroppable } from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDragDrop } from '../../../../../../assets/icon/drag-and-drop.svg';
import Searchbar from '../../../../../elements/searchbar/Searchbar';
import ProductMappingProduct from '../productmappingproduct/ProductMappingProduct';
import { ExtendedProductMapping, searchProduct } from '../ProductMappings';
import { useEffect, useState } from 'react';
import useDebounce from '../../../../../hooks/useDebounce';
import { NoResults } from '../../../../../elements/emptystate/EmptyState';

interface ProductMappingSocketProps {
  id: string;
  type: 'create' | 'empty';
  isInBackground?: boolean;
  isDragging: boolean;
  mappings?: ExtendedProductMapping[];
  mapProduct?: (activeMappingId: string, overMappingId: string) => void;
}

const ProductMappingSocket: React.FC<ProductMappingSocketProps> = ({
  id,
  type,
  isInBackground,
  isDragging,
  mappings,
  mapProduct,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.common.productMapping',
  });
  const { setNodeRef } = useDroppable({
    id,
  });

  const debounced = useDebounce();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<ExtendedProductMapping[] | null>(null);

  useEffect(() => {
    debounced(getResults);
  }, [query]);

  const getResults = () => {
    if (mappings && query && query !== '') {
      const results = mappings.filter((mapping) =>
        searchProduct(query, mapping.domainProduct)
      );
      setResults(results);
    } else {
      setResults(null);
    }
  };

  return (
    <div
      ref={setNodeRef}
      className={`productMappingSocket productMappingSocket__${
        isDragging ? 'dragging' : type
      } ${isInBackground ? 'productMappingSocket__inBackground' : ''}`}
    >
      {isDragging ? (
        <IconDragDrop className={'productMappingSocket-icon'} />
      ) : (
        t(`socket_${type}`)
      )}
      {setQuery ? (
        <div
          className={'productMappingSocket-search'}
          onMouseLeave={() => setQuery('')}
        >
          <div className={'productMappingSocket-search-bar'}>
            <Searchbar
              cta={t('search.cta_searchDomain')}
              height={'normal'}
              onSearch={setQuery}
              value={query}
              invertedColor
            />
          </div>
          {results && mapProduct && query && query !== '' ? (
            results.length > 0 ? (
              <div className={'productMappingSocket-search-results'}>
                {results.map((mapping) =>
                  mapping.domainProduct ? (
                    <div
                      className={'productMappingSocket-search-result'}
                      onClick={() => mapProduct(mapping.id, id.split('_')[0])}
                    >
                      <ProductMappingProduct
                        item={mapping.domainProduct}
                        id={mapping.id}
                        showVariantIcon
                      />
                    </div>
                  ) : null
                )}
              </div>
            ) : (
              <div className={'productMappingSocket-search-results'}>
                <div className={'productMappingSocket-search-results-empty'}>
                  <NoResults />
                </div>
              </div>
            )
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default ProductMappingSocket;
