import { BulkEditorProduct } from '../../../../../view/products/BulkEditor';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import {
  getTranslatedContent,
  getTranslatedContentFromImporterLanguageMap,
} from '../../../../../../locate/languageUtils';
import {
  InformationContentType,
  InformationEntityType,
  ProductAdditionalInformationResponse,
  ProductAnalyticConstituentResponse,
  ProductInformationContentType,
  ProductPriceResponse,
  ProductUnitResponse,
  TranslatedTypeResponseOfString,
} from '../../../../../api/petcloudapi/api';
import {
  addEmptyAnalyticConstituent,
  addEmptyPrice,
} from '../../../utils/addEmptyPrice';
import { constituentslist } from '../../../../product/analyticconstituents/constituentslist';
import {
  contentTypes,
  informationGroupNames,
} from '../../../../product/additionalinformation/AdditionalInformation';
import { useTranslation } from 'react-i18next';
import { ColumnHeaderMappingError } from './useDefaultMappings';

const unitMultipliers: { [key: string]: number } = {
  kg: 1,
  g: 0.001,
  mg: 0.00001,
  m: 0.01,
  cm: 1,
  mm: 0.1,
};

export type TranslatedContentLanguageMap = {
  [key: string]: string;
};

const useImportFunctions = () => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'view.productBulkEditor.productImporter.columnHeaderConfig.importFunctionMessages',
  });

  const getValue = (r: any[], rI: number | undefined) => {
    if (r && rI !== undefined) {
      return r[rI];
    }
  };

  const importConvertedNumericValue = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    opts: {
      originalUnit: string;
    },
    key: keyof BulkEditorProduct
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      return {
        ...p,
        [key]: parseFloat(v) * (unitMultipliers[opts.originalUnit] ?? 1),
      };
    } else {
      return p;
    }
  };

  const importString = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    key: keyof BulkEditorProduct
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI)?.toString();
    if (v !== undefined && v !== null) {
      return {
        ...p,
        [key]: v,
      };
    } else {
      return p;
    }
  };

  const importInt = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    key: keyof BulkEditorProduct
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      if (isNaN(v)) {
        return {
          identifier: 'unknown',
          messages: [`${v}: ${t('parseInt_failed')}`],
        };
      } else {
        return {
          ...p,
          [key]: parseInt(v),
        };
      }
    } else {
      return p;
    }
  };

  const importFloat = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    key: keyof BulkEditorProduct
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      if (isNaN(v)) {
        return {
          identifier: 'unknown',
          messages: [`${v}: ${t('parseFloat_failed')}`],
        };
      } else {
        return {
          ...p,
          [key]: parseFloat(v),
        };
      }
    } else {
      return p;
    }
  };

  const importProductUnit = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    key: keyof BulkEditorProduct,
    availableUnits: ProductUnitResponse[] | null
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    if (availableUnits) {
      const v = getValue(r, rI);
      if (v !== undefined && v !== null) {
        const unit = availableUnits.find((unit) =>
          Object.values(unit.name).includes(v)
        );
        if (unit) {
          return {
            ...p,
            [key]: unit.id,
          };
        } else {
          return {
            identifier: 'productUnitId',
            messages: [`${v}: ${t('productUnitId_not_found')}`],
          };
        }
      } else {
        return p;
      }
    } else {
      return {
        identifier: 'productUnitId',
        messages: [t('productUnits_not_loaded')],
      };
    }
  };

  const importTranslatedContent = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    opts: {
      languageMap: TranslatedContentLanguageMap;
      languageUniform: boolean;
    },
    key: keyof BulkEditorProduct,
    headers?: string[]
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    console.log(headers);
    const defaultValue = getValue(r, rI);
    if (defaultValue !== undefined && defaultValue !== null) {
      if (opts.languageUniform) {
        const translations = getTranslatedContent(
          opts.languageMap['de-DE'] as TranslatedStringIndex,
          defaultValue,
          true
        );
        return {
          ...p,
          [key]: translations,
        };
      } else if (headers) {
        const translations = getTranslatedContentFromImporterLanguageMap(
          r,
          opts.languageMap,
          headers
        );
        return {
          ...p,
          [key]: translations,
        };
      } else {
        return p;
      }
    } else {
      return p;
    }
  };

  const importProductPrice = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    priceKey: keyof ProductPriceResponse
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      if (isNaN(v)) {
        return {
          identifier: priceKey,
          messages: [`${v}: ${t('parseFloat_failed')}`],
        };
      } else {
        const f = parseFloat(v);
        if (p.productPrices && p.productPrices[0]) {
          return {
            ...p,
            productPrices: [
              {
                ...p.productPrices[0],
                [priceKey]: f,
              },
            ],
          };
        } else {
          const price = addEmptyPrice(
            {
              id: crypto.randomUUID(),
              gross: 0,
              countryId: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
              currencyId: 'f0902077-da87-4a7f-9bbd-c3e05a1f4a22',
            },
            p.id
          );
          return {
            ...p,
            productPrices: [
              {
                ...price,
                [priceKey]: f,
              },
            ],
          };
        }
      }
    } else {
      return p;
    }
  };

  const importToggleValue = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    opts: {
      trueKey: string;
      falseKey: string;
    },
    key: keyof BulkEditorProduct
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      return {
        ...p,
        [key]:
          v === opts.trueKey ? true : v === opts.falseKey ? false : undefined,
      };
    } else {
      return p;
    }
  };

  const splitAnalyticConstituents = (
    value: any,
    nameValuesSeparator: string,
    valueSeparator: string,
    separator: string
  ): { name: string; value: number }[] => {
    return value?.split(separator).map((item: string) => {
      console.log(item);
      const [name, valueString] = item.trim().split(nameValuesSeparator);
      console.log(name, valueString);
      const value =
        valueSeparator !== ''
          ? valueString?.split(valueSeparator)[0]
          : valueString;
      console.log(value);
      return {
        name: name,
        value: parseFloat(value),
      };
    });
  };

  const importAnalyticConstituents = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    opts: {
      nameValuesSeparator: string;
      valueSeparator: string;
      separator: string;
    }
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      const availableConstituents = constituentslist;
      const constituents: ProductAnalyticConstituentResponse[] = [];

      splitAnalyticConstituents(
        v,
        opts.nameValuesSeparator,
        opts.valueSeparator,
        opts.separator
      ).forEach((result) => {
        const match = availableConstituents.find((x) => {
          return !!Object.entries(x.name).find(
            ([lang, translatedName]) => translatedName === result.name
          );
        });
        if (match) {
          constituents.push(
            addEmptyAnalyticConstituent(
              constituents.length,
              match.name,
              result.value
            )
          );
        }
      });
      return {
        ...p,
        analyticConstituents: constituents,
      };
    } else {
      return p;
    }
  };

  const splitBySeparator = (value: any, separator: string) => {
    return value?.split(separator).map((string: string) => {
      return string.trim();
    });
  };

  // export const importAdditionalInformationDownloads = (
  //   p: BulkEditorProduct,
  //   r: any[],
  //   rI: number | undefined,
  //   opts: {
  //     separator: string;
  //   }
  // ) => {
  //   const v = getValue(r, rI);
  //   if (v !== undefined && v !== null) {
  //     const downloads = splitBySeparator(v, opts.separator);
  //     //TODO
  //   } else {
  //     return p;
  //   }
  // };

  // utility function to create additional information base object for all other additional information import functions
  const getAdditionalInformationPayload = (
    p: BulkEditorProduct,
    v: TranslatedTypeResponseOfString,
    informationGroupId: string,
    informationContentType: InformationContentType
  ): ProductAdditionalInformationResponse => {
    return {
      id: crypto.randomUUID(),
      createdAt: '',
      contentType: contentTypes[
        informationGroupId
      ] as ProductInformationContentType,
      position: 0,
      informationGroupId: informationGroupId,
      informationGroup: {
        id: informationGroupId,
        createdAt: 'createdAt',
        informationEntityType:
          InformationEntityType.ProductAdditionalInformation,
        informationContentType: informationContentType,
        name: informationGroupNames[informationGroupId] ?? 'name missing',
      },
      value: v,
      productId: p.id ?? '',
    };
  };

  const importAdditionalInformationRichText = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    opts: {
      languageMap: TranslatedContentLanguageMap;
      languageUniform: boolean;
    },
    informationGroupId: string,
    informationContentType: InformationContentType,
    headers?: string[]
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      const update = p.additionalInformation
        ? [...p.additionalInformation]
        : [];
      const translated = opts.languageUniform
        ? getTranslatedContent(
            opts.languageMap['de-DE'] as TranslatedStringIndex,
            JSON.stringify({ content: v }),
            true
          )
        : getTranslatedContentFromImporterLanguageMap(
            r,
            opts.languageMap,
            headers ?? [],
            (x) => JSON.stringify({ content: x })
          );
      update.push(
        getAdditionalInformationPayload(
          p,
          translated,
          informationGroupId,
          informationContentType
        )
      );
      return {
        ...p,
        additionalInformation: update,
      };
    } else {
      return p;
    }
  };

  const importAdditionalInformationBulletPoints = (
    p: BulkEditorProduct,
    r: any[],
    rI: number | undefined,
    opts: {
      separator: ',';
      languageMap: TranslatedContentLanguageMap;
      languageUniform: boolean;
    },
    informationGroupId: string,
    informationContentType: InformationContentType,
    headers?: string[]
  ): BulkEditorProduct | ColumnHeaderMappingError => {
    const v = getValue(r, rI);
    if (v !== undefined && v !== null) {
      const update = p.additionalInformation
        ? [...p.additionalInformation]
        : [];

      const bulletPoints = v.split(opts.separator).map((x: string) => x.trim());
      const translated = opts.languageUniform
        ? getTranslatedContent(
            opts.languageMap['de-DE'] as TranslatedStringIndex,
            JSON.stringify({ items: bulletPoints }),
            true
          )
        : getTranslatedContentFromImporterLanguageMap(
            r,
            opts.languageMap,
            headers ?? [],
            (x) => {
              const bp = x.split(opts.separator).map((y: string) => y.trim());
              return JSON.stringify({ items: bp });
            }
          );

      update.push(
        getAdditionalInformationPayload(
          p,
          translated,
          informationGroupId,
          informationContentType
        )
      );
      return {
        ...p,
        additionalInformation: update,
      };
    } else {
      return p;
    }
  };

  return {
    importConvertedNumericValue,
    importString,
    importProductUnit,
    importInt,
    importFloat,
    importTranslatedContent,
    importProductPrice,
    importToggleValue,
    splitAnalyticConstituents,
    importAnalyticConstituents,
    splitBySeparator,
    getAdditionalInformationPayload,
    importAdditionalInformationRichText,
    importAdditionalInformationBulletPoints,
  };
};

export default useImportFunctions;
