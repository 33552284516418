import './paymentmethod.css';
import { ReactComponent as IconCreditCard } from '../../../../../assets/icon/credit-card.svg';
import { ReactComponent as IconKlarna } from '../../../../../assets/icon/klarna.svg';
import { ReactComponent as IconPayPal } from '../../../../../assets/icon/paypal.svg';
import { ReactComponent as IconGPay } from '../../../../../assets/icon/gpay.svg';
import { ReactComponent as IconApplePay } from '../../../../../assets/icon/apple_pay.svg';
import { useTranslation } from 'react-i18next';

interface PaymentMethodProps {
  initialPaymentMethodBrand?: string | null;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  initialPaymentMethodBrand,
}) => {
  const { t } = useTranslation();

  return (
    <div className={'paymentMethod'}>
      {initialPaymentMethodBrand && icons[initialPaymentMethodBrand]
        ? icons[initialPaymentMethodBrand]
        : null}
      <div className={'paymentMethod-name'}>
        {initialPaymentMethodBrand
          ? t(`view.order.payment.method.${initialPaymentMethodBrand}`)
          : '-'}
      </div>
    </div>
  );
};

export default PaymentMethod;

const icons: { [key: string]: JSX.Element } = {
  Cards: (
    <IconCreditCard
      className={'paymentMethod-icon'}
      fill={'var(--color-blue)'}
    />
  ),
  Kreditkarte: (
    <IconCreditCard
      className={'paymentMethod-icon'}
      fill={'var(--color-blue)'}
    />
  ),
  'Klarna Sofortüberweisung': (
    <IconKlarna className={'paymentMethod-icon paymentMethod-icon-klarna'} />
  ),
  'Klarna Kauf auf Rechnung': (
    <IconKlarna className={'paymentMethod-icon paymentMethod-icon-klarna'} />
  ),
  'Klarna Account': (
    <IconKlarna className={'paymentMethod-icon paymentMethod-icon-klarna'} />
  ),
  PayPal: <IconPayPal className={'paymentMethod-icon'} />,
  'Google Pay': (
    <IconGPay className={'paymentMethod-icon paymentMethod-icon-gpay'} />
  ),
  ApplePay: (
    <IconApplePay
      className={'paymentMethod-icon paymentMethod-icon-applePay'}
    />
  ),
  'Apple Pay': (
    <IconApplePay
      className={'paymentMethod-icon paymentMethod-icon-applePay'}
    />
  ),
};
