import './loyaltyranks.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  AssetFolderResponse,
  CreateLoyaltyRankRequest,
  LoyaltyRankResponse,
  TranslatedTypeResponseOfAssetResponse,
  UpdateLoyaltyRankRequest,
} from '../../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import List from '../../../../features/list/List';
import Button from '../../../../elements/button/Button';
import useLanguages from '../../../../hooks/useLanguages';
import Popup from '../../../../elements/popup/Popup';
import LoyaltyRank from './loyaltyrank/LoyaltyRank';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import useNotifications from '../../../../hooks/useNotifications';
import InformationBox from '../../../../elements/informationbox/InformationBox';

const LoyaltyRanks = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.loyalty.loyaltyRanks',
  });
  const api = usePetCloudApi();
  const assetFoldersApi = api.assetFoldersApi();
  const loyaltyRanksApi = api.loyaltyRanksApi();
  const errorHandler = useErrorHandler();
  const { emptyTranslatableContent } = useLanguages();
  const { pushNotification } = useNotifications();

  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [loyaltyRanks, setLoyaltyRanks] = useState<
    LoyaltyRankResponse[] | null
  >(null);
  const [newRank, setNewRank] = useState<CreateLoyaltyRankRequest | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRank, setSelectedRank] =
    useState<UpdateLoyaltyRankRequest | null>(null);
  const [deleteRankId, setDeleteRankId] = useState<string | null>(null);

  useEffect(() => {
    getLoyaltyRanks();
    getAssetFolders();
  }, []);

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getLoyaltyRanks = () => {
    loyaltyRanksApi
      .loyaltyRanksGetLoyaltyRanks()
      .then((response) => {
        console.log(response);
        setLoyaltyRanks(
          response.data.map((rank) => {
            const { asset, name, description, ...rest } = rank;
            return {
              asset,
              name,
              description,
              ...rest,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const initNewRank = () => {
    setNewRank({
      minimumPoints: 0,
      discountPercentage: 0,
      validFrom: new Date().toISOString(),
      validTo: null,
      name: emptyTranslatableContent,
      description: null,
      assetId: null,
    });
  };

  const submitNewRank = () => {
    if (newRank) {
      setIsSubmitting(true);
      loyaltyRanksApi
        .loyaltyRanksCreateLoyaltyRank(newRank)
        .then((response) => {
          console.log(response);
          setNewRank(null);
          setIsSubmitting(false);
          getLoyaltyRanks();
          pushNotification(t('notifications.create_success'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const submitUpdatedRank = () => {
    if (selectedRank) {
      setIsSubmitting(true);
      loyaltyRanksApi
        .loyaltyRanksUpdateLoyaltyRank(
          (selectedRank as LoyaltyRankResponse).id,
          selectedRank
        )
        .then((response) => {
          console.log(response);
          setSelectedRank(null);
          setIsSubmitting(false);
          getLoyaltyRanks();
          pushNotification(t('notifications.update_success'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const submitDeleteRank = () => {
    if (deleteRankId) {
      setIsSubmitting(true);
      loyaltyRanksApi
        .loyaltyRanksDeleteLoyaltyRank(deleteRankId)
        .then((response) => {
          console.log(response);
          setDeleteRankId(null);
          setIsSubmitting(false);
          getLoyaltyRanks();
          pushNotification(t('notifications.delete_success'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const renderAsset = (
    asset?: TranslatedTypeResponseOfAssetResponse | null
  ) => {
    const src = asset?.[i18n.language as TranslatedStringIndex]?.uri;
    if (src) {
      return (
        <img src={src} className={'list-table-td-img-img'} alt={'Rank Icon'} />
      );
    } else {
      return <div className={'list-table-td-na'}>-</div>;
    }
  };

  if (loyaltyRanks && assetFolders) {
    return (
      <div className="loyaltyRanks">
        <List
          ignore={['id', 'updatedAt']}
          items={loyaltyRanks}
          translatedStrings={['name', 'description']}
          dateStrings={['validFrom', 'validTo', 'createdAt']}
          renderObjects={[
            {
              key: 'asset',
              renderMethod: renderAsset,
            },
          ]}
          onRowClick={(rank: LoyaltyRankResponse) => {
            setSelectedRank({ ...rank });
          }}
          actions={[
            {
              cta: 'delete',
              look: 'danger',
              action: (rank) => setDeleteRankId(rank.id),
            },
          ]}
        />
        <div className={'global-cardActions global-cardActions-postBorder'}>
          <Button
            cta={t('actions.new')}
            look={'secondary'}
            width={'minimal'}
            action={initNewRank}
          />
        </div>
        {newRank ? (
          <Popup toggled={true} width={'40%'} close={() => setNewRank(null)}>
            <div className={'popup-title'}>{t('newPopup.title')}</div>
            <LoyaltyRank
              rank={newRank}
              updateRank={(rank) => setNewRank(rank)}
              assetFolders={assetFolders}
              isSubmitting={isSubmitting}
              submit={submitNewRank}
            />
          </Popup>
        ) : null}
        {selectedRank ? (
          <Popup
            toggled={true}
            width={'40%'}
            close={() => setSelectedRank(null)}
          >
            <div className={'popup-title'}>{t('updatePopup.title')}</div>
            <LoyaltyRank
              rank={selectedRank}
              updateRank={(rank) => setSelectedRank(rank)}
              assetFolders={assetFolders}
              isSubmitting={isSubmitting}
              submit={submitUpdatedRank}
              asset={(selectedRank as LoyaltyRankResponse).asset}
            />
          </Popup>
        ) : null}
        {deleteRankId ? (
          <Popup
            toggled={true}
            width={'30%'}
            close={() => setDeleteRankId(null)}
          >
            <div className={'popup-title'}>{t('deletePopup.title')}</div>
            <InformationBox
              type={'warning'}
              title={t('deletePopup.infoBox.title')}
              content={t('deletePopup.infoBox.text')}
            />
            <div className={'global-cardActions'}>
              <Button
                cta={t('deletePopup.cta')}
                look={'danger'}
                width={'full'}
                action={submitDeleteRank}
                isLoading={isSubmitting}
              />
            </div>
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default LoyaltyRanks;
