import './listcontrols.css';
import Searchbar from '../../../elements/searchbar/Searchbar';
import { Dropdown, Check } from '../../../elements/selectors/Selectors';
import Button from '../../../elements/button/Button';
import Input from '../../../elements/input/Input';
import DatePicker from '../../../elements/datepicker/DatePicker';
import { ReactComponent as Next } from '../../../../assets/icon/next.svg';
import { ReactComponent as Previous } from '../../../../assets/icon/back.svg';
import { ReactComponent as IconSettings } from '../../../../assets/icon/settings.svg';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import Popup from '../../../elements/popup/Popup';
import DateRange from '../../../elements/daterange/DateRange';
import DropdownMenu from '../../../elements/dropdownmenu/DropdownMenu';
import Hoverable from '../../../elements/hoverable/Hoverable';

export type ListFilterSetting = {
  renderMethod?: () => ReactNode;
  title?: string;
  action?: () => void;
};

export type ListControlsFilter = {
  title?: string;
  update?: (param: any) => void;
  options?: string[];
  optionObjects?: { name: string; id: string }[] | null;
  checked?: boolean;
  date?: Date;
  dateRange?: string[];
  showTimeSelect?: boolean;
  selected?: any;
  multiSelected?: string[] | null;
  value?: string | null;
  inputType?: 'text' | 'number';
  disableAutoOptionSorting?: boolean;
  renderMethod?: () => ReactNode;
  nativeDropdown?: boolean;
};

export type ListControlSearch = {
  cta: string;
  initialQuery?: string;
  height: 'small' | 'normal';
  width: 'minimal' | 'full';
  callback: (query: string) => boolean;
  itemCount?: number;
};

export interface ListControlsProps {
  search?: ListControlSearch;
  onItemCountHoverNode?: () => ReactNode;
  filters?: (ListControlsFilter | undefined)[];
  settings?: ListFilterSetting[];
  applyFilters?: { cta: string; action: () => void };
  cursor?: {
    next: (() => void) | undefined;
    previous: (() => void) | undefined;
    currentPosition?: number;
  };
  children?: any;
  rightAlignedChildren?: any;
}

const ListControls: React.FC<ListControlsProps> = ({
  search,
  onItemCountHoverNode,
  filters,
  applyFilters,
  cursor,
  children,
  settings,
  rightAlignedChildren,
}) => {
  const { t } = useTranslation();
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [noSearchResultsError, setNoSearchResultsError] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (noSearchResultsError) {
      timer = setTimeout(() => {
        setNoSearchResultsError(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [noSearchResultsError]);

  useEffect(() => {
    if (search && search.initialQuery) {
      search.callback(search.initialQuery);
    }
  }, []);

  return (
    <div className="listControls">
      {search ? (
        <div className="listControls-search">
          <Searchbar
            cta={search.cta}
            height={search.height}
            onDebouncedSearch={(input) =>
              setNoSearchResultsError(!search.callback(input))
            }
            initialValue={search.initialQuery}
            isError={noSearchResultsError}
          />
          {search.itemCount !== null && search.itemCount !== undefined ? (
            <Hoverable
              onHoverNode={
                onItemCountHoverNode ? onItemCountHoverNode() : undefined
              }
            >
              <div className={'listControls-search-count'}>
                <ListItemCount itemCount={search.itemCount} />
              </div>
            </Hoverable>
          ) : null}
        </div>
      ) : null}
      <div className="listControls-filters">
        {filters
          ? filters.map((filter, i) => {
              return <Filter key={i} {...filter} />;
            })
          : null}
        {applyFilters ? (
          <Button
            cta={applyFilters.cta}
            action={applyFilters.action}
            look={'secondary'}
          />
        ) : null}
        {children}
      </div>
      {cursor ? (
        <div className={'listControls-cursor'}>
          <Button
            type={'icon'}
            look={'secondary'}
            action={cursor.previous}
            active={!!cursor.previous}
          >
            <Previous
              className={'button-icon'}
              fill={
                !!cursor.previous
                  ? 'var(--color-text_primary)'
                  : 'var(--color-text_tertiary)'
              }
            />
          </Button>
          <div className={'listControls-cursor-position'}>
            {cursor.currentPosition ? (
              <div className={'listControls-cursor-position-number'}>
                {cursor.currentPosition}
              </div>
            ) : null}
          </div>
          <Button
            type={'icon'}
            look={'secondary'}
            action={cursor.next}
            active={!!cursor.next}
          >
            <Next
              className={'button-icon'}
              fill={
                !!cursor.next
                  ? 'var(--color-text_primary)'
                  : 'var(--color-text_tertiary)'
              }
            />
          </Button>
        </div>
      ) : null}
      <div className={'listControls-rightAlignedChildren'}>
        {rightAlignedChildren}
        {settings ? (
          <Button
            helperCSSClass={'listControls-rightAlignedChildren-more'}
            type={'icon'}
            look={'tertiary'}
            action={() => setSettingsPopup(true)}
          >
            <IconSettings
              className={'button-icon'}
              fill={'var(--color-text_tertiary)'}
            />
          </Button>
        ) : null}
      </div>
      <Popup
        toggled={settingsPopup}
        width={'30%'}
        close={() => setSettingsPopup(false)}
      >
        <div className={'popup-title'}>{t('list.settingsPopup.title')}</div>
        <div className={'listControls-settings'}>
          {settings?.map((setting, index) => {
            if (setting.renderMethod) {
              return (
                <div key={index} className={'listControls-setting'}>
                  {setting.renderMethod()}
                </div>
              );
            } else {
              return 'TODO';
            }
          })}
        </div>
      </Popup>
    </div>
  );
};

export default ListControls;

const Filter: React.FC<ListControlsFilter> = ({
  title,
  options,
  optionObjects,
  checked,
  date,
  dateRange,
  showTimeSelect,
  update,
  selected,
  multiSelected,
  value,
  inputType,
  disableAutoOptionSorting,
  renderMethod,
  nativeDropdown,
}) => {
  if (renderMethod) {
    return <div className="listControls-filter">{renderMethod()}</div>;
  }
  if (update) {
    if (optionObjects || options) {
      if (nativeDropdown) {
        return (
          <div className="listControls-filter">
            <Dropdown
              title={title}
              defaultOptionText={title}
              options={options}
              optionObjects={optionObjects}
              update={(e) => {
                if (optionObjects) {
                  const id =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  if (id) {
                    const option = optionObjects.find(
                      (option) => option.id === id
                    );
                    update(option);
                  }
                }
                if (options) {
                  const val = e.currentTarget.selectedOptions[0].value;
                  if (val) {
                    update(val);
                  }
                }
              }}
              selected={selected}
              disableAutoOptionSorting={disableAutoOptionSorting}
            />
          </div>
        );
      } else {
        return (
          <div className="listControls-filter">
            <DropdownMenu
              title={title}
              defaultOptionText={title}
              options={options}
              optionObjects={optionObjects}
              onSelect={(val, id) => {
                if (optionObjects) {
                  if (id) {
                    const option = optionObjects.find(
                      (option) => option.id === id
                    );
                    update(option);
                  }
                }
                if (options) {
                  if (val) {
                    update(val);
                  }
                }
              }}
              selected={selected}
              multiSelected={multiSelected}
              disableAutoOptionSorting={disableAutoOptionSorting}
            />
          </div>
        );
      }
    } else if (checked !== undefined) {
      return (
        <div className="listControls-filter">
          <div
            className="button button-tertiary listControls-filter-boolean"
            onClick={() => update(!checked)}
          >
            <Check checked={checked} />
            <div className="listControls-filter-boolean-text">{title}</div>
          </div>
        </div>
      );
    } else if (date) {
      return (
        <div className="listControls-filter">
          <DatePicker
            title={title}
            selected={date}
            onChange={update}
            showTimeSelect={showTimeSelect}
          />
        </div>
      );
    } else if (dateRange) {
      return (
        <div className="listControls-filter">
          <DateRange
            selectedDateRange={dateRange}
            update={update}
            showTimeSelect={showTimeSelect}
          />
        </div>
      );
    } else if (value) {
      return (
        <div className="listControls-filter">
          <Input
            title={title}
            content={value}
            update={(value) => update(value)}
            type={inputType}
          />
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

interface ListItemCountProps {
  itemCount: number;
}

export const ListItemCount: React.FC<ListItemCountProps> = ({ itemCount }) => {
  const { t } = useTranslation();
  return (
    <div className={'listControls-itemCount'}>
      <span className={'listControls-itemCount-number'}>{itemCount}</span>
      {t('list.columns.index')}
    </div>
  );
};
