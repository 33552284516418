import './bestsellers.css';
import { useState } from 'react';
import {
  BestSellingProductsOrderBy,
  SoldProductResponse,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import BestSellersFetcher from '../common/bestsellersfetcher/BestSellersFetcher';
import SoldProductsList from '../common/soldproductslist/SoldProductsList';

interface BestsellersProps {
  dateRangeFrom: string;
  dateRangeTo: string;
  selectedManufacturerId?: string | null;
}

const Bestsellers: React.FC<BestsellersProps> = ({
  dateRangeFrom,
  dateRangeTo,
  selectedManufacturerId,
}) => {
  const [bestsellers, setBestsellers] = useState<SoldProductResponse[] | null>(
    null
  );
  const [selectedOrderBy, setSelectedOrderBy] =
    useState<BestSellingProductsOrderBy>(BestSellingProductsOrderBy.Revenue);

  return (
    <div className={'bestsellers'}>
      <BestSellersFetcher
        dateRangeFrom={dateRangeFrom}
        dateRangeTo={dateRangeTo}
        selectedManufacturerId={selectedManufacturerId}
        onFetch={(responses) =>
          setBestsellers(responses as SoldProductResponse[])
        }
        onOrder={(order) => setSelectedOrderBy(order)}
        mode={'range'}
      />
      {bestsellers ? (
        <div className={'bestsellers-list'}>
          <SoldProductsList
            soldProducts={bestsellers}
            orderBy={selectedOrderBy}
          />
        </div>
      ) : (
        <LoadingContainer />
      )}
    </div>
  );
};

export default Bestsellers;
