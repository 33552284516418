import './productvalidationlist.css';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import React, { useEffect, useState } from 'react';
import {
  ProductValidationResultResponse,
  ValidationFailureResponse,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import ProductList from '../../features/productlist/ProductList';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import ErrorsOverview from './errorsoverview/ErrorsOverview';
import Badge from '../../elements/badge/Badge';

interface ProductValidationListProps {
  manufacturerId: string | null;
  callback: (results: ProductValidationResultResponse[]) => void;
}

const ProductValidationList: React.FC<ProductValidationListProps> = ({
  manufacturerId,
  callback,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productValidation',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();

  const [validationResults, setValidationResults] = useState<
    ProductValidationResultResponse[] | null
  >(null);
  const [validationResult, setValidationResult] =
    useState<ProductValidationResultResponse | null>(null);

  useEffect(() => {
    getValidationResults();
  }, [manufacturerId]);

  const getValidationResults = () => {
    productsApi
      .productsGetValidationResults(manufacturerId ?? undefined)
      .then((response) => {
        console.log(response);
        setValidationResults(response.data);
        if (!manufacturerId) {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderErrors = (
    errors: ValidationFailureResponse[] | null,
    result: ProductValidationResultResponse | null
  ) => {
    return (
      <div className={'productValidationList-errors'}>
        <div className={'productValidationList-errors-count'}>
          <Badge
            title={errors?.length.toString() ?? '0'}
            color={'var(--color-danger)'}
          />
        </div>
        <Button
          width={'tiny'}
          cta={t('errorsButton')}
          action={() => setValidationResult(result)}
          active={!!result}
          look={'secondary'}
        />
      </div>
    );
  };

  if (validationResults) {
    return (
      <div className={'productValidationList'}>
        <ProductList
          prefetchedProducts={validationResults}
          renderObjects={[
            {
              key: 'errors',
              renderMethod: renderErrors,
            },
          ]}
          allowActions
          adjustHeightToViewportOffset={120}
        />
        {validationResult && validationResult.errors ? (
          <Popup
            toggled={true}
            width={'40%'}
            close={() => setValidationResult(null)}
          >
            <div className={'popup-title'}>{t('popup.title')}</div>
            <ErrorsOverview
              errors={validationResult.errors}
              productId={validationResult.id}
              isVariant={!!validationResult.parentId}
              showMessageInHead
            />
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ProductValidationList;
