import './additionalinformationeditor.css';
import { ProductAdditionalInformationResponse } from '../../../../api/petcloudapi/api';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import AdditionalInformation from '../../../../sections/product/additionalinformation/AdditionalInformation';
import { useState } from 'react';

interface AdditionalInformationEditorProps {
  additionalInformation: ProductAdditionalInformationResponse[];
  productId: string;
  updateCallback: EditorUpdateCallback;
}

const AdditionalInformationEditor: React.FC<
  AdditionalInformationEditorProps
> = ({ additionalInformation, productId, updateCallback }) => {
  const [information, setInformation] = useState(additionalInformation);

  return (
    <div className={'jtce-additionalInformation'}>
      <div className={'jtce-additionalInformation-items'}>
        <AdditionalInformation
          productId={productId}
          additionalInformation={information}
          onDelete={(id) => {
            const update = [...information];
            const index = update.findIndex((i) => i.id === id);
            if (index !== -1) {
              update.splice(index, 1);
              setInformation(update);
            }
          }}
          onSave={(request) => {
            const update = [...information];
            const index = update.findIndex((i) => i.id === request.id);
            if (index !== -1) {
              update[index] = {
                ...request,
              };
              console.log(update);
              setInformation(update);
            }
          }}
          onAdd={(request) => {
            console.log(request);
            const update = [...information];
            update.push(request);
            setInformation(update);
          }}
          fullWidthButton
        />
      </div>
      {updateCallback ? (
        <EditorActions save={() => updateCallback([{ value: information }])} />
      ) : null}
    </div>
  );
};

export default AdditionalInformationEditor;
