import './auth.css';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/button/Button';
import { AuthenticationContext } from '../../contexts/auth/Authentication';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import useIntegrationURLParams from '../../hooks/useIntegrationURLParams';

const Auth: React.FC = () => {
  const auth = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const { storeShopifyIntegrationParams } = useIntegrationURLParams();

  useEffect(() => {
    storeShopifyIntegrationParams();
  }, []);

  return (
    <div className="main">
      <div className="auth">
        <div className="card auth-modal">
          <div className="auth-modal-logo">
            <Logo
              fill={'var(--color-text_primary)'}
              className="auth-modal-logo-img"
            />
          </div>
          <div className="auth-modal-title">{t('page.auth.title')}</div>
          <div className="auth-modal-text">{t('page.auth.text')}</div>
          <div className="auth-modal-button">
            <Button
              cta={t('page.auth.form.cta')}
              look="primary"
              action={() => {
                auth?.setRedirectUriToCurrentUrl();
                auth?.initiateAuth();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
