import './newproductgroupmapping.css';
import {
  CreateProductGroupMappingRequest,
  ProductGroupResponse,
  PropertyValidationRuleResponse,
} from '../../../../api/petcloudapi/api';
import Popup from '../../../../elements/popup/Popup';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ProductGroupMappingEdit from '../productgroupmappingedit/ProductGroupMappingEdit';
import Button from '../../../../elements/button/Button';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../hooks/useNotifications';

const createProductGroupMappingRequest: CreateProductGroupMappingRequest = {
  name: '',
  isOptional: false,
  isUserInputAllowed: false,
  hasPropertyOptionListing: false,
  propertyGroupId: 'null',
  propertyOptionIds: [],
  productGroupIds: [],
  propertyValidationRuleIds: null,
};

interface NewProductGroupMappingProps {
  availableProductGroups: ProductGroupResponse[];
  availablePropertyValidationRules: PropertyValidationRuleResponse[];
  refreshAvailablePropertyValidationRules: () => void;
  popup: boolean;
  closePopup: () => void;
  refreshMappings: () => void;
}

const NewProductGroupMapping: React.FC<NewProductGroupMappingProps> = ({
  availableProductGroups,
  availablePropertyValidationRules,
  refreshAvailablePropertyValidationRules,
  popup,
  closePopup,
  refreshMappings,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroupMappings.new',
  });
  const api = usePetCloudApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const [request, setRequest] = useState<CreateProductGroupMappingRequest>(
    createProductGroupMappingRequest
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = () => {
    setIsSubmitting(true);
    productGroupMappingsApi
      .productGroupMappingsCreateProductGroupMapping(request)
      .then((response) => {
        console.log(response.data);
        setRequest(createProductGroupMappingRequest);
        setIsSubmitting(false);
        pushNotification(t('notifications.success'));
        closePopup();
        refreshMappings();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'newProductGroupMapping'}>
      <Popup toggled={popup} width={'50%'} close={closePopup}>
        <div className={'popup-title'}>{t('title')}</div>
        <ProductGroupMappingEdit
          request={request}
          update={(r) => setRequest(r)}
          availableProductGroups={availableProductGroups}
          availablePropertyValidationRules={availablePropertyValidationRules}
          refreshAvailablePropertyValidationRules={
            refreshAvailablePropertyValidationRules
          }
        />
        <div className={'global-cardActions newProductGroupMapping-actions'}>
          <Button
            cta={t('cta')}
            look={'save'}
            isLoading={isSubmitting}
            width={'minimal'}
            action={submit}
          />
        </div>
      </Popup>
    </div>
  );
};

export default NewProductGroupMapping;
