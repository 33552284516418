const slugify = (string: string | null | undefined) => {
  if (string) {
    return string
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  } else {
    return '';
  }
};

export default slugify;
