import './headersetting.css';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { JsonTableHeader } from '../../JsonTable';
import { Check } from '../../../../elements/selectors/Selectors';
import { ReactComponent as IconDrag } from '../../../../../assets/icon/drag.svg';
import { ReactComponent as IconPin } from '../../../../../assets/icon/jsontable/menu/pin.svg';
import { ReactComponent as IconPinned } from '../../../../../assets/icon/jsontable/menu/pinned.svg';

interface HeaderSettingProps extends JsonTableHeader {
  toggleVisibility: () => void;
  togglePinned: () => void;
}

const HeaderSetting: React.FC<HeaderSettingProps> = ({
  id,
  title,
  hidden,
  pinned,
  toggleVisibility,
  togglePinned,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 99999 : 1,
    boxShadow: isDragging ? 'var(--shadow-wide)' : 'none',
  };

  console.log(id);

  return (
    <div
      ref={setNodeRef}
      className={'headerSetting'}
      {...attributes}
      {...listeners}
      style={style}
    >
      <IconDrag className={'headerSetting-dragIcon'} />
      <div className={'headerSetting-toggle'}>
        <Check key={'hidden'} checked={!hidden} update={toggleVisibility} />
      </div>
      <div
        className={`headerSetting-pin ${
          pinned ? 'headerSetting-pin__active' : ''
        }`}
        onClick={togglePinned}
      >
        {pinned ? (
          <IconPinned className={'headerSetting-pin-icon'} />
        ) : (
          <IconPin className={'headerSetting-pin-icon'} />
        )}
      </div>
      {title}
    </div>
  );
};

export default HeaderSetting;
