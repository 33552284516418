import './roles.css';
import { useTranslation } from 'react-i18next';
import Input from '../../../elements/input/Input';
import { useEffect, useState } from 'react';
import Button from '../../../elements/button/Button';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import PolicyMatrix from './policymatrix/PolicyMatrix';
import Popup from '../../../elements/popup/Popup';
import {
  ManufacturerResponse,
  UserRoleResponse,
} from '../../../api/petcloudapi/api';
import ItemActions, {
  ItemAction,
} from '../../../elements/itemactions/ItemActions';
import { Dropdown } from '../../../elements/selectors/Selectors';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useAuthorization } from '../../../contexts/auth/Authorization';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { Store } from 'react-notifications-component';
import _ from 'lodash';
import Retractable from '../../../elements/retractable/Retractable';
import Badge from '../../../elements/badge/Badge';
import { useUser } from '../../../contexts/auth/User';
import RoleNameMapper from './rolenamemapper/RoleNameMapper';

interface RolesProps {
  manufacturers: ManufacturerResponse[] | null;
}

const Roles: React.FC<RolesProps> = ({ manufacturers }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { authorizations } = useAuthorization();
  const [newRolePopup, setNewRolePopup] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');
  const [newRoleManufacturerId, setNewRoleManufacturerId] = useState<
    string | null
  >(null);
  const [roles, setRoles] = useState<UserRoleResponse[] | null>(null);
  const [roleToBeEdited, setRoleToBeEdited] = useState<UserRoleResponse | null>(
    null
  );
  const [updatedRoleIsSubmitting, setUpdatedRoleIsSubmitting] = useState(false);
  const [templateRole, setTemplateRole] = useState<UserRoleResponse | null>(
    null
  );
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [newRoleIsSubmitting, setNewRoleIsSubmitting] = useState(false);

  const api = usePetCloudApi();
  const userRolesApi = api.userRolesApi();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    setRoles(null);
    userRolesApi
      .userRolesGetUserRoles()
      .then((response) => {
        console.log(response);
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  useEffect(() => {
    const role = roles?.find((role) => role.id === roleToBeEdited?.id);
    if (role && role.policies) {
      setSelectedPermissions(role.policies);
    }
  }, [roleToBeEdited]);

  const updateSelectedPermissions = (p: string) => {
    const current = [...selectedPermissions];
    const i = current.findIndex((permission) => permission === p);
    if (i >= 0) {
      current.splice(i, 1);
    } else {
      current.push(p);
    }
    setSelectedPermissions(current);
  };

  const validateNewRole = () => {
    if (newRoleName !== '') {
      return selectedPermissions.length > 0;
    } else {
      return false;
    }
  };

  const submitNewRole = () => {
    const body = {
      name: newRoleName,
      policies: selectedPermissions,
      manufacturerId: newRoleManufacturerId,
    };
    userRolesApi
      .userRolesCreateUserRole(body)
      .then((response) => {
        console.log(response);
        setNewRolePopup(false);
        setNewRoleIsSubmitting(false);
        getRoles();
        Store.addNotification({
          message: t('view.team.roles.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const updateRole = () => {
    if (roleToBeEdited) {
      const body = {
        name: roleToBeEdited.name,
        policies: selectedPermissions,
      };
      userRolesApi
        .userRolesUpdateUserRole(roleToBeEdited.id, body)
        .then((response) => {
          console.log(response);
          setUpdatedRoleIsSubmitting(false);
          setRoleToBeEdited(null);
          getRoles();
          Store.addNotification({
            message: t('view.team.roles.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const deleteRole = (idToBeDeleted: string) => {
    userRolesApi
      .userRolesDeleteUserRole(idToBeDeleted)
      .then((response) => {
        console.log(response);
        getRoles();
        Store.addNotification({
          message: t('view.team.roles.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  useEffect(() => {
    if (templateRole) {
      setSelectedPermissions(templateRole.policies);
    }
  }, [templateRole]);

  const getAllowedUserActions = (
    role: UserRoleResponse
  ): ItemAction[] | null => {
    const allowedUserActions: ItemAction[] = [];
    if (role.name === 'MANUFACTURER_ADMIN' && !user?.isProductOwner) {
      return null;
    } else {
      if (authorizations?.includes('user_roles:edit')) {
        allowedUserActions.push({
          cta: 'edit',
          look: 'blue',
          action: () => {
            setRoleToBeEdited(role);
          },
        });
      }
      if (authorizations?.includes('user_roles:delete')) {
        allowedUserActions.push({
          cta: 'delete',
          look: 'danger',
          action: () => {
            deleteRole(role.id);
          },
        });
      }
    }
    return allowedUserActions;
  };

  const getAllUserPermissions = () => {
    const results: string[] = [];
    user?.userRoles.forEach((role) => {
      role.policies.forEach((policy) => {
        results.push(policy);
      });
    });
    return results;
  };

  // groups roles by manufacturer for clustered view if user is product owner
  const getGroupedRoles = (roleArray: UserRoleResponse[]) => {
    return _.groupBy(roleArray, 'manufacturerId');
  };

  const renderGroupedRolesHead = (
    manufacturerId: string,
    manufacturers: ManufacturerResponse[],
    roles: UserRoleResponse[]
  ) => {
    const manufacturer = manufacturers.find((m) => m.id === manufacturerId);
    return (
      <>
        <div className="retractable-head-element">
          {manufacturer?.companyName ?? 'Inpetto Team'}
        </div>
        <div className="retractable-head-element">
          <Badge title={roles.length.toString()} color="var(--color-blue)" />
        </div>
      </>
    );
  };

  if (roles) {
    const groupedRoles = getGroupedRoles(roles);
    return (
      <div className="roles">
        {user?.isProductOwner && manufacturers ? (
          Object.entries(groupedRoles).map(
            ([manufacturerId, roles]: [string, UserRoleResponse[]]) => {
              return (
                <div className="roles-retractable">
                  <Retractable
                    head={renderGroupedRolesHead(
                      manufacturerId,
                      manufacturers,
                      roles
                    )}
                  >
                    <div className="roles-list roles-retractable-content">
                      {roles.map((role, i) => {
                        const actions = getAllowedUserActions(role);
                        return (
                          <Role
                            key={i}
                            role={role}
                            onClick={() => {
                              setRoleToBeEdited(role);
                            }}
                          >
                            {actions && actions.length > 0 ? (
                              <ItemActions item={role} actions={actions} />
                            ) : null}
                          </Role>
                        );
                      })}
                    </div>
                  </Retractable>
                </div>
              );
            }
          )
        ) : (
          <div className="roles-list">
            {roles.map((role, i) => {
              const actions = getAllowedUserActions(role);
              return (
                <Role
                  key={i}
                  role={role}
                  onClick={() => {
                    setRoleToBeEdited(role);
                  }}
                >
                  {actions && actions.length > 0 ? (
                    <ItemActions item={role} actions={actions} />
                  ) : null}
                </Role>
              );
            })}
          </div>
        )}
        {authorizations?.includes('user_roles:create') ? (
          <div className="global-cardActions-left">
            <Button
              look={'secondary'}
              cta={t('view.team.roles.new')}
              action={() => {
                setNewRolePopup(true);
                setSelectedPermissions([]);
                setRoleToBeEdited(null);
              }}
              width="minimal"
            />
          </div>
        ) : null}
        <Popup
          toggled={!!roleToBeEdited}
          width="50%"
          close={() => setRoleToBeEdited(null)}
        >
          <div className="roles-popup-title">{t('view.team.roles.edit')}</div>
          <div className="global-input">
            <Input
              key={roleToBeEdited?.id}
              title={t('view.team.roles.name')}
              update={(e) => {
                if (roleToBeEdited) {
                  setRoleToBeEdited({
                    ...roleToBeEdited,
                    name: e,
                  });
                }
              }}
              content={roleToBeEdited?.name}
            />
          </div>
          <PolicyMatrix
            t={t}
            selectedPermissions={selectedPermissions}
            updateSelectedPermissions={updateSelectedPermissions}
            inhertiablePermissions={getAllUserPermissions()}
          />
          <div className="roles-popup-buttons">
            <Button
              cta={t('actions.cancel')}
              look={'secondary'}
              action={() => setRoleToBeEdited(null)}
              width="minimal"
            />
            <Button
              cta={t('actions.save')}
              look="save"
              action={() => {
                updateRole();
                setUpdatedRoleIsSubmitting(true);
              }}
              width="minimal"
              margin="left"
              isLoading={updatedRoleIsSubmitting}
            />
          </div>
        </Popup>
        <Popup
          toggled={newRolePopup}
          width="50%"
          close={() => setNewRolePopup(false)}
        >
          <div className="roles-popup-title">{t('view.team.roles.new')}</div>
          <div className="global-inputGroup">
            <div className="global-inputGroup-input">
              <Input
                title={t('view.team.roles.new_name')}
                update={(e) => {
                  setNewRoleName(e);
                }}
                content={newRoleName}
              />
            </div>
            <div className="global-inputGroup-input">
              <Dropdown
                title={t('view.team.roles.new_template')}
                defaultOptionText={t('view.team.roles.no_template')}
                optionObjects={roles}
                update={(e) => {
                  const selectedTemplateRole = roles.find(
                    (role) =>
                      role.id ===
                      e.target.selectedOptions[0].getAttribute('data-value')
                  );
                  if (selectedTemplateRole) {
                    setTemplateRole(selectedTemplateRole);
                  }
                }}
                selected={templateRole?.name}
              />
            </div>
            {user?.isProductOwner && manufacturers ? (
              <div className="global-inputGroup-input">
                <Dropdown
                  title={t('view.team.roles.manufacturer')}
                  optionObjects={manufacturers.map((manufacturer) => {
                    return {
                      id: manufacturer.id,
                      name: manufacturer.companyName,
                    };
                  })}
                  update={(e) => {
                    setNewRoleManufacturerId(
                      e.target.selectedOptions[0].getAttribute('data-value')
                    );
                  }}
                  selected={
                    manufacturers.find((m) => m.id === newRoleManufacturerId)
                      ?.companyName
                  }
                />
              </div>
            ) : null}
          </div>
          <PolicyMatrix
            t={t}
            selectedPermissions={selectedPermissions}
            updateSelectedPermissions={updateSelectedPermissions}
            inhertiablePermissions={getAllUserPermissions()}
          />
          <div className="roles-popup-buttons">
            <Button
              cta={t('actions.cancel')}
              look={'secondary'}
              action={() => setNewRolePopup(false)}
              width="minimal"
            />
            <Button
              cta={t('view.team.roles.create')}
              look="save"
              action={() => {
                submitNewRole();
                setNewRoleIsSubmitting(true);
              }}
              width="minimal"
              margin="left"
              isLoading={newRoleIsSubmitting}
              active={validateNewRole()}
            />
          </div>
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Roles;

interface RoleProps {
  role: UserRoleResponse;
  onClick: () => void;
}

const Role: React.FC<RoleProps> = ({ role, onClick, children }) => {
  return (
    <div className={'roles-list-role'} onClick={onClick}>
      <RoleNameMapper role={role} />
      <div className={'roles-list-role-children'}>{children}</div>
    </div>
  );
};
