import './card.css';
import React, { useState, useEffect, SetStateAction } from 'react';
import { ReactComponent as Linked } from '../../../assets/icon/linked.svg';
import { ReactComponent as Unlinked } from '../../../assets/icon/unlinked.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icon/warning.svg';
import { ReactComponent as Close } from '../../../assets/icon/close.svg';
import { HintBox, SmallHint } from '../hint/Hint';
import Popup from '../popup/Popup';
import { useTranslation } from 'react-i18next';
import InformationBox from '../informationbox/InformationBox';
import Button from '../button/Button';
import { Error } from '../../contexts/errorhandler/ErrorHandler';
import KnowledgeBasePopup, {
  KnowledgeBaseInfoItem,
} from '../knowledgebasepopup/KnowledgeBasePopup';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';
import { ReactComponent as IconValidated } from '../../../assets/icon/validated.svg';
import { ReactComponent as IconPO } from '../../../assets/icon/sidebar/shield.svg';
import { ReactComponent as IconLock } from '../../../assets/icon/padlock.svg';
import {
  ProductGroupMappingResponse,
  PropertyValidationRuleResponse,
} from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import Badge, { BadgeProps } from '../badge/Badge';
import Hoverable from '../hoverable/Hoverable';
import ToggleSwitch from '../toggleswitch/ToggleSwitch';
import { CSSTransition } from 'react-transition-group';

interface CardProps {
  bigScreenWidth: string;
  noMargin?: boolean;
  color?: string;
  title?: string;
  action?: () => void;
  cta?: string;
  inherited?: boolean;
  badges?: BadgeProps[];
  required?: boolean;
  hint?: {
    message: string;
    stretchToLeft?: boolean;
  };
  helperCSSClass?: string;
  spanToViewport?: boolean;
  productOwnerOverride?: boolean;
}

const Card: React.FC<CardProps> = ({
  noMargin,
  children,
  color,
  title,
  cta,
  action,
  inherited,
  badges,
  required,
  hint,
  helperCSSClass,
  bigScreenWidth,
  spanToViewport,
  productOwnerOverride,
}) => {
  return (
    <div
      className={`card ${helperCSSClass ?? ''} ${
        productOwnerOverride ? 'card-productOwnerOverride' : ''
      }`}
      style={{
        margin: noMargin ? 0 : '0 auto 20px',
        backgroundColor: color ?? 'var(--color-foreground)',
        width: bigScreenWidth,
        flexGrow: spanToViewport ? 1 : 'initial',
      }}
    >
      {title || cta ? (
        <div className="card-header">
          <div className="card-header-info">
            {title ? (
              <CardTitle
                title={title}
                inherited={inherited}
                required={required}
                badges={badges}
                hint={hint}
              />
            ) : null}
          </div>
          <div className="card-header-action" onClick={action}>
            {cta}
          </div>
        </div>
      ) : null}
      {productOwnerOverride ? <SafeGuard /> : null}
      {children}
    </div>
  );
};

export default Card;

interface SafeGuardProps {
  borderRadius?: string;
}

const SafeGuard: React.FC<SafeGuardProps> = ({ borderRadius }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.card',
  });
  const [safeGuarded, setSafeGuarded] = useState(true);

  return (
    <CSSTransition
      in={safeGuarded}
      timeout={600}
      unmountOnExit
      classNames={'card-safeguard'}
    >
      <div
        className={'card-safeguard'}
        style={borderRadius ? { borderRadius } : undefined}
      >
        <div className={'card-safeguard-box'}>
          <Hoverable onHoverNode={<HintBox paragraphs={[t('po_override')]} />}>
            <IconPO className={'card-safeguard-box-icon'} />
          </Hoverable>
          <div className={'card-safeguard-box-switch'}>
            <ToggleSwitch
              toggled={safeGuarded}
              toggle={() => setSafeGuarded(false)}
              toggledColor={'var(--color-danger)'}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

interface HelperCardProps {
  title?: string;
  text: string;
  knowledgeBaseItems?: KnowledgeBaseInfoItem[];
  settingName?: string;
}

export const HelperCard: React.FC<HelperCardProps> = ({
  title,
  text,
  knowledgeBaseItems,
  settingName,
}) => {
  const { t } = useTranslation();
  const { getToggledSetting, setToggledSetting } = useLocalStorageSettings();
  const [muted, setMuted] = useState(false);

  const allowedToRender = () => {
    if (settingName || muted) {
      return !getToggledSetting(`helperCard-${settingName}`);
    } else {
      return true;
    }
  };

  if (allowedToRender()) {
    return (
      <div className={'card card-section helpercard'}>
        {title ? <CardTitle title={title} /> : null}
        <div className={'helpercard-content'}>
          <div className={'helpercard-content-column'}>
            {settingName ? (
              <Close
                fill={'var(--color-blue)'}
                className={'helpercard-content-dontShowAgain'}
                onClick={() => {
                  setToggledSetting(`helperCard-${settingName}`, true);
                  setMuted(true);
                }}
              />
            ) : null}
            <div className={'helpercard-content-text'}>{text}</div>
          </div>
          {knowledgeBaseItems ? (
            <KnowledgeBasePopup
              infoItems={knowledgeBaseItems}
              customButton={
                <div className={'helpercard-content-button'}>
                  {t('actions.learnMore')}
                </div>
              }
            />
          ) : null}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

interface CardSectionProps {
  noTopPadding?: boolean;
  title?: string;
  inherited?: boolean;
  locked?: boolean;
  badges?: BadgeProps[];
  required?: boolean;
  hint?: {
    message: string;
    stretchToLeft?: boolean;
  };
  knowledgeBaseItems?: KnowledgeBaseInfoItem[];
  action?: {
    action: () => void;
    cta: string;
  };
  validation?: {
    rules: PropertyValidationRuleResponse[] | null | undefined;
    identifier: string;
  };
  productGroupMappings?: ProductGroupMappingResponse[] | null;
  productOwnerOverride?: boolean;
}

export const CardSection: React.FC<CardSectionProps> = ({
  children,
  noTopPadding,
  title,
  inherited,
  locked,
  badges,
  required,
  hint,
  knowledgeBaseItems,
  action,
  validation,
  productGroupMappings,
  productOwnerOverride,
}) => {
  return (
    <div className={noTopPadding ? 'card-section-noTop' : 'card-section'}>
      {title || action ? (
        <div className={'card-section-header'}>
          {title ? (
            <CardTitle
              title={title}
              inherited={inherited}
              locked={locked}
              required={required}
              badges={badges}
              hint={hint}
              knowledgeBaseItems={knowledgeBaseItems}
              validation={validation}
              productGroupMappings={productGroupMappings}
            />
          ) : null}
          {action ? (
            <div className="card-header-action" onClick={action.action}>
              {action.cta}
            </div>
          ) : null}
        </div>
      ) : null}
      {children}
      {productOwnerOverride ? <SafeGuard borderRadius={'0'} /> : null}
    </div>
  );
};

interface CardTitleProps {
  title: string;
  inherited?: boolean;
  locked?: boolean;
  required?: boolean;
  badges?: BadgeProps[];
  hint?: {
    message: string;
    stretchToLeft?: boolean;
  };
  knowledgeBaseItems?: KnowledgeBaseInfoItem[];
  hasBottomMargin?: boolean;
  validation?: {
    rules: PropertyValidationRuleResponse[] | null | undefined;
    identifier: string;
  };
  productGroupMappings?: ProductGroupMappingResponse[] | null;
}

export const CardTitle: React.FC<CardTitleProps> = ({
  title,
  inherited,
  locked,
  required,
  badges,
  hint,
  knowledgeBaseItems,
  hasBottomMargin,
  validation,
  productGroupMappings,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.card',
  });

  const getValidationDescriptions = () => {
    const descriptions: string[] = [];
    if (productGroupMappings) {
      productGroupMappings.forEach((m) => {
        descriptions.push(m.name);
        // return (
        //   m.description?.[i18n.language as TranslatedStringIndex] ??
        //   'missing description or translation'
        // );
      });
    }
    if (validation && validation.rules) {
      validation.rules.forEach((r) => {
        if (r.identifier === validation.identifier) {
          descriptions.push(
            r.description?.[i18n.language as TranslatedStringIndex] ??
              'missing description or translation'
          );
        }
      });
    }
    if (descriptions.length > 0) {
      descriptions.unshift(t('hasValidationHint'));
      return descriptions;
    }
  };

  const validationDescriptions = getValidationDescriptions();

  return (
    <div
      className={'card-title '}
      style={hasBottomMargin ? { marginBottom: 20 } : undefined}
    >
      <div>
        {inherited !== null && inherited !== undefined ? (
          inherited ? (
            <Linked
              className="global-icon-inherited"
              fill="var(--color-inherited)"
            />
          ) : (
            <Unlinked
              className="global-icon-inherited"
              fill="var(--color-text_tertiary)"
            />
          )
        ) : null}
        {locked ? (
          <IconLock
            className="global-icon-inherited"
            fill="var(--color-text_tertiary)"
          />
        ) : null}
        <div
          className={
            inherited
              ? 'card-header-info-title global-inherited'
              : 'card-header-info-title'
          }
        >
          {title}
        </div>
        {badges
          ? badges.map((b, i) => {
              return (
                <Badge
                  key={i}
                  title={b.title}
                  color={b.color}
                  helperCSSClass={'card-header-info-badge'}
                />
              );
            })
          : null}
        {required ? <div className="global-required">•</div> : null}
        {knowledgeBaseItems ? (
          <KnowledgeBasePopup infoItems={knowledgeBaseItems} />
        ) : null}
        {hint ? (
          <SmallHint
            paragraphs={[hint.message]}
            stretchToLeft={hint.stretchToLeft}
          />
        ) : null}
      </div>
      {validationDescriptions && validationDescriptions.length > 0 ? (
        <SmallHint
          paragraphs={validationDescriptions}
          icon={
            <IconValidated
              className={'card-title-validation-icon'}
              fill={'var(--color-warning)'}
            />
          }
          helperClass={'card-title-validation'}
        />
      ) : null}
    </div>
  );
};

interface CardActionsProps {
  action: () => void;
  cta: string;
  active?: boolean;
}

export const CardAction: React.FC<CardActionsProps> = ({
  action,
  cta,
  active,
}) => {
  return (
    <div
      className={'card-action'}
      aria-disabled={!active}
      onClick={!!active ? action : undefined}
    >
      {cta}
    </div>
  );
};

export const InlineCards: React.FC = ({ children }) => {
  return <div className="inlineCards">{children}</div>;
};

export type TabConfig = {
  title: string;
  key: string;
  badge?: BadgeProps;
  error?: boolean;
  progressSafe?: boolean;
};

interface TabsProps {
  tabs: TabConfig[];
  updateProductViewTabs?: (
    array: {
      tabKey: string;
      safe: boolean;
    }[]
  ) => void;
  children?: React.ReactNode;
  look?: 'card' | 'bare';
  noMargin?: boolean;
  parentTab?: string;
  dontSetHash?: boolean;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  updateProductViewTabs,
  children,
  look,
  noMargin,
  parentTab,
  dontSetHash,
}) => {
  const { t } = useTranslation();
  const hash = window.location.hash.toLowerCase().substring(1);
  const [currentTabs, setCurrentTabs] = useState<
    | {
        tab: TabConfig;
        active: boolean;
      }[]
    | null
  >(null);
  const [confirmationPopup, setConfirmationPopup] = useState<number | null>(
    null
  );

  useEffect(() => {
    updateTabs();
  }, [tabs]);

  const updateTabs = () => {
    const tabKeys = tabs.map((t) => t.key);
    const result = tabs.map((tab, i) => {
      if (hash !== '' && parentTab === undefined && tabKeys.includes(hash)) {
        if (hash.includes(tab.key.toLowerCase())) {
          return { tab: tab, active: true };
        } else {
          return { tab: tab, active: false };
        }
      } else {
        const activeTabIndex = currentTabs?.findIndex((t) => t.active);
        if (activeTabIndex !== -1 && activeTabIndex !== undefined) {
          if (i === activeTabIndex) {
            return { tab: tab, active: true };
          } else {
            return { tab: tab, active: false };
          }
        } else if (i === 0) {
          return { tab: tab, active: true };
        } else {
          return { tab: tab, active: false };
        }
      }
    });
    setCurrentTabs([...result]);
  };

  const selectTab = (i: number) => {
    if (currentTabs) {
      const currentTab = currentTabs.find((t) => t.active);
      if (
        currentTab &&
        (currentTab.tab.progressSafe === true ||
          currentTab.tab.progressSafe === undefined)
      ) {
        changeTab(i);
      } else {
        setConfirmationPopup(i);
      }
    }
  };

  const replaceHash = (hash: string) => {
    window.location.replace(('' + window.location).split('#')[0] + '#' + hash);
  };

  const changeTab = (i: number) => {
    if (currentTabs) {
      const update = currentTabs;
      // deselect all tabs
      for (var j = 0; j < update.length; j++) {
        update[j] = { ...update[j], active: false };
      }
      // select the new tab
      update[i] = { ...update[i], active: true };
      setCurrentTabs([...update]);

      if (!dontSetHash) {
        if (parentTab) {
          replaceHash(parentTab + '#' + update[i].tab.key.toLowerCase());
        } else {
          replaceHash(update[i].tab.key.toLowerCase());
        }
      }

      if (updateProductViewTabs) {
        updateProductViewTabs([{ tabKey: update[i].tab.key, safe: true }]);
      }
    }
  };

  return (
    <>
      {tabs.length > 1 ? (
        <div
          className={`card-tabs ${
            look === 'card' ? 'card-tabs-cardStyle' : ''
          } ${noMargin ? 'card-tabs-noMargin' : ''}`}
          id={'tabs'}
        >
          {currentTabs?.map((tab, i) => {
            let className = 'card-tabs-tab';
            if (tab.active) {
              if (tab.tab.error) {
                className += ' card-tabs-tab-active-error';
              } else {
                className += ' card-tabs-tab-active';
              }
            }
            if (tab.tab.error) {
              className += ' card-tabs-tab-error';
            }
            return (
              <div
                key={i}
                id={'tab' + i}
                className={className}
                onClick={() => selectTab(i)}
              >
                <div className="card-tabs-tab-title">{tab.tab.title}</div>
                {tab.tab.badge ? (
                  <div className={'card-tabs-tab-badge'}>
                    <Badge
                      title={tab.tab.badge.title}
                      color={tab.tab.badge.color}
                    />
                  </div>
                ) : null}
                {tab.tab.error ? (
                  <ErrorIcon
                    fill={'var(--color-danger)'}
                    className="card-tabs-tab-errorIcon"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
      {React.Children.map(children, (child, i) => {
        const j = currentTabs?.findIndex((entry) => entry.active);
        if (j === i) {
          return child;
        }
      })}
      <Popup
        width={'30%'}
        toggled={!!confirmationPopup}
        close={() => setConfirmationPopup(null)}
      >
        <InformationBox
          title={t('components.tabs.confirmationWarning.title')}
          content={t('components.tabs.confirmationWarning.text')}
          type="warning"
        />
        <Button
          cta={t('components.tabs.confirmationWarning.cta2')}
          action={() => {
            if (confirmationPopup) {
              changeTab(confirmationPopup);
              setConfirmationPopup(null);
            }
          }}
          look="secondary-danger"
          helperCSSClass="card-tabs-popup-cta"
        />
        <Button
          cta={t('components.tabs.confirmationWarning.cta1')}
          action={() => setConfirmationPopup(null)}
          look={'secondary'}
        />
      </Popup>
    </>
  );
};

interface TabProps {
  neverRemember?: boolean;
}

export const Tab: React.FC<TabProps> = ({ children, neverRemember }) => {
  return (
    <div key={neverRemember ? Math.random() : undefined} className="card-tab">
      {children}
    </div>
  );
};

// this errors the tab in a component and reports it to the parent component via callback
export const setErrorTab = (
  initialTabs: TabConfig[],
  setTabs: (tabs: SetStateAction<TabConfig[]>) => void,
  key: string,
  hasError?: boolean | undefined,
  errorCallback?: () => void | undefined
) => {
  if (!initialTabs.find((t) => t.key === key)?.error) {
    const update = [...initialTabs];
    const i = update.findIndex((tab) => tab.key === key);
    if (i !== -1) {
      update[i] = {
        ...update[i],
        error: hasError !== undefined ? hasError : true,
      };
      console.log(update);
      setTabs(update);
    }
    if (errorCallback) {
      errorCallback();
    }
  }
};

export const checkForErrors = (
  keysToCheckFor: string[],
  errors: Error[],
  tabs: TabConfig[],
  tabKey: string,
  setTabs: (tabs: SetStateAction<TabConfig[]>) => void,
  errorCallback?: () => void | undefined
) => {
  const hasError = errors.find((e) => keysToCheckFor.includes(e.key));
  if (hasError) {
    setErrorTab(tabs, setTabs, tabKey, true, errorCallback);
  } else {
    setErrorTab(tabs, setTabs, tabKey, false);
  }
};

// this errors the tab in a component as well as in the parent
// export const alertTabError = (
//   tabs: TabConfig[],
//   setTabs: (tabs: SetStateAction<TabConfig[]>) => void,
//   tabKey: string,
//   errorCallback?: () => void
// ) => {
//   if (!tabs.find((t) => t.key === tabKey)?.error) {
//     setErrorTab(tabs, setTabs, tabKey);
//     if (errorCallback) {
//       errorCallback();
//     }
//   }
// };

export const setMultipleErrorTabs = (
  tabs: TabConfig[],
  setTabs: (tabs: SetStateAction<TabConfig[]>) => void,
  keys: string[]
) => {
  const update = tabs.map((tab) => {
    if (keys.includes(tab.key)) {
      return {
        ...tab,
        error: true,
      };
    } else {
      return {
        ...tab,
        error: false,
      };
    }
  });
  setTabs(update);
  return update;
};
