import './filebrowser.css';
import { useState, useEffect } from 'react';
import { useAuthorization, Has } from '../../contexts/auth/Authorization';
import {
  AssetFolderResponse,
  CreateAssetFolderRequest,
  AssetResponse,
} from '../../api/petcloudapi/api';
import Popup from '../popup/Popup';
import { useTranslation } from 'react-i18next';
import Input from '../input/Input';
import Button from '../button/Button';
import { ReactComponent as Folder } from '../../../assets/icon/folder_browser.svg';
import back from '../../../assets/icon/left-arrow.svg';
import ItemActions from '../itemactions/ItemActions';
import arrow from '../../../assets/icon/arrow_dropdown.svg';
import home from '../../../assets/icon/start.svg';
import { ReactComponent as Microphone } from '../../../assets/icon/microphone.svg';
import { ReactComponent as Doc } from '../../../assets/icon/document.svg';
import { ReactComponent as Video } from '../../../assets/icon/video.svg';
import { ReactComponent as Unknown } from '../../../assets/icon/unknown.svg';
import { ReactComponent as IconDownload } from '../../../assets/icon/download.svg';
import { ReactComponent as IconSelect } from '../../../assets/icon/check.svg';
import { ReactComponent as IconMove } from '../../../assets/icon/sidebar/exchange.svg';
import { ReactComponent as IconTrash } from '../../../assets/icon/trash.svg';
import { Check } from '../selectors/Selectors';
import Breadcrumbs, { Breadcrumb } from '../breadcrumbs/Breadcrumbs';
import { Dropzone } from '../dropzone/Dropzone';
import { NoAuthorization } from '../emptystate/EmptyState';
import { Store } from 'react-notifications-component';
import { useUser } from '../../contexts/auth/User';
import useFileDownload from '../../hooks/useFileDownload';

interface FileBrowserProps {
  assetFolders: AssetFolderResponse[];
  allowUrlNavigation: boolean;
  createNewFolder?: (payload: CreateAssetFolderRequest) => void;
  deleteFolder?: (id: string) => Promise<unknown>;
  moveFolder?: (
    folder: AssetFolderResponse,
    newParentId: string
  ) => Promise<unknown>;
  deleteAsset?: (id: string) => Promise<unknown>;
  moveAsset?: (asset: AssetResponse, newParentId: string) => Promise<unknown>;
  reloadAssetFolders?: () => void;
  onChooseAssets?: (assets: AssetResponse[]) => void;
  allowAssetUpload?: boolean;
}

const FileBrowser: React.FC<FileBrowserProps> = ({
  assetFolders,
  allowUrlNavigation,
  createNewFolder,
  deleteFolder,
  moveFolder,
  deleteAsset,
  moveAsset,
  reloadAssetFolders,
  onChooseAssets,
  allowAssetUpload,
}) => {
  const { t } = useTranslation();
  const { authorizations } = useAuthorization();
  const { downloadZippedAssets } = useFileDownload();
  const { user } = useUser();
  const indexFolder =
    assetFolders.find((af) => {
      if (user?.isProductOwner) {
        return af.isRoot;
      } else {
        return af.isRoot && !af.manufacturerId;
      }
    }) ?? assetFolders[0];
  const [currentFolder, setCurrentFolder] = useState(indexFolder);
  const [currentChildrenFolders, setCurrentChildrenFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [newFolderPopup, setNewFolderPopup] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string | null>(null);
  const [newFolderDescription, setNewFolderDescription] = useState<
    string | null
  >(null);
  const [folderToMove, setFolderToMove] = useState<AssetFolderResponse | null>(
    null
  );
  const [foldersToMove, setFoldersToMove] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [browsedFolders, setBrowsedFolders] = useState<AssetFolderResponse[]>(
    []
  );
  const [moveTargetFolder, setMoveTargetFolder] =
    useState<AssetFolderResponse | null>(null);
  const [assetToMove, setAssetToMove] = useState<AssetResponse | null>(null);
  const [assetsToMove, setAssetsToMove] = useState<AssetResponse[] | null>(
    null
  );
  const [folderCrumbs, setFolderCrumbs] = useState<Breadcrumb[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<AssetFolderResponse[]>(
    []
  );
  const [selectedAssets, setSelectedAssets] = useState<AssetResponse[]>([]);
  const [assetUploadPopup, setAssetUploadPopup] = useState(false);

  // init
  useEffect(() => {
    setCurrentChildrenFolders(getChildrenFolders(currentFolder.id));
    if (allowUrlNavigation) {
      checkURLForFolder();
    }
  }, [assetFolders, currentFolder]);

  useEffect(() => {
    getFolderCrumbs();
  }, [currentFolder]);

  const checkURLForFolder = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('folder')) {
      const folder = assetFolders.find(
        (folder) => folder.id === urlParams.get('folder')
      );
      if (folder) {
        setCurrentFolder(folder);
      }
    }
  };

  // create breadcrumbs navigation
  const getFolderCrumbs = () => {
    if (currentFolder) {
      const newCrumbs = [
        {
          id: currentFolder.id,
          title: currentFolder.isRoot ? 'Home' : currentFolder.name,
        },
      ];
      const c = getParentCrumbs(currentFolder, newCrumbs);
      if (c) {
        setFolderCrumbs(c);
      }
    }
  };

  const getParentCrumbs = (
    currentFolder: AssetFolderResponse,
    currentCrumbs: Breadcrumb[]
  ) => {
    if (currentFolder.parentId) {
      const parentFolder = assetFolders.find(
        (folder) => folder.id === currentFolder.parentId
      );
      if (parentFolder) {
        currentCrumbs.unshift({
          id: parentFolder.id,
          title: parentFolder.isRoot ? 'Home' : parentFolder.name,
        });
        getParentCrumbs(parentFolder, currentCrumbs);
      }
    }
    return currentCrumbs;
  };

  // get all children folders for one folder
  const getChildrenFolders = (parentId: string) => {
    const childrenFolders: AssetFolderResponse[] = [];
    assetFolders.forEach((folder) => {
      if (folder.parentId === parentId) {
        childrenFolders.push(folder);
      }
    });
    return childrenFolders;
  };

  // navigate to Folder
  const moveToFolder = (folderId: string | null) => {
    if (folderId) {
      const newFolder = assetFolders.find((folder) => folder.id === folderId);
      if (newFolder) {
        setCurrentFolder(newFolder);
        setSelectedAssets([]);
        setSelectedFolders([]);
        if (allowUrlNavigation) {
          const url = new URL(window.location.href);
          url.searchParams.set('folder', newFolder.id);
          window.history.replaceState(null, 'Folder', url);
        }
      }
    }
  };

  // create new folder
  const submitNewFolder = () => {
    if (newFolderName && createNewFolder) {
      createNewFolder({
        name: newFolderName,
        description: newFolderDescription,
        parentId: currentFolder.id,
      });
      setNewFolderPopup(false);
      setNewFolderName(null);
      setNewFolderDescription(null);
    }
  };

  // FOLDERS multiple select actions
  const selectFolder = (folder: AssetFolderResponse) => {
    const update = selectedFolders;
    if (selectedFolders.includes(folder)) {
      const i = update.findIndex((f) => f === folder);
      update.splice(i, 1);
      setSelectedFolders([...update]);
    } else {
      update.push(folder);
      setSelectedFolders([...update]);
    }
  };

  const deleteSelectedFolders = () => {
    const promises = selectedFolders.map(async (folder) => {
      if (!folder.isRestricted && deleteFolder) {
        return await deleteFolder(folder.id);
      }
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t('view.assets.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        if (reloadAssetFolders) {
          reloadAssetFolders();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveSelectedFolders = (targetFolder: AssetFolderResponse) => {
    const promises = selectedFolders.map(async (folder) => {
      if (moveTargetFolder && moveFolder) {
        return await moveFolder(folder, targetFolder.id);
      }
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t('view.assets.notifications.move_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        if (reloadAssetFolders) {
          reloadAssetFolders();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ASSETS multiple select actions
  const selectAsset = (asset: AssetResponse) => {
    const update = selectedAssets;
    if (selectedAssets.includes(asset)) {
      const i = update.findIndex((a) => a === asset);
      update.splice(i, 1);
      setSelectedAssets([...update]);
    } else {
      update.push(asset);
      setSelectedAssets([...update]);
    }
  };

  const selectAllAssets = () => {
    setSelectedAssets([...currentFolder.assets]);
  };

  const deleteSelectedAssets = () => {
    const promises = selectedAssets.map(async (asset) => {
      if (deleteAsset) {
        return await deleteAsset(asset.id);
      }
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t('view.assets.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        if (reloadAssetFolders) {
          reloadAssetFolders();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveSelectedAssets = (targetFolder: AssetFolderResponse) => {
    const promises = selectedAssets.map(async (asset) => {
      if (moveTargetFolder && moveAsset) {
        return await moveAsset(asset, targetFolder.id);
      }
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t('view.assets.notifications.move_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        if (reloadAssetFolders) {
          reloadAssetFolders();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // render structure for move popup naviation tree
  const renderFolderStructure = (parentId: string) => {
    if (assetFolders.length > 0) {
      return assetFolders.map((folder, i) => {
        if (folder.parentId === parentId) {
          return (
            <div
              key={i}
              className="filebrowser-moveFolderPopup-hierarchy-element"
            >
              <div
                className="filebrowser-moveFolderPopup-hierarchy-element-item"
                onClick={() => {
                  const newBrowsedFolders = browsedFolders;
                  if (newBrowsedFolders.includes(folder)) {
                    const i = newBrowsedFolders.indexOf(folder);
                    newBrowsedFolders.splice(i, 1);
                    setBrowsedFolders([...newBrowsedFolders]);
                    console.log('removed folder');
                  } else {
                    newBrowsedFolders.push(folder);
                    setBrowsedFolders([...newBrowsedFolders]);
                    console.log('added folder');
                  }
                }}
              >
                <img
                  src={arrow}
                  style={{
                    rotate: browsedFolders.includes(folder)
                      ? 'rotate(0)'
                      : 'rotate(0.75turn)',
                  }}
                  className="filebrowser-moveFolderPopup-hierarchy-element-item-arrow"
                  alt={'toggle icon'}
                />
                <div
                  className="filebrowser-moveFolderPopup-hierarchy-element-item-check"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMoveTargetFolder(folder);
                  }}
                >
                  <Check checked={moveTargetFolder === folder} />
                </div>
                <div className="filebrowser-moveFolderPopup-hierarchy-element-item-name">
                  {folder.name}
                </div>
              </div>
              <div
                style={{
                  display: browsedFolders.includes(folder) ? 'block' : 'none',
                }}
                className="filebrowser-moveFolderPopup-hierarchy-element-children"
              >
                {renderFolderStructure(folder.id)}
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    }
  };

  // render new folde popup
  const renderNewFolderPopup = () => {
    return (
      <Popup
        toggled={newFolderPopup}
        width={'30%'}
        close={() => setNewFolderPopup(false)}
      >
        <div className="popup-title">
          {t('view.assets.newFolderPopup.title')}
        </div>
        <Input
          update={(value) => setNewFolderName(value)}
          content={newFolderName ? newFolderName : ''}
          title={t('view.assets.newFolderPopup.name')}
        />
        <Input
          update={(value) => setNewFolderDescription(value)}
          content={newFolderDescription ? newFolderDescription : ''}
          title={t('view.assets.newFolderPopup.description')}
        />
        <div className="filebrowser-newFolderPopup-actions">
          <Button
            active={!!newFolderName}
            cta={t('view.assets.newFolderPopup.cta')}
            action={submitNewFolder}
            look={'save'}
            width="minimal"
          />
        </div>
      </Popup>
    );
  };

  // render folder move popup
  const renderFolderMovePopup = () => {
    if (moveFolder) {
      return (
        <Popup
          toggled={!!folderToMove}
          width="40%"
          close={() => {
            setFolderToMove(null);
          }}
        >
          <div className="popup-title">
            {t('view.assets.moveFolderPopup.title')}
          </div>
          <div className="filebrowser-moveFolderPopup-hierarchy">
            {renderFolderStructure(indexFolder.id)}
          </div>
          <div className="global-cardActions">
            <Button
              active={!!(folderToMove && moveTargetFolder)}
              cta={t('view.assets.moveFolderPopup.cta')}
              look="primary"
              action={() => {
                if (folderToMove && moveTargetFolder) {
                  moveFolder(folderToMove, moveTargetFolder.id).then(() => {
                    if (reloadAssetFolders) {
                      reloadAssetFolders();
                    }
                  });
                }
              }}
              width="minimal"
              margin="right"
            />
            <Button
              cta={t('view.assets.moveFolderPopup.home')}
              look={'secondary'}
              action={() => {
                if (folderToMove) {
                  moveFolder(folderToMove, indexFolder.id).then(() => {
                    if (reloadAssetFolders) {
                      reloadAssetFolders();
                    }
                  });
                }
              }}
              width="minimal"
            />
          </div>
        </Popup>
      );
    } else {
      return null;
    }
  };

  // render asset move popup
  const renderAssetMovePopup = () => {
    if (moveAsset) {
      return (
        <Popup
          toggled={!!assetToMove}
          width="40%"
          close={() => {
            setAssetToMove(null);
          }}
        >
          <div className="popup-title">
            {t('view.assets.moveAssetPopup.title')}
          </div>
          <div className="filebrowser-moveFolderPopup-hierarchy">
            {renderFolderStructure(indexFolder.id)}
          </div>
          <div className="global-cardActions">
            <Button
              active={!!(assetToMove && moveTargetFolder)}
              cta={t('view.assets.moveAssetPopup.cta')}
              look="primary"
              action={() => {
                if (assetToMove && moveTargetFolder) {
                  moveAsset(assetToMove, moveTargetFolder.id).then(() => {
                    if (reloadAssetFolders) {
                      reloadAssetFolders();
                    }
                  });
                }
              }}
              width="minimal"
              margin="right"
            />
            <Button
              cta={t('view.assets.moveAssetPopup.home')}
              look={'secondary'}
              action={() => {
                if (assetToMove) {
                  moveAsset(assetToMove, indexFolder.id).then(() => {
                    if (reloadAssetFolders) {
                      reloadAssetFolders();
                    }
                  });
                }
              }}
              width="minimal"
            />
          </div>
        </Popup>
      );
    } else {
      return null;
    }
  };

  // render selection move popup
  const renderSelectionMovePopup = () => {
    return (
      <Popup
        toggled={!!(assetsToMove || foldersToMove)}
        width="40%"
        close={() => {
          setAssetsToMove(null);
          setFoldersToMove(null);
        }}
      >
        <div className="popup-title">
          {t('view.assets.moveAssetPopup.title')}
        </div>
        <div className="filebrowser-moveFolderPopup-hierarchy">
          {renderFolderStructure(indexFolder.id)}
        </div>
        <div className="global-cardActions">
          <Button
            active={!!((assetsToMove || foldersToMove) && moveTargetFolder)}
            cta={t('view.assets.moveAssetPopup.cta')}
            look="primary"
            action={() => {
              if ((assetsToMove || foldersToMove) && moveTargetFolder) {
                moveSelectedAssets(moveTargetFolder);
                moveSelectedFolders(moveTargetFolder);
              }
            }}
            width="minimal"
            margin="right"
          />
          <Button
            cta={t('view.assets.moveAssetPopup.home')}
            look={'secondary'}
            action={() => {
              if (assetsToMove || foldersToMove) {
                moveSelectedAssets(indexFolder);
                moveSelectedFolders(indexFolder);
              }
            }}
            width="minimal"
          />
        </div>
      </Popup>
    );
  };

  // render asset upload popup
  const renderAssetUploadPopup = () => {
    if (reloadAssetFolders) {
      return (
        <Popup
          toggled={assetUploadPopup}
          width="30%"
          close={() => setAssetUploadPopup(false)}
        >
          <div className="popup-title">
            {t('view.assets.assetUploadPopup.title')}
          </div>
          <Dropzone
            maxFiles={-1}
            callback={() => {
              setAssetUploadPopup(false);
              reloadAssetFolders();
            }}
            assetContext="User"
            assetFolderId={currentFolder.id}
          />
        </Popup>
      );
    } else {
      return null;
    }
  };

  // render component
  if (
    authorizations?.includes('assets:list') &&
    authorizations?.includes('asset_folders:list')
  ) {
    return (
      <div className="filebrowser">
        <div className="filebrowser-nav">
          <div className="filebrowser-nav-element">
            <Button
              action={() =>
                moveToFolder(
                  currentFolder.parentId ? currentFolder.parentId : null
                )
              }
              look={'secondary'}
              type="icon"
            >
              <img src={back} alt="Back" className="button-icon" />
            </Button>
          </div>
          <div className="filebrowser-nav-element">
            <Button
              action={() => moveToFolder(indexFolder.id)}
              look={'secondary'}
              type="icon"
            >
              <img src={home} alt="Home" className="button-icon" />
            </Button>
          </div>
          {createNewFolder ? (
            <Has authorizations={['asset_folders:create']}>
              <div className="filebrowser-nav-element">
                <Button
                  cta={t('view.assets.new_folder')}
                  action={() => setNewFolderPopup(true)}
                  look={'secondary'}
                />
              </div>
            </Has>
          ) : null}
          {allowAssetUpload ? (
            <Has authorizations={['assets:create']}>
              <div className="filebrowser-nav-element">
                <Button
                  cta={t('view.assets.new_file')}
                  action={() => setAssetUploadPopup(true)}
                  look={'secondary'}
                />
              </div>
            </Has>
          ) : null}
        </div>
        <Breadcrumbs crumbs={folderCrumbs} navigate={moveToFolder} />
        <div className="filebrowser-spacer"></div>
        <div className="filebrowser-stage">
          <div className="filebrowser-stage-folders">
            {currentChildrenFolders?.map((child, i) => {
              return (
                <FileBrowserFolder
                  key={i}
                  folder={child}
                  selectedFolders={selectedFolders}
                  selectFolder={selectFolder}
                  deleteFolder={deleteFolder}
                  setFolderToMove={setFolderToMove}
                  movingAllowed={!!moveFolder}
                  reloadAssetFolders={reloadAssetFolders}
                >
                  <div
                    className="filebrowser-stage-folder"
                    onClick={() => moveToFolder(child.id)}
                  >
                    <Folder
                      fill={'var(--color-gray_light)'}
                      className="filebrowser-stage-folder-icon"
                    />
                    <div className="filebrowser-stage-folder-name">
                      {child.name.length <= 15
                        ? child.name
                        : child.name.slice(0, 15) + '...'}
                    </div>
                  </div>
                </FileBrowserFolder>
              );
            })}
          </div>
          {currentFolder.assets.length > 0 ? (
            <div className="filebrowser-stage-assets">
              {currentFolder.assets.map((asset, i) => {
                return (
                  <FileBrowserAsset
                    key={i}
                    asset={asset}
                    selectAsset={selectAsset}
                    selectedAssets={selectedAssets}
                    deleteAsset={deleteAsset}
                    reloadAssetFolders={reloadAssetFolders}
                    setAssetToMove={setAssetToMove}
                  >
                    <div key={i} className="filebrowser-stage-asset">
                      <FileBrowserAssetContent asset={asset} />
                    </div>
                  </FileBrowserAsset>
                );
              })}
            </div>
          ) : null}
        </div>
        {selectedFolders.length > 0 || selectedAssets.length > 0 ? (
          <div className="filebrowser-stage-folders-actions global-cardActions">
            {currentFolder.assets.length > 0 ? (
              <Button
                cta={t('view.assets.actions.select_all')}
                look={'secondary'}
                action={() => {
                  selectAllAssets();
                }}
                margin="right"
                type={'icon-text'}
              >
                <IconSelect
                  fill={'var(--color-text_primary)'}
                  className={'button-icon-tiny'}
                />
              </Button>
            ) : null}
            <Button
              cta={t('actions.download')}
              look={'secondary'}
              action={() => {
                downloadZippedAssets(selectedAssets);
              }}
              margin="right"
              type={'icon-text'}
            >
              <IconDownload
                fill={'var(--color-text_primary)'}
                className={'button-icon'}
              />
            </Button>
            {moveAsset ? (
              <Button
                cta={t('view.assets.actions.move')}
                look={'secondary'}
                action={() => {
                  setFoldersToMove(selectedFolders);
                  setAssetsToMove(selectedAssets);
                }}
                margin="right"
                type={'icon-text'}
              >
                <IconMove
                  fill={'var(--color-text_primary)'}
                  className={'button-icon-tiny'}
                />
              </Button>
            ) : null}
            {deleteAsset ? (
              <Button
                cta={t('view.assets.actions.delete')}
                look="secondary-danger"
                action={() => {
                  deleteSelectedFolders();
                  deleteSelectedAssets();
                }}
                type={'icon-text'}
              >
                <IconTrash
                  fill={'var(--color-danger)'}
                  className={'button-icon-tiny'}
                />
              </Button>
            ) : null}
            {onChooseAssets ? (
              <Button
                cta={t('view.assets.actions.choose')}
                look={'secondary'}
                action={() => {
                  onChooseAssets(selectedAssets);
                }}
              />
            ) : null}
          </div>
        ) : null}
        {renderNewFolderPopup()}
        {renderFolderMovePopup()}
        {renderAssetMovePopup()}
        {renderSelectionMovePopup()}
        {renderAssetUploadPopup()}
      </div>
    );
  } else {
    return <NoAuthorization />;
  }
};

export default FileBrowser;

interface FileBrowserFolderProps {
  folder: AssetFolderResponse;
  selectedFolders: AssetFolderResponse[];
  selectFolder: (folder: AssetFolderResponse) => void;
  deleteFolder?: (id: string) => Promise<unknown>;
  setFolderToMove: (
    value: React.SetStateAction<AssetFolderResponse | null>
  ) => void;
  movingAllowed?: boolean;
  reloadAssetFolders?: () => void;
}

const FileBrowserFolder: React.FC<FileBrowserFolderProps> = ({
  folder,
  selectedFolders,
  selectFolder,
  deleteFolder,
  setFolderToMove,
  movingAllowed,
  children,
  reloadAssetFolders,
}) => {
  const toggleItemActions = () => {
    if (!selectedFolders.includes(folder)) {
      setActions(!actions);
    }
  };
  const [actions, setActions] = useState(false);

  return (
    <div
      className="filebrowser-stage-folder-wrapper"
      onMouseEnter={toggleItemActions}
      onMouseLeave={toggleItemActions}
    >
      <div className={'filebrowser-stage-folder-content'}>{children}</div>
      <div
        className="filebrowser-stage-itemActions"
        style={{ display: actions ? 'flex' : 'none' }}
      >
        <Check
          checked={selectedFolders.includes(folder)}
          update={() => selectFolder(folder)}
        />
        <div className="filebrowser-stage-folder-actions">
          {deleteFolder && movingAllowed ? (
            <ItemActions
              item={folder}
              actions={[
                {
                  cta: 'delete',
                  look: 'danger',
                  action: () =>
                    deleteFolder(folder.id).then(() => {
                      if (reloadAssetFolders) {
                        reloadAssetFolders();
                      }
                    }),
                },
                {
                  cta: 'move',
                  look: 'blue',
                  action: () => setFolderToMove(folder),
                },
              ]}
              iconAlignment="horizontal"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

interface FileBrowserAssetProps {
  asset: AssetResponse;
  selectedAssets: AssetResponse[];
  selectAsset: (asset: AssetResponse) => void;
  deleteAsset?: (id: string) => Promise<unknown>;
  reloadAssetFolders?: () => void;
  setAssetToMove: (value: React.SetStateAction<AssetResponse | null>) => void;
}

const FileBrowserAsset: React.FC<FileBrowserAssetProps> = ({
  asset,
  selectedAssets,
  selectAsset,
  deleteAsset,
  reloadAssetFolders,
  setAssetToMove,
  children,
}) => {
  const toggleItemActions = (value: boolean) => {
    if (!selectedAssets.includes(asset)) {
      setActions(value);
    }
  };
  const [actions, setActions] = useState(false);
  const { downloadAsset } = useFileDownload();

  return (
    <div
      className="filebrowser-stage-asset-wrapper"
      onMouseEnter={() => toggleItemActions(true)}
      onMouseLeave={() => toggleItemActions(false)}
    >
      {children}
      <div
        className="filebrowser-stage-itemActions"
        style={{
          display: actions
            ? 'flex'
            : selectedAssets.includes(asset)
            ? 'flex'
            : 'none',
        }}
      >
        <Check
          checked={selectedAssets.includes(asset)}
          update={() => selectAsset(asset)}
        />
        <div className="filebrowser-stage-asset-actions">
          {deleteAsset && reloadAssetFolders ? (
            <ItemActions
              item={asset}
              actions={[
                {
                  cta: 'delete',
                  look: 'danger',
                  action: async () => {
                    deleteAsset(asset.id).then(() => {
                      if (reloadAssetFolders) {
                        reloadAssetFolders();
                      }
                    });
                  },
                },
                {
                  cta: 'move',
                  look: 'blue',
                  action: () => setAssetToMove(asset),
                },
                {
                  cta: 'download',
                  look: 'blue',
                  action: () => downloadAsset(asset),
                },
              ]}
              iconAlignment="horizontal"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

interface FileBrowserAssetContentProps {
  asset: AssetResponse;
}

const FileBrowserAssetContent: React.FC<FileBrowserAssetContentProps> = ({
  asset,
}) => {
  if (asset.assetType === 'Image') {
    return (
      <div className="filebrowser-stage-asset-content">
        <img
          src={asset.uri}
          alt={asset.fileName}
          className="filebrowser-stage-asset-content-img"
          loading={'lazy'}
        />
        <div className="filebrowser-stage-asset-content-name">{asset.name}</div>
      </div>
    );
  } else if (asset.assetType === 'Video') {
    return (
      <div className="filebrowser-stage-asset-content">
        <Video
          className="filebrowser-stage-asset-content-icon"
          fill="var(--color-gray_light)"
        />
        <div className="filebrowser-stage-asset-content-name">{asset.name}</div>
      </div>
    );
  } else if (asset.assetType === 'Audio') {
    return (
      <div className="filebrowser-stage-asset-content">
        <Microphone
          className="filebrowser-stage-asset-content-icon"
          fill="var(--color-gray_light)"
        />
        <div className="filebrowser-stage-asset-content-name">{asset.name}</div>
      </div>
    );
  } else if (asset.assetType === 'Document') {
    return (
      <div className="filebrowser-stage-asset-content">
        <div className="filebrowser-stage-asset-content-icon-wrapper">
          <Doc
            className="filebrowser-stage-asset-content-icon"
            fill="var(--color-gray_light)"
          />
        </div>
        <div className="filebrowser-stage-asset-content-name">{asset.name}</div>
      </div>
    );
  } else if (asset.assetType === 'Unknown') {
    return (
      <div className="filebrowser-stage-asset-content">
        <div className="filebrowser-stage-asset-content-icon-wrapper">
          <Unknown
            className="filebrowser-stage-asset-content-icon"
            fill="var(--color-gray_light)"
          />
        </div>
        <div className="filebrowser-stage-asset-content-name">{asset.name}</div>
      </div>
    );
  } else {
    return null;
  }
};
