import './loading.css';
import { ReactComponent as Refresh } from '../../../assets/icon/refresh.svg';

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const MinimalLoading = () => {
  return (
    <div className="minimalLoading">
      <div className="minimalLoading-square minimalLoading-square-one"></div>
      <div className="minimalLoading-square minimalLoading-square-two"></div>
      <div className="minimalLoading-square minimalLoading-square-four"></div>
      <div className="minimalLoading-square minimalLoading-square-three"></div>
    </div>
  );
};

interface LoadingContainerProps {
  message?: string;
}

export const LoadingContainer: React.FC<LoadingContainerProps> = ({
  message,
}) => {
  return (
    <div className="loading-container">
      <Loading />
      {message ? (
        <div className="loading-container-message">{message}</div>
      ) : null}
    </div>
  );
};

interface SpinnerProps {
  isLoading: boolean;
  alwaysShow?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ isLoading, alwaysShow }) => {
  if (alwaysShow || isLoading) {
    return (
      <div className="spinner-wrapper">
        <div
          className="spinner"
          style={{
            borderColor: isLoading
              ? 'var(--color-primary)'
              : 'var(--color-border)',
          }}
        >
          <Refresh
            fill={isLoading ? 'var(--color-primary)' : 'var(--color-border)'}
            className={
              isLoading ? 'spinner-icon spinner-icon-loading' : 'spinner-icon'
            }
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Loading;
