import './columnheaderconfigprofiles.css';
import { useEffect, useState } from 'react';
import Button from '../../../../../elements/button/Button';
import { ReactComponent as IconSettings } from '../../../../../../assets/icon/settings.svg';
import { ReactComponent as IconLoad } from '../../../../../../assets/icon/reload.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icon/trash.svg';
import Popup from '../../../../../elements/popup/Popup';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import {
  ManufacturerSettingKey,
  UpsertManufacturerSettingRequest,
} from '../../../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../../../elements/loading/Loading';
import { Check } from '../../../../../elements/selectors/Selectors';
import { EmptyState } from '../../../../../elements/emptystate/EmptyState';
import { ColumnHeaderMapping } from '../utils/useDefaultMappings';
import { useUser } from '../../../../../contexts/auth/User';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import Input from '../../../../../elements/input/Input';
import { SheetData } from '../../ProductImporter';
import useDateTools from '../../../../../hooks/useDateTools';
import useManufacturerOptions from '../../../../../hooks/useManufacturerOptions';
import DropdownMenu from '../../../../../elements/dropdownmenu/DropdownMenu';
import Badge from '../../../../../elements/badge/Badge';

interface ColumnHeaderConfigProfilesProps {
  currentMappings: ColumnHeaderMapping[];
  setCurrentMappings: (mappings: ColumnHeaderMapping[]) => void;
  sheetData: SheetData;
}

const ColumnHeaderConfigProfiles: React.FC<ColumnHeaderConfigProfilesProps> = ({
  currentMappings,
  setCurrentMappings,
  sheetData,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'view.productBulkEditor.productImporter.columnHeaderConfig.profiles',
  });
  const api = usePetCloudApi();
  const manufacturerSettingsApi = api.manufacturerSettingsApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const { pushNotification } = useNotifications();
  const { displayReadableDate } = useDateTools();
  const { manufacturerOptions } = useManufacturerOptions();

  const [mappingConfigs, setMappingConfigs] = useState<
    SavableColumHeaderConfig[] | null
  >(null);
  const [popup, setPopup] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const [selectedConfig, setSelectedConfig] =
    useState<SavableColumHeaderConfig | null>(null);
  const [configToDelete, setConfigToDelete] =
    useState<SavableColumHeaderConfig | null>(null);
  const [configToUpdate, setConfigToUpdate] =
    useState<SavableColumHeaderConfig | null>(null);
  const [newConfigName, setNewConfigName] = useState('');
  const [nameValidationErrors, setNameValidationErrors] = useState<
    string[] | null
  >(null);
  const [selectedManufacturerId, setSelectedManufacturer] = useState<
    string | null
  >(user?.manufacturerId ?? null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getMappingConfigs();
  }, [selectedManufacturerId]);

  const getMappingConfigs = () => {
    manufacturerSettingsApi
      .manufacturerSettingsGetManufacturerSettings()
      .then((response) => {
        console.log(response);
        const setting = response.data.find(
          (x) =>
            x.key === ManufacturerSettingKey.BulkEditorImportDataMappings &&
            x.manufacturerId === selectedManufacturerId
        );
        if (setting) {
          setMappingConfigs(JSON.parse(setting.value));
        } else {
          setMappingConfigs([]);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getSerializedConfigsPayload = (
    newConfigName?: string,
    overrideId?: string,
    deleteId?: string
  ) => {
    const configArray = mappingConfigs ?? [];
    const idIndex = configArray.findIndex((x) => x.id === overrideId);
    if (!deleteId) {
      const newConfig: SavableColumHeaderConfig = {
        id: crypto.randomUUID(),
        createdAt: new Date().toUTCString(),
        name: newConfigName ?? configArray[idIndex].name,
        idKey: sheetData.idKey,
        parentIdKey: sheetData.parentIdKey,
        mappings: currentMappings.map((m) => ({
          key: m.key,
          mappedKey: m.mappedKey,
          options: m.options,
        })),
      };
      if (overrideId) {
        //override at index
        if (idIndex !== -1) {
          configArray[idIndex] = {
            ...newConfig,
            id: configArray[idIndex].id,
          };
        }
      } else {
        //add as new config
        configArray.unshift(newConfig);
      }
    } else {
      //delete
      configArray.splice(idIndex, 1);
    }
    const serialized = JSON.stringify(configArray);
    const payload: UpsertManufacturerSettingRequest = {
      manufacturerId: selectedManufacturerId ?? 'error',
      key: ManufacturerSettingKey.BulkEditorImportDataMappings,
      value: serialized,
    };
    return payload;
  };

  const saveCurrentAsNew = (name: string) => {
    if (name.length > 3) {
      setIsSubmitting(true);
      const payload = getSerializedConfigsPayload(name);
      manufacturerSettingsApi
        .manufacturerSettingsUpsertManufacturerSetting(payload)
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.save_success'));
          getMappingConfigs();
          setIsSubmitting(false);
          setNewConfigName('');
          setSavePopup(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    } else {
      setNameValidationErrors([t('notifications.nameTooShort')]);
    }
  };

  const updateConfig = (config: SavableColumHeaderConfig) => {
    setIsSubmitting(true);
    const payload = getSerializedConfigsPayload(undefined, config.id);
    manufacturerSettingsApi
      .manufacturerSettingsUpsertManufacturerSetting(payload)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_success'));
        getMappingConfigs();
        setIsSubmitting(false);
        setConfigToUpdate(null);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const deleteConfig = (config: SavableColumHeaderConfig) => {
    setIsSubmitting(true);
    const payload = getSerializedConfigsPayload(
      undefined,
      undefined,
      config.id
    );
    manufacturerSettingsApi
      .manufacturerSettingsUpsertManufacturerSetting(payload)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.delete_success'));
        getMappingConfigs();
        setConfigToDelete(null);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const loadConfig = (config: SavableColumHeaderConfig) => {
    const mappings = currentMappings.map((mapping) => {
      const savedMapping = config.mappings.find((m) => m.key === mapping.key);
      if (savedMapping) {
        // if there is a saved mapping and the current import has a header named like it
        if (savedMapping.mappedKey) {
          if (sheetData.headers.includes(savedMapping.mappedKey)) {
            return {
              ...mapping,
              mappedKey: savedMapping.mappedKey,
              options: {
                ...mapping.options,
                ...savedMapping.options,
              },
            };
          } else {
            pushNotification(
              `${t('notifications.missingHeader_1')} [${
                savedMapping.mappedKey
              }] ${t('notifications.missingHeader_2')}`,
              'warning'
            );
            return {
              ...mapping,
              mappedKey: undefined,
              error: t('missingHeader'),
            };
          }
        } else {
          return mapping;
        }
      } else {
        return mapping;
      }
    });
    setCurrentMappings(mappings);
    setPopup(false);
  };

  return (
    <div className={'columnHeaderConfigProfiles'}>
      <Button
        type={'icon'}
        width={'minimal'}
        look={'secondary'}
        action={() => setPopup(true)}
      >
        <IconSettings
          className={'button-icon columnHeaderConfigProfiles-buttonIcon'}
        />
      </Button>
      <Popup toggled={popup} width={'40%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('title')}</div>
        {user?.isProductOwner &&
        manufacturerOptions &&
        manufacturerOptions !== 'NO_PERMISSION' ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <DropdownMenu
                title={t('savePopup.manufacturer')}
                optionObjects={manufacturerOptions}
                selected={
                  manufacturerOptions.find(
                    (x) => x.id === selectedManufacturerId
                  )?.name
                }
                onSelect={(name, id) => {
                  const manufacturer = manufacturerOptions.find(
                    (m) => m.id === id
                  );
                  if (manufacturer) {
                    setSelectedManufacturer(manufacturer.id);
                  }
                }}
              />
            </div>
          </div>
        ) : null}
        {mappingConfigs ? (
          <>
            <div className={'columnHeaderConfigProfiles-profiles'}>
              {mappingConfigs.length > 0 ? (
                mappingConfigs.map((config) => {
                  return (
                    <div
                      className={'columnHeaderConfigProfiles-profile'}
                      onClick={() => loadConfig(config)}
                    >
                      <div
                        className={'columnHeaderConfigProfiles-profile-head'}
                      >
                        <Check
                          checked={selectedConfig === config}
                          update={() =>
                            !selectedConfig
                              ? setSelectedConfig(config)
                              : setSelectedConfig(null)
                          }
                        />
                        <div
                          className={'columnHeaderConfigProfiles-profile-title'}
                        >
                          {config.name}
                        </div>
                        <div
                          className={
                            'columnHeaderConfigProfiles-profile-createdAt'
                          }
                        >
                          <Badge
                            title={displayReadableDate(config.createdAt)}
                            color={'var(--color-text_secondary)'}
                          />
                        </div>
                      </div>
                      <div
                        className={'columnHeaderConfigProfiles-profile-actions'}
                      >
                        <Button
                          type={'icon'}
                          look={'secondary'}
                          width={'minimal'}
                          action={() => loadConfig(config)}
                        >
                          <IconLoad className={'button-icon'} />
                        </Button>
                        <Button
                          type={'icon'}
                          look={'secondary-danger'}
                          width={'minimal'}
                          action={() => setConfigToDelete(config)}
                        >
                          <IconTrash
                            className={'button-icon button-icon-danger'}
                          />
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyState message={t('empty')} />
              )}
            </div>
            {configToUpdate ? (
              <Popup
                toggled={true}
                width={'30%'}
                close={() => setConfigToUpdate(null)}
              >
                <div className={'popup-title'}>{t('updatePopup.title')}</div>
                <div
                  className={
                    'global-textElement columnHeaderConfigProfiles-popupText'
                  }
                >
                  {t('updatePopup.text')}
                </div>
                <Button
                  cta={t('updatePopup.cta')}
                  isLoading={isSubmitting}
                  width={'full'}
                  look={'save'}
                  action={() => updateConfig(configToUpdate)}
                  helperCSSClass={'columnHeaderConfigProfiles-button'}
                />
              </Popup>
            ) : null}
            {configToDelete ? (
              <Popup
                toggled={true}
                width={'30%'}
                close={() => setConfigToDelete(null)}
              >
                <div className={'popup-title'}>{t('deletePopup.title')}</div>
                <div
                  className={
                    'global-textElement columnHeaderConfigProfiles-popupText'
                  }
                >
                  {t('deletePopup.text')}
                </div>
                <Button
                  cta={t('deletePopup.cta')}
                  isLoading={isSubmitting}
                  width={'full'}
                  look={'danger'}
                  action={() => deleteConfig(configToDelete)}
                  helperCSSClass={'columnHeaderConfigProfiles-button'}
                />
              </Popup>
            ) : null}
            <div
              className={
                'global-cardActions global-cardActions-margin global-cardActions-spaceBetween'
              }
            >
              <Button
                cta={t('cta.save_new')}
                look={'secondary'}
                width={'minimal'}
                action={() => setSavePopup(true)}
              />
              <Button
                cta={t('cta.save')}
                look={'secondary'}
                width={'minimal'}
                action={
                  selectedConfig
                    ? () => setConfigToUpdate(selectedConfig)
                    : undefined
                }
                active={!!selectedConfig}
              />
            </div>
          </>
        ) : (
          <LoadingContainer />
        )}
        <Popup
          toggled={savePopup}
          width={'30%'}
          close={() => setSavePopup(false)}
        >
          <div className={'popup-title'}>{t('savePopup.title')}</div>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('savePopup.name')}
                content={newConfigName}
                update={(n) => setNewConfigName(n)}
                errors={nameValidationErrors}
              />
            </div>
          </div>
          <div className={'global-cardActions'}>
            <Button
              cta={t('savePopup.cta')}
              width={'minimal'}
              look={'save'}
              action={() => saveCurrentAsNew(newConfigName)}
              active={!!newConfigName}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      </Popup>
    </div>
  );
};

export default ColumnHeaderConfigProfiles;

export type SavableColumHeaderConfig = {
  id: string;
  name: string;
  idKey?: string;
  parentIdKey?: string;
  mappings: SavableColumnHeaderMapping[];
  createdAt: string;
};

export type SavableColumnHeaderMapping = {
  key: string;
  mappedKey?: string;
  options?: any;
};
