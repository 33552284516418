import './downloads.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AssetFolderResponse,
  AssetResponse,
  UpsertProductAdditionalInformationDownloadRequest,
} from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import { TabSelector } from '../../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { ReactComponent as File } from '../../../../../assets/icon/document.svg';
import { ReactComponent as Trash } from '../../../../../assets/icon/trash.svg';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import Popup from '../../../../elements/popup/Popup';
import FileBrowser from '../../../../elements/filebrowser/FileBrowser';
import { Store } from 'react-notifications-component';

interface DownloadsProps {
  productId?: string;
  body: UpsertProductAdditionalInformationDownloadRequest;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  getProduct?: () => void;
  closePopup?: () => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  onSave?: (request: any) => void;
  onAdd?: (request: any) => void;
}

type LangGroupedAssets = {
  lang: TranslatedStringIndex;
  assets: AssetResponse[];
};

type LangGroupedAssetIds = {
  lang: TranslatedStringIndex;
  assetIds: string[];
};

const Downloads: React.FC<DownloadsProps> = ({
  productId,
  body,
  queueCriticalAction,
  killCriticalAction,
  getProduct,
  closePopup,
  readonly,
  toggleProductSavingHelpOverlay,
  onSave,
  onAdd,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const assetsApi = api.assetsApi();
  const assetFoldersApi = api.assetFoldersApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const [payload, setPayload] =
    useState<UpsertProductAdditionalInformationDownloadRequest>(body);
  const [selectedLanguage, setSelectedLanguage] = useState('de-DE');
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [assets, setAssets] = useState<LangGroupedAssets[]>([]);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onSave) {
      onSave(payload);
    }
  }, [payload]);

  useEffect(() => {
    getAssets();
    getAssetFolders();
  }, []);

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssets = () => {
    const langGroupedAssetIds: LangGroupedAssetIds[] = [];
    Object.entries(payload.assetIds).forEach(
      ([language, assetIds]: [string, string[]]) => {
        const group: LangGroupedAssetIds = {
          lang: language as TranslatedStringIndex,
          assetIds: assetIds,
        };
        langGroupedAssetIds.push(group);
      }
    );

    if (langGroupedAssetIds.length > 0) {
      setIsLoadingAssets(true);
      const langGroupedAssets = langGroupedAssetIds.map(async (idGroup) => {
        return {
          lang: idGroup.lang,
          assets: await Promise.all(
            idGroup.assetIds.map(async (assetId) => {
              return await getAssetById(assetId);
            })
          ),
        };
      });

      Promise.all(langGroupedAssets)
        .then((responses) => {
          setAssets(responses);
          setIsLoadingAssets(false);
        })
        .catch((error) => {
          errorHandler.addError(error.response);
          setIsLoadingAssets(false);
        });
    }
  };

  const getAssetById = (assetId: string) => {
    return new Promise<AssetResponse>((resolve, reject) => {
      assetsApi
        .assetsGetAssetById(assetId)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const handleAssetUpload = (assetsArray: AssetResponse[]) => {
    const langGroupedAssets = assets;
    const updatedPayload = payload;
    const i = langGroupedAssets.findIndex(
      (group) => group.lang === selectedLanguage
    );
    if (i !== -1) {
      langGroupedAssets[i] = {
        ...langGroupedAssets[i],
        assets: langGroupedAssets[i].assets.concat(assetsArray),
      };
      assetsArray.forEach((asset) => {
        updatedPayload.assetIds[
          selectedLanguage as TranslatedStringIndex
        ]?.push(asset.id);
      });
      setAssets([...langGroupedAssets]);
      setPayload({ ...updatedPayload });
    }
  };

  const removeAsset = (assetId: string) => {
    const langGroupedAssets = assets;
    const updatedPayload = payload;

    const groupIndex = langGroupedAssets.findIndex(
      (group) => group.lang === selectedLanguage
    );
    if (groupIndex !== -1) {
      const update = langGroupedAssets[groupIndex].assets;
      const assetsIndex = update.findIndex((asset) => asset.id === assetId);
      if (assetsIndex !== -1) {
        update.splice(assetsIndex, 1);
        langGroupedAssets[groupIndex] = {
          ...langGroupedAssets[groupIndex],
          assets: update,
        };
      }
    }

    const payloadIndex = payload.assetIds[
      selectedLanguage as TranslatedStringIndex
    ]?.findIndex((id) => id === assetId);
    if (payloadIndex !== undefined && payloadIndex !== -1) {
      updatedPayload.assetIds[
        selectedLanguage as TranslatedStringIndex
      ]?.splice(payloadIndex, 1);
    }

    setAssets([...langGroupedAssets]);
    setPayload({ ...updatedPayload });
  };

  const displayFileSize = (size: number) => {
    if (size.toString().length < 4) {
      return size + 'B';
    } else if (size.toString().length < 7) {
      return (size / 1000).toFixed(1) + ' KB';
    } else if (size.toString().length < 10) {
      return (size / 1000000).toFixed(1) + ' MB';
    } else {
      return (size / 1000000000).toFixed(1) + ' GB';
    }
  };

  const submitDownloads = (
    request: UpsertProductAdditionalInformationDownloadRequest
  ) => {
    if (productId && !onSave && !onAdd) {
      if (queueCriticalAction) {
        queueCriticalAction('updatingDownloads');
      }
      setIsSubmitting(true);
      productsApi
        .productsUpsertProductAdditionalInformationDownload(productId, request)
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          Store.addNotification({
            message: t(
              'view.product.additionalinformation.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (getProduct) {
            getProduct();
          }
          if (killCriticalAction) {
            killCriticalAction('updatingDownloads');
          }
          if (closePopup) {
            closePopup();
          }
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          errorHandler.addError(error.response);
          if (killCriticalAction) {
            killCriticalAction('updatingDownloads');
          }
        });
    } else if (onAdd) {
      onAdd(request);
    }
  };

  return (
    <div className="downloads">
      <TabSelector
        tabs={[
          {
            title: 'de-DE',
            key: 'de-DE',
          },
          {
            title: 'en-GB',
            key: 'en-GB',
          },
        ]}
        activeTabKey={selectedLanguage}
        update={(key: string) => setSelectedLanguage(key)}
      />
      <div className="downloads-assets">
        {isLoadingAssets ? (
          <LoadingContainer />
        ) : (
          assets.map((group) => {
            if (group.lang === selectedLanguage) {
              return group.assets.map((asset) => {
                return (
                  <div key={asset.id} className="downloads-asset-wrapper">
                    <div className="downloads-asset">
                      <div className="downloads-asset-icon">
                        <File
                          className="downloads-asset-icon-img"
                          fill="var(--color-text_secondary)"
                        />
                      </div>
                      <div className="downloads-asset-title">{asset.name}</div>
                      <div className="downloads-asset-info">
                        {displayFileSize(asset.size)}
                      </div>
                    </div>
                    {!readonly ? (
                      <Button
                        type="icon"
                        action={() => removeAsset(asset.id)}
                        look={'secondary'}
                      >
                        <Trash
                          fill="var(--color-danger)"
                          className="button-icon"
                        />
                      </Button>
                    ) : null}
                  </div>
                );
              });
            } else {
              return null;
            }
          })
        )}
      </div>
      {!readonly ? (
        <>
          <div className="downloads-dropzone">
            <Dropzone
              height={100}
              maxFiles={-1}
              callback={handleAssetUpload}
              assetContext={'Product'}
            />
          </div>{' '}
          <div className="downloads-actions">
            <Button
              cta={t(
                'view.product.additionalinformation.newPopup.downloads.gallery'
              )}
              action={() => setGalleryPopup(true)}
              width="minimal"
              look={'secondary'}
            />
            {!onSave ? (
              <Button
                cta={t('actions.save')}
                action={() => submitDownloads(payload)}
                width="minimal"
                look="save"
                isLoading={isSubmitting}
              />
            ) : null}
          </div>
        </>
      ) : null}
      <Popup
        width="60%"
        toggled={galleryPopup}
        close={() => setGalleryPopup(false)}
      >
        {assetFolders ? (
          <FileBrowser
            assetFolders={assetFolders}
            allowUrlNavigation={false}
            allowAssetUpload={false}
            onChooseAssets={(assets: AssetResponse[]) => {
              handleAssetUpload(assets);
              setGalleryPopup(false);
            }}
          />
        ) : (
          <LoadingContainer />
        )}
      </Popup>
    </div>
  );
};

export default Downloads;
