import './promotionslist.css';
import {
  PromotionDiscountResponse,
  PromotionResponse,
  SimpleManufacturerResponse,
} from '../../../api/petcloudapi/api';
import List from '../../../features/list/List';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { useCallback } from 'react';
import Badge from '../../../elements/badge/Badge';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '../../../elements/card/Card';
import { ReactComponent as IconCopy } from '../../../../assets/icon/copy.svg';
import useNotifications from '../../../hooks/useNotifications';

interface PromotionsListProps {
  promotions: PromotionResponse[];
  deletePromotion?: (id: string) => void;
  onSelect?: (item: any) => void;
  onSelectAll?: () => void;
  selectedItems?: PromotionResponse[];
  linked?: string;
}

const PromotionsList: React.FC<PromotionsListProps> = ({
  promotions,
  deletePromotion,
  onSelect,
  onSelectAll,
  selectedItems,
  linked,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.promotions',
  });
  const { pushNotification } = useNotifications();

  const renderStatus = useCallback((active: boolean) => {
    return (
      <Badge
        title={t(`states.${active ? 'active' : 'inactive'}`)}
        color={active ? 'var(--color-success)' : 'var(--color-danger)'}
      />
    );
  }, []);

  const renderDiscountInfo = useCallback(
    (discounts: PromotionDiscountResponse[], promotion: PromotionResponse) => {
      if (promotion.isValidForWholeAssortment) {
        return <span>{t('isValidForWholeAssortment')}</span>;
      } else {
        if (discounts && discounts.length > 0) {
          return (
            <span>
              {discounts.reduce(
                (acc, curr) =>
                  acc +
                  (curr.promotedProducts ? curr.promotedProducts.length : 0),
                0
              )}
            </span>
          );
        } else {
          return 'error';
        }
      }
    },
    []
  );

  const copyToClipboard = (string: string) => {
    navigator.clipboard
      .writeText(string)
      .then(() => pushNotification(t('clipboard'), 'info'));
  };

  const renderCode = useCallback(
    (code: string, promotion: PromotionResponse) => {
      if (
        !promotion.individualCodes ||
        promotion.individualCodes.length === 0
      ) {
        return (
          <div className={'promotionsList-list-code'}>
            <span className={'list-table-td-monoSpaced'}>{code}</span>
            <IconCopy
              className={'promotionsList-list-code-icon'}
              onClick={() => copyToClipboard(code)}
            />
          </div>
        );
      } else {
        const redeemed = promotion.individualCodes.filter((c) => c.redeemed);
        return (
          <span>{`${redeemed.length} / ${promotion.individualCodes.length} ${t(
            'states.redeemed'
          )}`}</span>
        );
      }
    },
    []
  );

  const renderManufacturer = useCallback(
    (manufacturer: SimpleManufacturerResponse) => {
      if (manufacturer) {
        return (
          <div className={'orderList-manufacturerInfo'}>
            <img
              className={'orderList-manufacturerInfo-portrait'}
              src={manufacturer.logoAsset?.uri}
              alt={'logo'}
            />
            <div className={'orderList-manufacturerInfo-name'}>
              {manufacturer.companyName}
            </div>
          </div>
        );
      } else {
        return <div className={'list-table-td-na'}>-</div>;
      }
    },
    []
  );

  const promos = promotions.filter((x) => !x.archived);
  const archivedPromos = promotions.filter((x) => x.archived);

  if (promos.length > 0 || archivedPromos.length > 0) {
    return (
      <div className={'promotionsList'}>
        <Tabs
          tabs={[
            {
              key: 'current',
              title: t('tabs.current'),
            },
            { key: 'archived', title: t('tabs.archived') },
          ]}
        >
          <Tab>
            <div className={'promotionsList-list'}>
              <List
                items={promos}
                selectedItems={selectedItems}
                ignore={[
                  'archived',
                  'priority',
                  'maxRedemptionsGlobal',
                  'maxRedemptionsPerCustomer',
                  'preventCombination',
                  'isValidForWholeAssortment',
                  'useIndividualCodes',
                  'individualCodePrefix',
                  'individualCodeSuffix',
                  'individualCodeLength',
                  'numberOfPromotionCodes',
                  'individualCodes',
                  'id',
                  'createdAt',
                  'updatedAt',
                  'manufacturerId',
                  'excludedFromPromotionIds',
                ]}
                monoSpaceStrings={['code']}
                translatedStrings={['name']}
                dateStrings={['validFrom', 'validTo']}
                sortValueFunctions={{
                  manufacturer: (promotion) =>
                    promotion.manufacturer?.companyName,
                }}
                renderObjects={[
                  {
                    key: 'active',
                    renderMethod: renderStatus,
                  },
                  {
                    key: 'discounts',
                    renderMethod: renderDiscountInfo,
                  },
                  {
                    key: 'code',
                    renderMethod: renderCode,
                    stopPropagation: true,
                  },
                  {
                    key: 'manufacturer',
                    renderMethod: renderManufacturer,
                  },
                ]}
                linkedKey={linked}
                actions={
                  deletePromotion
                    ? [
                        {
                          cta: 'archive',
                          look: 'danger',
                          action: (item) => deletePromotion(item.id),
                        },
                      ]
                    : undefined
                }
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                tableHeadContrast
              />
            </div>
          </Tab>
          <Tab>
            <div className={'promotionsList-list'}>
              <List
                items={archivedPromos}
                selectedItems={selectedItems}
                ignore={[
                  'archived',
                  'priority',
                  'maxRedemptionsGlobal',
                  'maxRedemptionsPerCustomer',
                  'preventCombination',
                  'isValidForWholeAssortment',
                  'useIndividualCodes',
                  'individualCodePrefix',
                  'individualCodeSuffix',
                  'individualCodeLength',
                  'numberOfPromotionCodes',
                  'individualCodes',
                  'id',
                  'createdAt',
                  'updatedAt',
                  'manufacturerId',
                  'excludedFromPromotionIds',
                ]}
                monoSpaceStrings={['code']}
                translatedStrings={['name']}
                dateStrings={['validFrom', 'validTo']}
                sortValueFunctions={{
                  manufacturer: (promotion) =>
                    promotion.manufacturer.companyName,
                }}
                renderObjects={[
                  {
                    key: 'active',
                    renderMethod: renderStatus,
                  },
                  {
                    key: 'discounts',
                    renderMethod: renderDiscountInfo,
                  },
                  {
                    key: 'code',
                    renderMethod: renderCode,
                  },
                  {
                    key: 'manufacturer',
                    renderMethod: renderManufacturer,
                  },
                ]}
                linkedKey={linked}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                tableHeadContrast
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  } else {
    return <EmptyState message={t('empty')} />;
  }
};
export default PromotionsList;
