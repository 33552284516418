import { useCallback } from 'react';
import _ from 'lodash';

const useDebounced = () => {
  return useCallback(
    _.debounce((callback: () => void) => {
      callback();
    }, 500),
    []
  );
}

export default useDebounced;
