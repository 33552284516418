import './loyaltyrank.css';
import {
  AssetFolderResponse,
  AssetResponse,
  CreateLoyaltyRankRequest,
  TranslatedTypeResponseOfAssetResponse,
  UpdateLoyaltyRankRequest,
} from '../../../../../api/petcloudapi/api';
import Input from '../../../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../../../elements/datepicker/DatePicker';
import Dropzone from '../../../../../elements/dropzone/Dropzone';
import { useState } from 'react';
import { Dropdown } from '../../../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import useLanguages from '../../../../../hooks/useLanguages';
import Button from '../../../../../elements/button/Button';
import FileBrowser from '../../../../../elements/filebrowser/FileBrowser';
import Popup from '../../../../../elements/popup/Popup';

interface LoyaltyRankProps {
  rank: CreateLoyaltyRankRequest | UpdateLoyaltyRankRequest;
  asset?: TranslatedTypeResponseOfAssetResponse | null;
  assetFolders: AssetFolderResponse[];
  updateRank: (
    rank: CreateLoyaltyRankRequest | UpdateLoyaltyRankRequest
  ) => void;
  submit: () => void;
  isSubmitting: boolean;
}

const LoyaltyRank: React.FC<LoyaltyRankProps> = ({
  rank,
  updateRank,
  asset,
  submit,
  isSubmitting,
  assetFolders,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.loyalty.loyaltyRanks.newPopup',
  });
  const { availableLanguages } = useLanguages();
  const [rankAsset, setRankAsset] = useState(asset);
  const [selectedLocale, setSelectedLocale] = useState(
    i18n.language as TranslatedStringIndex
  );
  const [galleryPopup, setGalleryPopup] = useState(false);

  const handleAssetUpload = (responses: AssetResponse[]) => {
    setRankAsset({
      ...rankAsset,
      [selectedLocale]: responses[0],
    });
    updateRank({
      ...rank,
      assetId: {
        ...rank.assetId,
        [selectedLocale]: responses[0].id,
      },
    });
  };

  return (
    <div className={'loyaltyRank'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('name')}
            translatedContent={rank.name}
            update={(value, lang) => {
              if (lang)
                updateRank({
                  ...rank,
                  name: {
                    ...rank.name,
                    [lang]: value,
                  },
                });
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('description')}
            translatedContent={rank.description}
            update={(value, lang) => {
              if (lang)
                updateRank({
                  ...rank,
                  description: {
                    ...rank.description,
                    [lang]: value,
                  },
                });
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('minimumPoints')}
            content={rank.minimumPoints.toString()}
            update={(value) => {
              updateRank({
                ...rank,
                minimumPoints: parseInt(value),
              });
            }}
            unit={t('unit_points')}
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('discountPercentage')}
            content={rank.discountPercentage.toString()}
            update={(value) => {
              updateRank({
                ...rank,
                discountPercentage: parseInt(value),
              });
            }}
            unit={'%'}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <DatePicker
            title={t('validFrom')}
            selected={new Date(rank.validFrom)}
            onChange={(date) => {
              if (date)
                updateRank({
                  ...rank,
                  validFrom: date.toISOString(),
                });
            }}
            dateFormat={'dd/MM/yyyy'}
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <DatePicker
            title={t('validTo')}
            selected={rank.validTo ? new Date(rank.validTo) : null}
            onChange={(date) => {
              if (date)
                updateRank({
                  ...rank,
                  validTo: date.toISOString(),
                });
            }}
            dateFormat={'dd/MM/yyyy'}
          />
        </div>
      </div>
      <div className={'loyaltyRank-media'}>
        <div className={'global-input-title'}>{t('asset')}</div>
        <div className={'loyaltyRank-media-languageSwitcher'}>
          <Dropdown
            options={availableLanguages}
            selected={selectedLocale}
            update={(e) => {
              const val =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (val) {
                setSelectedLocale(val as TranslatedStringIndex);
              }
            }}
          />
        </div>
        {rankAsset?.[selectedLocale] ? (
          <>
            <div className={'loyaltyRank-media-asset'}>
              <img
                src={rankAsset[selectedLocale]?.uri}
                className={'loyaltyRank-media-asset-img'}
                alt={'Rank Icon'}
              />
            </div>
            <Button
              cta={t('actions.deleteAsset')}
              action={() => {
                setRankAsset(null);
                updateRank({
                  ...rank,
                  assetId: {
                    ...rank.assetId,
                    [selectedLocale]: null,
                  },
                });
              }}
              width={'minimal'}
              look={'secondary-danger'}
            />
          </>
        ) : (
          <>
            <div className={'loyaltyRank-media-dropzone'}>
              <Dropzone
                maxFiles={1}
                height={200}
                callback={handleAssetUpload}
              />
            </div>
            <Button
              cta={t('actions.gallery')}
              action={() => setGalleryPopup(true)}
              width={'minimal'}
              look={'secondary'}
            />
            <Popup
              toggled={galleryPopup}
              width={'50%'}
              close={() => setGalleryPopup(false)}
            >
              <FileBrowser
                assetFolders={assetFolders}
                allowUrlNavigation={false}
                onChooseAssets={handleAssetUpload}
              />
            </Popup>
          </>
        )}
      </div>
      <div className={'global-cardActions'}>
        <Button
          cta={t('actions.save')}
          look={'save'}
          width={'minimal'}
          action={submit}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  );
};

export default LoyaltyRank;
