import './refunds.css';
import {
  AdyenPaymentRefundDetailResponse,
  AdyenPaymentRefundResponse,
  CurrencyResponse,
  OrderPositionResponse,
} from '../../../api/petcloudapi/api';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { useTranslation } from 'react-i18next';
import List from '../../../features/list/List';
import { useCallback, useState } from 'react';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import Badge from '../../../elements/badge/Badge';
import Popup from '../../../elements/popup/Popup';
import { getOrderRefundDetailPositionUnitEntries } from '../positions/Positions';
import RefundSummary from './refundsummary/RefundSummary';
import { useUser } from '../../../contexts/auth/User';
import useStatus from '../../../hooks/refunds/useStatus';
import AdyenPaymentRefundStatusDictionary from '../../../types/AdyenPaymentRefundStatusDictionary';

interface RefundsProps {
  positions: OrderPositionResponse[];
  refunds: AdyenPaymentRefundResponse[] | null | undefined;
  refundedShippingCosts?: number | null;
  currency?: CurrencyResponse | null;
}

const Refunds: React.FC<RefundsProps> = ({
  positions,
  refunds,
  refundedShippingCosts,
  currency,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const [refundInFocus, setRefundInFocus] =
    useState<AdyenPaymentRefundResponse | null>(null);
  const { getLatestStatus } = useStatus();

  const renderAdyenPaymentRefundDetails = useCallback(
    (value: AdyenPaymentRefundDetailResponse[] | null) => {
      if (value) {
        return value.reduce((prev, curr) => {
          return prev + curr.orderPositionUnitRefundAmounts.length;
        }, 0);
      } else {
        return <div className={'list-table-td-na'}>-</div>;
      }
    },
    []
  );

  const renderStatus = useCallback(
    (status: AdyenPaymentRefundStatusDictionary | null) => {
      if (status) {
        const latestStatus = getLatestStatus(status)?.status?.toString();
        return (
          <Badge
            title={latestStatus ?? 'null error'}
            color={
              latestStatus === 'Authorised' || latestStatus === 'Refunded'
                ? 'var(--color-success)'
                : 'var(--color-text_secondary)'
            }
          />
        );
      } else {
        return (
          <Badge
            title={t('view.order.refunds.state.pending')}
            color={'var(--color-text_secondary)'}
          />
        );
      }
    },
    []
  );

  const getIgnore = () => {
    const arr = ['id', 'updatedAt', 'paymentSplitId'];
    if (!user?.isProductOwner) {
      arr.push('commissionStatus');
    }
    return arr;
  };

  if (refunds) {
    return (
      <div className={'refunds'}>
        {refundedShippingCosts ? (
          <Badge
            title={t('view.order.refunds.refundedShippingCosts')}
            color={'var(--color-success)'}
          />
        ) : refundedShippingCosts !== undefined ? (
          <Badge
            title={t('view.order.refunds.notRefundedShippingCosts')}
            color={'var(--color-gray)'}
            hintParagraphs={[
              t('view.order.refunds.notRefundedShippingCosts_hint'),
            ]}
          />
        ) : null}
        <List
          items={refunds.map((refund) => {
            const {
              adyenPaymentRefundDetails,
              createdAt,
              totalAmount,
              totalCommission,
              refundedShippingCosts,
              ...rest
            } = refund;
            const own = refund.totalAmount - refund.totalCommission;
            return {
              ...rest,
              adyenPaymentRefundDetails,
              ownPart: own,
              totalCommission,
              refundedShippingCosts,
              totalAmount: own + totalCommission + (refundedShippingCosts ?? 0),
              createdAt,
            };
          })}
          ignore={getIgnore()}
          dateStrings={['createdAt']}
          renderObjects={[
            {
              key: 'adyenPaymentRefundDetails',
              renderMethod: renderAdyenPaymentRefundDetails,
            },
            {
              key: 'status',
              renderMethod: renderStatus,
              receiveNullValues: true,
            },
            {
              key: 'commissionStatus',
              renderMethod: renderStatus,
              receiveNullValues: true,
            },
          ]}
          priceKeysConfig={{
            priceKeys: [
              'totalAmount',
              'totalCommission',
              'ownPart',
              'refundedShippingCosts',
            ],
            currencySymbol: currency?.symbol,
          }}
          onRowClick={(refund: AdyenPaymentRefundResponse) =>
            setRefundInFocus(refund)
          }
        />
        {refundInFocus && refundInFocus.adyenPaymentRefundDetails ? (
          <Popup
            toggled={true}
            width={'50%'}
            close={() => setRefundInFocus(null)}
          >
            <div className={'popup-title'}>
              {t('view.order.refunds.detail.title')}
            </div>
            <RefundSummary
              refund={refundInFocus}
              currencySymbol={currency?.symbol}
            />
            <List
              items={getOrderRefundDetailPositionUnitEntries(
                positions,
                refundInFocus.adyenPaymentRefundDetails,
                i18n.language as TranslatedStringIndex
              )}
              itemImgKey={'coverUri'}
              ignore={['id']}
              monoSpaceStrings={['ean']}
              priceKeysConfig={{
                priceKeys: ['refundAmount', 'commission', 'totalPrice'],
                currencySymbol: currency?.symbol,
              }}
            />
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return <EmptyState message={t('view.order.refunds.empty')} />;
  }
};

export default Refunds;
