import './notificationsubscriptions.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { useUser } from '../../../contexts/auth/User';
import { Store } from 'react-notifications-component';
import { useAuthorization } from '../../../contexts/auth/Authorization';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import { LoadingContainer } from '../../../elements/loading/Loading';
import blacklist from '../roles/policymatrix/blacklistedEndpoints';
import Button from '../../../elements/button/Button';
import { EmptyState } from '../../../elements/emptystate/EmptyState';

interface NotificationSubscriptionsProps {}

const NotificationSubscriptions: React.FC<
  NotificationSubscriptionsProps
> = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.account.notificationSubscriptions',
  });
  const { user } = useUser();
  const { authorizations } = useAuthorization();
  const api = usePetCloudApi();
  const usersApi = api.usersApi();
  const errorHandler = useErrorHandler();

  const [allowedNotifications, setAllowedNotifications] = useState<
    { key: string; translation: string }[] | null
  >(null);
  const [subscribedNotifications, setSubscribedNotifications] = useState<
    string[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user && authorizations) {
      setSubscribedNotifications(user.subscribedNotifications);

      const an = authorizations.filter(
        (n) =>
          n.includes('notification_type_') &&
          !blacklist.includes(n.split(':')[0])
      );
      setAllowedNotifications(
        an.map((n) => {
          const notifactionName = n
            .replace('notification_type_', '')
            .split(':')[0];
          return {
            key: n,
            translation: t(`availableNotifications.${notifactionName}`),
          };
        })
      );
    }
  }, [user, authorizations]);

  const submitSubscribedNotifications = () => {
    if (user && subscribedNotifications) {
      setIsSubmitting(true);
      usersApi
        .usersUpdateSubscribedNotifications(user.id, {
          subscribedNotifications: subscribedNotifications,
        })
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const toggleSubscribedNotification = (notification: string) => {
    if (subscribedNotifications) {
      const update = [...subscribedNotifications];
      const i = update.findIndex((n) => n === notification);
      if (i !== -1) {
        update.splice(i, 1);
      } else {
        update.push(notification);
      }
      setSubscribedNotifications(update);
    }
  };

  if (user && authorizations && allowedNotifications) {
    return (
      <div className={'notificationSubscriptions'}>
        <div className={'notificationSubscriptions-text'}>{t('text')}</div>
        <div className={'notificationSubscriptions-notifications'}>
          {allowedNotifications.length > 0 ? (
            allowedNotifications.map((notification) => {
              return (
                <div className={'notificationSubscriptions-notification'}>
                  <ToggleSwitch
                    label={notification.translation}
                    toggled={subscribedNotifications?.includes(
                      notification.key
                    )}
                    toggle={() =>
                      toggleSubscribedNotification(notification.key)
                    }
                  />
                </div>
              );
            })
          ) : (
            <EmptyState message={t('empty')} />
          )}
        </div>
        <div className={'global-cardActions'}>
          <Button
            cta={t('actions.save')}
            action={submitSubscribedNotifications}
            look={'save'}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default NotificationSubscriptions;
