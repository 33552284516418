import './jsoneditor.css';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import { useState } from 'react';
import InformationBox from '../../../../elements/informationbox/InformationBox';

interface JsonEditorProps {
  json: object;
  updateCallback: EditorUpdateCallback;
}

const JsonEditor: React.FC<JsonEditorProps> = ({ json, updateCallback }) => {
  const [value, setValue] = useState(JSON.stringify(json, null, 2));
  const [error, setError] = useState(false);

  return (
    <div className={'jtce-json'}>
      <textarea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        rows={20}
        cols={60}
      />
      {error ? (
        <div className={'jtce-json-error'}>
          <InformationBox content={'Parsing Error'} type={'error'} />
        </div>
      ) : null}
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              setError(false);
              updateCallback([{ value: JSON.parse(value) }]);
            } catch {
              setError(true);
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default JsonEditor;
