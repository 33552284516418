import './bundlepositions.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  CurrencyResponse,
  ProductBundlePositionResponse,
  ProductBundlePriceResponse,
  ProductListFilterMode,
  SimpleProductResponse,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import List from '../../../features/list/List';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import ProductList from '../../../features/productlist/ProductList';
import BundlePositionEdit from './bundlepositionedit/BundlePositionEdit';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { DropdownOption } from '../../../elements/selectors/Selectors';
import Badge from '../../../elements/badge/Badge';
import usePrice from '../../../hooks/usePrice';
import { Has } from '../../../contexts/auth/Authorization';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMoveImmutable } from 'array-move';
import _ from 'lodash';

interface BundlePositionsProps {
  bundleId: string;
  updatePositions: (positions: ProductBundlePositionResponse[]) => void;
  setOriginalBundlePositions: (
    positions: ProductBundlePositionResponse[]
  ) => void;
}

const BundlePositions: React.FC<BundlePositionsProps> = ({
  bundleId,
  updatePositions,
  setOriginalBundlePositions,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.bundle.positions',
  });
  const api = usePetCloudApi();
  const productBundlesApi = api.productBundlesApi();
  const countriesApi = api.countriesApi();
  const currenciesApi = api.currenciesApi();
  const errorHandler = useErrorHandler();
  const renderPrice = usePrice();

  const [popup, setPopup] = useState(false);
  const [positionToEdit, setPositionToEdit] =
    useState<ProductBundlePositionResponse | null>(null);

  const [currencies, setCurrencies] = useState<CurrencyResponse[] | null>(null);
  const [currencyOptions, setCurrencyOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [countries, setCountries] = useState<DropdownOption[] | null>(null);
  const [bundlePositions, setBundlePositions] = useState<
    ProductBundlePositionResponse[] | null
  >(null);
  const [selectedProducts, setSelectedProducts] = useState<
    SimpleProductResponse[]
  >([]);

  useEffect(() => {
    getBundlePositions();
    getCurrencyOptions();
    getCountries();
  }, []);

  useEffect(() => {
    if (bundlePositions) {
      updatePositions(bundlePositions);
    }
  }, [bundlePositions]);

  const getCurrencyOptions = () => {
    currenciesApi
      .currenciesGetCurrencies()
      .then((response) => {
        console.log(response.data);
        setCurrencies(response.data);
        setCurrencyOptions(
          response.data.map((x) => ({
            id: x.id,
            name:
              x.name[i18n.language as TranslatedStringIndex] ??
              'missing translation',
          }))
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getCountries = () => {
    countriesApi
      .countriesGetCountries()
      .then((response) => {
        console.log(response.data);
        setCountries(
          response.data.map((x) => ({
            id: x.id,
            name:
              x.name[i18n.language as TranslatedStringIndex] ??
              'missing translation',
          }))
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getBundlePositions = () => {
    productBundlesApi
      .productBundlesGetProductBundlePositions(bundleId)
      .then((response) => {
        console.log(response.data);
        const sorted = _.sortBy(response.data, 'position');
        setBundlePositions(sorted);
        setOriginalBundlePositions(sorted);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getListPositions = (bPos: ProductBundlePositionResponse[]) => {
    return _.sortBy(
      bPos.map((position) => {
        const { product, ...rest } = position;
        return {
          coverUri:
            product?.cover?.asset[i18n.language as TranslatedStringIndex]?.uri,
          productName: product?.name,
          product: product,
          ...rest,
        };
      }),
      'position'
    );
  };

  const deletePosition = (item: ProductBundlePositionResponse) => {
    if (bundlePositions) {
      const update = [...bundlePositions];
      const i = update.findIndex((p) => p.id === item.id);
      if (i !== -1) {
        update.splice(i, 1);
      }
      setBundlePositions(update);
      setPositionToEdit(null);
    }
  };

  const convertProductsToPositions = () => {
    if (bundlePositions && selectedProducts.length > 0) {
      const update: ProductBundlePositionResponse[] = [...bundlePositions];
      selectedProducts.forEach((product, i) => {
        update.push({
          quantity: 1,
          prices: [],
          id: crypto.randomUUID(),
          createdAt: new Date().toUTCString(),
          productBundleId: bundleId,
          productId: product.id,
          position: update.length + i,
          product: product,
        });
      });
      setBundlePositions(update);
      setSelectedProducts([]);
      setPopup(false);
    }
  };

  const saveBundlePosition = (position: ProductBundlePositionResponse) => {
    if (bundlePositions) {
      const update = [...bundlePositions];
      const i = update.findIndex((p) => p.id === position.id);
      if (i !== -1) {
        update[i] = {
          ...position,
        };
      }
      setBundlePositions(update);
      setPositionToEdit(null);
    }
  };

  const renderPrices = (prices: ProductBundlePriceResponse[]) => {
    if (!prices || prices.length === 0) {
      return <div className={'list-table-td-na'}>-</div>;
    } else {
      const currencySymbol = currencies?.find(
        (c) => c.id === prices[0].currencyId
      )?.symbol;
      const total = prices.reduce((prev, next) => {
        return prev + next.gross;
      }, 0);
      return (
        <Badge
          title={renderPrice(total, currencySymbol) ?? 'error'}
          color={'var(--color-text_primary)'}
        />
      );
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (bundlePositions && over) {
      if (active.id !== over.id) {
        const oldPosition = bundlePositions.find(
          (p) => p.id === active.id
        )?.position;
        const newPosition = bundlePositions.find(
          (p) => p.id === over.id
        )?.position;
        if (oldPosition !== undefined && newPosition !== undefined) {
          const updatedProductProperties = [
            ...arrayMoveImmutable(bundlePositions, oldPosition, newPosition),
          ];
          setBundlePositions(setPositionByIndex(updatedProductProperties));
        }
      }
    }
  };

  const setPositionByIndex = (array: ProductBundlePositionResponse[]) => {
    return array.map((x, i) => ({
      ...x,
      position: i,
    }));
  };

  if (bundlePositions && countries && currencies && currencyOptions) {
    return (
      <Has
        authorizations={['countries:list', 'currency:list', 'products:list']}
        showNoAuthorization
      >
        <div className={'bundlePositions'}>
          {bundlePositions.length > 0 ? (
            <List
              items={getListPositions(bundlePositions)}
              translatedStrings={['productName']}
              itemImgKey={'coverUri'}
              onDragEnd={onDragEnd}
              isShowingIndex={true}
              ignore={[
                'productBundleId',
                'productId',
                'updatedAt',
                'position',
                'product',
              ]}
              dateStrings={['createdAt']}
              onRowClick={(position) => setPositionToEdit(position)}
              actions={[
                {
                  cta: 'edit',
                  look: 'blue',
                  action: (item) => setPositionToEdit(item),
                },
                {
                  cta: 'delete',
                  look: 'danger',
                  action: deletePosition,
                },
              ]}
              renderObjects={[
                {
                  key: 'prices',
                  renderMethod: renderPrices,
                  receiveNullValues: true,
                },
              ]}
            />
          ) : (
            <EmptyState message={t('empty')} />
          )}
          <div className={'global-cardActions global-cardActions-postBorder'}>
            <Button
              cta={t('actions.add')}
              width={'minimal'}
              look={'secondary'}
              action={() => setPopup(true)}
            />
          </div>
          {positionToEdit ? (
            <Popup
              toggled={true}
              width={'40%'}
              close={() => setPositionToEdit(null)}
            >
              <div className={'popup-title'}>{`${t(
                'bundlePositionEdit.title'
              )}: ${
                positionToEdit.product?.name[
                  i18n.language as TranslatedStringIndex
                ]
              }`}</div>
              <BundlePositionEdit
                bundlePosition={positionToEdit}
                saveBundlePosition={saveBundlePosition}
                countryOptions={countries}
                currencyOptions={currencyOptions}
              />
            </Popup>
          ) : null}
          <Popup toggled={popup} width={'50%'} close={() => setPopup(false)}>
            <div className={'popup-title'}>{t('popup.title')}</div>
            <ProductList
              selectProductCallback={(product) => {
                const update = [...selectedProducts];
                const i = selectedProducts.findIndex(
                  (p) => p.id === product.id
                );
                if (i !== -1) {
                  update.splice(i, 1);
                } else {
                  update.push(product);
                }
                setSelectedProducts(update);
              }}
              disableSelectAll
              height={'60vh'}
              productListFilterMode={ProductListFilterMode.ExcludeNonBuyable}
              disableURLParams
              disableAdjustHeightToViewport
            />
            <div className={'global-cardActions-margin'}>
              <Button
                cta={t('popup.cta')}
                width={'minimal'}
                look={'save'}
                action={convertProductsToPositions}
                active={selectedProducts.length > 0}
              />
            </div>
          </Popup>
        </div>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default BundlePositions;
