import { useTranslation } from 'react-i18next';
import { PurchaseOrderState as PurchaseOrderStateType } from '../../../api/petcloudapi/api';
import Badge from '../../../elements/badge/Badge';
import Button from '../../../elements/button/Button';
import './purchaseorderstate.css';

interface PurchaseOrderStateProps {
  state: PurchaseOrderStateType | undefined;
  setClosed: () => void;
}

const PurchaseOrderState: React.FC<PurchaseOrderStateProps> = ({
  state,
  setClosed,
}) => {
  const { t } = useTranslation();

  if (state) {
    return (
      <div className="purchaseorderstate">
        <div className="purchaseorderstate-title">
          {t('view.purchaseorder.state.title')}
        </div>
        <div className="purchaseorderstate-badge">
          <Badge
            title={t('view.purchaseorder.state.' + state)}
            color={
              state === 'Open'
                ? 'var(--color-blue)'
                : state === 'Review'
                ? 'var(--color-yellow)'
                : 'var(--color-gray)'
            }
          />
        </div>
        <div className="purchaseorderstate-description">
          {state === 'Open'
            ? t('view.purchaseorder.state.Open_disclaimer')
            : state === 'Review'
            ? t('view.purchaseorder.state.Review_disclaimer')
            : t('view.purchaseorder.state.Closed_disclaimer')}
        </div>
        {state === 'Review' ? (
          <div className="purchaseorderstate-actions">
            <Button
              cta={t('view.purchaseorder.state.closeBtn')}
              look={'secondary'}
              width="full"
              action={setClosed}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <div>State is missing.</div>;
  }
};

export default PurchaseOrderState;
