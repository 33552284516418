import { useState } from 'react';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../elements/selectors/Selectors';
import { useTranslation } from 'react-i18next';
import Input from '../../elements/input/Input';
import Button from '../../elements/button/Button';
import { unparse } from 'papaparse';

interface CSVExportProps {
  objects: any;
  objectPreparationFunc?: (objects: any) => any;
}

const CSVExport: React.FC<CSVExportProps> = ({
  objects,
  objectPreparationFunc,
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const [delimiter, setDelimiter] = useState(',');
  const [header, setHeader] = useState(true);
  const [exporting, setExporting] = useState(false);

  const delimiterOptions: DropdownOption[] = [
    {
      id: ',',
      name: t('view.purchaseorder.positionImport.delimiter_options.comma'),
    },
    {
      id: ';',
      name: t('view.purchaseorder.positionImport.delimiter_options.semicolon'),
    },
    {
      id: '\t',
      name: t('view.purchaseorder.positionImport.delimiter_options.tab'),
    },
    {
      id: ' ',
      name: t('view.purchaseorder.positionImport.delimiter_options.space'),
    },
    {
      id: '|',
      name: t('view.purchaseorder.positionImport.delimiter_options.pipe'),
    },
  ];

  const handleExport = async () => {
    setExporting(true);
    let load: any;
    if (objectPreparationFunc) {
      load = await objectPreparationFunc(objects);
    } else {
      load = objects;
    }
    const csv = unparse(load, {
      delimiter: delimiter,
      header: header,
    });
    console.log(csv);

    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8',
    });

    const l = document.createElement('a');
    l.href = URL.createObjectURL(blob);
    l.setAttribute('download', fileName);
    document.body.appendChild(l);
    l.click();
    document.body.removeChild(l);
    setExporting(false);
  };

  return (
    <div className={'csvExport'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('view.logistics.export.fileName')}
            content={fileName}
            update={(e) => {
              setFileName(e);
            }}
            unit={'.csv'}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('view.logistics.export.delimiter')}
            optionObjects={delimiterOptions}
            selected={delimiterOptions.find((o) => o.id === delimiter)?.name}
            update={(e) => {
              const value =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (value) {
                setDelimiter(value);
              }
            }}
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Check
            checked={header}
            update={() => setHeader(!header)}
            title={t('view.logistics.export.header')}
          />
        </div>
      </div>
      <div className={'global-cardActions'}>
        <Button
          cta={t('actions.export')}
          look={'save'}
          width={'full'}
          action={handleExport}
          isLoading={exporting}
        />
      </div>
    </div>
  );
};

export default CSVExport;
