import { useTranslation } from 'react-i18next';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import {
  NoAuthorization,
  NoResults,
} from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  AnimalSpeciesResponse,
  CreateAnimalSpeciesRequest,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import List from '../../features/list/List';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import AnimalSpeciesForm from '../../sections/admin/animalspecies/animalspeciesform/AnimalSpeciesForm';
import useNotifications from '../../hooks/useNotifications';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Dropdown } from '../../elements/selectors/Selectors';
import InformationBox from '../../elements/informationbox/InformationBox';
import useLanguages from '../../hooks/useLanguages';

const newSpecies = {
  name: {
    'de-DE': '',
    'en-GB': '',
  },
  slug: {
    'de-DE': '',
    'en-GB': '',
  },
  identifier: '',
};

const AnimalSpecies = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.animalSpecies',
  });
  const { user } = useUser();
  const api = usePetCloudApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { availableLanguages } = useLanguages();
  const [newPopup, setNewPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedLocale, setSelectedLocale] =
    useState<TranslatedStringIndex>('de-DE');
  const [animalSpecies, setAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);
  const [animalSpeciesInFocus, setAnimalSpeciesInFocus] =
    useState<AnimalSpeciesResponse | null>(null);
  const [newSpeciesRequest, setNewSpeciesRequest] =
    useState<CreateAnimalSpeciesRequest>(newSpecies);
  const [animalSpeciesToDelete, setAnimalSpeciesToDelete] =
    useState<AnimalSpeciesResponse | null>(null);

  useEffect(() => {
    getAnimalSpecies();
  }, []);

  const getAnimalSpecies = () => {
    setAnimalSpecies(null);
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitNewSpecies = () => {
    setIsSubmitting(true);
    animalSpeciesApi
      .animalSpeciesCreateAnimalSpecies(newSpeciesRequest)
      .then((response) => {
        setIsSubmitting(false);
        console.log(response);
        pushNotification(t('new.notifications.success'));
        setNewSpeciesRequest(newSpecies);
        getAnimalSpecies();
        setNewPopup(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitUpdatedAnimalSpecies = () => {
    if (animalSpeciesInFocus) {
      setIsSubmitting(true);
      animalSpeciesApi
        .animalSpeciesUpdateAnimalSpecies(animalSpeciesInFocus.id, {
          name: animalSpeciesInFocus.name,
          slug: animalSpeciesInFocus.slug,
          identifier: animalSpeciesInFocus.identifier,
        })
        .then((response) => {
          setIsSubmitting(false);
          console.log(response);
          pushNotification(t('update.notifications.success'));
          setAnimalSpeciesInFocus(null);
          getAnimalSpecies();
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const deleteSpecies = (species: AnimalSpeciesResponse) => {
    setIsDeleting(true);
    animalSpeciesApi
      .animalSpeciesDeleteAnimalSpecies(species.id)
      .then((response) => {
        console.log(response);
        pushNotification(t('delete.notifications.success'));
        getAnimalSpecies();
        setAnimalSpeciesToDelete(null);
        setIsDeleting(false);
      })
      .catch((error) => {
        setIsDeleting(true);
        console.log(error);
        errorHandler.addError(error.response);
        getAnimalSpecies();
      });
  };

  if (user?.isProductOwner) {
    if (animalSpecies) {
      return (
        <Stage>
          <Navigator title={t('title')}>
            <Button
              cta={t('cta')}
              look={'primary'}
              width={'minimal'}
              action={() => setNewPopup(true)}
            />
          </Navigator>
          <Card bigScreenWidth={'full'}>
            <CardSection>
              <div className={'global-inputGroup'}>
                <div className={'global-inputGroup-inputInline'}>
                  <Dropdown
                    options={availableLanguages}
                    selected={selectedLocale}
                    update={(e) => {
                      const lang = e.currentTarget.selectedOptions[0].value;
                      setSelectedLocale(lang as TranslatedStringIndex);
                    }}
                  />
                </div>
              </div>
              {animalSpecies.length > 0 ? (
                <List
                  items={animalSpecies}
                  translatedStrings={['name', 'slug']}
                  monoSpaceStrings={['id']}
                  dateStrings={['createdAt']}
                  ignore={['updatedAt']}
                  selectedLocale={selectedLocale}
                  actions={[
                    {
                      look: 'blue',
                      cta: 'edit',
                      action: (species) => setAnimalSpeciesInFocus(species),
                    },
                    {
                      look: 'danger',
                      cta: 'delete',
                      action: (species) => setAnimalSpeciesToDelete(species),
                    },
                  ]}
                  onRowClick={(item) => setAnimalSpeciesInFocus(item)}
                />
              ) : (
                <NoResults />
              )}
            </CardSection>
            <Popup
              toggled={newPopup}
              width={'50%'}
              close={() => setNewPopup(false)}
            >
              <div className={'popup-title'}>{t('new.title')}</div>
              <AnimalSpeciesForm
                species={newSpeciesRequest}
                update={(species) => setNewSpeciesRequest(species)}
              />
              <div className={'global-cardActions'}>
                <Button
                  cta={t('new.cta')}
                  action={submitNewSpecies}
                  isLoading={isSubmitting}
                  look={'save'}
                  width={'minimal'}
                />
              </div>
            </Popup>
            {animalSpeciesInFocus ? (
              <Popup
                toggled={true}
                width={'50%'}
                close={() => setAnimalSpeciesInFocus(null)}
              >
                <div className={'popup-title'}>{t('new.title')}</div>
                <AnimalSpeciesForm
                  species={animalSpeciesInFocus}
                  update={(species) =>
                    setAnimalSpeciesInFocus(species as AnimalSpeciesResponse)
                  }
                />
                <div className={'global-cardActions'}>
                  <Button
                    cta={t('update.cta')}
                    action={submitUpdatedAnimalSpecies}
                    isLoading={isSubmitting}
                    look={'save'}
                    width={'minimal'}
                  />
                </div>
              </Popup>
            ) : null}
            {animalSpeciesToDelete ? (
              <Popup
                toggled={true}
                width={'20%'}
                close={() => setAnimalSpeciesToDelete(null)}
              >
                <div className={'popup-title'}>{t('delete.title')}</div>
                <InformationBox content={t('delete.text')} type={'warning'} />
                <div className={'global-cardActions'}>
                  <Button
                    cta={t('delete.cta')}
                    action={() => deleteSpecies(animalSpeciesToDelete)}
                    isLoading={isDeleting}
                    look={'danger'}
                    width={'full'}
                  />
                </div>
              </Popup>
            ) : null}
          </Card>
        </Stage>
      );
    } else {
      return <LoadingContainer />;
    }
  } else {
    return <NoAuthorization />;
  }
};

export default AnimalSpecies;
