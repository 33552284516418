import './emptystate.css';
import { useTranslation } from 'react-i18next';
import notify from '../../../assets/img/notify.svg';
import notfound from '../../../assets/img/void.svg';
import noResult from '../../../assets/icon/properties_empty.svg';
import emptyList from '../../../assets/img/empty_list.svg';
import emptyListSmall from '../../../assets/img/empty_list_small.svg';
import construction from '../../../assets/img/construction.svg';

interface EmptyStateProps {
  message?: string;
  small?: boolean;
  img?: string;
  textOnly?: boolean;
  noMargin?: boolean;
  size?: number;
  maxTextWidth?: number;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  small,
  img,
  textOnly,
  noMargin,
  size,
  maxTextWidth,
}) => {
  return (
    <div className={`emptyState ${noMargin ? 'emptyState-noMargin' : null}`}>
      <div className="emptyState-content">
        {!textOnly ? (
          small ? (
            <img
              src={img ? img : emptyListSmall}
              className="emptyState-content-img-small"
              alt="empty"
              style={{ width: size ?? 80, height: size ?? 80 }}
            />
          ) : (
            <img
              src={img ? img : emptyList}
              className="emptyState-content-img"
              alt="empty"
              style={{ width: size ?? 80, height: size ?? 80 }}
            />
          )
        ) : null}
        {message ? (
          <div
            className="emptyState-content-msg"
            style={{ maxWidth: maxTextWidth ?? 660 }}
          >
            {message}
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface NoAuthorizationProps {
  message?: string;
  missingPermissions?: string[];
}

export const NoAuthorization: React.FC<NoAuthorizationProps> = ({
  message,
  missingPermissions,
}) => {
  const { t } = useTranslation();

  return (
    <div className="emptyState">
      <div className="emptyState-content">
        <img
          src={notify}
          className="emptyState-content-img"
          alt="no authorization"
        />
        <div className="emptyState-content-msg">
          {message ? message : t('errors.no_authorization')}
        </div>
        {missingPermissions ? (
          <div className={'emptyState-content-missingPermissions'}>
            <div className={'emptyState-content-missingPermissions-text'}>
              {t('errors.missingPermissions')}
            </div>
            <div
              className={'emptyState-content-missingPermissions-permissions'}
            >
              {missingPermissions.map((permission) => {
                return (
                  <div
                    className={
                      'emptyState-content-missingPermissions-permission'
                    }
                  >
                    • {permission}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="emptyState">
      <div className="emptyState-content">
        <img
          src={notfound}
          className="emptyState-content-img"
          alt="not found"
        />
        <div className="emptyState-content-msg">{t('errors.not_found')}</div>
      </div>
    </div>
  );
};

export const NoResults = () => {
  const { t } = useTranslation();

  return (
    <div className="emptyState">
      <div className="emptyState-content">
        <img
          src={noResult}
          className="emptyState-content-img"
          alt="no results"
        />
        <div className="emptyState-content-msg">{t('errors.no_results')}</div>
      </div>
    </div>
  );
};

export const UnderConstruction = () => {
  const { t } = useTranslation();

  return (
    <div className="emptyState">
      <div className="emptyState-content">
        <img
          src={construction}
          className="emptyState-content-img"
          alt="under construction"
        />
        <div className="emptyState-content-msg">
          {t('errors.under_construction')}
        </div>
      </div>
    </div>
  );
};
