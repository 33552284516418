import './recurringcustomers.css';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { ReturningCustomersStatisticsResponse } from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import BarChart from '../common/charts/barchart/BarChart';
import RecurringCustomersBarToolTip from '../common/charts/bartooltip/RecurringCustomersBarToolTip';
import _ from 'lodash';
import { Check } from '../../../elements/selectors/Selectors';
import { useTranslation } from 'react-i18next';
import RecurringCustomersBarPopup from './recurringcustomersbarpopup/RecurringCustomersBarPopup';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import RecurringCustomersFiltering from './recurringcustomersfiltering/RecurringCustomersFiltering';
import { SmallHint } from '../../../elements/hint/Hint';

interface RecurringCustomersProps {
  dateRangeFrom: string;
  dateRangeTo: string;
  selectedManufacturerId?: string | null;
}

const RecurringCustomers: React.FC<RecurringCustomersProps> = ({
  dateRangeFrom,
  dateRangeTo,
  selectedManufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.widgets.recurringCustomers',
  });
  const api = usePetCloudApi();
  const dashboardsApi = api.dashboardsApi();
  const errorHandler = useErrorHandler();

  const [data, setData] = useState<
    ReturningCustomersStatisticsResponse[] | null
  >(null);
  const [merge, setMerge] = useState(false);
  const [recurringCustomersFilterPopup, setRecurringCustomersFilterPopup] =
    useState(false);

  useEffect(() => {
    getRecurringCustomers();
  }, [selectedManufacturerId]);

  const getRecurringCustomers = () => {
    dashboardsApi
      .dashboardsGetReturningCustomersStatistics(
        selectedManufacturerId && selectedManufacturerId !== 'all'
          ? selectedManufacturerId
          : undefined,
        dateRangeFrom,
        dateRangeTo,
        true
      )
      .then((response) => {
        console.log(response);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  //TODO: sort array by numberOfOrders ascending
  const getAccountTypePairs = (arr: ReturningCustomersStatisticsResponse[]) => {
    let pairs: {
      numberOfOrders: number;
      data: ReturningCustomersStatisticsResponse[];
    }[] = [];
    arr.forEach((item) => {
      let pair = pairs.find(
        (pair) => pair.numberOfOrders === item.numberOfOrders
      );
      if (pair) {
        pair.data.push(item);
      } else {
        pairs.push({ numberOfOrders: item.numberOfOrders ?? 0, data: [item] });
      }
    });
    const sortedInner = pairs.map((x) => ({
      ...x,
      data: _.sortBy(x.data, 'guestAccount'),
    }));
    return _.sortBy(sortedInner, 'numberOfOrders');
  };

  const mergeAccountTypes = (arr: ReturningCustomersStatisticsResponse[]) => {
    const result: ReturningCustomersStatisticsResponse[] = [];
    arr.forEach((el) => {
      const index = result.findIndex(
        (x) => x.numberOfOrders === el.numberOfOrders
      );
      if (index !== -1) {
        result[index].numberOfCustomers =
          (result[index].numberOfCustomers ?? 0) + (el.numberOfCustomers ?? 0);
        result[index].totalCustomersPercentage = parseFloat(
          (
            (result[index].totalCustomersPercentage ?? 0) +
            (el.totalCustomersPercentage ?? 0)
          ).toFixed(2)
        );
        result[index].totalRevenue = parseFloat(
          ((result[index].totalRevenue ?? 0) + (el.totalRevenue ?? 0)).toFixed(
            2
          )
        );
        result[index].emails = result[index].emails?.concat(el.emails ?? []);
      } else {
        result.push(el);
      }
    });
    return _.sortBy(result, 'numberOfOrders');
  };

  const getTotalCustomerCount = () => {
    return _.sumBy(data, (item) => item.numberOfCustomers ?? 0) ?? 0;
  };

  const getTotalRecurringRate = () => {
    return (
      (_.sumBy(data, (item) =>
        item.numberOfOrders && item.numberOfOrders > 1
          ? item.numberOfCustomers ?? 0
          : 0
      ) /
        getTotalCustomerCount()) *
      100
    ).toFixed(2);
  };

  if (data) {
    return (
      <div className={'recurringCustomers'}>
        <div className={'recurringCustomers-topBar'}>
          <div className={'recurringCustomers-topBar-group'}>
            <div className={'recurringCustomers-topBar-info'}>
              {getTotalCustomerCount()} {t('totalCustomers')}
            </div>
            <div className={'recurringCustomers-topBar-info'}>
              {getTotalRecurringRate()}% {t('totalRecurring')}
              <SmallHint paragraphs={[t('totalRecurring_hint')]} />
            </div>
            <div className={'recurringCustomers-settings'}>
              <Check
                checked={merge}
                update={() => setMerge(!merge)}
                text={t('mergeAccountTypes')}
              />
            </div>
          </div>
          <div className={'recurringCustomers-topBar-group'}>
            <Button
              cta={t('filterCustomers')}
              look={'secondary'}
              width={'tiny'}
              action={() => setRecurringCustomersFilterPopup(true)}
            />
          </div>
        </div>
        {merge ? (
          <BarChart
            data={mergeAccountTypes(JSON.parse(JSON.stringify(data)))}
            xAxisName={t('xAxisName')}
            xAxisKey={'numberOfOrders'}
            yAxisName={t('yAxisName')}
            yAxisKey={'numberOfCustomers'}
            yAxisSteps={5}
            onHover={(x) => <RecurringCustomersBarToolTip data={x} />}
            barPopup={(bar: ReturningCustomersStatisticsResponse) => (
              <RecurringCustomersBarPopup data={bar} />
            )}
          />
        ) : (
          <BarChart
            data={getAccountTypePairs(JSON.parse(JSON.stringify(data)))}
            stackKey={'data'}
            stackType={'row'}
            xAxisName={t('xAxisName')}
            xAxisKey={'numberOfOrders'}
            yAxisName={t('yAxisName')}
            yAxisKey={'numberOfCustomers'}
            yAxisSteps={5}
            onHover={(x) => (
              <RecurringCustomersBarToolTip data={x} showGuestInfo />
            )}
            barColors={['var(--color-blue)', 'var(--color-primary)']}
            barPopup={(bar: ReturningCustomersStatisticsResponse) => (
              <RecurringCustomersBarPopup data={bar} />
            )}
          />
        )}
        <Popup
          toggled={recurringCustomersFilterPopup}
          width={'40%'}
          close={() => setRecurringCustomersFilterPopup(false)}
        >
          <div className={'popup-title'}>
            {t('recurringCustomersFilter.title')}
          </div>
          <RecurringCustomersFiltering data={data} />
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default RecurringCustomers;
