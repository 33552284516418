import './topbar.css';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import { useTranslation } from 'react-i18next';
import { Bell } from '../../elements/notifications/Notifications';
import { ReactComponent as Menu } from '../../../assets/icon/menu.svg';

interface TopbarProps {
  toggleMobileMenu: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ toggleMobileMenu }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="topbar">
        <div className="topbar-title">
          <Menu onClick={toggleMobileMenu} className="mobile-topbar-menuBtn" />
          <Logo fill="var(--color-primary)" className="topbar-title-logo" />
          <div className="topbar-title-subtitle">
            {t('topbar.subtitle')}
            <div className={'topbar-buildVersion'}>
              v{process.env.REACT_APP_BUILD_VERSION}
            </div>
          </div>
        </div>
        {process.env.REACT_APP_BUILD_ENV !== 'production' ? (
          process.env.REACT_APP_BUILD_ENV === 'test' ? (
            <div className={'topbar-environment-test'}>
              {t('topbar.environment.test')}
            </div>
          ) : process.env.REACT_APP_BUILD_ENV === 'staging' ? (
            <div className={'topbar-environment-staging'}>
              {t('topbar.environment.staging')}
            </div>
          ) : null
        ) : null}
        <div className="topbar-actions">
          <Bell />
        </div>
      </div>
      <div className="topbar-spacer"></div>
    </>
  );
};

export default Topbar;

// <div className="topbar-search">
//   <Searchbar cta={t('topbar.search')} height="normal" />
// </div>
