import './translatedsimpletext.css';
import { TranslatedTypeResponseOfString } from '../../../../api/petcloudapi/api';
import { useState } from 'react';
import Input from '../../../../elements/input/Input';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';

interface TranslatedSimpleTextEditorProps {
  value: TranslatedTypeResponseOfString;
  updateCallback: EditorUpdateCallback;
  translatedPrefixObject?: TranslatedTypeResponseOfString;
}

const emptyValue = {
  'de-DE': null,
  'en-GB': null,
};

const TranslatedSimpleTextEditor: React.FC<TranslatedSimpleTextEditorProps> = ({
  value,
  updateCallback,
  translatedPrefixObject,
}) => {
  const [translation, setTranslation] = useState(value ?? emptyValue);

  return (
    <div className={'jtce-simpleText'}>
      {Object.entries(translation).map(([lang, value], i) => {
        return (
          <div key={i} className={'jtce-simpleText-input'}>
            <span>{value}</span>
            <Input
              content={value}
              title={lang}
              update={(content) => {
                setTranslation({
                  ...translation,
                  [lang]: content,
                });
              }}
              prefix={
                translatedPrefixObject
                  ? translatedPrefixObject[lang as TranslatedStringIndex]
                  : undefined
              }
            />
          </div>
        );
      })}
      {updateCallback ? (
        <EditorActions save={() => updateCallback([{ value: translation }])} />
      ) : null}
    </div>
  );
};

export default TranslatedSimpleTextEditor;
