import './pricescell.css';
import { ProductPriceResponse } from '../../../../../api/petcloudapi/api';
import { ReactComponent as IconArrow } from '../../../../../../assets/icon/next.svg';

interface PricesCellProps {
  prices: ProductPriceResponse[];
  selectCellElement: (element: ProductPriceResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const PricesCell: React.FC<PricesCellProps> = ({
  prices,
  selectCellElement,
  isSelectedCellElement,
}) => {
  return (
    <div className={'pricesCell'}>
      {prices?.map((price, i) => {
        return (
          <div
            key={i}
            className={`pricesCell-price ${
              isSelectedCellElement(price) ? 'pricesCell-price__selected' : ''
            }`}
            onClick={() => selectCellElement(price)}
          >
            <div
              className={`pricesCell-price-el ${
                price.listPriceGross ? 'pricesCell-price-el-list' : ''
              }`}
            >
              {price.gross?.toFixed(2)}
            </div>
            {price.listPriceGross ? (
              <div
                className={'pricesCell-price-el'}
                style={
                  price.listPriceGross
                    ? { textDecoration: 'line-through' }
                    : undefined
                }
              >
                {price.listPriceGross?.toFixed(2)}
              </div>
            ) : null}
            <div className={'pricesCell-price-el'}>{price.currency.symbol}</div>
            <div className={'pricesCell-price-el'}>{price.country.iso3}</div>
            {price.quantityStart || price.quantityEnd ? (
              <div className={'pricesCell-price-el pricesCell-price-el-series'}>
                <span>{price.quantityStart ?? '?'}</span>
                <IconArrow
                  fill={'black'}
                  className={'pricesCell-price-el-series-arrow'}
                />
                <span>{price.quantityEnd ?? '?'}</span>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default PricesCell;
