import './categorieseditor.css';
import { ProductCategoryResponse } from '../../../../api/petcloudapi/api';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategoryHierarchy from '../../../../features/categoryhierarchy/CategoryHierarchy';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface CategoriesEditorProps {
  categories: ProductCategoryResponse[] | null;
  availableCategories: ProductCategoryResponse[];
  updateCallback: EditorUpdateCallback;
  selectedAnimalSpeciesIds?: string[];
  selectedProductGroupId: string;
  mainCategoryId?: string | null;
}

const CategoriesEditor: React.FC<CategoriesEditorProps> = ({
  categories,
  availableCategories,
  updateCallback,
  selectedAnimalSpeciesIds,
  selectedProductGroupId,
  mainCategoryId,
}) => {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState(
    categories ? [...categories] : []
  );
  const [currentMainCategoryId, setCurrentMainCategoryId] =
    useState(mainCategoryId);

  const filterTree = (
    tree: ProductCategoryResponse[],
    animalSpeciesIds: string[],
    productGroupId: string
  ) => {
    // Traverse each node of the tree
    return tree.filter((node) => {
      // If the node's product group id and animal species ids match, keep the node and all its children
      if (
        node.productGroups?.some((group) => group.id === productGroupId) &&
        node.animalSpecies?.some((species) =>
          animalSpeciesIds.includes(species.id)
        )
      ) {
        return true;
      }

      // If the node has children, recursively filter the children array.
      // If any of the children match the condition, keep the parent node.
      if (node.children && node.children.length > 0) {
        node.children = filterTree(
          node.children,
          animalSpeciesIds,
          productGroupId
        );
        if (node.children.length > 0) {
          return true;
        }
      }

      // At this point, the node neither matches the condition nor has any children matching the condition. Exclude it.
      return false;
    });
  };

  return (
    <div className={'categoriesEditor'}>
      <div className={'jtce-title'}>{t('view.product.categories.title')}</div>
      <div className={'categoriesEditor-hierarchy'}>
        {selectedAnimalSpeciesIds ? (
          <CategoryHierarchy
            availableProductCategories={filterTree(
              availableCategories,
              selectedAnimalSpeciesIds,
              selectedProductGroupId
            )}
            currentProductCategories={selectedCategories}
            updateCurrentProductCategories={(update) =>
              setSelectedCategories(
                update.map((category) => {
                  const { children, ...rest } = category;
                  return { ...rest };
                })
              )
            }
            mainCategoryId={currentMainCategoryId}
            setMainCategoryId={setCurrentMainCategoryId}
          />
        ) : (
          <EmptyState />
        )}
      </div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([
                {
                  value: selectedCategories,
                },
                {
                  value: currentMainCategoryId,
                  headerKey: 'mainCategoryId',
                },
              ]);
            } catch {}
          }}
        />
      ) : null}
    </div>
  );
};

export default CategoriesEditor;
