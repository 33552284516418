import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  AdyenPaymentTransactionResponse,
  CustomerResponse,
  OrderResponse,
} from '../../api/petcloudapi/api';
import Card, { CardSection } from '../../elements/card/Card';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import CustomerBrief from '../../sections/payment/customerbrief/CustomerBrief';
import OrderBrief from '../../sections/payment/orderbrief/OrderBrief';
import TransactionBrief from '../../sections/payment/transactionbrief/TransactionBrief';
import { Has } from '../../contexts/auth/Authorization';

const Payment = () => {
  const { t } = useTranslation();
  const params = useParams();

  const api = usePetCloudApi();
  const paymentTransactionsApi = api.paymentTransactionsApi();
  const ordersApi = api.ordersApi();
  const customersApi = api.customersApi();
  const errorHandler = useErrorHandler();

  const [payment, setPayment] =
    useState<AdyenPaymentTransactionResponse | null>(null);
  const [order, setOrder] = useState<OrderResponse | null | false>(null);
  const [customer, setCustomer] = useState<CustomerResponse | null | false>(
    null
  );

  const paymentId = params.paymentId;

  useEffect(() => {
    if (paymentId) {
      getPayment(paymentId);
    }
  }, []);

  const getPayment = (id: string) => {
    paymentTransactionsApi
      .paymentTransactionsGetTransactionById(id)
      .then((response) => {
        console.log(response);
        setPayment(response.data);
        const orderId = response.data.orderId;
        if (orderId) {
          getOrder(orderId);
        } else {
          setOrder(false);
          setCustomer(false);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getOrder = (orderId: string) => {
    ordersApi
      .ordersGetOrderById(orderId)
      .then((response) => {
        console.log(response);
        setOrder(response.data);
        const customerId = response.data.customer?.id;
        if (customerId) {
          getCustomer(customerId);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getCustomer = (customerId: string) => {
    customersApi
      .customersGetCustomerById(customerId)
      .then((response) => {
        console.log(response);
        setCustomer(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return (
    <Has authorizations={['payment_transactions:detail']} showNoAuthorization>
      {payment ? (
        <Stage>
          <Navigator
            title={t('view.payment.title')}
            allowBackButton
          ></Navigator>
          {payment.transaction ? (
            <Card
              bigScreenWidth="full"
              title={t('view.payment.transaction.title')}
            >
              <CardSection>
                <TransactionBrief transaction={payment.transaction} />
              </CardSection>
            </Card>
          ) : null}
          {order ? (
            <Card bigScreenWidth="full" title={t('view.payment.order.title')}>
              <CardSection>
                <OrderBrief order={order} />
              </CardSection>
            </Card>
          ) : order === false ? (
            <EmptyState message={t('view.payment.order.no_order')} />
          ) : (
            <LoadingContainer />
          )}
          {customer ? (
            <Card
              bigScreenWidth="full"
              title={t('view.payment.customer.title')}
            >
              <CardSection>
                <CustomerBrief customer={customer} />
              </CardSection>
            </Card>
          ) : customer === false ? null : (
            <LoadingContainer />
          )}
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Payment;
