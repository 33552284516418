import './pricing.css';
import { useState } from 'react';
import Price, { PriceType } from './Price';
import { DropdownOption } from '../../../elements/selectors/Selectors';
import UniversalProductPrice from '../../../types/UniversalProductPrice';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { Error } from '../../../contexts/errorhandler/ErrorHandler';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';

interface NewPriceWizardProps {
  productId?: string;
  currencyOptions: DropdownOption[];
  countryOptions: DropdownOption[];
  inlineCreationCallback?: (price: UniversalProductPrice) => void;
  creationCallback?: (price: UniversalProductPrice) => void;
  currentPrices: UniversalProductPrice[];
}

const NewPriceWizard: React.FC<NewPriceWizardProps> = ({
  productId,
  currencyOptions,
  countryOptions,
  inlineCreationCallback,
  creationCallback,
  currentPrices,
}) => {
  const { t } = useTranslation();
  const [newPrice, setNewPrice] = useState<UniversalProductPrice>({
    id: Math.random().toString(),
    net: -1,
    gross: 0.1,
    listPriceGross: null,
    quantityStart: null,
    quantityEnd: null,
    currencyId: 'f0902077-da87-4a7f-9bbd-c3e05a1f4a22',
    countryId: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
    productId: productId,
  });
  const [errors, setErrors] = useState<Error[]>([]);

  const updatePrice = (index: number, price: UniversalProductPrice) => {
    setNewPrice({
      ...price,
    });
  };

  const updatePriceType = (priceType: PriceType) => {
    if (priceType !== 'regular') {
      setNewPrice({ ...newPrice, quantityStart: 0, quantityEnd: 0 });
    } else {
      setNewPrice({ ...newPrice, quantityStart: null, quantityEnd: null });
    }
  };

  const submitNewPrice = () => {
    if (productId) {
      setErrors([]);
      if (
        currentPrices.find(
          (p) =>
            p.currencyId === newPrice.currencyId &&
            p.countryId === newPrice.countryId &&
            newPrice.quantityStart === null
        )
      ) {
        setErrors([
          {
            key: 'Overlap',
            values: [t('view.product.pricing.newPriceWizard.overlapError')],
          },
        ]);
      } else {
        if (creationCallback) {
          creationCallback(newPrice);
        }
      }
    } else {
      if (inlineCreationCallback) {
        inlineCreationCallback(newPrice);
      }
    }
  };

  return (
    <div className={'pricing-newPriceWizard'}>
      <ErrorCheck errors={errors} checkFor={['Overlap']} />
      <Price
        index={0}
        price={newPrice}
        updatePrice={updatePrice}
        currencyOptions={currencyOptions}
        countryOptions={countryOptions}
        mode={'NEW'}
        updatePriceType={updatePriceType}
      />
      <div className={'global-cardActions'}>
        <Button
          cta={t('actions.save')}
          action={submitNewPrice}
          look={'save'}
          width={'minimal'}
        />
      </div>
    </div>
  );
};

export default NewPriceWizard;
