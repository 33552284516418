import './linechart.css';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface LineChartProps {
  dataKey: string;
  xAxis?: { dataKey: string };
  yAxis?: { dataKey: string };
  height?: number | string;
  width?: number | string;
  data: any;
  margin?: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
  colors?: { fill: string; stroke: string };
}

const LineChart: React.FC<LineChartProps> = ({
  dataKey,
  xAxis,
  yAxis,
  height,
  width,
  data,
  margin,
}) => {
  return (
    <div className="linechart">
      <ResponsiveContainer width={width} height={height}>
        <AreaChart data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" strokeWidth={1} />
          {yAxis ? <YAxis dataKey={yAxis.dataKey} /> : null}
          {xAxis ? <XAxis dataKey={xAxis.dataKey} /> : null}
          <Tooltip />
          <Area
            dataKey={dataKey}
            stroke={'var(--color-primary)'}
            fill={'rgba(244,130,130,0.3)'}
            type={'monotoneX'}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
