import './kcal.css';
import InformationBox from '../../../elements/informationbox/InformationBox';
import { useTranslation } from 'react-i18next';
import Button from '../../../elements/button/Button';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';
import Popup from '../../../elements/popup/Popup';
import Input from '../../../elements/input/Input';
import {
  ProductGroupMappingResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../api/petcloudapi/api';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import prohibited from '../../../../assets/icon/prohibit_gray.svg';

interface KcalProps {
  hasAnalyticConstituents: boolean;
  productGroupMappings: ProductGroupMappingResponse[] | null;
  productId: string;
  propertyOptions: PropertyOptionResponse[];
  inheritedPropertyGroups?: PropertyGroupResponse[] | null;
}

const Kcal: React.FC<KcalProps> = ({
  hasAnalyticConstituents,
  productId,
  productGroupMappings,
  propertyOptions,
  inheritedPropertyGroups,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.product.kcal',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [isLoadingKcal, setIsLoadingKcal] = useState(false);
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [popup, setPopup] = useState(false);
  const [kcal, setKcal] = useState<number | null>(null);
  const [kcalMapping, setKcalMapping] =
    useState<ProductGroupMappingResponse | null>(null);

  const kcalPropertyGroupId = process.env.REACT_APP_KCAL100G_PROPERTY_GROUP_ID;

  useEffect(() => {
    getMapping();
    getCurrentKcal();
  }, [productGroupMappings]);

  const getMapping = () => {
    console.log(kcalPropertyGroupId);
    setKcalMapping(
      productGroupMappings?.find(
        (m) => m.propertyGroupId === kcalPropertyGroupId
      ) ?? null
    );
  };

  const getCurrentKcal = () => {
    const current = propertyOptions.find(
      (o) => o.propertyGroupId === kcalPropertyGroupId
    )?.name['de-DE'];
    if (current) {
      setKcal(parseInt(current));
    }
  };

  const calculateCalories = () => {
    setIsLoadingKcal(true);
    productsApi
      .productsGetCalculatedCalories(productId)
      .then((response) => {
        setKcal(response.data);
        setPopup(true);
        setIsLoadingKcal(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsLoadingKcal(false);
      });
  };

  const setProperty = () => {
    const propertyGroup = kcalMapping?.propertyGroup;
    if (kcal && propertyGroup && propertyGroup.animalSpecies) {
      setIsLoadingProperty(true);
      productGroupMappingsApi
        .productGroupMappingsValidateAndCreateUserInput(kcalMapping.id, {
          propertyGroupId: propertyGroup.id,
          propertyValue: {
            'de-DE': kcal.toString(),
            'en-GB': kcal.toString(),
          },
          animalSpeciesIds: propertyGroup.animalSpecies?.map((x) => x.id),
          isVariantOption: false,
        })
        .then((response) => {
          const propertyOptionIds = [response.data.id];

          // keep current options if there are any
          propertyOptions.forEach((o) => {
            if (o.propertyGroupId !== kcalPropertyGroupId) {
              propertyOptionIds.push(o.id);
            }
          });

          // transfer inherited properties if there are any
          inheritedPropertyGroups?.forEach((group) => {
            group.options.forEach((option) => {
              propertyOptionIds.push(option.id);
            });
          });

          productsApi
            .productsUpdateProductProperties(productId, {
              propertyOptionIds: propertyOptionIds,
            })
            .then((response) => {
              setIsLoadingProperty(false);
              pushNotification(t('notifications.success'));
              setPopup(false);
            })
            .catch((error) => {
              console.log(error);
              errorHandler.addError(error.response);
              setIsLoadingProperty(false);
            });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsLoadingProperty(false);
        });
    }
  };

  const getInheritedKcal = (): string | null => {
    const inherited = inheritedPropertyGroups?.find(
      (g) => g.id === kcalPropertyGroupId
    )?.options[0].name['de-DE'];
    if (inherited && (!propertyOptions || propertyOptions.length === 0)) {
      return inherited;
    } else {
      return null;
    }
  };

  const hasInheritedProperties = (): boolean => {
    return (
      propertyOptions.length === 0 &&
      !!inheritedPropertyGroups &&
      inheritedPropertyGroups.length > 0
    );
  };

  return (
    <div className={'kcal'}>
      {kcalMapping ? (
        <>
          <InformationBox type={'info'} content={t('info')} />
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={t('currentValue')}
                unit={'kCal'}
                content={
                  kcal
                    ? kcal.toString()
                    : getInheritedKcal()
                    ? null
                    : t('emptyValue')
                }
                inherited={getInheritedKcal()}
              />
            </div>
          </div>
          {!hasAnalyticConstituents ? (
            <InformationBox type={'warning'} content={t('warning')} />
          ) : null}
          <Button
            cta={kcal ? t('cta_renew') : t('cta_new')}
            active={hasAnalyticConstituents}
            look={'secondary'}
            width={'minimal'}
            isLoading={isLoadingKcal}
            action={calculateCalories}
          />
          {kcal && popup ? (
            <Popup toggled={!!kcal} width={'30%'} close={() => setPopup(false)}>
              <div className={'popup-title'}>{t('popup.title')}</div>
              {hasInheritedProperties() ? (
                <InformationBox
                  type={'warning'}
                  title={t('popup.inheritanceWarning.title')}
                  content={t('popup.inheritanceWarning.text')}
                  maxWidth={400}
                />
              ) : null}
              <div className={'global-inputGroup'}>
                <div className={'global-inputGroup-input'}>
                  <Input
                    title={t('popup.inputTitle')}
                    content={kcal.toString()}
                    update={(value) => {
                      setKcal(parseInt(value));
                    }}
                    type={'number'}
                    unit={'kCal'}
                  />
                </div>
              </div>
              <div className={'global-cardActions'}>
                <Button
                  cta={t('popup.cta')}
                  width={'full'}
                  look={'save'}
                  isLoading={isLoadingProperty}
                  action={setProperty}
                />
              </div>
            </Popup>
          ) : null}
        </>
      ) : (
        <EmptyState message={t('emptyState')} img={prohibited} />
      )}
    </div>
  );
};

export default Kcal;
