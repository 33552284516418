import { Error } from '../../contexts/errorhandler/ErrorHandler';
import InformationBox from '../informationbox/InformationBox';
import { memo } from 'react';

interface ErrorCheckProps {
  errors?: Error[] | null;
  checkFor: string[];
  errorCallback?: () => void;
}

const ErrorCheck: React.FC<ErrorCheckProps> = ({
  errors,
  checkFor,
  errorCallback,
}) => {
  const errorArray = errors?.find((err) => checkFor.includes(err.key))?.values;
  if (errorArray) {
    if (errorCallback) {
      errorCallback();
    }
    return (
      <>
        {errorArray.map((error, i) => {
          return <InformationBox key={i} content={error} type={'error'} />;
        })}
      </>
    );
  } else {
    return null;
  }
};

const deepCompare = (
  prevProps: ErrorCheckProps,
  nextProps: ErrorCheckProps
) => {
  return prevProps.errors === nextProps.errors;
};

export default memo(ErrorCheck, deepCompare);
