import './dogtag.css';
import ProfileImage from './profileimage/ProfileImage';

interface DogTagProps {
  name: string;
  lastName?: string;
  img?: string;
  tags?: { text: string; color: string }[];
  onClick: () => void;
  isProfile?: boolean;
}

const DogTag: React.FC<DogTagProps> = ({
  name,
  lastName,
  img,
  tags,
  onClick,
  isProfile,
  children,
}) => {
  return (
    <div className="dogtag" onClick={onClick}>
      {isProfile ? (
        <div className={'dogtag-profileImage'}>
          <ProfileImage imgSrc={img} name={name} lastName={lastName} />
        </div>
      ) : null}
      <div className="dogtag-meta">
        <div className="dogtag-meta-name">
          <div className="dogtag-meta-name-item">{name}</div>
          {lastName ? (
            <div className="dogtag-meta-name-item">{lastName}</div>
          ) : null}
        </div>
        {tags ? (
          <div className="dogtag-meta-tags">
            {tags.map((tag, i) => {
              return (
                <div
                  key={i}
                  className="dogtag-meta-tag"
                  style={{ color: tag.color }}
                >
                  {tag.text}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {children ? <div className="dogtag-children">{children}</div> : null}
    </div>
  );
};

export default DogTag;
