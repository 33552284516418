import { TranslatedTypeResponseOfString } from '../../../api/petcloudapi/api';
import { ClipboardFunction } from '../ProductBulkEditor';

const useClipboardFunctions = () => {
  const copyTranslatedContent = (data: TranslatedTypeResponseOfString) => {
    let result = '';
    const arr = Object.values(data);
    arr.forEach((v, i) => {
      if (v) {
        result += v + (i !== arr.length - 1 ? '\n' : '');
      }
    });
    return result;
  };

  const bulkEditorClipboardFunctionsConfig: ClipboardFunction[] = [
    {
      key: 'name',
      func: copyTranslatedContent,
    },
    {
      key: 'description',
      func: copyTranslatedContent,
    },
  ];

  return { copyTranslatedContent, bulkEditorClipboardFunctionsConfig };
};

export default useClipboardFunctions;
