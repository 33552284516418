import './knowledgebasepopup.css';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useState } from 'react';
import Popup from '../popup/Popup';
import { KnowledgeBaseGroup } from '../../sections/knowledgebase/KnowledgeBase';

export type KnowledgeBaseInfoItem = {
  title: string;
  text: string;
  mediaUri?: string;
  videoUri?: string;
};

interface KnowledgeBasePopupProps {
  infoItems?: KnowledgeBaseInfoItem[];
  customButton?: ReactNode;
}

const KnowledgeBasePopup: React.FC<KnowledgeBasePopupProps> = ({
  infoItems,
  customButton,
}) => {
  const { t } = useTranslation();

  const [mediaViewer, setMediaViewer] = useState<string | null | undefined>(
    null
  );
  const [toggled, setToggled] = useState(false);

  return (
    <div className={'knowledgebasepopup'}>
      {customButton ? (
        <div onClick={() => setToggled(true)}>{customButton}</div>
      ) : (
        <div
          className={'knowledgebasepopup-cta'}
          onClick={() => setToggled(true)}
        >
          {t('components.knowledgebasepopup.cta')}
        </div>
      )}
      <Popup width={'50%'} toggled={toggled} close={() => setToggled(false)}>
        <div className={'popup-title'}>
          {t('components.knowledgebasepopup.title')}
        </div>
        <Popup
          width={'50%'}
          toggled={!!mediaViewer}
          close={() => setMediaViewer(null)}
        >
          <img
            src={mediaViewer ?? 'oops'}
            className={'knowledgebasepopup-infoItems-mediaViewer-img'}
            alt={'Support Overlay Media'}
          />
        </Popup>
        {infoItems && toggled ? (
          <KnowledgeBaseGroup
            infoItems={infoItems}
            setMediaViewerCallback={(uri) => setMediaViewer(uri)}
          />
        ) : null}
      </Popup>
    </div>
  );
};

export default KnowledgeBasePopup;
