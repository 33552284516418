import { SmallHint } from '../hint/Hint';
import './colorpicker.css';

interface ColorPickerProps {
  title?: string;
  hint?: string;
  colorHex?: string | null;
  update: (e: React.FormEvent<HTMLInputElement>) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  title,
  hint,
  colorHex,
  update,
}) => {
  return (
    <div className="colorpicker">
      <div className="colorpicker-header">
        <div className="colorpicker-header-title">{title}</div>
        {hint ? <SmallHint paragraphs={[hint]} /> : null}
      </div>
      <label className="colorpicker-picker">
        <input
          type="color"
          value={colorHex ?? '#ffffff'}
          className="colorpicker-input"
          onChange={update}
        />
        <div className="colorpicker-color-wrapper">
          <div
            className="colorpicker-color"
            style={{ backgroundColor: colorHex ?? '#ffffff' }}
          ></div>
        </div>
        <div className="colorpicker-spacer"></div>
      </label>
    </div>
  );
};

export default ColorPicker;
