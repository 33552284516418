import { createContext, useContext, useEffect, useState } from 'react';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { UserResponse } from '../../api/petcloudapi/api';
import { useErrorHandler } from '../errorhandler/ErrorHandler';
import { Cookies } from 'react-cookie';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useAuthentication } from './Authentication';
import useStorageData from '../../hooks/useStorageData';

type AuthenticationType = 'LOGGED_IN' | 'NOT_LOGGED_IN';

export type UserContextValue = {
  user: UserResponse | null;
  authentication: AuthenticationType | null;
  logUserOut: () => void;
  getUser: (refresh: boolean) => void;
};

const UserContext = createContext({} as UserContextValue);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider: React.FC = ({ children }) => {
  const cookies = new Cookies();
  const api = usePetCloudApi();
  const usersApi = api.usersApi();
  const errorHandler = useErrorHandler();
  const { logout } = useAuthentication();
  const { persistObject, removePersistedObjects, getPersistedObject } =
    useStorageData();
  const [user, setUser] = useState<UserResponse | null>(null);
  const [authentication, setAuthentication] =
    useState<AuthenticationType | null>(null);

  useEffect(() => {
    if (cookies.get('access_token')) {
      getUser();
    } else if (cookies.get('refresh_token')) {
      getUser();
    } else {
      setAuthentication('NOT_LOGGED_IN');
    }
  }, []);

  const getUser = (refresh?: boolean) => {
    const storageUser = getPersistedObject('userData');
    if (storageUser && !refresh) {
      setUser(storageUser);
      setAuthentication('LOGGED_IN');
    } else {
      usersApi
        .usersGetCurrentUser()
        .then((response) => {
          console.log(response);
          setUser(response.data);
          persistObject(response.data, 'userData');
          setAuthentication('LOGGED_IN');
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const logUserOut = () => {
    setAuthentication('NOT_LOGGED_IN');
    setUser(null);
    removePersistedObjects(['userData']);
    logout();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        authentication,
        logUserOut,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Authentication Component - only renders children if authentication matches

interface AuthenticationProps {
  required: AuthenticationType;
}

export const Authentication: React.FC<AuthenticationProps> = ({
  children,
  required,
}) => {
  const { authentication } = useUser();

  const match = required === authentication;

  if (required === 'LOGGED_IN') {
    if (authentication) {
      if (match) {
        return <>{children}</>;
      } else {
        return null;
      }
    } else {
      return <LoadingContainer />;
    }
  } else {
    if (match) {
      return <>{children}</>;
    } else {
      return null;
    }
  }
};
