import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  IntegrationSubModuleResponse,
  IntegrationSubModuleType,
} from '../../../api/petcloudapi/api';
import { Has } from '../../../contexts/auth/Authorization';
import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../../layout/stage/Stage';
import Card, { CardSection } from '../../../elements/card/Card';
import { LoadingContainer } from '../../../elements/loading/Loading';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import { ShopifyIntegrationConfigResponse } from '../../../api/shopifyapi';
import useNotifications from '../../../hooks/useNotifications';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import ShopifyProductImport from '../../../sections/integration/integrationsubmodules/shopify/shopifyproductimport/ShopifyProductImport';
import ShopifyOrderExport from '../../../sections/integration/integrationsubmodules/shopify/shopifyorderexport/ShopifyOrderExport';
import ShopifyProductStocks from '../../../sections/integration/integrationsubmodules/shopify/shopifyproductstocks/ShopifyProductStocks';
import ShopifyOrderDeliveryInformationImport from '../../../sections/integration/integrationsubmodules/shopify/shopifyorderdeliveryinformationimport/ShopifyOrderDeliveryInformationImport';
import { useNavigate } from 'react-router-dom';
import useManufacturerOptions from '../../../hooks/useManufacturerOptions';
import { ReactComponent as IconLink } from '../../../../assets/icon/link.svg';
import installApp from '../../../../assets/img/integration_app_install.svg';

interface ShopifyIntegrationProps {
  integrationId: string;
  manufacturerId?: string;
}

const ShopifyIntegration: React.FC<ShopifyIntegrationProps> = ({
  integrationId,
  manufacturerId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integration.shopify',
  });
  const api = usePetCloudApi();
  const integrationsApi = api.integrationsApi();
  const shopifyIntegrationApi = api.shopifyIntegrationApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { manufacturerDict } = useManufacturerOptions();
  const link = useNavigate();

  const [integration, setIntegration] =
    useState<ShopifyIntegrationConfigResponse | null>(null);
  const [submodules, setSubModules] = useState<
    IntegrationSubModuleResponse[] | null
  >(null);
  const [dangerPopup, setDangerPopup] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    if (integrationId) {
      getIntegration(manufacturerId);
      getSubModules(integrationId, manufacturerId);
    }
  }, [integrationId]);

  const getIntegration = (id: string | undefined) => {
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigGet(id)
      .then((response) => {
        console.log(response);
        setIntegration(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getSubModules = (integrationId: string, manufacturerId?: string) => {
    integrationsApi
      .integrationsGetIntegrationSubModules(integrationId, manufacturerId)
      .then((response) => {
        console.log(response);
        setSubModules(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const updateIntegration = (req: ShopifyIntegrationConfigResponse) => {
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigPost(req)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_successful'));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const resetConfig = () => {
    setIsResetting(true);
    shopifyIntegrationApi
      .shopifyV1ApiIntegrationConfigDelete(manufacturerId)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.reset_successful'));
        setIsResetting(false);
        setDangerPopup(false);
        link('/integrations');
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsResetting(false);
      });
  };

  const hasProductImportConfig = (modules: IntegrationSubModuleResponse[]) => {
    return !!modules.find((x) => x.type === 'ProductImport')?.config;
  };

  const hasOrderExportConfig = (modules: IntegrationSubModuleResponse[]) => {
    return !!modules.find((x) => x.type === 'OrderExport')?.config;
  };

  const renderSubModules = (modules: IntegrationSubModuleResponse[]) => {
    // first order the modules so their appearance makes sense from an UX perspective
    const sortedModules: IntegrationSubModuleResponse[] = [];
    const productImport = modules.find((x) => x.type === 'ProductImport');
    if (productImport) {
      sortedModules.push(productImport);
    }
    const productStockImport = modules.find(
      (x) => x.type === 'ProductStockImport'
    );
    if (productStockImport) {
      sortedModules.push(productStockImport);
    }
    const orderExport = modules.find((x) => x.type === 'OrderExport');
    if (orderExport) {
      sortedModules.push(orderExport);
    }
    const orderDeliveryInformationImport = modules.find(
      (x) => x.type === 'OrderDeliveryInformationImport'
    );
    if (orderDeliveryInformationImport) {
      sortedModules.push(orderDeliveryInformationImport);
    }

    // then render them
    return sortedModules.map((module, i) => {
      const { type, id, config } = module;
      const props = {
        manufacturerId: manufacturerId,
        integrationSubModuleId: id,
        fetchedConfig: config,
      };
      switch (type) {
        case IntegrationSubModuleType.ProductImport:
          return (
            <ShopifyProductImport
              key={i}
              {...props}
              locked={
                integration && integration.installed !== undefined
                  ? !integration.installed
                  : false
              }
            />
          );
        case IntegrationSubModuleType.OrderExport:
          return (
            <ShopifyOrderExport
              key={i}
              {...props}
              locked={!hasProductImportConfig(modules)}
            />
          );
        case IntegrationSubModuleType.ProductStockImport:
          return (
            <ShopifyProductStocks
              key={i}
              {...props}
              locked={!hasProductImportConfig(modules)}
            />
          );
        case IntegrationSubModuleType.OrderDeliveryInformationImport:
          return (
            <ShopifyOrderDeliveryInformationImport
              key={i}
              {...props}
              locked={!hasOrderExportConfig(modules)}
            />
          );
        default:
          return <div>Something went wrong...</div>;
      }
    });
  };

  if (integration && submodules) {
    return (
      <Has authorizations={['integrations_api:list']} showNoAuthorization>
        <Stage>
          <Navigator
            title={`${t('title')}${
              manufacturerDict &&
              manufacturerId &&
              manufacturerDict !== 'NO_PERMISSION'
                ? ': ' + manufacturerDict[manufacturerId].companyName
                : ''
            }`}
            allowBackButton
          ></Navigator>
          {integration.installed ? (
            <StageColumns>
              <StageColumn size={'two'}>
                {renderSubModules(submodules)}
              </StageColumn>
              <StageColumn size={'one'}>
                <Card bigScreenWidth={'100%'}>
                  <CardSection title={t('settings.title')}>
                    <ToggleSwitch
                      title={t('settings.toggle')}
                      toggled={integration.enabled}
                      toggle={() => {
                        const update = {
                          ...integration,
                          enabled: !integration.enabled,
                        };
                        updateIntegration(update);
                        setIntegration(update);
                      }}
                    />
                  </CardSection>
                  <CardSection>
                    <Button
                      cta={t('settings.reset')}
                      width={'full'}
                      look={'secondary-danger'}
                      action={() => setDangerPopup(true)}
                    />
                    <Popup
                      toggled={dangerPopup}
                      width={'20%'}
                      close={() => setDangerPopup(false)}
                    >
                      <div className={'popup-title'}>
                        {t('settings.dangerPopup.title')}
                      </div>
                      <div className={'global-textElement'}>
                        {t('settings.dangerPopup.text')}
                      </div>
                      <Button
                        cta={t('settings.dangerPopup.cta')}
                        width={'full'}
                        look={'danger'}
                        action={resetConfig}
                        isLoading={isResetting}
                      />
                    </Popup>
                  </CardSection>
                </Card>
              </StageColumn>
            </StageColumns>
          ) : (
            <Card bigScreenWidth={'50%'} title={t('emptyState.title')}>
              <CardSection>
                <img
                  src={installApp}
                  style={{
                    display: 'block',
                    width: 300,
                    margin: '0 auto',
                    marginBottom: 20,
                  }}
                  alt={'App Install'}
                />
                <div className={'global-textElement'}>
                  {t('emptyState.text')}
                </div>
                <Button
                  cta={t('emptyState.cta')}
                  width={'minimal'}
                  look={'primary'}
                  type={'icon-text'}
                  action={
                    integration?.installationLink
                      ? () => window.open(integration.installationLink)?.focus()
                      : undefined
                  }
                >
                  <IconLink className={'button-icon button-icon-primary'} />
                </Button>
              </CardSection>
            </Card>
          )}
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ShopifyIntegration;
