import './knowledgebase.css';
import { StageColumn, StageColumns } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import { KnowledgeBaseInfoItem } from '../../elements/knowledgebasepopup/KnowledgeBasePopup';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import knowledge from '../../../assets/img/knowledge.svg';

type KnowledgeBaseInfoSection = {
  id: string;
  title: string;
  groups: KnowledgeBaseInfoGroup[];
};

type KnowledgeBaseInfoGroup = {
  title: string;
  items: KnowledgeBaseInfoItem[];
};

const KnowledgeBase = () => {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] =
    useState<KnowledgeBaseInfoSection | null>(null);

  const knowledgebase: KnowledgeBaseInfoSection[] = [
    {
      id: 'product',
      title: t('knowledgebase.product.title'),
      groups: [
        {
          title: t('knowledgebase.product.tutorials.title'),
          items: [
            {
              title: t('knowledgebase.product.tutorials.new.title'),
              text: t('knowledgebase.product.tutorials.new.text'),
              videoUri: t('knowledgebase.product.tutorials.new.video'),
            },
            {
              title: t('knowledgebase.product.tutorials.ready.title'),
              text: t('knowledgebase.product.tutorials.ready.text'),
              videoUri: t('knowledgebase.product.tutorials.ready.video'),
            },
            {
              title: t('knowledgebase.product.tutorials.pricing.title'),
              text: t('knowledgebase.product.tutorials.pricing.text'),
              videoUri: t('knowledgebase.product.tutorials.pricing.video'),
            },
            {
              title: t('knowledgebase.product.tutorials.activation.title'),
              text: t('knowledgebase.product.tutorials.activation.text'),
              videoUri: t('knowledgebase.product.tutorials.activation.video'),
            },
          ],
        },
        {
          title: t('knowledgebase.product.additionalInformation.title'),
          items: [
            {
              title: t(
                'knowledgebase.product.additionalInformation.table.title'
              ),
              text: t('knowledgebase.product.additionalInformation.table.text'),
              mediaUri: t(
                'knowledgebase.product.additionalInformation.table.media'
              ),
            },
            {
              title: t(
                'knowledgebase.product.additionalInformation.richtext.title'
              ),
              text: t(
                'knowledgebase.product.additionalInformation.richtext.text'
              ),
              mediaUri: t(
                'knowledgebase.product.additionalInformation.richtext.media'
              ),
            },
            {
              title: t(
                'knowledgebase.product.additionalInformation.media.title'
              ),
              text: t('knowledgebase.product.additionalInformation.media.text'),
              mediaUri: t(
                'knowledgebase.product.additionalInformation.media.media'
              ),
            },
            {
              title: t(
                'knowledgebase.product.additionalInformation.downloads.title'
              ),
              text: t(
                'knowledgebase.product.additionalInformation.downloads.text'
              ),
              mediaUri: t(
                'knowledgebase.product.additionalInformation.downloads.media'
              ),
            },
            {
              title: t('knowledgebase.product.analyticConstituents.title'),
              text: t('knowledgebase.product.analyticConstituents.text'),
              mediaUri: t('knowledgebase.product.analyticConstituents.media'),
            },
          ],
        },
        {
          title: t('knowledgebase.product.tips.title'),
          items: [
            {
              title: t(
                'knowledgebase.product.tips.updatingMediaPositions.title'
              ),
              text: t('knowledgebase.product.tips.updatingMediaPositions.text'),
              mediaUri: t(
                'knowledgebase.product.tips.updatingMediaPositions.media'
              ),
            },
            {
              title: t('knowledgebase.product.tips.listPrice.title'),
              text: t('knowledgebase.product.tips.listPrice.text'),
              mediaUri: t('knowledgebase.product.tips.listPrice.media'),
            },
          ],
        },
      ],
    },
    {
      id: 'settings',
      title: t('knowledgebase.settings.title'),
      groups: [
        {
          title: t('knowledgebase.settings.team.title'),
          items: [
            {
              title: t('knowledgebase.settings.team.addMember.title'),
              text: t('knowledgebase.settings.team.addMember.text'),
              videoUri: t('knowledgebase.settings.team.addMember.media'),
            },
          ],
        },
      ],
    },
    {
      id: 'logistics',
      title: t('knowledgebase.logistics.title'),
      groups: [
        {
          title: t('knowledgebase.logistics.stocks.title'),
          items: [
            {
              title: t('knowledgebase.logistics.stocks.csv.title'),
              text: t('knowledgebase.logistics.stocks.csv.text'),
              videoUri: t('knowledgebase.logistics.stocks.csv.media'),
            },
          ],
        },
        {
          title: t('knowledgebase.logistics.purchaseOrders.title'),
          items: [
            {
              title: t('knowledgebase.logistics.purchaseOrders.create.title'),
              text: t('knowledgebase.logistics.purchaseOrders.create.text'),
              videoUri: t(
                'knowledgebase.logistics.purchaseOrders.create.media'
              ),
            },
          ],
        },
      ],
    },
    {
      id: 'order',
      title: t('knowledgebase.order.title'),
      groups: [
        {
          title: t('knowledgebase.order.overview.title'),
          items: [
            {
              title: t('knowledgebase.order.overview.central.title'),
              text: t('knowledgebase.order.overview.central.text'),
              videoUri: t('knowledgebase.order.overview.central.media'),
            },
            {
              title: t('knowledgebase.order.overview.dropshipping.title'),
              text: t('knowledgebase.order.overview.dropshipping.text'),
              videoUri: t('knowledgebase.order.overview.dropshipping.media'),
            },
          ],
        },
        {
          title: t('knowledgebase.order.tutorials.title'),
          items: [
            {
              title: t(
                'knowledgebase.order.tutorials.returnAnnouncement.title'
              ),
              text: t('knowledgebase.order.tutorials.returnAnnouncement.text'),
              videoUri: t(
                'knowledgebase.order.tutorials.returnAnnouncement.media'
              ),
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className={'knowledgebase'}>
      <StageColumns>
        <StageColumn size={'one'}>
          <Card
            bigScreenWidth={'full'}
            helperCSSClass={'knowledgebase-sidebarWrapper'}
          >
            <div className={'knowledgebase-sidebar'}>
              {knowledgebase.map((section) => {
                return (
                  <div
                    className={
                      selectedSection?.id === section.id
                        ? 'knowledgebase-sidebar-item knowledgebase-sidebar-item_selected'
                        : 'knowledgebase-sidebar-item'
                    }
                    onClick={() => setSelectedSection(section)}
                  >
                    {section.title}
                  </div>
                );
              })}
            </div>
          </Card>
        </StageColumn>
        <StageColumn size={'two'}>
          <Card bigScreenWidth={'full'}>
            <CardSection>
              {selectedSection ? (
                <div className={'knowledgebase-groups'}>
                  {' '}
                  {selectedSection.groups.map((group) => {
                    return (
                      <div className={'knowledgebase-group'}>
                        <div className={'knowledgebase-group-title'}>
                          {group.title}
                        </div>
                        <KnowledgeBaseGroup infoItems={group.items} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <EmptyState
                  img={knowledge}
                  size={240}
                  message={t('knowledgebase.empty')}
                />
              )}
            </CardSection>
          </Card>
        </StageColumn>
      </StageColumns>
    </div>
  );
};

export default KnowledgeBase;

interface KnowledgeBaseGroupProps {
  infoItems: KnowledgeBaseInfoItem[];
  setMediaViewerCallback?: (uri: string | undefined) => void;
  inline?: boolean;
}

export const KnowledgeBaseGroup: React.FC<KnowledgeBaseGroupProps> = ({
  infoItems,
  setMediaViewerCallback,
  inline,
}) => {
  return (
    <div
      className={`knowledgebase-infoItems ${
        inline ? 'knowledgebase-infoItems-inline' : ''
      }`}
    >
      {infoItems.map((info) => {
        return (
          <div className={'knowledgebase-infoItems-item'}>
            <div className={'knowledgebase-infoItems-column'}>
              <div className={'knowledgebase-infoItems-item-title'}>
                {info.title}
              </div>
              <div className={'knowledgebase-infoItems-item-text'}>
                {info.text}
              </div>
            </div>
            {info.mediaUri ? (
              <div className={'knowledgebase-infoItems-column'}>
                <div className={'knowledgebase-infoItems-item-media'}>
                  <img
                    src={info.mediaUri}
                    className={'knowledgebase-infoItems-item-media-img'}
                    alt={'Support Overlay Media'}
                    onClick={
                      setMediaViewerCallback
                        ? (e) => {
                            e.stopPropagation();
                            setMediaViewerCallback(info.mediaUri);
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            ) : null}
            {info.videoUri ? (
              <div className={'knowledgebase-infoItems-column'}>
                <div className={'knowledgebase-infoItems-item-media'}>
                  <video
                    src={info.videoUri}
                    className={'knowledgebase-infoItems-item-media-img'}
                    controls
                  />
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
