import './contacts.css';
import { useState, useEffect } from 'react';
import { TFunction } from 'i18next';
import {
  ManufacturerResponse,
  CreateManufacturerContactRequest,
  ManufacturerContactResponse,
  Department,
} from '../../../api/petcloudapi/api';
import DogTag from '../../../elements/dogtag/DogTag';
import Button from '../../../elements/button/Button';
import ContactEdit from './contactedit/ContactEdit';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import Popup from '../../../elements/popup/Popup';
import ItemActions from '../../../elements/itemactions/ItemActions';
import { Store } from 'react-notifications-component';

interface ContactsProps {
  t: TFunction;
  manufacturer: ManufacturerResponse;
  refreshManufacturer?: () => void;
}

const Contacts: React.FC<ContactsProps> = ({
  t,
  manufacturer,
  refreshManufacturer,
}) => {
  const [additionalContact, setAdditionalContact] =
    useState<CreateManufacturerContactRequest | null>(null);
  const [memberToEdit, setMemberToEdit] =
    useState<ManufacturerContactResponse | null>(null);
  const [originalMemberToEdit, setOriginalMemberToEdit] =
    useState<ManufacturerContactResponse | null>(null);
  const [memberToEditIsSubmitting, setMemberToEditIsSubmitting] =
    useState(false);
  const [additionalContactIsSubmitting, setAdditionalContactIsSubmitting] =
    useState(false);
  const manufacturerContactsApi = usePetCloudApi().manufacturerContactsApi();

  // this will update the current memberToEdit when the manufacturer gets refreshed
  useEffect(() => {
    if (memberToEdit) {
      const id = memberToEdit.id;
      const updatedMember = (
        manufacturer as ManufacturerResponse
      ).manufacturerContacts?.find((user) => user.id === id);
      if (updatedMember) {
        setMemberToEdit(updatedMember);
      }
    }
  }, [manufacturer]);

  const initializeAdditionalContact = () => {
    setAdditionalContact({
      manufacturerId: manufacturer.id,
      department: 'ManagingDirector' as Department,
      email: '',
      title: '',
      salutation: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      street: '',
      streetNumber: '',
      zipCode: '',
      city: '',
      state: '',
      additionalAddressLine1: '',
      additionalAddressLine2: '',
      countryId: '',
    });
  };

  const updateAdditionalContact = (
    member: CreateManufacturerContactRequest
  ) => {
    setAdditionalContact({ ...member });
  };

  const validateAdditionalContact = () => {
    if (additionalContact) {
      if (additionalContact.salutation !== '') {
        if (additionalContact.firstName !== '') {
          if (additionalContact.lastName !== '') {
            if (additionalContact.phoneNumber !== '') {
              return additionalContact.email !== '';
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const submitAdditionalContact = () => {
    if (additionalContact) {
      manufacturerContactsApi
        .manufacturerContactsCreateManufacturerContact(additionalContact)
        .then((response) => {
          console.log(response);
          setAdditionalContact(null);
          if (refreshManufacturer) {
            refreshManufacturer();
          }
          setAdditionalContactIsSubmitting(false);
          Store.addNotification({
            message: t('components.contacts.notifications.create_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateMemberToEdit = (user: ManufacturerContactResponse) => {
    setMemberToEdit({ ...user });
  };

  const submitMemberToEdit = () => {
    if (memberToEdit) {
      manufacturerContactsApi
        .manufacturerContactsUpdateManufacturerContact(
          memberToEdit.id,
          memberToEdit
        )
        .then((response) => {
          console.log(response);
          if (refreshManufacturer) {
            refreshManufacturer();
          }
          setMemberToEditIsSubmitting(false);
          setMemberToEdit(null);
          setOriginalMemberToEdit(null);
          Store.addNotification({
            message: t('components.contacts.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteUser = (id: string) => {
    manufacturerContactsApi
      .manufacturerContactsDeleteManufacturerContact(id)
      .then((response) => {
        console.log(response);
        if (refreshManufacturer) {
          refreshManufacturer();
        }
        Store.addNotification({
          message: t('components.contacts.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="contacts">
      <div className="contacts-members">
        {manufacturer.manufacturerContacts?.map((contact, i) => {
          return (
            <DogTag
              key={i}
              name={contact.firstName}
              lastName={contact.lastName}
              onClick={() => {
                setMemberToEdit(contact);
                setOriginalMemberToEdit(contact);
              }}
              isProfile
            >
              <ItemActions
                item={contact}
                actions={[
                  {
                    cta: 'edit',
                    look: 'blue',
                    action: () => {
                      setMemberToEdit(contact);
                      setOriginalMemberToEdit(contact);
                    },
                  },
                  {
                    cta: 'delete',
                    look: 'danger',
                    action: () => deleteUser(contact.id),
                  },
                ]}
              />
            </DogTag>
          );
        })}
      </div>
      <Popup
        toggled={!!memberToEdit}
        close={() => {
          setMemberToEdit(null);
          setOriginalMemberToEdit(null);
        }}
        width="50%"
      >
        <div className="contacts-popup-title">
          {t('components.contacts.popup_edit.title')}
        </div>
        {memberToEdit ? (
          <ContactEdit
            member={memberToEdit}
            updateCurrentUser={updateMemberToEdit}
          />
        ) : null}
        <div className="contacts-popup-actions global-cardActions">
          <Button
            cta={t('actions.cancel')}
            look={'secondary'}
            action={() => {
              setMemberToEdit(null);
              setOriginalMemberToEdit(null);
            }}
          />
          <Button
            cta={t('actions.save')}
            look="save"
            action={() => {
              submitMemberToEdit();
              setMemberToEditIsSubmitting(true);
            }}
            margin="left"
            active={memberToEdit !== originalMemberToEdit}
            isLoading={memberToEditIsSubmitting}
          />
        </div>
      </Popup>
      <div className="global-cardActions-left">
        <Button
          cta={t('components.contacts.add')}
          look={'secondary'}
          action={initializeAdditionalContact}
          width="minimal"
        />
      </div>
      {additionalContact ? (
        <Popup
          toggled={!!additionalContact}
          close={() => setAdditionalContact(null)}
          width="50%"
        >
          <div className="contacts-popup-title">
            {t('components.contacts.popup_add.title')}
          </div>
          <ContactEdit
            member={additionalContact}
            updateNewUser={updateAdditionalContact}
          />
          <div className="global-cardActions">
            <Button
              cta={t('actions.save')}
              look="save"
              action={() => {
                submitAdditionalContact();
                setAdditionalContactIsSubmitting(true);
              }}
              width="minimal"
              active={validateAdditionalContact()}
              isLoading={additionalContactIsSubmitting}
            />
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default Contacts;
