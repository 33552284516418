import './propertyvalidationruleedit.css';
import {
  CreatePropertyValidationRuleRequest,
  PropertyValidationRuleCallable,
  UpdatePropertyValidationRuleRequest,
  ValidationConstraint,
} from '../../../../api/petcloudapi/api';
import Input from '../../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import ValueArray from '../../../../elements/valuearray/ValueArray';
import { useState } from 'react';
import Popup from '../../../../elements/popup/Popup';
import Button from '../../../../elements/button/Button';

const callables = [
  'IsIntegerBetween',
  'IsValueOrSplitValue',
  'IsInteger',
  'IsDecimal',
];
const constraints = ['NotNull', 'NotEmpty', 'IsInteger', 'IsNull'];

interface PropertyValidationRuleEditProps {
  request:
    | CreatePropertyValidationRuleRequest
    | UpdatePropertyValidationRuleRequest;
  update: (
    request:
      | CreatePropertyValidationRuleRequest
      | UpdatePropertyValidationRuleRequest
  ) => void;
}

const PropertyValidationRuleEdit: React.FC<PropertyValidationRuleEditProps> = ({
  request,
  update,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.propertyValidationRules.edit',
  });
  const [argumentsEditor, setArgumentsEditor] = useState(false);
  const [newArgument, setNewArgument] = useState<string | null>(null);

  return (
    <div className={'propertyValidationRuleEdit'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('name')}
            content={request.name}
            update={(e) => {
              update({ ...request, name: e });
            }}
            required
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('identifier')}
            content={request.identifier}
            update={(e) => {
              update({ ...request, identifier: e });
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('callable')}
            options={callables}
            selected={request.callable}
            update={(e) => {
              update({
                ...request,
                callable: e.currentTarget.selectedOptions[0]
                  .value as PropertyValidationRuleCallable,
              });
            }}
          />
        </div>
      </div>
      <CallableDescription callable={request.callable} />
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <ValueArray
            title={t('arguments')}
            items={request.arguments}
            onDelete={(index) => {
              if (request.arguments && request.arguments.length > 0) {
                const u = [...request.arguments];
                u.splice(index, 1);
                update({ ...request, arguments: u });
              }
            }}
            onEdit={() => setArgumentsEditor(true)}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-inputInline'}>
          <ValueArray
            title={t('constraints')}
            items={request.constraints}
            onDelete={(index) => {
              if (request.constraints && request.constraints.length > 0) {
                const u = [...request.constraints];
                u.splice(index, 1);
                update({ ...request, constraints: u });
              }
            }}
            editorOverride={
              <Dropdown
                options={constraints}
                update={(e) => {
                  const cons = request.constraints;
                  const newCon = e.currentTarget.selectedOptions[0]
                    .value as ValidationConstraint;
                  if (cons) {
                    cons.push(newCon);
                  }
                  update({ ...request, constraints: cons ?? [newCon] });
                }}
              />
            }
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('objectPath')}
            content={request.objectPath}
            update={(e) => {
              update({ ...request, objectPath: e });
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('message')}
            translatedContent={request.message}
            update={(e, locale) => {
              if (locale) {
                update({
                  ...request,
                  message: { ...request.message, [locale]: e },
                });
              }
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('description')}
            translatedContent={request.description}
            update={(e, locale) => {
              if (locale) {
                update({
                  ...request,
                  description: {
                    ...request.description,
                    [locale]: e,
                  },
                });
              }
            }}
          />
        </div>
      </div>
      <Popup
        toggled={argumentsEditor}
        width={'30%'}
        close={() => setArgumentsEditor(false)}
      >
        <div className={'popup-title'}>{t('argumentsEditor.title')}</div>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('argumentsEditor.name')}
              content={newArgument}
              update={(e) => setNewArgument(e)}
            />
          </div>
        </div>
        <div className={'global-cardActions'}>
          <Button
            cta={t('argumentsEditor.cta')}
            look={'save'}
            width={'minimal'}
            action={() => {
              if (newArgument) {
                const args = request.arguments;
                if (args) {
                  args.push(newArgument);
                }
                update({ ...request, arguments: args ?? [newArgument] });
                setArgumentsEditor(false);
              }
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default PropertyValidationRuleEdit;

const callableDescriptions: { [key: string]: string } = {
  IsIntegerBetween:
    'Prüft ob ein Wert eine Zahl ist und zwischen den hier anzugebenen Parametern ist.',
  IsValueOrSplitValue:
    'Prüft ob eine Angabe mit dem hier anzugebenen Parameter teilbar ist. Parameter Beispiel: " / "',
  IsInteger: 'Prüft ob ein Wert eine ganze Zahl ist.',
  IsDecimal: 'Prüft ob ein Werte eine Kommazahl ist.',
};

interface CallableDescriptionProps {
  callable: string | undefined | null;
}

const CallableDescription: React.FC<CallableDescriptionProps> = ({
  callable,
}) => {
  if (callable) {
    return (
      <div className={'callableDescription'}>
        {callableDescriptions[callable]}
      </div>
    );
  } else {
    return null;
  }
};
