import './returnannouncement.css';
import {
  OrderResponse,
  OrderReturnAnnouncementPositionResponse,
  OrderReturnAnnouncementResponse,
  SimpleProductResponse,
} from '../../../../api/petcloudapi/api';
import TitleValueList from '../../../../elements/titlevaluelist/TitleValueList';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';
import Retractable from '../../../../elements/retractable/Retractable';
import useDateTools from '../../../../hooks/useDateTools';
import { useState } from 'react';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../hooks/useNotifications';
import Button from '../../../../elements/button/Button';

interface ReturnAnnouncementProps {
  announcement: OrderReturnAnnouncementResponse;
  order: OrderResponse;
}

const ReturnAnnouncement: React.FC<ReturnAnnouncementProps> = ({
  announcement,
  order,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.order.returnAnnouncements.returnAnnouncement',
  });
  const api = usePetCloudApi();
  const orderReturnAnnouncementsApi = api.orderReturnAnnouncementsApi();
  const errorHandler = useErrorHandler();
  const { displayReadableDate } = useDateTools();
  const { pushNotification } = useNotifications();
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const getProduct = (position: OrderReturnAnnouncementPositionResponse) => {
    const shippingDetail = order.orderShippingDetails?.find(
      (s) => s.id === announcement.orderShippingDetailId
    );
    return order.orderPositions.find(
      (op) =>
        op.id ===
        shippingDetail?.positions?.find(
          (p) => p.id === position.orderShippingDetailPositionId
        )?.orderPositionId
    )?.product;
  };

  const renderPositionHead = (product: SimpleProductResponse) => {
    return (
      <div>
        <span>{product.name[i18n.language as TranslatedStringIndex]}</span>
        <span className={'retractable-head-element global-monospaced-contrast'}>
          {product.ean}
        </span>
      </div>
    );
  };

  const sendReturnLabelEmail = (orderReturnAnnouncementId: string) => {
    setIsSendingEmail(true);
    orderReturnAnnouncementsApi
      .orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId)
      .then((response) => {
        console.log(response);
        setIsSendingEmail(false);
        pushNotification(t('notifications.labelEmail_successful'));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSendingEmail(false);
      });
  };

  return (
    <div className={'returnAnnouncement'}>
      <div className={'returnAnnouncement-title'}>{t('title')}</div>
      <div className={'returnAnnouncement-header'}>
        <div>
          <TitleValueList
            entries={[
              {
                title: t('createdAt'),
                value: displayReadableDate(announcement.createdAt),
                asHighlight: true,
              },
              {
                title: t('positions'),
                value: announcement.orderReturnAnnouncementPositions?.length,
                asHighlight: true,
              },
              {
                title: t('shippingProvider'),
                value: announcement.shippingProvider?.name,
                asHighlight: true,
              },
              {
                title: t('shipmentNumber'),
                value: announcement.labelData.shipmentNumber,
                asHighlight: true,
              },
            ]}
          />
        </div>
        {announcement.shippingProvider?.name === 'DHL Standard' ? (
          <img
            className={'returnAnnouncement-header-qr'}
            src={`data:image/png;base64,${announcement.labelData.qrLabelData}`}
            alt={'QR Code'}
          />
        ) : null}
      </div>
      {announcement.orderReturnAnnouncementPositions &&
      announcement.orderReturnAnnouncementPositions.length > 0 ? (
        <div className="returnAnnouncement-positions">
          <div className={'returnAnnouncement-positions-title'}>
            {t('positions')}
          </div>
          {announcement.orderReturnAnnouncementPositions.map((position) => {
            const orderPositionProduct = getProduct(position);
            if (orderPositionProduct) {
              return (
                <div className={'returnAnnouncement-positions-retractable'}>
                  <Retractable head={renderPositionHead(orderPositionProduct)}>
                    <div className="returnAnnouncement-position">
                      <div className="returnAnnouncement-position-section">
                        <div className="returnAnnouncement-position-section-title">
                          {t('product')}
                        </div>
                        <TitleValueList
                          entries={[
                            {
                              title: t('productName'),
                              value:
                                orderPositionProduct.name[
                                  i18n.language as TranslatedStringIndex
                                ],
                            },
                            {
                              title: t('ean'),
                              value: orderPositionProduct.ean,
                            },
                            {
                              title: t('productNumber'),
                              value:
                                orderPositionProduct.manufacturerProductNumber,
                            },
                            {
                              title: t('quantity'),
                              value: position.quantity,
                            },
                          ]}
                        />
                      </div>
                      {position.orderReturnReasons &&
                      position.orderReturnReasons.length > 0 ? (
                        <div className="returnAnnouncement-position-section">
                          <div className="returnAnnouncement-position-section-title">
                            {t('reasons.title')}
                          </div>
                          <div className="returnAnnouncement-position-reasons">
                            {position.orderReturnReasons.map((reason) => {
                              return (
                                <div className="returnAnnouncement-position-reason">
                                  <TitleValueList
                                    entries={[
                                      {
                                        title: t('reasonType'),
                                        value: t(
                                          'reasons.' + reason.returnReason
                                        ),
                                      },
                                      {
                                        title: t('reasonText'),
                                        value: reason.reasonText,
                                      },
                                    ]}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Retractable>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      ) : null}
      <div className={'global-cardActions'}>
        <Button
          cta={t('triggerEmail')}
          look={'secondary'}
          width="minimal"
          action={() => sendReturnLabelEmail(announcement.id)}
          isLoading={isSendingEmail}
        />
      </div>
    </div>
  );
};

export default ReturnAnnouncement;
