import React, { useEffect, useState } from 'react';

export type AutoHeightConfig = {
  isAutoHeightEnabled?: boolean;
  autoHeightOffset?: number;
  listRef: React.RefObject<HTMLDivElement>;
  items?: any[] | null;
  selectedItems?: any[] | null;
  isActionsBarEnabled?: boolean;
  isTrancheSelectingEnabled?: boolean;
  defaultHeight?: number | string;
};

const useAutoHeight = ({
  isAutoHeightEnabled,
  autoHeightOffset,
  defaultHeight,
  listRef,
  items,
  selectedItems,
  isActionsBarEnabled,
  isTrancheSelectingEnabled,
}: AutoHeightConfig) => {
  const [layout, setLayout] = useState({
    listHeight: defaultHeight,
    showActionsBar: false,
  });

  useEffect(() => {
    if (isAutoHeightEnabled) {
      adjustLayout();
    }
  }, [listRef, items, selectedItems]);

  const adjustLayout = () => {
    const el = listRef.current;
    if (el) {
      const elRect = el.getBoundingClientRect();

      const showActionsBar = !!(
        items &&
        selectedItems &&
        selectedItems.length > 0 &&
        (isTrancheSelectingEnabled || isActionsBarEnabled)
      );

      const offset = autoHeightOffset ?? 60;
      let calculatedHeight = window.innerHeight - elRect.top;
      if (!showActionsBar) {
        if (layout.showActionsBar) {
          calculatedHeight -= offset - 70;
        } else {
          calculatedHeight -= offset;
        }
      } else {
        if (layout.showActionsBar) {
          calculatedHeight -= offset;
        } else {
          calculatedHeight -= offset + 70;
        }
      }

      setLayout({
        listHeight:
          defaultHeight && defaultHeight > calculatedHeight
            ? defaultHeight
            : calculatedHeight,
        showActionsBar: showActionsBar,
      });
    }
  };

  return {
    layout,
  };
};

export default useAutoHeight;
