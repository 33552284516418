import axios from 'axios';

class OAuth2 {
  public clientId: string;
  public authUri: string;
  public accessTokenUrl: string;
  public redirectUri: string;
  public endSessionEndpoint: string;
  public refreshTreshhold: number;

  public state: string;
  public authUrl: URL;

  public accessToken: string | null;
  public refreshToken: string | null;
  public idToken: string | null;

  constructor(params: {
    clientId: string;
    authUri: string;
    accessTokenUrl: string;
    endSessionEndpoint: string;
    refreshThreshold: number;
  }) {
    this.clientId = params.clientId;
    this.authUri = params.authUri;
    this.accessTokenUrl = params.accessTokenUrl;
    this.redirectUri = '';
    this.endSessionEndpoint = params.endSessionEndpoint;
    this.refreshTreshhold = params.refreshThreshold;

    this.state = this.createState(16);
    this.authUrl = this.createAuthUrl();

    this.accessToken = null;
    this.refreshToken = null;
    this.idToken = null;
  }

  private createState = (length: number) => {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  private createAuthUrl = () => {
    const url = new URL(this.authUri);
    url.searchParams.append('client_id', this.clientId);
    url.searchParams.append('redirect_uri', this.redirectUri);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('state', this.state);
    url.searchParams.append('scope', 'openid');
    return url;
  };

  public initiateAuth = () => {
    window.location.href = this.createAuthUrl().toString();
  };

  public getTokens = (code: string) => {
    const params = new URLSearchParams();
    params.append('code', code);
    params.append('grant_type', 'authorization_code');
    params.append(
      'redirect_uri',
      `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    );
    params.append('client_id', this.clientId);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const url = this.accessTokenUrl + '?response_type=code';

    return axios
      .post(url, params, config)
      .then((response) => {
        this.idToken = response.data.id_token;
        this.accessToken = response.data.access_token;
        this.refreshToken = response.data.refresh_token;
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  public refreshTokens = (refreshToken: string) => {
    const params = new URLSearchParams();
    params.append('refresh_token', refreshToken);
    params.append('grant_type', 'refresh_token');
    params.append('client_id', this.clientId);
    params.append('scope', 'openid');

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(this.accessTokenUrl, params, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default OAuth2;
