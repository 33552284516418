import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  AdyenPaymentTransactionsResponse,
  GetPaymentTransactionsRequest,
  Amount,
  CursorPaginator,
} from '../../api/petcloudapi/api';
import Card, { CardSection } from '../../elements/card/Card';
import { NoResults } from '../../elements/emptystate/EmptyState';
import List from '../../features/list/List';
import ListControls from '../../features/list/listcontrols/ListControls';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Stage, Navigator } from '../../layout/stage/Stage';
import MinifiedPayment from '../../types/MinifiedPayment';
import { Has } from '../../contexts/auth/Authorization';

const Payments = () => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const paymentTransactionsApi = api.paymentTransactionsApi();
  const errorHandler = useErrorHandler();

  const [payments, setPayments] = useState<MinifiedPayment[] | null>(null);
  const [cursor, setCursor] = useState<CursorPaginator | null>(null);
  const [currentCursorPosition, setCurrentCursorPosition] = useState(1);

  const today = new Date();
  const lastWeek = new Date().setDate(new Date().getDate() - 7);
  const lastWeekDate = new Date(lastWeek);
  console.log(lastWeek);
  const [request, setRequest] = useState<GetPaymentTransactionsRequest>({
    createdSince: lastWeekDate.toISOString(),
    createdUntil: today.toISOString(),
    balanceAccountId: null,
    accountHolderId: null,
    cursor: null,
    limit: 50,
  });

  useEffect(() => {
    getPayments();
  }, [request]);

  const getPayments = () => {
    setPayments(null);
    paymentTransactionsApi
      .paymentTransactionsGetTransactions(request)
      .then((response) => {
        console.log(response);
        setPayments(prepareDataForList(response.data));
        setCursor({
          next:
            cursor?.next === response.data.cursor.next
              ? null
              : response.data.cursor.next,
          previous:
            cursor?.previous === response.data.cursor.previous
              ? null
              : response.data.cursor.previous,
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const prepareDataForList = (
    paymentsArray: AdyenPaymentTransactionsResponse
  ) => {
    const results: MinifiedPayment[] = [];
    paymentsArray.data?.forEach((payment) => {
      const transaction = payment.transaction;
      if (transaction) {
        results.push({
          id: transaction.id,
          status: transaction.status,
          type: transaction.type,
          amount: transaction.amount
            ? {
                currency: transaction.amount.currency,
                value: transaction.amount.value / 100,
              }
            : null,
          bookingDate: transaction.bookingDate,
          customer:
            transaction.counterparty?.bankAccount?.accountHolder?.fullName,
          orderId: payment.orderId,
        });
      }
    });
    return results;
  };

  const renderAmount = (amount: Amount) => {
    return <span>{amount.value + ' ' + amount.currency}</span>;
  };

  return (
    <Has authorizations={['payment_transactions:list']} showNoAuthorization>
      {payments ? (
        <Stage>
          <Navigator title={t('view.payments.title')}></Navigator>
          <Card bigScreenWidth="full">
            <CardSection>
              <ListControls
                filters={[
                  {
                    title: t('view.payments.filters.createdSince'),
                    date: new Date(request.createdSince ?? ''),
                    update: (date: Date) => {
                      setRequest({
                        ...request,
                        createdSince: date.toISOString(),
                        cursor: null,
                      });
                      setCurrentCursorPosition(1);
                    },
                  },
                  {
                    title: t('view.payments.filters.createdUntil'),
                    date: new Date(request.createdUntil ?? ''),
                    update: (date: Date) => {
                      setRequest({
                        ...request,
                        createdUntil: date.toISOString(),
                        cursor: null,
                      });
                      setCurrentCursorPosition(1);
                    },
                  },
                  {
                    title: t('view.payments.filters.limit'),
                    optionObjects: [
                      {
                        name: '25',
                        id: '25',
                      },
                      {
                        name: '50',
                        id: '50',
                      },
                      {
                        name: '100',
                        id: '100',
                      },
                    ],
                    selected: request.limit?.toString(),
                    update: (value: any) => {
                      console.log(value);
                      setRequest({
                        ...request,
                        limit: parseInt(value.id),
                        cursor: null,
                      });
                      setCurrentCursorPosition(1);
                    },
                  },
                ]}
                cursor={{
                  next: cursor?.next
                    ? () => {
                        setRequest({ ...request, cursor: cursor?.next });
                        setCurrentCursorPosition(currentCursorPosition + 1);
                      }
                    : undefined,
                  previous: cursor?.previous
                    ? () => {
                        setRequest({ ...request, cursor: cursor?.previous });
                        setCurrentCursorPosition(currentCursorPosition - 1);
                      }
                    : undefined,
                  currentPosition: currentCursorPosition,
                }}
              />
              {payments.length > 0 ? (
                <List
                  linkedKey="id"
                  ignore={['orderId']}
                  monoSpaceStrings={['id']}
                  items={payments}
                  dateStrings={['bookingDate']}
                  renderObjects={[
                    {
                      key: 'amount',
                      renderMethod: renderAmount,
                    },
                  ]}
                  tableHeadContrast
                  adjustHeightToViewport
                />
              ) : (
                <NoResults />
              )}
            </CardSection>
          </Card>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Payments;
