import './deliverytimeseditor.css';
import {
  DeliveryTimeResponse,
  DeliveryTimeType,
  DeliveryTimeUnit,
} from '../../../../api/petcloudapi/api';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import { useState } from 'react';
import {
  DeliveryTime,
  shippingTypeOptions,
} from '../../../../sections/product/shipping/Shipping';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface DeliveryTimesEditorProps {
  deliveryTimes: DeliveryTimeResponse[];
  updateCallback: EditorUpdateCallback;
  productId: string;
}

const DeliveryTimesEditor: React.FC<DeliveryTimesEditorProps> = ({
  deliveryTimes,
  updateCallback,
  productId,
}) => {
  const { t } = useTranslation();
  const [times, setTimes] = useState<DeliveryTimeResponse[]>(
    deliveryTimes ?? []
  );

  return (
    <div className={'deliveryTimesEditor'}>
      <div className={'jtce-title'}>
        {t('view.productBulkEditor.headers.deliveryTimes')}
      </div>
      <div className={'deliveryTimesEditor-shipping'}>
        {times.length > 0 ? (
          times.map((time, i) => {
            return (
              <DeliveryTime
                key={i}
                deliveryTime={time}
                update={(t) => {
                  const u = [...times];
                  u[i] = { ...(t as DeliveryTimeResponse) };
                  setTimes(u);
                }}
                onDelete={() => {
                  const u = [...times];
                  u.splice(i, 1);
                  setTimes(u);
                }}
                shippingTypeOptions={shippingTypeOptions}
                canEditType={true}
              />
            );
          })
        ) : (
          <EmptyState />
        )}
      </div>
      <div className={'deliveryTimesEditor-shipping-new'}></div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: times }]);
            } catch {}
          }}
          buttons={[
            {
              cta: t('view.product.shipping.new'),
              action: () => {
                const u = [...times];
                u.push({
                  min: 1,
                  max: 3,
                  unit: 'Days' as DeliveryTimeUnit,
                  type: 'Standard' as DeliveryTimeType,
                  productId: productId,
                  id: '',
                  createdAt: '',
                });
                setTimes(u);
              },
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default DeliveryTimesEditor;
