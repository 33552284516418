import './analyticconstituentseditor.css';
import { ProductAnalyticConstituentResponse } from '../../../../api/petcloudapi/api';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import { useState } from 'react';
import { AnalyticConstituent } from '../../../../sections/product/analyticconstituents/analyticconstituent/AnalyticConstituent';
import { useTranslation } from 'react-i18next';
import { constituentslist } from '../../../../sections/product/analyticconstituents/constituentslist';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { addEmptyAnalyticConstituent } from '../../../../sections/productbulkeditor/utils/addEmptyPrice';

interface AnalyticConstituentsEditorProps {
  constituents: ProductAnalyticConstituentResponse[];
  updateCallback: EditorUpdateCallback;
}

const AnalyticConstituentsEditor: React.FC<AnalyticConstituentsEditorProps> = ({
  constituents,
  updateCallback,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.product.analyticConstituents',
  });
  const [analyticConstituents, setAnalyticConstituents] = useState(
    constituents ?? []
  );

  const addNew = () => {
    const update = [...analyticConstituents];
    update.push(addEmptyAnalyticConstituent(constituents.length));
    setAnalyticConstituents(update);
  };

  const options = constituentslist.map((c) => ({
    id: c.id,
    name: c.name[i18n.language as TranslatedStringIndex],
  }));

  return (
    <div className={'jtce-analyticConstituents'}>
      <div className={'jtce-title'}>{t('title')}</div>
      <div className={'jtce-analyticConstituents-list'}>
        {analyticConstituents.map((constituent, i) => {
          return (
            <AnalyticConstituent
              key={i}
              constituent={constituent}
              updateConstituent={(c) => {
                const update = [...analyticConstituents];
                update[i] = { ...(c as ProductAnalyticConstituentResponse) };
                setAnalyticConstituents(update);
              }}
              deleteConstituent={() => {
                const update = [...analyticConstituents];
                update.splice(i, 1);
                setAnalyticConstituents(update);
              }}
              options={options}
            />
          );
        })}
      </div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: analyticConstituents }]);
            } catch {}
          }}
          buttons={[
            {
              cta: t('actions.add'),
              action: addNew,
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default AnalyticConstituentsEditor;
