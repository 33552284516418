import './shippingdetails.css';
import { useTranslation } from 'react-i18next';
import {
  OrderPositionResponse,
  OrderResponse,
  OrderShippingDetailResponse,
  ShippingProviderResponse,
} from '../../../api/petcloudapi/api';
import List from '../../../features/list/List';
import { useCallback, useState } from 'react';
import Popup from '../../../elements/popup/Popup';
import Button from '../../../elements/button/Button';
import ShippingDetail from './shippingdetail/ShippingDetail';
import CreateShippingDetail from './createshippingdetail/CreateShippingDetail';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import packageImg from '../../../../assets/img/package.svg';
import { useAuthorization } from '../../../contexts/auth/Authorization';

interface ShippingDetailsProps {
  order: OrderResponse;
  userCanCreate: boolean;
  orderPositions: OrderPositionResponse[];
  shippingDetails: OrderShippingDetailResponse[];
  refreshOrder: () => void;
  currency: string;
}

const ShippingDetails: React.FC<ShippingDetailsProps> = ({
  order,
  orderPositions,
  shippingDetails,
  userCanCreate,
  refreshOrder,
  currency,
}) => {
  const { t } = useTranslation();
  const { authorizations } = useAuthorization();
  const [shippingDetailInFocus, setShippingDetailInFocus] =
    useState<OrderShippingDetailResponse | null>(null);
  const [newShippingDetailPopup, setNewShippingDetailPopup] = useState(false);

  const renderShippingProvider = useCallback(
    (provider: ShippingProviderResponse) => {
      return <div>{provider.name}</div>;
    },
    []
  );

  const renderWeight = useCallback((weight: string | null | undefined) => {
    if (weight) {
      return <div>{`${weight} kg`}</div>;
    } else {
      return <div className={'list-table-td-na'}>-</div>;
    }
  }, []);

  const renderPositions = useCallback((positions) => {
    return (
      <span className={'global-monospaced-contrast'}>{positions.length}</span>
    );
  }, []);

  return (
    <div className="shippingdetails">
      {shippingDetails.length > 0 ? (
        <List
          items={shippingDetails}
          monoSpaceStrings={['trackingCode']}
          onRowClick={(shippingDetail) => {
            setShippingDetailInFocus(shippingDetail);
          }}
          ignore={[
            'id',
            'shippingDateEarliest',
            'shippingDateLatest',
            'shippingProviderId',
            'updatedAt',
            'orderReturnAnnouncements',
          ]}
          dateStrings={['createdAt', 'transmissionDate']}
          renderObjects={[
            {
              key: 'shippingProvider',
              renderMethod: renderShippingProvider,
            },
            {
              key: 'positions',
              renderMethod: renderPositions,
            },
            {
              key: 'weight',
              renderMethod: renderWeight,
            },
          ]}
        />
      ) : (
        <EmptyState
          img={packageImg}
          message={t('view.order.shippingDetails.empty')}
        />
      )}
      <Popup
        toggled={!!shippingDetailInFocus}
        width="50%"
        close={() => setShippingDetailInFocus(null)}
      >
        <div className="popup-title">
          {t('view.order.shippingDetails.shippingDetail.title')}
        </div>
        {shippingDetailInFocus ? (
          <ShippingDetail
            order={order}
            shippingDetail={shippingDetailInFocus}
            orderPositions={orderPositions}
            currency={currency}
            closePopup={() => setShippingDetailInFocus(null)}
            refreshOrder={refreshOrder}
          />
        ) : null}
      </Popup>
      {userCanCreate &&
      authorizations?.includes('order_shipping_details:create') ? (
        <div className="shippingdetails-actions global-cardActions">
          <Button
            cta={t('view.order.shippingDetails.edit.title')}
            look={'secondary'}
            width="minimal"
            action={() => setNewShippingDetailPopup(true)}
          />
        </div>
      ) : null}
      <Popup
        toggled={newShippingDetailPopup}
        width="60%"
        lockWidth={true}
        close={() => setNewShippingDetailPopup(false)}
      >
        <div className="popup-title">
          {t('view.order.shippingDetails.edit.title')}
        </div>
        <CreateShippingDetail
          order={order}
          orderPositions={orderPositions}
          refreshOrder={refreshOrder}
          closePopup={() => setNewShippingDetailPopup(false)}
        />
      </Popup>
    </div>
  );
};

export default ShippingDetails;
