import { useCookies } from 'react-cookie';
import useUrlParams from './useUrlParams';

const useIntegrationURLParams = () => {
  const { getUrlParam } = useUrlParams();
  const [cookies, setCookie] = useCookies();

  const storeShopifyIntegrationParams = () => {
    if (window.location.href.includes('shopify/base')) {
      const host = getUrlParam('host');
      const hmac = getUrlParam('hmac');
      const shop = getUrlParam('shop');
      const timestamp = getUrlParam('timestamp');
      const session = getUrlParam('session');

      const expires = new Date();
      expires.setMinutes(expires.getMinutes() + 5);
      const opts = {
        path: '/',
        expires: expires,
      };

      if (hmac && shop && timestamp) {
        setCookie('integration_shopify_host', host, opts);
        setCookie('integration_shopify_hmac', hmac, opts);
        setCookie('integration_shopify_shop', shop, opts);
        setCookie('integration_shopify_timestamp', timestamp, opts);
      }

      if (session) {
        setCookie('integration_shopify_session', session, opts);
      }
    }
  };

  return { storeShopifyIntegrationParams };
};

export default useIntegrationURLParams;
