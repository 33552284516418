import { TabConfig } from '../components/elements/card/Card';

const languageTabs: TabConfig[] = [
  {
    title: 'de-DE',
    key: 'de-DE',
  },
  {
    title: 'en-GB',
    key: 'en-GB',
  },
];

export default languageTabs;
