import './dailybestsellerslist.css';
import {
  BestSellingProductsOrderBy,
  DailyBestSellingProductsResponse,
  SoldProductResponse,
} from '../../../../api/petcloudapi/api';
import { useState } from 'react';
import { ReactComponent as IconBack } from '../../../../../assets/icon/chevron_back.svg';
import { ReactComponent as IconNext } from '../../../../../assets/icon/chevron_next.svg';
import useDateTools from '../../../../hooks/useDateTools';
import SoldProductsList from '../../common/soldproductslist/SoldProductsList';

interface DailyBestsellerListProps {
  dailyBestsellers: DailyBestSellingProductsResponse[];
  orderBy: BestSellingProductsOrderBy;
}

const DailyBestsellerList: React.FC<DailyBestsellerListProps> = ({
  dailyBestsellers,
  orderBy,
}) => {
  const { displayReadableMinifiedDate } = useDateTools();
  const [selectedDay, setSelectedDay] = useState(dailyBestsellers[0].date);

  const navigateDay = (direction: 'forward' | 'backward') => {
    let index = dailyBestsellers.findIndex((x) => x.date === selectedDay);
    if (index !== -1) {
      if (direction === 'forward') {
        if (index < dailyBestsellers.length - 1) index += 1;
      } else {
        if (index > 0) index -= 1;
      }
      setSelectedDay(dailyBestsellers[index].date);
    }
  };

  const getSoldProducts = (): SoldProductResponse[] => {
    const soldProducts = dailyBestsellers.find(
      (x) => x.date === selectedDay
    )?.products;
    return soldProducts ?? [];
  };

  return (
    <div className={'dailyBestsellersList'}>
      <div className={'dailyBestsellersList-dayBar'}>
        <div
          className={
            'dailyBestsellersList-dayBar-button dailyBestsellersList-dayBar-button__back'
          }
          onClick={() => navigateDay('backward')}
        >
          <IconBack
            className={'dailyBestsellersList-dayBar-button-icon'}
            fill={'var(--color-text_primary)'}
          />
        </div>
        <div className={'dailyBestsellersList-dayBar-days'}>
          {dailyBestsellers.map((day, i) => {
            return (
              <div
                key={i}
                className={`dailyBestsellersList-dayBar-days-day ${
                  selectedDay === day.date
                    ? 'dailyBestsellersList-dayBar-days-day__selected'
                    : ''
                }`}
                onClick={() => setSelectedDay(day.date)}
              >
                {displayReadableMinifiedDate(day.date)}
              </div>
            );
          })}
        </div>
        <div
          className={
            'dailyBestsellersList-dayBar-button dailyBestsellersList-dayBar-button__next'
          }
          onClick={() => navigateDay('forward')}
        >
          <IconNext
            className={'dailyBestsellersList-dayBar-button-icon'}
            fill={'var(--color-text_primary)'}
          />
        </div>
      </div>
      <div className={'dailyBestsellersList-list'}>
        <SoldProductsList soldProducts={getSoldProducts()} orderBy={orderBy} />
      </div>
    </div>
  );
};

export default DailyBestsellerList;
