import './mediaitem.css';
import { useState } from 'react';
import { Has } from '../../contexts/auth/Authorization';
import Button from '../../elements/button/Button';
import { Check } from '../../elements/selectors/Selectors';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as Trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as IconVideo } from '../../../assets/icon/film.svg';
import { useSortable } from '@dnd-kit/sortable';

interface MediaItemProps {
  id: string;
  src: string;
  isSelected?: boolean;
  onSelect?: () => void;
  onDelete?: (assetId: string) => void;
  inherited?: boolean;
  disabled: boolean;
  width?: number | string;
  height?: number | string;
  margin?: number;
  isCover?: boolean;
  isVideo?: boolean;
  fileName?: string;
}

export const MediaItem: React.FC<MediaItemProps> = ({
  id,
  src,
  isSelected,
  onSelect,
  onDelete,
  inherited,
  disabled,
  width,
  height,
  margin,
  isCover,
  isVideo,
  fileName,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id, disabled: disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : 1,
    boxShadow: isDragging ? 'var(--shadow-wide)' : 'none',
    width: width ?? '100%',
    height: height ?? '100%',
    margin: margin ?? 0,
  };

  const toggleImgActions = (bool: boolean) => {
    setActions(bool);
  };

  const [actions, setActions] = useState(false);

  const getClassName = () => {
    let result = 'mediaitem';
    if (inherited) result += ' mediaitem-inherited';
    if (isCover) result += ' mediaitem-cover';
    return result;
  };

  return (
    <div
      className={getClassName()}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => toggleImgActions(true)}
      onMouseLeave={() => toggleImgActions(false)}
    >
      <div className="mediaitem-img-wrapper">
        {isVideo ? (
          <div className={'mediaitem-img mediaitem-icon-wrapper'}>
            <IconVideo
              className={'mediaitem-icon'}
              fill={'var(--color-primary)'}
            />
            {fileName ? (
              <div className={'mediaitem-fileName'}>{fileName}</div>
            ) : null}
          </div>
        ) : (
          <img src={src} className="mediaitem-img" alt="Product" />
        )}
      </div>
      {onDelete ? (
        <div
          className="mediaitem-actions"
          style={{ display: (isSelected ? true : actions) ? 'flex' : 'none' }}
        >
          {isSelected !== undefined ? (
            <Check checked={isSelected} update={onSelect} />
          ) : null}
          <Has authorizations={['product_assets:delete']}>
            <Button
              type="icon"
              look={'secondary'}
              action={() => onDelete(id)}
              helperCSSClass="media-actions-button"
            >
              <Trash
                fill="var(--color-danger)"
                className="mediaitem-actions-delete"
              />
            </Button>
          </Has>
        </div>
      ) : null}
    </div>
  );
};
