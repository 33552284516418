import { Navigator, Stage } from '../../layout/stage/Stage';
import { Has } from '../../contexts/auth/Authorization';
import { useTranslation } from 'react-i18next';
import IntegrationLogList from '../../sections/integration/integrationloglist/IntegrationLogList';
import Card, { CardSection } from '../../elements/card/Card';

const IntegrationLogs = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrationLogs',
  });

  return (
    <Has authorizations={['integrations_api:list']} showNoAuthorization>
      <Stage>
        <Navigator title={t('title')} />
        <Card bigScreenWidth={'100%'}>
          <CardSection>
            <IntegrationLogList />
          </CardSection>
        </Card>
      </Stage>
    </Has>
  );
};

export default IntegrationLogs;
