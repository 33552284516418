import { useUser } from '../contexts/auth/User';
import useLocalStorageSettings from './useLocalStorageSettings';
import { useAuthorization } from '../contexts/auth/Authorization';

const useUserTools = () => {
  const { user } = useUser();
  const { authorizations: userAuthorizations } = useAuthorization();
  const { getToggledSetting } = useLocalStorageSettings();

  const excludeUnlockedOptions = () => {
    if (!user?.isProductOwner) {
      return true;
    } else {
      return getToggledSetting('excludeUnlockedOptions');
    }
  };

  const hasAuthorizations = (
    authorizations: string[],
    isBypassed?: boolean
  ) => {
    const missingPermissions: string[] = [];
    if (isBypassed) {
      return true;
    } else {
      if (authorizations.length === 0) {
        return true;
      } else {
        authorizations.forEach((a) => {
          if (!userAuthorizations?.includes(a)) {
            missingPermissions.push(a);
          }
        });
        return missingPermissions.length === 0;
      }
    }
  };

  const hasAnyAuthorization = (
    authorizations: string[],
    isBypassed?: boolean
  ) => {
    const permissionMatches: string[] = [];
    if (isBypassed) {
      return true;
    } else {
      if (authorizations.length === 0) {
        return true;
      } else {
        authorizations.forEach((a) => {
          if (userAuthorizations?.includes(a)) {
            permissionMatches.push(a);
          }
        });
        return permissionMatches.length > 0;
      }
    }
  };

  return {
    excludeUnlockedOptions,
    hasAuthorizations,
    hasAnyAuthorization,
  };
};

export default useUserTools;
