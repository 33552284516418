import { useTranslation } from 'react-i18next';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, { CardSection, HelperCard } from '../../elements/card/Card';
import StockPerProductList from '../../sections/stockperproductlist/StockPerProductList';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { ProductStockResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { Has } from '../../contexts/auth/Authorization';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import { ReactComponent as Export } from '../../../assets/icon/export.svg';
import CSVExport from '../../features/csvexport/CSVExport';
import StocksPerProductObject from '../../types/StocksPerProductObject';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';

const Stocks = () => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const productStocksApi = api.productStocksApi();
  const warehousesApi = api.warehousesApi();
  const errorHandler = useErrorHandler();
  const [exportPopup, setExportPopup] = useState(false);

  const [productStocks, setProductStocks] = useState<
    StocksPerProductObject[] | null
  >(null);

  useEffect(() => {
    getProductStocks();
  }, []);

  const getProductStocks = () => {
    productStocksApi
      .productStocksGetProductStocks(undefined, undefined, true)
      .then((response) => {
        console.log(response);
        setProductStocks(getListItems(response.data));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getListItems = (productStocks: ProductStockResponse[]) => {
    const productMap: { [key: string]: StocksPerProductObject } = {}; // use a map to store products detected

    productStocks.forEach((stock) => {
      // if the productId is not in the map, initialize it
      if (!productMap[stock.productId]) {
        const productName =
          stock.product.name[i18n.language as TranslatedStringIndex];

        productMap[stock.productId] = {
          productCoverUri:
            stock.product.cover?.asset[i18n.language as TranslatedStringIndex]
              ?.uri,
          productId: stock.productId,
          productName: productName ?? t('errors.noNameForThisLocale'),
          ean: stock.product.ean,
          productNumber: stock.product.productNumber,
          manufacturerProductNumber: stock.product.manufacturerProductNumber,
          totalStock: 0,
          warehouses: [],
        };
      }

      productMap[stock.productId].warehouses.push({
        name: stock.warehouse.name,
        stock: stock.stock,
      });
      productMap[stock.productId].totalStock += stock.stock;
    });

    // return an array of the values in the map
    return Object.values(productMap);
  };

  const prepareStocksForCSV = (stocks: StocksPerProductObject[]) => {
    return warehousesApi
      .warehousesGetWarehouses()
      .then((response) => {
        console.log(response);
        const results: any = [...stocks];
        results.forEach((stock: any) => {
          response.data.forEach((warehouse) => {
            stock[warehouse.name] = null;
          });
        });

        return results.map((stock: any) => {
          const result: any = { ...stock };
          delete result.warehouses;
          stock.warehouses.forEach((warehouse: any) => {
            result[warehouse.name] = warehouse.stock;
          });
          return result;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Has authorizations={['product_stocks:list']} showNoAuthorization>
      {productStocks !== null ? (
        <Stage>
          <Navigator title={t('view.logistics.title')}>
            <Button
              type={'icon-text'}
              cta={t('actions.export')}
              action={() => setExportPopup(true)}
              look={'secondary'}
            >
              <Export
                fill={'var(--color-text_primary)'}
                className={'button-icon'}
              />
            </Button>
          </Navigator>
          <HelperCard
            text={t('view.logistics.helperCard')}
            knowledgeBaseItems={[
              {
                title: t('knowledgebase.logistics.stocks.csv.title'),
                text: t('knowledgebase.logistics.stocks.csv.text'),
                videoUri: t('knowledgebase.logistics.stocks.csv.media'),
              },
            ]}
            settingName={'stocks_csv'}
          />
          <Card bigScreenWidth="100%" noMargin>
            <CardSection>
              <StockPerProductList productStocks={productStocks} />
            </CardSection>
          </Card>
          <Popup
            toggled={exportPopup}
            width={'30%'}
            close={() => setExportPopup(false)}
          >
            <div className={'popup-title'}>
              {t('view.logistics.export.title')}
            </div>
            <CSVExport
              objects={productStocks}
              objectPreparationFunc={prepareStocksForCSV}
            />
          </Popup>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Stocks;
