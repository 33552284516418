import './loyaltypointtransactionedit.css';
import {
  CreateLoyaltyPointTransactionRequest,
  LoyaltyPointTransactionType,
  UpdateLoyaltyPointTransactionRequest,
} from '../../../../../api/petcloudapi/api';
import Input from '../../../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../../elements/selectors/Selectors';

interface LoyaltyPointTransactionEditProps {
  transaction:
    | CreateLoyaltyPointTransactionRequest
    | UpdateLoyaltyPointTransactionRequest;
  updateTransaction: (
    transaction:
      | CreateLoyaltyPointTransactionRequest
      | UpdateLoyaltyPointTransactionRequest
  ) => void;
}

const LoyaltyPointTransactionEdit: React.FC<
  LoyaltyPointTransactionEditProps
> = ({ transaction, updateTransaction }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.loyaltyPointTransaction',
  });

  return (
    <div className={'loyaltyPointTransactionEdit'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('type')}
            options={['Credit', 'Redemption']}
            selected={transaction.type}
            update={(e) => {
              const value = e.currentTarget.selectedOptions[0].value;
              updateTransaction({
                ...transaction,
                type: value as LoyaltyPointTransactionType,
              });
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('points')}
            content={transaction.points.toString()}
            update={(value) =>
              updateTransaction({
                ...transaction,
                points: parseInt(value),
              })
            }
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('description')}
            translatedContent={transaction.description}
            update={(value, lang) => {
              if (lang) {
                updateTransaction({
                  ...transaction,
                  description: {
                    ...transaction.description,
                    [lang]: value,
                  },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoyaltyPointTransactionEdit;
