import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { CreateTagRequest, TagResponse } from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import Input from '../../../elements/input/Input';
import List from '../../../features/list/List';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import { Dropdown } from '../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import './tags.css';

const Tags = () => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const errorHandler = useErrorHandler();
  const tagsApi = api.tagsApi();
  const [tags, setTags] = useState<TagResponse[] | null>(null);
  const [selectedTags, setSelectedTags] = useState<TagResponse[]>([]);
  const [newTag, setNewTag] = useState<CreateTagRequest>({
    name: {
      'de-DE': null,
      'en-GB': null,
    },
  });
  const [dangerPopup, setDangerPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPopup, setNewPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<TranslatedStringIndex>(
    i18n.language as TranslatedStringIndex
  );
  const [tagToBeEdited, setTagToBeEdited] = useState<TagResponse | null>(null);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    tagsApi
      .tagsGetTags()
      .then((response) => {
        console.log(response.data);
        setTags([...response.data]);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.reponse);
      });
  };

  const selectTag = (item: TagResponse) => {
    const update = selectedTags;
    const i = update.findIndex((tag) => tag.id === item.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(item);
    }
    setSelectedTags([...update]);
  };

  const selectAllTags = () => {
    if (tags && tags.length !== selectedTags.length) {
      setSelectedTags([...tags]);
    } else {
      setSelectedTags([]);
    }
  };

  const deleteSelectedTags = () => {
    setIsSubmitting(true);
    const promises = selectedTags.map((tag) => {
      return deleteTag(tag.id);
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t('view.admin.tags.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedTags([]);
        getTags();
      })
      .catch(() => {
        Store.addNotification({
          message: t('view.admin.tags.notifications.delete_failed'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedTags([]);
        getTags();
      });
  };

  const deleteTag = (id: string) => {
    return new Promise((resolve, reject) => {
      tagsApi
        .tagsDeleteTag(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.reponse);
          reject(error);
        });
    });
  };

  const submitNewTag = () => {
    setIsSubmitting(true);
    tagsApi
      .tagsCreateTag(newTag)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.admin.tags.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setIsSubmitting(false);
        setNewTag({
          name: {
            'de-DE': null,
            'en-GB': null,
          },
        });
        getTags();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.reponse);
        setIsSubmitting(false);
      });
  };

  const submitEditedTag = () => {
    if (tagToBeEdited) {
      setIsSubmitting(true);
      tagsApi
        .tagsUpdateTag(tagToBeEdited.id, tagToBeEdited)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.admin.tags.notifications.edit_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
          getTags();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.reponse);
          setIsSubmitting(false);
        });
    }
  };

  if (tags) {
    return (
      <div className="admin-tags">
        <div className="admin-tags-localeSwitch">
          <Dropdown
            options={['de-DE', 'en-GB']}
            selected={selectedLocale}
            update={(e) => {
              const val =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (val) {
                setSelectedLocale(val as TranslatedStringIndex);
              }
            }}
          />
        </div>
        {selectedTags.length > 0 ? (
          <div className="admin-tags-adminActions">
            <Button
              cta={t('actions.delete')}
              width={'minimal'}
              look="secondary-danger"
              action={() => setDangerPopup(true)}
              margin="right"
            />
          </div>
        ) : null}
        {tags.length > 0 ? (
          <List
            items={tags}
            selectedItems={selectedTags}
            onSelect={selectTag}
            onSelectAll={selectAllTags}
            ignore={[
              'updatedAt',
              'syncedAt',
              'manufacturerId',
              'shopReferenceId',
            ]}
            dateStrings={['createdAt']}
            translatedStrings={['name']}
            selectedLocale={selectedLocale}
            actions={[
              {
                cta: 'edit',
                look: 'blue',
                action: (item: TagResponse) => {
                  setTagToBeEdited({ ...item });
                  setEditPopup(true);
                },
              },
              {
                cta: 'delete',
                look: 'danger',
                action: (item: TagResponse) => {
                  setSelectedTags([item]);
                  setDangerPopup(true);
                },
              },
            ]}
            adjustHeightToViewport
            adjustHeightToViewportOffset={120}
            tableHeadContrast
          />
        ) : null}
        <div className="admin-tags-actions">
          <Button
            width="minimal"
            look={'secondary'}
            cta={t('view.admin.tags.new')}
            action={() => setNewPopup(true)}
          />
        </div>
        <Popup
          width="30%"
          toggled={dangerPopup}
          close={() => setDangerPopup(false)}
        >
          <div className="popup-title">
            {t('view.admin.tags.dangerPopup.title')}
          </div>
          <div className="tags-dangerPopup-message">
            {t('view.admin.tags.dangerPopup.message')}
          </div>
          <Button
            width="full"
            look="danger"
            cta={t('view.admin.tags.dangerPopup.cta')}
            action={deleteSelectedTags}
            isLoading={isSubmitting}
          />
        </Popup>
        <Popup width="40%" toggled={newPopup} close={() => setNewPopup(false)}>
          <div className="popup-title">
            {t('view.admin.tags.newPopup.title')}
          </div>
          <TagForm tag={newTag} setTag={setNewTag} />
          <div className="global-cardActions">
            <Button
              width="minimal"
              look="save"
              cta={t('view.admin.tags.newPopup.submit')}
              action={submitNewTag}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
        <Popup
          width="40%"
          toggled={editPopup}
          close={() => setEditPopup(false)}
        >
          <div className="popup-title">
            {t('view.admin.tags.editPopup.title')}
          </div>
          {tagToBeEdited ? (
            <TagForm
              tag={tagToBeEdited}
              setTag={(unit: TagResponse | CreateTagRequest) =>
                setTagToBeEdited(unit as TagResponse)
              }
            />
          ) : null}
          <div className="global-cardActions">
            <Button
              width="minimal"
              look="save"
              cta={t('actions.save')}
              action={submitEditedTag}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Tags;

interface TagFormProps {
  tag: TagResponse | CreateTagRequest;
  setTag: (unit: TagResponse | CreateTagRequest) => void;
}

const TagForm: React.FC<TagFormProps> = ({ tag, setTag }) => {
  const { t } = useTranslation();
  return (
    <div className="productunits-groupForm">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('view.admin.productUnits.newPopup.nameDE')}
            content={tag.name['de-DE']}
            update={(e) => {
              setTag({
                ...tag,
                name: {
                  ...tag.name,
                  'de-DE': e,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('view.admin.productUnits.newPopup.nameEN')}
            content={tag.name['en-GB']}
            update={(e) => {
              setTag({
                ...tag,
                name: {
                  ...tag.name,
                  'en-GB': e,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
