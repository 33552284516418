import { Stage } from '../../layout/stage/Stage';
import { UnderConstruction } from '../../elements/emptystate/EmptyState';

const Documents = () => {
  return (
    <Stage>
      <UnderConstruction />
    </Stage>
  );
};

export default Documents;
