import { ProductCategoryResponse } from '../../../../api/petcloudapi/api';
import { EditorActions, EditorUpdateCallback } from '../../JsonTable';
import { useTranslation } from 'react-i18next';
import CategoryHierarchy from '../../../categoryhierarchy/CategoryHierarchy';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';
import { useState } from 'react';

interface MainCategoryIdEditorProps {
  mainCategoryId?: string | null;
  availableCategories?: ProductCategoryResponse[] | null;
  updateCallback: EditorUpdateCallback;
}

const MainCategoryIdEditor: React.FC<MainCategoryIdEditorProps> = ({
  mainCategoryId,
  availableCategories,
  updateCallback,
}) => {
  const { t } = useTranslation();
  const [currentMainCategoryId, setCurrentMainCategoryId] =
    useState(mainCategoryId);

  return (
    <div className={'mainCategoryIdEditor'}>
      <div className={'jtce-title'}>{t('view.product.categories.title')}</div>
      <div className={'categoriesEditor-hierarchy'}>
        {availableCategories ? (
          <CategoryHierarchy
            availableProductCategories={availableCategories}
            currentProductCategories={availableCategories}
            mainCategoryId={currentMainCategoryId}
            setMainCategoryId={(id) => {
              setCurrentMainCategoryId(id);
            }}
          />
        ) : (
          <EmptyState />
        )}
      </div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: currentMainCategoryId }]);
            } catch {}
          }}
        />
      ) : null}
    </div>
  );
};

export default MainCategoryIdEditor;
