import './daterange.css';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as Calendar } from '../../../assets/icon/calendar.svg';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDateTools from '../../hooks/useDateTools';
import Button from '../button/Button';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import ClickOutsideContainer from '../clickoutsidecontianer/ClickOutsideContainer';

interface DateRangeProps {
  selectedDateRange: string[];
  update: (range: string[]) => void;
  showTimeSelect?: boolean;
}

const DateRange: React.FC<DateRangeProps> = ({
  selectedDateRange,
  update,
  showTimeSelect,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.dateRange',
  });
  const [currentDateRange, setCurrentDateRange] = useState(selectedDateRange);
  const [pop, setPop] = useState(false);
  const { displayReadableMinifiedDate } = useDateTools();
  const ref = useRef(null);
  useClickOutsideHandler(ref, () => setPop(false), 'test');
  const [error, setError] = useState<string | null>(null);

  const rangeRef = useRef<HTMLDivElement>(null);
  const [popLocation, setPopLocation] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const x = rangeRef.current?.getBoundingClientRect().x;
    const y = rangeRef.current?.getBoundingClientRect().y;
    console.log(x, y);
    if (x && y) {
      setPopLocation({ x: x, y: y });
    }
  }, [rangeRef]);

  useEffect(() => {
    setError(null);
  }, [currentDateRange]);

  const validateAndSetCurrentDateRange = (range: string[]) => {
    const start = new Date(range[0]);
    const end = new Date(range[1]);
    if (start < end) {
      setCurrentDateRange(range);
    } else {
      setError(t('notifications.startGreaterThanEnd'));
    }
  };

  const fixDate = (date: Date) => {
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const isoDateTime: string = new Date(date.getTime() - tzOffset)
      .toISOString()
      .slice(0, -1);
    return isoDateTime;
  };

  return (
    <div className="dateRange">
      <div
        ref={rangeRef}
        className={'dateRange-date'}
        onClick={() => setPop(!pop)}
      >
        {displayReadableMinifiedDate(selectedDateRange[0], true)}
        <span>{t('to')}</span>
        {displayReadableMinifiedDate(selectedDateRange[1], true)}
      </div>

      {pop ? (
        <ClickOutsideContainer
          onClickOutside={() => setPop(false)}
          fixedViewportAwareElementRef={rangeRef}
        >
          <div className={'dateRange-pop'}>
            <div className={'dateRange-pop-dates'}>
              <div className={'dateRange-pop-date'}>
                <div className={'dateRange-pop-date-title'}>
                  {t('startDate')}
                </div>
                <ReactDatePicker
                  selected={new Date(currentDateRange[0])}
                  onChange={(date) => {
                    if (date) {
                      let result = fixDate(date);
                      if (!showTimeSelect) {
                        result = result.split('T')[0];
                      }
                      validateAndSetCurrentDateRange([
                        result,
                        currentDateRange[1],
                      ]);
                    }
                  }}
                  wrapperClassName={
                    'dateRange-datepicker dateRange-datepicker-1'
                  }
                  dateFormat="dd.MM.yyyy"
                  inline
                  disabledKeyboardNavigation
                  fixedHeight
                  showTimeSelect={showTimeSelect}
                  timeFormat={'HH:mm'}
                />
              </div>
              <div className={'dateRange-pop-dates-divider'} />
              <div className={'dateRange-pop-date'}>
                <div className={'dateRange-pop-date-title'}>{t('endDate')}</div>
                <ReactDatePicker
                  selected={new Date(currentDateRange[1])}
                  onChange={(date) => {
                    if (date) {
                      let result = fixDate(date);
                      if (!showTimeSelect) {
                        result = result.split('T')[0];
                      }
                      validateAndSetCurrentDateRange([
                        currentDateRange[0],
                        result,
                      ]);
                    }
                  }}
                  wrapperClassName={
                    'dateRange-datepicker dateRange-datepicker-2'
                  }
                  dateFormat="dd.MM.yyyy"
                  inline
                  disabledKeyboardNavigation
                  fixedHeight
                  showTimeSelect={showTimeSelect}
                  timeFormat={'HH:mm'}
                />
              </div>
            </div>
            {error ? (
              <div className={'dateRange-pop-error'}>{error}</div>
            ) : null}
            <div className={'dateRange-pop-buttons'}>
              <Button
                cta={t('reset')}
                look={'secondary'}
                width={'minimal'}
                action={() => setCurrentDateRange(selectedDateRange)}
              />
              <Button
                cta={t('apply')}
                look={'save'}
                width={'minimal'}
                action={() => update([...currentDateRange])}
                margin={'left'}
              />
            </div>
          </div>
        </ClickOutsideContainer>
      ) : null}
      <div className="dateRange-icon">
        <div className="dateRange-icon-wrapper">
          <Calendar
            className="dateRange-icon-icon"
            fill="var(--color-text_secondary)"
          />
        </div>
      </div>
    </div>
  );
};

export default DateRange;
