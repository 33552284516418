import './barchart.css';

import { ReactNode, useEffect, useState } from 'react';
import { randomColor } from 'seed-to-color';
import Hoverable from '../../../../../elements/hoverable/Hoverable';
import useDateTools from '../../../../../hooks/useDateTools';
import Popup from '../../../../../elements/popup/Popup';

interface BarChartProps {
  data: any[];
  xAxisName?: string;
  xAxisKey: string;
  xAxisIsDate?: boolean;
  yAxisName?: string;
  yAxisKey: string;
  yAxisSteps?: number;
  yAxisUnit?: string;
  stackKey?: string;
  stackType?: 'row' | 'column';
  onHover?: (data: any) => ReactNode;
  barWidth?: string;
  barColorIdKey?: string;
  barColors?: string[];
  barPopup?: (data: any) => ReactNode;
  height?: number;
  keyHighlightOnHover?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  xAxisName,
  xAxisKey,
  xAxisIsDate,
  yAxisName,
  yAxisKey,
  stackKey,
  stackType,
  onHover,
  yAxisUnit,
  barWidth = `${100 / data.length}%`,
  yAxisSteps = 5,
  barColorIdKey,
  barColors,
  barPopup,
  height,
  keyHighlightOnHover,
}) => {
  const { displayReadableMinifiedDate } = useDateTools();

  const [highest, setHighest] = useState(0);
  const [ySteps, setYSteps] = useState(yAxisSteps);
  const [barPopupData, setBarPopupData] = useState<any | null>(null);
  const [hoveredKeyId, setHoveredKeyId] = useState<string | null>(null);

  useEffect(() => {
    setHighest(getHighest());
  }, [data]);

  const getHighest = () => {
    let highScore = 0;
    data.forEach((entry) => {
      const total = stackKey
        ? entry[stackKey].reduce((prev: any, next: any) => {
            return prev + next[yAxisKey];
          }, 0)
        : entry[yAxisKey];
      if (total > highScore) {
        highScore = total;
      }
    });
    if (highScore < yAxisSteps) {
      setYSteps(highScore);
    }
    return highScore;
  };

  const renderYAxisSteps = () => {
    const steps: ReactNode[] = [];
    for (let i = 0; i <= ySteps; i++) {
      const stepNumber = (i / ySteps) * highest;

      steps.push(
        <div className={'barChart-yAxis-step'}>
          {`${
            yAxisUnit === '€' ? stepNumber.toFixed(2) : Math.floor(stepNumber)
          }${yAxisUnit ?? ''}`}
        </div>
      );
    }
    return steps.reverse();
  };

  const renderXAxisSteps = () => {
    return data.map((entry, index) => {
      return (
        <div
          key={index}
          className={'barChart-xAxis-step'}
          style={{
            width: barWidth,
          }}
        >
          {xAxisIsDate
            ? displayReadableMinifiedDate(entry[xAxisKey])
            : entry[xAxisKey]}
        </div>
      );
    });
  };

  console.log(hoveredKeyId);

  const renderBar = (bar: any, index: number) => {
    console.log(barColorIdKey ? hoveredKeyId === bar[barColorIdKey] : null);
    return (
      <Hoverable
        key={`bar_${index}`}
        onHoverNode={onHover ? onHover(bar) : undefined}
        style={{
          height: `${(100 * bar[yAxisKey]) / highest}%`,
          backgroundColor: barColorIdKey
            ? `#${randomColor(bar[barColorIdKey])}`
            : barColors
            ? barColors[index]
            : 'var(--color-blue)',
        }}
        helperClass={`barChart-stage-stacks-stack-bar ${
          barPopup || onHover
            ? 'barChart-stage-stacks-stack-bar__hoverable'
            : ''
        } ${
          keyHighlightOnHover &&
          barColorIdKey &&
          hoveredKeyId === bar[barColorIdKey]
            ? 'barChart-stage-stacks-stack-bar__highlighted'
            : ''
        }`}
        onClick={barPopup ? () => setBarPopupData(bar) : undefined}
        onHoverStart={
          keyHighlightOnHover && barColorIdKey
            ? () => setHoveredKeyId(bar[barColorIdKey])
            : undefined
        }
        onHoverEnd={
          keyHighlightOnHover && barColorIdKey
            ? () => setHoveredKeyId(null)
            : undefined
        }
      />
    );
  };

  if (highest) {
    return (
      <div className={'barChart'}>
        <div className={'barChart-group'}>
          {yAxisName ? (
            <div className={'barChart-yAxis-name'}>
              <div>
                <span>{yAxisName}</span>
              </div>
            </div>
          ) : null}
          <div className={'barChart-yAxis'}>{renderYAxisSteps()}</div>
          <div className={'barChart-stage'}>
            <div
              className={'barChart-stage-stacks'}
              style={height ? { height: height } : undefined}
            >
              {stackKey
                ? data.map((stack, stackIndex) => {
                    return (
                      <div
                        key={`stack_${stackIndex}`}
                        className={`barChart-stage-stacks-stack ${
                          stackType
                            ? `barChart-stage-stacks-stack__${stackType}`
                            : ''
                        }`}
                        style={{
                          width: barWidth,
                        }}
                      >
                        {stack[stackKey]?.map((x: any, index: number) => {
                          return renderBar(x, index);
                        })}
                      </div>
                    );
                  })
                : data.map((x, index) => {
                    return (
                      <div
                        key={`stack_${index}`}
                        className={'barChart-stage-stacks-stack'}
                        style={{
                          width: barWidth,
                        }}
                      >
                        {renderBar(x, index)}
                      </div>
                    );
                  })}
            </div>
            <div className={'barChart-xAxis'}>{renderXAxisSteps()}</div>
          </div>
        </div>
        {xAxisName ? (
          <div className={'barChart-xAxis-name'}>{xAxisName}</div>
        ) : null}
        {barPopup && barPopupData ? (
          <Popup
            toggled={true}
            width={'30%'}
            close={() => setBarPopupData(null)}
          >
            {barPopup(barPopupData)}
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default BarChart;
