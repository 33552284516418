import './animalspeciesandproductgroup.css';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductResponse,
  ProductGroupResponse,
  CreateProductRequest,
  AnimalSpeciesResponse,
  UpdateProductRequest,
} from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { Error, useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { CardTitle } from '../../../elements/card/Card';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import ProductGroupMappingWizard from '../productgroupmappingwizward/ProductGroupMappingWizard';
import { transformProductToRequest } from '../../../view/products/Product';

interface AnimalSpeciesAndProductGroupProps {
  product: ProductResponse | CreateProductRequest;
  updateProduct?: (product: ProductResponse) => void;
  updateNewProduct?: (product: CreateProductRequest) => void;
  onFinish: () => void;
  locked: boolean;
  readonly?: boolean;
  errors?: Error[];
}

const AnimalSpeciesAndProductGroup: React.FC<
  AnimalSpeciesAndProductGroupProps
> = ({
  product,
  updateProduct,
  updateNewProduct,
  onFinish,
  locked,
  readonly,
  errors,
}) => {
  const { i18n, t } = useTranslation('translations', {
    keyPrefix: 'view.product.animalSpeciesAndProductGroup',
  });
  const api = usePetCloudApi();
  const productGroupsApi = api.productGroupsApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const errorHandler = useErrorHandler();
  const [popup, setPopup] = useState(false);
  const [productGroups, setProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [animalSpecies, setAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);

  useEffect(() => {
    getProductGroups();
    getAnimalSpecies();
  }, []);

  const getProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        setProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderAnimalSpecies = () => {
    if (updateNewProduct) {
      return animalSpecies
        ?.filter((s) =>
          (product as CreateProductRequest).animalSpeciesIds.includes(s.id)
        )
        .map((s) => {
          return (
            <div className={'animalSpeciesAndProductGroup-section-dogTag'}>
              {s.name[i18n.language as TranslatedStringIndex]}
            </div>
          );
        });
    } else if (updateProduct) {
      const animalSpecies = (product as ProductResponse).animalSpecies;
      if (animalSpecies && animalSpecies.length > 0) {
        return animalSpecies.map((s, i) => {
          return (
            <div
              key={i}
              className={'animalSpeciesAndProductGroup-section-dogTag'}
            >
              {s.name[i18n.language as TranslatedStringIndex]}
            </div>
          );
        });
      } else {
        return (
          <div
            className={'animalSpeciesAndProductGroup-section-dogTag_emptyAlert'}
          >
            {t('emptyAlert')}
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const update = (request: CreateProductRequest | UpdateProductRequest) => {
    if (updateNewProduct) {
      updateNewProduct(request);
    } else if (updateProduct) {
      updateProduct({
        ...(product as ProductResponse),
        animalSpecies:
          animalSpecies?.filter((s) =>
            request.animalSpeciesIds.includes(s.id)
          ) ?? [],
        productGroupId: request.productGroupId ?? 'error',
      });
    }
  };

  if (animalSpecies && productGroups) {
    return (
      <div className="animalSpeciesAndProductGroup">
        <div className={'animalSpeciesAndProductGroup-section'}>
          <CardTitle
            title={t('animalSpecies')}
            required
            hasBottomMargin
            locked={locked}
          />
          <div className={'animalSpeciesAndProductGroup-section-dogTags'}>
            {renderAnimalSpecies()}
          </div>
        </div>
        <div className={'animalSpeciesAndProductGroup-section'}>
          <CardTitle
            title={t('productGroup')}
            required
            hasBottomMargin
            locked={locked}
          />
          <div className={'animalSpeciesAndProductGroup-section-dogTags'}>
            {product.productGroupId ? (
              <div className={'animalSpeciesAndProductGroup-section-dogTag'}>
                {
                  productGroups.find((g) => g.id === product.productGroupId)
                    ?.name[i18n.language as TranslatedStringIndex]
                }
              </div>
            ) : (
              <div
                className={
                  'animalSpeciesAndProductGroup-section-dogTag_emptyAlert'
                }
              >
                {t('emptyAlert')}
              </div>
            )}
          </div>
        </div>
        {!locked ? (
          <>
            <Button
              cta={t('cta')}
              look={'secondary'}
              width={'full'}
              action={() => setPopup(true)}
              active={!readonly}
            />
            <Popup toggled={popup} width={'20%'} close={() => setPopup(false)}>
              <div className={'popup-title'}>{t('popup.title')}</div>
              <ProductGroupMappingWizard
                onFinish={() => {
                  setPopup(false);
                  onFinish();
                }}
                onFinishCta={t('popup.cta')}
                hideResetButton={true}
                productGroups={productGroups}
                animalSpecies={animalSpecies}
                product={
                  updateProduct
                    ? transformProductToRequest(product as ProductResponse)
                    : (product as CreateProductRequest)
                }
                updateProduct={update}
              />
            </Popup>
          </>
        ) : null}
        <ErrorCheck errors={errors} checkFor={['ProductGroupId']} />
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

const deepCompare = (
  prevProps: AnimalSpeciesAndProductGroupProps,
  nextProps: AnimalSpeciesAndProductGroupProps
) => {
  return (
    prevProps.product === nextProps.product &&
    prevProps.errors === nextProps.errors
  );
};

export default memo(AnimalSpeciesAndProductGroup, deepCompare);
