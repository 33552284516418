import { useEffect, useState } from 'react';
import { UpsertProductAdditionalInformationBulletPointsRequest } from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import Input from '../../../../elements/input/Input';
import './bulletpoints.css';
import { ReactComponent as Trash } from '../../../../../assets/icon/trash.svg';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { TabSelector } from '../../../../elements/selectors/Selectors';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { Store } from 'react-notifications-component';

interface BulletpointsProps {
  productId?: string;
  body: UpsertProductAdditionalInformationBulletPointsRequest;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  getProduct?: () => void;
  closePopup?: () => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  onSave?: (request: any) => void;
  onAdd?: (request: any) => void;
}

const Bulletpoints: React.FC<BulletpointsProps> = ({
  productId,
  body,
  queueCriticalAction,
  killCriticalAction,
  getProduct,
  closePopup,
  readonly,
  toggleProductSavingHelpOverlay,
  onSave,
  onAdd,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const [payload, setPayload] = useState(body);
  const [selectedLanguage, setSelectedLanguage] = useState('de-DE');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onSave) {
      onSave(payload);
    }
  }, [payload]);

  const submitBulletpoints = (
    request: UpsertProductAdditionalInformationBulletPointsRequest
  ) => {
    if (productId && !onSave && !onAdd) {
      setIsSubmitting(true);
      if (queueCriticalAction) {
        queueCriticalAction('updatingBulletpoints');
      }
      productsApi
        .productsUpsertProductAdditionalInformationRichText2(productId, request)
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          Store.addNotification({
            message: t(
              'view.product.additionalinformation.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (getProduct) {
            getProduct();
          }
          if (killCriticalAction) {
            killCriticalAction('updatingBulletpoints');
          }
          if (closePopup) {
            closePopup();
          }
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          errorHandler.addError(error.response);
          if (killCriticalAction) {
            killCriticalAction('updatingBulletpoints');
          }
        });
    } else if (onAdd) {
      onAdd(request);
    }
  };

  const renderBulletPointEntry = (
    i: number,
    content: string,
    selectedLanguage: TranslatedStringIndex
  ) => {
    return (
      <div key={selectedLanguage + i} className="bulletpoints-entry">
        <div className="bulletpoints-entry-number">{'#' + (i + 1)}</div>
        <div className="bulletpoints-entry-input">
          <Input
            content={content}
            noMargin={true}
            update={(e) => {
              const update = payload.items[selectedLanguage];
              if (update) {
                update[i] = e;
                setPayload({
                  ...payload,
                  items: {
                    ...payload.items,
                    [selectedLanguage]: [...update],
                  },
                });
              }
            }}
          />
        </div>
        {!readonly ? (
          <div className="bulletpoints-entry-delete">
            <Button
              type="icon"
              look={'secondary'}
              action={() => {
                const update = payload.items[selectedLanguage];
                if (update) {
                  update.splice(i, 1);
                  setPayload({
                    ...payload,
                    items: {
                      ...payload.items,
                      [selectedLanguage]: [...update],
                    },
                  });
                }
              }}
            >
              <Trash
                fill="var(--color-danger)"
                className="button-icon button-icon-danger"
              />
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="bulletpoints">
      <TabSelector
        tabs={[
          {
            title: 'de-DE',
            key: 'de-DE',
          },
          {
            title: 'en-GB',
            key: 'en-GB',
          },
        ]}
        activeTabKey={selectedLanguage}
        update={(key: string) => setSelectedLanguage(key)}
      />
      <div className="bulletpoints-entries">
        {payload.items[selectedLanguage as TranslatedStringIndex]?.map(
          (bulletpoint, i) => {
            return renderBulletPointEntry(
              i,
              bulletpoint,
              selectedLanguage as TranslatedStringIndex
            );
          }
        )}
      </div>
      {!readonly ? (
        <div className="bulletpoints-actions">
          <Button
            cta={t(
              'view.product.additionalinformation.newPopup.bulletpoints.add'
            )}
            look={'secondary'}
            width="minimal"
            action={() => {
              const update =
                payload.items[selectedLanguage as TranslatedStringIndex];
              if (update) {
                update.push('');
                setPayload({
                  ...payload,
                  items: {
                    ...payload.items,
                    [selectedLanguage as TranslatedStringIndex]: [...update],
                  },
                });
              }
            }}
          />
          {!onSave ? (
            <Button
              cta={t('actions.save')}
              look="save"
              width="minimal"
              action={() => submitBulletpoints(payload)}
              isLoading={isSubmitting}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Bulletpoints;
