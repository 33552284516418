import { useTranslation } from 'react-i18next';
import { ProductResponse } from '../../api/petcloudapi/api';
import './productsearch.css';
import SearchBar from '../searchbar/Searchbar';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { useState } from 'react';
import { ReactComponent as ImagePlaceholder } from '../../../assets/icon/image.svg';

export const displayStringHighlighted = (
  string: string | null | undefined,
  lowercasedQuery: string | undefined
) => {
  if (string && lowercasedQuery) {
    const highlightIndex = string.toLowerCase().indexOf(lowercasedQuery);
    if (highlightIndex !== -1) {
      const firstPart = string.substring(0, highlightIndex);
      const highlightPart = string.substring(
        highlightIndex,
        highlightIndex + lowercasedQuery.length
      );
      const lastPart = string.substring(
        firstPart.length + highlightPart.length
      );

      return (
        <div className="productsearch-result-displayText">
          <span className="productsearch-result-displayText">
            <div className="productsearch-result-displayText-normal">
              {firstPart}
            </div>
            <div className="productsearch-result-displayText-highlighted">
              {highlightPart}
            </div>
            <div className="productsearch-result-displayText-normal">
              {lastPart}
            </div>
          </span>
        </div>
      );
    } else {
      return string;
    }
  } else {
    return string;
  }
};

export const queryItems = (
  query: string,
  items: any,
  setQueryCallback: (query: string) => void,
  resultsCallback: (products: any) => void,
  filterFunction: (item: any, lowercasedQuery: string) => boolean
): any[] => {
  const lowercasedQuery = query.toLocaleLowerCase();
  const results = items?.filter((item: any) =>
    filterFunction(item, lowercasedQuery)
  );
  setQueryCallback(lowercasedQuery);
  if (results && results.length > 0) {
    resultsCallback([...results]);
  }
  return results;
};

interface ProductSearchProps {
  availableProducts: ProductResponse[];
  onSelectProduct: (product: ProductResponse) => void;
  note?: string;
}

const ProductSearch: React.FC<ProductSearchProps> = ({
  availableProducts,
  onSelectProduct,
  note,
}) => {
  const { t, i18n } = useTranslation();
  const [searchResults, setSearchResults] = useState<ProductResponse[]>([]);
  const [currentQuery, setCurrentQuery] = useState('');

  return (
    <div className="productsearch">
      {note ? <div className="productsearch-note">{note}</div> : null}
      <SearchBar
        cta={t('components.productsearch.searchPrompt')}
        height="normal"
        onDebouncedSearch={(input) => {
          queryItems(
            input,
            availableProducts,
            (q) => {
              setCurrentQuery(q);
            },
            (p) => {
              setSearchResults(p);
            },
            (item: any, lowercasedQuery: string) => {
              return (
                item.name['de-DE']
                  ?.toLocaleLowerCase()
                  .includes(lowercasedQuery) ||
                item.name['en-GB']
                  ?.toLocaleLowerCase()
                  .includes(lowercasedQuery) ||
                item.ean.toLocaleLowerCase().includes(lowercasedQuery) ||
                item.productNumber
                  .toLocaleLowerCase()
                  .includes(lowercasedQuery) ||
                item.manufacturerProductNumber
                  ?.toLocaleLowerCase()
                  .includes(lowercasedQuery)
              );
            }
          );
        }}
      />
      {searchResults.length > 0 && currentQuery ? (
        <div className="productsearch-results">
          {searchResults.map((result, i) => {
            return (
              <div
                key={i}
                className="productsearch-result"
                onClick={() => {
                  onSelectProduct(result);
                  setSearchResults([]);
                }}
              >
                {result.cover ? (
                  <img
                    src={
                      result.cover?.asset[
                        i18n.language as TranslatedStringIndex
                      ]?.uri
                    }
                    className="productsearch-result-cover"
                    alt="cover"
                  />
                ) : (
                  <div className="productsearch-result-cover">
                    <ImagePlaceholder
                      className="productsearch-result-cover-placeholder"
                      fill="var(--color-background)"
                    />
                  </div>
                )}
                <div className="productsearch-result-title">
                  {displayStringHighlighted(
                    result.name[i18n.language as TranslatedStringIndex],
                    currentQuery.toLocaleLowerCase()
                  )}
                </div>
                <div className="productsearch-result-ean">
                  {displayStringHighlighted(
                    result.ean,
                    currentQuery.toLocaleLowerCase()
                  )}
                </div>
                <div className="productsearch-result-information">
                  {displayStringHighlighted(
                    result.productNumber,
                    currentQuery.toLocaleLowerCase()
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ProductSearch;
