import './propertyvalidationrules.css';
import { useTranslation } from 'react-i18next';
import {
  PropertyValidationRuleResponse,
  UpdatePropertyValidationRuleRequest,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import List from '../../../features/list/List';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { useEffect, useState } from 'react';
import PropertyValidationRuleEdit from './propertyvalidationruleedit/PropertyValidationRuleEdit';
import Popup from '../../../elements/popup/Popup';
import Button from '../../../elements/button/Button';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import InformationBox from '../../../elements/informationbox/InformationBox';
import useNotifications from '../../../hooks/useNotifications';

interface PropertyValidationRulesProps {
  validationRules: PropertyValidationRuleResponse[] | null | undefined;
  getValidationRules: () => void;
}

const PropertyValidationRules: React.FC<PropertyValidationRulesProps> = ({
  validationRules,
  getValidationRules,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.propertyValidationRules',
  });
  const api = usePetCloudApi();
  const propertyValidationRulesApi = api.propertyValidationRulesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const [editPopup, setEditPopup] = useState(false);
  const [ruleToEdit, setRuleToEdit] =
    useState<UpdatePropertyValidationRuleRequest | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [ruleToDelete, setRuleToDelete] =
    useState<PropertyValidationRuleResponse | null>(null);

  useEffect(() => {
    if (!editPopup) {
      setRuleToEdit(null);
    }
  }, [editPopup]);

  const submitUpdatedRule = () => {
    if (ruleToEdit) {
      setIsSubmitting(true);
      propertyValidationRulesApi
        .propertyValidationRulesUpdatePropertyValidationRule(
          (ruleToEdit as PropertyValidationRuleResponse).id,
          ruleToEdit
        )
        .then((response) => {
          console.log(response);
          setEditPopup(false);
          getValidationRules();
          setIsSubmitting(false);
          pushNotification(t('edit.notifications.success'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const getRuleToEdit = (id: string) => {
    setEditPopup(true);
    propertyValidationRulesApi
      .propertyValidationRulesGetPropertyValidationRuleById(id)
      .then((response) => {
        console.log(response);
        setRuleToEdit(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deleteRule = (id: string) => {
    setIsDeleting(true);
    propertyValidationRulesApi
      .propertyValidationRulesDeletePropertyValidationRule(id)
      .then((response) => {
        console.log(response);
        setRuleToDelete(null);
        setIsDeleting(false);
        pushNotification(t('delete.notifications.success'));
        getValidationRules();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsDeleting(false);
      });
  };

  if (validationRules) {
    return (
      <div className={'propertyValidationRules'}>
        {validationRules.length > 0 ? (
          <List
            items={validationRules}
            ignore={['id', 'updatedAt', 'message', 'description']}
            dateStrings={['createdAt']}
            onRowClick={(rule) => getRuleToEdit(rule.id)}
            monoSpaceStrings={['objectPath']}
            translatedStrings={['message', 'description']}
            selectedLocale={i18n.language as TranslatedStringIndex}
            actions={[
              {
                cta: 'edit',
                look: 'blue',
                action: (rule) => getRuleToEdit(rule.id),
              },
              {
                cta: 'delete',
                look: 'danger',
                action: (rule) => setRuleToDelete(rule),
              },
            ]}
          />
        ) : (
          <EmptyState />
        )}
        <Popup
          toggled={editPopup}
          width={'40%'}
          close={() => setEditPopup(false)}
        >
          {ruleToEdit ? (
            <>
              <div className={'popup-title'}>{t('edit.title')}</div>
              <PropertyValidationRuleEdit
                request={ruleToEdit}
                update={(r) => setRuleToEdit(r)}
              />
              <div className={'global-cardActions'}>
                <Button
                  cta={t('edit.cta')}
                  look={'save'}
                  isLoading={isSubmitting}
                  action={submitUpdatedRule}
                  width={'minimal'}
                />
              </div>
            </>
          ) : (
            <LoadingContainer />
          )}
        </Popup>
        {ruleToDelete ? (
          <Popup
            toggled={true}
            width={'20%'}
            close={() => setRuleToDelete(null)}
          >
            <div className={'popup-title'}>{t('delete.title')}</div>
            <InformationBox content={t('delete.text')} type={'warning'} />
            <Button
              cta={t('delete.cta')}
              look={'danger'}
              isLoading={isDeleting}
              action={() => deleteRule(ruleToDelete?.id)}
              width={'full'}
            />
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default PropertyValidationRules;
