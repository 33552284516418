import './supportinfo.css';
import supportImg from '../../../../assets/img/customer-service.svg';
import { ReactComponent as IconPhone } from '../../../../assets/icon/phone.svg';
import { useTranslation } from 'react-i18next';

const SupportInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="supportInfo">
      <div className="supportInfo-block">
        <div className="supportInfo-block-title">
          {t('view.support.b2b.title')}
        </div>
        <div className="supportInfo-block-description">
          {t('view.support.b2b.text')}
        </div>
        <div className={'supportInfo-block-call'}>
          <img
            src={supportImg}
            alt={'call img'}
            className={'supportInfo-block-call-img'}
          />
          <div>
            <div className="supportInfo-block-phone">
              <IconPhone
                className={'supportInfo-block-phone-icon'}
                fill={'var(--color-text_primary)'}
              />
              +49 (0) 521 92279 501
            </div>
            <div className="supportInfo-block-text">
              {t('view.support.b2b.times1')}
            </div>
            <div className="supportInfo-block-text">
              {t('view.support.b2b.times2')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportInfo;
