import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import languageTabs from '../../../../locate/LanguageTabs';
import {
  AssetFolderResponse,
  AssetResponse,
  BrandResponse,
  CreateBrandRequest,
  TranslatedTypeResponseOfAssetResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import Dropzone from '../../../elements/dropzone/Dropzone';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import Input from '../../../elements/input/Input';
import { TabSelector } from '../../../elements/selectors/Selectors';
import {
  hasError,
  Error,
  useErrorHandler,
} from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import './brandprofile.css';
import Popup from '../../../elements/popup/Popup';
import FileBrowser from '../../../elements/filebrowser/FileBrowser';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Card, { CardSection } from '../../../elements/card/Card';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';

interface BrandProfileProps {
  brand: BrandResponse | CreateBrandRequest;
  updateBrand: (brand: BrandResponse | CreateBrandRequest) => void;
  deleteLogo: (lang: TranslatedStringIndex) => void;
  addLogo: (lang: TranslatedStringIndex, asset: AssetResponse) => void;
  logoAssets?: TranslatedTypeResponseOfAssetResponse | null;
  errors?: Error[];
}

const BrandProfile: React.FC<BrandProfileProps> = ({
  brand,
  logoAssets,
  updateBrand,
  addLogo,
  deleteLogo,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const assetFoldersApi = api.assetFoldersApi();
  const errorHandler = useErrorHandler();
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);

  const [fileBrowserPopup, setFileBrowserPopup] = useState(false);

  useEffect(() => {
    getAssetFolders();
  }, []);

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return (
    <Card bigScreenWidth={'full'}>
      <CardSection>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              key={brand.slug}
              title={t('view.brand.profile.slug')}
              content={brand.slug}
              update={(e) => {
                updateBrand({
                  ...brand,
                  slug: e,
                });
              }}
              hint={t('view.brand.profile.slug_hint')}
              errors={hasError(errors, ['Slug'])}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <ToggleSwitch
              toggled={brand.isActiveInStorefront}
              toggle={() => {
                updateBrand({
                  ...brand,
                  isActiveInStorefront: !brand.isActiveInStorefront,
                });
              }}
              label={t('view.brand.profile.isActiveInStorefront')}
            />
          </div>
        </div>
      </CardSection>
      <CardSection>
        <div className="brandprofile">
          <TabSelector
            tabs={languageTabs}
            activeTabKey={selectedLang}
            update={(key) => setSelectedLang(key)}
            look="spanToEdges"
          />
          <div className="brandprofile-content">
            <div className="brandprofile-meta">
              <div className="global-inputGroup">
                <div className="global-inputGroup-input">
                  <Input
                    key={selectedLang}
                    title={t('view.brand.profile.name')}
                    content={brand.name[selectedLang as TranslatedStringIndex]}
                    update={(e) => {
                      updateBrand({
                        ...brand,
                        name: {
                          ...brand.name,
                          [selectedLang as TranslatedStringIndex]:
                            e !== '' ? e : null,
                        },
                      });
                    }}
                    errors={hasError(errors, ['Name'])}
                  />
                </div>
              </div>
              <div className="global-inputGroup">
                <div className="global-inputGroup-input">
                  <Input
                    key={selectedLang}
                    title={t('view.brand.profile.claim')}
                    content={
                      brand.claim
                        ? brand.claim[selectedLang as TranslatedStringIndex]
                        : ''
                    }
                    update={(e) => {
                      updateBrand({
                        ...brand,
                        claim: {
                          ...brand.claim,
                          [selectedLang as TranslatedStringIndex]:
                            e !== '' ? e : null,
                        },
                      });
                    }}
                    errors={hasError(errors, ['Claim'])}
                  />
                </div>
              </div>
            </div>
            <div className="brandprofile-logo">
              <ErrorCheck
                errors={errors}
                checkFor={['$.logoId.' + selectedLang]}
              />
              {logoAssets &&
              logoAssets[selectedLang as TranslatedStringIndex] ? (
                <>
                  <img
                    src={logoAssets[selectedLang as TranslatedStringIndex]?.uri}
                    className="brandprofile-logo-img"
                    alt="logo"
                  />
                  <div className="brandprofile-logo-actions">
                    <Button
                      cta={t('actions.delete')}
                      look="secondary-danger"
                      width="full"
                      action={() =>
                        deleteLogo(selectedLang as TranslatedStringIndex)
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="brandprofile-logo-dropzone">
                    <Dropzone
                      maxFiles={1}
                      callback={(responses) =>
                        addLogo(
                          selectedLang as TranslatedStringIndex,
                          responses[0]
                        )
                      }
                    />
                  </div>
                  <div className="brandprofile-logo-actions">
                    <Button
                      cta={t('view.brand.profile.chooseFromGallery')}
                      look={'secondary'}
                      width="full"
                      action={() => setFileBrowserPopup(true)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <Popup
            width={'50%'}
            toggled={fileBrowserPopup}
            close={() => setFileBrowserPopup(false)}
          >
            {assetFolders ? (
              <FileBrowser
                assetFolders={assetFolders}
                allowUrlNavigation={false}
                onChooseAssets={(assets) => {
                  addLogo(selectedLang as TranslatedStringIndex, assets[0]);
                  setFileBrowserPopup(false);
                }}
              />
            ) : (
              <LoadingContainer />
            )}
          </Popup>
        </div>
      </CardSection>
    </Card>
  );
};

export default BrandProfile;
