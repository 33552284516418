import './settingsmenu.css';
import { ReactComponent as IconTeam } from '../../../../assets/icon/team.svg';
import { ReactComponent as IconSettings } from '../../../../assets/icon/settings.svg';
import { ReactComponent as IconSupport } from '../../../../assets/icon/support.svg';
import { ReactComponent as IconLogout } from '../../../../assets/icon/logout.svg';
import { useUser } from '../../../contexts/auth/User';
import ProfileImage from '../../../elements/dogtag/profileimage/ProfileImage';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClickOutsideContainer from '../../../elements/clickoutsidecontianer/ClickOutsideContainer';
import ThemeSwitcher from '../../../elements/themeswitcher/ThemeSwitcher';

interface SettingsMenuProps {
  toggled: boolean;
  close: () => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ toggled, close }) => {
  const { t } = useTranslation();
  const { user, logUserOut } = useUser();

  if (user && toggled) {
    return (
      <ClickOutsideContainer onClickOutside={close}>
        <div className={'settingsMenu'}>
          <div className={'settingsMenu-section'}>
            <div className={'settingsMenu-section-user'}>
              <ProfileImage name={user.firstName} lastName={user.lastName} />
              <div className={'settingsMenu-section-user-meta'}>
                <div className={'settingsMenu-section-user-meta-name'}>
                  {`${user.firstName} ${user.lastName}`}
                </div>
                <div className={'settingsMenu-section-user-meta-email'}>
                  {user.email}
                </div>
              </div>
            </div>
          </div>
          <div className={'settingsMenu-section'}>
            <Link to={'/account'}>
              <div
                className={
                  'settingsMenu-section-item settingsMenu-section-link'
                }
              >
                <IconSettings
                  fill={'var(--color-text_secondary)'}
                  className={'settingsMenu-section-item-icon'}
                />
                <div className={'settingsMenu-section-item-name'}>
                  {t('sidebar.settings.account')}
                </div>
              </div>
            </Link>
            <Link to={'/team'}>
              <div
                className={
                  'settingsMenu-section-item settingsMenu-section-link'
                }
              >
                <IconTeam
                  fill={'var(--color-text_secondary)'}
                  className={'settingsMenu-section-item-icon'}
                />
                <div className={'settingsMenu-section-item-name'}>
                  {t('sidebar.settings.team')}
                </div>
              </div>
            </Link>
            <Link to={'/support'}>
              <div
                className={
                  'settingsMenu-section-item settingsMenu-section-link'
                }
              >
                <IconSupport
                  fill={'var(--color-text_secondary)'}
                  className={'settingsMenu-section-item-icon'}
                />
                <div className={'settingsMenu-section-item-name'}>
                  {t('sidebar.settings.support')}
                </div>
              </div>
            </Link>
          </div>
          <div className={'settingsMenu-section'}>
            <div className={'settingsMenu-section-item'}>
              <ThemeSwitcher hideTitle smallSwitch />
            </div>
          </div>
          <div className={'settingsMenu-section'} onClick={logUserOut}>
            <div className={'settingsMenu-section-item'}>
              <IconLogout
                fill={'var(--color-danger)'}
                className={'settingsMenu-section-item-icon'}
              />
              <div
                className={
                  'settingsMenu-section-item-name settingsMenu-section-item-name-danger'
                }
              >
                {t('actions.logout')}
              </div>
            </div>
          </div>
        </div>
      </ClickOutsideContainer>
    );
  } else {
    return null;
  }
};

export default SettingsMenu;
