import './productgroupmappingwizard.css';
import { Dropdown } from '../../../elements/selectors/Selectors';
import {
  AnimalSpeciesResponse,
  CreateProductRequest,
  ProductGroupResponse,
  UpdateProductRequest,
} from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import AnimalSpeciesArray from '../../admin/animalspecies/animalspeciesarray/AnimalSpeciesArray';
import Button from '../../../elements/button/Button';
import useStorageData from '../../../hooks/useStorageData';

interface ProductGroupMappingWizardProps {
  onFinish: () => void;
  onFinishCta?: string;
  hideResetButton?: boolean;
  productGroups: ProductGroupResponse[];
  animalSpecies: AnimalSpeciesResponse[];
  product: CreateProductRequest | UpdateProductRequest;
  updateProduct: (product: CreateProductRequest | UpdateProductRequest) => void;
}

const ProductGroupMappingWizard: React.FC<ProductGroupMappingWizardProps> = ({
  onFinish,
  onFinishCta,
  hideResetButton,
  productGroups,
  animalSpecies,
  product,
  updateProduct,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.product.productGroupMappingWizard',
  });
  const { removePersistedObjects } = useStorageData();

  const isInAnimalSpecies = (
    group: ProductGroupResponse,
    animalSpeciesIds: string[]
  ) => {
    const arr = animalSpeciesIds.filter((s) =>
      group.animalSpecies?.find((species) => s === species.id)
    );
    return arr && arr.length === product.animalSpeciesIds.length;
  };

  const updateSpeciesIds = (ids: string[]) => {
    const productGroupInvalid = !productGroups
      .filter((g) => isInAnimalSpecies(g, ids))
      .find((p) => p.id === product.productGroupId);

    updateProduct({
      ...product,
      animalSpeciesIds: ids,
      productGroupId: productGroupInvalid ? '' : product.productGroupId,
    });
  };

  return (
    <div className={'productGroupMappingWizard'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <AnimalSpeciesArray
            animalSpecies={animalSpecies.filter((s) =>
              product.animalSpeciesIds?.includes(s.id)
            )}
            availableAnimalSpecies={animalSpecies}
            onDelete={(index) => {
              let update = product.animalSpeciesIds;
              if (update) {
                update.splice(index, 1);
              }
              updateSpeciesIds(update);
            }}
            onSubmit={(animalSpecies) => {
              let update = product.animalSpeciesIds;
              if (update) {
                update.push(animalSpecies.id);
              } else {
                update = [animalSpecies.id];
              }
              updateSpeciesIds(update);
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('productGroup')}
            optionObjects={productGroups
              .filter((g) => isInAnimalSpecies(g, product.animalSpeciesIds))
              .map((g) => {
                return {
                  id: g.id,
                  name:
                    g.name[i18n.language as TranslatedStringIndex] ??
                    'missing translation',
                };
              })}
            selected={
              productGroups.find((g) => g.id === product.productGroupId)?.name[
                i18n.language as TranslatedStringIndex
              ]
            }
            update={(e) => {
              const id =
                e.currentTarget.selectedOptions[0]?.getAttribute('data-value');
              if (id) {
                updateProduct({ ...product, productGroupId: id });
              }
            }}
            disabled={product.animalSpeciesIds.length === 0}
          />
        </div>
      </div>
      <div className={'global-cardActions global-cardActions-spaceBetween'}>
        {hideResetButton ? null : (
          <Button
            cta={t('reset')}
            look={'secondary'}
            width={'minimal'}
            action={() => {
              removePersistedObjects([
                'new_product_base',
                'new_product_assets',
                'new_product_variants',
                'new_product_categories',
                'new_product_properties',
                'new_product_stocks',
                'new_product_prices',
                'new_product_shipping',
              ]);
              updateProduct({
                ...product,
                animalSpeciesIds: [],
                productGroupId: '',
              });
            }}
          />
        )}
        <Button
          cta={onFinishCta ?? t('next')}
          look={'save'}
          width={hideResetButton ? 'full' : 'minimal'}
          action={onFinish}
          active={
            !!product.productGroupId && product.animalSpeciesIds.length > 0
          }
        />
      </div>
    </div>
  );
};

export default ProductGroupMappingWizard;
