import './manufacturerinfo.css';
import { TFunction } from 'react-i18next';
import Input from '../../../elements/input/Input';
import {
  CreateManufacturerRequest,
  UpdateManufacturerRequest,
} from '../../../api/petcloudapi/api';
import { hasError, Error } from '../../../contexts/errorhandler/ErrorHandler';

interface ManufacturerInfoProps {
  t: TFunction<'translation'>;
  updateNewManufacturer?: (manufacturer: CreateManufacturerRequest) => void;
  updateCurrentManufacturer?: (manufacturer: UpdateManufacturerRequest) => void;
  manufacturer: CreateManufacturerRequest | UpdateManufacturerRequest;
  errors?: Error[] | null;
}

const ManufacturerInfo: React.FC<ManufacturerInfoProps> = ({
  t,
  manufacturer,
  updateNewManufacturer,
  updateCurrentManufacturer,
  errors,
}) => {
  const updateCompanyName = (e: string) => {
    if (manufacturer) {
      manufacturer.companyName = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateCompanyId = (e: string) => {
    // enable after api endpoint supports company IDs
    if (manufacturer) {
      manufacturer.registrationNumber = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  const updateTaxId = (e: string) => {
    // enable after api endpoint supports tax IDs
    if (manufacturer) {
      manufacturer.vatId = e;
      if (updateNewManufacturer) {
        updateNewManufacturer(manufacturer as CreateManufacturerRequest);
      } else if (updateCurrentManufacturer) {
        updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
      }
    }
  };

  return (
    <div className="manufacturerinfo">
      <div className="manufacturerinfo-inputs">
        <div className="manufacturerinfo-input">
          <Input
            content={manufacturer.companyName}
            update={updateCompanyName}
            title={t('view.manufacturer.meta.info.name')}
            required={true}
            errors={hasError(errors, ['CompanyName'])}
          />
        </div>
        <div className="manufacturerinfo-input">
          <Input
            content={manufacturer.registrationNumber}
            update={updateCompanyId}
            title={t('view.manufacturer.meta.info.companyId')}
            required={true}
            errors={hasError(errors, ['RegistrationNumber'])}
          />
        </div>
        <div className="manufacturerinfo-input">
          <Input
            content={manufacturer.vatId}
            update={updateTaxId}
            title={t('view.manufacturer.meta.info.taxId')}
            required={true}
            errors={hasError(errors, ['VatId'])}
          />
        </div>
      </div>
    </div>
  );
};

export default ManufacturerInfo;
