import './stepselector.css';
import { useTranslation } from 'react-i18next';
import {
  BulkEditorProduct,
  BulkEditorState,
  SessionInfo,
} from '../../../view/products/BulkEditor';
import spreadsheet from '../../../../assets/img/spreadsheet.svg';
import { ReactComponent as IconLoad } from '../../../../assets/icon/reload.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icon/pencil.svg';
import { ReactComponent as IconImport } from '../../../../assets/icon/import.svg';
import { ReactComponent as IconNew } from '../../../../assets/icon/special.svg';
import { useState } from 'react';
import Popup from '../../../elements/popup/Popup';
import SessionHistories from './sessionhistories/SessionHistories';

interface StepSelectorProps {
  setStep: (step: BulkEditorState) => void;
  addEmptyProduct: () => void;
  setSessionInfo: (info: SessionInfo) => void;
  setProducts: (products: BulkEditorProduct[]) => void;
}

const StepSelector: React.FC<StepSelectorProps> = ({
  setStep,
  addEmptyProduct,
  setSessionInfo,
  setProducts,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.stepSelector',
  });
  const [sessionHistoriesPopup, setSessionHistoriesPopup] = useState(false);

  return (
    <div className={'stepSelector'}>
      <div className={'stepSelector-box'}>
        <img
          src={spreadsheet}
          alt={'spreadsheet'}
          className={'stepSelector-box-img'}
        />
        <div className={'stepSelector-box-title'}>{t('title')}</div>
        <div className={'stepSelector-box-options'}>
          <div
            className={'stepSelector-box-option'}
            onClick={() => setSessionHistoriesPopup(true)}
          >
            <IconLoad
              className={'stepSelector-box-option-icon'}
              fill={'var(--color-text_primary)'}
            />
            <div className={'stepSelector-box-option-title'}>
              {t('cta_load')}
            </div>
          </div>
          <div
            className={'stepSelector-box-option stepSelector-box-option__edit'}
            onClick={() => setStep(BulkEditorState.ProductSelection)}
          >
            <IconEdit
              className={'stepSelector-box-option-icon'}
              fill={'var(--color-blue)'}
            />
            <div className={'stepSelector-box-option-title'}>
              {t('cta_edit')}
            </div>
          </div>
          <div
            className={'stepSelector-box-option stepSelector-box-option__new'}
            onClick={() => {
              addEmptyProduct();
              setStep(BulkEditorState.Editor);
            }}
          >
            <IconNew
              className={'stepSelector-box-option-icon'}
              fill={'var(--color-success)'}
            />
            <div className={'stepSelector-box-option-title'}>
              {t('cta_create')}
            </div>
          </div>
          <div
            className={
              'stepSelector-box-option stepSelector-box-option__import'
            }
            onClick={() => {
              setStep(BulkEditorState.Import);
            }}
          >
            <IconImport
              className={'stepSelector-box-option-icon'}
              fill={'var(--color-inherited)'}
            />
            <div className={'stepSelector-box-option-title'}>
              {t('cta_import')}
            </div>
          </div>
        </div>
      </div>
      <Popup
        toggled={sessionHistoriesPopup}
        width={'40%'}
        close={() => setSessionHistoriesPopup(false)}
      >
        <div className={'popup-title'}>{t('sessionHistories.title')}</div>
        <SessionHistories
          setSessionInfo={setSessionInfo}
          setProducts={setProducts}
        />
      </Popup>
    </div>
  );
};

export default StepSelector;
