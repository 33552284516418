import './valuearray.css';
import { ReactComponent as IconDelete } from '../../../assets/icon/close.svg';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import Button from '../button/Button';
import { ReactComponent as IconPlus } from '../../../assets/icon/plus.svg';
import { SmallHint } from '../hint/Hint';

interface ValueArrayProps {
  title?: string;
  hint?: {
    title?: string;
    paragraphs: string[];
  };
  items: any[] | null | undefined;
  titleKey?: string;
  titleKeyLang?: TranslatedStringIndex;
  onDelete?: (index: number) => void;
  onEdit?: () => void;
  editorOverride?: JSX.Element;
  maxWidth?: number | string;
  emptyStateText?: string;
}

const ValueArray: React.FC<ValueArrayProps> = ({
  title,
  hint,
  items,
  titleKey,
  titleKeyLang,
  onDelete,
  onEdit,
  editorOverride,
  maxWidth,
  emptyStateText,
}) => {
  return (
    <div className={'valueArray'}>
      {title ? (
        <div className={'valueArray-title'}>
          <div className={'input-title'}>{title}</div>
          {hint ? (
            <SmallHint title={hint.title} paragraphs={hint.paragraphs} />
          ) : null}
        </div>
      ) : null}
      <div
        className={'valueArray-items'}
        style={maxWidth ? { maxWidth: maxWidth } : undefined}
      >
        <ArrayItems
          items={items}
          titleKey={titleKey}
          titleKeyLang={titleKeyLang}
          onDelete={onDelete}
          emptyStateText={emptyStateText}
        />
        {onEdit && !editorOverride ? (
          <div className={'valueArray-actions'}>
            <Button
              type={'icon'}
              look={'secondary'}
              width={'minimal'}
              action={onEdit}
            >
              <IconPlus
                className={'button-icon'}
                fill={'var(--color-text_primary)'}
              />
            </Button>
          </div>
        ) : null}
        {editorOverride ? (
          <div className={'valueArray-actions'}>{editorOverride}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ValueArray;

interface ArrayItemsProps {
  items: any[] | null | undefined;
  titleKey?: string;
  titleKeyLang?: TranslatedStringIndex;
  onDelete?: (index: number) => void;
  emptyStateText?: string;
}

export const ArrayItems: React.FC<ArrayItemsProps> = ({
  items,
  titleKey,
  titleKeyLang,
  onDelete,
  emptyStateText,
}) => {
  const getLabel = (item: any) => {
    if (titleKey) {
      if (titleKeyLang) {
        return item[titleKey][titleKeyLang];
      } else {
        return item[titleKey];
      }
    } else {
      return item;
    }
  };

  return (
    <>
      {items && items.length ? (
        items.map((item, i) => {
          const label = getLabel(item);
          return (
            <div key={i} className={'valueArray-item'}>
              {label}
              {onDelete ? (
                <IconDelete
                  className={'valueArray-item-iconDelete'}
                  fill={'var(--color-text_tertiary)'}
                  onClick={() => onDelete(i)}
                />
              ) : null}
            </div>
          );
        })
      ) : emptyStateText ? (
        <div className={'valueArray-emptyState'}>{emptyStateText}</div>
      ) : null}
    </>
  );
};
