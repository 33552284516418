import { useTranslation } from 'react-i18next';
import { ProductAssetResponse } from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { MediaItem } from '../mediaitem/MediaItem';
import './productmediaitem.css';

interface ProductMediaItemProps {
  asset: ProductAssetResponse;
  isCover?: boolean;
  lang?: TranslatedStringIndex;
  isSelected?: boolean;
  onSelect?: () => void;
  onDelete?: (id: string) => void;
  disabled?: boolean;
  inherited?: boolean;
}

const ProductMediaItem: React.FC<ProductMediaItemProps> = ({
  asset,
  isCover,
  lang,
  isSelected,
  onSelect,
  onDelete,
  disabled,
  inherited,
}) => {
  const { i18n } = useTranslation();

  const getSrc = () => {
    const src =
      asset.asset[lang ?? (i18n.language as TranslatedStringIndex)]?.uri;
    return src ?? 'no asset provided for selected language';
  };

  return (
    <div className="productmediaitem">
      <MediaItem
        isCover={isCover}
        key={asset.id}
        id={asset.id}
        src={getSrc()}
        isSelected={isSelected}
        onSelect={onSelect}
        onDelete={onDelete}
        disabled={disabled ?? false}
        inherited={inherited}
        isVideo={asset.asset['de-DE']?.assetType === 'Video'}
        fileName={asset.asset['de-DE']?.name}
      />
    </div>
  );
};

export default ProductMediaItem;
