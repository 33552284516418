import { createContext, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../hooks/useNotifications';

type ErrorResponse = {
  data: {
    title?: string;
    status?: number;
    detail?: string;
    errors?: { [key: string]: string[] } | object[];
  };
  status: number;
};

export type Error = {
  key: string;
  values: string[];
};

type ErrorHandlerContextValue = {
  addError: (
    error: ErrorResponse,
    callback?: (errors: Error[]) => void,
    ignore403?: boolean
  ) => void;
  getErrors: () => Error[];
};

const ErrorHandlerContext = createContext({} as ErrorHandlerContextValue);

export const useErrorHandler = () => {
  return useContext(ErrorHandlerContext);
};

export const ErrorHandlerProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Error[]>([]);
  const { pushNotification } = useNotifications();

  const addError = (
    error: ErrorResponse,
    callback?: (errors: Error[]) => void,
    ignore403?: boolean
  ) => {
    console.log(error);
    if (error && error.data.errors) {
      // if the errors are getting sent in the "old way" / as a dictionary
      if (!Array.isArray(error.data.errors)) {
        const errorsAsArray = Object.entries(error.data.errors);
        console.log(errorsAsArray);
        const validationErrors = errorsAsArray.map((e) => {
          const key = e[0];
          const values = e[1];
          return { key: key, values: values };
        });
        setErrors(validationErrors);
        if (callback) {
          callback(validationErrors);
        }
        if (errorsAsArray.length > 0) {
          pushNotification(
            errorsAsArray.reduce((prev, next: any) => {
              return prev + ' | ' + next;
            }, ''),
            'warning',
            10000
          );
        }

        // if the errors are getting sent as an actual array of error objects
      } else {
        const errorsAsArray = error.data.errors;
        console.log(errorsAsArray);
        const validationErrors = errorsAsArray.map((e: any) => {
          return { key: e.propertyName, values: [e.errorMessage] };
        });
        console.log(validationErrors);
        setErrors(validationErrors);
        if (callback) {
          callback(validationErrors);
        }
        if (errorsAsArray.length > 0) {
          pushNotification(
            errorsAsArray.reduce((prev, next: any) => {
              return prev + ' | ' + next.errorMessage;
            }, ''),
            'warning',
            10000
          );
        }
      }
      pushNotification(
        error.data.title
          ? error.data.title
          : error.data.detail
          ? error.data.detail
          : 'Undefined Server Error',
        'danger',
        10000
      );
    } else if (error && error.data) {
      pushNotification(
        'Server Error: ' + error.status + ' | ' + JSON.stringify(error.data),
        'danger',
        10000
      );
    } else if (error && error.status === 403) {
      if (!ignore403) {
        pushNotification(t('errors.403'), 'warning', 10000);
      }
    } else if (error && error.status === 404) {
      window.location.href = '/notfound';
    } else {
      pushNotification('Undefined Server Error', 'danger', 10000);
    }
  };

  const getErrors = () => {
    return errors;
  };

  return (
    <ErrorHandlerContext.Provider
      value={{
        addError,
        getErrors,
      }}
    >
      {children}
    </ErrorHandlerContext.Provider>
  );
};

export const hasError = (
  errors: Error[] | null | undefined,
  keys: string[],
  positiveCallback?: () => void,
  negativeCallback?: () => void
) => {
  if (errors) {
    const error = errors.find((error) => keys.includes(error.key));
    if (error) {
      if (positiveCallback) {
        positiveCallback();
      }
      return error.values;
    } else {
      if (negativeCallback) {
        negativeCallback();
      }
      return null;
    }
  } else {
    if (negativeCallback) {
      negativeCallback();
    }
    return null;
  }
};

export const removeErrors = (
  errors: Error[],
  setErrors: (tabs: SetStateAction<Error[]>) => void,
  keys: string[]
) => {
  const update = [...errors];
  keys.forEach((key) => {
    const i = update.findIndex((e) => e.key === key);
    if (i !== -1) {
      update.splice(i, 1);
    }
  });
  setErrors(update);
  return update;
};

export const addErrors = (
  oldErrors: Error[],
  newErrors: Error[],
  setErrors: (tabs: SetStateAction<Error[]>) => void
) => {
  const update = [...oldErrors];
  newErrors.forEach((error) => {
    const currentIndex = update.findIndex((e) => e.key === error.key);
    if (currentIndex !== -1) {
      update.splice(currentIndex, 1);
    }
    update.push(error);
  });
  setErrors(update);
};
