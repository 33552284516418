import {
  OrderResponse,
  OrderReturnAnnouncementResponse,
  ShippingProviderResponse,
} from '../../../api/petcloudapi/api';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { useTranslation } from 'react-i18next';
import Button from '../../../elements/button/Button';
import { useCallback, useState } from 'react';
import Popup from '../../../elements/popup/Popup';
import NewReturnAnnouncement from './newreturnannouncement/NewReturnAnnouncement';
import List from '../../../features/list/List';
import ReturnAnnouncement from './returnannouncement/ReturnAnnouncement';

interface ReturnAnnouncementsProps {
  order: OrderResponse;
  announcements: OrderReturnAnnouncementResponse[] | null;
  getOrder: () => void;
}

const ReturnAnnouncements: React.FC<ReturnAnnouncementsProps> = ({
  announcements,
  order,
  getOrder,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.order.returnAnnouncements',
  });
  const [newAnnouncementPopup, setNewAnnouncementPopup] = useState(false);
  const [announcementInFocus, setAnnouncementInFocus] =
    useState<OrderReturnAnnouncementResponse | null>(null);

  const renderShippingProvider = useCallback(
    (provider: ShippingProviderResponse) => {
      return <div>{provider.name}</div>;
    },
    []
  );

  const renderPositions = useCallback((positions) => {
    return (
      <span className={'global-monospaced-contrast'}>{positions.length}</span>
    );
  }, []);

  return (
    <div className={'returnAnnouncements'}>
      {announcements && announcements.length > 0 ? (
        <List
          items={announcements}
          onRowClick={(item) => setAnnouncementInFocus(item)}
          ignore={[
            'labelData',
            'customFields',
            'orderShippingDetailId',
            'userId',
            'shippingProviderId',
            'id',
            'updatedAt',
          ]}
          dateStrings={['createdAt']}
          renderObjects={[
            {
              key: 'orderReturnAnnouncementPositions',
              renderMethod: renderPositions,
            },
            {
              key: 'shippingProvider',
              renderMethod: renderShippingProvider,
            },
          ]}
        />
      ) : (
        <EmptyState message={t('empty')} />
      )}
      <div className={'global-cardActions-postBorder'}>
        <Button
          cta={t('new.title')}
          width={'minimal'}
          look={'secondary'}
          action={() => setNewAnnouncementPopup(true)}
          active={!!order.orderShippingDetails}
        />
      </div>
      {newAnnouncementPopup && order.orderShippingDetails ? (
        <Popup
          toggled={true}
          width="30%"
          close={() => setNewAnnouncementPopup(false)}
        >
          <div className="popup-title">{t('new.title')}</div>
          <NewReturnAnnouncement
            order={order}
            closePopup={() => {
              setNewAnnouncementPopup(false);
            }}
            getOrder={getOrder}
          />
        </Popup>
      ) : null}
      {announcementInFocus ? (
        <Popup
          toggled={true}
          width={'50%'}
          close={() => setAnnouncementInFocus(null)}
        >
          <ReturnAnnouncement
            announcement={announcementInFocus}
            order={order}
          />
        </Popup>
      ) : null}
    </div>
  );
};

export default ReturnAnnouncements;
