import './manufacturerlogo.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropzone } from '../../../elements/dropzone/Dropzone';
import {
  AssetResponse,
  CreateManufacturerRequest,
  UpdateManufacturerRequest,
  ManufacturerResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';

interface ManufacturerLogoProps {
  manufacturer:
    | CreateManufacturerRequest
    | UpdateManufacturerRequest
    | ManufacturerResponse;
  updateNewManufacturer?: (manufacturer: CreateManufacturerRequest) => void;
  updateCurrentManufacturer?: (manufacturer: UpdateManufacturerRequest) => void;
  logo?: string | null;
}

const ManufacturerLogo: React.FC<ManufacturerLogoProps> = ({
  manufacturer,
  updateNewManufacturer,
  updateCurrentManufacturer,
  logo,
}) => {
  const { t } = useTranslation();
  const [temporaryLogo, setTemporaryLogo] = useState<string | null>(null);

  // makes sure that the logoAssetId is attached to the UpdateManufacturerRequest (get the id from the logoAsset of the ManufacturerResponse)
  useEffect(() => {
    if (updateCurrentManufacturer) {
      const { logoAsset } = manufacturer as ManufacturerResponse;
      const update = manufacturer as UpdateManufacturerRequest;
      if (logoAsset) {
        updateCurrentManufacturer({ ...update, logoAssetId: logoAsset.id });
      }
    }
  }, []);

  const addLogoToManufacturer = (responses: AssetResponse[]) => {
    (manufacturer as UpdateManufacturerRequest).logoAssetId = responses[0].id;
    setTemporaryLogo(responses[0].uri);
    if (updateNewManufacturer) {
      updateNewManufacturer(manufacturer as CreateManufacturerRequest);
    } else if (updateCurrentManufacturer) {
      updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
    }
  };

  const removeLogoFromManufacturer = () => {
    (manufacturer as UpdateManufacturerRequest).logoAssetId = null;
    if ((manufacturer as ManufacturerResponse).logoAsset) {
      (manufacturer as ManufacturerResponse).logoAsset = null;
    }
    setTemporaryLogo(null);
    if (updateNewManufacturer) {
      updateNewManufacturer(manufacturer as CreateManufacturerRequest);
    } else if (updateCurrentManufacturer) {
      updateCurrentManufacturer(manufacturer as UpdateManufacturerRequest);
    }
  };

  return logo ? (
    <div className="manufacturerlogo-wrapper">
      <img className="manufacturerlogo" src={logo} alt="logo" />
      <Button
        look={'secondary'}
        cta={t('view.manufacturer.meta.logo.remove')}
        action={removeLogoFromManufacturer}
      />
    </div>
  ) : temporaryLogo ? (
    <div className="manufacturerlogo-wrapper">
      <img className="manufacturerlogo" src={temporaryLogo} alt="logo" />
      <Button
        look={'secondary'}
        cta={t('view.manufacturer.meta.logo.remove')}
        action={removeLogoFromManufacturer}
      />
    </div>
  ) : (
    <div className="manufacturerlogo">
      <Dropzone maxFiles={1} callback={addLogoToManufacturer} />
    </div>
  );
};

export default ManufacturerLogo;
