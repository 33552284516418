import TranslatedStringIndex from '../components/types/TranslatedStringIndex';
import { TranslatedContentLanguageMap } from '../components/sections/productbulkeditor/productimporter/columnheaderconfig/utils/useImportFunctions';

export const availableLanguages: TranslatedStringIndex[] = ['de-DE', 'en-GB'];

export const getTranslatedContent = (
  lang: TranslatedStringIndex,
  value: any,
  languageUniform?: boolean
) => {
  const langs = availableLanguages;
  const translations: { [l: string]: any } = {};
  availableLanguages.forEach((l) => (translations[l] = '""'));

  if (languageUniform) {
    langs.forEach((l: TranslatedStringIndex) => {
      translations[l] = value;
    });
  } else {
    translations[lang] = value;
  }

  return translations;
};

export const getTranslatedContentFromImporterLanguageMap = (
  sourceTableRow: any[],
  languageMap: TranslatedContentLanguageMap,
  headers: string[],
  valueFunc?: (value: any) => string
) => {
  const translations: { [l: string]: any } = {};
  availableLanguages.forEach(
    (l) =>
      (translations[l] =
        'No content detected for this language during import process.')
  );

  console.log(sourceTableRow, languageMap, headers);

  Object.entries(languageMap).forEach(([lang, mappedKey]) => {
    const i = headers.indexOf(mappedKey);
    translations[lang] = valueFunc
      ? valueFunc(sourceTableRow[i])
      : sourceTableRow[i];
  });

  return translations;
};
