import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { ProductUnitResponse } from '../../api/petcloudapi/api';

const useProductUnits = () => {
  const api = usePetCloudApi();
  const productUnitsApi = api.productUnitsApi();
  const errorHandler = useErrorHandler();

  const [productUnits, setProductUnits] = useState<
    ProductUnitResponse[] | null
  >(null);
  const [productUnitsReady, setProductUnitsReady] = useState(false);

  useEffect(() => {
    getProductUnits();
  }, []);

  const getProductUnits = () => {
    productUnitsApi
      .productUnitsGetProductUnits()
      .then((response) => {
        console.log(response);
        setProductUnits(response.data);
        setProductUnitsReady(true);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return {
    productUnits,
    productUnitsReady,
  };
};

export default useProductUnits;
