import { MutableRefObject, useEffect } from 'react';
import { usePopupContext } from '../elements/popup/Popup';

const useClickOutsideHandler = (
  ref: MutableRefObject<HTMLDivElement | null>,
  func: () => void,
  popupId: string
) => {
  const { popups } = usePopupContext();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      let targetElement = event.target; // clicked element
      while (targetElement) {
        if (
          targetElement.classList &&
          targetElement.classList.contains('popup-card')
        ) {
          // click is inside a popup, ignore it
          return;
        }
        targetElement = targetElement.parentNode;
      }

      const isTopPopup =
        popups.length > 0 && popups[popups.length - 1].id === popupId;

      if (isTopPopup && ref.current && !ref.current.contains(event.target)) {
        func();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, popups]);

  return func;
};

export default useClickOutsideHandler;
