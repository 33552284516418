import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { FiegePurchaseOrderResponse } from '../../api/petcloudapi/api';
import Button from '../../elements/button/Button';
import Card, { CardSection } from '../../elements/card/Card';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import List from '../../features/list/List';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Stage, Navigator } from '../../layout/stage/Stage';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import _ from 'lodash';

const PurchaseOrders = () => {
  const { t } = useTranslation();
  const { authorizations } = useAuthorization();
  const link = useNavigate();
  const api = usePetCloudApi();
  const fiegePurchaseOrdersApi = api.fiegePurchaseOrdersApi();
  const errorHandler = useErrorHandler();

  const [purchaseOrders, setPurchaseOrders] = useState<
    FiegePurchaseOrderResponse[] | null
  >(null);

  useEffect(() => {
    getPurchaseOrders();
  }, []);

  const getPurchaseOrders = () => {
    fiegePurchaseOrdersApi
      .fiegePurchaseOrdersGetFiegePurchaseOrders()
      .then((response) => {
        console.log(response);
        setPurchaseOrders(_.orderBy(response.data, ['orderNumber'], ['desc']));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  return (
    <Has authorizations={['fiege_purchase_orders:list']} showNoAuthorization>
      {authorizations && purchaseOrders !== null ? (
        <Stage>
          <Navigator title={t('view.purchaseorders.title')}>
            <Button
              look="primary"
              cta={t('view.purchaseorders.new')}
              width="minimal"
              action={() => link('/purchaseorders/new')}
              hasPermission={authorizations.includes(
                'fiege_purchase_orders:create'
              )}
            />
          </Navigator>
          <Card bigScreenWidth="full" noMargin>
            <CardSection>
              {purchaseOrders.length > 0 ? (
                <List
                  items={purchaseOrders}
                  ignore={['id', 'positions', 'manufacturerId', 'warehouseId']}
                  linkedKey={'id'}
                  linkPrefix={() => '/purchaseorders/'}
                  dateStrings={['createdAt', 'updatedAt']}
                  adjustHeightToViewport
                />
              ) : (
                <EmptyState message={t('view.purchaseorders.empty')} />
              )}
            </CardSection>
          </Card>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default PurchaseOrders;
