import './versions.css';
import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductVersionResponse } from '../../../api/petcloudapi/api';
import DateTime from '../../../elements/datetime/DateTime';
import Button from '../../../elements/button/Button';
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/prohibit.svg';

interface VersionsProps {
  productVersions: ProductVersionResponse[];
  getMoreVersions: () => void;
  productId: string;
  visitedProductVersionId?: string;
  setProductVersionId: Dispatch<SetStateAction<string | undefined>>;
  deactivateVersion: () => void;
}

const Versions: React.FC<VersionsProps> = ({
  productVersions,
  getMoreVersions,
  productId,
  visitedProductVersionId,
  setProductVersionId,
  deactivateVersion,
}) => {
  const { t } = useTranslation();
  const link = useNavigate();

  const getProductVersionsSorted = () => {
    return _.orderBy(productVersions, ['versionNumber'], ['desc']);
  };

  const getStateIndicatorClass = (live: boolean) => {
    let result = 'version-version-stateIndicator';
    if (live) {
      result += ' version-version-stateIndicator-live';
    } else {
      result += ' version-version-stateIndicator-offline';
    }
    return result;
  };

  return (
    <div className="versions">
      {productVersions.length === 0 ? (
        <div className="versions-empty">{t('view.product.versions.empty')}</div>
      ) : null}
      {getProductVersionsSorted().map((version) => {
        return (
          <div
            key={version.id}
            className="version"
            onClick={() => {
              link(
                '/products/published/' + productId + '?versionId=' + version.id
              );
              setProductVersionId(version.id);
            }}
            style={
              visitedProductVersionId === version.id
                ? { backgroundColor: 'var(--color-background)' }
                : undefined
            }
          >
            <div className="version-version">
              <div
                className={getStateIndicatorClass(
                  !!(version.isActive && version.syncedAt)
                )}
              ></div>
              <div className="version-version-number">
                #{version.versionNumber.toString().padStart(2, '0')}
              </div>
            </div>
            <div className="version-date">
              {version.isActive ? (
                version.syncedAt ? (
                  <DateTime
                    dateString={version.syncedAt}
                    show={['day', 'month', 'year', 'time']}
                  />
                ) : (
                  t('view.product.versions.syncing')
                )
              ) : (
                <DateTime
                  dateString={version.createdAt}
                  show={['day', 'month', 'year', 'time']}
                />
              )}
            </div>
            {version.isActive && version.syncedAt ? (
              <DeleteIcon
                fill={'var(--color-danger)'}
                className={'version-button'}
                onClick={(e) => {
                  e.stopPropagation();
                  deactivateVersion();
                }}
              />
            ) : null}
          </div>
        );
      })}
      <div className="versions-actions">
        <Button
          cta={t('view.product.versions.more')}
          look={'secondary'}
          width="full"
          action={getMoreVersions}
          active={productVersions.length > 0}
        />
      </div>
    </div>
  );
};

export default Versions;
