import { Check } from '../../../elements/selectors/Selectors';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconUp } from '../../../../assets/icon/indicator_up.svg';
import { ReactComponent as IconDown } from '../../../../assets/icon/indicator_down.svg';
import { ReactComponent as IconClose } from '../../../../assets/icon/close.svg';
import _ from 'lodash';

type OrderByConfig = {
  key: string;
  asc: boolean;
};

interface ListHeaderProps {
  items: {
    [key: string]: any;
  }[];
  selectedItems?: { [key: string]: any }[] | null;
  pageItems?: any[];
  columns: (string | null)[];
  tableHeadContrast?: boolean;
  isShowingIndex?: boolean;
  onSelectAll?: (items: any[]) => void;
  onSelectTranche?: (items: any[]) => void;
  itemImgKey?: string;
  ignoreHeaders?: string[];
  ignoreColumnTranslationExceptKeys?: string[];
  hasActions: boolean;
  orderBy?: OrderByConfig;
  setItemsSorted: (items: any[]) => void;
  sortValueFunctions?: {
    [key: string]: (value: any) => any;
  };
}

const ListHeader: React.FC<ListHeaderProps> = ({
  items,
  selectedItems,
  pageItems,
  columns,
  tableHeadContrast,
  isShowingIndex,
  onSelectAll,
  onSelectTranche,
  itemImgKey,
  ignoreHeaders,
  ignoreColumnTranslationExceptKeys,
  hasActions,
  orderBy,
  setItemsSorted,
  sortValueFunctions,
}) => {
  const { t } = useTranslation('');
  const [sortHeader, setSortHeader] = useState<OrderByConfig | null>(null);

  useEffect(() => {
    if (orderBy) {
      setSortHeader(orderBy);
    }
  }, [orderBy]);

  useEffect(() => {
    if (sortHeader) {
      setItemsSorted(sortItemsByHeader(sortHeader));
    } else {
      setItemsSorted(items);
    }
  }, [sortHeader, items]);

  const sortItemsByHeader = (sortHeader: OrderByConfig) => {
    const results = items;
    if (sortHeader) {
      const sortValueFunc = sortValueFunctions?.[sortHeader.key];
      let sorted;
      if (sortValueFunc) {
        sorted = _.sortBy(results, sortHeader.key, sortValueFunc);
      } else {
        sorted = _.sortBy(results, sortHeader.key);
      }
      if (sortHeader.asc) {
        return sorted.reverse();
      } else {
        return sorted;
      }
    } else {
      return results;
    }
  };

  const handleColumnSort = (headerKey: string) => {
    if (sortHeader && sortHeader.key === headerKey) {
      setSortHeader({
        key: headerKey,
        asc: !sortHeader.asc,
      });
    } else {
      setSortHeader({
        key: headerKey,
        asc: false,
      });
    }
  };

  return (
    <tr
      className={
        tableHeadContrast
          ? 'list-table-tr-head list-table-tr-head-contrast'
          : 'list-table-tr-head'
      }
    >
      {isShowingIndex ? (
        <th className={'list-table-th list-table-th-index'}></th>
      ) : null}
      {columns.map((column, i) => {
        if (column) {
          if (column === itemImgKey) {
            return <th key={i} className="list-table-th"></th>;
          } else if (column === 'select') {
            return (
              <th key={i} className="list-table-th">
                {onSelectTranche && pageItems ? (
                  <div
                    onClick={() => onSelectTranche(pageItems)}
                    className="list-table-th-check"
                  >
                    <Check
                      checked={pageItems.every((x: any) =>
                        selectedItems?.includes(x)
                      )}
                    />
                  </div>
                ) : onSelectAll ? (
                  <div
                    onClick={() => onSelectAll(items)}
                    className="list-table-th-check"
                  >
                    <Check checked={selectedItems?.length === items.length} />
                  </div>
                ) : (
                  <div className="list-table-th-empty"></div>
                )}
              </th>
            );
          } else {
            return (
              <th key={i} className="list-table-th">
                <div className="list-table-th-content">
                  <div
                    className={'list-table-th-title'}
                    onClick={
                      !ignoreHeaders?.includes(column)
                        ? () => handleColumnSort(column)
                        : undefined
                    }
                  >
                    {ignoreHeaders?.includes(column)
                      ? null
                      : ignoreColumnTranslationExceptKeys
                      ? ignoreColumnTranslationExceptKeys.includes(column)
                        ? t('list.columns.' + column)
                        : column
                      : t('list.columns.' + column)}
                    {sortHeader?.key === column ? (
                      <div className={'list-table-th-sort'}>
                        {sortHeader.asc ? (
                          <IconDown
                            className={'list-table-th-sort-indicator'}
                          />
                        ) : (
                          <IconUp className={'list-table-th-sort-indicator'} />
                        )}
                      </div>
                    ) : null}
                  </div>
                  {sortHeader?.key === column ? (
                    <IconClose
                      className={'list-table-th-sort-remove'}
                      onClick={() => setSortHeader(null)}
                    />
                  ) : null}
                </div>
              </th>
            );
          }
        } else {
          return null;
        }
      })}
      {hasActions ? (
        <th className="list-table-th list-table-th-actions"></th>
      ) : null}
    </tr>
  );
};

export default memo(ListHeader);
