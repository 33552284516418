import './promotioncodes.css';
import { PromotionCodeResponse } from '../../../api/petcloudapi/api';
import Badge from '../../../elements/badge/Badge';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCopy } from '../../../../assets/icon/copy.svg';
import { Store } from 'react-notifications-component';

interface PromotionCodesProps {
  codes: PromotionCodeResponse[];
}

const PromotionCodes: React.FC<PromotionCodesProps> = ({ codes }) => {
  const { t } = useTranslation();

  const redeemed = codes.filter((c) => c.redeemed);

  const clipBoard = (code: string) => {
    navigator.clipboard.writeText(code).then(() => {
      Store.addNotification({
        message: t('view.promotion.notifications.clipboard'),
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
    });
  };

  return (
    <div className={'promotionCodes'}>
      <div className={'promotionCodes-summary'}>{`${redeemed.length} / ${
        codes.length
      } ${t('view.promotion.promotionCodes.redeemed')}`}</div>
      <div className={'promotionCodes-codes'}>
        {codes.map((code) => {
          return (
            <div className={'promotionCodes-code'}>
              <div className={'promotionCodes-code-code'}>{code.code}</div>
              <Badge
                title={t(
                  `view.promotion.promotionCodes.${
                    code.redeemed ? 'redeemed' : 'unredeemed'
                  }`
                )}
                color={
                  code.redeemed ? 'var(--color-success)' : 'var(--color-blue)'
                }
              />
              <IconCopy
                className={'promotionCodes-code-copy'}
                onClick={() => clipBoard(code.code)}
                fill={'var(--color-text_secondary)'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PromotionCodes;
