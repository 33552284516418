import './purchaseorderPositionImport.css';
import { useEffect, useState } from 'react';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Dropzone from '../../../elements/dropzone/Dropzone';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import { parse } from 'papaparse';
import InformationBox from '../../../elements/informationbox/InformationBox';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import {
  ProductIdentifierType,
  SimpleProductResponse,
} from '../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import PurchaseOrderPosition from '../../../types/PurchaseOrderPosition';
import { getPurchaseOrderPosition } from '../../../view/purchaseorders/NewPurchaseOrder';

export type PurchaseOrderPositionImportEntry = {
  identifier: string;
  amount: number;
  errorLine?: number;
};

interface PurchaseOrderPositionImportProps {
  callback: (positions: PurchaseOrderPosition[]) => void;
  successCallback: () => void;
  products: SimpleProductResponse[] | null;
}

const PurchaseOrderPositionImport: React.FC<
  PurchaseOrderPositionImportProps
> = ({ callback, products }) => {
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();

  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [positions, setPositions] = useState<
    PurchaseOrderPositionImportEntry[] | null
  >(null);
  const [selectedIdentifier, setSelectedIdentifier] =
    useState<ProductIdentifierType>(ProductIdentifierType.Ean);
  const [header, setHeader] = useState(true);
  const [selectedDelimiter, setSelectedDelimiter] = useState('auto');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [importErrors, setImportErrors] = useState<
    PurchaseOrderPositionImportEntry[]
  >([]);

  useEffect(() => {
    if (file) {
      parseCSV(file);
    }
  }, [file, selectedIdentifier, selectedDelimiter, header]);

  const isNotFaulty = (string: string) => {
    return string !== '' && string !== ' ';
  };

  const parseCSV = (f: File) => {
    parse(f, {
      header: header,
      delimiter: selectedDelimiter !== 'auto' ? selectedDelimiter : undefined,
      skipEmptyLines: true,
      complete: (result) => {
        console.log(result.data);
        const fixed: PurchaseOrderPositionImportEntry[] = [];
        result.data.forEach((entry: any) => {
          const firstValue = entry[Object.keys(entry)[0]];
          const secondValue = entry[Object.keys(entry)[1]];
          console.log(firstValue);
          console.log(secondValue);
          if (isNotFaulty(firstValue) && isNotFaulty(secondValue)) {
            fixed.push({
              identifier: firstValue,
              amount: secondValue,
            });
          }
        });
        console.log(fixed);
        setPositions(fixed as PurchaseOrderPositionImportEntry[]);
      },
    });
  };

  const submit = () => {
    if (positions) {
      const ident = (selectedIdentifier.charAt(0).toLowerCase() +
        selectedIdentifier.slice(1)) as keyof SimpleProductResponse;
      setIsSubmitting(true);

      productsApi
        .productsGetProductsListByIdentifiers({
          productIdentifierType: selectedIdentifier,
          values: positions.map((position) => {
            return position.identifier;
          }),
        })
        .then((response) => {
          console.log(response);
          const results: PurchaseOrderPosition[] = [];
          positions.forEach((position) => {
            const product = response.data.find(
              (p) => p[ident] === position.identifier
            );
            if (product) {
              results.push(
                getPurchaseOrderPosition(
                  product,
                  position.amount,
                  product.cover?.asset[i18n.language as TranslatedStringIndex]
                    ?.uri
                )
              );
            }
          });
          console.log(results);
          callback(results);
          //successCallback();
          Store.addNotification({
            message:
              response.data.length +
              ' ' +
              t(
                'view.purchaseorder.positionImport.notifications.successful_imports'
              ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          Store.addNotification({
            message: t(
              'view.purchaseorder.positionImport.notifications.errors_imports'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const identifierOptions: DropdownOption[] = [
    {
      id: 'Ean',
      name: t('view.purchaseorder.positionImport.identifier_options.ean'),
    },
    {
      id: 'Id',
      name: t('view.purchaseorder.positionImport.identifier_options.productId'),
    },
    {
      id: 'ManufacturerProductNumber',
      name: t(
        'view.purchaseorder.positionImport.identifier_options.manufacturerProductNumber'
      ),
    },
  ];

  const delimiterOptions: DropdownOption[] = [
    {
      id: 'auto',
      name: t('view.purchaseorder.positionImport.delimiter_options.auto'),
    },
    {
      id: ',',
      name: t('view.purchaseorder.positionImport.delimiter_options.comma'),
    },
    {
      id: ';',
      name: t('view.purchaseorder.positionImport.delimiter_options.semicolon'),
    },
    {
      id: '\t',
      name: t('view.purchaseorder.positionImport.delimiter_options.tab'),
    },
    {
      id: ' ',
      name: t('view.purchaseorder.positionImport.delimiter_options.space'),
    },
    {
      id: '|',
      name: t('view.purchaseorder.positionImport.delimiter_options.pipe'),
    },
  ];

  return (
    <div className={'purchaseorderPositionImport'}>
      <div className={'global-inputGroup'}>
        {positions ? (
          <div className={'purchaseorderPositionImport-result'}>
            <InformationBox
              content={
                positions.length +
                ' ' +
                t('view.purchaseorder.positionImport.entries')
              }
              type={'success'}
            />
          </div>
        ) : (
          <div className={'global-inputGroup-input'}>
            <Dropzone
              maxFiles={1}
              callbackWithoutUpload={(files) => setFile(files[0])}
              height={100}
              accept={'.csv'}
            />
          </div>
        )}
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            hint={t('view.purchaseorder.positionImport.identifier_hint')}
            title={t('view.purchaseorder.positionImport.identifier')}
            optionObjects={identifierOptions}
            selected={
              identifierOptions.find((o) => o.id === selectedIdentifier)?.name
            }
            update={(e) => {
              const value =
                e.target.selectedOptions[0].getAttribute('data-value');
              console.log(value);
              if (value) {
                setSelectedIdentifier(value as ProductIdentifierType);
              }
            }}
          />
        </div>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('view.purchaseorder.positionImport.delimiter')}
            hint={t('view.purchaseorder.positionImport.delimiter_hint')}
            optionObjects={delimiterOptions}
            selected={
              delimiterOptions.find((o) => o.id === selectedDelimiter)?.name
            }
            update={(e) => {
              const value =
                e.target.selectedOptions[0].getAttribute('data-value');
              console.log(value);
              if (value) {
                setSelectedDelimiter(value);
              }
            }}
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Check
            title={t('view.purchaseorder.positionImport.header')}
            hint={t('view.purchaseorder.positionImport.header_hint')}
            checked={header}
            update={() => setHeader(!header)}
          />
        </div>
      </div>
      {importErrors.length > 0 ? (
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <div className={'input-title'}>
              {t('view.purchaseorder.positionImport.errors')}
            </div>
            <div className={'purchaseorderPositionImport-errors'}>
              {importErrors.map((error) => {
                return (
                  <div className={'purchaseorderPositionImport-error'}>
                    <div>
                      {t(
                        'view.purchaseorder.positionImport.notifications.notFound.row'
                      ) +
                        ':' +
                        ((error.errorLine ?? 0) + 1)}
                    </div>
                    {t(
                      'view.purchaseorder.positionImport.notifications.notFound.part_1'
                    )}
                    {' ' + selectedIdentifier + ' '}
                    <span>{error.identifier}</span>
                    {' ' +
                      t(
                        'view.purchaseorder.positionImport.notifications.notFound.part_2'
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      <div className={'purchaseorderPositionImport-actions global-cardActions'}>
        {importErrors.length === 0 ? (
          <Button
            cta={t('view.purchaseorder.positionImport.cta')}
            look={'save'}
            width={'full'}
            action={submit}
            active={!!positions}
            isLoading={isSubmitting}
          />
        ) : (
          <Button
            cta={t('view.purchaseorder.positionImport.restart')}
            look={'secondary'}
            width={'full'}
            action={() => {
              setImportErrors([]);
              setPositions(null);
              setFile(null);
            }}
            active={!!positions}
          />
        )}
      </div>
    </div>
  );
};

export default PurchaseOrderPositionImport;
