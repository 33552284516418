import { Stage, Navigator } from '../../layout/stage/Stage';
import { UnderConstruction } from '../../elements/emptystate/EmptyState';
import { useUser } from '../../contexts/auth/User';
import { useTranslation } from 'react-i18next';
import Card, { CardSection, InlineCards } from '../../elements/card/Card';
import BrandShare from '../../sections/widgets/fakes/brandshare/BrandShare';
import PetProfiles from '../../sections/widgets/fakes/petprofiles/PetProfiles';
import CategoryAnalysis from '../../sections/widgets/fakes/categoryanalysis/CategoryAnalysis';

const Statistics = () => {
  const { user } = useUser();
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.statistics',
  });

  if (
    user?.isProductOwner &&
    (process.env.REACT_APP_BUILD_ENV === 'staging' ||
      process.env.REACT_APP_BUILD_ENV === 'test' ||
      process.env.REACT_APP_BUILD_ENV === 'dev')
  ) {
    return (
      <Stage>
        <Navigator title={t('title')}></Navigator>
        <InlineCards>
          <Card bigScreenWidth="50%" title={t('brandShare.title')}>
            <CardSection>
              <BrandShare />
            </CardSection>
          </Card>
          <Card bigScreenWidth="50%" title={t('petProfiles.title')}>
            <CardSection>
              <PetProfiles />
            </CardSection>
          </Card>
        </InlineCards>
        <InlineCards>
          <Card bigScreenWidth={'100%'} title={t('categories.title')}>
            <CardSection>
              <CategoryAnalysis />
            </CardSection>
          </Card>
        </InlineCards>
      </Stage>
    );
  } else {
    return (
      <Stage>
        <UnderConstruction />
      </Stage>
    );
  }
};

export default Statistics;
