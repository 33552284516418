import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './locate/en/translate.json';
import languageDE from './locate/de/translate.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-GB': languageEN,
      'de-DE': languageDE,
    },
    fallbackLng: 'de-DE',
    supportedLngs: ['en-GB', 'de-DE'],
    load: 'currentOnly',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: false,
    },
    detection: {
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupFromPathIndex: 0,
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
