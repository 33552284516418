import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  AssetFolderResponse,
  AssetResponse,
  ProductBundleAssetResponse,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import MediaManager from '../../product/media/mediamanager/MediaManager';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import FileBrowser from '../../../elements/filebrowser/FileBrowser';
import { Has } from '../../../contexts/auth/Authorization';
import _ from 'lodash';

interface BundleAssetsProps {
  bundleId: string;
  setOriginalBundleAssets: (assets: ProductBundleAssetResponse[]) => void;
  updateBundleAssets: (assets: ProductBundleAssetResponse[]) => void;
}

const BundleAssets: React.FC<BundleAssetsProps> = ({
  bundleId,
  setOriginalBundleAssets,
  updateBundleAssets,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.bundle.assets',
  });
  const api = usePetCloudApi();
  const productBundlesApi = api.productBundlesApi();
  const assetFoldersApi = api.assetFoldersApi();
  const errorHandler = useErrorHandler();

  const [galleryPopup, setGalleryPopup] = useState(false);
  const [assets, setAssets] = useState<ProductBundleAssetResponse[] | null>(
    null
  );
  const [selectedAssets, setSelectedAssets] = useState<
    ProductBundleAssetResponse[]
  >([]);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);

  useEffect(() => {
    getBundleAssets();
    getAssetFolders();
  }, []);

  useEffect(() => {
    if (assets) {
      updateBundleAssets(assets);
    }
  }, [assets]);

  const getBundleAssets = () => {
    productBundlesApi
      .productBundlesGetProductBundleAssets(bundleId)
      .then((response) => {
        console.log(response.data);
        const sorted = _.sortBy(response.data, ['position']);
        setAssets(sorted);
        setOriginalBundleAssets(sorted);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response.data);
        setAssetFolders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  // const submit = () => {
  //   if (assets) {
  //     setIsSubmitting(true);
  //     productBundlesApi
  //       .productBundlesUpsertProductBundleAssets(
  //         bundleId,
  //         assets.map((asset) => {
  //           return {
  //             position: asset.position,
  //             assetId: {
  //               'de-DE': asset.asset['de-DE']?.id,
  //               'en-GB': asset.asset['en-GB']?.id,
  //             },
  //             description: asset.description,
  //             alternative: asset.alternative,
  //           };
  //         })
  //       )
  //       .then((response) => {
  //         console.log(response.data);
  //         pushNotification(t('notifications.update_success'));
  //         setIsSubmitting(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         errorHandler.addError(error.response);
  //         setIsSubmitting(false);
  //       });
  //   }
  // };

  const selectAsset = (id: string) => {
    const asset = assets?.find((a) => a.id === id);
    if (asset) {
      const update = [...selectedAssets];
      const i = update.findIndex((a) => a.id === asset.id);
      if (i !== -1) {
        update.splice(i, 1);
      } else {
        update.push(asset);
      }
      setSelectedAssets(update);
    }
  };

  const deleteAsset = (id: string) => {
    if (assets) {
      const update = [...assets];
      const i = update.findIndex((a) => a.id === id);
      if (i !== -1) {
        update.splice(i, 1);
      }
      setAssets(update);
    }
  };

  const deleteSelectedAssets = (ids: string[]) => {
    if (assets) {
      const update = [...assets];
      ids.forEach((id) => {
        const index = update.findIndex((a) => a.id === id);
        update.splice(index, 1);
      });
      setAssets(update);
      setSelectedAssets([]);
    }
  };

  const addAssets = (responses: AssetResponse[]) => {
    if (assets) {
      const update = [...assets];
      responses.forEach((response) => {
        update.push({
          id: crypto.randomUUID(),
          createdAt: new Date().toUTCString(),
          position: assets.length,
          productBundleId: bundleId,
          asset: {
            'de-DE': response,
            'en-GB': response,
          },
          description: null,
          alternative: null,
        });
      });
      setAssets(update);
      if (galleryPopup) {
        setGalleryPopup(false);
      }
    }
  };

  if (assets) {
    return (
      <Has authorizations={['asset_folders:list']} showNoAuthorization>
        <div className={'bundleAssets'}>
          <MediaManager
            assets={assets}
            selectedAssets={selectedAssets}
            selectAllAssets={() => setSelectedAssets([...assets])}
            selectAsset={selectAsset}
            deleteAsset={deleteAsset}
            deleteSelected={deleteSelectedAssets}
            sortAssetsCallback={(assets) => setAssets(assets)}
            addAssetsCallback={addAssets}
          />
          <div className={'global-cardActions'}>
            <Button
              cta={t('actions.gallery')}
              look={'secondary'}
              width={'minimal'}
              action={() => setGalleryPopup(true)}
            />
          </div>
          <Popup
            toggled={galleryPopup}
            width={'50%'}
            close={() => setGalleryPopup(false)}
          >
            <div className={'popup-title'}>{t('galleryPopup.title')}</div>
            {assetFolders ? (
              <FileBrowser
                assetFolders={assetFolders}
                allowUrlNavigation={false}
                onChooseAssets={addAssets}
              />
            ) : (
              <LoadingContainer />
            )}
          </Popup>
        </div>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default BundleAssets;
