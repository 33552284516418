import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthorization, Has } from '../../contexts/auth/Authorization';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import Button from '../../elements/button/Button';
import { LoadingContainer } from '../../elements/loading/Loading';
import ProductList from '../../features/productlist/ProductList';
import { ProductListFilterMode } from '../../api/petcloudapi/api';

const Products = () => {
  const { t } = useTranslation();
  const link = useNavigate();
  const { authorizations } = useAuthorization();

  if (authorizations) {
    return (
      <Has
        authorizations={['product_groups:list', 'products:list']}
        showNoAuthorization
      >
        <Stage>
          <Navigator title={t('view.products.title')}>
            <Button
              cta={t('view.products.new')}
              margin="left"
              look="primary"
              action={() => link('/products/new')}
            ></Button>
          </Navigator>
          <Card bigScreenWidth="100%" noMargin>
            <CardSection>
              <ProductList
                allowActions
                productListFilterMode={ProductListFilterMode.ParentProductsOnly}
                adjustHeightToViewportOffset={120}
              />
            </CardSection>
          </Card>
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Products;

// <Button cta={t('actions.export')} margin="left" type="icon-text">
//   <Export
//     className="button-icon"
//     fill="var(--color-text_secondary)"
//   />
// </Button>
