import { Tab, Tabs } from '../../../elements/card/Card';
import Button from '../../../elements/button/Button';
import languageTabs from '../../../../locate/LanguageTabs';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import Input from '../../../elements/input/Input';
import {
  CreateProductBundleRequest,
  UpdateProductBundleRequest,
} from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import FullEditor from '../../../elements/fulleditor/FullEditor';

interface BundleHeadEditProps {
  manufacturerOptions?: DropdownOption[] | null;
  request: CreateProductBundleRequest | UpdateProductBundleRequest;
  updateRequest: (
    request: CreateProductBundleRequest | UpdateProductBundleRequest
  ) => void;
  submit?: () => void;
  isSubmitting?: boolean;
}

const BundleHeadEdit: React.FC<BundleHeadEditProps> = ({
  manufacturerOptions,
  request,
  updateRequest,
  submit,
  isSubmitting,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.bundle.general',
  });
  const langTabs = languageTabs;

  const renderTab = (lang: TranslatedStringIndex) => {
    return (
      <>
        {manufacturerOptions ? (
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Dropdown
                title={t('manufacturer')}
                optionObjects={manufacturerOptions}
                selected={
                  manufacturerOptions.find(
                    (o) => o.id === request.manufacturerId
                  )?.name
                }
                update={(e) => {
                  const id =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  if (id) {
                    updateRequest({
                      ...request,
                      manufacturerId: id,
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : null}
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('name')}
              content={request.name[lang]}
              update={(value) => {
                updateRequest({
                  ...request,
                  name: { ...request.name, [lang]: value },
                });
              }}
              required={true}
            />
          </div>
        </div>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <FullEditor
              title={t('description')}
              initialHTMLValue={request.description[lang]}
              onUpdate={(value) => {
                updateRequest({
                  ...request,
                  description: {
                    ...request.description,
                    [lang]: value,
                  },
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={'bundleHeadEdit'}>
      <div className={'newBundle-tabs'}>
        <Tabs tabs={langTabs} dontSetHash>
          <div className={'newBundle-tab'}>
            <Tab>{renderTab('de-DE')}</Tab>
          </div>
          <div className={'newBundle-tab'}>
            <Tab>{renderTab('en-GB')}</Tab>
          </div>
        </Tabs>
      </div>
      {submit ? (
        <div className={'global-cardActions newBundle-actions'}>
          <Button
            cta={t('cta')}
            look={'save'}
            width={'minimal'}
            action={submit}
            isLoading={isSubmitting}
          />
        </div>
      ) : null}
    </div>
  );
};

export default BundleHeadEdit;
