import { useTranslation } from 'react-i18next';
import { ManufacturerResponse } from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Card, { CardSection } from '../../../elements/card/Card';
import ProfileOverview from './profileoverview/ProfileOverview';
import ManufacturerOverview from './manufactureroverview/ManufacturerOverview';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { useUser } from '../../../contexts/auth/User';

interface ProfileProps {
  manufacturer: ManufacturerResponse | null | undefined;
}

const Profile: React.FC<ProfileProps> = ({ manufacturer }) => {
  const { user } = useUser();
  const { t } = useTranslation();

  if (user) {
    return (
      <div className="profile">
        <Card bigScreenWidth={'100%'}>
          <CardSection>
            <ProfileOverview user={user} />
          </CardSection>
        </Card>
        <Card bigScreenWidth={'100%'}>
          <CardSection
            title={t('view.account.profile.manufacturerOverview.title')}
          >
            {manufacturer ? (
              <ManufacturerOverview manufacturer={manufacturer} user={user} />
            ) : (
              <EmptyState
                message={t('view.account.profile.manufacturerOverview.empty')}
              />
            )}
          </CardSection>
        </Card>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Profile;
