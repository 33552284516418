import './bartooltip.css';
import { ReturningCustomersStatisticsResponse } from '../../../../../api/petcloudapi/api';
import Badge from '../../../../../elements/badge/Badge';
import { useTranslation } from 'react-i18next';

interface RecurringCustomersBarToolTipProps {
  data: ReturningCustomersStatisticsResponse;
  showGuestInfo?: boolean;
}

const RecurringCustomersBarToolTip: React.FC<
  RecurringCustomersBarToolTipProps
> = ({ data, showGuestInfo }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.widgets.recurringCustomers.tooltip',
  });

  return (
    <div className={'barTooltip'}>
      <div className={'barTooltip-meta'}>
        {showGuestInfo ? (
          <div
            className={'barTooltip-meta-element barTooltip-meta-element-badge'}
          >
            {data.guestAccount ? (
              <Badge title={t('guestAccount')} color={'var(--color-primary)'} />
            ) : (
              <Badge title={t('account')} color={'var(--color-blue)'} />
            )}
          </div>
        ) : null}
        <div className={'barTooltip-meta-element'}>
          <span>{data.numberOfCustomers}</span> {t('numberOfCustomers')}
        </div>
        <div className={'barTooltip-meta-element'}>
          <span>{data.totalRevenue}€</span> {t('totalRevenue')}
        </div>
        <div className={'barTooltip-meta-element'}>
          <span>{data.totalCustomersPercentage}%</span>{' '}
          {t('totalCustomersPercentage')}
        </div>
      </div>
    </div>
  );
};

export default RecurringCustomersBarToolTip;
