import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  InformationGroupResponse,
  ProductAdditionalInformationResponse,
  TranslatedTypeResponseOfString,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import Retractable from '../../../elements/retractable/Retractable';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import './additionalinformation.css';
import Bulletpoints from './bulletpoints/Bulletpoints';
import Downloads from './downloads/Downloads';
import RichText from './richtext/RichText';
import AdditionalInformationTable from './additionalinformationtable/AdditionalInformationTable';
import AdditionalInformationGallery from './additionalinformationgallery/AdditionalInformationGallery';
import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import { Store } from 'react-notifications-component';
import { EmptyState } from '../../../elements/emptystate/EmptyState';

interface AdditionalInformationProps {
  productId?: string;
  additionalInformation?: ProductAdditionalInformationResponse[] | null;
  inheritedAdditionalInformation?:
    | ProductAdditionalInformationResponse[]
    | null;
  getProduct?: () => void;
  readonly?: boolean;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  toggleProductSavingHelpOverlay?: () => void;
  onDelete?: (id: string) => void;
  onSave?: (request: any) => void;
  onAdd?: (request: any) => void;
  fullWidthButton?: boolean;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  productId,
  additionalInformation,
  inheritedAdditionalInformation,
  getProduct,
  readonly,
  queueCriticalAction,
  killCriticalAction,
  toggleProductSavingHelpOverlay,
  onDelete,
  onSave,
  onAdd,
  fullWidthButton,
}) => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const informationGroupsApi = api.informationGroupsApi();
  const productAdditionalInformationApi = api.productAdditionalInformationApi();
  const errorHandler = useErrorHandler();

  const [informationGroupOptions, setInformationGroupOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [informationGroups, setInformationGroups] = useState<
    InformationGroupResponse[] | null
  >(null);
  const [selectedInformationGroupId, setSelectedInformationGroupId] = useState<
    string | null
  >(null);
  const [newInformationPopup, setNewInformationPopup] = useState(false);

  useEffect(() => {
    getInformationGroups();
  }, []);

  const getInformationGroups = () => {
    informationGroupsApi
      .informationGroupsGetInformationGroups()
      .then((response) => {
        console.log(response);
        setInformationGroups(response.data);
        const dropdownOptions = response.data
          .filter((x) => x.informationContentType !== 'AnalyticConstituents')
          .map((group) => {
            return {
              id: group.id,
              name:
                group.name[i18n.language as TranslatedStringIndex] ??
                'Name for selected language missing',
            };
          });
        setInformationGroupOptions(dropdownOptions);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deleteAdditionalInformation = (id: string) => {
    if (queueCriticalAction) {
      queueCriticalAction('deletingAdditionalInformation' + id);
    }
    productAdditionalInformationApi
      .productAdditionalInformationDeleteProductAdditionalInformation(id)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t(
            'view.product.additionalinformation.notifications.update_successful'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        if (getProduct) {
          getProduct();
        }
        if (killCriticalAction) {
          killCriticalAction('deletingAdditionalInformation' + id);
        }
        if (toggleProductSavingHelpOverlay) {
          toggleProductSavingHelpOverlay();
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        if (killCriticalAction) {
          killCriticalAction('deletingAdditionalInformation' + id);
        }
      });
  };

  // this method is used to "fake" a real ProductAdditionalInformationResponse to feed it back into the UI without
  // needing to send it to the api - this functionality is required for the bulk editor / bulk inline product creation
  const initInformationResponse = (
    informationGroupId: string,
    serializedValue: TranslatedTypeResponseOfString
  ) => {
    return {
      id: crypto.randomUUID(),
      createdAt: '',
      contentType: contentTypes[informationGroupId],
      position: 0,
      informationGroupId: informationGroupId,
      informationGroup:
        informationGroups?.find((g) => g.id === informationGroupId) ?? 'error',
      value: serializedValue,
      productId: productId ?? '',
    };
  };

  // this is for adding new additional information
  const renderContentType = (id: string | null) => {
    if (id) {
      const type = contentTypes[id];
      switch (type) {
        case 'Table':
          return (
            <AdditionalInformationTable
              key={id}
              body={{
                id: null,
                informationGroupId: id,
                position: 0,
                header: {
                  'de-DE': [''],
                  'en-GB': [''],
                },
                rows: {
                  'de-DE': [[''], ['']],
                  'en-GB': [[''], ['']],
                },
              }}
              productId={productId}
              getProduct={getProduct}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              closePopup={() => setNewInformationPopup(false)}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onAdd={
                onAdd
                  ? (r) => {
                      onAdd(
                        initInformationResponse(
                          id,
                          serializeTable(r.header, r.rows)
                        )
                      );
                      setNewInformationPopup(false);
                    }
                  : undefined
              }
            />
          );
        case 'BulletPoints':
          return (
            <Bulletpoints
              key={id}
              body={{
                id: null,
                informationGroupId: id,
                position: 0,
                items: {
                  'de-DE': [''],
                  'en-GB': [''],
                },
              }}
              productId={productId}
              getProduct={getProduct}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              closePopup={() => setNewInformationPopup(false)}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onAdd={
                onAdd
                  ? (r) => {
                      onAdd(
                        initInformationResponse(
                          id,
                          serializeBulletpointsItems(r.items)
                        )
                      );
                      setNewInformationPopup(false);
                    }
                  : undefined
              }
            />
          );
        case 'RichText':
          return (
            <RichText
              key={id}
              body={{
                id: null,
                informationGroupId: id,
                position: 0,
                value: {
                  'de-DE': '',
                  'en-GB': '',
                },
              }}
              productId={productId}
              getProduct={getProduct}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              closePopup={() => setNewInformationPopup(false)}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onAdd={
                onAdd
                  ? (r) => {
                      onAdd(
                        initInformationResponse(
                          id,
                          serializeRichTextContent(r.value)
                        )
                      );
                      setNewInformationPopup(false);
                    }
                  : undefined
              }
            />
          );
        case 'Downloads':
          return (
            <Downloads
              key={id}
              body={{
                id: null,
                informationGroupId: id,
                position: 0,
                assetIds: {
                  'de-DE': [],
                  'en-GB': [],
                },
              }}
              productId={productId}
              getProduct={getProduct}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              closePopup={() => setNewInformationPopup(false)}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onAdd={
                onAdd
                  ? (r) => {
                      onAdd(
                        initInformationResponse(id, serializeAssets(r.assetIds))
                      );
                      setNewInformationPopup(false);
                    }
                  : undefined
              }
            />
          );
        case 'MediaGallery':
          return (
            <AdditionalInformationGallery
              key={id}
              body={{
                id: null,
                informationGroupId: id,
                position: 0,
                assetIds: {
                  'de-DE': [],
                  'en-GB': [],
                },
              }}
              productId={productId}
              getProduct={getProduct}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              closePopup={() => setNewInformationPopup(false)}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onAdd={
                onAdd
                  ? (r) => {
                      onAdd(
                        initInformationResponse(id, serializeAssets(r.assetIds))
                      );
                      setNewInformationPopup(false);
                    }
                  : undefined
              }
            />
          );
        default:
          return null;
      }
    }
  };

  if (informationGroupOptions) {
    return (
      <div className="additionalinformation">
        {!additionalInformation || additionalInformation.length === 0 ? (
          inheritedAdditionalInformation &&
          inheritedAdditionalInformation.length > 0 ? (
            <div className="additionalinformation-elements-inherited">
              {inheritedAdditionalInformation.map((info) => {
                return (
                  <AdditionalInformationElement
                    key={info.id}
                    productId={productId}
                    informationGroups={informationGroupOptions}
                    info={info}
                    deleteAdditionalInformation={
                      onDelete ?? deleteAdditionalInformation
                    }
                    queueCriticalAction={queueCriticalAction}
                    killCriticalAction={killCriticalAction}
                    getProduct={getProduct}
                    readonly={readonly}
                    toggleProductSavingHelpOverlay={
                      toggleProductSavingHelpOverlay
                    }
                    onSave={onSave}
                  />
                );
              })}
            </div>
          ) : (
            <EmptyState small />
          )
        ) : (
          <div className="additionalinformation-elements">
            {additionalInformation.map((info) => {
              return (
                <AdditionalInformationElement
                  key={info.id}
                  productId={productId}
                  informationGroups={informationGroupOptions}
                  info={info}
                  deleteAdditionalInformation={
                    onDelete ?? deleteAdditionalInformation
                  }
                  queueCriticalAction={queueCriticalAction}
                  killCriticalAction={killCriticalAction}
                  getProduct={getProduct}
                  readonly={readonly}
                  toggleProductSavingHelpOverlay={
                    toggleProductSavingHelpOverlay
                  }
                  onSave={onSave}
                />
              );
            })}
          </div>
        )}
        {!readonly ? (
          <div className="additionalinformation-actions">
            <Button
              cta={t('view.product.additionalinformation.new')}
              action={() => setNewInformationPopup(true)}
              look={'secondary'}
              width={fullWidthButton ? 'full' : 'minimal'}
            />
          </div>
        ) : null}
        <Popup
          width="50%"
          toggled={newInformationPopup}
          close={() => setNewInformationPopup(false)}
        >
          <div className="popup-title">
            {t('view.product.additionalinformation.newPopup.title')}
          </div>
          <Dropdown
            title={t('view.product.additionalinformation.newPopup.type')}
            optionObjects={informationGroupOptions}
            update={(e) => {
              const selectedId =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (selectedId) {
                setSelectedInformationGroupId(selectedId);
              }
            }}
            selected={
              informationGroupOptions.find(
                (g) => g.id === selectedInformationGroupId
              )?.name
            }
          />
          {renderContentType(selectedInformationGroupId)}
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export const contentTypes: { [key: string]: string } = {
  '00a3ed64-b136-441a-bc31-fc265cdb697d': 'Table',
  '06619c0e-e61b-429d-90fa-6eb4a7a5abba': 'RichText',
  '067b7c75-8d4b-44ad-8269-fa841808a63d': 'RichText',
  '08fef6ba-2f33-4454-8865-916267b00592': 'Downloads',
  '2f5cf762-5078-4bbd-8bd6-b66bc5177830': 'RichText',
  '34d0fd1d-079a-4772-8966-0ddf31acbd59': 'BulletPoints',
  '4db8adb2-67c0-4e58-aaf7-98c5e78c5337': 'RichText',
  '624588d8-fc89-4172-81ca-37c6ab839b7e': 'RichText',
  '7c205b73-3da6-45de-a5fb-60bb85f390d2': 'RichText',
  '93823bfc-a523-486d-aa28-203ca230b443': 'RichText',
  'b40452c3-2f5e-4e27-857f-3eae74d78456': 'RichText',
  'cbf43978-28ca-4e32-98ce-1702180ab0d6': 'Table',
  'd4d1392c-f732-47b8-bcd4-c17d1fbc26bb': 'RichText',
  'd895ad7b-9de4-4b69-b2ad-ac1b9bee509d': 'MediaGallery',
  '8b585361-7c8a-4f19-9629-fda12d1c95ae': 'RichText',
};

export const informationGroupNames: {
  [key: string]: TranslatedTypeResponseOfString;
} = {
  '00a3ed64-b136-441a-bc31-fc265cdb697d': {
    'de-DE': 'Größentabelle',
    'en-GB': 'Size chart',
  },
  '06619c0e-e61b-429d-90fa-6eb4a7a5abba': {
    'de-DE': 'Weitere Produktmerkmale',
    'en-GB': 'Further product characteristics',
  },
  '067b7c75-8d4b-44ad-8269-fa841808a63d': {
    'de-DE': 'Lagerung',
    'en-GB': 'Storage',
  },
  '08fef6ba-2f33-4454-8865-916267b00592': {
    'de-DE': 'Downloads',
    'en-GB': 'Downloads',
  },
  '2f5cf762-5078-4bbd-8bd6-b66bc5177830': {
    'de-DE': 'Material',
    'en-GB': 'Material',
  },
  '34d0fd1d-079a-4772-8966-0ddf31acbd59': {
    'de-DE': 'Highlights',
    'en-GB': 'Highlights',
  },
  '4db8adb2-67c0-4e58-aaf7-98c5e78c5337': {
    'de-DE': 'Messanleitung',
    'en-GB': 'Measurement instructions',
  },
  '624588d8-fc89-4172-81ca-37c6ab839b7e': {
    'de-DE': 'Hinweise',
    'en-GB': 'Hints',
  },
  '7c205b73-3da6-45de-a5fb-60bb85f390d2': {
    'de-DE': 'Tipps / Anwendung',
    'en-GB': 'Tips / Application',
  },
  '93823bfc-a523-486d-aa28-203ca230b443': {
    'de-DE': 'Zusammensetzung',
    'en-GB': 'Composition',
  },
  'b40452c3-2f5e-4e27-857f-3eae74d78456': {
    'de-DE': 'Zusatzstoffe',
    'en-GB': 'Additives',
  },
  'cbf43978-28ca-4e32-98ce-1702180ab0d6': {
    'de-DE': 'Fütterungsempfehlung',
    'en-GB': 'Feeding recommendation',
  },
  'd4d1392c-f732-47b8-bcd4-c17d1fbc26bb': {
    'de-DE': 'Maße',
    'en-GB': 'Dimensions',
  },
  'd895ad7b-9de4-4b69-b2ad-ac1b9bee509d': {
    'de-DE': 'Weitere Produktmedien',
    'en-GB': 'Further product media',
  },
};

interface AdditionalInformationElementProps {
  productId?: string;
  informationGroups: DropdownOption[];
  info: ProductAdditionalInformationResponse;
  deleteAdditionalInformation: (id: string) => void;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  getProduct?: () => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  onSave?: (request: any) => void;
}

export const AdditionalInformationElement: React.FC<
  AdditionalInformationElementProps
> = ({
  productId,
  informationGroups,
  info,
  deleteAdditionalInformation,
  queueCriticalAction,
  killCriticalAction,
  getProduct,
  readonly,
  toggleProductSavingHelpOverlay,
  onSave,
}) => {
  const renderHead = (
    infoGroups: DropdownOption[],
    info: ProductAdditionalInformationResponse
  ) => {
    const title = infoGroups.find(
      (group) => group.id === info.informationGroupId
    )?.name;

    return (
      <div className="additionalinformation-element-head">
        <div className="additionalinformation-element-head-title">{title}</div>
        {!readonly ? (
          <div className="additionalinformation-element-action">
            <Button
              look={'secondary'}
              type="icon"
              action={() => deleteAdditionalInformation(info.id)}
            >
              <Trash className="button-icon button-icon-danger" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="additionalinformation-element">
      <Retractable head={renderHead(informationGroups, info)}>
        <div className="retractable-content-padded ">
          {info.contentType === 'BulletPoints' ? (
            <Bulletpoints
              productId={productId}
              body={{
                id: info.id,
                informationGroupId: info.informationGroupId,
                position: info.position,
                items: deserializeBulletpointsItems(info.value),
              }}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              getProduct={getProduct}
              readonly={readonly}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onSave={
                onSave
                  ? (r) =>
                      saveAsResponse(
                        info,
                        onSave,
                        serializeBulletpointsItems(r.items)
                      )
                  : undefined
              }
            />
          ) : info.contentType === 'RichText' ? (
            <RichText
              productId={productId}
              body={{
                id: info.id,
                informationGroupId: info.informationGroupId,
                position: info.position,
                value: deserializeRichTextContent(info.value),
              }}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              getProduct={getProduct}
              readonly={readonly}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onSave={
                onSave
                  ? (r) =>
                      saveAsResponse(
                        info,
                        onSave,
                        serializeRichTextContent(r.value)
                      )
                  : undefined
              }
            />
          ) : info.contentType === 'Downloads' ? (
            <Downloads
              productId={productId}
              body={{
                id: info.id,
                informationGroupId: info.informationGroupId,
                position: info.position,
                assetIds: deserializeAssets(info.value),
              }}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              getProduct={getProduct}
              readonly={readonly}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onSave={
                onSave
                  ? (r) =>
                      saveAsResponse(info, onSave, serializeAssets(r.assetIds))
                  : undefined
              }
            />
          ) : info.contentType === 'Table' ? (
            <AdditionalInformationTable
              productId={productId}
              body={{
                id: info.id,
                informationGroupId: info.informationGroupId,
                position: info.position,
                header: deserializeTable(info.value, 'header'),
                rows: deserializeTable(info.value, 'rows'),
              }}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              getProduct={getProduct}
              readonly={readonly}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onSave={
                onSave
                  ? (r) =>
                      saveAsResponse(
                        info,
                        onSave,
                        serializeTable(r.header, r.rows)
                      )
                  : undefined
              }
            />
          ) : info.contentType === 'MediaGallery' ? (
            <AdditionalInformationGallery
              productId={productId}
              body={{
                id: info.id,
                informationGroupId: info.informationGroupId,
                position: info.position,
                assetIds: deserializeAssets(info.value),
              }}
              queueCriticalAction={queueCriticalAction}
              killCriticalAction={killCriticalAction}
              getProduct={getProduct}
              readonly={readonly}
              toggleProductSavingHelpOverlay={toggleProductSavingHelpOverlay}
              onSave={
                onSave
                  ? (r) =>
                      saveAsResponse(info, onSave, serializeAssets(r.assetIds))
                  : undefined
              }
            />
          ) : null}
        </div>
      </Retractable>
    </div>
  );
};

export default AdditionalInformation;

const saveAsResponse = (
  info: ProductAdditionalInformationResponse,
  callback: (response: ProductAdditionalInformationResponse) => void,
  serializedValue: TranslatedTypeResponseOfString
) => {
  callback({ ...info, value: serializedValue });
};

export const deserializeBulletpointsItems = (
  value: TranslatedTypeResponseOfString
) => {
  const deserializedDE = JSON.parse(value['de-DE'] ?? '""');
  const deserializedEN = JSON.parse(value['en-GB'] ?? '""');

  return {
    'de-DE': deserializedDE.items,
    'en-GB': deserializedEN.items,
  };
};

export const serializeBulletpointsItems = (value: any) => {
  const serializedDE = JSON.stringify({ items: value['de-DE'] ?? '' });
  const serializedEN = JSON.stringify({ items: value['en-GB'] ?? '' });

  return {
    'de-DE': serializedDE,
    'en-GB': serializedEN,
  };
};

export const deserializeRichTextContent = (
  value: TranslatedTypeResponseOfString
) => {
  const deserializedDE = JSON.parse(value['de-DE'] ?? '""');
  const deserializedEN = JSON.parse(value['en-GB'] ?? '""');

  return {
    'de-DE': deserializedDE.content,
    'en-GB': deserializedEN.content,
  };
};

export const serializeRichTextContent = (value: any) => {
  const serializedDE = JSON.stringify({ content: value['de-DE'] ?? '' });
  const serializedEN = JSON.stringify({ content: value['en-GB'] ?? '' });

  return {
    'de-DE': serializedDE,
    'en-GB': serializedEN,
  };
};

export const deserializeAssets = (value: TranslatedTypeResponseOfString) => {
  const deserializedDE = JSON.parse(value['de-DE'] ?? '""');
  const deserializedEN = JSON.parse(value['en-GB'] ?? '""');

  return {
    'de-DE': deserializedDE.assetIds,
    'en-GB': deserializedEN.assetIds,
  };
};

export const serializeAssets = (value: any) => {
  const serializedDE = JSON.stringify({ assetIds: value['de-DE'] ?? '' });
  const serializedEN = JSON.stringify({ assetIds: value['en-GB'] ?? '' });

  return {
    'de-DE': serializedDE,
    'en-GB': serializedEN,
  };
};

export const deserializeTable = (
  value: TranslatedTypeResponseOfString,
  scenario: 'header' | 'rows'
) => {
  const deserializedDE = JSON.parse(value['de-DE'] ?? '""');
  const deserializedEN = JSON.parse(value['en-GB'] ?? '""');

  if (scenario === 'header') {
    return {
      'de-DE': deserializedDE.header,
      'en-GB': deserializedEN.header,
    };
  } else {
    return {
      'de-DE': deserializedDE.rows,
      'en-GB': deserializedEN.rows,
    };
  }
};

export const serializeTable = (header: any, rows: any) => {
  const serializedDE = JSON.stringify({
    header: header['de-DE'],
    rows: rows['de-DE'],
  });
  const serializedEN = JSON.stringify({
    header: header['en-GB'],
    rows: rows['en-GB'],
  });

  return {
    'de-DE': serializedDE,
    'en-GB': serializedEN,
  };
};
