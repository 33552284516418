import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  FiegePositionUnit,
  FiegePurchaseOrderPositionResponse,
  FiegePurchaseOrderResponse,
  ProductListFilterMode,
  PurchaseOrderState as PurchaseOrderStateType,
  SimpleProductResponse,
  UpdateFiegePurchaseOrderRequest,
  WarehouseType,
} from '../../api/petcloudapi/api';
import Badge from '../../elements/badge/Badge';
import Button from '../../elements/button/Button';
import Card, { CardSection } from '../../elements/card/Card';
import { ReactComponent as Import } from '../../../assets/icon/import.svg';
import ClusterList from '../../elements/clusterlist/ClusterList';
import Input from '../../elements/input/Input';
import List from '../../features/list/List';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import {
  Stage,
  Navigator,
  StageColumns,
  StageColumn,
} from '../../layout/stage/Stage';
import PurchaseOrderBrief from '../../sections/purchaseorder/purchaseorderbrief/PurchaseOrderBrief';
import PurchaseOrderState from '../../sections/purchaseorder/purchaseorderstate/PurchaseOrderState';
import PurchaseOrderPosition from '../../types/PurchaseOrderPosition';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { ReactComponent as Warning } from '../../../assets/icon/warning.svg';
import { ReactComponent as Success } from '../../../assets/icon/success.svg';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import Popup from '../../elements/popup/Popup';
import ProductList from '../../features/productlist/ProductList';
import PurchaseOrderPositionImport from '../../sections/purchaseorder/purchaseorderPositionImport/PurchaseOrderPositionImport';
import translatedStringIndex from '../../types/TranslatedStringIndex';
import { ReactComponent as Inbound } from '../../../assets/icon/inbound.svg';
import { ReactComponent as Outbound } from '../../../assets/icon/outbound.svg';
import { TFunction } from 'i18next';

const PurchaseOrder = () => {
  const { t, i18n } = useTranslation();
  const { authorizations } = useAuthorization();
  const params = useParams();
  const purchaseOrderId = params.purchaseOrderId;
  const api = usePetCloudApi();
  const fiegePurchaseOrdersApi = api.fiegePurchaseOrdersApi();
  const errorHandler = useErrorHandler();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originalPurchaseOrder, setOriginalPurchaseOrder] =
    useState<FiegePurchaseOrderResponse | null>(null);
  const [purchaseOrder, setPurchaseOrder] =
    useState<FiegePurchaseOrderResponse | null>(null);
  const [positions, setPositions] = useState<
    FiegePurchaseOrderPositionResponse[] | null
  >(null);
  const [productListPopup, setProductListPopup] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<
    SimpleProductResponse[] | null
  >(null);
  const [listPositions, setListPositions] = useState<
    PurchaseOrderPosition[] | null
  >(null);
  const [importPopup, setImportPopup] = useState(false);

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const getPurchaseOrder = () => {
    if (purchaseOrderId) {
      fiegePurchaseOrdersApi
        .fiegePurchaseOrdersGetFiegePurchaseOrderById(purchaseOrderId)
        .then((response) => {
          console.log(response);
          const purchaseOrderSortedPositions = response.data;
          purchaseOrderSortedPositions.positions = _.orderBy(
            purchaseOrderSortedPositions.positions,
            ['lineNumber', 'isReported'],
            ['asc', 'asc']
          );
          setPurchaseOrder(purchaseOrderSortedPositions);
          setOriginalPurchaseOrder(
            JSON.parse(JSON.stringify(purchaseOrderSortedPositions))
          );
          setPositions(purchaseOrderSortedPositions.positions);
          setListPositions(
            response.data.positions.map((position) => {
              return {
                productCoverUri:
                  position.product?.cover?.asset[
                    i18n.language as translatedStringIndex
                  ]?.uri ?? undefined,
                productName: position.product?.name ?? {},
                ean: position.product?.ean,
                productNumber: position.product?.productNumber,
                manufacturerProductNumber:
                  position.product?.manufacturerProductNumber,
                isReported: position.isReported,
                fiegeInboundDate: position.fiegeInboundDate,
                fiegeInboundId: position.fiegeInboundId,
                bestBefore: position.bestBefore,
                batchNumber: position.batchNumber,
                productId: position.productId,
                unit: 'Piece' as FiegePositionUnit,
                lineNumber: position.lineNumber,
                quality: position.quality,
                quantity: position.quantity,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  useEffect(() => {
    console.log(originalPurchaseOrder);
    console.log(purchaseOrder);
    console.log(
      JSON.stringify(originalPurchaseOrder) !== JSON.stringify(purchaseOrder)
    );
  }, [purchaseOrder]);

  useEffect(() => {
    getListPositions();
  }, [positions]);

  const getListPositions = () => {
    const listPositions: PurchaseOrderPosition[] = [];
    if (positions && availableProducts) {
      console.log(positions);
      positions.forEach((position) => {
        const product = availableProducts.find(
          (product) => product.id === position.productId
        );
        if (product) {
          listPositions.push({
            productCoverUri:
              product.cover?.asset[i18n.language as TranslatedStringIndex]
                ?.uri ?? undefined,
            productName: product.name,
            ean: product.ean,
            productNumber: product.productNumber,
            manufacturerProductNumber: product.manufacturerProductNumber,
            isReported: position.isReported,
            fiegeInboundDate: position.fiegeInboundDate,
            fiegeInboundId: position.fiegeInboundId,
            bestBefore: position.bestBefore,
            batchNumber: position.batchNumber,
            productId: position.productId,
            unit: 'Piece' as FiegePositionUnit,
            lineNumber: position.lineNumber,
            quality: position.quality,
            quantity: position.quantity,
          });
        }
      });
      setListPositions(listPositions);
    }
  };

  const getFiegePurchaseOrderPosition = (
    product: SimpleProductResponse,
    quantity?: number
  ) => {
    return {
      id: 'new',
      lineNumber: 0,
      createdAt: '',
      productId: product.id,
      quantity: quantity ?? 0,
      unit: 'Piece' as FiegePositionUnit,
    };
  };

  const addPosition = (product: SimpleProductResponse, quantity?: number) => {
    const update = purchaseOrder ? purchaseOrder.positions : null;
    if (purchaseOrder && update) {
      update.push(getFiegePurchaseOrderPosition(product, quantity));
      console.log(update);
      setPurchaseOrder({
        ...purchaseOrder,
        positions: [...update],
      });
      setPositions([...update]);
    }
  };

  const addAllPositions = (products: SimpleProductResponse[]) => {
    const update = purchaseOrder?.positions;
    if (update) {
      products.forEach((product) => {
        update.push(getFiegePurchaseOrderPosition(product));
      });
      setPurchaseOrder({
        ...purchaseOrder,
        positions: [...update],
      });
      setPositions([...update]);
    }
  };

  const deletePosition = (product: SimpleProductResponse) => {
    if (positions) {
      const index = positions.findIndex((p) => p.productId === product.id);
      if (index >= 0) {
        const update = purchaseOrder?.positions;
        if (update && update[index].lineNumber === 0) {
          update.splice(index, 1);
          setPurchaseOrder({
            ...purchaseOrder,
            positions: [...update],
          });
          setPositions([...update]);
        }
      }
    }
  };

  const submitUpdatedPurchaseOrder = () => {
    if (purchaseOrderId && purchaseOrder) {
      setIsSubmitting(true);
      const request: UpdateFiegePurchaseOrderRequest = {
        deliveryDate: purchaseOrder.deliveryDate,
        state: purchaseOrder.state as PurchaseOrderStateType,
        positions: purchaseOrder.positions.map((position, i) => {
          return {
            lineNumber: position.lineNumber !== 0 ? position.lineNumber : i + 1,
            quantity: position.quantity,
            productId: position.productId,
            unit: 'Piece' as FiegePositionUnit,
          };
        }),
      };

      fiegePurchaseOrdersApi
        .fiegePurchaseOrdersUpdateFiegePurchaseOrder(purchaseOrderId, request)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.purchaseorder.notifications.update_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const renderQuantityInput = (value: any, item: any, index: number) => {
    return (
      <Input
        content={value}
        update={(e) => {
          const update = purchaseOrder?.positions;
          if (update) {
            console.log(update);
            console.log(index);
            update[index] = {
              ...update[index],
              quantity: parseInt(e),
            };
            setPurchaseOrder({
              ...purchaseOrder,
              positions: [...update],
            });
          }
        }}
      />
    );
  };

  const renderClusterHead = (cluster: FiegePurchaseOrderPositionResponse[]) => {
    let sent = 0;
    sent = cluster.reduce(
      (pre, cur) => pre + (!cur.isReported ? cur.quantity : 0),
      sent
    );

    let received = 0;
    received = cluster.reduce(
      (pre, cur) => pre + (cur.isReported ? cur.quantity : 0),
      received
    );

    let faults = false;
    cluster.forEach((position) => {
      if (position.quality !== null) {
        faults = true;
        return;
      }
    });

    if (cluster.length > 0) {
      return (
        <div className={'clusterlist-cluster-head'}>
          <div className="clusterlist-cluster-head-title clusterlist-cluster-head-hoverText">
            {cluster[0].product?.name[i18n.language as TranslatedStringIndex]}
            <div className={'clusterlist-cluster-head-hoverText-content'}>
              {cluster[0].product?.name[i18n.language as TranslatedStringIndex]}
            </div>
          </div>
          <div className="clusterlist-cluster-head-element-secondary global-monospaced-contrast">
            {cluster[0].product?.ean}
          </div>
          <div className="clusterlist-cluster-head-element clusterlist-cluster-head-hoverText clusterlist-cluster-head-hoverText-anchor">
            <div className={'clusterlist-cluster-head-element-group'}>
              {sent}
              <Outbound
                className={'clusterlist-cluster-head-element-iconSmall'}
                fill={'var(--color-blue)'}
              />
            </div>
            <div className={'clusterlist-cluster-head-element-spacer'}>/</div>
            <div className={'clusterlist-cluster-head-element-group'}>
              {received}
              <Inbound
                className={'clusterlist-cluster-head-element-iconSmall'}
                fill={'var(--color-success)'}
              />
            </div>
            <div className={'clusterlist-cluster-head-hoverText-content'}>
              {sent +
                ' ' +
                t('view.purchaseorder.positions.sent') +
                ' / ' +
                received +
                ' ' +
                t('view.purchaseorder.positions.received')}
            </div>
          </div>
          {faults ? (
            <div className="clusterlist-cluster-head-element">
              <Warning
                fill="var(--color-danger)"
                className="clusterlist-cluster-head-element-icon"
                style={{ marginLeft: 6 }}
              />
            </div>
          ) : received !== sent ? (
            <div className="clusterlist-cluster-head-element">
              <Warning
                fill="var(--color-yellow)"
                className="clusterlist-cluster-head-element-icon"
                style={{ marginLeft: 6 }}
              />
            </div>
          ) : (
            <div className="clusterlist-cluster-head-element">
              <Success
                fill="var(--color-success)"
                className="clusterlist-cluster-head-element-icon"
                style={{ marginLeft: 6 }}
              />
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const render = () => {
    if (authorizations && purchaseOrder && positions) {
      return (
        <Stage>
          <Navigator
            title={
              t('view.purchaseorder.title') + ' # ' + purchaseOrder.orderNumber
            }
            allowBackButton
            backLink={'/purchaseorders'}
          >
            {purchaseOrder.state === 'Open' ? (
              <Button
                look="save"
                cta={t('actions.save')}
                width="minimal"
                action={submitUpdatedPurchaseOrder}
                isLoading={isSubmitting}
                hasPermission={authorizations?.includes(
                  'fiege_purchase_orders:edit'
                )}
                active={
                  JSON.stringify(originalPurchaseOrder) !==
                  JSON.stringify(purchaseOrder)
                }
              />
            ) : null}
          </Navigator>
          <StageColumns>
            <StageColumn size="two">
              <Card bigScreenWidth="full">
                <CardSection title={t('view.purchaseorder.information.title')}>
                  <PurchaseOrderBrief
                    purchaseOrder={purchaseOrder}
                    setPurchaseOrder={setPurchaseOrder}
                  />
                </CardSection>
              </Card>
              <Card bigScreenWidth="full">
                <CardSection title={t('view.purchaseorder.positions.title')}>
                  {purchaseOrder.state === 'Open' ? (
                    <>
                      {positions.length > 0 ? (
                        listPositions && listPositions.length > 0 ? (
                          <List
                            items={listPositions}
                            translatedStrings={['productName']}
                            ignore={[
                              'unit',
                              'lineNumber',
                              'isReported',
                              'quality',
                              'fiegeInboundDate',
                              'fiegeInboundId',
                              'bestBefore',
                              'batchNumber',
                              'productId',
                            ]}
                            monoSpaceStrings={[
                              'ean',
                              'productNumber',
                              'manufacturerProductNumber',
                              'productId',
                            ]}
                            itemImgKey={'productCoverUri'}
                            renderObjects={[
                              {
                                key: 'quantity',
                                renderMethod: renderQuantityInput,
                                stopPropagation: true,
                                receiveNullValues: true,
                              },
                            ]}
                            linkedKey={'productId'}
                            linkPrefix={() => '/products/'}
                            linkPostfix={'?readonly=true'}
                          />
                        ) : (
                          <LoadingContainer />
                        )
                      ) : (
                        <EmptyState />
                      )}
                      <div
                        className={
                          'global-cardActions-postBorder global-cardActions-spaceBetween'
                        }
                      >
                        <Button
                          cta={t('view.purchaseorder.positions.import')}
                          type="icon-text"
                          look={'secondary'}
                          action={() => setImportPopup(true)}
                          active={!!availableProducts}
                        >
                          <Import
                            className="button-icon"
                            fill="var(--color-text_secondary)"
                          />
                        </Button>
                        <Button
                          cta={t('view.purchaseorder.positions.changeProducts')}
                          look={'secondary'}
                          width={'minimal'}
                          action={() => setProductListPopup(true)}
                        />
                      </div>
                      <Popup
                        toggled={productListPopup}
                        width={'60%'}
                        close={() => setProductListPopup(false)}
                      >
                        <ProductList
                          selectedIds={positions.map((p) => p.productId)}
                          unselectableIds={positions
                            .filter((p) => p.lineNumber !== 0)
                            .map((p) => p.productId)}
                          selectProductCallback={(product, action) => {
                            if (action === 'select') {
                              addPosition(product);
                            } else {
                              deletePosition(product);
                            }
                          }}
                          selectAllProductsCallback={(products) => {
                            if (products.length > 0) {
                              addAllPositions(products);
                            } else {
                              setPurchaseOrder({
                                ...purchaseOrder,
                                positions: [],
                              });
                              setPositions([]);
                            }
                          }}
                          finishedLoadingCallback={(products) => {
                            setAvailableProducts(products);
                          }}
                          productListFilterMode={
                            ProductListFilterMode.ExcludeNonBuyable
                          }
                          warehouseType={WarehouseType.CentralWarehouse}
                          allowActions={false}
                        />
                      </Popup>
                      <Popup
                        toggled={importPopup}
                        width={'30%'}
                        close={() => setImportPopup(false)}
                      >
                        <div className={'popup-title'}>
                          {t('view.purchaseorder.positionImport.title')}
                        </div>
                        <PurchaseOrderPositionImport
                          callback={(pos) => {
                            const results = pos.map((p) => {
                              return {
                                id: 'new',
                                lineNumber: 0,
                                createdAt: '',
                                productId: p.productId,
                                quantity: p.quantity,
                                unit: 'Piece' as FiegePositionUnit,
                              };
                            });
                            setPurchaseOrder({
                              ...purchaseOrder,
                              positions: [...results],
                            });
                            setPositions([...results]);
                          }}
                          successCallback={() => setImportPopup(false)}
                          products={availableProducts}
                        />
                      </Popup>
                    </>
                  ) : positions.length > 0 ? (
                    listPositions ? (
                      listPositions.length > 0 ? (
                        <ClusterList
                          data={positions}
                          clusterDataBy={'productId'}
                          renderClusterHead={renderClusterHead}
                          translatedStrings={['productName']}
                          ignore={[
                            'id',
                            'unit',
                            'lineNumber',
                            'productId',
                            'fiegeInboundId',
                            'product',
                            'updatedAt',
                          ]}
                          dateStrings={['createdAt']}
                          itemImgKey={'productCoverUri'}
                          renderObjects={[
                            {
                              key: 'quality',
                              renderMethod: (q) => renderQuality(q, t),
                            },
                          ]}
                          listItemsFilterFunc={(item) => {
                            return !!item.isReported;
                          }}
                          emptyStateMessage={t(
                            'view.purchaseorder.cluster.empty'
                          )}
                        />
                      ) : (
                        <EmptyState />
                      )
                    ) : (
                      <LoadingContainer />
                    )
                  ) : (
                    <EmptyState />
                  )}
                </CardSection>
              </Card>
            </StageColumn>
            <StageColumn size="one">
              <Card bigScreenWidth="full">
                <CardSection>
                  <PurchaseOrderState
                    state={purchaseOrder.state}
                    setClosed={() => {
                      setPurchaseOrder({
                        ...purchaseOrder,
                        state: 'Closed' as PurchaseOrderStateType,
                      });
                    }}
                  />
                </CardSection>
              </Card>
            </StageColumn>
          </StageColumns>
        </Stage>
      );
    } else {
      return <LoadingContainer />;
    }
  };

  return (
    <Has
      authorizations={['fiege_purchase_orders:detail', 'products:list']}
      showNoAuthorization
    >
      {render()}
    </Has>
  );
};

export default PurchaseOrder;

export const renderQuality = (quality: string | null, t: TFunction) => {
  return (
    <Badge
      title={
        quality === 'Defective'
          ? t('view.purchaseorder.positions.quality_Defective')
          : quality === 'Dirty'
          ? t('view.purchaseorder.positions.quality_Dirty')
          : t('view.purchaseorder.positions.quality_OK')
      }
      color={
        quality === 'Defective'
          ? 'var(--color-danger)'
          : quality === 'Dirty'
          ? 'var(--color-yellow)'
          : 'var(--color-save)'
      }
    />
  );
};
