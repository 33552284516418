import './integrationcard.css';
import { IntegrationResponse } from '../../../api/petcloudapi/api';
import { ReactComponent as ShopifyIcon } from '../../../../assets/icon/integrations/shopify.svg';
import Card, { CardSection } from '../../../elements/card/Card';
import Button from '../../../elements/button/Button';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconSettings } from '../../../../assets/icon/settings.svg';
import useIntegrationBadges from './useIntegrationBadges';

interface IntegrationCardProps {
  integration: IntegrationResponse;
  manufacturerId?: string | null;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  integration,
  manufacturerId,
}) => {
  const link = useNavigate();
  const { getBadges } = useIntegrationBadges(integration);

  return (
    <Card bigScreenWidth="33.33%" noMargin>
      <CardSection title={integration.name} badges={getBadges()}>
        <div className={'integrationCard'}>
          {integrationIcon[integration.identifier]}
          <Button
            type={'icon'}
            width={'minimal'}
            look={'secondary'}
            helperCSSClass={'integrationCard-button'}
            action={() =>
              link(
                `/integration/${integration.identifier.toLowerCase()}/${
                  integration.id
                }${manufacturerId ? '/' + manufacturerId : ''}`
              )
            }
            active={!!integration.config}
          >
            <IconSettings className={'button-icon button-icon-tertiary'} />
          </Button>
        </div>
      </CardSection>
    </Card>
  );
};

export default IntegrationCard;

const integrationIcon: { [key: string]: JSX.Element } = {
  Shopify: <ShopifyIcon className={'integrationCard-icon'} />,
};
