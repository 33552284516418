import './additionalinformationcell.css';
import { ProductAdditionalInformationResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';

interface AdditionalInformationCellProps {
  additionalInformation: ProductAdditionalInformationResponse[];
  selectCellElement: (element: ProductAdditionalInformationResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const AdditionalInformationCell: React.FC<AdditionalInformationCellProps> = ({
  additionalInformation,
  selectCellElement,
  isSelectedCellElement,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={'additionalInformationCell'}>
      {additionalInformation?.map((information, i) => {
        return (
          <div
            key={i}
            className={`additionalInformationCell-info ${
              isSelectedCellElement(information)
                ? 'additionalInformationCell-info__selected'
                : ''
            }`}
            onClick={() => selectCellElement(information)}
          >
            {
              information.informationGroup.name[
                i18n.language as TranslatedStringIndex
              ]
            }
          </div>
        );
      })}
    </div>
  );
};

export default AdditionalInformationCell;
