import './categoriescell.css';
import { ProductCategoryResponse } from '../../../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconStar } from '../../../../../../assets/icon/star.svg';

interface CategoriesCellProps {
  categories: ProductCategoryResponse[] | null;
  selectCellElement: (element: ProductCategoryResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
  mainCategoryId?: string | null;
}

const CategoriesCell: React.FC<CategoriesCellProps> = ({
  categories,
  selectCellElement,
  isSelectedCellElement,
  mainCategoryId,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={'categoriesCell'}>
      {categories?.map((category, i) => {
        const isMainCategory = mainCategoryId === category.id;
        return (
          <div
            key={i}
            className={`jtce-bubble ${
              isSelectedCellElement(category) ? 'jtce-bubble__selected' : ''
            }`}
            onClick={() => selectCellElement(category)}
          >
            {isMainCategory ? (
              <IconStar
                className={'jtce-bubble-icon'}
                fill={'var(--color-yellow)'}
              />
            ) : null}
            {category.name[i18n.language as TranslatedStringIndex]}
          </div>
        );
      })}
    </div>
  );
};

export default CategoriesCell;
