import './inputcell.css';
import { HTMLInputTypeAttribute, useRef } from 'react';

interface InputCellProps {
  content?: string;
  update?: (value: string) => void;
  onPaste?: (pastedInput: string) => void;
  hasError?: boolean;
  disabled?: boolean;
  toggleKeyEvents?: (bool: boolean) => void;
  type?: HTMLInputTypeAttribute;
  isValid?: boolean;
}

const InputCell: React.FC<InputCellProps> = ({
  content,
  update,
  onPaste,
  hasError,
  disabled,
  toggleKeyEvents,
  type,
  isValid,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`input-cell ${
        isValid !== undefined
          ? isValid
            ? 'input-cell__completed'
            : 'input-cell__missing'
          : ''
      }`}
      onDoubleClick={() => {
        inputRef.current?.focus();
      }}
    >
      <input
        ref={inputRef}
        className={`input-cell-input ${
          hasError ? 'input-cell-input__error' : ''
        }`}
        defaultValue={content}
        onFocus={() => {
          if (toggleKeyEvents) {
            toggleKeyEvents(false);
          }
        }}
        onBlur={
          update
            ? (e) => {
                update(e.target.value);
                console.log('input cell update');
                if (toggleKeyEvents) {
                  toggleKeyEvents(true);
                }
              }
            : undefined
        }
        disabled={disabled}
        type={type}
      />
    </div>
  );
};

export default InputCell;
