import './mediacell.css';
import { ProductAssetResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';

interface MediaCellProps {
  assets: ProductAssetResponse[];
  selectCellElement: (element: ProductAssetResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const MediaCell: React.FC<MediaCellProps> = ({
  assets,
  selectCellElement,
  isSelectedCellElement,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={'jtce-media'}>
      {assets?.map((asset, i) => {
        return (
          <img
            key={i}
            className={`jtce-media-asset ${
              isSelectedCellElement(asset) ? 'jtce-media-asset__selected' : ''
            }`}
            onClick={() => selectCellElement(asset)}
            src={asset.asset[i18n.language as TranslatedStringIndex]?.uri}
            alt={'product media'}
          />
        );
      })}
    </div>
  );
};

export default MediaCell;
