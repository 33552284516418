import {
  BestSellingProductsOrderBy,
  SoldProductResponse,
} from '../../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import List from '../../../../features/list/List';
import { useTranslation } from 'react-i18next';

interface SoldProductsListProps {
  soldProducts: SoldProductResponse[];
  orderBy: BestSellingProductsOrderBy;
}

type SoldProduct = {
  id?: string;
  cover?: string | null;
  productName?: string | null;
  ean?: string | null;
  productNumber?: string | null;
  manufacturerProductNumber?: string | null;
  quantity: number;
  totalRevenue: number;
  isVariant: boolean;
};

const SoldProductsList: React.FC<SoldProductsListProps> = ({
  soldProducts,
  orderBy,
}) => {
  const { i18n } = useTranslation();

  const getItems = (): SoldProduct[] => {
    const lang = i18n.language as TranslatedStringIndex;
    if (soldProducts) {
      return soldProducts.map((entry) => {
        const { product } = entry;
        return {
          id: product?.id,
          cover: product?.cover?.asset[lang]?.uri,
          productName: product?.name[lang],
          ean: product?.ean,
          productNumber: product?.productNumber,
          manufacturerProductNumber: product?.manufacturerProductNumber,
          quantity: entry.quantity,
          totalRevenue: entry.totalRevenue,
          isVariant: !!product?.parentId,
        };
      });
    } else {
      return [];
    }
  };

  return (
    <List
      height={500}
      items={getItems()}
      ignore={['id', 'isVariant']}
      itemImgKey={'cover'}
      linkedKey={'id'}
      linkPrefix={(item: SoldProduct) => {
        return item.isVariant ? '/products/variant/' : '/products/';
      }}
      monoSpaceStrings={['ean', 'productNumber', 'manufacturerProductNumber']}
      priceKeysConfig={{
        priceKeys: ['totalRevenue'],
        currencySymbol: '€',
      }}
      orderBy={{
        key: orderBy === 'Revenue' ? 'totalRevenue' : 'quantity',
        asc: true,
      }}
      tableHeadContrast
      isShowingIndex
    />
  );
};

export default SoldProductsList;
