import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../layout/stage/Stage';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProductBundleAssetResponse,
  ProductBundlePositionResponse,
  ProductBundleResponse,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import BundleHeadEdit from '../../sections/bundles/bundleheadedit/BundleHeadEdit';
import Card, { CardSection } from '../../elements/card/Card';
import useNotifications from '../../hooks/useNotifications';
import BundlePositions from '../../sections/bundles/bundlepositions/BundlePositions';
import BundleAssets from '../../sections/bundles/bundleassets/BundleAssets';
import BundleState from '../../sections/bundles/bundlestate/BundleState';
import Button from '../../elements/button/Button';
import { Has } from '../../contexts/auth/Authorization';

const Bundle: React.FC = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.bundle',
  });
  const api = usePetCloudApi();
  const productBundlesApi = api.productBundlesApi();
  const errorHandler = useErrorHandler();
  const params = useParams();
  const { pushNotification } = useNotifications();

  const [bundle, setBundle] = useState<ProductBundleResponse | null>(null);
  const [bundlePositions, setBundlePositions] = useState<
    ProductBundlePositionResponse[] | null
  >(null);
  const [bundleAssets, setBundleAssets] = useState<
    ProductBundleAssetResponse[] | null
  >(null);
  const [originalBundle, setOriginalBundle] =
    useState<ProductBundleResponse | null>(null);
  const [originalBundlePositions, setOriginalBundlePositions] = useState<
    ProductBundlePositionResponse[] | null
  >(null);
  const [originalBundleAssets, setOriginalBundleAssets] = useState<
    ProductBundleAssetResponse[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (params.bundleId) {
      getBundle(params.bundleId);
    }
  }, []);

  useEffect(() => {
    //tbd
  }, [originalBundleAssets, originalBundlePositions]);

  const getBundle = (bundleId: string) => {
    productBundlesApi
      .productBundlesGetProductBundle(bundleId)
      .then((response) => {
        console.log(response.data);
        setBundle(response.data);
        setOriginalBundle(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submit = async () => {
    if (bundle) {
      setIsSubmitting(true);
      await submitPositions(bundle.id);
      await submitAssets(bundle.id);
      await submitBase();

      setIsSubmitting(false);
    }
  };

  const submitBase = () => {
    if (originalBundle && bundle) {
      return new Promise((resolve, reject) => {
        const wasDisabled = !originalBundle.enabled;
        productBundlesApi
          .productBundlesUpdateProductBundle(bundle.id, bundle)
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
            pushNotification(t('general.notifications.update_success'));
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setIsSubmitting(false);
            errorHandler.addError(error.response);
            if (wasDisabled) {
              setBundle({ ...bundle, enabled: false });
            }
          });
      });
    }
  };

  const submitPositions = (bundleId: string) => {
    if (bundlePositions && bundlePositions.length > 0) {
      return new Promise((resolve, reject) => {
        productBundlesApi
          .productBundlesUpsertProductBundlePositions(bundleId, bundlePositions)
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
            pushNotification(t('positions.notifications.update_success'));
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setIsSubmitting(false);
            errorHandler.addError(error.response);
          });
      });
    }
  };

  const submitAssets = (bundleId: string) => {
    if (bundleAssets) {
      return new Promise((resolve, reject) => {
        productBundlesApi
          .productBundlesUpsertProductBundleAssets(
            bundleId,
            bundleAssets.map((asset) => {
              return {
                position: asset.position,
                assetId: {
                  'de-DE': asset.asset['de-DE']?.id,
                  'en-GB': asset.asset['en-GB']?.id,
                },
                description: asset.description,
                alternative: asset.alternative,
              };
            })
          )
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
            pushNotification(t('assets.notifications.update_success'));
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setIsSubmitting(false);
            errorHandler.addError(error.response);
          });
      });
    }
  };

  if (bundle) {
    return (
      <Has authorizations={['product_bundle:detail']} showNoAuthorization>
        <Stage>
          <Navigator
            title={bundle.name[i18n.language as TranslatedStringIndex]}
            allowBackButton
          >
            <Button
              action={submit}
              cta={t('actions.save')}
              look={'save'}
              width={'minimal'}
              isLoading={isSubmitting}
            />
          </Navigator>
          <StageColumns>
            <StageColumn size={'two'}>
              <Card bigScreenWidth={'full'} title={t('general.title')}>
                <CardSection>
                  <BundleHeadEdit
                    request={bundle}
                    updateRequest={(r) => setBundle(r as ProductBundleResponse)}
                  />
                </CardSection>
              </Card>
              <Card bigScreenWidth={'full'} title={t('positions.title')}>
                <CardSection>
                  <BundlePositions
                    bundleId={bundle.id}
                    setOriginalBundlePositions={setOriginalBundlePositions}
                    updatePositions={setBundlePositions}
                  />
                </CardSection>
              </Card>
              <Card bigScreenWidth={'full'} title={t('assets.title')}>
                <CardSection>
                  <BundleAssets
                    bundleId={bundle.id}
                    setOriginalBundleAssets={setOriginalBundleAssets}
                    updateBundleAssets={setBundleAssets}
                  />
                </CardSection>
              </Card>
            </StageColumn>
            <StageColumn size={'one'}>
              <Card bigScreenWidth={'full'}>
                <CardSection>
                  <BundleState
                    request={bundle}
                    updateRequest={(r) => setBundle(r as ProductBundleResponse)}
                  />
                </CardSection>
              </Card>
            </StageColumn>
          </StageColumns>
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Bundle;

// {bundlePositions && bundleAssets ? (
//   <CardSection
//     title={t('preview.title')}
//     hint={{
//       message: t('preview.hint'),
//     }}
//   >
//     <BundlePreview
//       bundle={bundle}
//       bundlePositions={bundlePositions}
//       bundleAssets={bundleAssets}
//     />
//   </CardSection>
// ) : (
//   <LoadingContainer />
// )}
