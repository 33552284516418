import './analyticconstituents.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  ProductAnalyticConstituentResponse,
  UpdateProductAnalyticConstituentRequest,
  UpdateProductAnalyticConstituentsRequest,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { constituentslist } from './constituentslist';
import useNotifications from '../../../hooks/useNotifications';
import { AnalyticConstituent } from './analyticconstituent/AnalyticConstituent';

interface AnalyticConstituentsProps {
  productId: string;
  analyticConstituents?: ProductAnalyticConstituentResponse[] | null;
  inheritedAnalyticConstituents?: ProductAnalyticConstituentResponse[] | null;
  readonly?: boolean;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  updateProductViewTabs?: (array: { tabKey: string; safe: boolean }[]) => void;
  toggleProductSavingHelpOverlay?: () => void;
  updateProductAnalyticConstituents: (
    ac: ProductAnalyticConstituentResponse[]
  ) => void;
}

const AnalyticConstituents: React.FC<AnalyticConstituentsProps> = ({
  productId,
  analyticConstituents,
  inheritedAnalyticConstituents,
  readonly,
  queueCriticalAction,
  killCriticalAction,
  updateProductViewTabs,
  toggleProductSavingHelpOverlay,
  updateProductAnalyticConstituents,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.product.analyticConstituents',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const [constituents, setConstituents] = useState<
    UpdateProductAnalyticConstituentRequest[]
  >(analyticConstituents ?? []);
  const [originalConstituents] = useState<
    UpdateProductAnalyticConstituentRequest[]
  >(analyticConstituents ?? []);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log(JSON.stringify(constituents));
    console.log(JSON.stringify(originalConstituents));
    if (
      updateProductViewTabs &&
      JSON.stringify(constituents) !== JSON.stringify(originalConstituents)
    ) {
      updateProductViewTabs([{ tabKey: 'product', safe: false }]);
    }
  }, [constituents]);

  const submitUpdate = (request: UpdateProductAnalyticConstituentsRequest) => {
    setIsSubmitting(true);
    if (queueCriticalAction) {
      queueCriticalAction('updatingAnalyticConstituents');
    }
    productsApi
      .productsUpdateProductAnalyticConstituents(productId, request)
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        pushNotification(t('notifications.update_successful'));
        //TODO: response should give new consituents
        //updateProductAnalyticConstituents(response.data)
        if (killCriticalAction) {
          killCriticalAction('updatingAnalyticConstituents');
        }
        if (updateProductViewTabs) {
          updateProductViewTabs([{ tabKey: 'product', safe: true }]);
        }
        if (toggleProductSavingHelpOverlay) {
          toggleProductSavingHelpOverlay();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        errorHandler.addError(error.response);
        if (killCriticalAction) {
          killCriticalAction('updatingAnalyticConstituents');
        }
      });
  };

  const addConstituent = () => {
    const update = [...constituents];

    // if the first one is added transfer any inherited ones
    if (update.length === 0 && inheritedAnalyticConstituents) {
      inheritedAnalyticConstituents.forEach((inherited) => {
        update.push(inherited);
      });
    }
    update.push({
      value: 0,
      position: constituents.length,
      name: {
        'de-DE': '',
        'en-GB': '',
      },
    });
    reIndexedUpdate(update);
  };

  const updateConstituent = (
    constituent: UpdateProductAnalyticConstituentRequest,
    index: number
  ) => {
    const update = [...constituents];
    update[index] = {
      ...constituent,
    };
    setConstituents(update);
  };

  const deleteConstituent = (index: number) => {
    const update = [...constituents];
    update.splice(index, 1);
    reIndexedUpdate(update);
  };

  const reIndexedUpdate = (
    array: UpdateProductAnalyticConstituentRequest[]
  ) => {
    const reIndexed = array.map((entry, i) => {
      return {
        ...entry,
        position: i,
      };
    });
    setConstituents(reIndexed);
  };

  const options = constituentslist.map((c) => ({
    id: c.id,
    name: c.name[i18n.language as TranslatedStringIndex],
  }));

  return (
    <div className="analyticconstituents">
      {constituents.length === 0 ? (
        inheritedAnalyticConstituents &&
        inheritedAnalyticConstituents.length > 0 ? (
          <div className="analyticconstituents-inherited">
            {inheritedAnalyticConstituents.map((constituent, i) => {
              return (
                <AnalyticConstituent
                  key={i}
                  constituent={constituent}
                  updateConstituent={(c) => updateConstituent(c, i)}
                  deleteConstituent={() => deleteConstituent(i)}
                  readonly={readonly}
                  options={options}
                />
              );
            })}
          </div>
        ) : (
          <EmptyState small />
        )
      ) : (
        constituents.map((constituent, i) => {
          return (
            <AnalyticConstituent
              key={i}
              constituent={constituent}
              updateConstituent={(c) => updateConstituent(c, i)}
              deleteConstituent={() => deleteConstituent(i)}
              readonly={readonly}
              options={options}
            />
          );
        })
      )}
      {!readonly ? (
        <div className="analyticconstituents-actions">
          <Button
            cta={t('actions.add')}
            width="minimal"
            look={'secondary'}
            action={addConstituent}
          />
          <Button
            cta={t('actions.save')}
            width="minimal"
            look="save"
            action={() =>
              submitUpdate({
                productAnalyticConstituents: constituents,
              })
            }
            isLoading={isSubmitting}
            active={constituents !== originalConstituents}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AnalyticConstituents;
