import './headerconfigloader.css';
import { JsonTableHeader } from '../JsonTable';
import { ReactComponent as IconLoad } from '../../../../assets/icon/jsontable/menu/columns_load.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../elements/popup/Popup';
import useUserSettings from '../../../hooks/useUserSettings';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import useDateTools from '../../../hooks/useDateTools';
import Button from '../../../elements/button/Button';
import { ReactComponent as IconTrash } from '../../../../assets/icon/trash.svg';
import { ReactComponent as IconReload } from '../../../../assets/icon/reload.svg';
import { UserSettingHeaderConfig } from '../headerconfigsave/HeaderConfigSave';
import _ from 'lodash';
import Badge from '../../../elements/badge/Badge';
import useNotifications from '../../../hooks/useNotifications';

interface HeaderConfigLoaderProps {
  currentHeaders: JsonTableHeader[];
  setHeaders: (headers: JsonTableHeader[]) => void;
}

const HeaderConfigLoader: React.FC<HeaderConfigLoaderProps> = ({
  currentHeaders,
  setHeaders,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.jsonTable.headerConfigLoader',
  });
  const { displayReadableDate } = useDateTools();
  const { pushNotification } = useNotifications();
  const [popup, setPopup] = useState(false);
  const [configToDelete, setConfigToDelete] =
    useState<UserSettingHeaderConfig | null>(null);
  const { currentUserSettings, updateUserSettings, isSubmitting } =
    useUserSettings(() => {
      pushNotification(t('notifications.delete_success'));
      setConfigToDelete(null);
    });

  const deleteConfig = () => {
    if (
      configToDelete &&
      currentUserSettings &&
      currentUserSettings.productBulkEditor
    ) {
      const update = currentUserSettings.productBulkEditor;
      const i = update.headerConfigs.indexOf(configToDelete);
      console.log(i);
      if (i !== -1) {
        update.headerConfigs.splice(i, 1);
        console.log(update.headerConfigs);
        // submit to backend
        updateUserSettings('productBulkEditor', update);
      }
    }
  };

  // this ensures that the saved config respects headers / fields that have been added to the objects in the meantime
  const loadConfig = (config: UserSettingHeaderConfig) => {
    console.log(config);
    const newHeaders = currentHeaders.map((header) => {
      const h = config.headers.find((h) => h.id === header.id);
      if (h) {
        return h;
      } else {
        return header;
      }
    });
    setHeaders(newHeaders);
    setPopup(false);
  };

  if (currentUserSettings !== null) {
    return (
      <div className={'headerConfigLoader'}>
        <div
          className={'actionsPopout-action actionsPopout-action__secondary'}
          onClick={() => setPopup(true)}
        >
          <div className={'actionsPopout-action-icon'}>
            <IconLoad className={'actionsPopout-action-icon'} />
          </div>
          {t('cta')}
        </div>
        <Popup toggled={popup} width={'40%'} close={() => setPopup(false)}>
          <div className={'popup-title'}>{t('popup.title')}</div>
          <div className={'headerConfigLoader-configs'}>
            {currentUserSettings.productBulkEditor &&
            currentUserSettings.productBulkEditor.headerConfigs.length > 0 ? (
              _.orderBy(
                currentUserSettings.productBulkEditor.headerConfigs,
                'createdAt',
                ['desc']
              ).map((config) => {
                return (
                  <div
                    className={'headerConfigLoader-config'}
                    onClick={() => loadConfig(config)}
                  >
                    <div className={'headerConfigLoader-config-info'}>
                      <div
                        className={'headerConfigLoader-config-info-createdAt'}
                      >
                        <Badge
                          title={displayReadableDate(config.createdAt)}
                          color={'var(--color-text_secondary)'}
                        />
                      </div>
                      <div className={'headerConfigLoader-config-info-name'}>
                        {config.name}
                      </div>
                    </div>
                    <div className={'headerConfigLoader-config-actions'}>
                      <Button
                        type={'icon'}
                        width={'tiny'}
                        look={'secondary'}
                        action={() => loadConfig(config)}
                        margin={'right'}
                      >
                        <IconReload className={'button-icon-tiny'} />
                      </Button>
                      <Button
                        type={'icon'}
                        width={'tiny'}
                        look={'secondary'}
                        action={() => setConfigToDelete(config)}
                      >
                        <IconTrash
                          className={'button-icon-tiny button-icon-danger'}
                        />
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyState message={t('empty')} />
            )}
          </div>
          {configToDelete ? (
            <Popup
              toggled={true}
              width={'30%'}
              close={() => setConfigToDelete(null)}
            >
              <div className={'popup-title'}>{t('deletePopup.title')}</div>
              <div className={'global-textElement'}>
                {t('deletePopup.text')}
              </div>
              <div className={'headerConfigLoader-popupActions'}>
                <Button
                  cta={t('deletePopup.cta')}
                  look={'danger'}
                  width={'full'}
                  action={deleteConfig}
                  isLoading={isSubmitting}
                />
              </div>
            </Popup>
          ) : null}
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default HeaderConfigLoader;
