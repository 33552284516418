export const constituentslist = [
  {
    id: '526c1734-7c7a-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Rohasche',
      'en-GB': 'Crude Ash',
    },
  },
  {
    id: '6f04bfbc-7c7b-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Rohfaser',
      'en-GB': 'Crude Fibre',
    },
  },
  {
    id: '6f04c28c-7c7b-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Kalzium',
      'en-GB': 'Calcium',
    },
  },
  {
    id: '6f04c430-7c7a-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Rohfett',
      'en-GB': 'Crude Fat',
    },
  },
  {
    id: '6f04c610-7c7a-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Feuchtigkeit',
      'en-GB': 'Moisture',
    },
  },
  {
    id: '6f04c7dc-7c7a-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Rohprotein',
      'en-GB': 'Crude Protein',
    },
  },
  {
    id: '6f04c9b2-7c7a-11ee-b962-0242ac120002',
    name: {
      'de-DE': 'Phosphor',
      'en-GB': 'Phosphor',
    },
  },
];
