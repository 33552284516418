import { useState } from 'react';
import './retractable.css';
import { ReactComponent as Arrow } from '../../../assets/icon/arrow_dropdown.svg';

interface RetractableProps {
  title?: string;
  head?: React.ReactNode;
  forbidRetraction?: boolean;
  hasBottomMargin?: boolean;
}

const Retractable: React.FC<RetractableProps> = ({
  title,
  head,
  forbidRetraction,
  hasBottomMargin,
  children,
}) => {
  const [toggled, setToggled] = useState(false);

  return (
    <div
      className={'retractable '}
      style={{ marginBottom: hasBottomMargin ? 20 : 0 }}
    >
      <div
        className={
          'retractable-head' +
          ' ' +
          (!forbidRetraction ? 'retractable-head-active' : undefined)
        }
        onClick={!forbidRetraction ? () => setToggled(!toggled) : undefined}
      >
        {!forbidRetraction ? (
          <Arrow
            className="retractable-head-arrow"
            fill={'var(--color-text_primary)'}
            style={{
              transform: toggled ? 'none' : 'rotate(-90deg)',
            }}
          />
        ) : null}
        {title ? <div className="retractable-head-title">{title}</div> : null}
        {head ?? null}
      </div>
      <div
        className="retractable-content"
        style={{ display: toggled ? 'block' : 'none' }}
      >
        {children}
      </div>
    </div>
  );
};

export default Retractable;
