import './loyaltypointprocessingconfig.css';
import {
  LoyaltyPointHandlerConfig,
  LoyaltyPointProcessingConfigResponse,
} from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../../elements/selectors/Selectors';
import { ReactNode } from 'react';
import Input from '../../../../../elements/input/Input';
import Button from '../../../../../elements/button/Button';
import { ReactComponent as IconDelete } from '../../../../../../assets/icon/trash.svg';
import { ReactComponent as IconHandle } from '../../../../../../assets/icon/drag.svg';
import ToggleSwitch from '../../../../../elements/toggleswitch/ToggleSwitch';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface LoyaltyPointProcessingConfigProps {
  loyaltyPointHandlerConfigs: LoyaltyPointHandlerConfig[];
  updateConfig: (config: LoyaltyPointProcessingConfigResponse) => void;
  deleteConfig: () => void;
  config: LoyaltyPointProcessingConfigResponse;
}

const LoyaltyPointProcessingConfig: React.FC<
  LoyaltyPointProcessingConfigProps
> = ({ loyaltyPointHandlerConfigs, config, updateConfig, deleteConfig }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.loyalty.loyaltyPointProcessingConfigs.config',
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: config.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : 1,
    boxShadow: isDragging ? 'var(--shadow-wide)' : 'var(--shadow-minimal)',
  };

  const renderArgumentInputs = () => {
    const count = loyaltyPointHandlerConfigs.find(
      (x) => x.handlerName === config.handlerName
    )?.parameterCount;
    if (count) {
      const inputs: ReactNode[] = [];
      for (let i = 0; i < count; i++) {
        inputs.push(
          <div key={i} className={'global-inputGroup-input'}>
            <Input
              title={`${t('handlerArgument')} ${i + 1}`}
              content={config.handlerArguments?.[i]}
              update={(value) => {
                const argumentsUpdate = config.handlerArguments;
                if (argumentsUpdate) {
                  argumentsUpdate[i] = value;
                  updateConfig({
                    ...config,
                    handlerArguments: argumentsUpdate,
                  });
                }
              }}
            />
          </div>
        );
      }
      return inputs;
    } else {
      return null;
    }
  };

  return (
    <div
      className={'loyaltyPointProcessingConfig'}
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <div className={'loyaltyPointProcessingConfig-handle'}>
        <IconHandle
          className={'loyaltyPointProcessingConfig-handle-button'}
          fill={'var(--color-text_tertiary)'}
        />
      </div>
      <div className={'loyaltyPointProcessingConfig-inputs'}>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Dropdown
              title={t('handlerName')}
              selected={config.handlerName.split('.').pop()}
              options={loyaltyPointHandlerConfigs.map(
                (x) => x.handlerName?.split('.').pop() ?? 'handlerName missing'
              )}
              update={(e) => {
                const value = e.currentTarget.selectedOptions[0].value;
                const selectedHandlerConfig = loyaltyPointHandlerConfigs.find(
                  (x) => x.handlerName?.includes(value)
                );
                if (selectedHandlerConfig?.handlerName) {
                  updateConfig({
                    ...config,
                    handlerName: selectedHandlerConfig.handlerName,
                    handlerArguments: selectedHandlerConfig?.parameterCount
                      ? Array(selectedHandlerConfig.parameterCount).fill('')
                      : [],
                  });
                }
              }}
            />
          </div>
          {renderArgumentInputs()}
          <div className={'loyaltyPointProcessingConfig-toggle'}>
            <ToggleSwitch
              toggled={config.isEnabled}
              toggle={() =>
                updateConfig({
                  ...config,
                  isEnabled: !config.isEnabled,
                })
              }
            />
          </div>
          <div className={'loyaltyPointProcessingConfig-delete'}>
            <Button
              look={'secondary-danger'}
              type={'icon'}
              width={'minimal'}
              action={deleteConfig}
            >
              <IconDelete className={'button-icon button-icon-danger'} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyPointProcessingConfig;
