import './newbundle.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CreateProductBundleRequest } from '../../../api/petcloudapi/api';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';
import { useUser } from '../../../contexts/auth/User';
import { DropdownOption } from '../../../elements/selectors/Selectors';
import BundleHeadEdit from '../bundleheadedit/BundleHeadEdit';

interface NewBundleProps {
  postSubmit: () => void;
}

const NewBundle: React.FC<NewBundleProps> = ({ postSubmit }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.bundles.newPopup',
  });
  const api = usePetCloudApi();
  const productBundlesApi = api.productBundlesApi();
  const manufacturersApi = api.manufacturersApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const { user } = useUser();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createNewBundleRequest: CreateProductBundleRequest = {
    manufacturerId:
      user?.isManufacturerAdmin && user.manufacturerId
        ? user.manufacturerId
        : 'null',
    name: {
      'de-DE': '',
      'en-GB': '',
    },
    description: {
      'de-DE': '',
      'en-GB': '',
    },
  };
  const [request, setRequest] = useState({ ...createNewBundleRequest });
  const [manufacturerOptions, setManufacturerOptions] = useState<
    DropdownOption[] | null
  >(null);

  useEffect(() => {
    if (user?.isProductOwner) {
      getManufacturerOptions();
    }
  }, []);

  const getManufacturerOptions = () => {
    manufacturersApi
      .manufacturersGetManufacturers()
      .then((response) => {
        console.log(response.data);
        setManufacturerOptions(
          response.data.map((x) => ({
            name: x.companyName,
            id: x.id,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submit = () => {
    setIsSubmitting(true);
    productBundlesApi
      .productBundlesCreateProductBundle(request)
      .then((response) => {
        console.log(response.data);
        setRequest({ ...createNewBundleRequest });
        pushNotification(t('notifications.create_success'));
        setIsSubmitting(false);
        postSubmit();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'newBundle'}>
      <BundleHeadEdit
        manufacturerOptions={manufacturerOptions}
        request={request}
        updateRequest={(r) => setRequest(r)}
        submit={submit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default NewBundle;
