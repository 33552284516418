import _ from 'lodash';
import { useCallback } from 'react';

const useStorageData = () => {
  const persistObject = useCallback(
    _.debounce((object: any, storageName: string) => {
      const string = JSON.stringify(object);
      localStorage.setItem(storageName, string);
    }, 2000),
    []
  );

  const getPersistedObject = (storageName: string) => {
    const str = localStorage.getItem(storageName);
    if (str) {
      return JSON.parse(str);
    } else {
      return null;
    }
  };

  const removePersistedObjects = (storageNames: string[]) => {
    storageNames.forEach((name) => {
      localStorage.removeItem(name);
    });
  };

  return {
    persistObject,
    getPersistedObject,
    removePersistedObjects,
  };
};

export default useStorageData;
