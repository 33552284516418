const useListTools = () => {
  const toggleItem = (items: any[] | null | undefined, newItem: any) => {
    if (items) {
      const update = [...items];
      const i = update.findIndex((item) => item.id === newItem.id);
      if (i !== -1) {
        update.splice(i, 1);
      } else {
        update.push(newItem);
      }
      return update;
    }
  };

  const toggleAllItems = (items: any[] | null | undefined, newItems: any[]) => {
    if (items && items.length > 0) {
      return [];
    } else {
      return [...newItems];
    }
  };

  return {
    toggleItem,
    toggleAllItems,
  };
};

export default useListTools;
