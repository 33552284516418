import './manufacturerconditions.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  InlineCreateManufacturerConditionRequest,
  ManufacturerConditionKey,
  ManufacturerConditionResponse,
  UpdateManufacturerConditionRequest,
} from '../../../api/petcloudapi/api';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import Input from '../../../elements/input/Input';
import Button from '../../../elements/button/Button';
import { Dropdown } from '../../../elements/selectors/Selectors';
import { LoadingContainer } from '../../../elements/loading/Loading';
import ManufacturerConditionsBody, {
  ManufacturerCondition,
} from '../../../types/ManufacturerConditionsBody';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import { hasError, Error } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';

interface ManufacturerConditionsProps {
  manufacturerId?: string;
  setInlineConditionsCallback?: (
    conditions: InlineCreateManufacturerConditionRequest[]
  ) => void;
  errors?: Error[];
}

const ManufacturerConditions: React.FC<ManufacturerConditionsProps> = ({
  manufacturerId,
  setInlineConditionsCallback,
  errors,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.conditions',
  });
  const api = usePetCloudApi();
  const manufacturerConditionsApi = api.manufacturerConditionsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [conditions, setConditions] =
    useState<ManufacturerConditionsBody | null>(null);
  const [originalConditions, setOriginalConditions] =
    useState<ManufacturerConditionsBody | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (manufacturerId) {
      getConditions();
    } else {
      setNewConditions();
    }
  }, []);

  useEffect(() => {
    if (conditions && setInlineConditionsCallback) {
      const inline: InlineCreateManufacturerConditionRequest[] = [];
      Object.entries(conditions).forEach(([key, condition]) => {
        if (condition) {
          inline.push({
            manufacturerId: null,
            key: key as ManufacturerConditionKey,
            value: condition.value,
          });
        }
      });
      setInlineConditionsCallback(inline);
    }
  }, [conditions]);

  const setNewConditions = () => {
    setConditions({
      paymentCommission: {
        id: undefined,
        value: '20',
      },
      numberRangeAware: {
        id: undefined,
        value: 'false',
      },
      warehouseUsage: {
        id: undefined,
        value: 'Central',
      },
      promotionAware: {
        id: undefined,
        value: 'false',
      },
      orderReturnAnnouncementAware: {
        id: undefined,
        value: 'false',
      },
      productGroupMappingRuleAware: {
        id: undefined,
        value: 'false',
      },
      bulkProductImportAware: {
        id: undefined,
        value: 'false',
      },
      productBundleAware: {
        id: undefined,
        value: 'false',
      },
      manufacturerDataExchangeAware: {
        id: undefined,
        value: 'false',
      },
      exportOrderCreditNoteDataAware: {
        id: undefined,
        value: 'false',
        parent: 'manufacturerDataExchangeAware',
      },
      exportOrderInvoiceDataAware: {
        id: undefined,
        value: 'false',
        parent: 'manufacturerDataExchangeAware',
      },
      exportOrderReceiptAware: {
        id: undefined,
        value: 'false',
        parent: 'manufacturerDataExchangeAware',
      },
      exportOrderReturnAnnouncementAware: {
        id: undefined,
        value: 'false',
        parent: 'manufacturerDataExchangeAware',
      },
      exportOrderAware: {
        id: undefined,
        value: 'false',
        parent: 'manufacturerDataExchangeAware',
      },
    });
  };

  const getCondition = (
    conditions: ManufacturerConditionResponse[],
    key: string
  ) => {
    return conditions.find(
      (c) => c.manufacturerId === manufacturerId && c.key === key
    );
  };

  const getConditions = () => {
    manufacturerConditionsApi
      .manufacturerConditionsGetManufacturerConditions()
      .then((response) => {
        console.log(response);
        const paymentCommissionCondition = getCondition(
          response.data,
          'PaymentCommission'
        );
        const numberRangeAwareCondition = getCondition(
          response.data,
          'NumberRangeAware'
        );
        const warehouseUsageCondition = getCondition(
          response.data,
          'WarehouseUsage'
        );
        const promotionAwareCondition = getCondition(
          response.data,
          'PromotionAware'
        );
        const orderReturnAnnouncementAware = getCondition(
          response.data,
          'OrderReturnAnnouncementAware'
        );
        const manufacturerDataExchangeAware = getCondition(
          response.data,
          'ManufacturerDataExchangeAware'
        );
        const productGroupMappingRuleAware = getCondition(
          response.data,
          'ProductGroupMappingRuleAware'
        );
        const bulkProductImportAware = getCondition(
          response.data,
          'BulkProductImportAware'
        );
        const productBundleAware = getCondition(
          response.data,
          'ProductBundleAware'
        );
        const exportOrderCreditNoteDataAware = getCondition(
          response.data,
          'ExportOrderCreditNoteDataAware'
        );
        const exportOrderInvoiceDataAware = getCondition(
          response.data,
          'ExportOrderInvoiceDataAware'
        );
        const exportOrderReceiptAware = getCondition(
          response.data,
          'ExportOrderReceiptAware'
        );
        const exportOrderReturnAnnouncementAware = getCondition(
          response.data,
          'ExportOrderReturnAnnouncementAware'
        );
        const exportOrderAware = getCondition(
          response.data,
          'ExportOrderAware'
        );
        const result = {
          paymentCommission: paymentCommissionCondition
            ? {
                id: paymentCommissionCondition.id,
                value: paymentCommissionCondition.value,
              }
            : undefined,
          numberRangeAware: numberRangeAwareCondition
            ? {
                id: numberRangeAwareCondition.id,
                value: numberRangeAwareCondition.value,
              }
            : undefined,
          warehouseUsage: warehouseUsageCondition
            ? {
                id: warehouseUsageCondition.id,
                value: warehouseUsageCondition.value,
              }
            : undefined,
          promotionAware: promotionAwareCondition
            ? {
                id: promotionAwareCondition.id,
                value: promotionAwareCondition.value,
              }
            : undefined,
          orderReturnAnnouncementAware: orderReturnAnnouncementAware
            ? {
                id: orderReturnAnnouncementAware.id,
                value: orderReturnAnnouncementAware.value,
              }
            : undefined,
          productGroupMappingRuleAware: productGroupMappingRuleAware
            ? {
                id: productGroupMappingRuleAware.id,
                value: productGroupMappingRuleAware.value,
              }
            : undefined,
          bulkProductImportAware: bulkProductImportAware
            ? {
                id: bulkProductImportAware.id,
                value: bulkProductImportAware.value,
              }
            : undefined,
          productBundleAware: productBundleAware
            ? {
                id: productBundleAware.id,
                value: productBundleAware.value,
              }
            : undefined,
          manufacturerDataExchangeAware: manufacturerDataExchangeAware
            ? {
                id: manufacturerDataExchangeAware.id,
                value: manufacturerDataExchangeAware.value,
              }
            : undefined,
          exportOrderCreditNoteDataAware: exportOrderCreditNoteDataAware
            ? {
                id: exportOrderCreditNoteDataAware.id,
                value: exportOrderCreditNoteDataAware.value,
                parent: 'manufacturerDataExchangeAware',
              }
            : undefined,
          exportOrderInvoiceDataAware: exportOrderInvoiceDataAware
            ? {
                id: exportOrderInvoiceDataAware.id,
                value: exportOrderInvoiceDataAware.value,
                parent: 'manufacturerDataExchangeAware',
              }
            : undefined,
          exportOrderReceiptAware: exportOrderReceiptAware
            ? {
                id: exportOrderReceiptAware.id,
                value: exportOrderReceiptAware.value,
                parent: 'manufacturerDataExchangeAware',
              }
            : undefined,
          exportOrderReturnAnnouncementAware: exportOrderReturnAnnouncementAware
            ? {
                id: exportOrderReturnAnnouncementAware.id,
                value: exportOrderReturnAnnouncementAware.value,
                parent: 'manufacturerDataExchangeAware',
              }
            : undefined,
          exportOrderAware: exportOrderAware
            ? {
                id: exportOrderAware.id,
                value: exportOrderAware.value,
                parent: 'manufacturerDataExchangeAware',
              }
            : undefined,
        };
        setConditions(result);
        setOriginalConditions(result);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const updateConditions = () => {
    if (conditions && originalConditions) {
      Object.entries(conditions).forEach(([key, condition]) => {
        if (condition?.id) {
          if (
            originalConditions[key as keyof ManufacturerConditionsBody] !==
            condition
          )
            updateCondition(condition.id, condition);
        }
      });
    }
  };

  const updateCondition = (
    conditionId: string,
    request: UpdateManufacturerConditionRequest
  ) => {
    setIsSubmitting(true);
    manufacturerConditionsApi
      .manufacturerConditionsUpdateManufacturerCondition(conditionId, request)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.update_successful'));
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const nonToggleableConditions = ['paymentCommission', 'warehouseUsage'];

  const renderSwitch = (key: string, condition: ManufacturerCondition) => {
    if (conditions) {
      const isToggled = condition.value === 'true';
      return (
        <div key={key} className={'global-inputGroup'}>
          <div
            className={`global-inputGroup-input ${
              condition.parent ? 'manufacturerconditions-conditions-indent' : ''
            }`}
          >
            <ToggleSwitch
              label={t(`names.${key}`)}
              toggled={isToggled}
              toggle={() => {
                setConditions({
                  ...conditions,
                  [key]: {
                    ...conditions?.[key as keyof ManufacturerConditionsBody],
                    value: isToggled ? 'false' : 'true',
                  },
                });
              }}
              smallLabel={!!condition.parent}
              smallSwitch={!!condition.parent}
              disabled={
                condition.parent
                  ? conditions[
                      condition.parent as keyof ManufacturerConditionsBody
                    ]?.value === 'false'
                  : false
              }
            />
          </div>
        </div>
      );
    }
  };

  if (conditions) {
    return (
      <div className="manufacturerconditions">
        <div className="manufacturerconditions-conditions">
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Input
                title={'Payment Commission'}
                content={conditions.paymentCommission?.value}
                update={(e) => {
                  setConditions({
                    ...conditions,
                    paymentCommission: {
                      ...conditions?.paymentCommission,
                      value: e,
                    },
                  });
                }}
                noMargin
                unit={'%'}
                errors={hasError(errors, ['PaymentCommission'])}
                required={true}
              />
            </div>
          </div>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Dropdown
                title={'Warehouse Usage'}
                options={['Central', 'Hybrid', 'Custom']}
                selected={conditions.warehouseUsage?.value}
                update={(e) => {
                  setConditions({
                    ...conditions,
                    warehouseUsage: {
                      ...conditions?.warehouseUsage,
                      value: e.target.selectedOptions[0].value,
                    },
                  });
                }}
                errors={hasError(errors, ['WarehouseUsage'])}
                required={true}
              />
            </div>
          </div>
          {Object.entries(conditions)
            .filter(([key]) => !nonToggleableConditions.includes(key))
            .map(([key, condition]) => {
              if (condition) {
                return renderSwitch(key, condition);
              } else {
                return null;
              }
            })}
        </div>
        {manufacturerId ? (
          <div className={'global-cardActions manufacturerconditions-actions'}>
            <Button
              cta={t('actions.save')}
              look="save"
              width="minimal"
              action={updateConditions}
              active={originalConditions !== conditions}
              isLoading={isSubmitting}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ManufacturerConditions;
