import './tablecompletionbar.css';
import ProgressBar from '../../../elements/progressbar/ProgressBar';
import Hoverable from '../../../elements/hoverable/Hoverable';
import { useTranslation } from 'react-i18next';
import useValidation from '../utils/useValidation';

export type ComplexRequiredFieldConfig = {
  conditionFunc: (item: any) => boolean;
  requiredFields: string[];
};

export type RequiredFieldValidationMap = {
  [key: string]: (value: any) => boolean;
};

type TableCompletionInfo = {
  present: number;
  totalRequired: number;
  percentage: number;
};

interface TableCompletionBarProps {
  items: any[];
  requiredFields?: string[];
  complexRequiredFieldConfigs?: ComplexRequiredFieldConfig[];
  requiredFieldValidationMap?: RequiredFieldValidationMap;
}

const TableCompletionBar: React.FC<TableCompletionBarProps> = ({
  items,
  requiredFields,
  complexRequiredFieldConfigs,
  requiredFieldValidationMap,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.jsonTable.tableCompletionBar',
  });
  const { isCompleteValue } = useValidation(requiredFieldValidationMap);

  const getTableCompletionInfo = (rf: string[]): TableCompletionInfo => {
    const presentRequiredFields = items.reduce((count, item) => {
      const presentItemFields = rf.reduce((c, field) => {
        return c + (isCompleteValue(item, field) ? 1 : 0);
      }, 0);
      return count + presentItemFields;
    }, 0);
    const rfCount = rf.length;
    return {
      present: presentRequiredFields,
      totalRequired: rfCount * items.length,
      percentage: Math.floor(
        (presentRequiredFields / (items.length * rfCount)) * 100
      ),
    };
  };

  const getComplexTableCompletionInfo = (crf: ComplexRequiredFieldConfig[]) => {
    let rfCount = 0;
    const presentRequiredFields = items.reduce((count, item) => {
      const rf: string[] = [];
      crf.forEach((config) => {
        if (config.conditionFunc(item)) {
          config.requiredFields.forEach((field) => {
            rf.push(field);
            rfCount += 1;
          });
        }
      });
      const presentItemFields = rf.reduce((c, field) => {
        return c + (isCompleteValue(item, field) ? 1 : 0);
      }, 0);
      return count + presentItemFields;
    }, 0);
    return {
      present: presentRequiredFields,
      totalRequired: rfCount,
      percentage: Math.floor((presentRequiredFields / rfCount) * 100),
    };
  };

  const info = complexRequiredFieldConfigs
    ? getComplexTableCompletionInfo(complexRequiredFieldConfigs)
    : requiredFields
    ? getTableCompletionInfo(requiredFields)
    : null;

  if (info) {
    return (
      <div className={'tableCompletionBar'}>
        <Hoverable
          onHoverNode={
            <div className={'tableCompletionBar-details'}>
              {`${info.percentage}% | ${info.present} / ${info.totalRequired} `}
              <span>{t('progress')}</span>
            </div>
          }
          helperClass={'tableCompletionBar-bar'}
        >
          <ProgressBar
            total={info.totalRequired}
            progress={info.present}
            barColor={'var(--color-select)'}
            backgroundColor={'rgba(0,149,255,0.15)'}
            hideDetail
            height={4}
            borderRadiusOuter={'6px 6px 0 0'}
            borderRadiusInner={'6px 0px 0 0'}
            colorOnCompletion={'var(--color-success)'}
          />
        </Hoverable>
      </div>
    );
  } else {
    return <div>'No required fields or configs provided.'</div>;
  }
};

export default TableCompletionBar;
