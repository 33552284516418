import './bundlestate.css';
import { ProductBundleResponse } from '../../../api/petcloudapi/api';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import { useTranslation } from 'react-i18next';

interface BundleStateProps {
  request: ProductBundleResponse;
  updateRequest: (request: ProductBundleResponse) => void;
}

const BundleState: React.FC<BundleStateProps> = ({
  request,
  updateRequest,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.bundle.state',
  });

  return (
    <div className={'bundleState'}>
      <ToggleSwitch
        label={request.enabled ? t('active') : t('inactive')}
        toggled={request.enabled}
        toggle={() =>
          updateRequest({
            ...request,
            enabled: !request.enabled,
          })
        }
      />
    </div>
  );
};

export default BundleState;
