import { randomLightColor } from 'seed-to-color';

const useWarehouseColors = () => {
  const warehouseColors: { name: string; color: string }[] = [];

  return (warehouseName: string) => {
    const whc = warehouseColors.find((c) => c.name === warehouseName);
    if (whc) {
      return whc.color;
    } else {
      const c = {
        name: warehouseName,
        color: '#' + randomLightColor(warehouseName),
      };
      warehouseColors.push(c);
      console.log(c.color);
      return c.color;
    }
  };
};

export default useWarehouseColors;
