const useJsonParseCamelcase = () => {
  const toCamelCase = (key: string, value: any) => {
    if (value && typeof value === 'object') {
      for (let k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  };

  return { toCamelCase };
};

export default useJsonParseCamelcase;
