import { queryItems } from '../../../../elements/productsearch/ProductSearch';
import { ListControlSearch } from '../ListControls';
import { useEffect, useState } from 'react';
import useUrlParams from '../../../../hooks/useUrlParams';
import _ from 'lodash';

const useListControlsSearch = <T = any,>(
  cta: string,
  initialItems: T[] | null | undefined,
  queryableKeyPaths: string[]
): {
  listControlSearch: ListControlSearch;
  query: string;
  currentItems: T[] | null | undefined;
  setOriginalItems: (items: T[] | null) => void;
  originalItems: T[] | null | undefined;
} => {
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();
  const [query, setQuery] = useState('');
  const [originalItems, setOriginalItems] = useState(initialItems);
  const [currentItems, setCurrentItems] = useState(initialItems);

  useEffect(() => {
    const queryParam = getUrlParam('query');
    if (queryParam && queryParam !== '') {
      setQuery(queryParam);
      handleQuery(queryParam);
    }
  }, []);

  useEffect(() => {
    setCurrentItems(originalItems);
    handleQuery(query);
  }, [originalItems]);

  const handleQuery = (query: string) => {
    if (query) {
      setUrlParam('query', query);
    } else {
      removeUrlParam('query');
    }
    const results = queryItems(
      query,
      originalItems,
      (q) => setQuery(q),
      (r) => setCurrentItems(r),
      (item: any, lowercasedQuery: string) => {
        for (const path of queryableKeyPaths) {
          if (
            _.get(item, path)?.toLocaleLowerCase().includes(lowercasedQuery)
          ) {
            return true;
          }
        }
        return false;
      }
    );
    console.log(results);
    return results?.length !== 0;
  };

  const listControlSearch: ListControlSearch = {
    cta: cta,
    initialQuery: query,
    height: 'normal',
    width: 'full',
    callback: handleQuery,
    itemCount: currentItems?.length,
  };

  return {
    listControlSearch,
    query,
    currentItems,
    setOriginalItems,
    originalItems,
  };
};

export default useListControlsSearch;
