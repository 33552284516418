const useUrlParams = () => {
  const setUrlParam = (key: string, value: string) => {
    var url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState(null, 'Filter', url);
  };

  const removeUrlParam = (key: string) => {
    var url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.replaceState(null, 'Filter', url);
  };

  const getUrlParam = (key: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(key)) {
      return urlParams.get(key);
    } else {
      return null;
    }
  };

  const getUrlParamBool = (key: string) => {
    return getUrlParam(key) === 'true';
  };

  return {
    setUrlParam,
    removeUrlParam,
    getUrlParam,
    getUrlParamBool,
  };
};

export default useUrlParams;
