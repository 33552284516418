import './toggledsettingswitches.css';
import { useTranslation } from 'react-i18next';
import useLocalStorageSettings from '../../../hooks/useLocalStorageSettings';
import { useState } from 'react';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';

interface ToggledSettingSwitchesProps {
  settingsIdentifiers: string[];
}

const ToggledSettingSwitches: React.FC<ToggledSettingSwitchesProps> = ({
  settingsIdentifiers,
}) => {
  return (
    <div className={'toggledSettingSwitches'}>
      {settingsIdentifiers.map((identifier) => {
        return (
          <ToggledSettingSwitch
            key={identifier}
            settingsIdentifier={identifier}
          />
        );
      })}
    </div>
  );
};

export default ToggledSettingSwitches;

interface ToggledSettingSwitchProps {
  settingsIdentifier: string;
}

const ToggledSettingSwitch: React.FC<ToggledSettingSwitchProps> = ({
  settingsIdentifier,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.account.interface',
  });
  const { getToggledSetting, setToggledSetting } = useLocalStorageSettings();
  const [setting, setSetting] = useState(getToggledSetting(settingsIdentifier));
  return (
    <div className={'toggledSettingSwitch'}>
      <ToggleSwitch
        label={t(settingsIdentifier)}
        toggled={setting}
        toggle={() => {
          setSetting(!setting);
          setToggledSetting(settingsIdentifier, !setting);
        }}
      />
    </div>
  );
};
