import './media.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  AssetFolderResponse,
  AssetResponse,
  CreateProductAssetRequest,
  ProductAssetResponse,
  UpdateProductAssetsRequest,
} from '../../../api/petcloudapi/api';
import { useAuthorization } from '../../../contexts/auth/Authorization';
import Button from '../../../elements/button/Button';
import { CardTitle, TabConfig } from '../../../elements/card/Card';
import Dropzone from '../../../elements/dropzone/Dropzone';
import FileBrowser from '../../../elements/filebrowser/FileBrowser';
import {
  LoadingContainer,
  MinimalLoading,
} from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import { useErrorHandler, Error } from '../../../contexts/errorhandler/ErrorHandler';
import ErrorCheck from '../../../elements/errorcheck/ErrorCheck';
import ProductMediaItem from '../../../elements/productmediaitem/ProductMediaItem';
import _ from 'lodash';
import usePersistInCookies from '../../../hooks/useStorageData';
import MediaManager from './mediamanager/MediaManager';

interface MediaProps {
  productId?: string;
  productVersionId?: string;
  coverId?: string | null;
  updateCoverId?: (newCoverId: string | undefined) => void;
  inheritedContent?: ProductAssetResponse[] | null;
  allowGallery?: boolean;
  inlineCreationCallback?: (assets: ProductAssetResponse[]) => void;
  readonly?: boolean;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  errors?: Error[] | null;
  errorCallback?: () => void;
  updateProductViewTabs?: (
    array: { tabKey: string; safe: boolean; error?: boolean }[],
    tabArray?: TabConfig[],
    errorOverrides?: Error[]
  ) => void;
  removeErrors: (keys: string[]) => Error[];
  toggleProductSavingHelpOverlay?: () => void;
}

const Media: React.FC<MediaProps> = ({
  productId,
  productVersionId,
  coverId,
  updateCoverId,
  inheritedContent,
  allowGallery,
  inlineCreationCallback,
  readonly,
  queueCriticalAction,
  killCriticalAction,
  errors,
  errorCallback,
  updateProductViewTabs,
  removeErrors,
  toggleProductSavingHelpOverlay,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const productAssetsApi = api.productAssetsApi();
  const assetFoldersApi = api.assetFoldersApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();

  const [productAssets, setProductAssets] = useState<
    ProductAssetResponse[] | null
  >(null);
  const [originalProductAssets, setOriginalProductAssets] = useState<
    ProductAssetResponse[] | null
  >(null);
  const [assetPopup, setAssetPopup] = useState(false);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [selectedProductAssets, setSelectedProductAssets] = useState<
    ProductAssetResponse[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInheritanceBroken, setIsInheritanceBroken] = useState(false);
  const { authorizations: userAuthorizations } = useAuthorization();
  const { persistObject, getPersistedObject } = usePersistInCookies();

  useEffect(() => {
    getAssetFolders();
  }, []);

  useEffect(() => {
    getProductAssets();
  }, [productVersionId]);

  useEffect(() => {
    if (inlineCreationCallback && productAssets) {
      inlineCreationCallback(productAssets);
      persistObject(productAssets, 'new_product_assets');
    }
    if (productAssets && productAssets.length > 0) {
      setIsInheritanceBroken(true);
    }
    if (
      productAssets &&
      originalProductAssets &&
      productAssets !== originalProductAssets &&
      updateProductViewTabs
    ) {
      updateProductViewTabs([{ tabKey: 'media', safe: false }]);
    }
  }, [productAssets]);

  const reIndexByPosition = (array: ProductAssetResponse[]) => {
    return _.sortBy(array, ['position']);
  };

  const validateCoverId = (assets: ProductAssetResponse[] | null) => {
    if (assets && updateCoverId) {
      if (assets.length === 0) {
        updateCoverId(undefined);
      } else if (assets[0].id !== coverId) {
        updateCoverId(assets[0].id);
      }
    }
  };

  const getProductAssets = (shouldValidateCover?: boolean) => {
    if (productId) {
      setOriginalProductAssets(null);
      productAssetsApi
        .productAssetsGetProductAssets(productId, undefined, productVersionId)
        .then((response) => {
          console.log(response);
          const reIndexed = reIndexByPosition(response.data);
          setProductAssets(reIndexed);
          setOriginalProductAssets(reIndexed);
          if (shouldValidateCover) {
            validateCoverId(reIndexed);
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      const persisted = getPersistedObject('new_product_assets');
      setProductAssets(persisted ?? []);
    }
  };

  const getAssetFolders = () => {
    if (!readonly) {
      assetFoldersApi
        .assetFoldersGetAssetFolders()
        .then((response) => {
          console.log(response);
          setAssetFolders(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, undefined, true);
        });
    }
  };

  const isInherited = () => {
    return inheritedContent !== undefined
      ? !!(productAssets && !isInheritanceBroken)
      : undefined;
  };

  const selectProductAsset = (productAssetId: string) => {
    if (productAssets) {
      const assetToSelect = productAssets.find((a) => a.id === productAssetId);
      if (assetToSelect) {
        if (!selectedProductAssets.includes(assetToSelect)) {
          setSelectedProductAssets([...selectedProductAssets, assetToSelect]);
        } else {
          const update = [...selectedProductAssets];
          const i = update.indexOf(assetToSelect);
          update.splice(i, 1);
          setSelectedProductAssets(update);
        }
      }
    }
  };

  const selectAllProductAssets = () => {
    if (productAssets) {
      setSelectedProductAssets([...productAssets]);
    }
  };

  const deleteProductAsset = (productAssetId: string) => {
    if (productAssets) {
      const update = [...productAssets];
      const selectionUpdate = [...selectedProductAssets];
      const i = update.findIndex((a) => a.id === productAssetId);
      const j = selectionUpdate.findIndex((a) => a.id === productAssetId);
      update.splice(i, 1);
      selectionUpdate.splice(j, 1);
      setProductAssets(update);
      setSelectedProductAssets(selectionUpdate);
    }
  };

  const deleteSelectedProductAssets = (ids: string[]) => {
    if (productAssets) {
      const update = [...productAssets];
      ids.forEach((id) => {
        const index = update.findIndex((a) => a.id === id);
        update.splice(index, 1);
      });
      setProductAssets(update);
      setSelectedProductAssets([]);
    }
  };

  const addAssets = (responses: AssetResponse[]) => {
    if (productAssets && productId) {
      const createProductAssetsRequests: CreateProductAssetRequest[] =
        responses.map((response, i) => {
          return {
            position: productAssets.length + i,
            assetId: {
              'de-DE': response.id,
              'en-GB': response.id,
            },
            description: null,
            alternative: null,
            productId: productId,
          };
        });
      submit(productId, {
        createProductAssetRequests: createProductAssetsRequests,
        updateProductAssetRequests: getUpdateProductAssetRequests(
          productId,
          productAssets
        ),
      });
    }
  };

  const getUpdateProductAssetRequests = (
    productId: string,
    assets: ProductAssetResponse[]
  ) => {
    return assets.map((asset) => {
      return {
        position: asset.position,
        assetId: {
          'de-DE': asset.asset['de-DE']?.id,
          'en-GB': asset.asset['en-GB']?.id,
        },
        productId: productId,
        id: asset.id,
      };
    });
  };

  const updateProductAssets = () => {
    if (productAssets && productId) {
      submit(productId, {
        createProductAssetRequests: [],
        updateProductAssetRequests: getUpdateProductAssetRequests(
          productId,
          productAssets
        ),
      });
    }
  };

  const submit = (productId: string, request: UpdateProductAssetsRequest) => {
    setIsSubmitting(true);
    removeErrors(['ProductAssets', 'CoverId']);
    if (queueCriticalAction) {
      queueCriticalAction('updating_productAssets');
    }
    productsApi
      .productsUpdateProductAssets(productId, request)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('view.product.notifications.mediaUpdate_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setIsSubmitting(false);
        if (killCriticalAction) {
          killCriticalAction('updating_productAssets');
        }
        if (updateProductViewTabs) {
          updateProductViewTabs(
            [{ tabKey: 'media', safe: true, error: false }],
            undefined
          );
        }
        if (toggleProductSavingHelpOverlay) {
          toggleProductSavingHelpOverlay();
        }
        getProductAssets(true);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
        if (killCriticalAction) {
          killCriticalAction('updating_productAssets');
        }
        if (errorCallback) {
          errorCallback();
        }
      });
  };

  const addAssetsInline = (responses: AssetResponse[]) => {
    if (productAssets) {
      const update: ProductAssetResponse[] = [...productAssets];
      responses.forEach((response, i) => {
        update.push({
          id: (productAssets.length + i).toString(),
          position: productAssets.length + i,
          productId: '',
          createdAt: '',
          asset: {
            'de-DE': response,
            'en-GB': response,
          },
          description: {
            'de-DE': '',
            'en-GB': '',
          },
          alternative: {
            'de-DE': '',
            'en-GB': '',
          },
        });
      });
      setProductAssets([...update]);
      if (inlineCreationCallback) {
        inlineCreationCallback([...update]);
      }
    }
  };

  const breakInheritance = () => {
    if (inheritedContent && productId && productAssets) {
      const createProductAssetsRequests: CreateProductAssetRequest[] =
        inheritedContent.map((response, i) => {
          return {
            position: productAssets.length + i,
            assetId: {
              'de-DE': response.asset['de-DE']?.id,
              'en-GB': response.asset['en-GB']?.id,
            },
            description: response.description,
            alternative: response.alternative,
            productId: productId,
          };
        });
      submit(productId, {
        createProductAssetRequests: createProductAssetsRequests,
        updateProductAssetRequests: getUpdateProductAssetRequests(
          productId,
          productAssets
        ),
      });
      setIsInheritanceBroken(true);
    }
  };

  const renderDropzone = () => {
    return (
      <div className="media-assets-dropzone">
        <Dropzone
          maxFiles={-1}
          callback={inlineCreationCallback ? addAssetsInline : addAssets}
          assetContext={'Product'}
          hasPermission={userAuthorizations?.includes('product_assets:create')}
        />
      </div>
    );
  };

  if (productAssets) {
    return (
      <div className="media">
        <CardTitle
          title={t('view.product.media.title')}
          inherited={isInherited()}
          knowledgeBaseItems={[
            {
              title: t(
                'knowledgebase.product.tips.updatingMediaPositions.title'
              ),
              text: t('knowledgebase.product.tips.updatingMediaPositions.text'),
              mediaUri: t(
                'knowledgebase.product.tips.updatingMediaPositions.media'
              ),
            },
          ]}
          hasBottomMargin
        />
        {allowGallery &&
        (!inheritedContent || isInheritanceBroken) &&
        userAuthorizations?.includes('product_assets:edit') &&
        !readonly ? (
          <div className="card-actions">
            {assetFolders ? (
              <div className="card-action" onClick={() => setAssetPopup(true)}>
                {t('components.media.chooseFromAssets')}
              </div>
            ) : (
              <div className="media-galleryLoader">
                <div className="media-galleryLoader-title">
                  {t('components.media.galleryLoading')}
                </div>
                <MinimalLoading />
              </div>
            )}
          </div>
        ) : null}
        <ErrorCheck
          errors={errors}
          checkFor={['ProductAssets']}
          errorCallback={errorCallback}
        />
        <ErrorCheck
          errors={errors}
          checkFor={['CoverId']}
          errorCallback={errorCallback}
        />
        {productAssets.length > 0 ? (
          <>
            <MediaManager
              assets={productAssets}
              selectAsset={!readonly ? selectProductAsset : undefined}
              deleteAsset={!readonly ? deleteProductAsset : undefined}
              selectedAssets={selectedProductAssets}
              selectAllAssets={selectAllProductAssets}
              deleteSelected={deleteSelectedProductAssets}
              addAssetsCallback={
                userAuthorizations?.includes('product_assets:create') &&
                !readonly
                  ? inlineCreationCallback
                    ? addAssetsInline
                    : addAssets
                  : undefined
              }
              sortAssetsCallback={
                userAuthorizations?.includes('product_assets:edit')
                  ? setProductAssets
                  : undefined
              }
            />
            <div id={'testMediaId'} className="media-hint">
              {t('view.product.media.hint')}
            </div>
          </>
        ) : inheritedContent && !isInheritanceBroken ? (
          <div className="media-assets">
            {inheritedContent.map((asset, i) => {
              return (
                <ProductMediaItem
                  key={i + 'inherited'}
                  isCover={i === 0}
                  asset={asset}
                  inherited={true}
                />
              );
            })}
          </div>
        ) : !readonly ? (
          <div className="media-assets">{renderDropzone()}</div>
        ) : null}
        {assetFolders ? (
          <Popup
            toggled={assetPopup}
            width="60%"
            close={() => setAssetPopup(false)}
          >
            <FileBrowser
              assetFolders={assetFolders}
              allowUrlNavigation={false}
              allowAssetUpload={false}
              onChooseAssets={(assets: AssetResponse[]) => {
                if (inlineCreationCallback) {
                  addAssetsInline(assets);
                } else {
                  addAssets(assets);
                }
                setAssetPopup(false);
              }}
            />
          </Popup>
        ) : null}
        {productId && !readonly ? (
          <div className="global-cardActions">
            {inheritedContent && !isInheritanceBroken ? (
              <Button
                cta={t('view.product.media.break_inheritance')}
                look={'secondary'}
                width="minimal"
                action={breakInheritance}
              />
            ) : (
              <Button
                cta={t('actions.save')}
                look="save"
                width="minimal"
                action={updateProductAssets}
                isLoading={isSubmitting}
                active={productAssets !== originalProductAssets}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Media;
