import './button.css';
import { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../hooks/useNotifications';
import Hoverable from '../hoverable/Hoverable';
import { HintBox } from '../hint/Hint';
import { ReactComponent as IconArrow } from '../../../assets/icon/arrow_dropdown.svg';
import ActionsPopout from '../itemactions/actionspopout/ActionsPopout';
import { ItemAction } from '../itemactions/ItemActions';

export type ButtonLook =
  | 'primary'
  | 'save'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-filled'
  | 'danger'
  | 'secondary-danger'
  | 'blue';

export interface ButtonProps {
  cta?: string;
  margin?: 'left' | 'right' | 'both';
  look?: ButtonLook;
  type?: 'text' | 'icon' | 'icon-text';
  status?: 'active' | 'inactive';
  action?: (e: MouseEvent<HTMLDivElement>) => void;
  width?: 'full' | 'minimal' | 'tiny';
  isLoading?: boolean;
  active?: boolean;
  helperCSSClass?: string;
  hasPermission?: boolean;
  inactiveMessage?: string;
  subActions?: ItemAction[];
}

const Button: React.FC<ButtonProps> = ({
  cta,
  margin,
  look,
  type,
  children,
  action,
  width,
  isLoading,
  active,
  helperCSSClass,
  hasPermission,
  inactiveMessage,
  subActions,
}) => {
  const { t } = useTranslation();
  const { pushNotification } = useNotifications();

  const [subActionsMenu, setSubActionsMenu] = useState(false);
  const subActionMenuRef = useRef(null);

  const click = (e: MouseEvent<HTMLDivElement>) => {
    console.log('click');
    e.preventDefault();
    e.stopPropagation();
    if (action) {
      if (hasPermission !== undefined) {
        if (hasPermission) {
          action(e);
        } else {
          pushNotification(t('errors.no_authorization_action'), 'warning');
        }
      } else {
        action(e);
      }
    }
  };

  const getStyle = () => {
    const styles: any = {};
    if (margin) {
      styles['margin'] =
        margin === 'left'
          ? '0 0 0 10px'
          : margin === 'right'
          ? '0 10px 0 0'
          : margin === 'both'
          ? '0 10px 0 10px'
          : '0';
    }
    if (subActions) {
      styles['borderRadius'] = '6px 0 0 6px';
    }
    return styles;
  };

  const classes = `button button-${look} ${
    active === false ? 'button-inactive' : 'button-active'
  } ${isLoading ? 'button-spinnerBackground' : ''} ${
    type === 'icon' ? 'buttonWithIcon' : ''
  } button-${width} ${helperCSSClass}`;
  return (
    <Hoverable
      onHoverNode={
        <HintBox
          paragraphs={inactiveMessage ? [inactiveMessage] : undefined}
          isToolTip
        />
      }
      disabled={active === true || !inactiveMessage}
    >
      <div className={'button-wrapper'}>
        <div
          className={classes}
          style={getStyle()}
          onClick={active === false ? undefined : click}
        >
          {isLoading ? (
            <Spinner />
          ) : type === 'icon' ? (
            children
          ) : type === 'icon-text' ? (
            <>
              {children}
              <div className="button-icon-text-cta">{cta}</div>
            </>
          ) : (
            cta
          )}
        </div>
        {subActions ? (
          <div
            className={`button-subAction button-subAction-${look}`}
            onClick={() => setSubActionsMenu(true)}
            ref={subActionMenuRef}
          >
            <IconArrow
              className={'button-subAction-icon'}
              fill={
                look !== 'secondary' && look !== 'secondary-danger'
                  ? 'white'
                  : 'var(--color-text_primary)'
              }
            />
            {subActionsMenu && subActionMenuRef ? (
              <ActionsPopout
                show={true}
                hide={() => setSubActionsMenu(false)}
                actions={subActions}
                anchorRef={subActionMenuRef}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </Hoverable>
  );
};

export default Button;

const Spinner = () => {
  return <div className="button-spinner"></div>;
};

// <ClickOutsideContainer
//   onClickOutside={() => setSubActionsMenu(false)}
//   fixedViewportAwareElementRef={subActionMenuRef}
//   offsetY={40}
//   offsetX={-32}
// >
//   <div className={'button-subAction-menu'}>
//     {subActions.map((a) => {
//       return (
//         <div
//           className={`button-subAction-menu-action button-subAction-menu-action__${a.look}`}
//           onClick={(e) => a.action(e)}
//         >
//           {a.cta}
//         </div>
//       );
//     })}
//   </div>
// </ClickOutsideContainer>
