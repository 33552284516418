import './clickoutsidecontainer.css';
import { RefObject } from 'react';

interface ClickOutsideContainerProps {
  onClickOutside: () => void;
  fixedViewportAwareElementRef?: RefObject<HTMLDivElement>;
  offsetY?: number;
  offsetX?: number;
}

const ClickOutsideContainer: React.FC<ClickOutsideContainerProps> = ({
  onClickOutside,
  children,
  fixedViewportAwareElementRef,
  offsetY,
  offsetX,
}) => {
  console.log(fixedViewportAwareElementRef?.current?.getBoundingClientRect().x);
  const rect = fixedViewportAwareElementRef?.current?.getBoundingClientRect();
  const x = rect?.x ?? 0;
  const y = rect?.y ?? 0;

  const getStyles = () => {
    const isRightHalf = x > window.innerWidth / 2;
    const isBottomHalf = y > window.innerHeight / 2;

    const styles: any = {};
    styles['right'] = isRightHalf ? 0 : 'auto';
    styles['left'] = isRightHalf ? 'auto' : 0;
    styles['bottom'] = isBottomHalf ? 0 : 'auto';
    styles['top'] = isBottomHalf ? 'auto' : 0;

    if (offsetY && styles.top !== undefined) {
      styles.top = `${styles.top + offsetY}px`;
    }
    if (offsetX && styles.left !== undefined) {
      styles.left = `${styles.left + offsetX}px`;
    }
    if (offsetX && styles.right !== undefined) {
      styles.right = `${styles.right + offsetX}px`;
    }

    if (isRightHalf) {
      styles.right = styles.right - (rect?.width ?? 0);
    }
    return styles;
  };

  return (
    <div>
      <div
        className={'clickOutsideContainer'}
        onClick={(e) => {
          e.stopPropagation();
          onClickOutside();
        }}
      ></div>
      {fixedViewportAwareElementRef ? (
        <div
          className={'clickOutsideContainer-fixedViewportAwareElement-wrapper'}
          style={{ top: y, left: x }}
        >
          <div
            className={'clickOutsideContainer-fixedViewportAwareElement'}
            style={getStyles()}
          >
            {children}
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default ClickOutsideContainer;
