import './accountholder.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import { AdyenWizardStep } from '../AdyenWizard';
import {
  AdyenAccountHolderResponse,
  CreateAdyenAccountHolderRequest,
} from '../../../../../api/petcloudapi/api';
import MenuList, {
  MenuListItem,
} from '../../../../../elements/menulist/MenuList';
import { useState } from 'react';
import useNotifications from '../../../../../hooks/useNotifications';
import Popup from '../../../../../elements/popup/Popup';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import Button from '../../../../../elements/button/Button';

const emptyAccountHolderRequest = (
  legalEntityName: string
): CreateAdyenAccountHolderRequest => ({
  legalEntityId: '',
  manufacturerId: '',
  name: `${legalEntityName} - Account Holder`,
  description: `${legalEntityName} - Account Holder`,
  reference: `${legalEntityName} - Account Holder`,
});

interface AccountHolderProps {
  setStep: (step: AdyenWizardStep) => void;
  legalEntityId: string;
  legalEntityName: string;
  manufacturerId: string;
  fetchedAccountHolders: AdyenAccountHolderResponse[] | null;
  setAccountHolderId: (id: string) => void;
}

const AccountHolder: React.FC<AccountHolderProps> = ({
  setStep,
  legalEntityId,
  legalEntityName,
  manufacturerId,
  fetchedAccountHolders,
  setAccountHolderId,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard.accountHolder',
  });
  const api = usePetCloudApi();
  const adyenAccountHolderApi = api.adyenAccountHolderApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [accountHolders, setAccountHolders] = useState<
    AdyenAccountHolderResponse[]
  >(fetchedAccountHolders ?? []);
  const [newAccountHolder, setNewAccountHolder] =
    useState<CreateAdyenAccountHolderRequest | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAccountHolderId, setSelectedAccountHolderId] = useState<
    null | string
  >(null);

  const createTransferInstrument = () => {
    if (newAccountHolder) {
      setIsSubmitting(true);
      adyenAccountHolderApi
        .adyenAccountHolderCreateAccountHolder({
          ...newAccountHolder,
          legalEntityId: legalEntityId,
          manufacturerId: manufacturerId,
        })
        .then((response) => {
          console.log(response);

          // push to be rendered in legal entities list
          const updateL = fetchedAccountHolders ?? [];
          updateL.push(response.data);
          setAccountHolders(updateL);

          setIsSubmitting(false);
          setNewAccountHolder(null);
          pushNotification(t('notifications.create_successful'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const getMenuListItems = (): MenuListItem[] => {
    return accountHolders.map((holder) => {
      return {
        title: holder.name,
        toggled: selectedAccountHolderId === holder.accountHolderId,
        onToggle: () => {
          setSelectedAccountHolderId(holder.accountHolderId);
        },
      };
    });
  };

  const nextStep = () => {
    if (selectedAccountHolderId) {
      setStep(AdyenWizardStep.BalanceAccount);
      setAccountHolderId(selectedAccountHolderId);
    }
  };

  return (
    <div className={'accountHolder'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement global-textElement'}>{t('text')}</div>
      <MenuList
        items={getMenuListItems()}
        addButton={{
          cta: t('cta_add'),
          look: 'primary',
          action: () =>
            setNewAccountHolder(emptyAccountHolderRequest(legalEntityName)),
        }}
      />
      {newAccountHolder ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setNewAccountHolder(null)}
        >
          <div className={'popup-title'}>{t('form.title')}</div>
          <div className={'global-textElement accountHolder-text'}>
            {t('form.text')}
          </div>
          <div className={'accountHolder-form'}>
            <FormBuilder
              contentObject={newAccountHolder}
              setContentObject={setNewAccountHolder}
              elements={[
                {
                  type: 'input',
                  title: t('form.name'),
                  objectPath: 'name',
                },
                {
                  type: 'input',
                  title: t('form.description'),
                  objectPath: 'description',
                },
                {
                  type: 'input',
                  title: t('form.reference'),
                  objectPath: 'reference',
                },
              ]}
            />
          </div>
          <div className={'global-cardActions'}>
            <Button
              cta={t('form.cta')}
              look={'save'}
              action={createTransferInstrument}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      ) : null}
      <div className={'global-cardActions global-cardActions-margin'}>
        <Button
          cta={t('cta_next')}
          action={nextStep}
          look={'secondary'}
          width={'minimal'}
          active={!!selectedAccountHolderId}
        />
      </div>
    </div>
  );
};

export default AccountHolder;
