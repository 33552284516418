import './categoryhierarchy.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../../assets/icon/arrow_dropdown.svg';
import { ReactComponent as IconStar } from '../../../assets/icon/star.svg';
import {
  AnimalSpeciesResponse,
  ProductCategoryResponse,
  ProductGroupResponse,
  UpdateProductCategoryRequest,
} from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Check } from '../../elements/selectors/Selectors';
import { ReactComponent as Plus } from '../../../assets/icon/plus.svg';
import Popup from '../../elements/popup/Popup';
import { CreateProductCategoryRequest } from '../../api/petcloudapi/api';
import Button from '../../elements/button/Button';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../hooks/useNotifications';
import { ReactComponent as IconPencil } from '../../../assets/icon/pencil.svg';
import { LoadingContainer } from '../../elements/loading/Loading';
import CategoryEdit from './categoryedit/CategoryEdit';
import Badge from '../../elements/badge/Badge';

const createCategoryRequest = {
  name: {
    'de-DE': null,
    'en-GB': null,
  },
  identifier: '',
  description: {
    'de-DE': null,
    'en-GB': null,
  },
  enabled: true,
  parentId: null,
};

interface CategoryHierarchyProps {
  availableProductCategories: ProductCategoryResponse[];
  currentProductCategories?: ProductCategoryResponse[];
  currentProductCategoriesIds?: string[];
  updateCurrentProductCategories?: (
    categories: ProductCategoryResponse[]
  ) => void;
  allowAdminFeatures?: boolean;
  getCategories?: () => void;
  onlyAllowSingleSelection?: boolean;
  selectedLocale?: TranslatedStringIndex;
  mainCategoryId?: string | null;
  setMainCategoryId?: (categoryId: string | null) => void;
}

const CategoryHierarchy: React.FC<CategoryHierarchyProps> = ({
  availableProductCategories,
  currentProductCategories,
  currentProductCategoriesIds,
  updateCurrentProductCategories,
  allowAdminFeatures,
  getCategories,
  onlyAllowSingleSelection,
  selectedLocale,
  mainCategoryId,
  setMainCategoryId,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.categoryHierarchy',
  });
  const api = usePetCloudApi();
  const productCategoriesApi = api.productCategoriesApi();
  const productGroupsApi = api.productGroupsApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [browsedCategoriesIds, setBrowsedCategoriesIds] = useState<string[]>(
    []
  );
  const [newCategoryPopup, setNewCategoryPopup] = useState(false);
  const [newCategoryRequest, setNewCategoryRequest] =
    useState<CreateProductCategoryRequest>({ ...createCategoryRequest });
  const [newCategoryEditKey, setNewCategoryEditKey] = useState(Math.random());
  const [categoryToEdit, setCategoryToEdit] = useState<
    UpdateProductCategoryRequest | 'LOADING' | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableProductGroups, setAvailableProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [availableAnimalSpecies, setAvailableAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);

  useEffect(() => {
    if (allowAdminFeatures) {
      getAvailableProductGroups();
      getAvailableAnimalSpecies();
    }
  }, []);

  const getAvailableProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        setAvailableProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAvailableAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAvailableAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getResponsesFromIds = (
    responses: any[],
    ids: string[] | null | undefined
  ) => {
    if (ids && ids.length > 0) {
      return responses.filter((r) => ids.includes(r.id));
    } else {
      return [];
    }
  };

  const browseCategory = (category: ProductCategoryResponse) => {
    const update = browsedCategoriesIds;
    if (update.includes(category.id)) {
      const i = update.indexOf(category.id);
      update.splice(i, 1);
    } else {
      update.push(category.id);
    }
    setBrowsedCategoriesIds([...update]);
  };

  const doesCategoryContainSelectedChild = (
    category: ProductCategoryResponse
  ) => {
    const isChildSelected = (children: ProductCategoryResponse[]): boolean => {
      return children.some(
        (child) =>
          currentProductCategories?.find((c) => c.id === child.id) ||
          currentProductCategoriesIds?.includes(child.id) ||
          (child.children && isChildSelected(child.children))
      );
    };
    return category.children && isChildSelected(category.children);
  };

  const findParentCategory = (
    category: ProductCategoryResponse,
    categories: ProductCategoryResponse[]
  ): ProductCategoryResponse | null => {
    for (let cat of categories) {
      if (cat.children?.find((child) => child.id === category.id)) {
        return cat;
      }
      if (cat.children) {
        let result = findParentCategory(category, cat.children);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const getCategoriesFromIds = (
    availableCategories: ProductCategoryResponse[],
    currentIds: string[],
    result: ProductCategoryResponse[] = []
  ): ProductCategoryResponse[] => {
    for (let cat of availableCategories) {
      if (currentIds.includes(cat.id)) {
        result.push(cat);
      }
      if (cat.children) {
        getCategoriesFromIds(cat.children, currentIds, result);
      }
    }
    return result;
  };

  const selectCategory = (category: ProductCategoryResponse) => {
    if (updateCurrentProductCategories) {
      let update: ProductCategoryResponse[];
      if (onlyAllowSingleSelection) {
        update = [];
      } else if (currentProductCategories) {
        update = [...currentProductCategories];
      } else if (currentProductCategoriesIds) {
        update = getCategoriesFromIds(
          availableProductCategories,
          currentProductCategoriesIds
        );
      } else {
        update = [];
      }

      let parentCategory = findParentCategory(
        category,
        availableProductCategories
      );
      while (parentCategory) {
        const categoryId = parentCategory?.id;
        const index = update.findIndex((cat) => cat.id === categoryId);
        if (index !== -1) {
          // Remove parent category from selected categories
          update.splice(index, 1);
        }
        // Find parent of current parent category
        parentCategory = findParentCategory(
          parentCategory,
          availableProductCategories
        );
      }

      const index = update.findIndex((c) => c.id === category.id);
      if (index > -1) {
        update.splice(index, 1);
        // if the mainCategory gets removed, reset the mainCategoryId to the first available category
        // if the last category gets removed, also remove the mainCategoryId
        if (setMainCategoryId) {
          if (update.length >= 1) {
            if (category.id === mainCategoryId) {
              setMainCategoryId(update[0].id);
            }
          } else {
            setMainCategoryId(null);
          }
        }
      } else {
        // if the first category gets added, also set the mainCategoryId
        if (update.length === 0 && setMainCategoryId) {
          setMainCategoryId(category.id);
        }
        update.push(category);
      }

      updateCurrentProductCategories([...update]);
    }
  };

  const submitNewCategory = () => {
    setIsSubmitting(true);
    productCategoriesApi
      .productCategoriesCreateProductCategory(newCategoryRequest)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.create_successful'));
        setIsSubmitting(false);
        if (getCategories) {
          getCategories();
        }
        setNewCategoryPopup(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const submitEditedCategory = () => {
    if (categoryToEdit && categoryToEdit !== 'LOADING') {
      setIsSubmitting(true);
      productCategoriesApi
        .productCategoriesUpdateProductCategory(
          (categoryToEdit as ProductCategoryResponse).id,
          categoryToEdit
        )
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.edit_successful'));
          setIsSubmitting(false);
          resetNewCategory();
          setCategoryToEdit(null);
          if (getCategories) {
            getCategories();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          errorHandler.addError(error.response);
        });
    }
  };

  const resetNewCategory = () => {
    setNewCategoryRequest({ ...createCategoryRequest });
    setNewCategoryEditKey(Math.random());
  };

  const getCategoryToEdit = (id: string) => {
    setCategoryToEdit('LOADING');
    productCategoriesApi
      .productCategoriesGetProductCategoryById(id)
      .then((response) => {
        console.log(response);
        setCategoryToEdit({
          ...response.data,
          productGroupIds: response.data.productGroups?.map((x) => x.id),
          animalSpeciesIds: response.data.animalSpecies?.map((x) => x.id),
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderHierarchy = (category: ProductCategoryResponse, key: number) => {
    const isBrowsed = browsedCategoriesIds.includes(category.id);
    const containsSelectedChild = doesCategoryContainSelectedChild(category);
    const isChecked =
      containsSelectedChild ||
      !!currentProductCategories?.find((c) => c.id === category.id) ||
      !!currentProductCategoriesIds?.includes(category.id);
    return (
      <div
        key={category.id}
        className="global-hierarchy-element"
        onClick={(e) => {
          e.stopPropagation();
          browseCategory(category);
        }}
      >
        <div className="global-hierarchy-element-item categoryhierarchy-element-item">
          {category.children && category.children.length > 0 ? (
            <Arrow
              className="global-hierarchy-element-item-arrow"
              fill={'var(--color-text_primary)'}
              style={{
                transform: isBrowsed ? 'rotate(0turn)' : 'rotate(0.75turn)',
              }}
            />
          ) : (
            <div className="global-hierarchy-element-item-arrow"></div>
          )}
          <div
            className="global-hierarchy-element-item-check"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Check
              checked={isChecked}
              disabled={containsSelectedChild ?? false}
              update={() => selectCategory(category)}
            />
          </div>
          <div className="global-hierarchy-element-item-title">
            {
              category.name[
                selectedLocale
                  ? selectedLocale
                  : (i18n.language as TranslatedStringIndex)
              ]
            }
          </div>
          {allowAdminFeatures && category.identifier ? (
            <div
              className={
                'global-monospaced-contrast categoryhierarchy-element-identifier'
              }
            >
              {category.identifier}
            </div>
          ) : null}
          {allowAdminFeatures ? (
            <div className={'global-hierarchy-element-item-badge'}>
              <Badge
                title={`${category.productGroups?.length ?? 0} Produktgruppen`}
                color={'var(--color-blue)'}
              />
              {category.productGroups && category.productGroups.length > 0 ? (
                <div
                  className={'global-hierarchy-element-item-badge-hoverPopup'}
                >
                  {category.productGroups.map((g) => (
                    <div>{g.name[i18n.language as TranslatedStringIndex]}</div>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className={'global-hierarchy-element-item-badge'}>
            <Badge
              title={t(`enabled_${category.enabled}`)}
              color={
                category.enabled
                  ? 'var(--color-success)'
                  : 'var(--color-danger)'
              }
            />
          </div>
          {mainCategoryId === category.id ? (
            <div className={'global-hierarchy-element-item-badge'}>
              <Badge title={t('mainCategory')} color={'var(--color-yellow)'} />
            </div>
          ) : null}
          <div className="categoryhierarchy-element-actions">
            {allowAdminFeatures ? (
              <>
                <Button
                  type={'icon'}
                  look={'secondary'}
                  width={'tiny'}
                  action={() => {
                    getCategoryToEdit(category.id);
                  }}
                  margin={'right'}
                >
                  <IconPencil
                    className="button-icon-tiny"
                    fill={'var(--color-text_primary)'}
                  />
                </Button>
                <Button
                  type={'icon'}
                  look={'secondary'}
                  width={'tiny'}
                  action={() => {
                    setNewCategoryPopup(true);
                    setNewCategoryRequest({
                      ...newCategoryRequest,
                      parentId: category.id,
                    });
                  }}
                >
                  <Plus
                    className="button-icon-tiny"
                    fill={'var(--color-text_primary)'}
                  />
                </Button>
              </>
            ) : null}
            {setMainCategoryId &&
            (!!currentProductCategories?.find((c) => c.id === category.id) ||
              !!currentProductCategoriesIds?.includes(category.id)) ? (
              <Button
                type={'icon'}
                width={'tiny'}
                look={'secondary'}
                action={() => setMainCategoryId(category.id)}
              >
                <IconStar
                  className={'button-icon button-icon-tiny'}
                  fill={'var(--color-yellow)'}
                />
              </Button>
            ) : null}
          </div>
        </div>
        {category.children ? (
          <div
            className="global-hierarchy-element-children"
            style={{
              display: isBrowsed ? 'block' : 'none',
            }}
          >
            {category.children.map((child, i) => {
              return renderHierarchy(child, i);
            })}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="categoryhierarchy">
      {availableProductCategories.map((category, i) => {
        return renderHierarchy(category, i);
      })}
      {allowAdminFeatures ? (
        <div className="categoryhierarchy-actions">
          <Button
            cta={t('adminActions.new')}
            width="minimal"
            look={'secondary'}
            action={() => {
              setNewCategoryPopup(true);
              setNewCategoryRequest({
                ...newCategoryRequest,
                parentId: null,
              });
            }}
          />
        </div>
      ) : null}
      <Popup
        width="50%"
        toggled={newCategoryPopup}
        close={() => setNewCategoryPopup(false)}
      >
        <div className="popup-title">{t('new.title')}</div>
        {availableAnimalSpecies && availableProductGroups ? (
          <CategoryEdit
            key={newCategoryEditKey}
            category={newCategoryRequest}
            setCategory={setNewCategoryRequest}
            productGroups={getResponsesFromIds(
              availableProductGroups,
              newCategoryRequest.productGroupIds
            )}
            availableProductGroups={availableProductGroups}
            animalSpecies={getResponsesFromIds(
              availableAnimalSpecies,
              newCategoryRequest.animalSpeciesIds
            )}
            availableAnimalSpecies={availableAnimalSpecies}
            isLeafCategory={true}
          />
        ) : (
          <LoadingContainer />
        )}
        <div className="global-cardActions-spaceBetween global-cardActions">
          <Button
            cta={t('new.reset')}
            width={'minimal'}
            look={'secondary'}
            action={resetNewCategory}
          />
          <Button
            cta={t('new.cta')}
            width="minimal"
            look="save"
            action={submitNewCategory}
            isLoading={isSubmitting}
          />
        </div>
      </Popup>
      {categoryToEdit ? (
        <Popup width="50%" toggled={true} close={() => setCategoryToEdit(null)}>
          <div className="popup-title">{t('edit.title')}</div>
          {categoryToEdit !== 'LOADING' ? (
            <>
              {availableAnimalSpecies && availableProductGroups ? (
                <CategoryEdit
                  key={(categoryToEdit as ProductCategoryResponse).id}
                  category={categoryToEdit}
                  productGroups={
                    (categoryToEdit as ProductCategoryResponse).productGroups
                  }
                  availableProductGroups={availableProductGroups}
                  animalSpecies={
                    (categoryToEdit as ProductCategoryResponse).animalSpecies
                  }
                  availableAnimalSpecies={availableAnimalSpecies}
                  setCategory={(
                    category:
                      | CreateProductCategoryRequest
                      | UpdateProductCategoryRequest
                  ) => {
                    const productGroups = availableProductGroups.filter((g) =>
                      category.productGroupIds?.includes(g.id)
                    );
                    const animalSpecies = availableAnimalSpecies.filter((s) =>
                      category.animalSpeciesIds?.includes(s.id)
                    );
                    setCategoryToEdit({
                      ...category,
                      productGroups: productGroups,
                      animalSpecies: animalSpecies,
                    } as ProductCategoryResponse);
                  }}
                  isLeafCategory={
                    !(categoryToEdit as ProductCategoryResponse).children ||
                    (categoryToEdit as ProductCategoryResponse).children
                      ?.length === 0
                  }
                />
              ) : (
                <LoadingContainer />
              )}
              <div className="global-cardActions">
                <Button
                  cta={t('edit.cta')}
                  width="minimal"
                  look="save"
                  action={submitEditedCategory}
                  isLoading={isSubmitting}
                />
              </div>
            </>
          ) : (
            <LoadingContainer />
          )}
        </Popup>
      ) : null}
    </div>
  );
};

export default CategoryHierarchy;
