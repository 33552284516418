import './bestsellersfetcher.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import {
  BestSellingProductsOrderBy,
  DailyBestSellingProductsResponse,
  SoldProductResponse,
} from '../../../../api/petcloudapi/api';
import { Dropdown } from '../../../../elements/selectors/Selectors';

interface BestSellersFetcherProps {
  dateRangeFrom: string;
  dateRangeTo: string;
  selectedManufacturerId?: string | null;
  onFetch: (
    responses: DailyBestSellingProductsResponse[] | SoldProductResponse[]
  ) => void;
  onOrder: (orderBy: BestSellingProductsOrderBy) => void;
  mode: 'daily' | 'range';
}

const BestSellersFetcher: React.FC<BestSellersFetcherProps> = ({
  dateRangeFrom,
  dateRangeTo,
  selectedManufacturerId,
  onFetch,
  onOrder,
  mode,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.widgets.dailyBestsellersFetcher',
  });
  const api = usePetCloudApi();
  const dashboardsApi = api.dashboardsApi();
  const errorHandler = useErrorHandler();

  const [bestsellers, setBestsellers] = useState<
    DailyBestSellingProductsResponse[] | SoldProductResponse[] | null
  >(null);
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [selectedOrderBy, setSelectedOrderBy] =
    useState<BestSellingProductsOrderBy>(BestSellingProductsOrderBy.Revenue);

  useEffect(() => {
    getDailyBestSellers();
  }, [
    selectedManufacturerId,
    dateRangeTo,
    dateRangeFrom,
    selectedLimit,
    selectedOrderBy,
  ]);

  useEffect(() => {
    if (bestsellers) {
      onFetch(bestsellers);
    }
  }, [bestsellers]);

  useEffect(() => {
    onOrder(selectedOrderBy);
  }, [selectedOrderBy]);

  const getDailyBestSellers = () => {
    if (mode === 'daily') {
      dashboardsApi
        .dashboardsGetDailyBestSellingProducts(
          selectedManufacturerId && selectedManufacturerId !== 'all'
            ? selectedManufacturerId
            : undefined,
          dateRangeFrom,
          dateRangeTo,
          selectedLimit,
          selectedOrderBy
        )
        .then((response) => {
          console.log(response.data);
          setBestsellers(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      dashboardsApi
        .dashboardsGetBestSellingProducts(
          selectedManufacturerId && selectedManufacturerId !== 'all'
            ? selectedManufacturerId
            : undefined,
          dateRangeFrom,
          dateRangeTo,
          selectedLimit,
          selectedOrderBy
        )
        .then((response) => {
          console.log(response.data);
          setBestsellers(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  return (
    <div className={'bestsellersFetcher'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-inputInline'}>
          <Dropdown
            title={
              mode === 'daily' ? t('productsLimitPerDay') : t('productsLimit')
            }
            options={['10', '20', '50', '1000']}
            selected={selectedLimit.toString()}
            update={(e) => {
              const l = parseInt(e.currentTarget.selectedOptions[0].value);
              setSelectedLimit(l);
            }}
            disableAutoOptionSorting
          />
        </div>
        <div className={'global-inputGroup-inputInline'}>
          <Dropdown
            title={t('orderBy.title')}
            optionObjects={[
              {
                id: BestSellingProductsOrderBy.Revenue,
                name: t('orderBy.Revenue'),
              },
              {
                id: BestSellingProductsOrderBy.Quantity,
                name: t('orderBy.Quantity'),
              },
            ]}
            selected={t(`orderBy.${selectedOrderBy}`)}
            update={(e) => {
              const x =
                e.currentTarget.selectedOptions[0].getAttribute('data-value');
              if (x) {
                setSelectedOrderBy(x as BestSellingProductsOrderBy);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BestSellersFetcher;
