import {
  ColumnHeaderMapping,
  ColumnHeaderMappingError,
} from './useDefaultMappings';
import { useTranslation } from 'react-i18next';
import useLanguages from '../../../../../hooks/useLanguages';

const useValidationFunctions = () => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'view.productBulkEditor.productImporter.columnHeaderConfig.validationFunctionMessages',
  });
  const { availableLanguages } = useLanguages();

  const isTranslationCorrectlyMapped = (
    mapping: ColumnHeaderMapping
  ): ColumnHeaderMappingError[] | undefined => {
    const opts = mapping.options;
    if (opts) {
      if (opts.languageUniform) {
        return undefined;
      } else {
        if (opts.languageMap) {
          console.log(opts.languageMap, availableLanguages);
          if (availableLanguages.every((lang) => !!opts.languageMap[lang])) {
            return undefined;
          } else {
            return [
              {
                identifier: 'languageMap',
                messages: [t('languageMap_incomplete')],
              },
            ];
          }
        } else {
          console.log('language map is missing for this translation config');
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  };

  return {
    isTranslationCorrectlyMapped,
  };
};

export default useValidationFunctions;
