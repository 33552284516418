import { useTranslation } from 'react-i18next';
import { FiegePurchaseOrderResponse } from '../../../api/petcloudapi/api';
import DatePicker from '../../../elements/datepicker/DatePicker';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';
import './purchaseorderbrief.css';

interface PurchaseOrderBriefProps {
  purchaseOrder: FiegePurchaseOrderResponse;
  setPurchaseOrder: (purchaseOrder: FiegePurchaseOrderResponse) => void;
}

const PurchaseOrderBrief: React.FC<PurchaseOrderBriefProps> = ({
  purchaseOrder,
  setPurchaseOrder,
}) => {
  const { t } = useTranslation();

  const renderDatePicker = (date: string) => {
    return (
      <div className="purchaseorderbrief-datepicker">
        <DatePicker
          selected={new Date(date)}
          onChange={(date) => {
            if (date) {
              setPurchaseOrder({
                ...purchaseOrder,
                deliveryDate: date.toISOString().split('T')[0],
              });
            }
          }}
          dateFormat="yyyy-MM-dd"
        />
      </div>
    );
  };

  return (
    <div className="purchaseorderbrief">
      <TitleValueList
        entries={[
          {
            title: t('view.purchaseorder.information.orderNumber'),
            value: purchaseOrder.orderNumber.toString(),
          },
          {
            title: t('view.purchaseorder.information.orderDate'),
            value: purchaseOrder.orderDate,
          },
          {
            title: t('view.purchaseorder.information.deliveryDate'),
            value: purchaseOrder.deliveryDate,
            renderMethod:
              purchaseOrder.state === 'Open' ? renderDatePicker : undefined,
          },
        ]}
      />
    </div>
  );
};

export default PurchaseOrderBrief;
