import './mediaeditor.css';
import {
  AssetFolderResponse,
  AssetResponse,
  ProductAssetResponse,
} from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import ProductMediaItem from '../../../../elements/productmediaitem/ProductMediaItem';
import { arrayMoveImmutable } from 'array-move';
import { useState } from 'react';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import Button from '../../../../elements/button/Button';
import Popup from '../../../../elements/popup/Popup';
import FileBrowser from '../../../../elements/filebrowser/FileBrowser';

interface MediaEditorProps {
  assets: ProductAssetResponse[];
  updateCallback: EditorUpdateCallback;
  assetFolders: AssetFolderResponse[];
}

const MediaEditor: React.FC<MediaEditorProps> = ({
  assets,
  updateCallback,
  assetFolders,
}) => {
  const { t } = useTranslation();
  const [productAssets, setProductAssets] = useState(assets ?? []);
  const [selectedProductAssets, setSelectedProductAssets] = useState<
    ProductAssetResponse[]
  >([]);
  const [gallery, setGallery] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (productAssets && over) {
      if (active.id !== over.id) {
        const oldPosition = productAssets.find(
          (a) => a?.id === active.id
        )?.position;
        const newPosition = productAssets.find(
          (a) => a?.id === over.id
        )?.position;
        if (oldPosition !== undefined && newPosition !== undefined) {
          const update = arrayMoveImmutable(
            productAssets,
            oldPosition,
            newPosition
          );
          setProductAssets(rePositionByIndex(update));
        }
      }
    }
  };

  const rePositionByIndex = (array: ProductAssetResponse[]) => {
    return array.map((element, i) => {
      return {
        ...element,
        position: i,
      };
    });
  };

  const selectAllProductAssets = () => {
    setSelectedProductAssets(productAssets);
  };

  const deleteSelectedProductAssets = () => {
    const update = [...productAssets];
    selectedProductAssets.forEach((s) => {
      const i = update.findIndex((a) => a.id === s.id);
      if (i !== -1) {
        update.splice(i, 1);
      }
    });
    setProductAssets(update);
  };

  const selectProductAsset = (assetId: string) => {
    const update = [...selectedProductAssets];
    const i = update.findIndex((a) => a.id === assetId);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      const a = productAssets.find((asset) => asset.id === assetId);
      if (a) {
        update.push(a);
      }
    }
    setSelectedProductAssets(update);
  };

  const deleteProductAsset = (assetId: string) => {
    const update = [...productAssets];
    const i = update.findIndex((a) => a.id === assetId);
    if (i !== -1) {
      update.splice(i, 1);
    }
    setProductAssets(update);
  };

  const addAssets = (responses: AssetResponse[]) => {
    const update = [...productAssets];
    responses.forEach((response, i) => {
      update.push({
        id: Math.random().toString(),
        position: productAssets.length + i,
        asset: {
          'de-DE': response,
          'en-GB': response,
        },
        description: {
          'de-DE': '',
          'en-GB': '',
        },
        alternative: {
          'de-DE': '',
          'en-GB': '',
        },
        createdAt: 'createdAt',
        productId: 'productId',
      });
    });
    setProductAssets(update);
  };

  return (
    <div className={'jtce-media-editor'}>
      <div className={'jtce-title'}>{t('view.product.media.title')}</div>
      {selectedProductAssets.length > 0 ? (
        <div className="media-actions">
          <div className="media-action">
            <Button
              cta={t('actions.select_all')}
              look="tertiary"
              width="minimal"
              action={selectAllProductAssets}
            />
          </div>
          <div className="media-action">
            <Button
              cta={t('actions.delete_selected')}
              look="secondary-danger"
              width="minimal"
              action={deleteSelectedProductAssets}
            />
          </div>
        </div>
      ) : null}
      <div className={'jtce-media-editor-assets'}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={productAssets} strategy={rectSortingStrategy}>
            <div className="media-assets">
              {productAssets.map((asset, i) => {
                return (
                  <ProductMediaItem
                    key={i}
                    isCover={i === 0}
                    asset={asset}
                    onSelect={() => selectProductAsset(asset.id)}
                    onDelete={() => deleteProductAsset(asset.id)}
                    isSelected={selectedProductAssets.includes(asset)}
                  />
                );
              })}
              <div className="media-assets-dropzone">
                <Dropzone
                  maxFiles={-1}
                  callback={addAssets}
                  assetContext={'Product'}
                />
              </div>
            </div>
          </SortableContext>
        </DndContext>
      </div>
      <div id={'testMediaId'} className="media-hint">
        {t('view.product.media.hint')}
      </div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: productAssets }]);
            } catch {}
          }}
          buttons={[
            {
              cta: t('components.media.chooseFromAssets'),
              action: () => setGallery(true),
            },
          ]}
        />
      ) : null}
      <Popup toggled={gallery} width="60%" close={() => setGallery(false)}>
        <FileBrowser
          assetFolders={assetFolders}
          allowUrlNavigation={false}
          allowAssetUpload={false}
          onChooseAssets={(assets: AssetResponse[]) => {
            addAssets(assets);
            setGallery(false);
          }}
        />
      </Popup>
    </div>
  );
};
export default MediaEditor;
