import './keyhints.css';
import { ReactNode } from 'react';
import Hoverable from '../hoverable/Hoverable';
import { HintBox } from '../hint/Hint';

export type KeyHint = {
  keys: string[];
  explanationNode?: ReactNode;
  explanationText?: string;
  hintParagraphs?: string[];
};

interface KeyHintsProps {
  keyHints: KeyHint[];
}

const KeyHints: React.FC<KeyHintsProps> = ({ keyHints }) => {
  return (
    <div className={'keyHints'}>
      {keyHints.map((hint, i) => {
        return (
          <div className={'keyHint'}>
            <Hoverable
              key={i}
              onHoverNode={
                hint.hintParagraphs ? (
                  <HintBox paragraphs={hint.hintParagraphs} />
                ) : null
              }
            >
              <div className={'keyHint-content'}>
                <div className={'keyHint-keys'}>
                  {hint.keys.map((key, i) => {
                    return (
                      <div key={i} className={'keyHint-key'}>
                        <span>{key}</span>
                        {i < hint.keys.length - 1 ? (
                          <div className={'keyHint-key-plus'}>+</div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                <div className={'keyHint-doubleColon'}>:</div>
                <div className={'keyHint-explanation'}>
                  {hint.explanationNode}
                  {hint.explanationText}
                </div>
              </div>
            </Hoverable>
          </div>
        );
      })}
    </div>
  );
};

export default KeyHints;
