import './pricing.css';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import { useTranslation } from 'react-i18next';
import { Has } from '../../../contexts/auth/Authorization';
import Button from '../../../elements/button/Button';
import Input from '../../../elements/input/Input';
import { MODE } from './Pricing';
import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';
import UniversalProductPrice from '../../../types/UniversalProductPrice';
import { useState } from 'react';
import InformationBox from '../../../elements/informationbox/InformationBox';

export type PriceType = 'regular' | 'graduated';

interface PriceProps {
  index: number;
  price: UniversalProductPrice;
  updatePrice: (
    index: number,
    price: UniversalProductPrice,
    updatesNew: boolean
  ) => void;
  deletePrice?: (priceId: string, index: number) => void;
  currencyOptions: DropdownOption[];
  countryOptions: DropdownOption[];
  mode: MODE;
  updatePriceType?: (type: PriceType) => void;
  disableGraduation?: boolean;
}

const Price: React.FC<PriceProps> = ({
  index,
  price,
  updatePrice,
  deletePrice,
  currencyOptions,
  countryOptions,
  mode,
  updatePriceType,
  disableGraduation,
}) => {
  const { t } = useTranslation();
  const [priceType, setPriceType] = useState<PriceType>('regular');
  const [listPriceError, setListPriceError] = useState(false);
  /* Prices with 0 gross are hidden.
  Those are created automatically for products with variants upon product creation,
  since the user won't / shouldn't add any on the parent level but shopware requires a value here. */
  const currentCurrencySymbol = currencyOptions.find(
    (c) => c.id === price.currencyId
  )?.name;
  const updatesNew = mode === 'NEW';
  return (
    <div
      key={price.id}
      className={
        'pricing-price' +
        (mode === 'INHERITED' ? ' pricing-price-inherited' : '')
      }
    >
      {price.net === 0 && price.id ? (
        <InformationBox
          type={'info'}
          title={t('view.product.pricing.zeroPriceDisclaimer.title')}
          content={t('view.product.pricing.zeroPriceDisclaimer.text')}
        />
      ) : null}
      {updatePriceType ? (
        <div className={'global-inputGroup'}>
          <Dropdown
            title={t('view.product.pricing.newPriceWizard.priceType.title')}
            options={['regular', 'graduated']}
            selected={priceType}
            update={(e) => {
              const type = e.target.selectedOptions[0].value as PriceType;
              setPriceType(type);
              updatePriceType(type);
            }}
            translationPath={'view.product.pricing.newPriceWizard.priceType.'}
            hint={t('view.product.pricing.newPriceWizard.priceType.hint')}
          />
        </div>
      ) : null}
      <div className="global-inputGroup">
        <div className="global-inputGroup-inputInline">
          <Dropdown
            title={t('view.product.pricing.currency')}
            optionObjects={currencyOptions}
            selected={currentCurrencySymbol}
            update={(e) => {
              const currencyId =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (currencyId) {
                updatePrice(
                  index,
                  {
                    ...price,
                    currencyId: currencyId,
                  },
                  updatesNew
                );
              }
            }}
            required={mode === 'NEW'}
            disabled={mode !== 'NEW'}
          />
        </div>
        <div className="global-inputGroup-inputInline">
          <Dropdown
            title={t('view.product.pricing.country')}
            optionObjects={countryOptions}
            update={(e) => {
              const countryId =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (countryId) {
                updatePrice(
                  index,
                  {
                    ...price,
                    countryId: countryId,
                  },
                  updatesNew
                );
              }
            }}
            selected={
              countryOptions?.find((country) => country.id === price.countryId)
                ?.name
            }
            required={mode === 'NEW'}
            disabled={mode !== 'NEW'}
          />
        </div>
        <Has
          authorizations={['product_prices:delete']}
          isBypassed={mode === 'NORMAL'}
        >
          <div className="pricing-price-inputGroup-inputDelete">
            <Button
              type="icon"
              look="secondary"
              action={() => (deletePrice ? deletePrice(price.id, index) : null)}
            >
              <Trash
                className="pricing-price-inputGroup-inputDelete-icon"
                fill="var(--color-danger)"
              />
            </Button>
          </div>
        </Has>
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('view.product.pricing.gross')}
            content={price.gross.toString()}
            update={(e) =>
              updatePrice(index, { ...price, gross: parseFloat(e) }, updatesNew)
            }
            type="number"
            unit={currentCurrencySymbol}
            required={true}
            minValue={0.1}
          />
        </div>
        {mode !== 'NEW' ? (
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('view.product.pricing.net')}
              content={price.net?.toString()}
              type="number"
              unit={currentCurrencySymbol}
            />
          </div>
        ) : null}
      </div>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('view.product.pricing.listGross')}
            content={price.listPriceGross?.toString()}
            update={(e) => {
              if (parseFloat(e) <= price.gross) {
                setListPriceError(true);
              } else {
                setListPriceError(false);
                updatePrice(
                  index,
                  { ...price, listPriceGross: parseFloat(e) },
                  updatesNew
                );
              }
            }}
            type="number"
            unit={currentCurrencySymbol}
            minValue={0.1}
            errors={
              listPriceError
                ? [t('view.product.pricing.listPriceGrossTooBig')]
                : undefined
            }
          />
        </div>
        {mode !== 'NEW' ? (
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('view.product.pricing.listNet')}
              content={price.listPriceNet?.toString()}
              type="number"
              unit={currentCurrencySymbol}
            />
          </div>
        ) : null}
      </div>
      {price.quantityStart !== null && !disableGraduation ? (
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('view.product.pricing.quantityStart')}
              content={price.quantityStart?.toString()}
              update={(e) =>
                updatePrice(
                  index,
                  { ...price, quantityStart: parseInt(e) },
                  updatesNew
                )
              }
              type="number"
              minValue={1}
            />
          </div>
          <div className={'global-inputGroup-input'}>
            <Input
              title={t('view.product.pricing.quantityEnd')}
              content={price.quantityEnd?.toString()}
              update={(e) =>
                updatePrice(
                  index,
                  { ...price, quantityEnd: parseInt(e) },
                  updatesNew
                )
              }
              type="number"
              minValue={2}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Price;

// check if missing id -> key for new product prices causes problems
