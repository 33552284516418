import { ProductCategoryResponse } from '../../../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';

interface MainCategoryIdCellProps {
  mainCategoryId?: string | null;
  availableCategories?: ProductCategoryResponse[] | null;
}

const MainCategoryIdCell: React.FC<MainCategoryIdCellProps> = ({
  mainCategoryId,
  availableCategories,
}) => {
  const { i18n } = useTranslation();
  const category = availableCategories?.find((x) => x.id === mainCategoryId);

  if (category) {
    return (
      <div className={'mainCategoryIdCell'}>
        <div className={`jtce-bubble`}>
          {category.name[i18n.language as TranslatedStringIndex]}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MainCategoryIdCell;
