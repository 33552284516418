import React from 'react';

export type MemoScrollConfig = {
  memoScrollKey?: string;
  memoScrollConditionFunc?: () => boolean;
  listRef: React.RefObject<HTMLDivElement>;
};

const useMemoScroll = ({
  memoScrollKey,
  memoScrollConditionFunc,
  listRef,
}: MemoScrollConfig) => {
  const handleMemoScroll = (scrollTop: number) => {
    if (memoScrollKey) {
      rememberScrollPosition(memoScrollKey, scrollTop);
    }
  };

  const handleSetMemoScrollPosition = () => {
    if (memoScrollKey) {
      if (memoScrollConditionFunc) {
        if (memoScrollConditionFunc()) {
          setScrollPosition(memoScrollKey);
        }
      } else {
        setScrollPosition(memoScrollKey);
      }
    }
  };

  const rememberScrollPosition = (key: string, scrollTop: number) => {
    localStorage.setItem(
      `inpetto_portal_listScrollPosition-${key}`,
      scrollTop.toString()
    );
  };

  const setScrollPosition = (title: string) => {
    const scrollTop = localStorage.getItem(
      `inpetto_portal_listScrollPosition-${title}`
    );
    console.log('scrolling to: ' + scrollTop);
    setTimeout(() => {
      if (scrollTop && listRef && listRef.current) {
        listRef.current.scrollTo({
          top: parseInt(scrollTop),
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  return {
    handleMemoScroll,
    handleSetMemoScrollPosition,
  };
};

export default useMemoScroll;
