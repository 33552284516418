import { useTranslation } from 'react-i18next';
import {
  OrderPositionResponse,
  OrderResponse,
  OrderShippingDetailResponse,
} from '../../../../api/petcloudapi/api';
import TitleValueList from '../../../../elements/titlevaluelist/TitleValueList';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { getShippingDetailPositionEntries } from '../../positions/Positions';
import './shippingdetail.css';
import List from '../../../../features/list/List';
import useDateTools from '../../../../hooks/useDateTools';
import Button from '../../../../elements/button/Button';
import { useState } from 'react';
import UpdateShippingDetail from '../updateshippingdetail/UpdateShippingDetail';

interface ShippingDetailProps {
  order: OrderResponse;
  orderPositions: OrderPositionResponse[];
  shippingDetail: OrderShippingDetailResponse;
  currency: string;
  refreshOrder: () => void;
  closePopup: () => void;
}

const ShippingDetail: React.FC<ShippingDetailProps> = ({
  order,
  shippingDetail,
  orderPositions,
  currency,
  refreshOrder,
  closePopup,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.order.shippingDetails.shippingDetail',
  });
  const { displayReadableDate } = useDateTools();
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  console.log(shippingDetail);

  const render = () => {
    switch (mode) {
      case 'view':
        return (
          <>
            <TitleValueList
              entries={[
                {
                  title: t('trackingCode'),
                  value: shippingDetail.trackingCode,
                  asHighlight: true,
                },
                {
                  title: t('shippingProvider'),
                  value: shippingDetail.shippingProvider?.name,
                  asHighlight: true,
                },
                {
                  title: t('createdAt'),
                  value: displayReadableDate(shippingDetail.createdAt),
                  asHighlight: true,
                },
              ]}
            />
            {shippingDetail.positions && shippingDetail.positions.length > 0 ? (
              <div className="shippingDetail-positons">
                <List
                  items={getShippingDetailPositionEntries(
                    i18n.language as TranslatedStringIndex,
                    orderPositions,
                    shippingDetail.positions,
                    currency
                  )}
                  ignore={['id', 'productId', 'positionId', 'currency']}
                  priceKeysConfig={{
                    priceKeys: ['price'],
                    currencySymbol: currency,
                  }}
                  itemImgKey={'coverUri'}
                  monoSpaceStrings={['productNumber', 'ean']}
                />
              </div>
            ) : null}
            <div className={'global-cardActions-postBorder'}>
              <Button
                cta={t('edit')}
                look={'secondary'}
                width={'minimal'}
                action={() => setMode('edit')}
              />
            </div>
          </>
        );
      case 'edit':
        return (
          <UpdateShippingDetail
            order={order}
            orderPositions={orderPositions}
            shippingDetail={shippingDetail}
            refreshOrder={refreshOrder}
            closePopup={closePopup}
          />
        );
    }
  };

  return <div className="shippingDetail">{render()}</div>;
};

export default ShippingDetail;
