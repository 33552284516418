import './productimporter.css';
import { BulkEditorProduct } from '../../../view/products/BulkEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SheetParser from './sheetparser/SheetParser';
import ColumnHeaderConfig from './columnheaderconfig/ColumnHeaderConfig';

export enum ProductImportStep {
  Upload,
  Mapping,
}

export type SheetData = {
  data: any[];
  headers: string[];
  idKey?: string;
  parentIdKey?: string;
};

interface ProductImporterProps {
  setProducts: (products: BulkEditorProduct[]) => void;
}

const ProductImporter: React.FC<ProductImporterProps> = ({ setProducts }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter',
  });
  const [step, setStep] = useState(ProductImportStep.Upload);
  const [sheetData, setSheetData] = useState<SheetData | null>(null);

  switch (step) {
    case ProductImportStep.Upload:
      return (
        <div className={'productImporter'}>
          <div className={'productImporter-stepTitle'}>{t('upload_title')}</div>
          <SheetParser
            setSheetData={(x) => {
              setSheetData(x);
              setStep(ProductImportStep.Mapping);
            }}
          />
        </div>
      );
    case ProductImportStep.Mapping:
      return (
        <div className={'productImporter'}>
          <div className={'productImporter-stepTitle'}>
            {t('mapping_title')}
          </div>
          {sheetData ? (
            <ColumnHeaderConfig
              sheetData={sheetData}
              setProducts={setProducts}
            />
          ) : (
            <div>Error: sheet data missing</div>
          )}
        </div>
      );
  }
};

export default ProductImporter;
