import './clusterlist.css';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import List from '../../features/list/List';
import Retractable from '../retractable/Retractable';
import { EmptyState } from '../emptystate/EmptyState';

interface ClusterListProps {
  data: any;
  clusterDataBy: string;
  renderClusterHead: (cluster: any) => React.ReactNode;
  showTableHead?: boolean;
  ignore?: string[];
  translatedStrings?: string[];
  itemImgKey?: string;
  renderObjects?: {
    key: string;
    renderMethod: (value: any, item: any, index: number) => React.ReactNode;
  }[];
  dateStrings?: string[];
  monoSpaceStrings?: string[];
  listItemsFilterFunc?: (item: any) => boolean;
  emptyStateMessage?: string;
}

const ClusterList: React.FC<ClusterListProps> = ({
  data,
  clusterDataBy,
  showTableHead,
  renderClusterHead,
  ignore,
  translatedStrings,
  itemImgKey,
  renderObjects,
  dateStrings,
  monoSpaceStrings,
  listItemsFilterFunc,
  emptyStateMessage,
}) => {
  const [clusteredData, setClusteredData] = useState<any | null>(null);

  useEffect(() => {
    clusterData();
  }, []);

  const clusterData = () => {
    const results = _.groupBy(data, clusterDataBy);
    console.log(results);
    setClusteredData(results);
  };

  if (clusteredData) {
    return (
      <div className="clusterlist">
        {Object.entries(clusteredData).map(([id, children]: any) => {
          const items = listItemsFilterFunc
            ? children.filter(listItemsFilterFunc)
            : children;
          return (
            <div className="clusterlist-cluster">
              <Retractable head={renderClusterHead(children)}>
                <div className="clusterlist-cluster-list">
                  {items && items.length > 0 ? (
                    <List
                      items={items}
                      translatedStrings={translatedStrings}
                      ignore={ignore}
                      itemImgKey={itemImgKey}
                      renderObjects={renderObjects}
                      dateStrings={dateStrings}
                      monoSpaceStrings={monoSpaceStrings}
                    />
                  ) : (
                    <EmptyState message={emptyStateMessage} />
                  )}
                </div>
              </Retractable>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default ClusterList;
