import './refundsummarystatushistory.css';
import Badge from '../../../../../elements/badge/Badge';
import useStatus from '../../../../../hooks/refunds/useStatus';
import AdyenPaymentRefundStatusDictionary from '../../../../../types/AdyenPaymentRefundStatusDictionary';
import useDateTools from '../../../../../hooks/useDateTools';

interface RefundSummaryStatusHistoryProps {
  status: AdyenPaymentRefundStatusDictionary;
  title: string;
}

const RefundSummaryStatusHistory: React.FC<RefundSummaryStatusHistoryProps> = ({
  status,
  title,
}) => {
  const { getSortedStatus } = useStatus();
  const { displayReadableDate } = useDateTools();

  return (
    <div className={'refundSummaryStatusHistory'}>
      <div className={'refundSummaryStatusHistory-title'}>{title}</div>
      <div className={'refundSummaryStatusHistory-history'}>
        {getSortedStatus(status)
          .reverse()
          .map((status, i) => {
            const name = status.status?.toString();
            return (
              <div className={'refundSummaryStatusHistory-status'} key={i}>
                <div className={'refundSummaryStatusHistory-status-name'}>
                  <Badge
                    title={name ?? 'null error'}
                    color={
                      name === 'Authorised' || name === 'Refunded'
                        ? 'var(--color-success)'
                        : 'var(--color-text_secondary)'
                    }
                  />
                </div>
                <div className={'refundSummaryStatusHistory-status-date'}>
                  {status.bookingDate
                    ? displayReadableDate(status.bookingDate)
                    : 'null'}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RefundSummaryStatusHistory;
