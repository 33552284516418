import './propertieseditor.css';
import {
  ProductGroupMappingResponse,
  ProductGroupResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../../api/petcloudapi/api';
import { useEffect, useState } from 'react';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import PropertyBrowser from '../../../../elements/propertybrowser/PropertyBrowser';
import { BulkEditorProduct } from '../../../../view/products/BulkEditor';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import useUserTools from '../../../../hooks/useUserTools';

interface PropertiesEditorProps {
  value: PropertyOptionResponse[];
  updateCallback: EditorUpdateCallback;
  propertyGroups: PropertyGroupResponse[];
  availableProductGroups: ProductGroupResponse[];
  variantOptionsOnly?: boolean;
  validationOptional?: boolean;
  product: BulkEditorProduct;
}

const PropertiesEditor: React.FC<PropertiesEditorProps> = ({
  value,
  updateCallback,
  propertyGroups,
  availableProductGroups,
  variantOptionsOnly,
  validationOptional,
  product,
}) => {
  const api = usePetCloudApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const errorHandler = useErrorHandler();
  const { excludeUnlockedOptions } = useUserTools();

  const [properties, setProperties] = useState(value ?? []);
  const [productGroupMappings, setProductGroupMappings] = useState<
    ProductGroupMappingResponse[] | null | undefined
  >(null);

  useEffect(() => {
    getProductGroupMappings();
  }, []);

  const getProductGroupMappings = () => {
    const productGroupId = product.productGroupId;
    const animalSpeciesIds = product.animalSpecies?.map((x) => x.id) ?? [];
    if (productGroupId && animalSpeciesIds && animalSpeciesIds.length > 0) {
      productGroupMappingsApi
        .productGroupMappingsGetProductGroupMappings(
          [productGroupId],
          animalSpeciesIds,
          excludeUnlockedOptions()
        )
        .then((response) => {
          console.log(response);
          setProductGroupMappings(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setProductGroupMappings(undefined);
    }
  };

  const getFilteredPropertyGroupsByAnimalSpeciesIds = () => {
    const validPropertyGroupIds = availableProductGroups
      .find((x) => x.id === product.productGroupId)
      ?.propertyGroups?.map((x) => x.id);
    return propertyGroups.filter((group) => {
      return validPropertyGroupIds?.includes(group.id);
    });
  };

  if (productGroupMappings !== null) {
    return (
      <div className={'jtce-properties-editor'}>
        <div className={'jtce-properties-editor-browser'}>
          <PropertyBrowser
            availablePropertyGroups={getFilteredPropertyGroupsByAnimalSpeciesIds()}
            selectedPropertyOptions={properties}
            updateSelectedPropertyOptions={(updatedProperties) => {
              setProperties(updatedProperties);
            }}
            withSearch
            variantOptionsOnly={variantOptionsOnly}
            isVariantOption={variantOptionsOnly}
            userInputCreatesVariantOption={variantOptionsOnly}
            validationOptional={variantOptionsOnly || validationOptional}
            productGroupMappings={productGroupMappings}
            selectedAnimalSpeciesIds={product.animalSpecies?.map((x) => x.id)}
          />
        </div>
        {updateCallback ? (
          <EditorActions save={() => updateCallback([{ value: properties }])} />
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default PropertiesEditor;
