import './paginatedelements.css';
import { ReactNode, useState } from 'react';
import ListPagination from '../../features/list/listpagination/ListPagination';

interface PaginatedElementsProps {
  elements: ReactNode[];
  elementsCSSHelperClass?: string;
}

const PaginatedElements: React.FC<PaginatedElementsProps> = ({
  elements,
  elementsCSSHelperClass,
}) => {
  const [currentPageItemCount, setCurrentPageItemCount] = useState(25);
  const [page, setPage] = useState(1);

  const renderElements = () => {
    return elements.slice(
      (page - 1) * currentPageItemCount,
      page * currentPageItemCount
    );
  };

  return (
    <div className={'paginatedElements'}>
      <div className={`paginatedElements-elements ${elementsCSSHelperClass}`}>
        {renderElements()}
      </div>
      <div className={'paginatedElements-pagination'}>
        <ListPagination
          totalItemCount={elements.length}
          pageItemCount={[25, 50, 100]}
          currentPageItemCount={currentPageItemCount}
          setCurrentPageItemCount={setCurrentPageItemCount}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default PaginatedElements;
