import './animalspecieseditor.css';
import { AnimalSpeciesResponse } from '../../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface AnimaSpeciesEditorProps {
  availableAnimalSpecies: AnimalSpeciesResponse[];
  animalSpecies: AnimalSpeciesResponse[] | null | undefined;
  onDelete?: (index: number) => void;
  onSubmit?: (selectedAnimalSpecies: AnimalSpeciesResponse) => void;
}

const AnimalSpeciesEditor: React.FC<AnimaSpeciesEditorProps> = ({
  availableAnimalSpecies,
  animalSpecies,
  onDelete,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.animalSpeciesArray',
  });
  const filteredAvailableSpecies = availableAnimalSpecies.filter(
    (s) => !animalSpecies?.find((x) => x.id === s.id)
  );

  return (
    <div className={'animalSpeciesEditor'}>
      {onSubmit ? (
        <div className={'animalSpeciesEditor-speciesSection'}>
          <div className={'animalSpeciesEditor-speciesSection-title'}>
            {t('availableSpecies')}
          </div>
          <div className={'animalSpeciesEditor-speciesSection-options'}>
            {filteredAvailableSpecies.length > 0 ? (
              filteredAvailableSpecies.map((species, i) => {
                return (
                  <div
                    key={i}
                    className={'animalSpeciesEditor-speciesSection-option'}
                    onClick={() => {
                      onSubmit(species);
                    }}
                  >
                    {species.name[i18n.language as TranslatedStringIndex]}
                  </div>
                );
              })
            ) : (
              <EmptyState message={t('noSpeciesAvailable')} textOnly noMargin />
            )}
          </div>
        </div>
      ) : null}
      <div className={'animalSpeciesEditor-speciesSection'}>
        <div className={'animalSpeciesEditor-speciesSection-title'}>
          {t('currentSpecies')}
        </div>
        <div className={'animalSpeciesEditor-speciesSection-options'}>
          {animalSpecies && animalSpecies.length > 0 ? (
            animalSpecies.map((species, i) => {
              return (
                <div
                  key={i}
                  className={
                    'animalSpeciesEditor-speciesSection-option animalSpeciesEditor-speciesSection-option-selected'
                  }
                  onClick={() => {
                    if (onDelete) onDelete(i);
                  }}
                >
                  {species.name[i18n.language as TranslatedStringIndex]}
                </div>
              );
            })
          ) : (
            <EmptyState message={t('noSpeciesChosen')} textOnly noMargin />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnimalSpeciesEditor;
