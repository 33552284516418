import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import useDebounced from '../../../hooks/useDebounced';
import {
  CustomCellConfig,
  EditorUpdateCallbackInstruction,
  JsonTableCellError,
  safelyParseJSON,
  SelectedCellElement,
} from '../JsonTable';
import InputCell from '../../../features/jsontable/jsontablecell/cells/input/InputCell';
import { SmallHint } from '../../../elements/hint/Hint';
import { ReactComponent as IconValidated } from '../../../../assets/icon/validated.svg';
import Popup from '../../../elements/popup/Popup';
import { RequiredFieldValidationMap } from '../tablecompletionbar/TableCompletionBar';
import useValidation from '../utils/useValidation';

interface JsonTableCellProps {
  cellId: string;
  headerId: string;
  item: any;
  value: any;
  height: number;
  selected: boolean;
  customCellConfig?: CustomCellConfig;
  updateItem?: (
    itemId: string,
    instructions: EditorUpdateCallbackInstruction[]
  ) => void;
  setCellError: (cellId: string, error: unknown, asWarning?: boolean) => void;
  removeCellError: (cellId: string) => void;
  error?: JsonTableCellError;
  technicalEditor?: boolean;
  disabled?: boolean;
  toggleKeyEvents: (enable: boolean) => void;
  selectCellElement: (element: SelectedCellElement) => void;
  selectedCellElement: SelectedCellElement | null;
  requiredFields: string[];
  requiredFieldValidationMap?: RequiredFieldValidationMap;
  showValidation: boolean;
}

const JsonTableCell: React.FC<JsonTableCellProps> = ({
  cellId,
  headerId,
  item,
  value,
  height,
  selected,
  customCellConfig,
  updateItem,
  setCellError,
  removeCellError,
  error,
  technicalEditor,
  disabled,
  toggleKeyEvents,
  selectCellElement,
  selectedCellElement,
  requiredFields,
  requiredFieldValidationMap,
  showValidation,
}) => {
  const [editor, setEditor] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const debounced = useDebounced();
  const { isCompleteValue } = useValidation(requiredFieldValidationMap);

  useEffect(() => {
    if (selected) {
      ref.current?.focus();
    }
  }, [selected]);

  const renderEditor = (config: CustomCellConfig) => {
    if (technicalEditor) {
      if (config.technicalEditor) {
        if (editor) {
          return (
            <Popup
              key={`technicalEditor_${cellId}`}
              toggled={true}
              width={'40%'}
              close={() => toggleEditor(false)}
            >
              {config.technicalEditor(
                value,
                updateItem
                  ? (newValue: any) => {
                      updateItem(item.id, [
                        {
                          value: newValue,
                          headerKey: headerId,
                        },
                      ]);
                      toggleEditor(false);
                    }
                  : () => {}
              )}
            </Popup>
          );
        } else {
          return null;
        }
      } else if (editor) {
        return (
          <div className={'jsontable-input-editor-wrapper'}>
            NO TECHNICAL EDITOR PROVIDED
          </div>
        );
      }
    } else if (config.editor && editor) {
      return (
        <Popup
          key={`editor_${cellId}`}
          toggled={true}
          width={'40%'}
          close={() => toggleEditor(false)}
        >
          {config.editor(
            value,
            updateItem
              ? (instructions) => {
                  const instr = instructions;
                  instr.forEach((instruction) => {
                    if (!instruction.headerKey) {
                      instruction.headerKey = headerId;
                    }
                  });
                  updateItem(item.id, instr);
                  toggleEditor(false);
                }
              : () => {},
            item,
            (error: unknown, asWarning?: boolean) => {
              setCellError(cellId, error, asWarning);
            },
            () => removeCellError(cellId)
          )}
        </Popup>
      );
    } else {
      return null;
    }
  };

  const toggleEditor = (bool: boolean) => {
    setEditor(bool);
    toggleKeyEvents(!bool);
    console.log('Settings key events: ' + !bool);
  };

  const renderValidation = (validationDescriptions: string[]) => {
    if (validationDescriptions.length > 0) {
      return (
        <SmallHint
          paragraphs={validationDescriptions}
          icon={
            <IconValidated
              className={'jsontable-cell-content-validation-icon'}
            />
          }
        />
      );
    } else {
      return null;
    }
  };

  const isRequired = useMemo(() => {
    return requiredFields.includes(headerId);
  }, [requiredFields, headerId]);

  const render = () => {
    if (customCellConfig) {
      if (customCellConfig.renderMethod !== 'default') {
        if (!disabled) {
          return (
            <div
              className={`jsontable-cell-content ${
                !!error ? 'jsontable-cell-content__error' : ''
              } ${error?.isWarning ? 'jsontable-cell-content__warning' : ''} ${
                isRequired && showValidation
                  ? isCompleteValue(item, headerId)
                    ? 'jsontable-cell-content__completed'
                    : 'jsontable-cell-content__missing'
                  : ''
              }`}
              onDoubleClick={() => toggleEditor(true)}
            >
              {customCellConfig.renderMethod(
                value,
                updateItem
                  ? (instructions) => {
                      const instr = instructions;

                      if (customCellConfig.parseValueFunc) {
                        const i = instr.findIndex(
                          (x) => x.headerKey === headerId
                        );
                        instr[i] = {
                          ...instr[i],
                          value: customCellConfig.parseValueFunc(
                            instr[i].value
                          ),
                        };
                      }

                      instr.forEach((instruction) => {
                        if (!instruction.headerKey) {
                          instruction.headerKey = headerId;
                        }
                      });

                      updateItem(item.id, instr);
                    }
                  : () => {},
                item,
                (error: unknown, asWarning?: boolean) => {
                  setCellError(cellId, error, asWarning);
                },
                () => removeCellError(cellId),
                (element: any, compatibleHeaders?: string[]) =>
                  selectCellElement({
                    header: headerId,
                    element: element,
                    compatibleHeaders: compatibleHeaders,
                  }),
                (element: any) => {
                  return selectedCellElement?.element === element;
                }
              )}
              {renderEditor(customCellConfig)}
              {customCellConfig.validationDescriptions ? (
                <div className={'jsontable-cell-content-validation'}>
                  {renderValidation(
                    customCellConfig.validationDescriptions(item, headerId)
                  )}
                </div>
              ) : null}
            </div>
          );
        } else {
          return null;
        }
      } else {
        const content = customCellConfig.parseJSON
          ? JSON.stringify(value)
          : value;
        return (
          <div
            className={'jsontable-input-wrapper'}
            onDoubleClick={() => toggleEditor(true)}
          >
            <InputCell
              key={cellId + value}
              content={content}
              update={
                updateItem
                  ? (value) => {
                      debounced(() =>
                        updateItem(item.id, [
                          {
                            headerKey: headerId,
                            value: customCellConfig?.parseJSON
                              ? safelyParseJSON(value, content, (error) => {
                                  if (error) {
                                    setCellError(cellId, error);
                                  } else {
                                    removeCellError(cellId);
                                  }
                                })
                              : customCellConfig.parseValueFunc
                              ? customCellConfig.parseValueFunc(value)
                              : value,
                          },
                        ])
                      );
                    }
                  : undefined
              }
              hasError={!!error}
              disabled={disabled}
              type={customCellConfig.inputType}
              toggleKeyEvents={toggleKeyEvents}
              isValid={
                isRequired && showValidation
                  ? isCompleteValue(item, headerId)
                  : undefined
              }
            />
            {renderEditor(customCellConfig)}
            <span>{content?.toString()}</span>
          </div>
        );
      }
    } else {
      return (
        <div className={'jsontable-input-wrapper'}>
          <InputCell
            key={cellId + value}
            content={value}
            update={
              updateItem
                ? (value) =>
                    updateItem(item.id, [
                      {
                        headerKey: headerId,
                        value: value,
                      },
                    ])
                : undefined
            }
            hasError={!!error}
            disabled={disabled}
            toggleKeyEvents={toggleKeyEvents}
            isValid={
              isRequired && showValidation
                ? isCompleteValue(item, headerId)
                : undefined
            }
          />
          <span>{value?.toString()}</span>
        </div>
      );
    }
  };

  return (
    <div
      ref={ref}
      className={`jsontable-cell jsontable-cell_${
        selected ? 'selected' : 'deselected'
      } jsontable-cell_${disabled ? 'disabled' : 'enabled'}`}
      style={{ height: height }}
    >
      {render()}
    </div>
  );
};

const deepCompare = (
  prevProps: JsonTableCellProps,
  nextProps: JsonTableCellProps
) => {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.selected === nextProps.selected &&
    prevProps.customCellConfig === nextProps.customCellConfig &&
    prevProps.technicalEditor === nextProps.technicalEditor &&
    prevProps.error === nextProps.error &&
    prevProps.selectedCellElement === nextProps.selectedCellElement &&
    prevProps.showValidation === nextProps.showValidation &&
    prevProps.requiredFields === nextProps.requiredFields
  );
};

export default memo(JsonTableCell, deepCompare);
