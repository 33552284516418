import './themeswitcher.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../toggleswitch/ToggleSwitch';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';
import { ReactComponent as IconMoon } from '../../../assets/icon/moon.svg';

export const useDarkModeDetector = () => {
  const { getToggledSetting } = useLocalStorageSettings();

  return () => {
    if (getToggledSetting('pcb_darkmode')) {
      document.documentElement.setAttribute('data-theme', 'dark');
      return true;
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      return false;
    }
  };
};

interface ThemeSwitcherProps {
  hideTitle?: boolean;
  smallSwitch?: boolean;
}

const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({
  hideTitle,
  smallSwitch,
}) => {
  const { t } = useTranslation();
  const darkModeDetector = useDarkModeDetector();
  const { setToggledSetting } = useLocalStorageSettings();
  const [darkMode, setDarkMode] = useState(darkModeDetector());

  useEffect(() => {
    toggleDarkMode();
  }, [darkMode]);

  const toggleDarkMode = () => {
    if (darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
      setToggledSetting('pcb_darkmode', true);
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      setToggledSetting('pcb_darkmode', false);
    }
  };

  return (
    <div className={'themeswitcher'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <ToggleSwitch
            label={hideTitle ? undefined : t('view.account.interface.darkMode')}
            toggled={darkMode}
            toggle={() => setDarkMode(!darkMode)}
            icon={<IconMoon fill={'var(--color-text_secondary)'} />}
            smallSwitch={smallSwitch}
          />
        </div>
      </div>
    </div>
  );
};

export default ThemeSwitcher;
