import { DropdownOption } from '../elements/selectors/Selectors';
import { useMemo } from 'react';
import iso3311a2 from 'iso-3166-1-alpha-2';
import { data } from 'currency-codes';

const useCommonDropDownOptions = () => {
  const countryOptions: DropdownOption[] = useMemo(() => {
    const result: DropdownOption[] = [];
    Object.entries(iso3311a2.getData()).forEach(([countryKey, countryName]) => {
      if (countryName) {
        result.push({
          id: countryKey,
          name: countryName,
        });
      }
    });
    return result;
  }, []);

  const currencyOptions: DropdownOption[] = useMemo(() => {
    return data.map((x) => ({
      id: x.code,
      name: x.currency,
    }));
  }, []);

  return { countryOptions, currencyOptions };
};

export default useCommonDropDownOptions;
