import './policymatrix.css';
import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from 'react-i18next';
import { TFunction } from 'i18next';
import { Check } from '../../../../elements/selectors/Selectors';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { PolicyResponse } from '../../../../api/petcloudapi/api';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import ToggleSwitch from '../../../../elements/toggleswitch/ToggleSwitch';
import blacklist from './blacklistedEndpoints';

interface PolicyMatrixProps {
  t: TFunction;
  selectedPermissions: string[];
  updateSelectedPermissions: (p: string) => void;
  inhertiablePermissions: string[];
}

const PolicyMatrix: React.FC<PolicyMatrixProps> = ({
  t,
  selectedPermissions,
  updateSelectedPermissions,
  inhertiablePermissions,
}) => {
  const [permissions, setPermissions] = useState<PolicyResponse[]>([]);
  const { i18n } = useContext(I18nContext);
  const [showTechnicalNames, setShowTechnicalNames] = useState(false);
  const api = usePetCloudApi().policiesApi();

  useEffect(() => {
    api
      .policiesGetPolicies()
      .then((response) => {
        console.log(response);
        setPermissions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  interface PermissionTreeItem {
    group: string;
    permissionSets: (
      | {
          name: string;
          technicalName: string;
          permissions: (PolicyResponse | undefined)[];
        }
      | undefined
    )[];
  }

  const [permissionTree, setPermissionTree] = useState<PermissionTreeItem[]>(
    []
  );

  const sortPermissionsByPolicy = (perms: PolicyResponse[]) => {
    const ranking: { [key: string]: number } = {
      list: 0,
      detail: 1,
      edit: 2,
      create: 3,
      delete: 4,
    };
    return perms.sort((a, b) => {
      return (
        ranking[a.permission.split(':')[1]] -
        ranking[b.permission.split(':')[1]]
      );
    });
  };

  // after permissions have been set create permission tree for rendering
  useEffect(() => {
    const tree: PermissionTreeItem[] = [];
    permissions.forEach((permission) => {
      const groupName =
        permission.group[i18n.language as TranslatedStringIndex];
      if (
        groupName &&
        tree.find((element) => element.group === groupName) === undefined
      ) {
        const element: PermissionTreeItem = {
          group: groupName,
          permissionSets: [],
        };
        permissions.forEach((perm) => {
          const permName = perm.name[i18n.language as TranslatedStringIndex];
          if (
            permName &&
            perm.group[i18n.language as TranslatedStringIndex] === groupName &&
            element.permissionSets.find((s) => s?.name === permName) ===
              undefined
          ) {
            const perms: PolicyResponse[] = [];
            permissions.forEach((p) => {
              if (
                p.group[i18n.language as TranslatedStringIndex] === groupName &&
                p.name[i18n.language as TranslatedStringIndex] === permName
              ) {
                perms.push(p);
              }
            });
            const technicalName = perm.permission.split(':')[0];
            if (!blacklist.includes(technicalName)) {
              element.permissionSets.push({
                name: permName,
                technicalName: technicalName,
                permissions: sortPermissionsByPolicy(perms),
              });
            }
          }
        });
        if (element.permissionSets.length > 0) {
          tree.push(element);
        }
      }
    });
    setPermissionTree(tree);
  }, [permissions]);

  return (
    <div className="policymatrix-wrapper">
      <table className="policymatrix">
        <tbody>
          <tr className="policymatrix-header">
            <th>
              <div className="policymatrix-header-switch">
                <ToggleSwitch
                  label={t('view.team.roles.showTechnicalNames')}
                  toggled={showTechnicalNames}
                  toggle={() => setShowTechnicalNames(!showTechnicalNames)}
                  smallLabel
                />
              </div>
            </th>
            <th>{t('view.team.roles.permissions.list')}</th>
            <th>{t('view.team.roles.permissions.detail')}</th>
            <th>{t('view.team.roles.permissions.edit')}</th>
            <th>{t('view.team.roles.permissions.create')}</th>
            <th>{t('view.team.roles.permissions.delete')}</th>
          </tr>
          {permissionTree.map((element, i) => {
            return (
              <React.Fragment key={i}>
                <tr className="policymatrix-group">
                  <td className="policymatrix-group-title">{element.group}</td>
                </tr>
                {element.permissionSets.map((permissionSet, i) => {
                  if (permissionSet) {
                    return (
                      <tr
                        key={i}
                        className={`policymatrix-set ${
                          i % 2
                            ? 'policymatrix-set-even'
                            : 'policymatrix-set-odd'
                        }`}
                      >
                        <td className="policymatrix-set-title">
                          {permissionSet.name}
                          {showTechnicalNames ? (
                            <span
                              className={
                                'policymatrix-set-technicalName global-monospaced-contrast'
                              }
                            >
                              {permissionSet.technicalName}
                            </span>
                          ) : null}
                        </td>
                        {permissionSet.permissions.map((permission, i) => {
                          if (permission) {
                            return (
                              <td key={i} className="policymatrix-permission">
                                <div className="policymatrix-permission-check">
                                  <Check
                                    checked={selectedPermissions.includes(
                                      permission.permission
                                    )}
                                    update={() =>
                                      updateSelectedPermissions(
                                        permission.permission
                                      )
                                    }
                                    disabled={
                                      !inhertiablePermissions.includes(
                                        permission.permission
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PolicyMatrix;
