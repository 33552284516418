import './newreturnannouncement.css';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateOrderReturnAnnouncementPositionRequest,
  CreateOrderReturnAnnouncementRequest,
  OrderResponse,
  OrderShippingDetailPositionResponse,
  OrderShippingDetailResponse,
  ReturnReason,
  ShippingProviderResponse,
} from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import Input from '../../../../elements/input/Input';
import Retractable from '../../../../elements/retractable/Retractable';
import {
  Dropdown,
  DropdownOption,
} from '../../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { ReactComponent as Trash } from '../../../../../assets/icon/trash.svg';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import { useAuthorization } from '../../../../contexts/auth/Authorization';
import List from '../../../../features/list/List';
import { getShippingDetailPositionEntries } from '../../positions/Positions';
import useStateUpdateTools from '../../../../hooks/useStateUpdateTools';
import useNotifications from '../../../../hooks/useNotifications';
import InformationBox from '../../../../elements/informationbox/InformationBox';

interface CreateReturnAnnouncementProps {
  order: OrderResponse;
  closePopup: () => void;
  getOrder: () => void;
}

const NewReturnAnnouncement: React.FC<CreateReturnAnnouncementProps> = ({
  order,
  closePopup,
  getOrder,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.order.returnAnnouncements.new',
  });
  const { authorizations } = useAuthorization();
  const { toggleItemInArray, toggleItem } = useStateUpdateTools();
  const api = usePetCloudApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const orderReturnAnnouncementsApi = api.orderReturnAnnouncementsApi();
  const shippingProvidersApi = api.shippingProvidersApi();
  const [returnAnnouncementRequest, setReturnAnnouncementRequest] =
    useState<CreateOrderReturnAnnouncementRequest | null>(null);
  const [shippingProviders, setShippingProviders] = useState<
    DropdownOption[] | null
  >(null);
  const [selectedShippingProvider, setSelectedShippingProvider] =
    useState('DHL');
  const [selectedShippingDetail, setSelectedShippingDetail] =
    useState<OrderShippingDetailResponse | null>(null);
  const [selectedShippingDetailPositions, setSelectedShippingDetailPositions] =
    useState<OrderShippingDetailPositionResponse[]>([]);
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getShippingProviders();
  }, [authorizations]);

  useEffect(() => {
    if (shippingProviders) {
      const dhlId = shippingProviders.find(
        (s) => s.name === 'DHL Standard'
      )?.id;
      setSelectedShippingProvider(dhlId ?? 'DHL Standard could not be found');
    }
  }, [shippingProviders]);

  const getShippingProviders = () => {
    if (authorizations?.includes('shipping_providers:list')) {
      shippingProvidersApi
        .shippingProvidersGetShippingProviders()
        .then((response) => {
          console.log(response);
          setShippingProviders(
            response.data.map((provider) => {
              return {
                name: provider.name,
                id: provider.id,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const returnReasons = [
    {
      id: 'TooLarge',
      name: t('reasons.TooLarge'),
    },
    {
      id: 'TooSmall',
      name: t('reasons.TooSmall'),
    },
    {
      id: 'TooLate',
      name: t('reasons.TooLate'),
    },
    {
      id: 'BadQuality',
      name: t('reasons.BadQuality'),
    },
    {
      id: 'Defective',
      name: t('reasons.Defective'),
    },
    {
      id: 'Dislike',
      name: t('reasons.Dislike'),
    },
    {
      id: 'DoesNotFitDescription',
      name: t('reasons.DoesNotFitDescription'),
    },
    {
      id: 'FoundCheaper',
      name: t('reasons.FoundCheaper'),
    },
    {
      id: 'Other',
      name: t('reasons.Other'),
    },
  ];

  // useEffect(() => {
  //   initRequest();
  // }, [returnAnnouncementPositions, selectedShippingProvider]);

  const initRequest = () => {
    if (selectedShippingDetail) {
      setReturnAnnouncementRequest({
        orderShippingDetailId: selectedShippingDetail.id,
        shippingProviderId: selectedShippingProvider,
        orderReturnAnnouncementPositions: selectedShippingDetailPositions.map(
          (position) => {
            return {
              quantity: position.quantity ?? 0,
              orderShippingDetailPositionId: position.id,
              orderReturnReasons: null,
            };
          }
        ),
      });
    }
  };

  const renderRequestPositionHead = (
    positionRequest: CreateOrderReturnAnnouncementPositionRequest,
    index: number
  ) => {
    console.log('rendered head');

    const originalPosition = selectedShippingDetail?.positions?.find(
      (p) => p.id === positionRequest.orderShippingDetailPositionId
    );
    const product = order.orderPositions.find(
      (p) => p.id === originalPosition?.orderPositionId
    )?.product;

    if (originalPosition && product) {
      return (
        <div
          className="shippingdetails-returnAnnouncement-head"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="shippingdetails-returnAnnouncement-head-title">
            {product.name[i18n.language as TranslatedStringIndex]}
          </div>
          <div className="shippingdetails-returnAnnouncement-head-quantity-input">
            <Input
              content={positionRequest.quantity.toString()}
              type={'number'}
              update={(e) => {
                if (originalPosition.quantity) {
                  const newValue = parseInt(e);
                  if (
                    newValue >= 1 &&
                    newValue <= originalPosition.quantity &&
                    returnAnnouncementRequest &&
                    returnAnnouncementRequest.orderReturnAnnouncementPositions
                  ) {
                    const update = [
                      ...returnAnnouncementRequest.orderReturnAnnouncementPositions,
                    ];
                    update[index] = {
                      ...update[index],
                      quantity: newValue,
                    };
                    setReturnAnnouncementRequest({
                      ...returnAnnouncementRequest,
                      orderReturnAnnouncementPositions: update,
                    });
                  }
                }
              }}
            />
          </div>
          <div className="shippingdetails-returnAnnouncement-head-quantity-max">
            / {originalPosition?.quantity}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const updateReason = (
    value: string,
    positionIndex: number,
    reasonIndex: number,
    scenario: 'reason' | 'text' | 'delete'
  ) => {
    if (
      returnAnnouncementRequest &&
      returnAnnouncementRequest.orderReturnAnnouncementPositions
    ) {
      const update = [
        ...returnAnnouncementRequest.orderReturnAnnouncementPositions,
      ];
      const orderReturnReasonsUpdate = update[positionIndex].orderReturnReasons;
      if (orderReturnReasonsUpdate) {
        if (scenario === 'reason') {
          orderReturnReasonsUpdate[reasonIndex] = {
            ...orderReturnReasonsUpdate[reasonIndex],
            returnReason: value as ReturnReason,
          };
        } else if (scenario === 'text') {
          orderReturnReasonsUpdate[reasonIndex] = {
            ...orderReturnReasonsUpdate[reasonIndex],
            reasonText: value,
          };
        } else if (scenario === 'delete') {
          orderReturnReasonsUpdate.splice(reasonIndex, 1);
        }

        update[positionIndex] = {
          ...update[positionIndex],
          orderReturnReasons: [...orderReturnReasonsUpdate],
        };
      }
      setReturnAnnouncementRequest({
        ...returnAnnouncementRequest,
        orderReturnAnnouncementPositions: update,
      });
    }
  };

  const sendReturnLabelEmail = (orderReturnAnnouncementId: string) => {
    orderReturnAnnouncementsApi
      .orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.labelEmail_successful'));
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitReturnAnnouncement = () => {
    if (returnAnnouncementRequest) {
      setIsSubmitting(true);
      orderReturnAnnouncementsApi
        .orderReturnAnnouncementsCreateOrderReturnAnnouncement(
          returnAnnouncementRequest
        )
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          pushNotification(t('notifications.returnAnnouncement_successful'));
          closePopup();
          getOrder();
          sendReturnLabelEmail(response.data.id);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const renderShippingDetailPositions = useCallback(
    (positions: OrderShippingDetailPositionResponse[]) => {
      return (
        <div className={'newReturnAnnouncement-shippingDetails-positions'}>
          <span className={'global-monospaced-contrast'}>
            {positions.length}
          </span>
        </div>
      );
    },
    []
  );

  const renderShippingDetailShippingProvider = useCallback(
    (provider: ShippingProviderResponse) => {
      return (
        <div className={'newReturnAnnouncement-shippingDetails-provider'}>
          {provider.name}
        </div>
      );
    },
    []
  );

  const renderStep = (shippingProviders: DropdownOption[]) => {
    switch (step) {
      case 0:
        return (
          <div className="newReturnAnnouncement">
            {order.orderShippingDetails &&
            order.orderShippingDetails?.length > 0 ? (
              <>
                <div className="popup-text" style={{ maxWidth: 500 }}>
                  {t('steps.shippingDetails.text')}
                </div>
                <List
                  items={order.orderShippingDetails}
                  onSelect={(item) =>
                    setSelectedShippingDetail(
                      toggleItem(selectedShippingDetail, item)
                    )
                  }
                  selectedItems={
                    selectedShippingDetail ? [selectedShippingDetail] : []
                  }
                  ignore={[
                    'id',
                    'shippingDateEarliest',
                    'shippingDateLatest',
                    'shippingProviderId',
                    'orderReturnAnnouncements',
                    'updatedAt',
                  ]}
                  dateStrings={['createdAt']}
                  monoSpaceStrings={['trackingCode']}
                  renderObjects={[
                    {
                      key: 'positions',
                      renderMethod: renderShippingDetailPositions,
                    },
                    {
                      key: 'shippingProvider',
                      renderMethod: renderShippingDetailShippingProvider,
                    },
                  ]}
                />
                <div className={'global-cardActions-postBorder'}>
                  <Button
                    cta={t('steps.shippingDetails.next')}
                    look={'secondary'}
                    width={'minimal'}
                    action={() => setStep(1)}
                    active={!!selectedShippingDetail}
                  />
                </div>
              </>
            ) : (
              <InformationBox
                content={t('noShippingDetailsWarning')}
                type={'warning'}
              />
            )}
          </div>
        );
      case 1:
        return (
          <div className="newReturnAnnouncement">
            <div className="popup-text" style={{ maxWidth: 500 }}>
              {t('steps.positions.text')}
            </div>
            <List
              items={getShippingDetailPositionEntries(
                i18n.language as TranslatedStringIndex,
                order.orderPositions,
                selectedShippingDetail?.positions
              )}
              selectedItems={selectedShippingDetailPositions}
              onSelect={(item: OrderShippingDetailResponse) => {
                setSelectedShippingDetailPositions(
                  toggleItemInArray(selectedShippingDetailPositions, item)
                );
              }}
              onSelectAll={(items) =>
                setSelectedShippingDetailPositions([...items])
              }
              itemImgKey={'coverUri'}
              ignore={['id', 'productId', 'positionId']}
              monoSpaceStrings={['ean']}
              priceKeysConfig={{
                priceKeys: ['price'],
                currencySymbol: order.currency?.symbol,
              }}
            />
            <div
              className={
                'global-cardActions-postBorder global-cardActions-spaceBetween'
              }
            >
              <Button
                cta={t('steps.positions.back')}
                look={'secondary'}
                width={'minimal'}
                action={() => setStep(0)}
                active={!!selectedShippingDetail}
              />
              <Button
                cta={t('steps.positions.next')}
                look={'secondary'}
                width={'minimal'}
                action={() => {
                  initRequest();
                  setStep(2);
                }}
                active={selectedShippingDetailPositions.length > 0}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="newReturnAnnouncement">
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <Dropdown
                  title={t('shippingProvider')}
                  optionObjects={shippingProviders}
                  selected={
                    shippingProviders?.find(
                      (s) => s.id === selectedShippingProvider
                    )?.name
                  }
                  update={(e) => {
                    const value =
                      e.target.selectedOptions[0].getAttribute('data-value');
                    if (value) {
                      setSelectedShippingProvider(value);
                    }
                  }}
                  disabled
                />
              </div>
            </div>
            {returnAnnouncementRequest &&
            returnAnnouncementRequest.orderReturnAnnouncementPositions &&
            returnAnnouncementRequest.orderReturnAnnouncementPositions.length >
              0
              ? returnAnnouncementRequest.orderReturnAnnouncementPositions.map(
                  (position, i) => {
                    return (
                      <div className="newReturnAnnouncement-retractable">
                        <Retractable
                          key={i}
                          head={renderRequestPositionHead(position, i)}
                        >
                          <div className="newReturnAnnouncement-reasons">
                            {position.orderReturnReasons?.map((reason, j) => {
                              return (
                                <div
                                  key={j}
                                  className="newReturnAnnouncement-reason"
                                >
                                  <div className="global-inputGroup newReturnAnnouncement-reason-top">
                                    <Dropdown
                                      title={t('reasonType')}
                                      optionObjects={returnReasons}
                                      selected={
                                        returnReasons.find(
                                          (r) => r.id === reason.returnReason
                                        )?.name
                                      }
                                      update={(e) => {
                                        const value = returnReasons.find(
                                          (r) =>
                                            r.id ===
                                            e.target.selectedOptions[0].getAttribute(
                                              'data-value'
                                            )
                                        )?.id;
                                        console.log(value);
                                        if (value) {
                                          updateReason(value, i, j, 'reason');
                                        }
                                      }}
                                      required
                                    />
                                    <Button
                                      look={'secondary'}
                                      type="icon"
                                      action={() =>
                                        updateReason('', i, j, 'delete')
                                      }
                                    >
                                      <Trash
                                        fill="var(--color-danger)"
                                        className="button-icon"
                                      />
                                    </Button>
                                  </div>
                                  <div className="global-inputGroup">
                                    <div className="global-inputGroup-input">
                                      <Input
                                        title={t('reasonText')}
                                        content={reason.reasonText}
                                        update={(e) => {
                                          updateReason(e, i, j, 'text');
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="newReturnAnnouncement-reasons-actions">
                            <Button
                              cta={t('addReason')}
                              look={'secondary'}
                              width="minimal"
                              action={() => {
                                if (
                                  returnAnnouncementRequest &&
                                  returnAnnouncementRequest.orderReturnAnnouncementPositions
                                ) {
                                  const update = [
                                    ...returnAnnouncementRequest.orderReturnAnnouncementPositions,
                                  ];

                                  let reasonsUpdate =
                                    update[i].orderReturnReasons;
                                  if (reasonsUpdate) {
                                    reasonsUpdate.push({
                                      reasonText: null,
                                      returnReason: 'Defective' as ReturnReason,
                                    });
                                  } else {
                                    reasonsUpdate = [
                                      {
                                        reasonText: null,
                                        returnReason:
                                          'Defective' as ReturnReason,
                                      },
                                    ];
                                  }
                                  update[i] = {
                                    ...update[i],
                                    orderReturnReasons: [...reasonsUpdate],
                                  };
                                  setReturnAnnouncementRequest({
                                    ...returnAnnouncementRequest,
                                    orderReturnAnnouncementPositions: update,
                                  });
                                }
                              }}
                            />
                          </div>
                        </Retractable>
                      </div>
                    );
                  }
                )
              : null}
            <div className="global-cardActions global-cardActions-spaceBetween newReturnAnnouncement-actions ">
              <Button
                cta={t('steps.final.back')}
                look={'secondary'}
                width={'minimal'}
                action={() => setStep(1)}
              />
              <Button
                cta={t('steps.final.submit')}
                look={'secondary-danger'}
                width="minimal"
                action={submitReturnAnnouncement}
                isLoading={isSubmitting}
              />
            </div>
          </div>
        );
    }
  };

  if (shippingProviders) {
    return (
      <div className="newReturnAnnouncement">
        {renderStep(shippingProviders)}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default NewReturnAnnouncement;
