import './tagwithheaders.css';
import Hoverable from '../hoverable/Hoverable';
import { ReactComponent as IconStar } from '../../../assets/icon/star.svg';

interface TagWithHeadersProps {
  tag: string;
  headers?: string[] | undefined | null;
  highlight?: boolean;
}

const TagWithHeaders: React.FC<TagWithHeadersProps> = ({
  tag,
  headers,
  highlight,
}) => {
  return (
    <div className={'tagWithHeaders'}>
      <div className={'tagWithHeaders-tag'}>
        {highlight ? (
          <IconStar
            className={'tagWithHeaders-tag-icon'}
            fill={'var(--color-yellow)'}
          />
        ) : null}
        {tag}
      </div>
      {headers ? (
        <div
          className={'tagWithHeaders-headers'}
          style={{
            justifyContent: headers.length > 1 ? 'space-between' : 'center',
          }}
        >
          <div className={'tagWithHeaders-header'}>{headers[0]}</div>
          {headers.length > 1 ? (
            <Hoverable
              onHoverNode={
                <div className={'tagWithHeaders-headers-pop'}>
                  {headers.map((header) => {
                    return <div>{header}</div>;
                  })}
                </div>
              }
            >
              <div className={'tagWithHeaders-header-count'}>
                +{headers.length - 1}
              </div>
            </Hoverable>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TagWithHeaders;
