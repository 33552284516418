import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import { Has } from '../../contexts/auth/Authorization';
import {
  Stage,
  Navigator,
  StageColumns,
  StageColumn,
} from '../../layout/stage/Stage';
import Card, { CardSection, HelperCard } from '../../elements/card/Card';
import Button from '../../elements/button/Button';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { NoAuthorization } from '../../elements/emptystate/EmptyState';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  CreateProductRequest,
  ProductAssetResponse,
  InlineCreateProductPriceRequest,
  InlineCreateProductStockRequest,
  InlineCreateDeliveryTimeRequest,
  ProductState,
  ProductCategoryResponse,
  PropertyOptionResponse,
  TaxTypeIdentifier,
  TagResponse,
  InlineCreateVariantProductRequest,
  WarehouseResponse,
  ProductGroupResponse,
  AnimalSpeciesResponse,
  ProductGroupMappingResponse,
  PropertyGroupResponse,
  TranslatedTypeResponseOfString,
  BrandResponse,
} from '../../api/petcloudapi/api';
import {
  useErrorHandler,
  Error,
} from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import Meta from '../../sections/product/meta/Meta';
import Media from '../../sections/product/media/Media';
import Pricing from '../../sections/product/pricing/Pricing';
import Stocks from '../../sections/product/stocks/Stocks';
import Measures from '../../sections/product/measures/Measures';
import Shipping from '../../sections/product/shipping/Shipping';
import CartOptions from '../../sections/product/cartoptions/CartOptions';
import Properties from '../../sections/product/properties/Properties';
import Categories from '../../sections/product/categories/Categories';
import StockSettings from '../../sections/product/stocksettings/StockSettings';
import Variants from '../../sections/product/variants/Variants';
import Regulations from '../../sections/product/regulations/Regulations';
import Information from '../../sections/product/information/Information';
import useManufacturerConditions from '../../hooks/useManufacturerConditions';
import { useUser } from '../../contexts/auth/User';
import BrandProductLine from '../../sections/product/brandproductline/BrandProductLine';
import UniversalProductPrice from '../../types/UniversalProductPrice';
import useStorageData from '../../hooks/useStorageData';
import { ReactComponent as ReloadIcon } from '../../../assets/icon/reload.svg';
import ProductGroupMappingWizard from '../../sections/product/productgroupmappingwizward/ProductGroupMappingWizard';
import AnimalSpeciesAndProductGroup from '../../sections/product/animalspeciesandproductgroup/AnimalSpeciesAndProductGroup';
import useUserTools from '../../hooks/useUserTools';

export const createNewProductRequest = {
  animalSpeciesIds: [],
  state: 'Draft' as ProductState,
  name: {
    'de-DE': 'Neues Produkt',
    'en-GB': null,
  },
  description: {
    'de-DE': null,
    'en-GB': null,
  },
  ean: '',
  releaseDate: '2022-01-03T15:32:05.78418+00:00',
  parentId: null,
  mainVariantId: null,
  variants: null,
  propertyIds: null,
  productStocks: null,
  restockTimeDays: null,
  isCloseout: false,
  isBatchControlled: false,
  isBestBeforeControlled: false,
  isDangerousGoods: false,
  productPrices: null,
  taxTypeIdentifier: '' as TaxTypeIdentifier,
  purchaseSteps: 1,
  minPurchase: 1,
  maxPurchase: null,
  purchaseUnit: null,
  referenceUnit: null,
  weight: null,
  width: null,
  height: null,
  length: null,
  productUnitId: null,
  packUnit: {
    'de-DE': 'Stück',
    'en-GB': 'Piece',
  },
  packUnitPlural: {
    'de-DE': 'Stück',
    'en-GB': 'Pieces',
  },
  deliveryTimes: null,
  isShippingFree: false,
  manufacturerProductNumber: null,
  productAssets: null,
  coverId: null,
  tagIds: null,
  categoryIds: null,
  productGroupId: null,
  brandId: '',
};

const NewProduct = () => {
  const { t, i18n } = useTranslation();
  const link = useNavigate();
  const api = usePetCloudApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const productGroupsApi = api.productGroupsApi();
  const productsApi = api.productsApi();
  const warehousesApi = api.warehousesApi();
  const brandsApi = api.brandsApi();
  const productGroupMappingsApi = api.productGroupMappingsApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const { isUsingCentralWarehouse, isUsingManufacturerWarehouse } =
    useManufacturerConditions();
  const { excludeUnlockedOptions } = useUserTools();

  const [originalProduct, setOriginalProduct] =
    useState<CreateProductRequest | null>(null);
  const [product, setProduct] = useState<CreateProductRequest | null>(null);
  const [variantRequests, setVariantRequests] = useState<
    InlineCreateVariantProductRequest[] | null
  >(null);
  const [newProductAssets, setNewProductAssets] = useState<
    ProductAssetResponse[]
  >([]);
  const [newProductPrices, setNewProductPrices] = useState<
    InlineCreateProductPriceRequest[]
  >([]);
  const [warehouses, setWarehouses] = useState<WarehouseResponse[] | null>(
    null
  );
  const [brands, setBrands] = useState<BrandResponse[] | null>(null);
  const [brandOptions, setBrandOptions] = useState<DropdownOption[] | null>(
    null
  );
  const [newProductStocks, setNewProductStocks] = useState<
    InlineCreateProductStockRequest[]
  >([]);
  const [newDeliveryTimes, setNewDeliveryTimes] = useState<
    InlineCreateDeliveryTimeRequest[]
  >([]);
  const [selectedPropertyOptions, setSelectedPropertyOptions] = useState<
    PropertyOptionResponse[]
  >([]);
  const [productCategories, setProductCategories] = useState<
    ProductCategoryResponse[]
  >([]);
  const [productGroups, setProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [animalSpecies, setAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);
  const [productTags] = useState<TagResponse[]>([]);
  const [productIsSubmitting, setProductIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [productGroupMappings, setProductGroupMappings] = useState<
    ProductGroupMappingResponse[] | 'LOADING' | null
  >(null);
  const [availablePropertyGroups, setAvailablePropertyGroups] = useState<
    PropertyGroupResponse[] | null
  >(null);

  // payload persistence via local storage
  const { persistObject, getPersistedObject, removePersistedObjects } =
    useStorageData();
  useEffect(() => {
    persistObject(product, 'new_product_base');
  }, [product]);

  const removeErrors = (keys: string[]) => {
    const update = [...errors];
    keys.forEach((key) => {
      const i = update.findIndex((e) => e.key === key);
      if (i !== -1) {
        update.splice(i, 1);
      }
    });
    setErrors(update);
    return update;
  };

  // initialize all product data
  useEffect(() => {
    initializeProduct();
    getWarehouses();
    getAnimalSpecies();
    getProductGroups();
  }, []);

  // set initial product data
  const initializeProduct = () => {
    const persisted: CreateProductRequest =
      getPersistedObject('new_product_base');
    const newProduct: CreateProductRequest =
      persisted ?? createNewProductRequest;
    brandsApi
      .brandsGetBrands()
      .then((response) => {
        console.log(response);
        setBrands(response.data);
        setBrandOptions(
          response.data.map((brand) => {
            return {
              id: brand.id,
              name:
                brand.name[i18n.language as TranslatedStringIndex] ??
                brand.name['de-DE']!,
            };
          })
        );
        setProduct({ ...newProduct, brandId: response.data[0].id });
        //TODO: let users know if no brand is present yet
        setOriginalProduct({
          ...newProduct,
          brandId: response.data[0].id,
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getWarehouses = () => {
    warehousesApi
      .warehousesGetWarehouses()
      .then((response) => {
        console.log(response);
        setWarehouses(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response);
        setProductGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  useEffect(() => {
    getAvailablePropertyGroups();
  }, [product?.productGroupId, product?.animalSpeciesIds]);

  const getAvailablePropertyGroups = () => {
    propertyGroupsApi
      .propertyGroupsGetPropertyGroups(
        undefined,
        undefined,
        excludeUnlockedOptions(),
        undefined,
        product?.animalSpeciesIds,
        product?.productGroupId ? [product.productGroupId] : undefined
      )
      .then((response) => {
        console.log(response);
        setAvailablePropertyGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroupMappings = (
    productGroupId: string,
    animalSpeciesIds: string[]
  ) => {
    setProductGroupMappings('LOADING');
    productGroupMappingsApi
      .productGroupMappingsGetProductGroupMappings(
        [productGroupId],
        animalSpeciesIds,
        excludeUnlockedOptions()
      )
      .then((response) => {
        console.log(response);
        setProductGroupMappings(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setProductGroupMappings(null);
      });
  };

  const update = (request: CreateProductRequest) => {
    setProduct({ ...request });
  };

  const updateTaxTypeIdentifier = (taxTypeIdentifier: TaxTypeIdentifier) => {
    if (product) {
      setProduct({
        ...product,
        taxTypeIdentifier: taxTypeIdentifier,
      });
    }
  };

  // checks if any product data has been changed
  const checkForChanges = () => {
    return originalProduct !== product;
  };

  const submitChanges = () => {
    if (product) {
      if (originalProduct !== product) {
        submitNewProduct();
      }
    }
  };

  const resetPersistedData = () => {
    removePersistedObjects([
      'new_product_base',
      'new_product_assets',
      'new_product_variants',
      'new_product_categories',
      'new_product_properties',
      'new_product_stocks',
      'new_product_prices',
      'new_product_shipping',
    ]);
  };

  const submitNewProduct = () => {
    if (product && warehouses) {
      setProductIsSubmitting(true);

      // if product is to be stored in the centralwarehouse automatically attach a stock for it
      const newStocks: InlineCreateProductStockRequest[] = [];
      const centralWarehouseId = warehouses.find(
        (warehouse) => warehouse.type === 'CentralWarehouse'
      )?.id;
      console.log(centralWarehouseId);
      console.log(newProductStocks);
      newProductStocks?.forEach((newStock) => {
        if (newStock.warehouseId !== centralWarehouseId) {
          newStocks.push(newStock);
        }
      });
      if (isUsingCentralWarehouse() === true && centralWarehouseId) {
        newStocks.push({
          priority: 1,
          stock: 0,
          available: false,
          warehouseId: centralWarehouseId,
          productId: null,
        });
      }

      const request = {
        ...product,
        name: {
          ...product.name,
          'en-GB': product.name['en-GB'] ?? product.name['de-DE'],
        },
        propertyIds:
          selectedPropertyOptions.length > 0
            ? selectedPropertyOptions.map((property) => {
                return property.id;
              })
            : null,
        productStocks: newStocks.length > 0 ? newStocks : null,
        productPrices:
          newProductPrices.length > 0
            ? newProductPrices
            : variantRequests && variantRequests.length > 0
            ? [
                {
                  gross: 0,
                  listPriceGross: null,
                  quantityStart: null,
                  quantityEnd: null,
                  currencyId: 'f0902077-da87-4a7f-9bbd-c3e05a1f4a22',
                  countryId: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
                  productId: null,
                },
              ]
            : null,
        deliveryTimes: newDeliveryTimes.length > 0 ? newDeliveryTimes : null,
        productAssets:
          newProductAssets.length > 0
            ? newProductAssets.map((asset) => {
                return {
                  position: asset.position,
                  assetId: {
                    'de-DE': asset.asset['de-DE']?.id,
                    'en-GB': asset.asset['en-GB']?.id,
                  },
                  description: asset.description,
                  alternative: asset.alternative,
                  productId: null,
                };
              })
            : null,
        tagIds:
          productTags.length > 0
            ? productTags.map((tag) => {
                return tag.id;
              })
            : null,
        categoryIds:
          productCategories?.length > 0
            ? productCategories.map((category) => {
                return category.id;
              })
            : null,
        variants: variantRequests,
      };

      productsApi
        .productsCreateProduct(request)
        .then((response) => {
          console.log(response);
          Store.addNotification({
            message: t('view.product.notifications.create_successful'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          resetPersistedData();
          setProductIsSubmitting(false);
          link('/products/' + response.data.id);
        })
        .catch((error) => {
          console.log(error);
          setProductIsSubmitting(false);
          errorHandler.addError(error.response, (errs) => {
            setErrors(errs);
          });
        });
    }
  };

  if (!user?.isProductOwner) {
    return (
      <Has
        authorizations={['products:create', 'warehouses:list', 'brands:list']}
        showNoAuthorization={true}
      >
        {product &&
        warehouses &&
        brandOptions &&
        brands &&
        productGroups &&
        animalSpecies ? (
          productGroupMappings === null ? (
            <Stage>
              <Navigator
                title={t('view.product.productGroupMappingWizard.title')}
              />
              <Card bigScreenWidth={'40%'}>
                <CardSection>
                  <ProductGroupMappingWizard
                    product={product}
                    productGroups={productGroups}
                    animalSpecies={animalSpecies}
                    updateProduct={update}
                    onFinish={() => {
                      const productGroupId = product?.productGroupId;
                      const animalSpeciesIds = product?.animalSpeciesIds;
                      if (productGroupId && animalSpeciesIds) {
                        getProductGroupMappings(
                          productGroupId,
                          animalSpeciesIds
                        );
                      }
                    }}
                  />
                </CardSection>
              </Card>
            </Stage>
          ) : productGroupMappings === 'LOADING' ? (
            <LoadingContainer />
          ) : (
            <Stage>
              <Navigator
                title={product.name[i18n.language as TranslatedStringIndex]}
                backLink={'/products'}
              >
                <Button
                  type={'icon-text'}
                  cta={t('actions.reset')}
                  look={'secondary'}
                  width={'minimal'}
                  action={() => {
                    resetPersistedData();
                    window.location.reload();
                  }}
                >
                  <ReloadIcon
                    className={'button-icon'}
                    fill={'var(--color-text_primary)'}
                  />
                </Button>
                <Has authorizations={['products:create']}>
                  <Button
                    cta={t('actions.save')}
                    margin="left"
                    look="primary"
                    action={submitChanges}
                    isLoading={productIsSubmitting}
                    active={checkForChanges()}
                  />
                </Has>
              </Navigator>
              <StageColumns>
                <StageColumn size="two">
                  <HelperCard
                    text={t('view.product.helperCard.new')}
                    knowledgeBaseItems={[
                      {
                        title: t('knowledgebase.product.tutorials.new.title'),
                        text: t('knowledgebase.product.tutorials.new.text'),
                        videoUri: t(
                          'knowledgebase.product.tutorials.new.video'
                        ),
                      },
                    ]}
                    settingName={'product_new'}
                  />
                  <Card bigScreenWidth="100%">
                    <CardSection title={t('view.product.meta.title')}>
                      <Meta
                        product={product}
                        updateNewProduct={update}
                        errors={errors}
                      />
                    </CardSection>
                    <CardSection
                      title={t('view.product.brandproductline.title')}
                    >
                      <BrandProductLine
                        product={product}
                        updateNewProduct={update}
                        availabeBrandOptions={brandOptions}
                      />
                    </CardSection>
                    <CardSection title={t('view.product.information.title')}>
                      <Information
                        product={product}
                        brandName={
                          brands?.find((o) => o.id === product.brandId)?.name
                        }
                        updateNewProduct={update}
                        errors={errors}
                      />
                    </CardSection>
                  </Card>
                  <Card bigScreenWidth="100%">
                    <CardSection>
                      <Media
                        coverId={product.coverId}
                        allowGallery={true}
                        inlineCreationCallback={setNewProductAssets}
                        removeErrors={removeErrors}
                      />
                    </CardSection>
                  </Card>
                  <Has
                    authorizations={['currency:list', 'countries:list']}
                    showNoAuthorization
                  >
                    <Card bigScreenWidth="100%">
                      <CardSection>
                        <Pricing
                          taxTypeIdentifier={product.taxTypeIdentifier}
                          updateTaxTypeIdentifier={updateTaxTypeIdentifier}
                          inlineCreationCallback={(
                            prices: UniversalProductPrice[]
                          ) => {
                            setNewProductPrices([...prices]);
                          }}
                          errors={errors}
                          hint={t('view.product.pricing.hint_newProduct')}
                          removeErrors={removeErrors}
                        />
                      </CardSection>
                    </Card>
                  </Has>
                  <Has authorizations={['warehouses:list']} showNoAuthorization>
                    <Card bigScreenWidth="100%">
                      {isUsingManufacturerWarehouse() === true ? (
                        <CardSection title={t('view.product.stocks.title')}>
                          <Stocks
                            callback={setNewProductStocks}
                            prohibitAddingCentralWarehouse={true}
                            errors={errors}
                          />
                        </CardSection>
                      ) : null}
                      <CardSection>
                        <StockSettings
                          restockTimeDays={product.restockTimeDays}
                          updateRestockTimeDays={(e) =>
                            update({
                              ...product,
                              restockTimeDays: parseInt(e),
                            })
                          }
                          isCloseout={!!product.isCloseout}
                          toggleIsCloseout={() =>
                            update({
                              ...product,
                              isCloseout: !product.isCloseout,
                            })
                          }
                        />
                      </CardSection>
                    </Card>
                  </Has>
                  <Has
                    authorizations={['product_units:list']}
                    showNoAuthorization
                  >
                    <Card bigScreenWidth="100%">
                      <CardSection title={t('view.product.measures.title')}>
                        <Measures
                          product={product}
                          updateNewProduct={update}
                          errors={errors}
                        />
                      </CardSection>
                    </Card>
                  </Has>
                  <Card bigScreenWidth="100%">
                    <CardSection title={t('view.product.cartoptions.title')}>
                      <CartOptions
                        product={product}
                        updateNewProduct={update}
                      />
                    </CardSection>
                  </Card>
                  {isUsingCentralWarehouse() === true ? (
                    <Card bigScreenWidth="100%">
                      <CardSection title={t('view.product.regulations.title')}>
                        <Regulations
                          isBatchControlled={product.isBatchControlled}
                          toggleIsBatchControlled={() => {
                            update({
                              ...product,
                              isBatchControlled: !product.isBatchControlled,
                            });
                          }}
                          isBestBeforeControlled={
                            product.isBestBeforeControlled
                          }
                          toggleIsBestBeforeControlled={() => {
                            update({
                              ...product,
                              isBestBeforeControlled:
                                !product.isBestBeforeControlled,
                            });
                          }}
                          isDangerousGoods={product.isDangerousGoods}
                          toggleIsDangerousGoods={() => {
                            update({
                              ...product,
                              isDangerousGoods: !product.isDangerousGoods,
                            });
                          }}
                        />
                      </CardSection>
                    </Card>
                  ) : null}
                  {isUsingManufacturerWarehouse() === true ? (
                    <Card bigScreenWidth="100%">
                      <CardSection>
                        <Shipping callback={setNewDeliveryTimes} />
                      </CardSection>
                    </Card>
                  ) : null}
                  {availablePropertyGroups ? (
                    <Has
                      authorizations={['property_groups:list']}
                      showNoAuthorization
                    >
                      <Card bigScreenWidth="100%">
                        <CardSection>
                          <Properties
                            callback={setSelectedPropertyOptions}
                            productGroupMappings={productGroupMappings}
                            selectedAnimalSpecies={animalSpecies.filter((s) =>
                              product.animalSpeciesIds.includes(s.id)
                            )}
                            validationOptional={!!variantRequests}
                            availableProductProperties={availablePropertyGroups}
                          />
                        </CardSection>
                      </Card>
                    </Has>
                  ) : (
                    <LoadingContainer />
                  )}
                  <Has
                    authorizations={['property_groups:list']}
                    showNoAuthorization
                  >
                    <Card bigScreenWidth="100%">
                      <CardSection title={t('view.product.variants.title')}>
                        <Variants
                          productVariants={[]}
                          callback={setVariantRequests}
                          animalSpeciesIds={product.animalSpeciesIds}
                          productGroupId={product.productGroupId}
                          productGroupMappings={productGroupMappings}
                        />
                      </CardSection>
                    </Card>
                  </Has>
                </StageColumn>
                <StageColumn size="one">
                  <Card bigScreenWidth="100%">
                    <CardSection>
                      <AnimalSpeciesAndProductGroup
                        product={product}
                        updateNewProduct={update}
                        locked={false}
                        errors={errors}
                        onFinish={() => {
                          const productGroupId = product?.productGroupId;
                          const animalSpeciesIds = product?.animalSpeciesIds;
                          if (productGroupId && animalSpecies) {
                            getProductGroupMappings(
                              productGroupId,
                              animalSpeciesIds
                            );
                          }
                        }}
                      />
                    </CardSection>
                    <CardSection>
                      <Categories
                        callback={setProductCategories}
                        errors={errors}
                        productGroupId={product.productGroupId}
                        animalSpeciesIds={product.animalSpeciesIds}
                      />
                    </CardSection>
                  </Card>
                </StageColumn>
              </StageColumns>
            </Stage>
          )
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return (
      <Stage>
        <Navigator allowBackButton />
        <Card bigScreenWidth={'100%'}>
          <CardSection>
            <NoAuthorization
              message={t('view.product.emptyStates.productOwner')}
            />
          </CardSection>
        </Card>
      </Stage>
    );
  }
};

export default NewProduct;
