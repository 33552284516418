import './faq.css';
import { useTranslation } from 'react-i18next';
import { TranslatedTypeRequestOfDictionaryOfStringAndString } from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import Input from '../../../elements/input/Input';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { ReactComponent as Trash } from '../../../../assets/icon/trash.svg';

interface FaqProps {
  faq?: TranslatedTypeRequestOfDictionaryOfStringAndString | null;
  updateFaq: (faq: TranslatedTypeRequestOfDictionaryOfStringAndString) => void;
  selectedLocale: TranslatedStringIndex;
}

const Faq: React.FC<FaqProps> = ({ faq, updateFaq, selectedLocale }) => {
  const { t } = useTranslation();

  const currentFaq = faq ? faq[selectedLocale] : null;
  const faqArray = currentFaq ? Object.entries(currentFaq) : null;

  return (
    <div className="faq">
      {faqArray
        ? faqArray.map(([title, text], i) => {
            return (
              <div className="faq-entry">
                <div className="faq-entry-header">
                  <div className="faq-entry-header-title">
                    {t('view.shopcustomization.edit.faq.entry.title') +
                      ' #' +
                      (i + 1)}
                  </div>
                  <div className="faq-entry-header-actions">
                    <Button
                      type="icon"
                      look={'secondary'}
                      action={() => {
                        faqArray.splice(i, 1);

                        updateFaq({
                          ...faq,
                          [selectedLocale]: Object.fromEntries(faqArray),
                        });
                      }}
                    >
                      <Trash
                        fill="var(--color-danger)"
                        className="button-icon"
                      />
                    </Button>
                  </div>
                </div>
                <div className="faq-entry-title">
                  <Input
                    title={t('view.shopcustomization.edit.faq.entry.title')}
                    content={title}
                    update={(e) => {
                      faqArray[i] = [e, text];

                      updateFaq({
                        ...faq,
                        [selectedLocale]: Object.fromEntries(faqArray),
                      });
                    }}
                  />
                </div>
                <div className="faq-entry-text">
                  <Input
                    title={t('view.shopcustomization.edit.faq.entry.text')}
                    content={text}
                    update={(e) => {
                      faqArray[i] = [title, e];

                      updateFaq({
                        ...faq,
                        [selectedLocale]: Object.fromEntries(faqArray),
                      });
                    }}
                  />
                </div>
              </div>
            );
          })
        : null}
      <div className="faq-actions">
        <Button
          cta={t('view.shopcustomization.edit.faq.add')}
          look={'secondary'}
          width="minimal"
          action={() => {
            if (faqArray) {
              faqArray.push(['', '']);
              updateFaq({
                ...faq,
                [selectedLocale]: Object.fromEntries(faqArray),
              });
            } else {
              const init: any = [];
              init.push(['', '']);
              updateFaq({
                ...faq,
                [selectedLocale]: Object.fromEntries(init),
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default Faq;
