import './balanceaccount.css';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import { useState } from 'react';
import { CreateAdyenBalanceAccountRequest } from '../../../../../api/petcloudapi/api';
import FormBuilder from '../../../../../elements/formbuilder/FormBuilder';
import useCommonDropDownOptions from '../../../../../hooks/useCommonDropDownOptions';
import Button from '../../../../../elements/button/Button';

interface BalanceAccountProps {
  closePopup: () => void;
  accountHolderId: string;
  legalEntityName?: string;
  refreshManufacturer: () => void;
}

const BalanceAccount: React.FC<BalanceAccountProps> = ({
  closePopup,
  accountHolderId,
  legalEntityName,
  refreshManufacturer,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturer.adyenWizard.balanceAccount',
  });
  const api = usePetCloudApi();
  const adyenBalanceAccountsApi = api.adyenBalanceAccountsApi();
  const errorHandler = useErrorHandler();
  const { currencyOptions } = useCommonDropDownOptions();
  const { pushNotification } = useNotifications();

  const [newBalanceAccount, setNewBalanceAccount] =
    useState<CreateAdyenBalanceAccountRequest>({
      accountHolderId: accountHolderId,
      description: `${legalEntityName} - Balance Account`,
      reference: `${legalEntityName} - Balance Account`,
      defaultCurrencyCode: 'EUR',
    });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createBalanceAccount = () => {
    setIsSubmitting(true);
    adyenBalanceAccountsApi
      .adyenBalanceAccountsCreateBalanceAccount(newBalanceAccount)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.create_successful'));
        setIsSubmitting(false);
        closePopup();
        refreshManufacturer();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'balanceAccount'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement global-textElement'}>{t('text')}</div>
      <div className={'balanceAccount-form'}>
        <FormBuilder
          contentObject={newBalanceAccount}
          setContentObject={setNewBalanceAccount}
          elements={[
            {
              type: 'input',
              title: t('form.description'),
              objectPath: 'description',
            },
            {
              type: 'input',
              title: t('form.reference'),
              objectPath: 'reference',
            },
            {
              type: 'dropdownMenu',
              title: t('form.defaultCurrencyCode'),
              objectPath: 'defaultCurrencyCode',
              optionObjects: currencyOptions,
            },
          ]}
        />
      </div>
      <div className={'global-cardActions'}>
        <Button
          cta={t('cta')}
          look={'save'}
          width={'full'}
          action={createBalanceAccount}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  );
};

export default BalanceAccount;
