import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Stage,
  Navigator,
  StageColumns,
  StageColumn,
} from '../../layout/stage/Stage';
import Card, { CardSection, HelperCard } from '../../elements/card/Card';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  OrderResponse,
  OrderReturnAnnouncementResponse,
  OrderShippingDetailResponse,
} from '../../api/petcloudapi/api';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../elements/loading/Loading';
import Customer from '../../sections/order/customer/Customer';
import Payment from '../../sections/order/payment/Payment';
import ShippingAddress from '../../sections/order/shippingaddress/ShippingAddress';
import InvoiceAddress from '../../sections/order/invoiceaddress/InvoiceAddress';
import {
  getRefundPositionEntries,
  getOrderPositionEntries,
} from '../../sections/order/positions/Positions';
import StateHistories from '../../sections/order/statehistories/StateHistories';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import Refunding from '../../sections/order/refunding/Refunding';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import ShippingDetails from '../../sections/order/shippingdetails/ShippingDetails';
import Returns from '../../sections/order/returns/Returns';
import Refunds from '../../sections/order/refunds/Refunds';
import { Has } from '../../contexts/auth/Authorization';
import Receipts from '../../sections/order/receipts/Receipts';
import { useUser } from '../../contexts/auth/User';
import Reference from '../../sections/order/reference/Reference';
import { BadgeProps } from '../../elements/badge/Badge';
import List from '../../features/list/List';
import useManufacturerConditions from '../../hooks/useManufacturerConditions';
import ReturnAnnouncements from '../../sections/order/returnannouncements/ReturnAnnouncements';
import useDateTools from '../../hooks/useDateTools';
import PositionEntry from '../../types/PositionEntry';
import Hoverable from '../../elements/hoverable/Hoverable';
import { HintBox } from '../../elements/hint/Hint';
import { ReactComponent as IconBundle } from '../../../assets/icon/bundle.svg';
import { ReactComponent as IconTime } from '../../../assets/icon/history.svg';
import { ReactComponent as IconManufacturer } from '../../../assets/icon/team.svg';
import { ReactComponent as IconWarehouse } from '../../../assets/icon/sidebar/logistics.svg';
import '../../sections/order/positions/positions.css';

const Order = () => {
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const { isOrderReturnAnnouncementAware } = useManufacturerConditions();
  const { displayReadableDate } = useDateTools();
  const params = useParams();
  const orderId = params.orderId;
  const api = usePetCloudApi();
  const ordersApi = api.ordersApi();
  const errorHandler = useErrorHandler();

  const [order, setOrder] = useState<OrderResponse | null>(null);
  const [refundPopup, setRefundPopup] = useState(false);

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = () => {
    if (orderId) {
      setOrder(null);
      ordersApi
        .ordersGetOrderById(orderId, true)
        .then((response) => {
          console.log(response);
          setOrder(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const isViableForRefunding = () => {
    const currentState =
      order?.currentTransactionState.orderState?.technicalName;
    if (currentState) {
      return ['Authorized', 'Paid', 'RefundedPartially'].includes(currentState);
    } else {
      return false;
    }
  };

  const getBadges = (o: OrderResponse) => {
    const badges: BadgeProps[] = [
      {
        title: displayReadableDate(o.createdAt),
        color: 'var(--color-gray)',
        textColor: 'var(--color-text_primary)',
        icon: (
          <IconTime
            className={'badge-icon'}
            fill={'var(--color-text_primary)'}
          />
        ),
      },
    ];
    if (o.manufacturer?.companyName && user?.isProductOwner) {
      badges.push({
        title: o.manufacturer?.companyName,
        color: 'var(--color-gray)',
        textColor: 'var(--color-text_primary)',
        icon: (
          <IconManufacturer
            className={'badge-icon'}
            fill={'var(--color-text_primary)'}
          />
        ),
      });
    }
    if (o.warehouse?.name) {
      badges.push({
        title: o.warehouse.name,
        color: 'var(--color-gray)',
        textColor: 'var(--color-text_primary)',
        icon: (
          <IconWarehouse
            className={'badge-icon'}
            fill={'var(--color-text_primary)'}
          />
        ),
      });
    }
    if (o.customer?.isGuest) {
      badges.push({
        title: t('view.order.isGuest'),
        color: 'var(--color-primary)',
      });
    }
    return badges;
  };

  const flatMapAccouncements = (
    shippingDetails: OrderShippingDetailResponse[]
  ) => {
    const announcements: OrderReturnAnnouncementResponse[] = [];
    shippingDetails.forEach((detail) => {
      detail.orderReturnAnnouncements?.forEach((announcement) => {
        announcements.push(announcement);
      });
    });
    return announcements;
  };

  const renderName = (name: string, position: PositionEntry) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {name}
        {position.bundleName ? (
          <div style={{ marginLeft: 10 }}>
            <Hoverable
              helperClass={'position-bundle'}
              onHoverNode={
                <HintBox
                  paragraphs={[`Bundle: ${position.bundleName}`]}
                  isToolTip={true}
                />
              }
            >
              <IconBundle className={'position-bundle-icon'} />
            </Hoverable>
          </div>
        ) : null}
      </div>
    );
  };

  const orderReturnAnnouncementAware = isOrderReturnAnnouncementAware(
    order?.manufacturerId
  );
  const orderReturnAnnouncementAwareOverride =
    orderReturnAnnouncementAware === 'PO_OVERRIDE';

  return (
    <Has authorizations={['orders:detail']} showNoAuthorization>
      {orderId && order ? (
        <Stage>
          <Navigator
            title={t('view.order.title') + ' ' + order.orderNumber}
            badges={getBadges(order)}
            allowBackButton
          >
            <Button
              cta={t('view.order.refund')}
              action={() => setRefundPopup(true)}
              width="minimal"
              look="secondary-danger"
              active={isViableForRefunding()}
            />
          </Navigator>
          <StageColumns>
            <StageColumn size="two">
              {order.warehouse?.type === 'ManufacturerWarehouse' ? (
                <HelperCard
                  text={t('view.order.helperCard.dropshipping')}
                  knowledgeBaseItems={[
                    {
                      title: t(
                        'knowledgebase.order.overview.dropshipping.title'
                      ),
                      text: t('knowledgebase.order.overview.dropshipping.text'),
                      videoUri: t(
                        'knowledgebase.order.overview.dropshipping.media'
                      ),
                    },
                  ]}
                  settingName={'product_dropshipping'}
                />
              ) : (
                <HelperCard
                  text={t('view.order.helperCard.central')}
                  knowledgeBaseItems={[
                    {
                      title: t('knowledgebase.order.overview.central.title'),
                      text: t('knowledgebase.order.overview.central.text'),
                      videoUri: t('knowledgebase.order.overview.central.media'),
                    },
                  ]}
                  settingName={'order_central'}
                />
              )}
              <Card bigScreenWidth="100%">
                <CardSection title={t('view.order.positions.title')}>
                  <List
                    items={getOrderPositionEntries(
                      order.orderPositions,
                      i18n.language as TranslatedStringIndex,
                      order.currency?.symbol
                    )}
                    ignore={[
                      'id',
                      'productId',
                      'positionId',
                      'refundAmountCurrency',
                      'currency',
                      'bundleName',
                      'isVariant',
                    ]}
                    linkedKey={'productId'}
                    linkPrefix={(item) =>
                      item.isVariant ? '/products/variant/' : '/products/'
                    }
                    isNewTabLink={true}
                    itemImgKey={'coverUri'}
                    monoSpaceStrings={['productNumber', 'ean']}
                    priceKeysConfig={{
                      priceKeys: ['price'],
                      currencySymbol: order.currency?.symbol,
                    }}
                    renderObjects={[
                      {
                        key: 'name',
                        renderMethod: renderName,
                      },
                    ]}
                  />
                </CardSection>
              </Card>
              <Card bigScreenWidth="100%">
                <CardSection title={t('view.order.payment.title')}>
                  <Payment
                    price={order.price}
                    shippingCosts={order.shippingCosts}
                    commission={order.commission}
                    currencySymbol={order.currency?.symbol}
                    initialPaymentMethodBrand={order.initialPaymentMethodBrand}
                  />
                </CardSection>
              </Card>
              {order.orderShippingDetails ? (
                <Card bigScreenWidth="100%">
                  <CardSection title={t('view.order.shippingDetails.title')}>
                    <ShippingDetails
                      order={order}
                      userCanCreate={
                        order.warehouse?.type === 'ManufacturerWarehouse'
                      }
                      orderPositions={order.orderPositions}
                      shippingDetails={order.orderShippingDetails}
                      refreshOrder={getOrder}
                      currency={order.currency?.symbol ?? ''}
                    />
                  </CardSection>
                </Card>
              ) : null}
              {orderReturnAnnouncementAware ? (
                <Card
                  bigScreenWidth="100%"
                  productOwnerOverride={orderReturnAnnouncementAwareOverride}
                >
                  <CardSection
                    title={t('view.order.returnAnnouncements.title')}
                  >
                    <ReturnAnnouncements
                      announcements={
                        order.orderShippingDetails
                          ? flatMapAccouncements(order.orderShippingDetails)
                          : null
                      }
                      order={order}
                      getOrder={getOrder}
                    />
                  </CardSection>
                </Card>
              ) : null}
              <Card bigScreenWidth="100%">
                <CardSection
                  title={t('view.order.returns.title')}
                  knowledgeBaseItems={[
                    {
                      title: t(
                        'knowledgebase.order.tutorials.returnAnnouncement.title'
                      ),
                      text: t(
                        'knowledgebase.order.tutorials.returnAnnouncement.text'
                      ),
                      videoUri: t(
                        'knowledgebase.order.tutorials.returnAnnouncement.media'
                      ),
                    },
                  ]}
                >
                  <Returns
                    order={order}
                    returns={order.orderReturns}
                    orderPositions={order.orderPositions}
                    userCanCreate={
                      order.warehouse?.type === 'ManufacturerWarehouse'
                    }
                    isViableForRefunding={isViableForRefunding()}
                    getOrder={getOrder}
                  />
                </CardSection>
              </Card>
              <Card bigScreenWidth={'100%'}>
                <CardSection title={t('view.order.refunds.title')}>
                  <Refunds
                    positions={order.orderPositions}
                    refunds={order.adyenPaymentRefunds}
                    refundedShippingCosts={
                      order.shippingCosts?.totalPrice &&
                      order.shippingCosts.totalPrice > 0.0
                        ? order.refundedShippingCosts
                        : undefined
                    }
                    currency={order.currency}
                  />
                </CardSection>
              </Card>
              {order.orderStateHistories ? (
                <Card
                  bigScreenWidth="100%"
                  title={t('view.order.stateHistories.title')}
                >
                  <StateHistories
                    orderId={order.id}
                    order={order}
                    stateHistories={order.orderStateHistories}
                    refreshOrder={getOrder}
                  />
                </Card>
              ) : null}
            </StageColumn>
            <StageColumn size="one">
              {order.originOrderId ||
              order.splitOrders ||
              order.fiegeSalesOrderNumber ? (
                <Card bigScreenWidth="100%">
                  <CardSection title={t('view.order.reference.title')}>
                    <Reference order={order} />
                  </CardSection>
                </Card>
              ) : null}
              <Card bigScreenWidth="100%">
                <CardSection title={t('view.order.customer.title')}>
                  {order.customer ? (
                    <Customer customer={order.customer} orderId={order.id} />
                  ) : null}
                </CardSection>
              </Card>
              <Card bigScreenWidth="100%">
                <CardSection title={t('view.order.address.shippingAddress')}>
                  <ShippingAddress address={order.orderShippingAddress} />
                </CardSection>
                <CardSection title={t('view.order.address.invoiceAddress')}>
                  <InvoiceAddress address={order.orderInvoiceAddress} />
                </CardSection>
              </Card>
              <Card bigScreenWidth={'100%'}>
                <CardSection title={t('view.order.receipts.title')}>
                  <Receipts receipts={order.orderReceipts} />
                </CardSection>
              </Card>
            </StageColumn>
          </StageColumns>
          <Popup
            width={'70%'}
            toggled={refundPopup}
            close={() => setRefundPopup(false)}
          >
            <div className="popup-title">{t('view.order.refunding.title')}</div>
            <Refunding
              order={order}
              currency={order.currency?.symbol}
              positions={getRefundPositionEntries(
                order.orderPositions,
                i18n.language as TranslatedStringIndex,
                order.currency?.symbol
              )}
              refreshOrder={getOrder}
              successCallback={() => setRefundPopup(false)}
            />
          </Popup>
        </Stage>
      ) : (
        <LoadingContainer />
      )}
    </Has>
  );
};

export default Order;
