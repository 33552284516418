import './newpropertyoption.css';
import {
  AnimalSpeciesResponse,
  AssetFolderResponse,
  AssetResponse,
  CreatePropertyOptionRequest,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Input from '../../../../../elements/input/Input';
import {
  LoadingContainer,
  MinimalLoading,
} from '../../../../../elements/loading/Loading';
import Button from '../../../../../elements/button/Button';
import Dropzone from '../../../../../elements/dropzone/Dropzone';
import Popup from '../../../../../elements/popup/Popup';
import FileBrowser from '../../../../../elements/filebrowser/FileBrowser';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { ReactComponent as Trash } from '../../../../../../assets/icon/trash.svg';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../../hooks/useNotifications';
import AnimalSpeciesArray from '../../../animalspecies/animalspeciesarray/AnimalSpeciesArray';
import ToggleSwitch from '../../../../../elements/toggleswitch/ToggleSwitch';

const newPropertyOption = (isVariantOption?: boolean) => ({
  name: {
    'de-DE': '',
    'en-GB': '',
  },
  description: {
    'de-DE': '',
    'en-GB': '',
  },
  position: 0,
  isVariantOption: isVariantOption !== undefined ? isVariantOption : false,
  assetId: null,
  propertyGroupId: 'string',
  identifier: '',
});

interface NewPropertyOptionProps {
  propertyGroup: PropertyGroupResponse;
  newPosition: number;
  postSubmit: (option: PropertyOptionResponse) => void;
  isVariantOption?: boolean;
}

const NewPropertyOption: React.FC<NewPropertyOptionProps> = ({
  propertyGroup,
  newPosition,
  postSubmit,
  isVariantOption,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.properties.newOptionPopup',
  });
  const api = usePetCloudApi();
  const assetFoldersApi = api.assetFoldersApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const propertyOptionsApi = api.propertyOptionsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [availableAnimalSpecies, setAvailableAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);
  const [assetFolders, setAssetFolders] = useState<
    AssetFolderResponse[] | null
  >(null);
  const [indexFolder, setIndexFolder] = useState<AssetFolderResponse | null>(
    null
  );
  const [request, setRequest] = useState<CreatePropertyOptionRequest>({
    ...newPropertyOption(isVariantOption),
    position: newPosition,
    propertyGroupId: propertyGroup.id,
    isLocked: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getAssetFolders();
    getAnimalSpecies();
  }, []);

  const getAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAvailableAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAssetFolders = () => {
    assetFoldersApi
      .assetFoldersGetAssetFolders()
      .then((response) => {
        console.log(response);
        setAssetFolders(response.data);
        const homeFolder = response.data.find((folder) => folder.isRoot);
        if (homeFolder) {
          setIndexFolder(homeFolder);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAssetUpload = (assets: AssetResponse[]) => {
    setRequest({
      ...request,
      assetId: assets[0].id,
    });
  };

  const submitNewPropertyOption = () => {
    setIsSubmitting(true);
    propertyOptionsApi
      .propertyOptionsCreatePropertyOption(request)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.success'));
        setIsSubmitting(false);
        postSubmit(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  if (availableAnimalSpecies) {
    return (
      <div className="newPropertyOption">
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('identifier')}
              content={request.identifier}
              update={(e) => {
                setRequest({
                  ...request,
                  identifier: e,
                });
              }}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={propertyGroup.isLanguageNeutral ? t('name') : t('nameDE')}
              content={request.name['de-DE']}
              update={(e) => {
                setRequest({
                  ...request,
                  name: {
                    ...request.name,
                    'de-DE': e,
                  },
                });
              }}
            />
          </div>
          {!propertyGroup.isLanguageNeutral ? (
            <div className="global-inputGroup-input">
              <Input
                title={t('nameEN')}
                content={request.name['en-GB']}
                update={(e) => {
                  setRequest({
                    ...request,
                    name: {
                      ...request.name,
                      'en-GB': e,
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <Input
              title={t('descriptionDE')}
              content={
                request.description ? request.description['de-DE'] : null
              }
              update={(e) => {
                setRequest({
                  ...request,
                  description: {
                    ...request.description,
                    'de-DE': e,
                  },
                });
              }}
            />
          </div>
          <div className="global-inputGroup-input">
            <Input
              title={t('descriptionEN')}
              content={
                request.description ? request.description['en-GB'] : null
              }
              update={(e) => {
                setRequest({
                  ...request,
                  description: {
                    ...request.description,
                    'en-GB': e,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <ToggleSwitch
              title={t('isVariantOption')}
              toggled={request.isVariantOption}
              toggle={() => {
                setRequest({
                  ...request,
                  isVariantOption: !request.isVariantOption,
                });
              }}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <AnimalSpeciesArray
              animalSpecies={availableAnimalSpecies.filter((s) =>
                request.animalSpeciesIds?.includes(s.id)
              )}
              availableAnimalSpecies={availableAnimalSpecies}
              onDelete={(index) => {
                let update = request.animalSpeciesIds;
                if (update) {
                  update.splice(index, 1);
                }
                setRequest({
                  ...request,
                  animalSpeciesIds: update,
                });
              }}
              onSubmit={(animalSpecies) => {
                let update = request.animalSpeciesIds;
                if (update) {
                  update.push(animalSpecies.id);
                } else {
                  update = [animalSpecies.id];
                }
                setRequest({
                  ...request,
                  animalSpeciesIds: update,
                });
              }}
            />
          </div>
        </div>
        <div className="global-inputGroup">
          <div className="global-inputGroup-input">
            <div className="newPropertyOption-newOptionPopup-header">
              <div className="global-input-title">{t('asset')}</div>
              {assetFolders && indexFolder ? (
                <div
                  className="newPropertyOption-newOptionPopup-header-action"
                  onClick={() => setGalleryPopup(true)}
                >
                  {t('gallery')}
                </div>
              ) : (
                <MinimalLoading />
              )}
            </div>
            {request.assetId ? (
              <div className="newPropertyOption-newOptionPopup-assetId">
                <div>{t('assetId') + ': ' + request.assetId}</div>
                <Button
                  look={'secondary'}
                  type="icon"
                  action={() => {
                    setRequest({
                      ...request,
                      assetId: null,
                    });
                  }}
                >
                  <Trash fill="var(--color-danger)" className="button-icon" />
                </Button>
              </div>
            ) : (
              <Dropzone
                maxFiles={1}
                callback={handleAssetUpload}
                height={100}
                assetContext={'Property'}
              />
            )}
          </div>
        </div>
        <Popup
          width="60%"
          toggled={galleryPopup}
          close={() => setGalleryPopup(false)}
        >
          {assetFolders && indexFolder ? (
            <FileBrowser
              assetFolders={assetFolders}
              allowUrlNavigation={false}
              allowAssetUpload={false}
              onChooseAssets={(assets) => {
                handleAssetUpload(assets);
                setGalleryPopup(false);
              }}
            />
          ) : (
            <LoadingContainer />
          )}
        </Popup>
        <div className={'global-cardActions'}>
          <Button
            cta={t('submit')}
            width={'minimal'}
            look="save"
            action={() => {
              submitNewPropertyOption();
            }}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default NewPropertyOption;
