import './statemanagement.css';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  ProductResponse,
  ProductState,
  ProductVariantResponse,
  ProductVersionResponse,
} from '../../../api/petcloudapi/api';
import { useUser } from '../../../contexts/auth/User';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import {
  Error,
  useErrorHandler,
} from '../../../contexts/errorhandler/ErrorHandler';
import InformationBox from '../../../elements/informationbox/InformationBox';
import List from '../../../features/list/List';
import MinifiedVariant from '../../../types/MinifiedVariant';
import { ReactComponent as ErrorIcon } from '../../../../assets/icon/warning.svg';
import { SmallHint } from '../../../elements/hint/Hint';
import { ReactComponent as Link } from '../../../../assets/icon/link.svg';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../../elements/loading/Loading';
import {
  minifyVariants,
  renderProductStateInList,
} from '../../../features/productlist/ProductList';
import DateTime from '../../../elements/datetime/DateTime';
import ToggleSwitch from '../../../elements/toggleswitch/ToggleSwitch';
import useStorageData from '../../../hooks/useStorageData';

interface StateManagementProps {
  product: ProductResponse;
  refreshProduct: () => void;
  refreshVariants: () => void;
  refreshProductVersions: () => void;
  variants?: ProductVariantResponse[] | null;
  readonly?: boolean;
  isPerformingCriticalAction: boolean;
  queueCriticalAction: (action: string) => void;
  killCriticalAction: (action: string) => void;
  setErrors: Dispatch<SetStateAction<Error[]>>;
  isVariant?: boolean;
}

const StateManagement: React.FC<StateManagementProps> = ({
  product,
  refreshProduct,
  refreshVariants,
  refreshProductVersions,
  variants,
  readonly,
  isPerformingCriticalAction,
  queueCriticalAction,
  killCriticalAction,
  setErrors,
  isVariant,
}) => {
  const { t } = useTranslation();
  const link = useNavigate();
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const productVersionsApi = api.productVersionsApi();
  const { user } = useUser();
  const errorHandler = useErrorHandler();
  const { persistObject, getPersistedObject } = useStorageData();
  const [minifiedVariants, setMinifiedVariants] = useState<
    MinifiedVariant[] | null
  >(minifyVariants(variants));
  const [sendToApprovalModal, setSendToApprovalModal] = useState(false);
  const [activationPopup, setActivationPopup] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState<MinifiedVariant[]>(
    []
  );
  const [removeFromApprovalModal, setRemoveFromApprovalModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [parentActiveVersion, setParentActiveVersion] = useState<
    ProductVersionResponse | null | undefined
  >(null);
  const [updateProgress, setUpdateProgress] = useState<{
    progress: number;
    max: number;
  } | null>(null);
  const [willSubmitParentProduct, setWillSubmitParentProduct] = useState(true);

  useEffect(() => {
    console.log('minfying variants...');
    const minified = minifyVariants(variants);
    console.log(minified);
    if (minified) {
      setMinifiedVariants([...minified]);
    }
    getErrorsFromCookies();
  }, [variants]);

  const getErrorsFromCookies = () => {
    if (minifiedVariants) {
      const update = minifiedVariants.map((variant) => {
        const errs = getPersistedObject(
          'productValidationErrors_' + variant.id
        );
        return {
          ...variant,
          hasErrors: !!errs,
        };
      });
      setMinifiedVariants(update);
    }
  };

  useEffect(() => {
    getParentActiveVersionId(product.parentId);
  }, []);

  const getParentActiveVersionId = (parentId: string | null | undefined) => {
    if (isVariant && parentId) {
      productVersionsApi
        .productVersionsGetProductVersions(parentId)
        .then((response) => {
          console.log(response);
          const activeVersion = response.data.find(
            (version) => version.isActive
          );
          setParentActiveVersion(activeVersion);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setParentActiveVersion(undefined);
    }
  };

  const submitProductStateUpdates = async (state: ProductState) => {
    setIsSubmitting(true);
    queueCriticalAction('updatingState');
    const ids: string[] = [];

    // first all selected variants need to be updated (if applicable)
    selectedVariants.forEach((variant) => {
      ids.push(variant.id);
    });
    console.log(ids);

    // if the approval should be withdrawn from a parent automatically do that also for all variants
    if (state === 'Draft') {
      minifiedVariants?.forEach((variant) => {
        ids.push(variant.id);
      });
    }
    // then add the current product id
    // if the current product is a parent product only do that if action toggled
    if (isVariant || willSubmitParentProduct) {
      ids.push(product.id);
    }

    // if the product is a variant itself and the action is to set Active also update the parent
    // if the product is a variant itself and the action is to set ForApproval also update the parent in case there is no active version
    if (isVariant && product.parentId) {
      if (state === 'Active' && !product.parent.activeProductVersionId) {
        ids.push(product.parentId);
      } else if (
        state === 'ForApproval' &&
        !product.parent.activeProductVersionId
      ) {
        ids.push(product.parentId);
      }
    }

    // reverse array in case of product activation
    // in this case, parent products need to be activated first
    if (state === 'Active') {
      ids.reverse();
    }

    setUpdateProgress({
      progress: 0,
      max: ids.length,
    });

    console.log(ids);

    const results: string[] = [];
    for (const id of ids) {
      try {
        await updateProductState(
          id,
          state,
          id !== product.id || isVariant === true,
          id === product.id
        );
        results.push('success');
        console.log({
          progress: results.length,
          max: ids.length,
        });
        setUpdateProgress({
          progress: results.length,
          max: ids.length,
        });
      } catch {
        results.push('failed');
        console.log({
          progress: results.length,
          max: ids.length,
        });
        setUpdateProgress({
          progress: results.length,
          max: ids.length,
        });
      }
    }

    getErrorsFromCookies();
    if (!results.find((r) => r === 'failed')) {
      Store.addNotification({
        message: t('view.product.notifications.stateUpdate_successful'),
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
      setTimeout(() => {
        setSendToApprovalModal(false);
        setRemoveFromApprovalModal(false);
        setActivationPopup(false);
        setUpdateProgress(null);
        refreshProductVersions();
      }, 1000);
    } else {
      Store.addNotification({
        message: t('view.product.notifications.stateUpdate_errors'),
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
      setTimeout(() => {
        setUpdateProgress(null);
      }, 1000);
    }
    refreshProduct();
    refreshVariants();
    killCriticalAction('updatingState');
    setSelectedVariants([]);
    setIsSubmitting(false);
  };

  // this should return a promise
  const updateProductState = (
    productId: string,
    state: ProductState,
    moveErrorsToCookies: boolean,
    showErrorsInView: boolean
  ) => {
    return new Promise((resolve, reject) => {
      productsApi
        .productsUpdateProductState(productId, {
          state: state,
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response, (errs) => {
            if (moveErrorsToCookies && !showErrorsInView) {
              persistObject(
                JSON.stringify(errs),
                'productValidationErrors_' + productId
              );
            }
            if (showErrorsInView) {
              setErrors(errs);
            }
          });
          reject(error);
        });
    });
  };

  const toggleVariant = (variant: MinifiedVariant) => {
    const update = [...selectedVariants];
    const i = update.findIndex((v) => v.id === variant.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(variant);
    }
    setSelectedVariants(update);
  };

  const toggleAllVariants = () => {
    if (
      minifiedVariants &&
      selectedVariants.length !== minifiedVariants.length
    ) {
      setSelectedVariants(minifiedVariants);
    } else {
      setSelectedVariants([]);
    }
  };

  const renderVariantsList = () => {
    return minifiedVariants && minifiedVariants.length > 0 ? (
      <div className="statemanagement-variants">
        <List
          items={minifiedVariants}
          ignore={['id', 'activeProductVersionId', 'hasErrors']}
          itemImgKey={'coverUri'}
          selectedItems={selectedVariants}
          onSelect={toggleVariant}
          onSelectAll={toggleAllVariants}
          onRowClick={toggleVariant}
          renderObjects={[
            {
              key: 'state',
              renderMethod: (value, item) =>
                renderProductStateInList(value, item, t),
            },
            {
              key: 'hasErrors',
              renderMethod: renderHasErrors,
            },
          ]}
          actions={[
            {
              cta: 'edit',
              look: 'blue',
              action: (item) => link('/products/variant/' + item.id),
            },
          ]}
        />
      </div>
    ) : null;
  };

  const renderHasErrors = (hasErrors: boolean) => {
    if (hasErrors) {
      return <ErrorIcon fill="var(--color-danger)" className="list-warning" />;
    } else {
      return null;
    }
  };

  const renderProgressBar = () => {
    if (updateProgress) {
      return (
        <div className="statemanagement-progressbar">
          <div className="statemanagement-progressbar-bar">
            <div
              className="statemanagement-progressbar-bar-progress"
              style={{
                width:
                  Math.round(
                    (updateProgress.progress / updateProgress.max) * 100
                  ) + '%',
              }}
            ></div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="statemanagement">
      {product.state === 'ForApproval' ? (
        user?.isProductOwner ? (
          <div className={'statemanagement-actions'}>
            <Button
              cta={t('view.product.publishing.activate')}
              look="save"
              width="full"
              action={() => setActivationPopup(true)}
            />
            <Button
              cta={t('view.product.publishing.removeFromApproval')}
              look="secondary-danger"
              width="full"
              action={() => setRemoveFromApprovalModal(true)}
              active={!readonly}
            />
          </div>
        ) : (
          <Button
            cta={t('view.product.publishing.removeFromApproval')}
            look="secondary-danger"
            width="full"
            action={() => setRemoveFromApprovalModal(true)}
            active={!readonly}
          />
        )
      ) : (
        <Button
          cta={t('view.product.publishing.sendToApproval')}
          look={'secondary'}
          width="full"
          action={() => setSendToApprovalModal(true)}
          active={!readonly}
        />
      )}
      <Popup
        width={'30%'}
        close={() => setActivationPopup(false)}
        toggled={activationPopup && product.state === 'ForApproval'}
        title={t('view.product.publishing.popup_activate.title')}
      >
        <InformationBox
          title={t(
            'view.product.publishing.popup_activate.activationWarning.title'
          )}
          content={t(
            'view.product.publishing.popup_activate.activationWarning.text'
          )}
          type="warning"
          dismissSettingName={'product_stateManagament_activationWarning'}
        />
        {minifiedVariants && minifiedVariants.length > 0 ? (
          <div className="statemanagement-popupActions-toggle">
            <ToggleSwitch
              label={t('view.product.publishing.popup_activate.toggle')}
              toggled={willSubmitParentProduct}
              toggle={() =>
                setWillSubmitParentProduct(!willSubmitParentProduct)
              }
            />
          </div>
        ) : null}
        {renderVariantsList()}
        <div className="global-cardActions-postBorder">
          {updateProgress ? renderProgressBar() : null}
          <Button
            cta={t('view.product.publishing.popup_activate.cta')}
            width="minimal"
            look="save"
            action={() => submitProductStateUpdates('Active' as ProductState)}
            active={
              !isPerformingCriticalAction &&
              (!isVariant
                ? selectedVariants?.length > 0 || willSubmitParentProduct
                : true)
            }
            isLoading={isSubmitting}
          />
        </div>
      </Popup>
      <Popup
        width={'30%'}
        close={() => setSendToApprovalModal(false)}
        toggled={sendToApprovalModal}
        title={t('view.product.publishing.popup_sendToApproval.title')}
      >
        {parentActiveVersion !== null ? (
          <>
            <InformationBox
              title={t(
                'view.product.publishing.popup_sendToApproval.info.title'
              )}
              content={t(
                'view.product.publishing.popup_sendToApproval.info.text'
              )}
              type={'info'}
              maxWidth={720}
              dismissSettingName={'product_stateManagament_sendToApproval'}
            />
            {minifiedVariants && minifiedVariants.length > 0 ? (
              <div className="statemanagement-popupActions-toggle">
                <ToggleSwitch
                  label={t(
                    'view.product.publishing.popup_sendToApproval.toggle'
                  )}
                  toggled={willSubmitParentProduct}
                  toggle={() =>
                    setWillSubmitParentProduct(!willSubmitParentProduct)
                  }
                  hint={t(
                    'view.product.publishing.popup_sendToApproval.toggle_hint'
                  )}
                />
              </div>
            ) : null}
            {isVariant ? (
              parentActiveVersion ? (
                <div
                  className="statemanagement-parentActiveVersion"
                  onClick={() =>
                    link(
                      '/products/published/' +
                        product.parentId +
                        '?versionId=' +
                        parentActiveVersion.id
                    )
                  }
                >
                  <div className="statemanagement-parentActiveVersion-title">
                    {t(
                      'view.product.publishing.popup_sendToApproval.activeParent.title'
                    )}
                    <SmallHint
                      paragraphs={[
                        t(
                          'view.product.publishing.popup_sendToApproval.activeParent.hint'
                        ),
                      ]}
                    />
                  </div>
                  <div className="statemanagement-parentActiveVersion-dogtag">
                    <div className="statemanagement-parentActiveVersion-dogtag-indicator"></div>
                    <div className="statemanagement-parentActiveVersion-dogtag-info">
                      <div className="statemanagement-parentActiveVersion-dogtag-info-title">
                        {'Version #' +
                          parentActiveVersion.versionNumber
                            .toString()
                            .padStart(2, '0')}
                      </div>
                      <div className="statemanagement-parentActiveVersion-dogtag-info-info">
                        {parentActiveVersion.syncedAt ? (
                          <DateTime
                            dateString={parentActiveVersion.syncedAt}
                            show={['day', 'month', 'year', 'time']}
                          />
                        ) : (
                          t('view.product.versions.syncing')
                        )}
                      </div>
                    </div>
                    <Link className="statemanagement-parentActiveVersion-dogtag-link" />
                  </div>
                </div>
              ) : (
                <InformationBox
                  title={t(
                    'view.product.publishing.popup_sendToApproval.noActiveParentWarining.title'
                  )}
                  content={t(
                    'view.product.publishing.popup_sendToApproval.noActiveParentWarining.text'
                  )}
                  type={'warning'}
                  maxWidth={720}
                />
              )
            ) : null}
            {renderVariantsList()}
          </>
        ) : (
          <LoadingContainer />
        )}
        <div className="global-cardActions-postBorder">
          {updateProgress ? renderProgressBar() : null}
          <Button
            cta={t('view.product.publishing.popup_sendToApproval.cta')}
            width="minimal"
            look="save"
            action={() =>
              submitProductStateUpdates('ForApproval' as ProductState)
            }
            active={
              !isPerformingCriticalAction &&
              (!isVariant
                ? selectedVariants?.length > 0 || willSubmitParentProduct
                : true)
            }
            isLoading={isSubmitting}
          />
        </div>
      </Popup>
      <Popup
        width={'30%'}
        close={() => setRemoveFromApprovalModal(false)}
        toggled={removeFromApprovalModal}
        title={t('view.product.publishing.popup_removeFromApproval.title')}
      >
        {!isVariant ? (
          <InformationBox
            title={t(
              'view.product.publishing.popup_removeFromApproval.info.title'
            )}
            content={t(
              'view.product.publishing.popup_removeFromApproval.info.text'
            )}
            type="info"
          />
        ) : null}
        <div className="global-cardActions">
          <Button
            cta={t('view.product.publishing.popup_removeFromApproval.cta')}
            action={() => submitProductStateUpdates('Draft' as ProductState)}
            isLoading={isSubmitting}
            active={!isPerformingCriticalAction}
            look="secondary-danger"
            width="minimal"
          />
        </div>
      </Popup>
    </div>
  );
};

export default StateManagement;
