import './newloyaltypointtransaction.css';
import {
  CreateLoyaltyPointTransactionRequest,
  CustomerResponse,
  LoyaltyPointTransactionType,
} from '../../../../../api/petcloudapi/api';
import { useState } from 'react';
import { usePetCloudApi } from '../../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../../contexts/errorhandler/ErrorHandler';
import Button from '../../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../../elements/popup/Popup';
import LoyaltyPointTransactionEdit from '../loyaltypointtransactionedit/LoyaltyPointTransactionEdit';
import useNotifications from '../../../../../hooks/useNotifications';

interface NewLoyaltyPointTransactionProps {
  customer: CustomerResponse;
  orderId?: string;
  callbackFunc?: () => void;
  buttonWidth?: 'full' | 'minimal';
  cta?: string;
}

const NewLoyaltyPointTransaction: React.FC<NewLoyaltyPointTransactionProps> = ({
  customer,
  orderId,
  callbackFunc,
  buttonWidth,
  cta,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.loyaltyPointTransaction',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const loyaltyPointTransactionsApi = api.loyaltyPointTransactionsApi();
  const errorHandler = useErrorHandler();

  const [newTransaction, setNewTransaction] =
    useState<CreateLoyaltyPointTransactionRequest | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initNewTransaction = () => {
    setNewTransaction({
      points: 0,
      type: LoyaltyPointTransactionType.Credit,
      customerId: customer.id,
      orderId: orderId ?? null,
      description: null,
    });
  };

  const submitNewTransaction = () => {
    if (newTransaction) {
      setIsSubmitting(true);
      loyaltyPointTransactionsApi
        .loyaltyPointTransactionsCreateLoyaltyPointTransaction(newTransaction)
        .then((response) => {
          console.log(response);
          if (callbackFunc) {
            callbackFunc();
          }
          setIsSubmitting(false);
          pushNotification(t('notifications.post_success'));
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className={'newLoyaltyPointTransaction'}>
      <Button
        cta={cta ?? t('actions.new')}
        width={buttonWidth ?? 'minimal'}
        look={'secondary'}
        action={initNewTransaction}
        active={customer.attributes?.LoyaltyOptIn ?? false}
        inactiveMessage={t('notifications.noOptIn')}
      />
      {newTransaction ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setNewTransaction(null)}
        >
          <div className={'popup-title'}>{t('popupTitle')}</div>
          <LoyaltyPointTransactionEdit
            transaction={newTransaction}
            updateTransaction={setNewTransaction}
          />
          <div className={'global-cardActions'}>
            <Button
              cta={t('actions.save')}
              look={'save'}
              width={'minimal'}
              action={submitNewTransaction}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default NewLoyaltyPointTransaction;
