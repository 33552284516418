const useStateUpdateTools = () => {
  const toggleItemInArray = (state: any[], item: any) => {
    const update = [...state];
    const index = update.findIndex((element) => element.id === item.id);
    if (index !== -1) {
      update.splice(index, 1);
    } else {
      update.push(item);
    }
    return update;
  };

  const toggleItem = (state: any, item: any) => {
    if (state !== item) {
      return item;
    } else {
      return null;
    }
  };

  return {
    toggleItemInArray,
    toggleItem,
  };
};

export default useStateUpdateTools;
