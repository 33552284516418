import './navigationblocker.css';
import { Blocker } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconWarning } from '../../../assets/icon/warning.svg';

interface NavigationBlockerProps {
  blocker: Blocker;
}

const NavigationBlocker: React.FC<NavigationBlockerProps> = ({ blocker }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.navigationBlocker',
  });

  if (blocker.state === 'blocked') {
    return (
      <div className={'navigationBlocker-wrapper'}>
        <div className={'navigationBlocker'}>
          <div className={'navigationBlocker-icon-wrapper'}>
            <IconWarning className={'navigationBlocker-icon'} />
          </div>
          <div className={'navigationBlocker-inner'}>
            <div className={'navigationBlocker-text'}>{t('text')}</div>
            <div className={'navigationBlocker-actions'}>
              <div
                className={'navigationBlocker-action'}
                onClick={() => blocker.reset()}
              >
                {t('cta.cancel')}
              </div>
              <div
                className={
                  'navigationBlocker-action navigationBlocker-action__success'
                }
                onClick={() => blocker.proceed()}
              >
                {t('cta.proceed')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default NavigationBlocker;
