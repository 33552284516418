import './breadcrumbs.css';
import { Link } from 'react-router-dom';

export type Breadcrumb = {
  id: string;
  title: string;
  link?: string;
};

interface BreadcrumbsProps {
  crumbs: Breadcrumb[];
  navigate?: (id: string) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs, navigate }) => {
  return (
    <div className="breadcrumbs">
      {crumbs.map((crumb, i) => {
        return (
          <div key={i} style={{ display: 'flex' }}>
            <div
              key={i}
              className="breadcrumb"
              onClick={() => {
                if (navigate) {
                  navigate(crumb.id);
                }
              }}
            >
              {crumb.link ? (
                <Link to={crumb.link}>{crumb.title}</Link>
              ) : (
                crumb.title
              )}
            </div>
            {i + 1 < crumbs.length ? (
              <div className="breadcrumbs-spacer">/</div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
