import './profile.css';

import { CustomerResponse } from '../../../api/petcloudapi/api';

interface ProfileProps {
  customer: CustomerResponse;
}

const Profile: React.FC<ProfileProps> = ({ customer }) => {
  return (
    <div className="profile">
      <div
        className={
          'global-textElement global-monospaced-contrast profile-customerNumber'
        }
      >
        {customer.customerNumber}
      </div>
      <div className="global-textElement">
        {customer.salutation +
          ' ' +
          (customer.title ?? '') +
          ' ' +
          customer.firstName +
          ' ' +
          customer.lastName}
      </div>
      <div className="global-textElement global-textElement-link">
        <a href={`mailto:${customer.email}`}>{customer.email}</a>
      </div>
      {customer.company ? (
        <div className="global-textElement">{customer.company}</div>
      ) : null}
      {customer.language ? (
        <div className="global-textElement">{customer.language.name}</div>
      ) : null}
    </div>
  );
};

export default Profile;
