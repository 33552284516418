import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../api/PetCloudApi';
import {
  CreateFiegePurchaseOrderRequest,
  FiegePositionUnit,
  ProductListFilterMode,
  SimpleProductResponse,
  WarehouseType,
} from '../../api/petcloudapi/api';
import { useUser } from '../../contexts/auth/User';
import Button from '../../elements/button/Button';
import Card, { CardSection, HelperCard } from '../../elements/card/Card';
import DatePicker from '../../elements/datepicker/DatePicker';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import Input from '../../elements/input/Input';
import List from '../../features/list/List';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import PurchaseOrderPosition from '../../types/PurchaseOrderPosition';
import { Has } from '../../contexts/auth/Authorization';
import { useNavigate } from 'react-router-dom';
import Popup from '../../elements/popup/Popup';
import ProductList from '../../features/productlist/ProductList';
import { ReactComponent as Import } from '../../../assets/icon/import.svg';
import PurchaseOrderPositionImport from '../../sections/purchaseorder/purchaseorderPositionImport/PurchaseOrderPositionImport';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { ListItemCount } from '../../features/list/listcontrols/ListControls';

const NewPurchaseOrder = () => {
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const link = useNavigate();
  const api = usePetCloudApi();
  const fiegePurchaseOrdersApi = api.fiegePurchaseOrdersApi();
  const warehousesApi = api.warehousesApi();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productListPopup, setProductListPopup] = useState(false);
  const [importPopup, setImportPopup] = useState(false);
  const [products, setProducts] = useState<SimpleProductResponse[] | null>(
    null
  );
  const [positions, setPositions] = useState<PurchaseOrderPosition[]>([]);
  const [newPurchaseOrder, setNewPurchaseOrder] =
    useState<CreateFiegePurchaseOrderRequest>({
      orderDate: new Date().toISOString().split('T')[0],
      deliveryDate: new Date().toISOString().split('T')[0],
      warehouseId: 'central warehouse ID could not be identified',
      positions: [],
      manufacturerId: user?.manufacturerId,
    });

  useEffect(() => {
    getCentralWarehouseId();
  }, []);

  const getCentralWarehouseId = () => {
    warehousesApi
      .warehousesGetWarehouses()
      .then((response) => {
        console.log(response);
        const id = response.data.find(
          (warehouse) => warehouse.type === 'CentralWarehouse'
        )?.id;
        if (id) {
          setNewPurchaseOrder({
            ...newPurchaseOrder,
            warehouseId: id,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitNewPurchaseOrder = () => {
    setIsSubmitting(true);
    fiegePurchaseOrdersApi
      .fiegePurchaseOrdersCreateFiegePurchaseOrder({
        ...newPurchaseOrder,
        positions: positions.map((position, i) => {
          return {
            lineNumber: i + 1,
            quantity: position.quantity,
            productId: position.productId,
            unit: position.unit,
          };
        }),
      })
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        Store.addNotification({
          message: t('view.purchaseorder.notifications.create_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        link('/purchaseorders/' + response.data.id);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const addPosition = (
    product: SimpleProductResponse,
    quantity?: number,
    productCoverUri?: string | null
  ) => {
    const update = positions;
    update.push(getPurchaseOrderPosition(product, quantity, productCoverUri));
    setPositions([...update]);
  };

  const addAllPositions = (products: SimpleProductResponse[]) => {
    const update = positions;
    products.forEach((product) => {
      update.push(getPurchaseOrderPosition(product));
    });
    setPositions([...update]);
  };

  const deletePosition = (product: SimpleProductResponse) => {
    const index = positions.findIndex(
      (position) => position.productId === product.id
    );
    if (index >= 0) {
      const update = positions;
      update.splice(index, 1);
      setPositions([...update]);
    }
  };

  const renderQuantityInput = (value: any, item: any, index: number) => {
    return (
      <Input
        content={value}
        update={(e) => {
          const update = positions;
          update[index].quantity = parseInt(e);
          setPositions(update);
        }}
      />
    );
  };

  return (
    <Has authorizations={['fiege_purchase_orders:create']} showNoAuthorization>
      <Stage>
        <Navigator title={t('view.purchaseorders.new')}>
          <Button
            look="save"
            cta={t('view.purchaseorders.submit')}
            width="minimal"
            action={submitNewPurchaseOrder}
            isLoading={isSubmitting}
          />
        </Navigator>
        <HelperCard
          text={t('view.purchaseorders.helperCard')}
          knowledgeBaseItems={[
            {
              title: t('knowledgebase.logistics.purchaseOrders.create.title'),
              text: t('knowledgebase.logistics.purchaseOrders.create.text'),
              videoUri: t(
                'knowledgebase.logistics.purchaseOrders.create.media'
              ),
            },
          ]}
          settingName={'settings_team'}
        />
        <Card bigScreenWidth="full">
          <CardSection title={t('view.purchaseorder.information.title')}>
            <div className="global-inputGroup">
              <div className="global-inputGroup-inputInline">
                <Input
                  title={t('view.purchaseorder.information.orderDate')}
                  content={newPurchaseOrder.orderDate}
                />
              </div>
              <div className="global-inputGroup-inputInline">
                <DatePicker
                  title={t('view.purchaseorder.information.deliveryDate')}
                  selected={new Date(newPurchaseOrder.deliveryDate)}
                  onChange={(date) => {
                    if (date) {
                      setNewPurchaseOrder({
                        ...newPurchaseOrder,
                        deliveryDate: date.toISOString().split('T')[0],
                      });
                    }
                  }}
                  dateFormat="yyyy-MM-dd"
                  hint={t('view.purchaseorder.information.deliveryDate_hint')}
                />
              </div>
            </div>
          </CardSection>
        </Card>
        <Card bigScreenWidth="full">
          <CardSection title={t('view.purchaseorder.positions.title')}>
            {positions.length > 0 ? (
              <>
                <ListItemCount itemCount={positions.length} />
                <List
                  items={positions}
                  ignore={['unit', 'productId']}
                  translatedStrings={['productName']}
                  itemImgKey={'productCoverUri'}
                  monoSpaceStrings={['productId', 'ean', 'productNumber']}
                  renderObjects={[
                    {
                      key: 'quantity',
                      renderMethod: renderQuantityInput,
                      receiveNullValues: true,
                    },
                  ]}
                  isShowingIndex
                  height={400}
                />
              </>
            ) : (
              <EmptyState message={t('view.purchaseorder.positions.empty')} />
            )}
            <div
              className={
                'global-cardActions-postBorder global-cardActions-spaceBetween'
              }
            >
              <Button
                cta={t('view.purchaseorder.positions.import')}
                type="icon-text"
                look={'secondary'}
                action={() => setImportPopup(true)}
                active={!!products}
              >
                <Import
                  className="button-icon"
                  fill="var(--color-text_secondary)"
                />
              </Button>
              <Button
                cta={t('view.purchaseorder.positions.chooseProducts')}
                look={'secondary'}
                width={'minimal'}
                action={() => setProductListPopup(true)}
              />
            </div>
            <Popup
              toggled={productListPopup}
              width={'60%'}
              close={() => setProductListPopup(false)}
            >
              <ProductList
                selectedIds={positions.map((p) => p.productId)}
                selectProductCallback={(product, action) => {
                  if (action === 'select') {
                    addPosition(
                      product,
                      undefined,
                      product.cover?.asset[
                        i18n.language as TranslatedStringIndex
                      ]?.uri
                    );
                  } else {
                    deletePosition(product);
                  }
                }}
                selectAllProductsCallback={(products) => {
                  if (products.length > 0) {
                    addAllPositions(products);
                  } else {
                    setPositions([]);
                  }
                }}
                finishedLoadingCallback={(p) => {
                  setProducts(p);
                }}
                productListFilterMode={ProductListFilterMode.ExcludeNonBuyable}
                warehouseType={WarehouseType.CentralWarehouse}
                height={'80vh'}
              />
            </Popup>
            <Popup
              toggled={importPopup}
              width={'30%'}
              close={() => setImportPopup(false)}
            >
              <div className={'popup-title'}>
                {t('view.purchaseorder.positionImport.title')}
              </div>
              <PurchaseOrderPositionImport
                callback={(pos) => {
                  console.log(pos);
                  setPositions(pos);
                }}
                successCallback={() => setImportPopup(false)}
                products={products}
              />
            </Popup>
          </CardSection>
        </Card>
      </Stage>
    </Has>
  );
};

export default NewPurchaseOrder;

export const getPurchaseOrderPosition = (
  product: SimpleProductResponse,
  quantity?: number,
  productCoverUri?: string | null
) => {
  return {
    productCoverUri: productCoverUri ?? undefined,
    productName: product.name,
    productNumber: product.productNumber,
    ean: product.ean,
    productId: product.id,
    manufacturerProductNumber: product.manufacturerProductNumber,
    quantity: quantity ?? 0,
    unit: 'Piece' as FiegePositionUnit,
  };
};

// TODO: introduce back to ProductsList
// filterFunc={{
//   infoBox: {
//     title: t(
//       'view.purchaseorder.positions.filterInfoBox.title'
//     ),
//       message: t(
//       'view.purchaseorder.positions.filterInfoBox.message'
//     ),
//   },
//   func: (p) => {
//     return !!p.productStock?.find(
//       (s) => s.warehouse.type === 'CentralWarehouse'
//     );
//   },
// }}
