import './newpropertygroup.css';
import {
  AnimalSpeciesResponse,
  CreatePropertyGroupRequest,
} from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import Input from '../../../../elements/input/Input';
import AnimalSpeciesArray from '../../animalspecies/animalspeciesarray/AnimalSpeciesArray';
import ToggleSwitch from '../../../../elements/toggleswitch/ToggleSwitch';

interface NewPropertyGroupProps {
  group: CreatePropertyGroupRequest;
  setGroup: (group: CreatePropertyGroupRequest) => void;
  availableAnimalSpecies: AnimalSpeciesResponse[];
}

const NewPropertyGroup: React.FC<NewPropertyGroupProps> = ({
  group,
  setGroup,
  availableAnimalSpecies,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.properties',
  });
  return (
    <div className="productproperties-groupForm">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newGroupPopup.identifier')}
            content={group.identifier}
            update={(value) => {
              setGroup({
                ...group,
                identifier: value,
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('newGroupPopup.nameDE')}
            content={group.name['de-DE']}
            update={(value) => {
              setGroup({
                ...group,
                name: {
                  ...group.name,
                  'de-DE': value,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('newGroupPopup.nameEN')}
            content={group.name['en-GB']}
            update={(value) => {
              setGroup({
                ...group,
                name: {
                  ...group.name,
                  'en-GB': value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newGroupPopup.descriptionDE')}
            content={group.description ? group.description['de-DE'] : null}
            update={(value) => {
              setGroup({
                ...group,
                description: {
                  ...group.description,
                  'de-DE': value,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('newGroupPopup.descriptionEN')}
            content={group.description ? group.description['en-GB'] : null}
            update={(value) => {
              setGroup({
                ...group,
                description: {
                  ...group.description,
                  'en-GB': value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <ToggleSwitch
            toggled={group.isLanguageNeutral}
            toggle={() => {
              setGroup({
                ...group,
                isLanguageNeutral: !group.isLanguageNeutral,
              });
            }}
            title={t('newGroupPopup.isLanguageNeutral')}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <AnimalSpeciesArray
            animalSpecies={availableAnimalSpecies.filter((s) =>
              group.animalSpeciesIds?.includes(s.id)
            )}
            availableAnimalSpecies={availableAnimalSpecies}
            onDelete={(index) => {
              let update = group.animalSpeciesIds;
              if (update) {
                update.splice(index, 1);
              }
              setGroup({
                ...group,
                animalSpeciesIds: update,
              });
            }}
            onSubmit={(animalSpecies) => {
              let update = group.animalSpeciesIds;
              if (update) {
                update.push(animalSpecies.id);
              } else {
                update = [animalSpecies.id];
              }
              setGroup({
                ...group,
                animalSpeciesIds: update,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewPropertyGroup;
