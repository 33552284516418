import { EmptyState } from '../../elements/emptystate/EmptyState';
import { useTranslation } from 'react-i18next';
import notFoundImg from '../../../assets/img/404.svg';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <EmptyState
        message={t('view.notfound.text')}
        img={notFoundImg}
        size={240}
      ></EmptyState>
    </div>
  );
};

export default NotFound;
