import './rankoverview.css';
import {
  CustomerResponse,
  LoyaltyPointAggregationResponse,
  LoyaltyRankCustomerHistoryResponse,
} from '../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import emptyRank from '../../../../assets/img/empty_rank.svg';
import TitleValueList from '../../../elements/titlevaluelist/TitleValueList';
import useDateTools from '../../../hooks/useDateTools';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Button from '../../../elements/button/Button';
import Popup from '../../../elements/popup/Popup';
import LoyaltyPointTransactions from './loyaltypointtransactions/LoyaltyPointTransactions';

interface RankOverviewProps {
  customer: CustomerResponse;
  activeRank?: LoyaltyRankCustomerHistoryResponse | null;
}

const RankOverview: React.FC<RankOverviewProps> = ({
  customer,
  activeRank,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.customer.loyaltyRank',
  });
  const api = usePetCloudApi();
  const loyaltyPointTransactionsApi = api.loyaltyPointTransactionsApi();
  const errorHandler = useErrorHandler();
  const { displayReadableDate } = useDateTools();
  const lang = i18n.language as TranslatedStringIndex;

  const [aggregation, setAggregation] =
    useState<LoyaltyPointAggregationResponse | null>(null);
  const [historyPopup, setHistoryPopup] = useState(false);

  useEffect(() => {
    getAggregation();
  }, []);

  const getAggregation = () => {
    loyaltyPointTransactionsApi
      .loyaltyPointTransactionsGetLoyaltyPointAggregation(customer.id)
      .then((response) => {
        console.log(response);
        setAggregation(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getEntries = () => {
    return [
      {
        title: t('activeRank'),
        value: activeRank?.loyaltyRank?.name[lang],
      },
      {
        title: t('optedInSince'),
        value: customer.attributes?.LoyaltyOptInDateTime
          ? displayReadableDate(customer.attributes?.LoyaltyOptInDateTime)
          : 'time missing',
      },
      {
        title: t('achievedAt'),
        value: activeRank ? displayReadableDate(activeRank.achievedAt) : null,
      },
      {
        title: t('totalPoints'),
        value: aggregation?.overallTotalPoints,
      },
      {
        title: t('spentPoints'),
        value:
          aggregation && aggregation.spentPoints
            ? aggregation.spentPoints
            : '0',
      },
      {
        title: t('balance'),
        value: aggregation?.currentBalance,
      },
    ];
  };

  if (aggregation) {
    return (
      <div className={'rankOverview'}>
        <div className={'rankOverview-icon-wrapper'}>
          {activeRank ? (
            <img
              src={activeRank.loyaltyRank?.asset?.[lang]?.uri}
              className={'rankOverview-icon'}
              alt={'Rank Icon'}
            />
          ) : (
            <img
              src={emptyRank}
              className={'rankOverview-emptyIcon'}
              alt={'Rank Icon'}
            />
          )}
        </div>
        <TitleValueList entries={getEntries()} spaceBetween />
        <div className={'rankOverview-button'}>
          <Button
            cta={t('showHistory')}
            width={'full'}
            look={'secondary'}
            action={() => setHistoryPopup(true)}
          />
        </div>
        <Popup
          toggled={historyPopup}
          width={'40%'}
          close={() => setHistoryPopup(false)}
        >
          <div className={'popup-title'}>
            {t('loyaltyPointTransactions.title')}
          </div>
          <LoyaltyPointTransactions customer={customer} />
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default RankOverview;
