import './inputheader.css';
import { SmallHint } from '../../hint/Hint';
import { ReactComponent as Linked } from '../../../../assets/icon/linked.svg';
import { ReactComponent as Unlinked } from '../../../../assets/icon/unlinked.svg';
import { ReactComponent as Lock } from '../../../../assets/icon/padlock.svg';
import Badge from '../../badge/Badge';

interface InputHeaderProps {
  title: string;
  inherited?: boolean | null;
  brokenInheritance?: boolean;
  locked?: boolean;
  required?: boolean;
  hint?: {
    title?: string;
    paragraphs?: string[];
  };
  optional?: boolean;
}

const InputHeader: React.FC<InputHeaderProps> = ({
  title,
  inherited,
  locked,
  brokenInheritance,
  required,
  hint,
  optional,
}) => {
  return (
    <div className="inputHeader">
      {inherited ? (
        locked ? (
          <Lock
            fill="var(--color-inherited)"
            className="global-icon-inherited"
          />
        ) : !brokenInheritance ? (
          <Linked
            className="global-icon-inherited"
            fill="var(--color-inherited)"
          />
        ) : (
          <Unlinked
            className="global-icon-inherited"
            fill="var(--color-text_tertiary)"
          />
        )
      ) : null}
      <div
        className={`inputHeader-title ${
          inherited && !brokenInheritance ? 'inputHeader-title_inherited' : ''
        }`}
      >
        {title}
      </div>
      {required ? <div className="global-required">•</div> : null}
      {optional ? (
        <div className={'inputHeader-badge'}>
          <Badge title={'Optional'} color={'var(--color-blue)'} />
        </div>
      ) : null}
      {hint ? (
        <SmallHint title={hint.title} paragraphs={hint.paragraphs} />
      ) : null}
    </div>
  );
};

export default InputHeader;
