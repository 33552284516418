import './stage.css';
import { ReactNode, useState } from 'react';
import { ReactComponent as Arrow } from '../../../assets/icon/left-arrow.svg';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as More } from '../../../assets/icon/more.svg';
import { Spinner } from '../../elements/loading/Loading';
import useBrowserTab from '../../hooks/useBrowserTab';
import Badge, { BadgeProps } from '../../elements/badge/Badge';
import { ReactComponent as Maximize } from '../../../assets/icon/maximize.svg';
import { ReactComponent as Minimize } from '../../../assets/icon/minimize.svg';
import { useTranslation } from 'react-i18next';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';

interface NavigatorProps {
  backLink?: string;
  backButtonOverrideFunc?: () => void;
  allowBackButton?: boolean;
  titlePrefix?: string | null;
  title?: string | null;
  badges?: BadgeProps[];
  addInfo?: string;
  isLoading?: boolean;
  additionalElement?: ReactNode;
}

export const Navigator: React.FC<NavigatorProps> = ({
  backLink,
  backButtonOverrideFunc,
  allowBackButton,
  titlePrefix,
  title,
  children,
  badges,
  addInfo,
  isLoading,
  additionalElement,
}) => {
  const link = useNavigate();
  const [mobileActions, setMobileActions] = useState(false);
  const setTabTitle = useBrowserTab();
  if (title) {
    if (titlePrefix) {
      setTabTitle(titlePrefix, title);
    } else {
      setTabTitle(title);
    }
  }
  return (
    <div className="stage-header">
      <div className="stage-header-top">
        <div className="stage-header-left">
          {allowBackButton ? (
            <div className={'stage-header-left-button'}>
              <Button
                margin="right"
                look={'secondary'}
                type="icon"
                action={() => {
                  backButtonOverrideFunc
                    ? backButtonOverrideFunc()
                    : backLink
                    ? link(backLink)
                    : window.history.go(-1);
                }}
              >
                <Arrow
                  fill="var(--color-text_primary)"
                  className="button-icon"
                />
              </Button>
            </div>
          ) : null}
          {additionalElement}
          <div className="stage-header-meta">
            {isLoading ? (
              <div className="stage-header-meta-spinner">
                <Spinner isLoading={isLoading} />
              </div>
            ) : null}
            <div className="stage-header-meta-title">{title}</div>
            {badges ? (
              <div className={'stage-header-meta-badges'}>
                {badges.map((badge, i) => {
                  return (
                    <div key={i} className={'stage-header-meta-badge'}>
                      <Badge {...badge} />
                    </div>
                  );
                })}
              </div>
            ) : null}
            {addInfo ? (
              <div className="stage-header-meta-addInfo">{addInfo}</div>
            ) : null}
          </div>
        </div>
        <div className="stage-header-actions">{children}</div>
        {children ? (
          <More
            className="mobile-stage-header-actions"
            fill="var(--color-gray)"
            onClick={() => setMobileActions(true)}
          />
        ) : null}
      </div>
      <Popup
        width="80%"
        toggled={mobileActions}
        close={() => setMobileActions(false)}
      >
        <div className="mobile-stage-header-actions-popup">{children}</div>
      </Popup>
    </div>
  );
};

export const Stage: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { setToggledSetting, getToggledSetting } = useLocalStorageSettings();
  const [fullWidth, setFullWidth] = useState(
    getToggledSetting('stageMaximized')
  );

  const toggleFullWidth = () => {
    setFullWidth(!fullWidth);
    setToggledSetting('stageMaximized', !fullWidth);
  };

  return (
    <div className="stage-wrapper">
      <div
        style={fullWidth ? undefined : { maxWidth: '1400px' }}
        className="stage stage-full"
      >
        <div className={'stage-header-secondary-actions'}>
          {fullWidth ? (
            <div
              className={
                'stage-header-secondary-action stage-header-maximizeBtn'
              }
              onClick={toggleFullWidth}
            >
              <Minimize
                className={'stage-header-secondary-action-icon'}
                fill={'var(--color-text_tertiary)'}
              />
              <div className={'stage-header-secondary-action-text'}>
                {t('actions.minimize')}
              </div>
            </div>
          ) : (
            <div
              className={
                'stage-header-secondary-action stage-header-maximizeBtn'
              }
              onClick={toggleFullWidth}
            >
              <Maximize
                className={'stage-header-secondary-action-icon'}
                fill={'var(--color-text_tertiary)'}
              />
              <div className={'stage-header-secondary-action-text'}>
                {t('actions.maximize')}
              </div>
            </div>
          )}
        </div>
        {children}
        <div className="stage-bottom"></div>
      </div>
    </div>
  );
};

export const StageColumns: React.FC = ({ children }) => {
  return <div className="stageColumns">{children}</div>;
};

interface StageColumnProps {
  size: 'one' | 'two';
}

export const StageColumn: React.FC<StageColumnProps> = ({ children, size }) => {
  if (size === 'one') {
    return (
      <div className="stageColumns-column stageColumns-column-one">
        {children}
      </div>
    );
  } else if (size === 'two') {
    return (
      <div className="stageColumns-column stageColumns-column-two">
        {children}
      </div>
    );
  } else {
    return <div className="stageColumns-column">Please specify size</div>;
  }
};
