import './teammembers.css';
import { useEffect, useState } from 'react';
import {
  CreateManufacturerRequest,
  CreateUserRequest,
  ManufacturerResponse,
  UpdateManufacturerRequest,
  UserResponse,
  UserRoleResponse,
} from '../../../api/petcloudapi/api';
import DogTag from '../../../elements/dogtag/DogTag';
import ItemActions from '../../../elements/itemactions/ItemActions';
import { TFunction } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import MemberEdit from './memberedit/MemberEdit';
import Popup from '../../../elements/popup/Popup';
import Button from '../../../elements/button/Button';
import _ from 'lodash';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Retractable from '../../../elements/retractable/Retractable';
import Badge from '../../../elements/badge/Badge';
import { Dropdown } from '../../../elements/selectors/Selectors';
import { Store } from 'react-notifications-component';

interface TeamMembersProps {
  t: TFunction<'translation'>;
  manufacturer: CreateManufacturerRequest | UpdateManufacturerRequest | null;
  manufacturers?: ManufacturerResponse[] | null;
  refreshManufacturer?: () => void;
  manufacturerId?: string | null;
}

const TeamMembers: React.FC<TeamMembersProps> = ({
  t,
  manufacturer,
  manufacturers,
  refreshManufacturer,
  manufacturerId,
}) => {
  const [members, setMembers] = useState<UserResponse[] | null>(null);
  const [additionalMember, setAdditionalMember] =
    useState<CreateUserRequest | null>(null);
  const [memberToEdit, setMemberToEdit] = useState<UserResponse | null>(null);
  const [originalMemberToEdit, setOriginalMemberToEdit] =
    useState<UserResponse | null>(null);
  const [roles, setRoles] = useState<UserRoleResponse[] | null>(null);
  const [additionalMemberIsSubmitting, setAdditionalMemberIsSubmitting] =
    useState(false);
  const [memberToEditIsSubmitting, setMemberToEditIsSubmitting] =
    useState(false);

  const usersApi = usePetCloudApi().usersApi();
  const rolesApi = usePetCloudApi().userRolesApi();
  const errorHandler = useErrorHandler();

  // this will update the current memberToEdit when the manufacturer gets refreshed
  useEffect(() => {
    if (memberToEdit) {
      const id = memberToEdit.id;
      const updatedMember = (manufacturer as ManufacturerResponse).users?.find(
        (user) => user.id === id
      );
      if (updatedMember) {
        setMemberToEdit(updatedMember);
      }
    }
  }, [manufacturer]);

  useEffect(() => {
    getUsers();
    getRoles();
  }, [manufacturer]);

  const getUsers = () => {
    setMembers(null);
    usersApi
      .usersGetUsers()
      .then((response) => {
        console.log(response);
        setMembers(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getRoles = () => {
    setRoles(null);
    rolesApi
      .userRolesGetUserRoles()
      .then((response) => {
        console.log(response);
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const initializeAdditionalMember = () => {
    setAdditionalMember({
      username: '',
      email: '',
      title: '',
      salutation: '',
      firstName: '',
      lastName: '',
      manufacturerId: manufacturer
        ? (manufacturer as ManufacturerResponse).id
        : null,
    });
  };

  const updateAdditionalMember = (user: CreateUserRequest) => {
    setAdditionalMember({ ...user });
  };

  const validateAdditionalMember = () => {
    if (additionalMember) {
      if (additionalMember.salutation !== '') {
        if (additionalMember.firstName !== '') {
          if (additionalMember.lastName !== '') {
            if (additionalMember.username !== '') {
              return additionalMember.email !== '';
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const submitAdditionalMember = () => {
    if (additionalMember) {
      setAdditionalMemberIsSubmitting(true);
      usersApi
        .usersCreateUser(additionalMember)
        .then((response) => {
          console.log(response);
          setAdditionalMember(null);
          getUsers();
          setAdditionalMemberIsSubmitting(false);
          if (refreshManufacturer) {
            refreshManufacturer();
          }
          Store.addNotification({
            message: t(
              'components.teammembers.notifications.create_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setAdditionalMemberIsSubmitting(false);
        });
    }
  };

  const updateMemberToEdit = (user: UserResponse) => {
    setMemberToEdit({ ...user });
  };

  const submitMemberToEdit = () => {
    if (memberToEdit) {
      usersApi
        .usersUpdateUser(memberToEdit.id, memberToEdit)
        .then((response) => {
          console.log(response);
          setMemberToEditIsSubmitting(false);
          setMemberToEdit(null);
          setOriginalMemberToEdit(null);
          if (refreshManufacturer) {
            refreshManufacturer();
          }
          Store.addNotification({
            message: t(
              'components.teammembers.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setMemberToEditIsSubmitting(false);
        });
    }
  };

  const deleteUser = (id: string) => {
    usersApi
      .usersDeleteUser(id)
      .then((response) => {
        console.log(response);
        getUsers();
        if (refreshManufacturer) {
          refreshManufacturer();
        }
        Store.addNotification({
          message: t('components.teammembers.notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const addRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.selectedOptions[0].getAttribute('data-value');
    if (id && memberToEdit) {
      const body = {
        userRoleId: id,
      };

      // visually attach the role
      const roleToAttach = roles?.find((role) => role.id === id);
      if (roleToAttach) {
        setMemberToEdit({
          ...memberToEdit,
          userRoles: [...memberToEdit.userRoles, roleToAttach],
        });
      }

      setMemberToEditIsSubmitting(true);
      usersApi
        .usersCreateUserRoleMapping(memberToEdit.id, body)
        .then((response) => {
          console.log(response);
          getUsers();
          setMemberToEditIsSubmitting(false);
          setMemberToEdit(null);
          Store.addNotification({
            message: t(
              'components.teammembers.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setMemberToEditIsSubmitting(false);
        });
    }
  };

  const deleteRole = (id: string) => {
    if (memberToEdit) {
      usersApi
        .usersDeleteUserRoleMapping(memberToEdit.id, id)
        .then((response) => {
          console.log(response);
          setMemberToEdit(null);
          getUsers();
          Store.addNotification({
            message: t(
              'components.teammembers.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  // groups members by manufacturer for clustered view if user is product owner
  const getGroupedMembers = (userArray: UserResponse[]) => {
    if (userArray.length > 0) {
      return _.groupBy(userArray, 'manufacturerId');
    } else {
      return null;
    }
  };

  const renderGroupedMembersHead = (
    manufacturerId: string,
    manufacturers: ManufacturerResponse[],
    members: UserResponse[]
  ) => {
    const manufacturer = manufacturers.find((m) => m.id === manufacturerId);
    return (
      <>
        <div className="retractable-head-element">
          {manufacturer?.companyName ?? 'Inpetto Team'}
        </div>
        <div className="retractable-head-element">
          <Badge title={members.length.toString()} color="var(--color-blue)" />
        </div>
      </>
    );
  };

  if (roles && members) {
    const groupedMembers = getGroupedMembers(members);
    console.log(groupedMembers);
    return (
      <div className="teammembers">
        {groupedMembers && manufacturers && manufacturerId === undefined ? (
          Object.entries(groupedMembers).map(
            ([manufacturerId, users]: [string, UserResponse[]]) => {
              return (
                <div className="teammembers-retractable">
                  <Retractable
                    head={renderGroupedMembersHead(
                      manufacturerId,
                      manufacturers,
                      users
                    )}
                  >
                    <div className="teammembers-members teammembers-members-retractale">
                      {users.map((user, i) => {
                        return (
                          <DogTag
                            key={i}
                            name={user.firstName}
                            lastName={user.lastName}
                            tags={
                              user.isManufacturerAdmin
                                ? [
                                    {
                                      text: t(
                                        'components.teammembers.tags.admin'
                                      ),
                                      color: 'var(--color-success)',
                                    },
                                  ]
                                : undefined
                            }
                            onClick={() => {
                              setMemberToEdit(user);
                              setOriginalMemberToEdit(user);
                            }}
                            isProfile
                          >
                            <ItemActions
                              item={user}
                              actions={[
                                {
                                  cta: 'edit',
                                  look: 'blue',
                                  action: () => {
                                    setMemberToEdit(user);
                                    setOriginalMemberToEdit(user);
                                  },
                                },
                                {
                                  cta: 'delete',
                                  look: 'danger',
                                  action: () => deleteUser(user.id),
                                },
                              ]}
                            />
                          </DogTag>
                        );
                      })}
                    </div>
                  </Retractable>
                </div>
              );
            }
          )
        ) : (
          <div className="teammembers-members">
            {members.map((user, i) => {
              if (user.manufacturerId === manufacturerId) {
                return (
                  <DogTag
                    key={i}
                    name={user.firstName}
                    lastName={user.lastName}
                    tags={
                      user.isManufacturerAdmin
                        ? [
                            {
                              text: t('components.teammembers.tags.admin'),
                              color: 'var(--color-success)',
                            },
                          ]
                        : undefined
                    }
                    onClick={() => {
                      setMemberToEdit(user);
                      setOriginalMemberToEdit(user);
                    }}
                    isProfile
                  >
                    <ItemActions
                      item={user}
                      actions={[
                        {
                          cta: 'edit',
                          look: 'blue',
                          action: () => {
                            setMemberToEdit(user);
                            setOriginalMemberToEdit(user);
                          },
                        },
                        {
                          cta: 'delete',
                          look: 'danger',
                          action: () => deleteUser(user.id),
                        },
                      ]}
                    />
                  </DogTag>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
        <Popup
          toggled={!!memberToEdit}
          close={() => {
            setMemberToEdit(null);
            setOriginalMemberToEdit(null);
          }}
          width="50%"
        >
          <div className="teammembers-popup-title">
            {t('view.team.team.popup.title_edit')}
          </div>
          {memberToEdit ? (
            <MemberEdit
              member={memberToEdit}
              updateCurrentUser={updateMemberToEdit}
              t={t}
              roles={roles.filter(
                (role) => role.manufacturerId === memberToEdit.manufacturerId
              )}
              memberRoles={memberToEdit.userRoles}
              addRole={addRole}
              deleteRole={deleteRole}
            />
          ) : null}
          <div className="global-cardActions">
            <Button
              cta={t('actions.cancel')}
              look={'secondary'}
              action={() => {
                setMemberToEdit(null);
                setOriginalMemberToEdit(null);
              }}
            />
            <Button
              cta={t('actions.save')}
              look="save"
              action={() => {
                submitMemberToEdit();
                setMemberToEditIsSubmitting(true);
              }}
              margin="left"
              isLoading={memberToEditIsSubmitting}
              active={memberToEdit !== originalMemberToEdit}
            />
          </div>
        </Popup>
        <div className="global-cardActions-left">
          <Button
            cta={t('view.manufacturer.members.add')}
            look={'secondary'}
            action={initializeAdditionalMember}
            width="minimal"
          />
        </div>
        {additionalMember ? (
          <Popup
            toggled={!!additionalMember}
            close={() => setAdditionalMember(null)}
            width="50%"
          >
            <div className="teammembers-popup-title">
              {t('components.teammembers.popup_invite.title')}
            </div>
            {!manufacturer && manufacturers ? (
              <div className="global-inputGroup">
                <Dropdown
                  title={t('components.teammembers.popup_invite.manufacturer')}
                  optionObjects={manufacturers.map((m) => {
                    return {
                      id: m.id,
                      name: m.companyName,
                    };
                  })}
                  update={(e) => {
                    setAdditionalMember({
                      ...additionalMember,
                      manufacturerId:
                        e.target.selectedOptions[0].getAttribute('data-value'),
                    });
                  }}
                  selected={
                    manufacturers.find(
                      (m) => m.id === additionalMember.manufacturerId
                    )?.companyName
                  }
                />
              </div>
            ) : null}
            <MemberEdit
              t={t}
              member={additionalMember}
              updateNewUser={updateAdditionalMember}
              roles={roles}
              addRole={addRole}
              deleteRole={deleteRole}
            />
            <div className="global-cardActions">
              <Button
                cta={t('view.manufacturer.admin.add')}
                look="save"
                action={() => {
                  submitAdditionalMember();
                }}
                width="minimal"
                isLoading={additionalMemberIsSubmitting}
                active={validateAdditionalMember()}
              />
            </div>
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default TeamMembers;
