import './manufactureroverview.css';
import { useTranslation } from 'react-i18next';
import {
  ManufacturerResponse,
  UserResponse,
} from '../../../../api/petcloudapi/api';
import TitleValueList from '../../../../elements/titlevaluelist/TitleValueList';

interface ManufacturerOverviewProps {
  user: UserResponse;
  manufacturer: ManufacturerResponse;
}

const ManufacturerOverview: React.FC<ManufacturerOverviewProps> = ({
  user,
  manufacturer,
}) => {
  const { t } = useTranslation();

  const manufacturerList = [
    {
      title: t('view.account.profile.manufacturerOverview.companyName'),
      value: manufacturer.companyName,
    },
    {
      title: t('view.account.profile.manufacturerOverview.shortCode'),
      value: manufacturer.shortCode,
    },
    {
      title: t('view.account.profile.manufacturerOverview.address'),
      value:
        (manufacturer.companyAddress?.street ?? ' - ') +
        ' ' +
        (manufacturer.companyAddress?.streetNumber ?? ' - ') +
        ' ' +
        (manufacturer.companyAddress?.zipCode ?? ' - ') +
        ' ' +
        (manufacturer.companyAddress?.city ?? ' - '),
    },
    {
      title: t('view.account.profile.manufacturerOverview.additionalAddress'),
      value:
        (manufacturer.companyAddress?.additionalAddressLine1 ?? ' - ') +
        ' ' +
        (manufacturer.companyAddress?.additionalAddressLine2 ?? ' '),
    },
  ];

  if (user?.isManufacturerAdmin) {
    manufacturerList.push({
      title: t('view.account.profile.manufacturerOverview.registrationNumber'),
      value: manufacturer.registrationNumber,
    });
    manufacturerList.push({
      title: t('view.account.profile.manufacturerOverview.vatId'),
      value: manufacturer.vatId,
    });
  }

  return (
    <div className="manufactureroverview">
      <TitleValueList entries={manufacturerList} />
    </div>
  );
};

export default ManufacturerOverview;
