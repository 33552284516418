import './stockscell.css';
import {
  ProductStockResponse,
  WarehouseResponse,
} from '../../../../../api/petcloudapi/api';

interface StocksCellProps {
  stocks: ProductStockResponse[];
  warehouses: WarehouseResponse[];
  selectCellElement: (element: ProductStockResponse) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const StocksCell: React.FC<StocksCellProps> = ({
  stocks,
  warehouses,
  selectCellElement,
  isSelectedCellElement,
}) => {
  const isCentralWarehouse = (id: string) => {
    return warehouses.find((w) => w.id === id)?.type === 'CentralWarehouse';
  };

  return (
    <div className={'jtce-stocks'}>
      {stocks?.map((stock, i) => {
        return (
          <div
            key={i}
            className={`jtce-stock ${
              isSelectedCellElement(stock) ? 'jtce-stock__selected' : ''
            }`}
            onClick={() => selectCellElement(stock)}
          >
            <div className={'jtce-stock-el'}>{stock.stock}</div>
            <div className={'jtce-stock-el jtce-stock-el-priority'}>
              <span>P</span>
              {stock.priority}
            </div>
            <div
              className={'jtce-stock-el-warehouse'}
              style={{
                backgroundColor: isCentralWarehouse(stock.warehouseId)
                  ? 'var(--color-inherited)'
                  : 'var(--color-primary)',
              }}
            >
              {isCentralWarehouse(stock.warehouseId) ? 'C' : 'M'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StocksCell;
