import './deliverytimescell.css';
import { DeliveryTimeResponse } from '../../../../../api/petcloudapi/api';

interface DeliveryTimesCellProps {
  deliveryTimes: DeliveryTimeResponse[];
}

const units = {
  Hours: 'Stunden',
  Days: 'Tage',
  Weeks: 'Wochen',
  Months: 'Monate',
  Years: 'Jahre',
};

const DeliveryTimesCell: React.FC<DeliveryTimesCellProps> = ({
  deliveryTimes,
}) => {
  return (
    <div className={'deliveryTimesCell'}>
      {deliveryTimes?.map((time, i) => {
        const value = `${time.min} - ${time.max} ${
          units[time.unit] ?? 'unknown unit'
        }`;
        return (
          <div key={i} className={'jtce-bubble'}>
            {value}
          </div>
        );
      })}
    </div>
  );
};

export default DeliveryTimesCell;
