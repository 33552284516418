import './headerconfigsettings.css';
import { JsonTableHeader } from '../JsonTable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconColumns } from '../../../../assets/icon/jsontable/menu/columns.svg';
import { useCallback, useRef, useState } from 'react';
import ClickOutsideContainer from '../../../elements/clickoutsidecontianer/ClickOutsideContainer';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import HeaderSetting from './headersetting/HeaderSetting';
import { arrayMoveImmutable } from 'array-move';

interface HeaderConfigSettingsProps {
  headers: JsonTableHeader[];
  setHeaders: (headers: JsonTableHeader[]) => void;
}

const HeaderConfigSettings: React.FC<HeaderConfigSettingsProps> = ({
  headers,
  setHeaders,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.jsonTable.headerConfigSettings',
  });
  const [menu, setMenu] = useState(false);
  const ref = useRef(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over) {
      const oldPosition = headers.findIndex((group) => group.id === active.id);
      const newPosition = headers.findIndex((group) => group.id === over.id);
      const update = [...arrayMoveImmutable(headers, oldPosition, newPosition)];
      setHeaders(update);
    }
  };

  const toggle = useCallback(
    (header: JsonTableHeader, key: keyof JsonTableHeader) => {
      const update = [...headers];
      const i = update.indexOf(header);
      if (i !== -1) {
        update[i] = {
          ...update[i],
          [key]: !update[i][key],
        };
        setHeaders(update);
      }
    },
    [headers]
  );

  return (
    <div className={'headerConfigSettings'} ref={ref}>
      <div
        className={'actionsPopout-action actionsPopout-action__secondary'}
        onClick={() => setMenu(true)}
      >
        <div className={'actionsPopout-action-icon'}>
          <IconColumns className={'actionsPopout-action-icon'} />
        </div>
        {t('cta')}
      </div>
      {menu && ref ? (
        <ClickOutsideContainer
          onClickOutside={() => setMenu(false)}
          fixedViewportAwareElementRef={ref}
        >
          <div className={'headerConfigSettings-menu'}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={onDragEnd}
            >
              <SortableContext items={headers} strategy={rectSortingStrategy}>
                {headers.map((header) => {
                  return (
                    <HeaderSetting
                      key={header.id}
                      {...header}
                      toggleVisibility={() => toggle(header, 'hidden')}
                      togglePinned={() => toggle(header, 'pinned')}
                    />
                  );
                })}
              </SortableContext>
            </DndContext>
          </div>
        </ClickOutsideContainer>
      ) : null}
    </div>
  );
};

export default HeaderConfigSettings;
