import UniversalProductPrice from '../../../types/UniversalProductPrice';
import { TranslatedTypeResponseOfString } from '../../../api/petcloudapi/api';

export const addEmptyAnalyticConstituent = (
  currentConstituentsLength: number,
  name?: TranslatedTypeResponseOfString,
  value?: number
) => {
  return {
    id: Math.random().toString(),
    createdAt: 'createdAt',
    productId: 'productId',
    value: value ?? 0,
    position: currentConstituentsLength,
    name: name ?? {
      'de-DE': '',
      'en-GB': '',
    },
  };
};

export const addEmptyPrice = (
  price: UniversalProductPrice,
  productId: string
) => {
  return {
    ...price,
    net: price.net ?? 0,
    productId: productId,
    createdAt: 'createdAt',
    currency: {
      code: 'EUR',
      factor: 1,
      symbol: '€',
      position: 0,
      countryId: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
      country: {
        iso: 'DE',
        iso3: 'DEU',
        name: {
          'de-DE': 'Deutschland',
          'en-GB': 'Germany',
        },
        id: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
        createdAt: '2022-07-13T09:38:58.345302+00:00',
        updatedAt: null,
      },
      name: {
        'de-DE': 'Euro',
        'en-GB': 'Euro',
      },
      shortName: {
        'de-DE': 'EUR',
        'en-GB': 'EUR',
      },
      shopReferenceId: null,
      syncedAt: null,
      id: 'f0902077-da87-4a7f-9bbd-c3e05a1f4a22',
      createdAt: '2022-07-13T09:38:58.345302+00:00',
      updatedAt: null,
    },
    country: {
      iso: 'DE',
      iso3: 'DEU',
      name: {
        'de-DE': 'Deutschland',
        'en-GB': 'Germany',
      },
      id: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
      createdAt: '2022-07-13T09:38:58.345302+00:00',
      updatedAt: null,
    },
  };
};
