import './newpropertyvalidationrule.css';
import Popup from '../../../../elements/popup/Popup';
import PropertyValidationRuleEdit from '../propertyvalidationruleedit/PropertyValidationRuleEdit';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CreatePropertyValidationRuleRequest } from '../../../../api/petcloudapi/api';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../../hooks/useNotifications';

const createPropertyValidationRuleRequest: CreatePropertyValidationRuleRequest =
  {
    name: '',
    callable: null,
    arguments: null,
    constraints: null,
    objectPath: null,
    message: {
      'de-DE': null,
      'en-GB': null,
    },
    description: {
      'de-DE': null,
      'en-GB': null,
    },
  };

interface NewPropertyValidationRuleProps {
  popup: boolean;
  closePopup: () => void;
  refreshValidationRules?: () => void;
}

const NewPropertyValidationRule: React.FC<NewPropertyValidationRuleProps> = ({
  popup,
  closePopup,
  refreshValidationRules,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.propertyValidationRules',
  });
  const api = usePetCloudApi();
  const propertyValidationRulesApi = api.propertyValidationRulesApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();
  const [request, setRequest] = useState<CreatePropertyValidationRuleRequest>(
    createPropertyValidationRuleRequest
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitNewRule = () => {
    setIsSubmitting(true);
    propertyValidationRulesApi
      .propertyValidationRulesCreatePropertyValidationRule(request)
      .then((response) => {
        console.log(response);
        pushNotification(t('new.notifications.success'));
        if (refreshValidationRules) {
          refreshValidationRules();
        }
        setIsSubmitting(false);
        setRequest(createPropertyValidationRuleRequest);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={'newPropertyValidationRule'}>
      <Popup toggled={popup} width={'40%'} close={closePopup}>
        <div className={'popup-title'}>{t('new.title')}</div>
        <PropertyValidationRuleEdit
          request={request}
          update={(r) => setRequest(r)}
        />
        <div className={'global-cardActions'}>
          <Button
            cta={t('new.cta')}
            look={'save'}
            width={'minimal'}
            action={submitNewRule}
            isLoading={isSubmitting}
          />
        </div>
      </Popup>
    </div>
  );
};

export default NewPropertyValidationRule;
