import axios, { AxiosResponse } from 'axios';
import { useErrorHandler } from '../contexts/errorhandler/ErrorHandler';
import { AssetResponse } from '../api/petcloudapi/api';
import JSZip from 'jszip';

const useFileDownload = () => {
  const errorHandler = useErrorHandler();

  const downloadFromBlob = (response: AxiosResponse) => {
    let fileName;
    try {
      fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .split(';')[0];
    } catch {
      fileName = 'file';
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getBlob = (url: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
            Cache: 'no-cache',
          },
        })
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
          errorHandler.addError(error.response);
        });
    });
  };

  const downloadFile = (blob: Blob, fileName: string) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadStaticFile = async (
    uri: string,
    accept: string,
    fileName: string,
    loadStateToggleCallback?: () => void
  ) => {
    if (loadStateToggleCallback) {
      loadStateToggleCallback();
    }
    axios
      .get(uri, {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
          Cache: 'no-cache',
        },
      })
      .then((response) => {
        console.log(response);
        if (loadStateToggleCallback) {
          loadStateToggleCallback();
        }
        downloadFile(response.data, fileName);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        if (loadStateToggleCallback) {
          loadStateToggleCallback();
        }
      });
  };

  const getAssetFileName = (asset: AssetResponse) => {
    return `${asset.fileName.split('/').at(-1) ?? 'file'}.${asset.extension}`;
  };

  const downloadAsset = async (asset: AssetResponse) => {
    const blob = await getBlob(asset.uri);
    downloadFile(blob, getAssetFileName(asset));
  };

  const downloadZippedAssets = (
    assets: AssetResponse[],
    callback?: () => void
  ) => {
    const blobPromises = assets.map((asset) => {
      return getBlob(asset.uri);
    });

    Promise.all(blobPromises).then((blobs) => {
      const zip = JSZip();
      blobs.forEach((blob, i) => {
        zip.file(getAssetFileName(assets[i]), blob);
      });
      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `pc_download-${currentDate}.zip`;
        downloadFile(zipFile, fileName);
        if (callback) {
          callback();
        }
      });
    });
  };

  return {
    downloadFromBlob,
    downloadAsset,
    downloadZippedAssets,
    downloadFile,
    downloadStaticFile,
  };
};

export default useFileDownload;
