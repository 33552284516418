import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { UpsertProductAdditionalInformationTableRequest } from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import { TabSelector } from '../../../../elements/selectors/Selectors';
import Table from '../../../../elements/stringarraytable/StringArrayTable';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import './additionalinformationtable.css';

interface AdditionalInformationTableProps {
  productId?: string;
  body: UpsertProductAdditionalInformationTableRequest;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  getProduct?: () => void;
  closePopup?: () => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  onSave?: (request: any) => void;
  onAdd?: (request: any) => void;
}

const AdditionalInformationTable: React.FC<AdditionalInformationTableProps> = ({
  productId,
  body,
  queueCriticalAction,
  killCriticalAction,
  getProduct,
  closePopup,
  readonly,
  toggleProductSavingHelpOverlay,
  onSave,
  onAdd,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const [payload, setPayload] =
    useState<UpsertProductAdditionalInformationTableRequest>(body);
  const [selectedLanguage, setSelectedLanguage] = useState('de-DE');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onSave && !onAdd) {
      onSave(payload);
    }
  }, [payload]);

  const submitTable = (
    request: UpsertProductAdditionalInformationTableRequest
  ) => {
    if (productId && !onSave && !onAdd) {
      if (queueCriticalAction) {
        queueCriticalAction('updatingTable');
      }
      setIsSubmitting(true);
      productsApi
        .productsUpsertProductAdditionalInformationTable(productId, request)
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          Store.addNotification({
            message: t(
              'view.product.additionalinformation.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (getProduct) {
            getProduct();
          }
          if (killCriticalAction) {
            killCriticalAction('updatingTable');
          }
          if (closePopup) {
            closePopup();
          }
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          errorHandler.addError(error.response);
          if (killCriticalAction) {
            killCriticalAction('updatingTable');
          }
        });
    } else if (onAdd) {
      onAdd(request);
    }
  };

  const updateHeader = (value: string, index: number) => {
    const header = payload.header[selectedLanguage as TranslatedStringIndex];
    if (header) {
      header[index] = value;
      setPayload({
        ...payload,
        header: {
          ...payload.header,
          [selectedLanguage as TranslatedStringIndex]: [...header],
        },
      });
    }
  };

  const updateCell = (value: string, rowIndex: number, cellIndex: number) => {
    const rows = payload.rows[selectedLanguage as TranslatedStringIndex];
    if (rows) {
      rows[rowIndex][cellIndex] = value;
      setPayload({
        ...payload,
        rows: {
          ...payload.rows,
          [selectedLanguage as TranslatedStringIndex]: [...rows],
        },
      });
    }
  };

  const changeColumns = (index: number, deletion: boolean) => {
    const header = payload.header[selectedLanguage as TranslatedStringIndex];
    const rows = payload.rows[selectedLanguage as TranslatedStringIndex];

    if (header && rows) {
      const updatedRows = rows.map((row) => {
        if (deletion) {
          row.splice(index, 1);
        } else {
          row.splice(index + 1, 0, '');
        }
        return row;
      });
      const updatedHeader = [...header];
      if (deletion) {
        updatedHeader.splice(index, 1);
      } else {
        updatedHeader.splice(index + 1, 0, '');
      }
      setPayload({
        ...payload,
        header: {
          ...payload.header,
          [selectedLanguage as TranslatedStringIndex]: updatedHeader,
        },
        rows: {
          ...payload.rows,
          [selectedLanguage as TranslatedStringIndex]: [...updatedRows],
        },
      });
    }
  };

  const changeRows = (index: number, deletion: boolean) => {
    const header = payload.header[selectedLanguage as TranslatedStringIndex];
    const rows = payload.rows[selectedLanguage as TranslatedStringIndex];
    const newRow: string[] = [];

    if (header && rows) {
      header.forEach(() => {
        newRow.push('');
      });

      if (deletion) {
        rows.splice(index, 1);
      } else {
        rows.splice(index + 1, 0, newRow);
      }

      setPayload({
        ...payload,
        rows: {
          ...payload.rows,
          [selectedLanguage as TranslatedStringIndex]: [...rows],
        },
      });
    }
  };

  const parsePasteToTableData = (
    input: string,
    startCoords: { column: number; row: number }
  ) => {
    const rows = input.split('\n');
    const head = payload.header[selectedLanguage as TranslatedStringIndex];
    const body = payload.rows[selectedLanguage as TranslatedStringIndex];

    if (head && body) {
      const updatedHeader = [...head];
      let updatedBody = [...body];

      console.log(startCoords);

      // parse the pasted input
      rows.forEach((row, i) => {
        const arr = row.split('\t');
        if (startCoords.row === 0 && i === 0) {
          updatedHeader.splice(startCoords.column, arr.length, ...arr);
        } else {
          const correctedRowIndex = startCoords.row - 1 + i;
          const updatedRow =
            correctedRowIndex < body.length ? body[correctedRowIndex] : [''];
          updatedRow.splice(startCoords.column, arr.length, ...arr);
          updatedBody.splice(correctedRowIndex, 1, updatedRow);
        }
      });

      //add empty cells where needed
      let longestColumnLength = updatedHeader.length;
      updatedBody.forEach((row) => {
        if (row.length > longestColumnLength) {
          longestColumnLength = row.length;
        }
      });
      const finalHeader = fillRowUp(
        updatedHeader,
        updatedHeader.length,
        longestColumnLength
      );
      const finalBody = updatedBody.map((row) => {
        return fillRowUp(row, row.length, longestColumnLength);
      });

      // finally save the new data
      setPayload({
        ...payload,
        header: {
          ...payload.header,
          [selectedLanguage as TranslatedStringIndex]: [...finalHeader],
        },
        rows: {
          ...payload.rows,
          [selectedLanguage as TranslatedStringIndex]: [...finalBody],
        },
      });
    }
  };

  const fillRowUp = (row: string[], length: number, maxLength: number) => {
    for (let i = length; i < maxLength; i++) {
      row.push('');
    }
    return row;
  };

  const header = payload.header[selectedLanguage as TranslatedStringIndex];
  const rows = payload.rows[selectedLanguage as TranslatedStringIndex];
  return (
    <div className="additionalinformationtable">
      <TabSelector
        tabs={[
          {
            title: 'de-DE',
            key: 'de-DE',
          },
          {
            title: 'en-GB',
            key: 'en-GB',
          },
        ]}
        activeTabKey={selectedLanguage}
        update={(key: string) => setSelectedLanguage(key)}
      />
      <div className="additionalinformationtable-table">
        {header && rows ? (
          <Table
            key={selectedLanguage}
            header={header}
            rows={rows}
            updateHeader={updateHeader}
            updateCell={updateCell}
            addColumn={(i) => changeColumns(i, false)}
            deleteColumn={(i) => changeColumns(i, true)}
            addRow={(i) => changeRows(i, false)}
            deleteRow={(i) => changeRows(i, true)}
            onPaste={parsePasteToTableData}
          />
        ) : null}
      </div>
      {!readonly && !onSave ? (
        <div className="additionalinformationtable-actions">
          <Button
            cta={t('actions.save')}
            action={() => submitTable(payload)}
            isLoading={isSubmitting}
            look="save"
            width="minimal"
          />
        </div>
      ) : null}
    </div>
  );
};

export default AdditionalInformationTable;
