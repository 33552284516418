import './jsonviewer.css';
import { JSONTree } from 'react-json-tree';

const theme = {
  scheme: 'JSON Bourne',
  author: 'PetCloud GmbH',
  base00: 'var(--color-background)',
  base01: '#393F45',
  base02: '#565E65',
  base03: '#747C84',
  base04: '#ADB3BA',
  base05: '#C7CCD1',
  base06: '#DFE2E5',
  base07: '#F3F4F5',
  base08: 'var(--color-danger)',
  base09: 'var(--color-inherited)',
  base0A: '#AEC795',
  base0B: 'var(--color-text_primary)',
  base0C: '#95AEC7',
  base0D: 'var(--color-text_secondary)',
  base0E: '#C795AE',
  base0F: '#C79595',
};

interface JSONViewerProps {
  jsonString: string;
}

const JSONViewer: React.FC<JSONViewerProps> = ({ jsonString }) => {
  return (
    <div className={'jsonviewer'}>
      <JSONTree data={JSON.parse(jsonString)} theme={theme} />
    </div>
  );
};

export default JSONViewer;
