import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { UpsertProductAdditionalInformationRichTextRequest } from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import FullEditor from '../../../../elements/fulleditor/FullEditor';
import { TabSelector } from '../../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import './richtext.css';

interface RichTextProps {
  productId?: string;
  body: UpsertProductAdditionalInformationRichTextRequest;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
  getProduct?: () => void;
  closePopup?: () => void;
  readonly?: boolean;
  toggleProductSavingHelpOverlay?: () => void;
  onSave?: (request: any) => void;
  onAdd?: (request: any) => void;
}

const RichText: React.FC<RichTextProps> = ({
  productId,
  body,
  queueCriticalAction,
  killCriticalAction,
  getProduct,
  closePopup,
  readonly,
  toggleProductSavingHelpOverlay,
  onSave,
  onAdd,
}) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const [payload, setPayload] = useState(body);
  const [selectedLanguage, setSelectedLanguage] = useState('de-DE');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (onSave) {
      onSave(payload);
    }
  }, [payload]);

  const submitRichText = (
    request: UpsertProductAdditionalInformationRichTextRequest
  ) => {
    if (productId && !onSave && !onAdd) {
      setIsSubmitting(true);
      if (queueCriticalAction) {
        queueCriticalAction('updatingRichtext');
      }
      productsApi
        .productsUpsertProductAdditionalInformationRichText(productId, request)
        .then((response) => {
          console.log(response);
          setIsSubmitting(false);
          Store.addNotification({
            message: t(
              'view.product.additionalinformation.notifications.update_successful'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
            },
          });
          if (getProduct) {
            getProduct();
          }
          if (killCriticalAction) {
            killCriticalAction('updatingRichtext');
          }
          if (closePopup) {
            closePopup();
          }
          if (toggleProductSavingHelpOverlay) {
            toggleProductSavingHelpOverlay();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          errorHandler.addError(error.response);
          if (killCriticalAction) {
            killCriticalAction('updatingRichtext');
          }
        });
    } else if (onAdd) {
      onAdd(request);
    }
  };

  return (
    <div className="richtext">
      <TabSelector
        tabs={[
          {
            title: 'de-DE',
            key: 'de-DE',
          },
          {
            title: 'en-GB',
            key: 'en-GB',
          },
        ]}
        activeTabKey={selectedLanguage}
        update={(key: string) => setSelectedLanguage(key)}
      />
      <div className="richtext-editor">
        {selectedLanguage === 'de-DE' ? (
          <FullEditor
            key={'de'}
            initialHTMLValue={payload.value['de-DE']}
            onUpdate={(content) =>
              setPayload({
                ...payload,
                value: {
                  ...payload.value,
                  'de-DE': content,
                },
              })
            }
          />
        ) : selectedLanguage === 'en-GB' ? (
          <FullEditor
            key={'en'}
            initialHTMLValue={payload.value['en-GB']}
            onUpdate={(content) =>
              setPayload({
                ...payload,
                value: {
                  ...payload.value,
                  'en-GB': content,
                },
              })
            }
          />
        ) : null}
      </div>
      {!readonly && !onSave ? (
        <div className="richtext-actions global-cardActions">
          <Button
            cta={t('actions.save')}
            look="save"
            width="minimal"
            action={() => submitRichText(payload)}
            isLoading={isSubmitting}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RichText;
