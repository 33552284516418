import './categories.css';
import { MouseEvent, useEffect, useState } from 'react';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { ProductCategoryResponse } from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import CategoryHierarchy from '../../../features/categoryhierarchy/CategoryHierarchy';
import Button from '../../../elements/button/Button';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import Popup from '../../../elements/popup/Popup';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import { Dropdown } from '../../../elements/selectors/Selectors';

const Categories = () => {
  const { t, i18n } = useTranslation();
  const api = usePetCloudApi();
  const errorHandler = useErrorHandler();
  const productCategoriesApi = api.productCategoriesApi();
  const [availableProductCategories, setAvailableProductCategories] = useState<
    ProductCategoryResponse[] | null
  >(null);
  const [selectedProductCategories, setSelectedProductCategories] = useState<
    ProductCategoryResponse[]
  >([]);
  const [
    selectedMoveTargetProductCategories,
    setSelectedMoveTargetProductCategories,
  ] = useState<ProductCategoryResponse[]>([]);
  const [dangerPopup, setDangerPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [movePopup, setMovePopup] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<TranslatedStringIndex>(
    i18n.language as TranslatedStringIndex
  );

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    productCategoriesApi
      .productCategoriesGetProductCategories(true)
      .then((response) => {
        console.log(response.data);
        setAvailableProductCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deleteSelectedCategories = () => {
    setIsSubmitting(true);
    const promises = selectedProductCategories.map((category) => {
      return deleteCategory(category.id);
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t(
            'view.admin.productCategories.notifications.delete_successful'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedProductCategories([]);
        getCategories();
      })
      .catch(() => {
        Store.addNotification({
          message: t(
            'view.admin.productCategories.notifications.delete_failed'
          ),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedProductCategories([]);
        getCategories();
      });
  };

  const deleteCategory = (id: string) => {
    return new Promise((resolve, reject) => {
      productCategoriesApi
        .productCategoriesDeleteProductCategory(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const moveSelectedCategories = (
    e: MouseEvent<HTMLDivElement>,
    toFirstLayer?: boolean
  ) => {
    setIsSubmitting(true);
    const promises = selectedProductCategories.map((category) => {
      return moveCategory(
        category,
        toFirstLayer === true
          ? null
          : selectedMoveTargetProductCategories.length > 0
          ? selectedMoveTargetProductCategories[0].id
          : null
      );
    });

    Promise.all(promises)
      .then(() => {
        Store.addNotification({
          message: t(
            'view.admin.productCategories.notifications.move_successful'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setMovePopup(false);
        setIsSubmitting(false);
        setSelectedProductCategories([]);
        setAvailableProductCategories([]);
        getCategories();
      })
      .catch(() => {
        Store.addNotification({
          message: t('view.admin.productCategories.notifications.move_failed'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        setMovePopup(false);
        setIsSubmitting(false);
        setSelectedProductCategories([]);
        getCategories();
      });
  };

  const moveCategory = (
    category: ProductCategoryResponse,
    targetId: string | null
  ) => {
    console.log(targetId);
    return new Promise((resolve, reject) => {
      productCategoriesApi
        .productCategoriesUpdateProductCategory(category.id, {
          ...category,
          parentId: targetId,
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  if (availableProductCategories) {
    return (
      <div className="admin-categories">
        <div className="admin-categories-localeSwitch">
          <Dropdown
            options={['de-DE', 'en-GB']}
            selected={selectedLocale}
            update={(e) => {
              const val =
                e.target.selectedOptions[0].getAttribute('data-value');
              if (val) {
                setSelectedLocale(val as TranslatedStringIndex);
              }
            }}
          />
        </div>
        {selectedProductCategories.length > 0 ? (
          <div className="admin-categories-adminActions">
            <Button
              cta={t('view.admin.productCategories.actions.delete')}
              width={'minimal'}
              look="secondary-danger"
              action={() => setDangerPopup(true)}
              margin="right"
            />
            <Button
              cta={t('view.admin.productCategories.actions.move')}
              width={'minimal'}
              look={'secondary'}
              action={() => setMovePopup(true)}
            />
          </div>
        ) : null}
        <CategoryHierarchy
          availableProductCategories={availableProductCategories}
          currentProductCategories={selectedProductCategories}
          updateCurrentProductCategories={setSelectedProductCategories}
          allowAdminFeatures={true}
          getCategories={getCategories}
          selectedLocale={selectedLocale}
        />
        <Popup
          width="30%"
          toggled={dangerPopup}
          close={() => setDangerPopup(false)}
        >
          <div className="popup-title">
            {t('view.admin.productCategories.dangerPopup.title')}
          </div>
          <div className="admin-categories-dangerPopup-message">
            {t('view.admin.productCategories.dangerPopup.message')}
          </div>
          <Button
            width="full"
            look="danger"
            cta={t('view.admin.productCategories.dangerPopup.cta')}
            action={deleteSelectedCategories}
            isLoading={isSubmitting}
          />
        </Popup>
        <Popup
          width="50%"
          toggled={movePopup}
          close={() => setMovePopup(false)}
        >
          <div className="popup-title">
            {t('view.admin.productCategories.movePopup.title')}
          </div>
          <div className="popup-card-content-text">
            {t('view.admin.productCategories.movePopup.message')}
          </div>
          <div className="admin-categories-movePopup-hierarchy">
            <CategoryHierarchy
              availableProductCategories={availableProductCategories}
              currentProductCategories={selectedMoveTargetProductCategories}
              updateCurrentProductCategories={
                setSelectedMoveTargetProductCategories
              }
              onlyAllowSingleSelection={true}
              getCategories={getCategories}
            />
          </div>
          <div className="popup-card-content-actions">
            <Button
              cta={t('view.admin.productCategories.movePopup.cta')}
              width="minimal"
              look={'secondary'}
              active={selectedMoveTargetProductCategories.length > 0}
              isLoading={isSubmitting}
              action={moveSelectedCategories}
            />
            <Button
              cta={t('view.admin.productCategories.movePopup.cta2')}
              width="minimal"
              look={'secondary'}
              isLoading={isSubmitting}
              margin="left"
              action={(e) => moveSelectedCategories(e, true)}
            />
          </div>
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Categories;
