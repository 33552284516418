import { useParams } from 'react-router-dom';
import ShopifyIntegration from './shopify/ShopifyIntegration';
import NotFound from '../notfound/NotFound';

const Integration = () => {
  const params = useParams();
  const integrationId = params.integrationId;
  const integrationName = params.integrationName;
  const manufacturerId = params.manufacturerId;

  if (integrationId) {
    switch (integrationName) {
      case 'shopify':
        return (
          <ShopifyIntegration
            integrationId={integrationId}
            manufacturerId={manufacturerId}
          />
        );
      default:
        return null;
    }
  } else {
    return <NotFound />;
  }
};

export default Integration;
