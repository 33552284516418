import './recurringcustomersfiltering.css';
import { ReturningCustomersStatisticsResponse } from '../../../../api/petcloudapi/api';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../elements/button/Button';
import { NoResults } from '../../../../elements/emptystate/EmptyState';

interface RecurringCustomersFilteringProps {
  data: ReturningCustomersStatisticsResponse[];
}

const RecurringCustomersFiltering: React.FC<
  RecurringCustomersFilteringProps
> = ({ data }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.widgets.recurringCustomers.recurringCustomersFilter',
  });
  const [accountType, setAccountType] = useState('all');
  const [minOrderNumber, setMinOrderNumber] = useState(2);
  const [emails, setEmails] = useState<string[] | null>(null);

  const getOrderNumberOptions = () => {
    let highScore = 0;
    data.forEach((item) => {
      if (item.numberOfOrders && item.numberOfOrders > highScore) {
        highScore = item.numberOfOrders;
      }
    });
    const options: string[] = [];
    for (let i = 1; i <= highScore; i++) {
      options.push(i.toString());
    }
    return options;
  };

  const getEmailAddresses = () => {
    const filtered = data.filter((x) => {
      return (
        (accountType !== 'all'
          ? x.guestAccount === (accountType === 'guest')
          : x.guestAccount === true || x.guestAccount === false) &&
        x.numberOfOrders &&
        x.numberOfOrders >= minOrderNumber
      );
    });
    console.log(filtered);
    const results: string[] = [];
    filtered.forEach((x) => {
      x.emails?.forEach((email) => results.push(email));
    });
    setEmails(results);
  };

  return (
    <div className={'recurringCustomersFiltering'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('accountType.title')}
            options={['all', 'guest', 'account']}
            translationPath={
              'components.widgets.recurringCustomers.recurringCustomersFilter.accountType.'
            }
            selected={accountType}
            update={(e) => {
              setAccountType(e.currentTarget.selectedOptions[0].value);
            }}
          />
        </div>
        <div className={'global-inputGroup-input'}>
          <Dropdown
            title={t('minOrderNumber')}
            options={getOrderNumberOptions()}
            selected={minOrderNumber.toString()}
            update={(e) => {
              setMinOrderNumber(
                parseInt(e.currentTarget.selectedOptions[0].value)
              );
            }}
          />
        </div>
      </div>
      {emails && emails.length > 0 ? (
        <div className={'recurringCustomersFiltering-emails'}>
          {emails.map((email) => (
            <div className={'recurringCustomersFiltering-email'}>{email}</div>
          ))}
        </div>
      ) : (
        <NoResults />
      )}
      <div className={'global-cardActions'}>
        <Button
          cta={t('cta')}
          look={'secondary'}
          width={'minimal'}
          action={getEmailAddresses}
        />
      </div>
    </div>
  );
};

export default RecurringCustomersFiltering;
