import './bundlepositionedit.css';
import {
  ProductBundlePositionResponse,
  TaxTypeIdentifier,
} from '../../../../api/petcloudapi/api';
import Input from '../../../../elements/input/Input';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button from '../../../../elements/button/Button';
import Price from '../../../product/pricing/Price';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import UniversalProductPrice from '../../../../types/UniversalProductPrice';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface BundlePositionEditProps {
  bundlePosition: ProductBundlePositionResponse;
  saveBundlePosition: (position: ProductBundlePositionResponse) => void;
  currencyOptions: DropdownOption[];
  countryOptions: DropdownOption[];
}

const BundlePositionEdit: React.FC<BundlePositionEditProps> = ({
  bundlePosition,
  saveBundlePosition,
  currencyOptions,
  countryOptions,
}) => {
  const [position, setPosition] = useState(bundlePosition);

  const { t } = useTranslation('translations', {
    keyPrefix: 'view.bundle.positions.bundlePositionEdit',
  });

  const addPrice = () => {
    const update = { ...position };
    update.prices?.push({
      id: crypto.randomUUID(),
      createdAt: 'createdAt',
      taxTypeId: 'taxTypeId',
      taxTypeIdentifier: TaxTypeIdentifier.Full,
      currencyId: 'f0902077-da87-4a7f-9bbd-c3e05a1f4a22',
      countryId: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
      productBundlePositionId: 'productBundlePositionId',
      productId: position.productId,
      gross: 0,
      listPriceGross: null,
    });
    setPosition(update);
  };

  const deletePrice = (index: number) => {
    const update = { ...position };
    update.prices?.splice(index, 1);
    setPosition(update);
  };

  const updatePrice = (index: number, price: UniversalProductPrice) => {
    const update = { ...position };
    if (update.prices) {
      update.prices[index] = {
        ...update.prices[index],
        countryId: price.countryId,
        currencyId: price.currencyId,
        gross: price.gross,
        listPriceGross: price.listPriceGross,
      };
    }
    setPosition(update);
  };

  return (
    <div className={'bundlePositionEdit'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input'}>
          <Input
            title={t('quantity')}
            content={position.quantity.toString()}
            update={(value) => {
              setPosition({ ...position, quantity: parseInt(value) });
            }}
            type={'number'}
          />
        </div>
      </div>
      <div className={'bundlePositionEdit-sectionTitle'}>
        {t('pricesTitle')}
      </div>
      <div className={'bundlePositionEdit-prices'}>
        {position.prices && position.prices.length > 0 ? (
          position.prices?.map((price, i) => {
            return (
              <Price
                index={i}
                price={price}
                updatePrice={updatePrice}
                currencyOptions={currencyOptions}
                countryOptions={countryOptions}
                mode={'NEW'}
                disableGraduation
                deletePrice={() => deletePrice(i)}
              />
            );
          })
        ) : (
          <EmptyState message={t('pricesEmpty')} />
        )}
      </div>
      <div
        className={
          'global-cardActions global-cardActions-postBorder global-cardActions-spaceBetween'
        }
      >
        <Button
          cta={t('actions.add')}
          look={'secondary'}
          action={addPrice}
          width={'minimal'}
          active={
            !!position.prices && position.prices.length < position.quantity
          }
        />
        <Button
          cta={t('actions.save')}
          look={'save'}
          action={() => saveBundlePosition(position)}
          width={'minimal'}
          active={position.prices?.length === position.quantity}
          inactiveMessage={t('actions.saveInactive')}
        />
      </div>
    </div>
  );
};

export default BundlePositionEdit;
