import './toolsmenu.css';
import { useRef, useState } from 'react';
import Button from '../../../../elements/button/Button';
import { ReactComponent as IconMenu } from '../../../../../assets/icon/sliders.svg';
import ActionsPopout from '../../../../elements/itemactions/actionspopout/ActionsPopout';
import { ItemAction } from '../../../../elements/itemactions/ItemActions';

interface ToolsMenuProps {
  active: boolean;
  actions: ItemAction[];
}

const ToolsMenu: React.FC<ToolsMenuProps> = ({ active, actions }) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={'toolsMenu'} ref={ref}>
      <Button
        type={'icon'}
        action={(e) => setShow(true)}
        look={'secondary'}
        width={'minimal'}
        active={active}
      >
        <IconMenu
          className={'button-icon button-icon-tertiary'}
          fill={'var(--color-text_tertiary)'}
        />
      </Button>
      {show && ref.current ? (
        <ActionsPopout
          show={true}
          hide={() => setShow(false)}
          actions={actions}
          anchorRef={ref}
        />
      ) : null}
    </div>
  );
};

export default ToolsMenu;
