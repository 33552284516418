import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card, { Tabs, Tab, CardSection } from '../../elements/card/Card';
import { LanguageSwitcher } from '../../elements/languageswitcher/LanguageSwitcher';
import Profile from '../../sections/settings/account/Profile';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { ManufacturerResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useAuthorization } from '../../contexts/auth/Authorization';
import { useUser } from '../../contexts/auth/User';
import ThemeSwitcher from '../../elements/themeswitcher/ThemeSwitcher';
import { Stage, Navigator } from '../../layout/stage/Stage';
import NotificationSubscriptions from '../../sections/settings/notificationsubscriptions/NotificationSubscriptions';
import ToggledSettingSwitches from '../../sections/settings/toggledsettingswitches/ToggledSettingSwitches';

const Account: React.FC = () => {
  const { user } = useUser();
  const { authorizations } = useAuthorization();
  const { t } = useTranslation();
  const manufacturersApi = usePetCloudApi().manufacturersApi();
  const errorHandler = useErrorHandler();

  const [manufacturer, setManufacturer] = useState<ManufacturerResponse | null>(
    null
  );

  useEffect(() => {
    getManufacturer();
  }, []);

  const getManufacturer = () => {
    if (user && user.manufacturerId) {
      manufacturersApi
        .manufacturersGetManufacturerById(user.manufacturerId)
        .then((response) => {
          console.log(response);
          setManufacturer({ ...response.data });
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const tabs = [
    { title: t('view.account.profile.title'), key: 'account' },
    { title: t('view.account.interface.title'), key: 'interface' },
    {
      title: t('view.account.notificationSubscriptions.title'),
      key: 'notificationSubscriptions',
    },
  ];

  const getSettingsIdentifiers = () => {
    const result = ['interface_compactList'];
    if (user?.isProductOwner) {
      result.push('excludeUnlockedOptions');
    }
    return result;
  };

  if (user && authorizations) {
    return (
      <Stage>
        <Navigator title={t('view.account.title')}></Navigator>
        <Tabs tabs={tabs} look="card">
          <Tab>
            <Profile manufacturer={manufacturer} />
          </Tab>
          <Tab>
            <Card bigScreenWidth="100%">
              <CardSection>
                <LanguageSwitcher global={true} />
                <ThemeSwitcher />
                <ToggledSettingSwitches
                  settingsIdentifiers={getSettingsIdentifiers()}
                />
              </CardSection>
            </Card>
          </Tab>
          <Tab>
            <Card bigScreenWidth={'100%'}>
              <CardSection
                title={t(
                  'view.account.notificationSubscriptions.subscribedNotifications'
                )}
              >
                <NotificationSubscriptions />
              </CardSection>
            </Card>
          </Tab>
        </Tabs>
      </Stage>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Account;
