import './bartooltip.css';
import { SoldProductResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';
import { ReactComponent as IconImgPlaceholder } from '../../../../../../assets/icon/image.svg';

interface StackBarTooltipProps {
  soldProduct: SoldProductResponse;
}

const DailyBestSellersBarTooltip: React.FC<StackBarTooltipProps> = ({
  soldProduct,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.widgets.dailyBestsellers.chart.toolTip',
  });

  const { product } = soldProduct;
  const lang = i18n.language as TranslatedStringIndex;
  const imgUri = product?.cover?.asset[lang]?.uri;

  return (
    <div className={'barTooltip'}>
      {imgUri ? (
        <img
          src={imgUri}
          alt={'Product Thumbnail'}
          className={'barTooltip-cover'}
        />
      ) : (
        <IconImgPlaceholder
          fill={'var(--color-gray)'}
          className={'barTooltip-cover barTooltip-cover-placeholder'}
        />
      )}
      <div className={'barTooltip-meta'}>
        <div className={'barTooltip-meta-title'}>{product?.name[lang]}</div>
        <div className={'barTooltip-meta-element'}>
          <span>{soldProduct.quantity}</span> {t('sold')}
        </div>
        <div className={'barTooltip-meta-element'}>
          <span>{soldProduct.totalRevenue}€</span> {t('revenue')}
        </div>
      </div>
    </div>
  );
};

export default DailyBestSellersBarTooltip;
