import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  CreateVariantProductsRequest,
  InlineCreateVariantProductRequest,
  ProductGroupMappingResponse,
  ProductState,
  ProductVariantResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../../api/petcloudapi/api';
import Button from '../../../elements/button/Button';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import { SmallHint } from '../../../elements/hint/Hint';
import Input from '../../../elements/input/Input';
import List from '../../../features/list/List';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import PropertyBrowser from '../../../elements/propertybrowser/PropertyBrowser';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import {
  getStateSortIndex,
  renderProductStateInList,
} from '../../../features/productlist/ProductList';
import './variants.css';
import usePersistInCookies from '../../../hooks/useStorageData';
import useUserTools from '../../../hooks/useUserTools';
import useNotifications from '../../../hooks/useNotifications';

interface VariantsProps {
  productVariants: ProductVariantResponse[] | null;
  animalSpeciesIds?: string[];
  productGroupMappings: ProductGroupMappingResponse[];
  productGroupId: string | null;
  refreshProductVariants?: () => void;
  productId?: string;
  callback?: (requests: InlineCreateVariantProductRequestWithId[]) => void;
  readonly?: boolean;
  queueCriticalAction?: (action: string) => void;
  killCriticalAction?: (action: string) => void;
}

type InlineCreateVariantProductRequestWithId =
  InlineCreateVariantProductRequest & { id: string };

const Variants: React.FC<VariantsProps> = ({
  productVariants,
  animalSpeciesIds,
  productGroupId,
  refreshProductVariants,
  productId,
  callback,
  readonly,
  queueCriticalAction,
  killCriticalAction,
  productGroupMappings,
}) => {
  const { t, i18n } = useTranslation();
  const link = useNavigate();
  const [popup, setPopup] = useState(false);
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const errorHandler = useErrorHandler();
  const { excludeUnlockedOptions } = useUserTools();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { persistObject, getPersistedObject } = usePersistInCookies();
  const { pushNotification } = useNotifications();

  const [selectedProductVariants, setSelectedProductVariants] = useState<
    ProductVariantResponse[]
  >([]);
  const [selectedVariantOptions, setSelectedVariantOptions] = useState<
    PropertyOptionResponse[]
  >([]);
  const [availableProductProperties, setAvailableProductProperties] = useState<
    PropertyGroupResponse[] | null
  >(null);

  const [newVariants, setNewVariants] = useState<
    InlineCreateVariantProductRequestWithId[] | null
  >(null);

  const [selectedNewVariants, setSelectedNewVariants] = useState<
    InlineCreateVariantProductRequestWithId[]
  >([]);
  const [inlineNewVariants, setInlineVariants] = useState<
    InlineCreateVariantProductRequestWithId[] | null
  >(null);

  const [selectedStep, setSelectedStep] = useState(1);

  useEffect(() => {
    getAvailableProductProperties();
  }, []);

  useEffect(() => {
    const persisted: InlineCreateVariantProductRequestWithId[] =
      getPersistedObject('new_product_variants');
    if (!productId && callback && availableProductProperties && persisted) {
      console.log(persisted);
      setInlineVariants(persisted ?? null);
      setNewVariants(persisted ?? null);
      const availableOptions = availableProductProperties.flatMap(
        (group) => group.options
      );
      callback(persisted);

      const selectedOptions: PropertyOptionResponse[] = [];

      persisted.forEach((variant) => {
        variant.variantOptionIds.forEach((id) => {
          const option = availableOptions.find((o) => o.id === id);
          if (option) {
            selectedOptions.push(option);
          }
        });
      });
      setSelectedVariantOptions(selectedOptions);
    }
  }, [availableProductProperties]);

  const getAvailableProductProperties = () => {
    if (productGroupId) {
      propertyGroupsApi
        .propertyGroupsGetPropertyGroups(
          undefined,
          undefined,
          excludeUnlockedOptions(),
          undefined,
          animalSpeciesIds,
          [productGroupId]
        )
        .then((response) => {
          console.log(response);
          setAvailableProductProperties(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const updateSelectedVariantOptions = (options: PropertyOptionResponse[]) => {
    setSelectedVariantOptions(options);
  };

  const cartesianProductVariants = (array: PropertyOptionResponse[][]) => {
    return array.reduce(
      (a: PropertyOptionResponse[][], b) => {
        return a
          .map((x) => {
            return b.map((y) => {
              return x.concat([y]);
            });
          })
          .reduce((a, b) => {
            return a.concat(b);
          }, []);
      },
      [[]]
    );
  };

  const submitNewVariants = () => {
    if (productId && selectedNewVariants.length > 0 && newVariants) {
      setIsSubmitting(true);
      if (queueCriticalAction) {
        queueCriticalAction('submitting_variants');
      }
      const request: CreateVariantProductsRequest = {
        variants: newVariants.filter(
          (x) => !!selectedNewVariants.find((y) => y.id === x.id)
        ),
      };
      productsApi
        .productsCreateProductVariants(productId, request)
        .then((response) => {
          console.log(response);
          pushNotification(
            t('view.product.notifications.variantCreation_successful')
          );
          setIsSubmitting(false);
          if (killCriticalAction) {
            killCriticalAction('submitting_variants');
          }
          setNewVariants([]);
          if (refreshProductVariants) {
            refreshProductVariants();
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      setInlineVariants(selectedNewVariants);
    }
  };

  const createNewVariantsList = () => {
    const groupIds: string[] = [];
    const groupedOptions: PropertyOptionResponse[][] = [];

    selectedVariantOptions.forEach((option) => {
      const optionGroup: PropertyOptionResponse[] = [];
      if (!groupIds.includes(option.propertyGroupId)) {
        groupIds.push(option.propertyGroupId);

        selectedVariantOptions.forEach((opt) => {
          if (opt.propertyGroupId === option.propertyGroupId) {
            optionGroup.push(opt);
          }
        });
      }
      if (optionGroup.length > 0) {
        groupedOptions.push(optionGroup);
      }
    });

    const variants = cartesianProductVariants(groupedOptions).map(
      (combination, i) => {
        const result: InlineCreateVariantProductRequestWithId & {
          [key: string]: string | string[] | null;
        } = {
          id: crypto.randomUUID(),
          productId: productId ?? null,
          variantOptionIds: combination.map((option) => option.id),
          ean: '',
        };

        combination.forEach((option) => {
          const lang = i18n.language as TranslatedStringIndex;
          const groupName = availableProductProperties?.find(
            (g) => g.id === option.propertyGroupId
          )?.name[lang];
          const name = option.name[lang];
          result[groupName ?? 'missing group translation'] =
            name ?? 'missing option translation';
        });

        return result;
      }
    );
    setNewVariants([...variants]);
    setSelectedNewVariants([...variants]);
  };

  useEffect(() => {
    if (callback && newVariants) {
      callback(newVariants);
      persistObject(newVariants, 'new_product_variants');
    }
  }, [newVariants]);

  const toggleSelectVariant = (
    variant: InlineCreateVariantProductRequestWithId
  ) => {
    const update = [...selectedNewVariants];
    const i = update.findIndex(
      (v) => v.variantOptionIds === variant.variantOptionIds
    );
    if (i >= 0) {
      update.splice(i, 1);
    } else {
      update.push(variant);
    }
    if (callback) {
      callback([...update]);
    }
    setSelectedNewVariants(update);
  };

  const archiveVariant = (variantId: string) => {
    if (productId) {
      if (queueCriticalAction) {
        queueCriticalAction('archiving_variants');
      }
      productsApi
        .productsUpdateProductState(variantId, {
          state: 'Archived' as ProductState,
        })
        .then((response) => {
          console.log(response);
          pushNotification(
            t('view.product.notifications.variantArchive_successful')
          );
          if (killCriticalAction) {
            killCriticalAction('archiving_variants');
          }
          if (refreshProductVariants) {
            refreshProductVariants();
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const unarchiveVariant = (variantId: string) => {
    if (productId) {
      if (queueCriticalAction) {
        queueCriticalAction('unarchiving_variants');
      }
      productsApi
        .productsUpdateProductState(variantId, {
          state: 'Draft' as ProductState,
        })
        .then((response) => {
          console.log(response);
          pushNotification(
            t('view.product.notifications.variantUnarchive_successful')
          );
          if (killCriticalAction) {
            killCriticalAction('unarchiving_variants');
          }
          if (refreshProductVariants) {
            refreshProductVariants();
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    }
  };

  const renderEAN = (value: string, item: any, index: number) => {
    return (
      <Input
        key={`v${index}`}
        content={value}
        update={(e) => {
          if (newVariants) {
            console.log(e);
            const update = [...newVariants];
            update[index] = { ...update[index], ean: e };
            setNewVariants(update);
            if (callback) {
              callback(update);
              persistObject(update, 'new_product_variants');
            }
          }
        }}
      />
    );
  };

  const renderVariantsPopup = (step: number) => {
    if (availableProductProperties) {
      switch (step) {
        case 1:
          return (
            <div className="variants-popup">
              <div className="variants-popup-header">
                <div className="variants-popup-header-title">
                  {t('view.product.variants.popup.title_step1')}
                </div>
                <SmallHint
                  paragraphs={[t('view.product.variants.popup.hint_step1')]}
                />
              </div>
              <PropertyBrowser
                availablePropertyGroups={availableProductProperties}
                selectedPropertyOptions={selectedVariantOptions}
                updateSelectedPropertyOptions={updateSelectedVariantOptions}
                selectedAnimalSpeciesIds={animalSpeciesIds}
                productGroupMappings={productGroupMappings}
                variantOptionsOnly={true}
                userInputCreatesVariantOption
                withSearch
              />
              <div className="variants-popup-actions">
                <Button
                  cta={t('actions.next')}
                  action={() => {
                    createNewVariantsList();
                    setSelectedStep(2);
                  }}
                  look={'secondary'}
                  width="minimal"
                  active={selectedVariantOptions.length > 0 && !readonly}
                />
              </div>
            </div>
          );
        case 2:
          return newVariants && newVariants.length > 0 ? (
            <div className="variants-popup">
              <div className="variants-popup-header">
                <div className="variants-popup-header-title">
                  {t('view.product.variants.popup.title_step2')}
                </div>
                <SmallHint
                  paragraphs={[t('view.product.variants.popup.hint_step2')]}
                />
              </div>
              <List
                items={newVariants}
                selectedItems={[...selectedNewVariants]}
                onSelect={toggleSelectVariant}
                onSelectAll={() => {
                  if (selectedNewVariants.length === newVariants.length) {
                    setSelectedNewVariants([]);
                  } else {
                    setSelectedNewVariants([...newVariants]);
                  }
                }}
                ignore={['productId', 'id', 'variantOptionIds']}
                ignoreColumnTranslationExceptKeys={['ean']}
                renderObjects={[
                  {
                    key: 'ean',
                    renderMethod: renderEAN,
                    receiveNullValues: true,
                  },
                ]}
              />
              <div className="variants-popup-actions variants-popup-actions-2">
                <Button
                  cta={t('actions.back')}
                  action={() => {
                    setSelectedStep(1);
                  }}
                  look={'secondary'}
                  width="minimal"
                />
                <Button
                  cta={t('view.product.variants.popup.cta')}
                  action={() => {
                    submitNewVariants();
                    setPopup(false);
                    setSelectedStep(1);
                  }}
                  look="save"
                  width="minimal"
                  active={selectedVariantOptions.length > 0 && !readonly}
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          ) : null;
      }
    }
  };

  if (productGroupId) {
    if (productVariants && availableProductProperties) {
      return (
        <div className="variants">
          {selectedProductVariants.length > 0 ? (
            <div className="variants-bulkActions">
              <Button
                cta={t('actions.archive')}
                look="secondary-danger"
                width="minimal"
                action={() => {
                  selectedProductVariants.forEach((variant) => {
                    archiveVariant(variant.id);
                  });
                  setSelectedProductVariants([]);
                }}
              />
              <Button
                cta={t('actions.unarchive')}
                look={'secondary'}
                width="minimal"
                margin="left"
                action={() => {
                  selectedProductVariants.forEach((variant) => {
                    if (variant.state === 'Archived') {
                      unarchiveVariant(variant.id);
                    }
                  });
                  setSelectedProductVariants([]);
                }}
              />
            </div>
          ) : null}
          {productVariants.length > 0 ? (
            <List
              items={productVariants}
              translatedStrings={['name']}
              ignore={[
                'id',
                'manufacturerId',
                'parentId',
                'productPrices',
                'productStocks',
                'productAssets',
                'updatedAt',
                'isBatchControlled',
                'isBestBeforeControlled',
                'productLine',
                'additionalInformation',
                'analyticConstituents',
                'createdAt',
                'isDangerousGoods',
                'brand',
                'brandId',
                'activeProductVersionId',
                'animalSpecies',
              ]}
              linkedKey={'id'}
              linkPrefix={() => '/products/variant/'}
              monoSpaceStrings={['ean']}
              actions={[
                {
                  cta: 'edit',
                  look: 'blue',
                  action: (item: any) => link('/products/variant/' + item.id),
                },
                {
                  cta: 'archive',
                  look: 'danger',
                  action: (item: any) => archiveVariant(item.id),
                },
              ]}
              sortValueFunctions={{
                state: getStateSortIndex,
              }}
              renderObjects={[
                {
                  key: 'state',
                  renderMethod: (value, item) =>
                    renderProductStateInList(value, item, t),
                },
              ]}
              selectedItems={selectedProductVariants}
              onSelectAll={() => {
                if (selectedProductVariants.length !== productVariants.length) {
                  setSelectedProductVariants(productVariants);
                } else {
                  setSelectedProductVariants([]);
                }
              }}
              onSelect={(item) => {
                const update = [...selectedProductVariants];
                const i = selectedProductVariants.findIndex(
                  (v) => v.id === item.id
                );
                if (i !== -1) {
                  update.splice(i, 1);
                } else {
                  update.push(item);
                }
                setSelectedProductVariants(update);
              }}
            />
          ) : null}
          {productVariants.length === 0 && !inlineNewVariants ? (
            <EmptyState message={t('view.product.variants.no_variants')} />
          ) : inlineNewVariants && inlineNewVariants.length > 0 ? (
            <List
              items={inlineNewVariants}
              ignore={['productId', 'variantOptionIds']}
              monoSpaceStrings={['ean']}
              ignoreColumnTranslationExceptKeys={['ean']}
              renderObjects={[
                {
                  key: 'ean',
                  renderMethod: renderEAN,
                  receiveNullValues: true,
                },
              ]}
              actions={[
                {
                  cta: 'delete',
                  look: 'danger',
                  action: (newVariant) => {
                    const update = [...inlineNewVariants];
                    const i = update.findIndex(
                      (v: any) => v.id === newVariant.id
                    );
                    console.log(i);
                    if (i !== -1) {
                      update.splice(i, 1);
                      setInlineVariants(update);
                    }
                  },
                },
              ]}
            />
          ) : null}
          {!readonly ? (
            <div className="variants-actions global-cardActions">
              <Button
                cta={t('view.product.variants.add')}
                look={'secondary'}
                width="minimal"
                action={() => setPopup(!popup)}
                active={!isSubmitting && !readonly}
              />
            </div>
          ) : null}
          <Popup toggled={popup} close={() => setPopup(false)} width="50%">
            {renderVariantsPopup(selectedStep)}
          </Popup>
        </div>
      );
    } else {
      return <LoadingContainer />;
    }
  } else {
    return <EmptyState message={t('view.product.variants.noProductGroupId')} />;
  }
};

export default Variants;
