import './receipts.css';
import { OrderReceiptResponse } from '../../../api/petcloudapi/api';
import { NoResults } from '../../../elements/emptystate/EmptyState';
import { useTranslation } from 'react-i18next';
import DateTime from '../../../elements/datetime/DateTime';
import { ReactComponent as IconDownload } from '../../../../assets/icon/import.svg';
import Button from '../../../elements/button/Button';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import { useState } from 'react';
import useFileDownload from '../../../hooks/useFileDownload';

interface ReceiptsProps {
  receipts: OrderReceiptResponse[] | null | undefined;
}

const Receipts: React.FC<ReceiptsProps> = ({ receipts }) => {
  const { t } = useTranslation();
  const api = usePetCloudApi();
  const ordersApi = api.ordersApi();
  const errorHandler = useErrorHandler();
  const { downloadFromBlob } = useFileDownload();

  const [downloading, setDownloading] = useState<string[]>([]);

  const toggleDownloading = (id: string) => {
    const update = downloading;
    const i = update.findIndex((d) => d === id);
    if (i !== -1) {
      update.splice(i);
    } else {
      update.push(id);
    }
    setDownloading([...update]);
  };

  const download = (orderId: string, orderReceiptId: string) => {
    toggleDownloading(orderReceiptId);
    ordersApi
      .ordersGetOrderReceiptDownload(orderId, orderReceiptId, undefined, {
        responseType: 'blob',
      })
      .then((response) => {
        console.log(response);
        downloadFromBlob(response);
        toggleDownloading(orderReceiptId);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        toggleDownloading(orderReceiptId);
      });
  };

  const triggerDownload = async (receipt: OrderReceiptResponse) => {
    if (receipt.documentData?.static && receipt.documentData.asset) {
      window.open(receipt.documentData.asset.uri, '_blank');
    } else {
      download(receipt.orderId, receipt.id);
    }
  };

  if (receipts && receipts.length > 0) {
    return (
      <div className={'receipts'}>
        {receipts.map((receipt) => {
          return (
            <div className={'receipt'}>
              <div>
                <div className={'receipt-title'}>
                  <div className={'receipt-title-name'}>
                    {t(`view.order.receipts.types.${receipt.type}`)}
                  </div>
                  <div className={'receipt-title-number'}>
                    {receipt.documentData?.finalDocumentNumber}
                  </div>
                </div>
                <div className={'receipt-date'}>
                  <DateTime
                    dateString={receipt.createdAt}
                    show={['day', 'month', 'year']}
                  />
                </div>
              </div>
              <div>
                <Button
                  type={'icon'}
                  look={'secondary'}
                  action={() => triggerDownload(receipt)}
                  isLoading={downloading.includes(receipt.id)}
                >
                  <IconDownload className={'button-icon-tertiary'} />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <NoResults />;
  }
};

export default Receipts;
