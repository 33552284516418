import './badge.css';
import { SmallHint } from '../hint/Hint';
import { ReactNode } from 'react';

export interface BadgeProps {
  title: string;
  color: string;
  textColor?: string;
  hintParagraphs?: string[];
  hoverable?: boolean;
  icon?: ReactNode;
  helperCSSClass?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  title,
  color,
  textColor,
  hintParagraphs,
  hoverable,
  icon,
  helperCSSClass,
}) => {
  return (
    <SmallHint paragraphs={hintParagraphs}>
      <div
        className={`badge ${helperCSSClass ?? ''}`}
        style={{ cursor: hintParagraphs || hoverable ? 'pointer' : 'auto' }}
      >
        <div
          className={'badge-background'}
          style={{ backgroundColor: color }}
        ></div>
        {icon ?? null}
        <div className={'badge-value'} style={{ color: textColor ?? color }}>
          {title}
        </div>
      </div>
    </SmallHint>
  );
};

export default Badge;
