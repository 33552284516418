import './informationbox.css';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';
import { ReactNode, useState } from 'react';
import { ReactComponent as IconClose } from '../../../assets/icon/close.svg';

export interface InformationBoxProps {
  title?: string;
  content?: string | JSX.Element;
  paragraphs?: string[];
  type: 'info' | 'warning' | 'error' | 'success';
  maxWidth?: number;
  dismissSettingName?: string;
  icon?: ReactNode;
  noMargin?: boolean;
}

const classes = {
  info: 'informationbox',
  warning: 'informationbox informationbox-warning',
  error: 'informationbox informationbox-error',
  success: 'informationbox informationbox-success',
};

const markerClasses = {
  info: 'informationbox-marker',
  warning: 'informationbox-marker informationbox-marker-warning',
  error: 'informationbox-marker informationbox-marker-error',
  success: 'informationbox-marker informationbox-marker-success',
};

const InformationBox: React.FC<InformationBoxProps> = ({
  title,
  content,
  paragraphs,
  type,
  maxWidth,
  dismissSettingName,
  icon,
  noMargin,
}) => {
  const { getToggledSetting, setToggledSetting } = useLocalStorageSettings();
  const [dismissed, setDismissed] = useState(
    dismissSettingName ? getToggledSetting(dismissSettingName) : false
  );

  if (dismissed) {
    return null;
  } else {
    return (
      <div
        className={classes[type]}
        style={{ maxWidth: maxWidth, marginBottom: noMargin ? undefined : 20 }}
      >
        <div className={markerClasses[type]}></div>
        {icon ? <div className={`informationbox-icon`}>{icon}</div> : null}
        <div>
          {title ? <div className="informationbox-title">{title}</div> : null}
          <div className="informationbox-text">{content}</div>
          {paragraphs
            ? paragraphs.map((x) => {
                return <p className={'informationbox-paragraph'}>{x}</p>;
              })
            : null}
          {dismissSettingName ? (
            <IconClose
              fill={'black'}
              className={'informationbox-close'}
              onClick={() => {
                setDismissed(true);
                setToggledSetting(dismissSettingName, true);
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
};

export default InformationBox;
